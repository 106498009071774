import type { FlexProps, TooltipProps } from '@chakra-ui/react';
import {
  Flex,
  Icon as ChakraIcon,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { InfoDrawer } from '@ui/components/InfoDrawer';
import { RenderInMobile, useIsDesktop } from '@ui/components/MobileOrDesktop';
import { InColorTheme } from '@veno-app/chakra';
import { Info as InfoIcon } from '@veno-app/icons';
import type { ReactNode } from 'react';
import React from 'react';

export type InfoTooltipProps = FlexProps & {
  title: ReactNode;
  label: ReactNode;
  tooltipProps?: Partial<TooltipProps>;
};
export const InfoTooltip = ({
  title,
  label,
  tooltipProps,
  ...props
}: InfoTooltipProps) => {
  const isDesktop = useIsDesktop();
  const {
    isOpen: isInfoDrawerOpen,
    onClose: onCloseInfoDrawer,
    onOpen: onOpenInfoDrawer,
  } = useDisclosure();

  return (
    <>
      <RenderInMobile>
        <InColorTheme colorTheme="default">
          <InfoDrawer
            close={onCloseInfoDrawer}
            isOpen={isInfoDrawerOpen}
            title={title}
          >
            {label}
          </InfoDrawer>
        </InColorTheme>
      </RenderInMobile>
      <Tooltip
        p={0}
        m={0}
        color="brand-black"
        bgColor="bg.main"
        label={label}
        {...tooltipProps}
      >
        <Flex cursor="pointer" color={'pink.500'} {...props}>
          <ChakraIcon
            as={InfoIcon}
            ml={1}
            w="24px"
            h="24px"
            onClick={(e) => {
              e.stopPropagation();
              if (!isDesktop) {
                onOpenInfoDrawer();
              }
            }}
          />
        </Flex>
      </Tooltip>
    </>
  );
};
