import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import { create } from 'zustand';

import type { InfoDrawerProps } from './InfoDrawer';
import InfoDrawer from './InfoDrawer';

type InfoDrawerWithIdentifierProps = { identifier: string } & Omit<
  InfoDrawerProps,
  'close' | 'isOpen'
>;

export const InfoDrawerWithIdentifier = ({
  identifier,
  ...props
}: InfoDrawerWithIdentifierProps) => {
  const { isOpen: isInfoDrawerOpen, close: onCloseInfoDrawer } =
    useInfoDrawerWithIdentifier();
  return (
    <InfoDrawer
      close={() => onCloseInfoDrawer(identifier)}
      isOpen={isInfoDrawerOpen(identifier)}
      {...props}
    />
  );
};

type InfoDrawerStore = {
  openDrawers: string[];
  open: (key: string) => void;
  close: (key: string) => void;
};

export const useInfoDrawerStore = create<InfoDrawerStore>(
  resetWhenRouterChange((set, get) => ({
    openDrawers: [],
    open: (key: string) =>
      set(() => ({
        openDrawers: [...get().openDrawers, key],
      })),
    close: (key: string) =>
      set(() => ({
        openDrawers: get().openDrawers.filter((drawer) => key !== drawer),
      })),
  })),
);

export const useInfoDrawerWithIdentifier = () => {
  const { openDrawers, open, close } = useInfoDrawerStore();
  const isOpen = (key: string) => openDrawers.includes(key);

  return { isOpen, open, close };
};

export default InfoDrawerWithIdentifier;
