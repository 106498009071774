import { BigNumber } from '@ethersproject/bignumber';

const ZERO = BigNumber.from(0);

type Pool = {
  p: string;
  t: number;
  f0: string;
  f1: string;
  fc: string;
  pf: number;
  t0: [string, string, string, number];
  t1: [string, string, string, number];
  r0: string;
  r1: string;
  s: string;
  v0: string;
  v1: string;
  fl: string;
  ft: string;
  tf: string;
};

type APIResult = {
  pools: Pool[];
};

const toBigNumber = (value: string) => BigNumber.from(value);

const ONE_DAY_SECONDS = 86400;
const YEAR_SECONDS = 31536000;
const DECIMALS_10 = BigNumber.from('10000000000');

function getTimestampNow() {
  return Date.now() / 1000;
}

function getEpochStartNow(duration: number) {
  const timestamp = getTimestampNow();
  return timestamp - (timestamp % duration);
}

let response: Response | null = null;

/**
 * calculate the fee apr of a syncswap pool
 *
 * @param poolId
 * @returns if this apr value is 0.0195, it means we have 1.95% APR
 */
export const getZksyncApr = async (poolId: string) => {
  response =
    response ??
    (await fetch(
      `https://api.syncswap.xyz/api/fetchers/fetchAllPools?network=zkSyncMainnet&account=0x0000000000000000000000000000000000000001&quote=next&type=v2`,
    ));
  const data: APIResult = await response.json();

  const pool = data.pools.find((p) => p.p === poolId);
  if (!pool) throw new Error(`ZkSync APR: Pool ${poolId} not found`);

  // min fee = max fee
  // const fee01 = BigNumber.from(pool.f0);
  // const fee10 = BigNumber.from(pool.f1);
  // const isV2 = false;
  // const pooltype = pool.t;
  const protocolFee = pool.pf;

  // const reserve0 = toBigNumber(pool.r0);
  // const reserve1 = toBigNumber(pool.r1);
  const totalSupply = toBigNumber(pool.s);
  // const accountBalance = ZERO;

  // const token0Value = toBigNumber(pool.v0);
  // const token1Value = toBigNumber(pool.v1);

  const feesCurrentEpoch = toBigNumber(pool.fc);
  const feesLastEpoch = toBigNumber(pool.fl);
  const feeStartTimeBn = toBigNumber(pool.ft);
  // const totalFeeAmount = toBigNumber(pool.tf);

  const epochDuration = ONE_DAY_SECONDS;

  // const a = ZERO;
  const timestampNow = getTimestampNow();

  const epochStart = getEpochStartNow(epochDuration);
  // const lastEpochStart = getEpochStartNow(epochDuration) - epochDuration;
  const elapsedCurrentEpoch = Math.floor(timestampNow - epochStart);
  const elapsedSinceLastEpoch = epochDuration + elapsedCurrentEpoch;

  let feeStartTime = Number(feeStartTimeBn.toString());
  if (feeStartTime < 1) {
    feeStartTime = timestampNow;
  }

  const combined: [number, BigNumber] = [
    elapsedSinceLastEpoch,
    feesCurrentEpoch.add(feesLastEpoch),
  ];

  const protocolFees = combined[1];

  // recover LP fees with protocol fees and fee share.
  const liquidityProviderFees =
    protocolFee === 0
      ? BigNumber.from(0)
      : protocolFees
          .mul(100000 - protocolFee) // LP fee share, like 70000 or 70%
          .div(protocolFee); // protocol fee share, like 30000 or 30%

  // const totalFees = protocolFees.add(liquidityProviderFees);

  const noElapsedSecs = combined[0] < 1;
  const elapsedSecs = noElapsedSecs
    ? ZERO
    : BigNumber.from(Math.floor(combined[0]));

  // 24h fees
  // const moreThanOneDay = elapsedSecs.gt(ONE_DAY_SECONDS);
  // const fees = moreThanOneDay
  //   ? totalFees.mul(ONE_DAY_SECONDS).div(elapsedSecs)
  //   : totalFees; // estimate 24h or less than 24h

  // const totalValue = token0Value.add(token1Value);

  // 24h volume
  // const avgFee = BigNumber.from(
  //   Math.floor(
  //     (Number(fee01.toString()) +
  //       Number(fee10.toString())) /
  //       2,
  //   ),
  // );
  // const volume =
  //   avgFee.isZero() || noElapsedSecs
  //     ? ZERO
  //     : moreThanOneDay
  //     ? totalFees
  //         .mul(totalValue)
  //         .mul(100000)
  //         .mul(ONE_DAY_SECONDS)
  //         .div(avgFee)
  //         .div(elapsedSecs)
  //         .div(totalSupply)
  //     : totalFees
  //         .mul(totalValue)
  //         .mul(100000)
  //         .div(avgFee)
  //         .div(totalSupply);

  // 24h apr, in 10 decimals
  const apr = noElapsedSecs
    ? ZERO
    : liquidityProviderFees
        .mul(DECIMALS_10)
        .mul(YEAR_SECONDS)
        .div(elapsedSecs)
        .div(totalSupply);

  return apr.toNumber() / DECIMALS_10.toNumber();
};
