import { Box, Link, Text } from '@chakra-ui/react';
import type { Token } from '@ui/components/TokenIcon';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { useTranslations } from '@ui/i18n';
import type { ChainType } from '@veno-app/sdk';
import { currentWallet } from '@veno-app/wallet';

const { useIsCronosChainId } = currentWallet;

export const useHowItWorksData = () => {
  const t = useTranslations();
  const isCronos = useIsCronosChainId();

  return {
    title: 'Reservoir & Fountain',
    shortText:
      t(
        'You can lock your VNO to earn rewards There are two ways to earn from holding VNO',
      ).slice(0, 70) + '...',
    shortTextDesktop: (
      <>
        <Text color="#000000" fontWeight="bold" display="inline">
          {t('{a} and {b}', {
            a: 'Reservoir',
            b: 'Fountain',
          })}
          :{' '}
        </Text>
        {t(
          'You can lock your VNO to earn rewards There are two ways to earn from holding VNO',
        ).slice(0, 75) + '...'}
      </>
    ),
    longText: (
      <Box>
        {t(
          'You can lock your VNO to earn rewards There are two ways to earn from holding VNO',
        )}
        <br />
        <br />
        <b>Fountain</b>
        <br />
        {t('Lock your VNO in the Fountain to earn a share of VNO emissions')}
        <br />
        <br />
        <b>Reservoir</b>
        <br />
        {t('reservoir-how-it-works-1-{Link}', {
          Link: (chunks) => (
            <Link
              href={EXTERNAL_LINK.gitbookReservoir}
              isExternal
              color="text-link"
            >
              {chunks}
            </Link>
          ),
        })}
        <br />
        <br />
        {isCronos ? (
          <>{t('enabled-early-withdraw')}</>
        ) : (
          <>{t('disabled-early-withdraw')}</>
        )}
      </Box>
    ),
  };
};

export const RESERVOIR_REWARD_TOKEN_DICT: Record<
  ChainType,
  { name: string; icon: string; iconId: Token }
> = {
  cronos: { name: 'CRO', icon: '/tokens/cro.svg', iconId: 'cro' },
  zksync: { name: 'ETH', icon: '/tokens/eth.svg', iconId: 'eth' },
};

export const THREE_MONTHS_TIMESTAMP = 7776000;
export const SIX_MONTHS_TIMESTAMP = 15552000;
export const ONE_YEAR_TIMESTAMP = 31104000;
export const FOUR_YEARS_TIMESTAMP = 31104000 * 4;
