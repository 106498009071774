import type { BigNumber } from '@ethersproject/bignumber';
import { useQuery } from '@tanstack/react-query';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';

import type { CoinMode } from './useCoinMode';

export const useStrategyTokenUsdValue = (
  amount: BigNumber | undefined,
  coinMode?: CoinMode,
): number | null => {
  // const result = useReceiveTokenInfo(amount ?? BigNumber.from(0)).maxAmount;
  // if (!amount) return 0;
  // return result ? bigNumberToNumber(result) : null;

  const sdk = useVenoSDK();
  return (
    useQuery(
      ['useStrategyTokenUsdValue', coinMode, amount?.toString()],
      async (): Promise<number | null> => {
        try {
          return amount ? sdk.FerroVault.getUsdValue(amount) : null;
        } catch (e) {
          console.error(e);
          return null;
        }
      },
    ).data ?? null
  );
};
