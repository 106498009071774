import type { CoinMode } from '@ui/hooks/useCoinMode';

import { Network } from './base';

export const FERRO_POOL_CRO_LCRO = 'LCRO-CRO';

export const FERRO_POOL_ATOM_LATOM = 'LATOM-ATOM';

export const VVS_VNO_POOL = 'VVS-VNO LP';

export const VVS_CRO_POOL = 'LCRO-CRO LP';

// TODO: check with vvs team
export const VVS_TIA_POOL = 'LTIA-TIA LP';

// TODO ttoken address for Tectonic LCRO
export const getTectonicTotalSupplyEndpoint = (coinMode: CoinMode) => {
  if (coinMode === 'atom') {
    // tLatom is only available on mainnet
    return `https://api.tectonic.finance/info/tasset-info/0x0a927384CC7ff3D250ac276ae2158D837e42f667`;
  }

  switch (process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK) {
    case Network.cronos_testnet:
      return 'https://psta-tectonic-api.crorc.co/info/tasset-info/0x440aB4Cdb8A7C4D017700027C122D3E73A2e5e5b';
    case Network.cronos:
    default:
      return 'https://api.tectonic.finance/info/tasset-info/0xCd8F4147A4F9452e027f5203bfc5B7A786055138';
  }
};
