import type { ButtonProps, FlexProps } from '@chakra-ui/react';
import { Button, Flex, Image, Text } from '@chakra-ui/react';
import { useTranslations } from '@ui/i18n';

export type TransactionPendingContentProps = {
  nSteps?: number;
  currentStep?: number;
} & FlexProps;

const TransactionPendingContent = ({
  nSteps,
  currentStep,
  ...flexProps
}: TransactionPendingContentProps) => {
  const t = useTranslations();
  return (
    <Flex
      mt="25px"
      grow={1}
      flexDir="column"
      gap={2}
      align="center"
      color="text.light"
      textStyle="body"
      {...flexProps}
    >
      <Image src="/images/loading.gif" alt="loading" boxSize="160px" />
      <Text>
        {t('Confirming transaction in your wallet')}{' '}
        {currentStep && nSteps && nSteps > 1 ? (
          <>
            ({currentStep} / {nSteps})
          </>
        ) : (
          <></>
        )}
      </Text>
    </Flex>
  );
};

type TransactionPendingFooterProps = ButtonProps;

const TransactionPendingFooter = ({
  ...buttonProps
}: TransactionPendingFooterProps) => (
  <Button isLoading={true} {...buttonProps} />
);

const TransactionPending = {
  Content: TransactionPendingContent,
  Footer: TransactionPendingFooter,
} as const;

export default TransactionPending;
