import { useTranslations } from '@ui/i18n';
import { currentWallet, explorers } from '@veno-app/wallet';

const { useIsZksyncChainId, useSupportedChainId } = currentWallet;

export const useExplorerLabel = () => {
  const isZksync = useIsZksyncChainId();
  const t = useTranslations();

  return t('View on {explorer}', {
    explorer: isZksync ? 'zkSync Era Block Explorer' : 'Cronos Explorer',
  });
};

export const useExplorerUrl = (): string | undefined => {
  const supportedChainId = useSupportedChainId();
  const explorerUrl = supportedChainId
    ? explorers[supportedChainId]
    : undefined;

  return explorerUrl;
};
