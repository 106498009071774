import type { DrawerProps, FlexProps, ModalProps } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';
import {
  ModalOrDragDrawerContent,
  ModalOrDrawer,
  ModalOrDrawerBody,
  ModalOrDrawerCloseButton,
  ModalOrDrawerFooter,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from '@ui/components/ModalOrDrawer';
import type {
  FC,
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
} from 'react';
import { Children } from 'react';

import { RenderInDesktop } from '../MobileOrDesktop';

export type InfoDrawerProps = {
  close: () => void;
  isOpen: boolean;
  title: ReactNode;
} & Omit<DrawerProps & ModalProps, 'onClose'>;

const InfoDrawer: FunctionComponent<InfoDrawerProps> = ({
  close,
  isOpen,
  title,
  children,
  ...modalOrDrawerProps
}) => {
  const [content, footer] = Children.toArray(children);

  return (
    <ModalOrDrawer
      placement="bottom"
      size="lg"
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={() => close()}
      {...modalOrDrawerProps}
    >
      <ModalOrDrawerOverlay />
      <ModalOrDragDrawerContent>
        <RenderInDesktop>
          <ModalOrDrawerCloseButton />
        </RenderInDesktop>
        <ModalOrDrawerHeader>{title}</ModalOrDrawerHeader>
        <ModalOrDrawerBody mb="150px">{content}</ModalOrDrawerBody>
        {footer && <ModalOrDrawerFooter>{footer}</ModalOrDrawerFooter>}
      </ModalOrDragDrawerContent>
    </ModalOrDrawer>
  );
};

export const InfoDrawerContent: FC<PropsWithChildren<FlexProps>> = ({
  children,
  ...flexProps
}) => {
  return <Flex {...flexProps}>{children}</Flex>;
};

export const InfoDrawerFooter: FC<PropsWithChildren<FlexProps>> = ({
  children,
  ...flexProps
}) => {
  return <Flex {...flexProps}>{children}</Flex>;
};

export default InfoDrawer;
