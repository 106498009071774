import type { DrawerProps } from '@chakra-ui/react';
import { Drawer, DrawerHeader, DrawerOverlay } from '@chakra-ui/react';
import { useTranslations } from '@ui/i18n';

import { DragDrawerContent } from '../DragDrawer';

export const GetAssetDrawer = ({
  children,
  tokenName,
  ...props
}: DrawerProps & { tokenName: string }) => {
  const t = useTranslations();
  return (
    <Drawer placement="bottom" {...props}>
      <DrawerOverlay zIndex="modal" />
      <DragDrawerContent minH="60vh">
        <DrawerHeader>
          {t('Get {coin}', {
            coin: tokenName,
          })}
        </DrawerHeader>
        {children}
      </DragDrawerContent>
    </Drawer>
  );
};
