import { trackEvent } from '@ui/utils/tracking';
import type { Dict } from 'mixpanel-browser';
import { useCallback, useEffect, useRef } from 'react';

const propTypeAllowed = (prop: unknown): boolean => {
  return (
    typeof prop === 'string' ||
    typeof prop === 'number' ||
    typeof prop === 'boolean'
  );
};

const useTrackVisit = (
  eventName: string,
  additionalProps?: Dict,
  enabled?: () => boolean,
) => {
  const called = useRef(false);

  const reset = useCallback(() => {
    called.current = false;
  }, []);

  useEffect(() => {
    if ((enabled && !enabled()) || called.current) return;

    const props: Record<string, unknown> = {};
    if (additionalProps) {
      Object.keys(additionalProps).map(
        // (a) => (props[`visit_${a}`] = `${additionalProps[a]}`),
        (a) => (props[a] = propTypeAllowed(a) ? a : `${additionalProps[a]}`),
      );
    }

    called.current = true;
    trackEvent(eventName, props);
  }, [enabled, additionalProps, eventName]);

  return { reset };
};

export default useTrackVisit;
