import type { BigNumber } from '@ethersproject/bignumber';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from '@ui/overrides/next/router';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { QueryKey } from '@ui/queries/queryKey';
import bigNumberToNumber from '@ui/utils/bigNumberToNumber';
import { trackEvent } from '@ui/utils/tracking';
import type { VenoSDK } from '@veno-app/sdk';
import { currentWallet } from '@veno-app/wallet';

import { useCoinMode } from './useCoinMode';
import { useLiquidCoinBalance } from './useLiquidCoinBalance';

const { useAccount } = currentWallet;

const useTectonicSupply = () => {
  const coinMode = useCoinMode();
  const queryClient = useQueryClient();
  const { pathname } = useRouter();
  const sdk = useVenoSDK();
  const userAccount = useAccount();
  const supply = async (amount: BigNumber) => {
    const data =
      coinMode === 'atom'
        ? await sdk.TLatom.mint(amount)
        : await sdk.TLcro.mint(amount);
    return await data.txReceiptPromise;
  };

  return useMutation(({ amount }: { amount: BigNumber }) => supply(amount), {
    onSuccess: async (_, vars) => {
      queryClient.invalidateQueries([QueryKey.TLCRO_BALANCE]);
      queryClient.invalidateQueries(useLiquidCoinBalance.queryKey(coinMode));
      trackTectonicDirectDepositEvent(sdk, vars, userAccount ?? '', pathname);
    },
  });
};

async function trackTectonicDirectDepositEvent(
  sdk: VenoSDK,
  vars: { amount: BigNumber },
  userAccount: string,
  pathname: string,
) {
  const remainingBalance = bigNumberToNumber(
    await sdk.TLcro.balanceOf(userAccount ?? ''),
  );
  const protocol = 'tectonic';
  const isTransaction = true;
  const amount = bigNumberToNumber(vars.amount);
  const entrypoint = pathname.includes('garden') ? 'garden' : 'use_lcro';
  trackEvent('Deposit LCRO Confirmed', {
    Amount: amount,
    isTransaction,
    Protocol: protocol,
    'Entry Point': entrypoint,
    'Remaining Balance': remainingBalance,
  });
}

export default useTectonicSupply;
