import { Button } from './Button';
export const CloseButton = {
    baseStyle() {
        return {
            '& > svg': {
                fontSize: '24px',
            },
        };
    },
    variants: {
        ...Button.variants,
    },
    sizes: {
        ...Button.sizes,
    },
    defaultProps: {},
};
