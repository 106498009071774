/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { ChakraProps } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';
import { BigNumber } from '@ethersproject/bignumber';
import { MaxInt256, MaxUint256 } from '@ethersproject/constants';
import { formatUnits } from '@ethersproject/units';
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { LPTokenIcon } from '@ui/components/LPTokenIcon';
import { TokenIcon } from '@ui/components/TokenIcon';
import { useStrategyApyQuery } from '@ui/hooks/useApr';
import { useBalance } from '@ui/hooks/useBalance';
import {
  type CoinMode,
  COIN_MODE,
  useGlobalCoinMode,
} from '@ui/hooks/useCoinMode';
import { useCoinUsdValue } from '@ui/hooks/useCoinUsdValue';
import useFerroLpBalance from '@ui/hooks/useFerroLpBalance';
import { useLiquidCoinBalance } from '@ui/hooks/useLiquidCoinBalance';
import { useLiquidCoinUsd } from '@ui/hooks/useLiquidCoinUsd';
import useLpUsdValue from '@ui/hooks/useLpUsdValue';
import useWcroBalance from '@ui/hooks/useWcroBalance';
import { useWcroUsdValue } from '@ui/hooks/useWcroUsdPrice';
import { useSpecificVenoSDK, useVenoSDK } from '@ui/providers/VenoSDKProvider';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import {
  type ContractReceipt,
  type VenoTransaction,
  ExcludeTransactionMethods,
  expandDecimals,
  VenoSDK,
} from '@veno-app/sdk';
import { currentWallet } from '@veno-app/wallet';
import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';

import bigNumberToNumber, { numberToBigNumber } from './bigNumberToNumber';
import { parseValue } from './format';
import { gardenTokenDict } from './garden';

export type LiquidityStrategyView = 'deposit' | 'withdraw' | 'dashboard';

export type LiquidityStrategyDepositToken = 'cro' | 'wcro' | 'lcro' | 'ferroLP';

export const LiquidityStrategyTokenIcon: FC<ChakraProps> = ({
  width,
  height,
  ...props
}) => (
  <LPTokenIcon
    coinMode="cro"
    gardenType="FERRO"
    iconWidth={width}
    iconHeight={height}
    {...props}
  />
);

export const LiquidityStrategyTokenDict: Record<
  LiquidityStrategyDepositToken,
  {
    name: string;
    shortName: string;
    Icon: FC<ChakraProps>;
    maxDecimals: number;
    decimals: number;
    vaultTokenName: string;
    ProtocolImage?: ReactNode;
  }
> = {
  cro: {
    name: 'CRO',
    shortName: 'CRO',
    Icon: (props) => <TokenIcon token="cro" {...props} />,
    maxDecimals: 8,
    decimals: COIN_MODE.cro.decimals,
    vaultTokenName: 'vLCRO-CRO',
  },
  wcro: {
    name: 'WCRO',
    shortName: 'WCRO',
    Icon: (props) => <TokenIcon token="cro" {...props} />,
    maxDecimals: 8,
    decimals: COIN_MODE.cro.decimals,
    vaultTokenName: 'vLCRO-CRO',
  },
  lcro: {
    name: 'LCRO',
    shortName: 'LCRO',
    Icon: (props) => <TokenIcon token="lcro" {...props} />,
    maxDecimals: 8,
    decimals: COIN_MODE.cro.liquidDecimals,
    vaultTokenName: 'vLCRO-CRO',
  },
  ferroLP: {
    name: 'LCRO-CRO-LP',
    shortName: 'LP',
    Icon: ({ width, height, ...props }) => (
      <LPTokenIcon
        coinMode="cro"
        gardenType="FERRO"
        iconWidth={width}
        iconHeight={height}
        {...props}
      />
    ),
    maxDecimals: 8,
    decimals: gardenTokenDict.cro.FERRO.decimals!,
    vaultTokenName: 'vLCRO-CRO',
    ProtocolImage: (
      <Image w="76px" h="16px" src="/images/ferro-logo.png" alt="Ferro" />
    ),
  },
};

const getReceivedAmount = (
  sdk: VenoSDK,
  receipt: ContractReceipt,
  eventEmitterAddress: string,
  isDeposit: boolean,
  _receiver: string,
): BigNumber | undefined => {
  const targetEvent = [...receipt.logs]
    .reverse()
    .map((log) => {
      try {
        return {
          ...sdk.FerroVault.interface.parseLog(log),
          address: log.address,
        };
      } catch (e) {
        // nothing
      }
    })
    .find((event) => {
      return Boolean(
        event &&
          (event.name === 'Deposit' || event.name === 'Withdraw') &&
          (!isDeposit ||
            event.address.toLowerCase() === eventEmitterAddress.toLowerCase()),
      );
    });
  return (
    (isDeposit
      ? (targetEvent?.args?.shares as BigNumber)
      : (targetEvent?.args?.amount as BigNumber)) || undefined
  );
};

type VaultSdk = {
  contractAddress: string;
  // balanceOf: (account: string) => Promise<BigNumber>;
  // exchangeRate: () => Promise<BigNumber>;
  deposit: (amount: BigNumber, slippage: number) => Promise<VenoTransaction>;
  withdraw: (amount: BigNumber, slippage: number) => Promise<VenoTransaction>;
  getDepositReceivedAmount: (
    receipt: ContractReceipt,
    receiver: string,
  ) => BigNumber | undefined;
  getWithdrawReceivedAmount: (
    receipt: ContractReceipt,
    receiver: string,
  ) => BigNumber | undefined;
  shouldApproveWhenWithdraw: (
    account: string,
    amount: BigNumber,
  ) => Promise<boolean>;
  approveWhenWithdraw: (amount: BigNumber) => Promise<void | VenoTransaction>;
  getWithdrawExchangeRate: (vaultTokenAmount: BigNumber) => Promise<BigNumber>;
  getDepositExchangeRate: (tokenAmount: BigNumber) => Promise<BigNumber>;
  getDepositPriceImpact: (tokenAmount: BigNumber) => Promise<number>;
  getWithdrawPriceImpact: (vaultTokenAmount: BigNumber) => Promise<number>;
};

type ModuleAbstract = Omit<
  VaultSdk,
  | 'getDepositReceivedAmount'
  | 'getWithdrawReceivedAmount'
  | 'approveWhenWithdraw'
>;

export const useVaultSdk = (
  token: LiquidityStrategyDepositToken,
): VaultSdk | null => {
  const sdk = useVenoSDK();
  const cronosSdk = useSpecificVenoSDK('cronos');

  const tokenToEventEmitterMap: Record<LiquidityStrategyDepositToken, string> =
    {
      wcro: sdk.addresses.wcroDelegator,
      ferroLP: sdk.addresses.ferroVault,
      lcro: sdk.addresses.lcroDelegator,
      cro: sdk.addresses.wcroDelegator,
    };

  const tokenApprovalMap: Record<LiquidityStrategyDepositToken, string> = {
    wcro: sdk.addresses.wcroDelegator,
    ferroLP: sdk.addresses.ferroVault,
    lcro: sdk.addresses.lcroDelegator,
    cro: sdk.addresses.croDelegator,
  };

  const _getReceivedAmount = (
    receipt: ContractReceipt,
    receiver: string,
    isDeposit: boolean,
  ) =>
    getReceivedAmount(
      sdk,
      receipt,
      tokenToEventEmitterMap[token],
      isDeposit,
      receiver,
    );

  const approveWhenWithdraw = async (_amount: BigNumber) => {
    const targetContract = tokenApprovalMap[token];
    if (targetContract === sdk.addresses.ferroVault) return;
    return await sdk.FerroVault.approve(targetContract, MaxUint256);
  };

  const commonVaultSdkMap: Record<
    LiquidityStrategyDepositToken,
    ModuleAbstract
  > = {
    wcro: sdk.WcroDelegator,
    ferroLP: sdk.FerroVault,
    lcro: sdk.LcroDelegator,
    cro: sdk.CroDelegator,
  };

  const cronosVaultSdkMap: Record<
    LiquidityStrategyDepositToken,
    ExcludeTransactionMethods<ModuleAbstract>
  > = {
    wcro: cronosSdk.WcroDelegator,
    ferroLP: cronosSdk.FerroVault,
    lcro: cronosSdk.LcroDelegator,
    cro: cronosSdk.CroDelegator,
  };

  const newVault: typeof sdk.FerroVault = Object.create(
    commonVaultSdkMap[token],
  );

  return Object.assign(newVault, {
    getDepositReceivedAmount: (receipt: ContractReceipt, receiver: string) =>
      _getReceivedAmount(receipt, receiver, true),
    getWithdrawReceivedAmount: (receipt: ContractReceipt, receiver: string) =>
      _getReceivedAmount(receipt, receiver, false),
    approveWhenWithdraw,
    getWithdrawExchangeRate: (amount: BigNumber) =>
      cronosVaultSdkMap[token].getWithdrawExchangeRate(amount),
  });
};

type TokenSdk = {
  allowance: (account: string, contractAddress: string) => Promise<BigNumber>;
  approve: (
    contractAddress: string,
    amount: BigNumber,
  ) => Promise<VenoTransaction>;
};

export const useTokenSdk = (
  token: LiquidityStrategyDepositToken,
): TokenSdk | null => {
  const sdk = useVenoSDK();

  return useMemo(() => {
    if (token === 'cro') {
      return {
        allowance: async () => {
          return MaxInt256;
        },
        approve: async () => {
          return {
            txHash: '',
            txReceiptPromise: new Promise((resolve) =>
              resolve({} as ContractReceipt),
            ),
          };
        },
      };
    } else if (token === 'wcro') {
      return sdk.Wcro;
    } else if (token === 'lcro') {
      return sdk.LiquidCro;
    } else if (token === 'ferroLP') {
      return sdk.FerroLpToken;
    }
    return null;
  }, [token, sdk.FerroLpToken, sdk.LiquidCro, sdk.Wcro]);
};

export const useVaultBalance = (
  options: UseQueryOptions<BigNumber> = {},
): UseQueryResult<BigNumber> => {
  const sdk = useSpecificVenoSDK('cronos');
  const account = currentWallet.useAccount();

  return useQuery<BigNumber>(
    ['useVaultBalance', sdk, account],
    async () => {
      if (!account) {
        return BigNumber.from('0');
      }

      return sdk.FerroVault.balanceOf(account);
    },
    options,
  );
};

export const useVaultDepositExchangeRate = (
  token: LiquidityStrategyDepositToken,
  amount: BigNumber,
  options: UseQueryOptions<number | undefined> = {},
) => {
  const vaultSdk = useVaultSdk(token);

  return useQuery<number | undefined>(
    ['useVaultDepositExchangeRate', token, amount],
    async () => {
      if (!vaultSdk) {
        return;
      }

      return bigNumberToNumber(await vaultSdk.getDepositExchangeRate(amount));
    },
    options,
  );
};

export const useVaultWithdrawExchangeRate = (
  token: LiquidityStrategyDepositToken,
  amount: BigNumber,
  options: UseQueryOptions<number | undefined> = {},
) => {
  const vaultSdk = useVaultSdk(token);

  return useQuery<number | undefined>(
    ['useVaultWithdrawExchangeRate', token, amount],
    async () => {
      if (!vaultSdk) {
        return;
      }

      return bigNumberToNumber(await vaultSdk.getWithdrawExchangeRate(amount));
    },
    options,
  );
};

export const useFerroLpMintedOnDeposit = (
  token: LiquidityStrategyDepositToken,
  tokenAmount: BigNumber | undefined | null,
  options: UseQueryOptions<BigNumber | undefined> = {},
) => {
  const vaultSdk = useVaultSdk(token);
  const sdk = useVenoSDK();

  return useQuery<BigNumber | undefined>(
    ['useFerroLpMintedOnDeposit', token, tokenAmount],
    async () => {
      if (!vaultSdk) {
        return;
      }

      if (!tokenAmount?.gt(0)) {
        return BigNumber.from(0);
      }

      if (token === 'ferroLP') {
        return tokenAmount;
      }

      return token === 'lcro'
        ? await sdk.FerroSwap.calculateTokenAmount(
            tokenAmount,
            BigNumber.from(0),
          )
        : await sdk.FerroSwap.calculateTokenAmount(
            BigNumber.from(0),
            tokenAmount,
          );
    },
    {
      ...options,
      enabled: tokenAmount ? options.enabled : false,
    },
  );
};

export const useFerroLpBurnedOnWithdraw = (
  token: LiquidityStrategyDepositToken,
  vaultTokenAmount: BigNumber | undefined | null,
  options: UseQueryOptions<BigNumber | undefined> = {},
) => {
  const vaultSdk = useVaultSdk(token);
  const sdk = useVenoSDK();

  return useQuery<BigNumber | undefined>(
    ['useFerroLpBurnedOnWithdraw', token, vaultTokenAmount],
    async () => {
      if (!vaultSdk) {
        return;
      }

      if (!vaultTokenAmount?.gt(0)) {
        return BigNumber.from(0);
      }

      const exchangeRate = await sdk.FerroVault.exchangeRate();

      return vaultTokenAmount.mul(exchangeRate).div(expandDecimals(1, 18));
    },
    {
      ...options,
      enabled: vaultTokenAmount ? options.enabled : false,
    },
  );
};

export const useTokenAssetsFromShares = (
  token: LiquidityStrategyDepositToken,
  shares: BigNumber | undefined | null,
) => {
  const exchangeRateRet = useVaultDepositExchangeRate(
    token,
    shares ?? BigNumber.from(0),
  );

  const precision = 1_000_000;
  return shares && exchangeRateRet.data
    ? shares.mul(Math.round(exchangeRateRet.data * precision)).div(precision)
    : undefined;
};

export const useSharesFromTokenAssets = (
  token: LiquidityStrategyDepositToken,
  assets: BigNumber | undefined | null,
) => {
  const exchangeRateRet = useVaultDepositExchangeRate(
    token,
    assets ?? BigNumber.from(0),
  );

  const precision = 1_000_000;
  return assets && exchangeRateRet.data
    ? assets.mul(precision).div(Math.round(exchangeRateRet.data * precision))
    : undefined;
};

export const useDepositTokenBalances = (
  options?: UseQueryOptions<BigNumber>,
): Record<LiquidityStrategyDepositToken, UseQueryResult<BigNumber>> => {
  const croBalanceRet = useBalance();

  const wcroBalanceRet = useWcroBalance(options);

  const lcroBalanceRet = useLiquidCoinBalance({
    ...options,
    coinMode: 'cro',
  });

  const ferroLpBalanceRet = useFerroLpBalance({
    ...options,
    coinMode: 'cro',
  });

  return {
    cro: {
      ...croBalanceRet,
      data:
        typeof croBalanceRet.data === 'undefined'
          ? undefined
          : parseValue(String(croBalanceRet.data), 18),
    } as UseQueryResult<BigNumber>,
    wcro: wcroBalanceRet,
    lcro: lcroBalanceRet,
    ferroLP: ferroLpBalanceRet,
  };
};

export const useDepositTokenBalancesUsd = (): Record<
  LiquidityStrategyDepositToken,
  BigNumber | undefined
> => {
  const balances = useDepositTokenBalances();
  const croBalanceUsdRet = useCoinUsdValue(balances['cro'].data, 'cro');
  const wcroBalanceUsdRet = useWcroUsdValue(balances['wcro'].data);
  const lcroBalanceUsdRet = useLiquidCoinUsd({
    coinMode: 'cro',
    amount: balances['lcro'].data,
  });
  const ferroAmount = balances['ferroLP'].data;
  const ferroLpBalanceUsd = useLpUsdValue(
    'FERRO',
    ferroAmount ? formatUnits(ferroAmount) : '',
  );
  let ferroLpBalanceUsdBn: BigNumber | undefined = undefined;

  try {
    ferroLpBalanceUsdBn =
      typeof ferroLpBalanceUsd === 'number'
        ? parseValue(String(ferroLpBalanceUsd), 18)
        : undefined;
  } catch (e) {
    ferroLpBalanceUsdBn = BigNumber.from('0');
  }

  const dict: Record<LiquidityStrategyDepositToken, BigNumber | undefined> = {
    cro: croBalanceUsdRet.data,
    wcro: wcroBalanceUsdRet.data,
    lcro: lcroBalanceUsdRet.data,
    ferroLP: ferroLpBalanceUsdBn,
  };

  return dict;
};

export const useDepositTokenBalanceUsd = ({
  token,
  amount,
}: {
  token: LiquidityStrategyDepositToken;
  amount: BigNumber | undefined;
}): BigNumber | undefined => {
  const croBalanceUsdRet = useCoinUsdValue(amount, 'cro');

  const wcroBalanceUsdRet = useCoinUsdValue(amount, 'cro');

  const lcroBalanceUsdRet = useLiquidCoinUsd({
    coinMode: 'cro',
    amount,
  });

  const ferroLpBalanceUsd = useLpUsdValue(
    'FERRO',
    amount ? formatUnits(amount) : '',
  );

  let ferroLpBalanceUsdBn: BigNumber | undefined = undefined;

  try {
    ferroLpBalanceUsdBn =
      typeof ferroLpBalanceUsd === 'number'
        ? parseValue(String(ferroLpBalanceUsd), 18)
        : undefined;
  } catch (e) {
    ferroLpBalanceUsdBn = undefined;
  }

  const dict: Record<LiquidityStrategyDepositToken, BigNumber | undefined> = {
    cro: croBalanceUsdRet.data,
    wcro: wcroBalanceUsdRet.data,
    lcro: lcroBalanceUsdRet.data,
    ferroLP: ferroLpBalanceUsdBn,
  };

  return dict[token];
};

export const useCoinReturnedForUsdValue = ({
  usdAmount,
}: {
  usdAmount: BigNumber | undefined;
}): Record<LiquidityStrategyDepositToken, BigNumber | undefined> => {
  const croForOneUsd = useCoinUsdValue(
    BigNumber.from(expandDecimals(1, 18)),
    'cro',
  ).data;
  // const wcroForOneUsd = useWcroUsdValue(
  //   BigNumber.from(expandDecimals(1, 18)),
  // ).data;
  const lcroForOneUsd = useLiquidCoinUsd({
    coinMode: 'cro',
    amount: BigNumber.from(expandDecimals(1, 18)),
  }).data;
  const ferroLpForOneUsdNumber = useLpUsdValue(
    'FERRO',
    formatUnits(BigNumber.from(expandDecimals(1, 18))),
  );
  const ferroLpForOneUsd = numberToBigNumber(ferroLpForOneUsdNumber);

  const dict: Record<LiquidityStrategyDepositToken, BigNumber | undefined> = {
    cro: croForOneUsd
      ? usdAmount
          ?.mul(expandDecimals(1, 18))
          ?.div(croForOneUsd ?? BigNumber.from(0))
      : BigNumber.from(0),
    wcro: croForOneUsd
      ? usdAmount
          ?.mul(expandDecimals(1, 18))
          ?.div(croForOneUsd ?? BigNumber.from(0))
      : BigNumber.from(0),
    lcro: lcroForOneUsd
      ? usdAmount
          ?.mul(expandDecimals(1, 18))
          ?.div(lcroForOneUsd ?? BigNumber.from(0))
      : BigNumber.from(0),
    ferroLP: ferroLpForOneUsd
      ? usdAmount
          ?.mul(expandDecimals(1, 18))
          ?.div(ferroLpForOneUsd ?? BigNumber.from(0))
      : BigNumber.from(0),
  };

  return dict;
};

export const useStrategyApy = () => {
  return useStrategyApyQuery();
};

export const useOwnedStrategyDepositCoinAmount = (
  token: LiquidityStrategyDepositToken = 'cro',
  _coinMode?: CoinMode,
): BigNumber | undefined => {
  const globalCoinMode = useGlobalCoinMode();
  const enabled = (_coinMode ?? globalCoinMode) === 'cro';
  const vaultBalanceRet = useVaultBalance({ enabled });
  const exchangeRateRet = useVaultWithdrawExchangeRate(
    token,
    vaultBalanceRet.data ?? BigNumber.from(0),
    {
      enabled: enabled && !!vaultBalanceRet.data && vaultBalanceRet.data.gt(0),
    },
  );

  const result = useMemo(() => {
    if (vaultBalanceRet.data?.lte(0)) return BigNumber.from(0);
    const vaultBalanceNum =
      vaultBalanceRet.data && bigNumberToNumber(vaultBalanceRet.data);

    const receivedAmountFromWithdraw =
      vaultBalanceNum !== undefined && exchangeRateRet.data !== undefined
        ? vaultBalanceNum * exchangeRateRet.data
        : undefined;

    return receivedAmountFromWithdraw !== undefined
      ? numberToBigNumber(receivedAmountFromWithdraw)
      : undefined;
  }, [exchangeRateRet.data, vaultBalanceRet.data]);

  return result;
};

export const useStrategyTvl = (coinMode?: CoinMode) => {
  const sdk = useVenoSDK();

  // TODO update when we have an atom strategy
  return useQuery(['useStrategyTvl', coinMode], async (): Promise<number> => {
    return sdk.FerroVault.getTvl();
  });
};
