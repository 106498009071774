/* eslint-disable @typescript-eslint/no-explicit-any */
export const GA_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || '';

export function trackPageView(url: string): void {
  if ((window as any).dataLayer && GA_ID) {
    (window as any).dataLayer.push({
      event: 'pageview',
      page: url,
    });
  }
}
