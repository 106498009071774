import Info from '@ui/components/Info';
import { InfoTooltip } from '@ui/components/InfoTooltip';
import TextWithAbbreviateZeros from '@ui/components/TextWithAbbreviateZeros';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { useTranslations } from '@ui/i18n';
import { formatNumber } from '@ui/utils/format';
import type { GardenType } from '@ui/utils/garden';
import { gardenTokenDict } from '@ui/utils/garden';
import React, { useMemo } from 'react';

import AssetExplainer from './AssetExplainer';

const SupplyLcroModalCoinToReceive = ({
  coinMode,
  type,
  exchangeRateFetching,
  receiveAmount,
  receiveAmountUsd,
}: {
  coinMode: CoinMode;
  type: GardenType;
  exchangeRateFetching: boolean;
  receiveAmount: number | undefined;
  receiveAmountUsd: number | undefined;
}) => {
  const t = useTranslations();
  const assetName = gardenTokenDict[coinMode][type].name;
  const value = useMemo(() => {
    if (exchangeRateFetching) {
      return undefined;
    }
    if (!receiveAmount) return '0';

    return receiveAmount.toFixed(
      gardenTokenDict[coinMode][type].displayDecimals ?? 4,
    );
  }, [coinMode, exchangeRateFetching, receiveAmount, type]);
  return (
    <Info
      className="veno-tour__supply-modal-received-row"
      flexGrow={1}
      title={
        <>
          {t('{coin} to receive', {
            coin: assetName ?? '',
          })}
          <InfoTooltip
            title={gardenTokenDict[coinMode][type].name}
            label={
              <AssetExplainer
                coinMode={coinMode}
                explainText="TOKEN"
                type={type}
              />
            }
          />
        </>
      }
      value={
        value === undefined ? undefined : (
          <TextWithAbbreviateZeros
            abbreviateZeros={gardenTokenDict[coinMode][type].abbreviateZeros}
          >
            {value}
          </TextWithAbbreviateZeros>
        )
      }
      convertedValue={
        receiveAmountUsd ? `$${formatNumber(receiveAmountUsd, 2)}` : '--'
      }
    />
  );
};

const SupplyLcroModalCoinToReceiveMemo = React.memo(
  SupplyLcroModalCoinToReceive,
);

export default SupplyLcroModalCoinToReceiveMemo;
