import { useApy } from '@ui/hooks/useApr';
import useCoinPerStakedCoin from '@ui/hooks/useCoinPerStakedCoin';
import { useCoinUsdValue } from '@ui/hooks/useCoinUsdValue';
import { formatNumber } from '@ui/utils/format';
import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import { currentWallet } from '@veno-app/wallet';
import numbro from 'numbro';
import { useCallback, useMemo } from 'react';
import type { Step } from 'react-joyride';
import { useEvent } from 'react-use';
import { create } from 'zustand';

import { useIsDesktop } from '../MobileOrDesktop';
import { useStakingTourEnabled } from './utils/useStakingTourEnabled';
import { VenoTour } from './utils/VenoTour';

export const StakingTour = () => {
  const isZksync = currentWallet.useIsZksyncChainId();

  const enabled = useStakingTourEnabled();
  // only working on stake cro page and cronos chain;
  if (isZksync || !enabled) {
    return null;
  }

  return <StakingTourCore />;
};

const StakingTourCore = () => {
  const stepIndex = useStakingTour((s) => s.stepIndex);
  const inputAmountStr = useStakingTour((s) => s.inputAmountStr);
  const isDesktop = useIsDesktop();

  const { data: apy } = useApy();
  const apyStr = useMemo(() => {
    return `${(apy * 100).toFixed(2)}%`;
  }, [apy]);

  const lcroAmountStr = useLcroAmountStr(inputAmountStr);
  const { oneYearRewardsStr, oneYearUsdRewardsStr } = useApyInfo(
    inputAmountStr,
    apy,
  );

  const inputAmountNumbroStr = useMemo(() => {
    if (!inputAmountStr) {
      return '';
    }
    return numbro(inputAmountStr).format({
      thousandSeparated: true,
    });
  }, [inputAmountStr]);

  let steps: Step[] = [
    {
      target: '.veno-tour__stake-input',
      content: 'Enter how much CRO you want to stake.',
      hideFooter: lcroAmountStr ? false : true,
    },
    {
      target: '.veno-tour__stake-info',
      content: `You will receive ${lcroAmountStr} LCRO. LCRO continues to increase in value over time, earning you staking rewards.`,
      spotlightClicks: false,
    },
    {
      target: '.veno-tour__stake-button',
      content: 'Click “stake” to continue.',
    },
    {
      target: '.veno-tour__stake-modal-rewards',
      content: `You are staking ${inputAmountNumbroStr} CRO. Currently the estimated staking APY is ${apyStr}, estimated to earn you ${oneYearRewardsStr} CRO (${oneYearUsdRewardsStr}) rewards within 1 year.`,
      spotlightClicks: false,
    },
    {
      target: '.veno-tour__stake-modal-confirm-button',
      content: 'Review your inputs and click confirm staking.',
    },
    {
      target: 'body',
      content:
        'Confirm your transaction in your wallet and wait until the transaction has been confirmed on the blockchain.',
      placement: 'center',
    },
  ];

  if (isDesktop) {
    steps = [
      ...steps,
      {
        target: '.veno-tour__stake-modal-done-button',
        content: `Click "Done" to continue`,
      },
      {
        target: '.veno-tour__use-coin-balance',
        content: `Congratulations! You have successfully staked ${inputAmountNumbroStr} CRO and received ${lcroAmountStr} LCRO. Your LCRO is already earning rewards for you. Use your LCRO to earn additional rewards!`,
        spotlightClicks: false,
      },
    ];
  } else {
    steps = [
      ...steps,
      {
        target: '.veno-tour__stake-modal-use-coin-button',
        content: `Congratulations! You have successfully staked ${inputAmountNumbroStr} CRO and received ${lcroAmountStr} LCRO. Your LCRO is already earning rewards for you. Use your LCRO to earn additional rewards!`,
      },
    ];
  }

  const nextStepTarget = steps[stepIndex + 1]?.target;
  const currentStepTarget = steps[stepIndex]?.target;

  useEvent(
    'click',
    useCallback(
      (event: Event) => {
        if (
          event.target instanceof HTMLElement &&
          event.target.classList.contains(
            'veno-tour__stake-modal-done-button',
          ) &&
          nextStepTarget === 'veno-tour__use-coin-balance'
        ) {
          useStakingTour.getState().next();
        }
      },
      [nextStepTarget],
    ),
    typeof window === 'undefined' ? null : window.document,
  );

  useEvent(
    'click',
    useCallback(
      (event: Event) => {
        if (
          event.target instanceof HTMLElement &&
          event.target.classList.contains(
            'veno-tour__stake-modal-use-coin-button',
          ) &&
          currentStepTarget === '.veno-tour__stake-modal-use-coin-button'
        ) {
          useStakingTour.getState().next();
        }
      },
      [currentStepTarget],
    ),
    typeof window === 'undefined' ? null : window.document,
  );

  return (
    <VenoTour
      tourId="StakingTour"
      withWalletTour
      steps={steps}
      stepIndex={stepIndex}
      onStepIndexChange={(stepIndex, isFinished) => {
        if (isFinished) {
          useStakingTour.getState().$$resetData?.();
        } else {
          useStakingTour.setState({
            stepIndex: stepIndex,
          });
        }
      }}
    />
  );
};

const useLcroAmountStr = (inputAmountStr: string | undefined) => {
  const { data: coinPerStakedCoin } = useCoinPerStakedCoin('cro');
  return useMemo(() => {
    if (typeof coinPerStakedCoin === 'undefined' || !inputAmountStr) {
      return '';
    }
    const lcroAmountNum =
      Math.floor((100 * Number(inputAmountStr)) / coinPerStakedCoin) / 100;
    return numbro(lcroAmountNum).format({
      thousandSeparated: true,
      trimMantissa: true,
      mantissa: 2,
    });
  }, [coinPerStakedCoin, inputAmountStr]);
};

const useApyInfo = (
  inputAmountStr: string | undefined,
  apy: number | undefined,
) => {
  const oneYearCroRewards = Number(inputAmountStr ?? 0) * (apy ?? 0);
  const { data: oneYearUsdRewards } = useCoinUsdValue(oneYearCroRewards);

  return {
    oneYearUsdRewardsStr: `${formatNumber(oneYearUsdRewards ?? 0, 2)} USD`,
    oneYearRewardsStr: oneYearCroRewards.toFixed(2),
  };
};

type UseStakingTourStore = {
  inputAmountStr: string;
  stepIndex: number;
  next: () => void;
};

export const useStakingTour = create(
  resetWhenRouterChange<UseStakingTourStore>((set) => ({
    inputAmountStr: '',
    stepIndex: 0,
    next: () => {
      set((s) => {
        return {
          stepIndex: s.stepIndex + 1,
        };
      });
    },
  })),
);
