import { theme } from '@chakra-ui/react';
import { addAlpha, lintBrandColors, lintColors } from './utils/colors';
const { colors: colorHues } = theme;
const brand = lintBrandColors({
    black: '#17181A',
    white: '#FFFFFF',
    background: '#EBF0F3',
    pink: '#C42F74',
    pinkInDark: '#FF66AD',
    pinkLight: '#FFA7D0',
    green: '#01B08F',
    blue: '#66D3FF',
    primary: '#1D0063',
    inactive: '#6F609D',
    disabled: '#838588',
    success: '#01B08F',
    error: '#C50F0F',
    warning: '#BA8600',
    light: '#505050',
    shadow: '#B4C1D5',
    shadowPositive: 'rgba(180, 193, 213, 0.75)',
    shadowsLightPositive: 'rgba(255, 255, 255, 0.75)',
    gauze: 'rgba(105, 38, 69, 0.8)',
    garden: '#CFE8D4',
    atomLight: '#7459B4',
    atomMainColorLight: '#321579',
    tiaMainColorLight: '#8531EB',
    tiaTab: '#FFA7D0',
    tiaTabActive: '#FF66AD',
    transparentGrey: '#B4C1D5BF',
});
const vvs = lintBrandColors({
    aqua: '#46A1C2',
    lightAqua: '#F0F8F9',
    darkBlue: '#2C3851',
    grey: '#F4F5F1',
    textLight: '#60605F',
});
const pink = { ...colorHues.pink, 500: brand.pink };
const gray = { ...colorHues.gray, 500: brand.disabled };
const green = { ...colorHues.green, 500: brand.green };
const red = {
    ...colorHues.red,
    500: brand.error,
    ['500-80a']: addAlpha(brand.error, 0.8),
};
/**
 * @example
 * {
 *  text: {
 *    primary: gray[500],
 *    primary_dark: gray[100]
 *  },
 *  success: green[500],
 *  success_dark: green[800],
 * }
 */
export const colors = lintColors({
    white: brand.white,
    black: brand.black,
    gray,
    red,
    pink,
    green,
    brand,
    vvs: {
        primary: vvs.aqua,
        primaryLight: vvs.lightAqua,
        primaryLight_dark: '#16182B',
        darkBlue: vvs.darkBlue,
        darkBlue_dark: '#ffffff',
        bg: '#FFFFFF',
        bg_dark: '#1A1F34',
        textLight: vvs.textLight,
        textLight_dark: '#BABAB9',
    },
    bg: {
        main: brand.background,
        card: brand.background,
        card_dark: '#16182B',
        button: brand.primary,
        button_dark: brand.pink,
        buttonHover: brand.inactive,
        disabled: brand.disabled,
        gauze: brand.gauze,
        mobileAppBar: addAlpha(brand.background, 0.95),
        mobileAppBar_dark: addAlpha(brand.black, 0.95),
        garden: brand.garden,
        atom: brand.primary,
        tia: '#4B1191',
        cardVvs: 'white',
        cardVvs_dark: '#16182B',
    },
    text: {
        normal: brand.black,
        normal_dark: brand.white,
        light: brand.light,
        light_dark: addAlpha(brand.white, 0.75),
        active: brand.primary,
        active_dark: brand.white,
        inactive: brand.inactive,
        inactive_dark: addAlpha(brand.white, 0.75),
        disabled: brand.disabled,
        link: brand.pink,
        vvs: brand.black,
        vvs_dark: brand.white,
    },
    garden: {
        highlight: '#2565B0',
        highlight_dark: '#00FFCF',
        light: '#E8FFEA',
        dark: '#788E4D',
    },
    vaults: {
        month6: '#6666FF',
        month12: '#66D3FF',
        month24: '#FFA7D0',
        month48: '#692645',
    },
    success: brand.success,
    error: brand.error,
    warning: brand.warning,
    shadow: brand.shadow,
    /**
     * custom color scheme
     * see https://github.com/chakra-ui/chakra-ui/discussions/2846#discussioncomment-230531
     */
    primary: {
        50: brand.primary,
        100: brand.primary,
        200: brand.primary,
        300: brand.primary,
        400: brand.primary,
        500: brand.primary,
        600: brand.primary,
        700: brand.primary,
        800: brand.primary,
        900: brand.primary,
    },
});
