import { RarityRegistry__factory } from '@contracts';
import { VenoSDKModule } from './base';
import { wrapSdkTransaction } from './util';
export default class RarityRegistryModule extends VenoSDKModule {
    constructor() {
        super(...arguments);
        this.contractFactory = RarityRegistry__factory;
        this.contractAddress = this.sdk.addresses.cruiserRarityRegistry;
    }
    /**
     * @returns cronos cruiser rarity 0 = common, 1 = rare, 2 = epic, 3 = legendary
     */
    async getRarityLevel(tokenId) {
        return (await this.getContract().getRarityLevel(tokenId)).toNumber();
    }
    /**
     * @returns cronos cruiser rarity 0 = common, 1 = rare, 2 = epic, 3 = legendary
     */
    async getRarityLevels(tokenIds) {
        return await Promise.all(tokenIds.map((tokenId) => this.getRarityLevel(tokenId)));
    }
    /**
     * @returns cronos cruiser tier 1 == best, tier 4 == normal
     */
    async getTier(tokenId) {
        return (await this.getContract().getTier(tokenId)).toNumber();
    }
    /**
     * @returns cronos cruiser tier 1 == best, tier 4 == normal
     */
    async getTiers(tokenIds) {
        return await Promise.all(tokenIds.map((tokenId) => this.getTier(tokenId)));
    }
    async updateTier(tier, tokenId) {
        return wrapSdkTransaction(this.getContract().updateTier(tier, tokenId));
    }
}
