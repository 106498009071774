import {
  Button,
  Checkbox,
  Flex,
  Icon,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import type { BigNumberish } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import { useTranslations } from '@ui/i18n';
import estimateReward from '@ui/utils/estimateReward';
import { formatNumber, formatTokenAmount } from '@ui/utils/format';
import { formatLockPeriod } from '@ui/utils/formatLockPeriod';
import { useVaultSelectionStore } from '@ui/views/Vno/ReservoirAndFountain';
import { useStakeStore } from '@ui/views/Vno/ReservoirAndFountain/StakeCards';
import { colors } from '@veno-app/chakra';
import { ArrowDown, Check, Locked, Unlocked } from '@veno-app/icons';
import type { Stake } from '@veno-app/sdk';
import { currentWallet } from '@veno-app/wallet';
import { format } from 'date-fns';
import React, { useMemo, useState } from 'react';

import { Card } from '../Card';
import type { VaultStatus } from '../VaultCard';
import { VnoAprSkeleton } from '../VnoAprSkeleton';

type StakeCardProps = {
  isCheckBox?: boolean;
  currentTimestamp: number;
  status: VaultStatus;
  stake: Stake;
  allocation: BigNumberish;
  onWithdraw?: () => void;
};

const { useIsCronosChainId } = currentWallet;

const StakeCard: React.FC<StakeCardProps> = ({
  isCheckBox,
  currentTimestamp,
  status,
  stake,
  allocation,
  onWithdraw,
}) => {
  const t = useTranslations();

  const [expanded, setExpanded] = useState(false);
  const selectedStakeIds = useStakeStore((s) => s.selectedStakeIds);
  const onSelectedStakesChange = useStakeStore((s) => s.onChange);
  const vaultInfo = useVaultSelectionStore((s) => s.selectedVault);
  const vaultType = vaultInfo?.type ?? 'RESERVOIR';
  const isChecked = useMemo(
    () => selectedStakeIds.includes(Number(stake.stakeId)),
    [selectedStakeIds, stake],
  );
  const isCronos = useIsCronosChainId();

  const reward = estimateReward(
    Number(formatUnits(allocation, 18)),
    vaultInfo?.lockPeriod,
    vaultInfo?.apr,
  );

  const handleSelectStake = () => onSelectedStakesChange(Number(stake.stakeId));

  return (
    <Card
      mx={0}
      my={3}
      p="10px"
      gap={1}
      isActive={isCheckBox ? isChecked : expanded}
      onClick={(): void => {
        if (isCheckBox) {
          handleSelectStake();
        } else {
          if (!expanded) setExpanded(true);
        }
      }}
    >
      <Flex align="center" gap={1}>
        <Icon as={status === 'UNLOCKED' ? Unlocked : Locked} boxSize="24px" />
        <Text>
          {status === 'UNLOCKED'
            ? t('Unlocked')
            : t('{time} left', {
                time: formatLockPeriod(
                  stake.unlockTimestamp.sub(currentTimestamp).toNumber(),
                  { roughly: true, uppercase: false },
                ),
              })}
        </Text>
        {isCheckBox ? (
          <Checkbox
            ml="auto"
            icon={<Check color={colors.bg.card} />}
            isChecked={isChecked}
            pointerEvents="none"
          />
        ) : (
          <Icon
            as={ArrowDown}
            boxSize="18px"
            ml="auto"
            transform={`rotate(${expanded ? 180 : 0}deg)`}
            cursor="pointer"
            onClick={(): void => {
              if (expanded) setExpanded(false);
            }}
          />
        )}
      </Flex>
      <Text mt={1} textStyle="bodySmallBold" color="text.light">
        {formatTokenAmount(allocation, 2)} VNO
      </Text>
      {!isCheckBox && expanded && (
        <>
          <SimpleGrid columns={2} gap={2} mt={4} maxW={{ desktop: '410px' }}>
            <Text textStyle="bodySmall">{t('Unlock day')}</Text>
            <Text textStyle="bodySmall" color="text.light" align="end">
              {format(
                stake.unlockTimestamp.mul(1000).toNumber(),
                'dd MMM yyyy',
              )}
            </Text>
            <Text textStyle="bodySmall">{t('Estimated rewards')}</Text>
            {reward === null ? (
              <VnoAprSkeleton unavailableText="--" textStyle="bodySmall" />
            ) : (
              <Text textStyle="bodySmall" color="text.light" align="end">
                {formatNumber(reward, 2)}{' '}
                {vaultType === 'RESERVOIR' ? 'WCRO' : 'VNO'}
              </Text>
            )}
          </SimpleGrid>
          {status === 'UNLOCKED' && (
            <Button
              w={{ base: 'full', desktop: 'auto' }}
              mt={4}
              isDisabled={!onWithdraw}
              onClick={onWithdraw}
            >
              {t('Withdraw')}
            </Button>
          )}
          {isCronos && status !== 'UNLOCKED' && vaultType === 'FOUNTAIN' && (
            <Button
              w={{ base: 'full', desktop: 'auto' }}
              variant="secondary"
              mt={4}
              isDisabled={!onWithdraw}
              onClick={onWithdraw}
            >
              {t('Withdraw early with penalty')}
            </Button>
          )}
        </>
      )}
    </Card>
  );
};

export default StakeCard;
