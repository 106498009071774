/* eslint-disable max-lines */
import {
  Box,
  Button,
  DrawerCloseButton,
  Flex,
  Image,
  Link,
  SimpleGrid,
  Skeleton,
  Text,
  useConst,
} from '@chakra-ui/react';
import { formatUnits, parseUnits } from '@ethersproject/units';
import Balance from '@ui/components/Balance';
import { InputCard } from '@ui/components/InputCard';
import { InDesktop } from '@ui/components/MobileOrDesktop';
import {
  ModalOrDragDrawerContent,
  ModalOrDrawer,
  ModalOrDrawerBodyWithMask,
  ModalOrDrawerFooter,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from '@ui/components/ModalOrDrawer';
import { NoteBox } from '@ui/components/NoteBox';
import type { TransactionStatus } from '@ui/components/TransactionDrawer';
import {
  ReservoirDepositTransactionCompleted,
  TransactionError,
  TransactionPending,
} from '@ui/components/TransactionDrawer';
import DepositTransactionCompleted from '@ui/components/TransactionDrawer/DepositTransactionCompleted';
import { VnoAprSkeleton } from '@ui/components/VnoAprSkeleton';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { useCoinUsdValue } from '@ui/hooks/useCoinUsdValue';
import {
  useVenoFountainDeposit,
  useVenoReservoirDeposit,
} from '@ui/hooks/useVenoDeposit';
import useVnoBalance from '@ui/hooks/useVnoBalance';
import { useVnoUsdValue } from '@ui/hooks/useVnoUsdValue';
import { useTranslations } from '@ui/i18n';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { MAXW_DESKTOP_CTA } from '@ui/utils/constants';
import estimateReward from '@ui/utils/estimateReward';
import { formatNumber } from '@ui/utils/format';
import { formatLockPeriod } from '@ui/utils/formatLockPeriod';
import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import type { EthersError } from '@veno-app/sdk';
import { format } from 'date-fns';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { create } from 'zustand';

import { RESERVOIR_REWARD_TOKEN_DICT } from '../constants';
import { useVaultSelectionStore } from '../VaultSelectionStore';
import type { VaultInfo } from './types';
import { useVaultDetailDrawer } from './VaultDetailDrawer';

const INPUT_ERROR_BALANCE_LOW = 'VNO balance too low';

type DepositTransactionStatus = TransactionStatus | 'GET_VNO';

export const DepositDrawer: React.FC = () => {
  const t = useTranslations();
  const {
    nSteps,
    currentStep,
    handleDeposit,
    handleCloseAndReset,
    isOpen,
    error,
    transactionStatus,
    setTransactionStatus,
    inputError,
    setInputError,
    depositAmount,
    setDepositAmount,
    onDetailDrawerClose,
    type,
    lockPeriod,
    depositUsdAmount,
  } = useDepositDrawerData();

  const colorTheme = useDepositDrawer((s) => s.colorTheme);

  return (
    <ModalOrDrawer
      variant={colorTheme === 'gauze' ? 'wholeScreen' : undefined}
      isOpen={isOpen}
      onClose={handleCloseAndReset}
      scrollBehavior="inside"
      placement="bottom"
      size={{
        base: 'full',
        desktop: 'md',
      }}
    >
      <ModalOrDrawerOverlay
        bg={colorTheme === 'gauze' ? 'bg-gauze' : undefined}
      />
      <ModalOrDragDrawerContent>
        <ModalOrDrawerHeader>
          <>
            {transactionStatus === 'GET_VNO' && (
              <>
                {t('Get {coin}', {
                  coin: 'VNO',
                })}
              </>
            )}
            {transactionStatus !== 'GET_VNO' && (
              <>
                {t('Deposit in {platform}', {
                  platform:
                    (lockPeriod
                      ? formatLockPeriod(lockPeriod, { roughly: true })
                      : '') +
                    ' ' +
                    (type === 'RESERVOIR' ? 'Reservoir' : 'Fountain'),
                })}
              </>
            )}
          </>
        </ModalOrDrawerHeader>
        <InDesktop>
          <DrawerCloseButton />
        </InDesktop>
        <ModalOrDrawerBodyWithMask>
          <DepositContent
            transactionStatus={transactionStatus}
            handleCloseAndReset={handleCloseAndReset}
            error={error}
            inputError={inputError}
            setInputError={setInputError}
            depositAmount={depositAmount}
            onDepositAmountChange={setDepositAmount}
            nSteps={nSteps}
            currentStep={currentStep}
            depositUsdAmount={depositUsdAmount}
          />
        </ModalOrDrawerBodyWithMask>
        <ModalOrDrawerFooter>
          <DepositFooter
            setTransactionStatus={setTransactionStatus}
            transactionStatus={transactionStatus}
            onCloseDepositDrawer={() => {
              handleCloseAndReset();
            }}
            inputError={inputError}
            onDeposit={handleDeposit}
            disableDeposit={
              !depositAmount ||
              (!!inputError && inputError !== INPUT_ERROR_BALANCE_LOW)
            }
          />
        </ModalOrDrawerFooter>
      </ModalOrDragDrawerContent>
    </ModalOrDrawer>
  );
};

const useDepositDrawerData = () => {
  const onDetailDrawerClose = useVaultDetailDrawer((s) => s.onClose);
  const { isOpen, onClose, vaultInfo: vaultInfoFromStore } = useDepositDrawer();
  const [depositAmount, setDepositAmount] = useState('');
  const [inputError, setInputError] = useState<string>('');
  const { data: depositUsdAmount } = useVnoUsdValue(
    depositAmount && !inputError ? parseFloat(depositAmount) : undefined,
  );
  const [transactionStatus, setTransactionStatus] =
    useState<DepositTransactionStatus>('CONFIRM');
  const [error, setError] = useState<EthersError | null>(null);
  const handleCloseAndReset = (): void => {
    onClose();
    setDepositAmount('');
    setError(null);
    // reset selected vault on success, because data will be outdated
    if (transactionStatus === 'COMPLETED') resetVault();
    setTransactionStatus('CONFIRM');
  };
  const selectedVaultInfo = useVaultSelectionStore((s) => s.selectedVault);
  const vaultInfo = vaultInfoFromStore || selectedVaultInfo;
  const type = vaultInfo?.type ?? 'RESERVOIR';
  const resetVault = useVaultSelectionStore((s) => s.resetSelectedVault);
  const {
    mutateAsync: reservoirDeposit,
    nSteps: nStepsReservoir,
    currentStep: currentStepReservoir,
  } = useVenoReservoirDeposit();
  const {
    mutateAsync: fountainDeposit,
    nSteps: nStepsFountain,
    currentStep: currentStepFountain,
  } = useVenoFountainDeposit();
  const { deposit, nSteps, currentStep } = useMemo(() => {
    switch (type) {
      case 'RESERVOIR':
        return {
          deposit: reservoirDeposit,
          nSteps: nStepsReservoir,
          currentStep: currentStepReservoir,
        };

      case 'FOUNTAIN':
      default:
        return {
          deposit: fountainDeposit,
          nSteps: nStepsFountain,
          currentStep: currentStepFountain,
        };
    }
  }, [
    currentStepFountain,
    currentStepReservoir,
    fountainDeposit,
    nStepsFountain,
    nStepsReservoir,
    reservoirDeposit,
    type,
  ]);

  const handleDeposit = useCallback(async () => {
    const isPidNotSet = vaultInfo?.pid === null || vaultInfo?.pid === undefined;
    if (isPidNotSet || !depositAmount) return;

    setTransactionStatus('PENDING');
    try {
      await deposit({
        pid: vaultInfo.pid,
        amount: parseUnits(depositAmount, 18),
      });
    } catch (err) {
      setError(err as EthersError);
      setTransactionStatus('ERROR');
      return;
    }
    setTransactionStatus('COMPLETED');
  }, [depositAmount, deposit, vaultInfo?.pid]);
  const lockPeriod = vaultInfo?.lockPeriod;
  return {
    nSteps,
    currentStep,
    handleDeposit,
    handleCloseAndReset,
    isOpen,
    error,
    transactionStatus,
    inputError,
    setInputError,
    depositAmount,
    setTransactionStatus,
    setDepositAmount,
    onDetailDrawerClose,
    type,
    lockPeriod,
    depositUsdAmount,
  };
};

interface DepositContentProps {
  transactionStatus: DepositTransactionStatus;
  handleCloseAndReset: () => void;
  error: EthersError | null;
  inputError: string;
  setInputError: Dispatch<string>;
  depositAmount: string;
  onDepositAmountChange: (amount: string) => void;
  nSteps: number;
  currentStep: number;
  depositUsdAmount?: number;
}
const DepositContent = ({
  transactionStatus,
  error,
  inputError,
  setInputError,
  depositAmount,
  nSteps,
  currentStep,
  onDepositAmountChange,
  depositUsdAmount,
}: DepositContentProps) => {
  const t = useTranslations();
  const sdk = useVenoSDK();

  const currentTimestamp = useConst(() => Math.trunc(Date.now() / 1000));
  const vaultInfoFromStore = useDepositDrawer((s) => s.vaultInfo);
  const selectedVaultInfo = useVaultSelectionStore((s) => s.selectedVault);
  const vaultInfo = vaultInfoFromStore || selectedVaultInfo;
  const { vnoBalance } = useVnoBalance();
  const { data: croPrice } = useCoinUsdValue(1);
  const { data: vnoPrice } = useVnoUsdValue(1);

  const maxDepositAmount = vnoBalance
    ? Math.floor(100_000_000 * parseFloat(formatUnits(vnoBalance, 18) ?? '0')) /
      100_000_000
    : undefined;

  const { data: maxDepositUsd } = useVnoUsdValue(maxDepositAmount);

  const reward = estimateReward(
    Number(depositAmount),
    vaultInfo?.lockPeriod,
    vaultInfo?.apr,
  );

  const rewardInUsd = useMemo(() => {
    if (vaultInfo?.type === 'RESERVOIR') {
      return reward && croPrice ? reward * croPrice : null;
    }
    if (vaultInfo?.type === 'FOUNTAIN') {
      return reward && vnoPrice ? reward * vnoPrice : null;
    }
    return null;
  }, [croPrice, reward, vaultInfo?.type, vnoPrice]);

  const handleInputChange = useCallback(
    (value: string) => {
      if (value && !/^[0-9]+\.?[0-9]*$/.test(value)) {
        setInputError(t('invalid format'));
      } else if (parseFloat(value) < 1.0) {
        setInputError(
          t('minimum value is {amount} {coin}', {
            amount: 1,
            coin: 'VNO',
          }),
        );
      } else if (
        maxDepositAmount !== undefined &&
        parseFloat(value) > maxDepositAmount
      ) {
        setInputError(INPUT_ERROR_BALANCE_LOW);
      } else {
        setInputError('');
      }
      onDepositAmountChange(value);
    },
    [maxDepositAmount, onDepositAmountChange, setInputError, t],
  );

  if (!vaultInfo) return <></>;

  switch (transactionStatus) {
    case 'COMPLETED':
      return (
        <DepositTransactionCompleted.Content
          amount={parseFloat(depositAmount || '0')}
          lockPeriod={vaultInfo.lockPeriod}
          type={vaultInfo.type}
        />
      );
    case 'PENDING':
      return (
        <TransactionPending.Content nSteps={nSteps} currentStep={currentStep} />
      );
    case 'ERROR':
      return <TransactionError.Content error={error} />;
    case 'GET_VNO':
      return (
        <Flex direction="column" alignItems="center" px={4}>
          <Image alt="logo" src="/tokens/vno.svg" w="50px" h="50px" />
          <Text textAlign="center" color="text-light" mt="10px">
            {t('buy-direction-{platform}-{coin}', {
              platform: 'VVS Finance',
              coin: 'VNO',
            })}
          </Text>
          <Text
            mt="20px"
            color="text-light"
            textStyle="bodySmall"
            textAlign="center"
          >
            {t('buy-coin-risk-{platform}', {
              platform: 'VVS Finance',
            })}
            <br />
            {t('More info can be found here {Link}', {
              Link: (chunks) => (
                <Link
                  href={EXTERNAL_LINK.vvsLanding}
                  color="pink.500"
                  isExternal
                >
                  {chunks}
                </Link>
              ),
            })}
          </Text>
        </Flex>
      );
    default:
      return (
        <Flex direction="column" gap={4}>
          <Balance
            title={t('Balance')}
            icon={<Image alt="VNO" src="/tokens/vno.svg" />}
            amount={maxDepositAmount}
            usdAmount={maxDepositUsd}
            showUsdAmount={true}
          />
          <InputCard
            m={0}
            label={t('Input {coin} amount', {
              coin: 'VNO',
            })}
            max={maxDepositAmount}
            errorMessage={inputError}
            value={depositAmount}
            onChange={handleInputChange}
            usdValue={depositUsdAmount}
          />
          {depositAmount && !inputError && (
            <NoteBox desc={t('note-vault-apr')} />
          )}
          <SimpleGrid mt="14px" columns={2} width="full" gap={4}>
            <Text>APR</Text>
            {vaultInfo.apr !== null ? (
              <Text textStyle="bodyBold" align="end">
                {formatNumber(vaultInfo.apr * 100, 2)}%
              </Text>
            ) : (
              <VnoAprSkeleton />
            )}
            <Text>Multiplier</Text>
            <Text textStyle="bodyBold" align="end">
              {`${(vaultInfo.multiplier / 100).toFixed(0)}x`}
            </Text>
            {!!currentTimestamp && !!vaultInfo.lockPeriod && (
              <>
                <Text>{t('Locked end date')}</Text>
                <Text textStyle="bodyBold" align="end">
                  {format(
                    (currentTimestamp + vaultInfo.lockPeriod) * 1000,
                    'dd MMM yyyy',
                  )}
                </Text>
              </>
            )}
            <Text>{t('Estimated rewards')}</Text>
            <Box justifySelf="end">
              {reward === null ? (
                <Skeleton
                  ml="auto"
                  w={vaultInfo?.apr === undefined ? '36px' : 0}
                  h="20px"
                />
              ) : (
                <Text textStyle="bodyBold">
                  {formatNumber(reward)}{' '}
                  {vaultInfo.type === 'RESERVOIR'
                    ? RESERVOIR_REWARD_TOKEN_DICT[sdk.chainType].name
                    : 'VNO'}
                </Text>
              )}
              {rewardInUsd === null ? null : (
                <Text textStyle="bodySmall" color="text.light" align="end">
                  {formatNumber(rewardInUsd)} USD
                </Text>
              )}
            </Box>
          </SimpleGrid>
          <Text
            hidden={vaultInfo.type !== 'RESERVOIR'}
            textStyle="bodySmall"
            color="text-light"
          >
            {t(
              'You are helping to insure the protocol The deposited VNO will be used to offset losses in case of of a drought event',
            )}{' '}
            <Link
              href={EXTERNAL_LINK.gitbookReservoir}
              isExternal
              color="text-link"
            >
              {t('Learn more')}
            </Link>
            .
          </Text>
        </Flex>
      );
  }
};

interface DepositFooterProps {
  transactionStatus: DepositTransactionStatus;
  onCloseDepositDrawer: () => void;
  onDeposit: () => void;
  disableDeposit?: boolean;
  inputError?: string;
  setTransactionStatus: Dispatch<SetStateAction<DepositTransactionStatus>>;
}
const DepositFooter: React.FC<DepositFooterProps> = ({
  transactionStatus,
  onCloseDepositDrawer,
  onDeposit,
  disableDeposit,
  setTransactionStatus,
  inputError,
}) => {
  const t = useTranslations();
  const isBalanceLowError = inputError === INPUT_ERROR_BALANCE_LOW;

  switch (transactionStatus) {
    case 'COMPLETED':
      return (
        <ReservoirDepositTransactionCompleted.Footer
          onCloseDrawer={onCloseDepositDrawer}
        />
      );
    case 'PENDING':
      return <TransactionPending.Footer />;
    case 'ERROR':
      return null;
    case 'GET_VNO':
      return (
        <Button as="a" flexGrow="1" href={EXTERNAL_LINK.vvs} target="_blank">
          {t('Confirm')}
        </Button>
      );
    default:
      return (
        <Button
          disabled={disableDeposit}
          onClick={
            isBalanceLowError
              ? () => setTransactionStatus('GET_VNO')
              : onDeposit
          }
          mx={{ desktop: 'auto' }}
          w={{ desktop: MAXW_DESKTOP_CTA }}
        >
          {isBalanceLowError ? (
            <>
              {t('Buy {coin} now', {
                coin: 'VNO',
              })}
            </>
          ) : (
            <>
              {t('Lock {coin} now', {
                coin: 'VNO',
              })}
            </>
          )}
        </Button>
      );
  }
};

type UseVaultDepositDrawer = {
  isOpen: boolean;
  vaultInfo: VaultInfo | null;
  colorTheme: 'default' | 'gauze';
  onOpen: (options?: {
    vaultInfo?: VaultInfo;
    colorTheme?: 'default' | 'gauze';
  }) => void;
  onClose: () => void;
};
export const useDepositDrawer = create(
  resetWhenRouterChange<UseVaultDepositDrawer>((set) => ({
    isOpen: false,
    vaultInfo: null,
    colorTheme: 'default',
    onOpen: ({ vaultInfo, colorTheme } = {}): void => {
      set(() => ({
        isOpen: true,
        vaultInfo,
        colorTheme,
      }));
    },
    onClose: () => {
      useDepositDrawer.getState().$$resetData?.();
    },
  })),
);
