import { TToken__factory } from '@contracts';
import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { LATOM_DECIMALS, TLATOM_DECIMALS } from './constants';
import LiquidAtomModule from './liquidAtom';
import TLcroModule from './tLcro';
import { getAtomUsdPrice, getLiquidTokenUsdPrice } from './util';
export default class TLatomModule extends TLcroModule {
    constructor() {
        super(...arguments);
        this.contractFactory = TToken__factory;
        this.contractAddress = this.sdk.addresses.tLatom;
        this.LiquidAtom = new LiquidAtomModule(this.sdk);
    }
    async getLpCoinPerLiquidCoin() {
        const exchangeRate = await this.getExchangeRate();
        const exchangeRateDecimals = LATOM_DECIMALS + 10;
        return (BigNumber.from(10)
            .pow(exchangeRateDecimals)
            .mul(100000000)
            .div(exchangeRate)
            .toNumber() / 100000000);
    }
    async getTLatomUsdWorth(amount) {
        if (amount.lte(0))
            return BigNumber.from(0);
        const [atomUsdPrice, atomPerLatom, tlatomPerLatom] = await Promise.all([
            getAtomUsdPrice(),
            this.LiquidAtom.atomPerLatom(),
            this.getLpCoinPerLiquidCoin(),
        ]);
        if (!atomUsdPrice || !atomPerLatom)
            return BigNumber.from(0);
        const latomEquivalent = parseFloat(formatUnits(amount, TLATOM_DECIMALS)) / tlatomPerLatom;
        const latomUsdPrice = getLiquidTokenUsdPrice(atomUsdPrice, atomPerLatom);
        return parseUnits((latomEquivalent * latomUsdPrice).toString(), TLATOM_DECIMALS);
    }
}
