import type { FlexProps } from '@chakra-ui/react';
import { Flex, Link, Text } from '@chakra-ui/react';
import { useExplorerLabel, useExplorerUrl } from '@ui/hooks/useExplorer';
import { useTranslations } from '@ui/i18n';
import { currentWallet } from '@veno-app/wallet';
import React from 'react';

import AddTokenButton, { TokenType } from '../AddTokenButton';
import {
  TRANSACTION_TYPE_FERRO_IN,
  TRANSACTION_TYPE_FERRO_OUT,
  TRANSACTION_TYPE_REDEEM,
  TRANSACTION_TYPE_STAKE,
  TRANSACTION_TYPE_TECTONIC_IN,
  TRANSACTION_TYPE_TECTONIC_OUT,
  TRANSACTION_TYPE_UNSTAKE,
  TRANSACTION_TYPE_VVS_IN,
  TRANSACTION_TYPE_VVS_OUT,
} from './transactionConstants';
import type { Transaction } from './useTransactionHistory';

const { useSupportedChainId } = currentWallet;

type TransactionDetailContentProps = FlexProps & {
  transaction: Transaction;
};

function TransactionDetailContent({
  transaction,
  ...flexProps
}: TransactionDetailContentProps) {
  const t = useTranslations();
  const explorerLabel = useExplorerLabel();
  const explorerUrl = useExplorerUrl();
  const supportedChainId = useSupportedChainId();
  return (
    <Flex flexDir="column" gap={4} {...flexProps}>
      <Text
        color={{ base: 'text-light', desktop: 'text.normal' }}
        textStyle="h4"
        mb={{ base: 1, desktop: 0 }}
      >
        {t('Transaction details')}
      </Text>
      <Flex justify="space-between">
        <Text textStyle={{ base: 'body', desktop: 'h4' }}>
          {t('Wallet address')}
        </Text>
        <Text
          fontWeight="bolder"
          textStyle={{ base: 'body', desktop: 'h4Bold' }}
        >
          {transaction.walletAddress}
        </Text>
      </Flex>
      <Flex justify="space-between">
        <Text textStyle={{ base: 'body', desktop: 'h4' }}>
          {t('Exchange rate')}
        </Text>
        <Text
          fontWeight="bolder"
          textStyle={{ base: 'body', desktop: 'h4Bold' }}
        >
          1 LCRO = {transaction.croPerLcro.toFixed(5)} CRO
        </Text>
      </Flex>
      <TransactionSpecificValues transaction={transaction} />
      {supportedChainId && (
        <Link href={`${explorerUrl}/tx/${transaction.txnHash}`} isExternal>
          {explorerLabel}
        </Link>
      )}
    </Flex>
  );
}

const TransactionSpecificValues = ({
  transaction,
}: {
  transaction: Transaction;
}) => {
  const t = useTranslations();
  switch (transaction.type) {
    case TRANSACTION_TYPE_STAKE:
      return (
        <Flex justify="space-between">
          <Text textStyle={{ base: 'body', desktop: 'h4' }}>
            {t('{coin} received', {
              coin: 'LCRO',
            })}
          </Text>
          <Text
            fontWeight="bolder"
            textStyle={{ base: 'body', desktop: 'h4Bold' }}
            wordBreak="break-all"
          >
            {transaction.lcroAmount.toFixed(4)}
          </Text>
        </Flex>
      );
    case TRANSACTION_TYPE_UNSTAKE:
      return (
        <Flex justify="space-between">
          <Text textStyle={{ base: 'body', desktop: 'h4' }}>
            {t('{coin} sent', {
              coin: 'LCRO',
            })}
          </Text>
          <Text
            fontWeight="bolder"
            textStyle={{ base: 'body', desktop: 'h4Bold' }}
            wordBreak="break-all"
          >
            {transaction.lcroAmount.toFixed(4)}
          </Text>
        </Flex>
      );
    case TRANSACTION_TYPE_REDEEM:
      return (
        <Flex justify="space-between">
          <Text textStyle={{ base: 'body', desktop: 'h4' }}>
            {t('{coin} received', {
              coin: 'CRO',
            })}
          </Text>
          <Text
            fontWeight="bolder"
            textStyle={{ base: 'body', desktop: 'h4Bold' }}
            wordBreak="break-all"
          >
            {transaction.croAmount.toFixed(4)}
          </Text>
        </Flex>
      );
    case TRANSACTION_TYPE_FERRO_IN:
    case TRANSACTION_TYPE_VVS_IN:
      return (
        <>
          <Flex justify="space-between">
            <Text textStyle={{ base: 'body', desktop: 'h4' }}>
              {t('{coin} transferred out', {
                coin: 'CRO',
              })}
            </Text>
            <Text
              fontWeight="bolder"
              textStyle={{ base: 'body', desktop: 'h4Bold' }}
              wordBreak="break-all"
            >
              {transaction.croAmount.toFixed(4)}
            </Text>
          </Flex>
          <Flex justify="space-between">
            <Text textStyle={{ base: 'body', desktop: 'h4' }}>
              {t('{coin} transferred out', {
                coin: 'LCRO',
              })}
            </Text>
            <Text
              fontWeight="bolder"
              textStyle={{ base: 'body', desktop: 'h4Bold' }}
              wordBreak="break-all"
            >
              {transaction.lcroAmount.toFixed(4)}
            </Text>
          </Flex>
          <AddTokenButton
            tokenType={
              transaction.type === TRANSACTION_TYPE_FERRO_IN
                ? TokenType.FERRO_LP
                : TokenType.VVS_CRO_LCRO_LP
            }
            text={t('Add {coin} token to wallet', {
              coin: 'LP',
            })}
          />
        </>
      );
    case TRANSACTION_TYPE_FERRO_OUT:
    case TRANSACTION_TYPE_VVS_OUT:
      return (
        <>
          <Flex justify="space-between">
            <Text textStyle={{ base: 'body', desktop: 'h4' }}>
              {t('{coin} received', {
                coin: 'CRO',
              })}
            </Text>
            <Text
              fontWeight="bolder"
              textStyle={{ base: 'body', desktop: 'h4Bold' }}
              wordBreak="break-all"
            >
              {transaction.croAmount.toFixed(4)}
            </Text>
          </Flex>
          <Flex justify="space-between">
            <Text textStyle={{ base: 'body', desktop: 'h4' }}>
              {t('{coin} received', {
                coin: 'LCRO',
              })}
            </Text>
            <Text
              fontWeight="bolder"
              textStyle={{ base: 'body', desktop: 'h4Bold' }}
              wordBreak="break-all"
            >
              {transaction.lcroAmount.toFixed(4)}
            </Text>
          </Flex>
          <AddTokenButton
            tokenType={
              transaction.type === TRANSACTION_TYPE_FERRO_OUT
                ? TokenType.FERRO_LP
                : TokenType.VVS_CRO_LCRO_LP
            }
            text={t('Add {coin} token to wallet', {
              coin: 'LP',
            })}
          />
        </>
      );
    case TRANSACTION_TYPE_TECTONIC_IN:
      return (
        <>
          <Flex justify="space-between">
            <Text textStyle={{ base: 'body', desktop: 'h4' }}>
              {t('{coin} transferred out', {
                coin: 'LCRO',
              })}
            </Text>
            <Text
              fontWeight="bolder"
              textStyle={{ base: 'body', desktop: 'h4Bold' }}
              wordBreak="break-all"
            >
              {transaction.lcroAmount.toFixed(4)}
            </Text>
          </Flex>
          <AddTokenButton
            tokenType={TokenType.TLCRO}
            text={t('Add {coin} token to wallet', {
              coin: 'tLCRO',
            })}
          />
        </>
      );
    case TRANSACTION_TYPE_TECTONIC_OUT:
      return (
        <>
          <Flex justify="space-between">
            <Text textStyle={{ base: 'body', desktop: 'h4' }}>
              {t('{coin} received', {
                coin: 'LCRO',
              })}
            </Text>
            <Text
              fontWeight="bolder"
              textStyle={{ base: 'body', desktop: 'h4Bold' }}
              wordBreak="break-all"
            >
              {transaction.lcroAmount.toFixed(4)}
            </Text>
          </Flex>
          <AddTokenButton
            tokenType={TokenType.TLCRO}
            text={t('Add {coin} token to wallet', {
              coin: 'tLCRO',
            })}
          />
        </>
      );
    default:
      return <></>;
  }
};

export default TransactionDetailContent;
