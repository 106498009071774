import { Button, CloseButton, Drawer, Icon, Menu, Modal, ModalOverlay, Spinner, } from '@chakra-ui/react';
// eslint-disable-next-line unused-imports/no-unused-imports
import React from 'react';
Modal.defaultProps = {
    ...Modal.defaultProps,
    autoFocus: false,
    closeOnOverlayClick: false,
    // if true, close modal or drawer will make page flickering
    returnFocusOnClose: false,
};
ModalOverlay.defaultProps = {
    // the overlay of highest modal or drawer should overlay the others
    zIndex: 'modal',
};
Button.defaultProps = {
    ...Button.defaultProps,
    spinner: React.createElement(Spinner, { color: "currentColor", width: "23px", height: "23px" }),
};
Drawer.defaultProps = {
    ...Drawer.defaultProps,
    autoFocus: false,
    closeOnOverlayClick: true,
    returnFocusOnClose: false,
};
CloseButton.defaultProps = {
    children: (React.createElement(Icon, { focusable: "false", "aria-hidden": true, width: "1em", height: "1em", viewBox: "0 0 24 24" },
        React.createElement("path", { d: "M11.875 12.56l-5.143 5.144a.446.446 0 01-.331.16.417.417 0 01-.355-.16.44.44 0 01-.171-.343.44.44 0 01.171-.343l5.143-5.143-5.143-5.143a.443.443 0 01-.158-.331.415.415 0 01.158-.355.44.44 0 01.343-.171.44.44 0 01.343.171l5.143 5.143 5.143-5.143a.445.445 0 01.33-.16.419.419 0 01.356.16.44.44 0 01.171.343.44.44 0 01-.171.343l-5.143 5.143 5.143 5.143a.445.445 0 01.16.33.419.419 0 01-.16.356.44.44 0 01-.343.171.44.44 0 01-.343-.171l-5.143-5.143z", fill: "currentColor" }))),
};
Menu.defaultProps = {
    ...Menu.defaultProps,
    autoSelect: false,
};
