import { useEffect } from 'react';
export const viewportWidthVariable = '--viewport-width';
export const viewportHeightVariable = '--viewport-height';
/**
 * Fix missing modal footer on iOS.
 * @see https://github.com/chakra-ui/chakra-ui/issues/4903
 */
export const useInjectedCssVarOfViewport = ({ enabled = true, } = {}) => {
    useEffect(() => {
        if (!enabled) {
            return;
        }
        const handleResize = () => {
            const viewport = {
                width: window.innerWidth,
                height: window.innerHeight,
            };
            const documentStyle = window.document?.documentElement?.style;
            if (!documentStyle) {
                return;
            }
            if (viewport.width) {
                documentStyle.setProperty(viewportWidthVariable, `${viewport.width}px`);
            }
            else {
                documentStyle.removeProperty(viewportWidthVariable);
            }
            if (viewport.height) {
                documentStyle.setProperty(viewportHeightVariable, `${viewport.height}px`);
            }
            else {
                documentStyle.removeProperty(viewportHeightVariable);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            const documentStyle = window.document?.documentElement?.style;
            if (documentStyle) {
                documentStyle.removeProperty(viewportWidthVariable);
                documentStyle.removeProperty(viewportHeightVariable);
            }
            window.removeEventListener('resize', handleResize);
        };
    }, [enabled]);
};
