import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import { create } from 'zustand';

import type { VaultInfo } from './ReservoirAndFountain/types';

type VaultSelectionStore = {
  selectedVault: VaultInfo | null;
  onVaultSelect: (vault: VaultInfo) => void;
  resetSelectedVault: () => void;
};
export const useVaultSelectionStore = create<VaultSelectionStore>(
  resetWhenRouterChange((set) => ({
    selectedVault: null,
    onVaultSelect: (vault): void =>
      set(() => ({
        selectedVault: vault,
      })),
    resetSelectedVault: () => set(() => ({ selectedVault: null })),
  })),
);
