import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { getVvsTiaLpBalance } from '@ui/queries/sdk';
import type { GardenType } from '@ui/utils/garden';

import useFerroLpBalance from './useFerroLpBalance';
import useTLcroBalance from './useTLcroBalance';
import useVvsCroLpBalance from './useVvsCroLpBalance';
import useVvsLpBalance from './useVvsLpBalance';

const useTotalGardenDeposit = (gardenType: GardenType) => {
  const sdk = useVenoSDK();
  const { tlcroBalance: tlcroTotalBalance } = useTLcroBalance(
    {
      enabled: gardenType === 'TECTONIC',
    },
    sdk.addresses.venoStorm,
  );
  const { ferroLpBalance: ferroTotalBalance } = useFerroLpBalance(
    {
      enabled: gardenType === 'FERRO',
    },
    sdk.addresses.venoStorm,
  );

  const { vvsLpBalance: vvsTotalBalance } = useVvsLpBalance(
    { enabled: gardenType === 'VVS' },
    sdk.addresses.venoStorm,
  );

  const { vvsLpBalance: vvsTiaTotalBalance } = useVvsLpBalance(
    { enabled: gardenType === 'VVS_TIA' },
    sdk.addresses.venoStorm,
    getVvsTiaLpBalance,
  );

  const { vvsCroLpBalance: vvsCroTotalBalance } = useVvsCroLpBalance(
    { enabled: gardenType === 'VVS_CRO' },
    sdk.addresses.venoStorm,
  );

  let totalBalance = ferroTotalBalance;
  switch (gardenType) {
    case 'VVS':
      totalBalance = vvsTotalBalance;
      break;
    case 'VVS_CRO':
      totalBalance = vvsCroTotalBalance;
      break;
    case 'VVS_TIA':
      totalBalance = vvsTiaTotalBalance;
      break;
    case 'TECTONIC':
      totalBalance = tlcroTotalBalance;
      break;
  }

  return { totalBalance };
};

export default useTotalGardenDeposit;
