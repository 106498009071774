import type { VaultType } from '@ui/views/Vno/ReservoirAndFountain/types';

import { useFountainPoolInfos } from './useFountainPoolInfos';
import { useReservoirPoolInfos } from './useReservoirPoolInfos';

export const usePoolInfos = (type: VaultType) => {
  const { pools: reservoirPools, isLoading: reservoirPoolsLoading } =
    useReservoirPoolInfos(type === 'RESERVOIR');
  const { pools: fountainPools, isLoading: fountainPoolsLoading } =
    useFountainPoolInfos(type === 'FOUNTAIN');

  switch (type) {
    case 'RESERVOIR':
      return { pools: reservoirPools, isLoading: reservoirPoolsLoading };
    case 'FOUNTAIN':
      return { pools: fountainPools, isLoading: fountainPoolsLoading };
  }
};
