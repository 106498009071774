import { Button, DrawerBody, DrawerFooter, Flex, Text } from '@chakra-ui/react';
import { pathDict } from '@ui/config/paths';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { COIN_MODE } from '@ui/hooks/useCoinMode';
import { useTranslations } from '@ui/i18n';
import NextLink from '@ui/overrides/next/link';

type GetAssetByStakingContentProps = {
  i18nkey: string;
  coinMode: CoinMode;
};

export const GetAssetByStakingContent = ({
  i18nkey,
  coinMode,
}: GetAssetByStakingContentProps) => {
  const t = useTranslations();
  const coinModeInfo = COIN_MODE[coinMode];
  return (
    <>
      <DrawerBody>
        <Flex direction="column" alignItems="center">
          <coinModeInfo.LiquidIcon w="50px" h="50px" />
          <Text textAlign="center" color="text-light" mt="10px">
            {t(i18nkey)}
          </Text>
        </Flex>
      </DrawerBody>
      <DrawerFooter>
        <NextLink href={pathDict[coinMode].stake} passHref>
          <Button as="a">
            {t('Stake your {coin}', {
              coin: coinModeInfo.name,
            })}
          </Button>
        </NextLink>
      </DrawerFooter>
    </>
  );
};
