import {
  Button,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Switch,
  Text,
  useConst,
} from '@chakra-ui/react';
import { InDesktop } from '@ui/components/MobileOrDesktop';
import {
  ModalOrDragDrawerContent,
  ModalOrDrawer,
  ModalOrDrawerBodyWithMask,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from '@ui/components/ModalOrDrawer';
import { StakeCard } from '@ui/components/StakeCard';
import { useTranslations } from '@ui/i18n';
import { formatLockPeriod } from '@ui/utils/formatLockPeriod';
import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import type { Stake } from '@veno-app/sdk';
import { useEffect, useMemo } from 'react';
import { create } from 'zustand';

import { useVaultSelectionStore } from '../VaultSelectionStore';
import { useVaultWithdrawDrawerStore } from '../VaultWithdrawDrawer';
import type { VaultType } from './types';

type StakeCardsProps = {
  displayStakeNumber?: number;
  isCheckBox?: boolean;
  stakeCount?: number;
  lockedStakes: Stake[];
  unlockedStakes: Stake[];
  vaultType: VaultType;
};
const StakeCards: React.FC<StakeCardsProps> = ({
  displayStakeNumber,
  isCheckBox,
  stakeCount,
  lockedStakes,
  unlockedStakes,
}) => {
  const t = useTranslations();

  const currentTimestamp = useConst(() => Math.trunc(Date.now() / 1000));
  const onStakeCardsDrawerOpen = useStakeCardsDrawer((s) => s.onOpen);
  const onVaultWithdrawDrawerOpen = useVaultWithdrawDrawerStore(
    (s) => s.onOpen,
  );
  const {
    selectedStakeIds,
    stakes,
    onSelectAll,
    onDeselectAll,
    initializeStakes,
  } = useStakeStore();
  const allSelected = useMemo(
    () => !!stakes && selectedStakeIds.length === stakes.length,
    [selectedStakeIds, stakes],
  );

  useEffect(() => {
    if (isCheckBox) {
      initializeStakes(unlockedStakes.concat(lockedStakes));
    }
  }, [isCheckBox, lockedStakes, unlockedStakes, initializeStakes]);

  if (!displayStakeNumber) {
    return (
      <>
        {isCheckBox && (
          <FormControl
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            mb={4}
          >
            <Text as={FormLabel} htmlFor="select-all" textStyle="body" mb="0">
              {t('Select all')}
            </Text>
            <Switch
              id="select-all"
              isChecked={allSelected}
              onChange={allSelected ? onDeselectAll : onSelectAll}
            />
          </FormControl>
        )}
        {unlockedStakes.map((stake) => (
          <StakeCard
            key={Number(stake.stakeId)}
            status="UNLOCKED"
            isCheckBox={isCheckBox}
            stake={stake}
            allocation={stake.amount}
            currentTimestamp={currentTimestamp}
            onWithdraw={() =>
              onVaultWithdrawDrawerOpen(stake.amount, stake.stakeId)
            }
          />
        ))}
        {lockedStakes.map((stake) => (
          <StakeCard
            key={Number(stake.stakeId)}
            status="LOCKED"
            isCheckBox={isCheckBox}
            stake={stake}
            allocation={stake.amount}
            currentTimestamp={currentTimestamp}
            onWithdraw={() =>
              onVaultWithdrawDrawerOpen(stake.amount, stake.stakeId, true)
            }
          />
        ))}
      </>
    );
  }

  const unlockedStakesToDisplay = unlockedStakes.slice(0, displayStakeNumber);
  const lockedStakesToDisplay =
    displayStakeNumber - unlockedStakesToDisplay.length > 0
      ? lockedStakes.slice(
          0,
          displayStakeNumber - unlockedStakesToDisplay.length,
        )
      : [];

  return (
    <>
      {unlockedStakesToDisplay.map((stake) => (
        <StakeCard
          key={Number(stake.stakeId)}
          status="UNLOCKED"
          isCheckBox={isCheckBox}
          stake={stake}
          allocation={stake.amount}
          currentTimestamp={currentTimestamp}
          onWithdraw={() =>
            onVaultWithdrawDrawerOpen(stake.amount, stake.stakeId)
          }
        />
      ))}
      {lockedStakesToDisplay.map((stake) => (
        <StakeCard
          key={Number(stake.stakeId)}
          status="LOCKED"
          isCheckBox={isCheckBox}
          stake={stake}
          allocation={stake.amount}
          currentTimestamp={currentTimestamp}
          onWithdraw={() =>
            onVaultWithdrawDrawerOpen(stake.amount, stake.stakeId, true)
          }
        />
      ))}
      {(stakeCount ?? 0) > displayStakeNumber && (
        <Button
          variant="link"
          color="pink.500"
          onClick={onStakeCardsDrawerOpen}
        >
          {t('See all')}
        </Button>
      )}
    </>
  );
};

export default StakeCards;

export const StakeCardsDrawer: React.FC = () => {
  const t = useTranslations();
  const { isOpen, onClose } = useStakeCardsDrawer();
  const vaultInfo = useVaultSelectionStore((s) => s.selectedVault);

  if (!vaultInfo) return null;

  return (
    <ModalOrDrawer
      scrollBehavior="inside"
      size={{
        base: 'full',
        desktop: 'md',
      }}
      placement="bottom"
      isOpen={isOpen}
      onClose={onClose}
      isFullHeight
    >
      <ModalOrDrawerOverlay />
      <ModalOrDragDrawerContent>
        <ModalOrDrawerHeader>{t('See all')}</ModalOrDrawerHeader>
        <InDesktop>
          <DrawerCloseButton />
        </InDesktop>
        <ModalOrDrawerBodyWithMask>
          <Text textStyle="h3" align="center" mb={8}>
            {formatLockPeriod(vaultInfo?.lockPeriod ?? 0, {
              uppercase: false,
              roughly: true,
            })}{' '}
            <>
              {vaultInfo?.type === 'RESERVOIR' ? 'Reservoir ' : 'Fountain '}
              {t('Deposits')}
            </>
          </Text>
          <StakeCards
            lockedStakes={vaultInfo?.lockedStakes ?? []}
            unlockedStakes={vaultInfo?.unlockedStakes ?? []}
            vaultType={vaultInfo?.type || 'RESERVOIR'}
          />
        </ModalOrDrawerBodyWithMask>
      </ModalOrDragDrawerContent>
    </ModalOrDrawer>
  );
};

type UseStakeCardsDrawer = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};
export const useStakeCardsDrawer = create<UseStakeCardsDrawer>(
  resetWhenRouterChange((set) => ({
    isOpen: false,
    onOpen: (): void => set(() => ({ isOpen: true })),
    onClose: (): void => set(() => ({ isOpen: false })),
  })),
);

// for checkbox mode usage
type StakeStore = {
  stakes: Stake[] | null;
  selectedStakeIds: number[];
  initializeStakes: (stakes: Stake[]) => void;
  onChange: (id: number) => void;
  onSelectAll: () => void;
  onDeselectAll: () => void;
  onReset: () => void;
};
export const useStakeStore = create<StakeStore>(
  resetWhenRouterChange((set, get) => ({
    selectedStakeIds: [],
    stakes: null,
    initializeStakes: (stakes) => set(() => ({ stakes })),
    onChange: (id): void =>
      set(() => {
        const newSelectedStakeIds = [...get().selectedStakeIds];
        const idx = newSelectedStakeIds.indexOf(id);
        if (idx >= 0) {
          newSelectedStakeIds.splice(idx, 1);
          return { selectedStakeIds: newSelectedStakeIds };
        } else {
          newSelectedStakeIds.push(id);
          return { selectedStakeIds: newSelectedStakeIds };
        }
      }),
    onSelectAll: () => {
      const stakeIds = get().stakes?.map(({ stakeId }) => Number(stakeId));
      if (stakeIds) return set(() => ({ selectedStakeIds: stakeIds }));
    },
    onDeselectAll: () => set(() => ({ selectedStakeIds: [] })),
    onReset: () => set(() => ({ stakeIds: null, selectedStakeIds: [] })),
  })),
);
