import type { FlexProps } from '@chakra-ui/react';
import { Flex, keyframes } from '@chakra-ui/react';
import React from 'react';

const animationKeyframes = keyframes`
  from { transform: rotate(0); }
  to { transform: rotate(360deg); }
`;
const animation = `${animationKeyframes} 5s linear infinite`;

type RotatingBgProps = FlexProps & {
  src?: string;
};
export const RotatingBg: React.FC<RotatingBgProps> = ({
  children,
  src = '/images/activated-vault.png',
  shadow,
  ...props
}) => {
  return (
    <Flex
      direction="column"
      position="relative"
      flexShrink="0"
      overflow="hidden"
      background="transparent"
      zIndex="0"
      {...props}
      _before={{
        content: '""',
        bgImage: `url(${src})`,
        bgRepeat: 'no-repeat',
        bgSize: '100% 100%',
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        zIndex: -1,
        animation: animation,
      }}
    >
      {children}
    </Flex>
  );
};
