import { BigNumber } from '@ethersproject/bignumber';
import {
  useVenoFountainData,
  useVenoReservoirData,
} from '@ui/components/VenoInfoView/useVenoInfoData';
import type { VaultType } from '@ui/views/Vno/ReservoirAndFountain/types';
import { useMemo } from 'react';

const useVnoBasicInfo = (type: VaultType) => {
  const {
    isLoading: isLoadingReservoir,
    lockedStakes: lockedStakesReservoir,
    unlockedStakes: unlockedStakesReservoir,
    pools: poolsReservoir,
  } = useVenoReservoirData(type === 'RESERVOIR');
  const {
    isLoading: isLoadingFountain,
    lockedStakes: lockedStakesFountain,
    unlockedStakes: unlockedStakesFountain,
    pools: poolsFountain,
  } = useVenoFountainData(type === 'FOUNTAIN');

  const { isLoading, totalStakes, apr12month, maxApr } = useMemo(() => {
    let stakeAmounts: BigNumber[] = [];
    let apr12month = 0;
    let maxApr = 0;
    switch (type) {
      case 'RESERVOIR':
        (apr12month =
          poolsReservoir?.length === 4 ? poolsReservoir[1].apr ?? 0 : 0),
          (stakeAmounts = [
            ...lockedStakesReservoir.map((s) => s.amount),
            ...unlockedStakesReservoir.map((s) => s.amount),
          ]);
        (maxApr =
          poolsReservoir?.length === 4 ? poolsReservoir[3].apr ?? 0 : 0),
          (stakeAmounts = [
            ...lockedStakesReservoir.map((s) => s.amount),
            ...unlockedStakesReservoir.map((s) => s.amount),
          ]);
        break;

      case 'FOUNTAIN':
        (apr12month =
          poolsFountain?.length === 4 ? poolsFountain[1].apr ?? 0 : 0),
          (stakeAmounts = [
            ...lockedStakesFountain.map((s) => s.amount),
            ...unlockedStakesFountain.map((s) => s.amount),
          ]);
        (maxApr = poolsFountain?.length === 4 ? poolsFountain[3].apr ?? 0 : 0),
          (stakeAmounts = [
            ...lockedStakesFountain.map((s) => s.amount),
            ...unlockedStakesFountain.map((s) => s.amount),
          ]);
        break;
    }

    const isLoading =
      type === 'FOUNTAIN' ? isLoadingFountain : isLoadingReservoir;

    const totalStakes = stakeAmounts.reduce(
      (prev, current) => prev.add(current),
      BigNumber.from(0),
    );

    return { isLoading, totalStakes, apr12month, maxApr };
  }, [
    isLoadingFountain,
    isLoadingReservoir,
    lockedStakesFountain,
    lockedStakesReservoir,
    poolsFountain,
    poolsReservoir,
    type,
    unlockedStakesFountain,
    unlockedStakesReservoir,
  ]);

  return {
    isLoading,
    totalStakes,
    apr12month,
    maxApr,
  };
};

export default useVnoBasicInfo;
