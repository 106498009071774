const TWITTER = 'https://twitter.com/VenoFinance';
const DISCORD = 'https://discord.com/invite/xQK8f8wu26';
const TELEGRAM = 'https://t.me/VenoFinance';
const TELEGRAM_ANNOUNCEMENTS = 'https://t.me/s/VenoFinanceAnn';
const FERRO = 'https://ferroprotocol.com/';
const FERRO_SWAP_TO_CRO = 'https://ferroprotocol.com/#/swap?from=LCRO&to=CRO';
const FERRO_SWAP_TO_LCRO = 'https://ferroprotocol.com/#/swap?from=CRO&to=LCRO';
const FERRO_DEPOSIT = 'https://ferroprotocol.com/#/pools/lcro/deposit';
const VVS_LANDING = 'https://vvs.finance';
const VVS =
  'https://vvs.finance/swap?disableCurrencySelect&inputCurrency=0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae2359&outputCurrency=0xdb7d0a1ec37de1de924f8e8adac6ed338d4404e9';
const VVS_TIA =
  'https://vvs.finance/swap?outputCurrency=0x982b59aaE4f0BC66960b4BF06d6fE96b9F33d3F7&inputCurrency=0xc21223249CA28397B4B6541dfFaEcC539BfF0c59';
const VVS_SWAP_CRO_TO_ATOM =
  'https://vvs.finance/swap?disableCurrencySelect&inputCurrency=0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae2359&outputCurrency=0xB888d8Dd1733d72681b30c00ee76BDE93ae7aa93';
const VVS_REMOVE =
  'https://vvs.finance/remove/0x2D03bECE6747ADC00E1a131BBA1469C15fD11e03/0xdb7d0A1eC37dE1dE924F8e8adac6Ed338D4404E9';
const MINTED = 'https://minted.network';
const TECTONIC = 'https://app.tectonic.finance';

const MINTED_COLLECTION_BASE_URL = 'https://minted.network/collections/cronos/';
const MINTED_CRUISER_COLLECTION_PROD =
  'https://minted.network/collections/cronos/0xd25358e2cAD3E1Fd165887569892A99fFFA674ac/';
const MINTED_COLLECTION_BASE_URL_DEV =
  'https://msta-minted.crorc.co/collections/cronos/';
const AUDIT =
  'https://docs.veno.finance/veno-finance/protocol/security-and-audits';

const MEDIUM = 'https://medium.com/@VenoFinance';
const WHITEPAPER = 'https://docs.veno.finance/veno-finance/veno/litepaper';
const GITBOOK = 'https://docs.veno.finance/veno-finance/';
const GITBOOK_RESERVOIR =
  'https://docs.veno.finance/veno-finance/protocol/reservoir';
const SYNCSWAP_SWAP_TO_ETH =
  'https://syncswap.xyz/swap?outputCurrency=0xE7895ed01a1a6AAcF1c2E955aF14E7cf612E7F9d';
const MAVERICK = 'https://mav.xyz';
const SINGLE_FINANCE = 'https://app.singlefinance.io';

export const EXTERNAL_LINK = {
  twitter: TWITTER,
  discord: DISCORD,
  telegram: TELEGRAM,
  medium: MEDIUM,
  ferro: FERRO,
  ferroDeposit: FERRO_DEPOSIT,
  ferroSwapToCro: FERRO_SWAP_TO_CRO,
  ferroSwapToLcro: FERRO_SWAP_TO_LCRO,
  syncswapSwapToEth: SYNCSWAP_SWAP_TO_ETH,
  syncSwapPool: 'https://syncswap.xyz/pool',
  vvs: VVS,
  vvsTia: VVS_TIA,
  vvsLanding: VVS_LANDING,
  vvsRemove: VVS_REMOVE,
  vvsSwapCroToAtom: VVS_SWAP_CRO_TO_ATOM,
  minted: MINTED,
  tectonic: TECTONIC,
  whitepaper: WHITEPAPER,
  mintedCollectionBaseUrl: MINTED_COLLECTION_BASE_URL,
  mintedCollectionBaseUrlDev: MINTED_COLLECTION_BASE_URL_DEV,
  gitbook: GITBOOK,
  audit: AUDIT,
  telegramAnnouncements: TELEGRAM_ANNOUNCEMENTS,
  mintedCruiserCollectionProd: MINTED_CRUISER_COLLECTION_PROD,
  gitbookReservoir: GITBOOK_RESERVOIR,
  listingForm: 'https://airtable.com/shrVxouQhihwOZMzy',
  pwnCreateLoan: 'https://app.pwn.xyz/#/create-loan',
  elementMarketplaceBaseUrl:
    'https://element.market/collections/veno-unstaked-eth',
  vvsTiaDeposit:
    'https://vvs.finance/add/0x276e28664dec4982f892a5b836e11f23040b6995/0x982b59aae4f0bc66960b4bf06d6fe96b9f33d3f7/3000?lptype=v2',
  maverick: MAVERICK,
  singleFinance: SINGLE_FINANCE,
};
