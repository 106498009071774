import { Button, Flex, Image, Text } from '@chakra-ui/react';
import { BigNumber } from '@ethersproject/bignumber';
import { MaxUint256 } from '@ethersproject/constants';
import Balance from '@ui/components/Balance';
import { Card } from '@ui/components/Card';
import { InDesktop } from '@ui/components/MobileOrDesktop';
import {
  ModalOrDragDrawerContent,
  ModalOrDrawer,
  ModalOrDrawerBody,
  ModalOrDrawerCloseButton,
  ModalOrDrawerFooter,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from '@ui/components/ModalOrDrawer';
import type { TransactionStatus } from '@ui/components/TransactionDrawer';
import {
  TransactionError,
  TransactionPending,
  VaultWithdrawCompleted,
} from '@ui/components/TransactionDrawer';
import { VaultImage } from '@ui/components/VaultCard/VaultCard';
import useClaimPenaltyRewards from '@ui/hooks/useClaimPenaltyRewards';
import usePendingVaultPenaltyRewards from '@ui/hooks/usePendingVaultPenaltyRewards';
import { useVnoUsdValue } from '@ui/hooks/useVnoUsdValue';
import { useTranslations } from '@ui/i18n';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { formatNumber } from '@ui/utils/format';
import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import type { EthersError } from '@veno-app/sdk';
import { currentWallet } from '@veno-app/wallet';
import React, { useCallback, useState } from 'react';
import { create } from 'zustand';

const { useAccount } = currentWallet;

function CollectPenaltyDrawer() {
  const t = useTranslations();
  const {
    isOpen,
    vaultApr,
    pendingVaultPenalty,
    pendingVaultPenaltyUsd,
    transactionStatus,
    handleClaim,
    error,
    receivedVno,
    handleCloseAndReset,
    nSteps,
    currentStep,
  } = useCollectPenaltyDrawer();

  return (
    <ModalOrDrawer
      placement="bottom"
      size={{
        base: 'full',
        desktop: 'md',
      }}
      isOpen={isOpen}
      onClose={handleCloseAndReset}
      scrollBehavior="inside"
    >
      <ModalOrDrawerOverlay zIndex="modal" />
      <ModalOrDragDrawerContent>
        <InDesktop>
          <ModalOrDrawerCloseButton />
        </InDesktop>
        <ModalOrDrawerHeader>
          {t('Collect penalty fee rewards')}
        </ModalOrDrawerHeader>
        <ModalOrDrawerBody>
          {(() => {
            switch (transactionStatus) {
              case 'COMPLETED':
                return (
                  <Flex flexDir="column" align="center" px={4}>
                    <Text textStyle="h3" mt={6}>
                      {t('Transaction completed!')}
                    </Text>
                    <Balance
                      mt={6}
                      title={t('You have locked')}
                      icon={<Image alt="VNO" src="/tokens/vno.svg" />}
                      amount={receivedVno ?? undefined}
                    />
                    <Text
                      textStyle={{ base: 'bodySmall', desktop: 'body' }}
                      color="text.light"
                      align="center"
                    >
                      {t('in 96 month {platform}', {
                        platform: 'Fountain',
                      })}
                    </Text>
                  </Flex>
                );
              case 'PENDING':
                return (
                  <TransactionPending.Content
                    nSteps={nSteps}
                    currentStep={currentStep}
                  />
                );
              case 'ERROR':
                return <TransactionError.Content error={error} />;
              default:
                return (
                  <>
                    <Card px={4} py={8}>
                      <Balance
                        title={t('Amount to collect')}
                        icon={<Image alt="VNO" src="/tokens/vno.svg" />}
                        amount={pendingVaultPenalty}
                        usdAmount={pendingVaultPenaltyUsd}
                        showUsdAmount
                      />
                    </Card>
                    <Text
                      align="center"
                      textStyle="body"
                      color="text.light"
                      mt={10}
                      px={4}
                    >
                      {t(
                        'Note: This reward will automatically be locked into a 96 month vault',
                      )}
                    </Text>
                    <Flex
                      mt={6}
                      alignItems="center"
                      justifyContent="center"
                      gap={4.5}
                      flexDir={{ base: 'column', desktop: 'row' }}
                    >
                      <VaultImage
                        status="LOCKED"
                        period={t('{num} Months', {
                          num: 96,
                        })}
                        alignSelf="center"
                        size="100px"
                        innerSize="96px"
                      />
                      <Flex flexDir="column" gap={4} align="center">
                        <Flex gap={1}>
                          <Text textStyle="body" color="text.normal">
                            APR
                          </Text>
                          <Text
                            textStyle="bodyBold"
                            color="text.normal"
                            wordBreak="break-all"
                          >
                            {formatNumber(vaultApr * 100, 2)}%
                          </Text>
                        </Flex>
                        <Flex gap={1}>
                          <Text textStyle="body" color="text.normal">
                            {t('Multiplier')}
                          </Text>
                          <Text
                            textStyle="bodyBold"
                            color="text.normal"
                            wordBreak="break-all"
                          >
                            96x
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </>
                );
            }
          })()}
        </ModalOrDrawerBody>
        <ModalOrDrawerFooter>
          {(() => {
            switch (transactionStatus) {
              case 'COMPLETED':
                return (
                  <VaultWithdrawCompleted.Footer
                    onCloseDrawer={handleCloseAndReset}
                  />
                );
              case 'PENDING':
                return <TransactionPending.Footer />;
              case 'ERROR':
                return null;
              default:
                return (
                  <Button
                    disabled={
                      !pendingVaultPenalty || pendingVaultPenalty.lte(0)
                    }
                    onClick={handleClaim}
                  >
                    {t('Confirm')}
                  </Button>
                );
            }
          })()}
        </ModalOrDrawerFooter>
      </ModalOrDragDrawerContent>
    </ModalOrDrawer>
  );
}

export default CollectPenaltyDrawer;

const useCollectPenaltyDrawer = () => {
  const { isOpen, vaultApr, onClose } = useCollectPenaltyDrawerStore();
  const account = useAccount();
  const { pendingVaultPenalty, pendingVaultPenaltyLoading } =
    usePendingVaultPenaltyRewards();
  const { data: pendingVaultPenaltyUsd } = useVnoUsdValue(pendingVaultPenalty);
  const [error, setError] = useState<EthersError | null>(null);
  const [transactionStatus, setTransactionStatus] =
    useState<TransactionStatus>('CONFIRM');
  const sdk = useVenoSDK();
  const [receivedVno, setReceivedVno] = useState<BigNumber | null>(null);
  const { mutateAsync: claimPenalty } = useClaimPenaltyRewards();
  const [nSteps, setNSteps] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);

  const handleCloseAndReset = (): void => {
    onClose();
    setTransactionStatus('CONFIRM');
    setError(null);
    setReceivedVno(null);
  };

  const handleClaim = useCallback(async () => {
    setTransactionStatus('PENDING');
    setError(null);
    try {
      const allowance = await sdk.VenoToken.allowance(
        account ?? '',
        sdk.VenoFountain.contractAddress,
      );
      // approve a bit more than expected initially, because it might be growing
      const necessaryApproveAmount = (pendingVaultPenalty ?? BigNumber.from(0))
        .mul(10)
        .div(9);
      if (allowance.lt(necessaryApproveAmount)) {
        // not yet approved enough.
        setNSteps(2);
        setCurrentStep(1);
        const approveRet = await sdk.VenoToken.approve(
          sdk.VenoFountain.contractAddress,
          MaxUint256,
        );
        await approveRet.txReceiptPromise;
        // approval done, now got to step 2, deposit
        setCurrentStep(2);
      } else {
        // it was already approved, only one step, which is "deposit"
        setNSteps(1);
        setCurrentStep(1);
      }
      const r = await claimPenalty();
      const p = await r.txReceiptPromise;
      setTransactionStatus('COMPLETED');
      setReceivedVno(sdk.VenoFountain.getReceivedPenalty(p));
    } catch (e) {
      console.error(e);
      setTransactionStatus('ERROR');
      setError(e as EthersError);
    }
  }, [
    account,
    claimPenalty,
    pendingVaultPenalty,
    sdk.VenoFountain,
    sdk.VenoToken,
  ]);

  return {
    isOpen,
    vaultApr,
    pendingVaultPenalty,
    pendingVaultPenaltyLoading,
    pendingVaultPenaltyUsd,
    transactionStatus,
    handleClaim,
    error,
    receivedVno,
    handleCloseAndReset,
    nSteps,
    currentStep,
  };
};

export type CollectPenaltyDrawerStore = {
  isOpen: boolean;
  onOpen: (vaultApr: number) => void;
  onClose: () => void;
  vaultApr: number;
};
export const useCollectPenaltyDrawerStore = create<CollectPenaltyDrawerStore>(
  resetWhenRouterChange((set) => ({
    isOpen: false,
    vaultApr: 0,
    onOpen: (vaultApr: number): void =>
      set(() => ({
        isOpen: true,
        vaultApr,
      })),
    onClose: () => set(() => ({ isOpen: false, allocation: null })),
  })),
);
