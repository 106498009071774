const desktopBreakpoint = 992;
export const breakpoints = {
    base: '0px',
    xxs: '480px',
    sm: '576px',
    md: '768px',
    lg: `${desktopBreakpoint - 0.001}px`,
    desktop: `${desktopBreakpoint}px`,
    xl: '1280px',
    '2xl': '1440px',
};
