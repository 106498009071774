import { Text } from '@chakra-ui/react';
import {
  ModalOrDrawer,
  ModalOrDrawerBody,
  ModalOrDrawerCloseButton,
  ModalOrDrawerContent,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from '@ui/components/ModalOrDrawer';
import { useTranslations } from '@ui/i18n';
import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import React, { useRef } from 'react';
import { create } from 'zustand';

import TransactionHistoryContent from './TransactionHistoryContent';

function TransactionHistoryDrawer() {
  const bodyIdRef = useRef(String(Math.random()));
  const t = useTranslations();
  const { isOpen, toggle } = useTransactionHistoryDrawerStore();

  return (
    <ModalOrDrawer
      variant={{ base: 'backward', desktop: 'default' }}
      placement="right"
      size={{
        base: 'full',
        desktop: 'md',
      }}
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={() => toggle(false)}
    >
      <ModalOrDrawerOverlay />
      <ModalOrDrawerContent>
        <ModalOrDrawerCloseButton />
        <ModalOrDrawerHeader pt="80px" textAlign="left">
          <Text textStyle="h3">{t('Transaction history')}</Text>
        </ModalOrDrawerHeader>
        <ModalOrDrawerBody id={bodyIdRef.current} pb="40px">
          <TransactionHistoryContent isOpen={isOpen} idRef={bodyIdRef} />
        </ModalOrDrawerBody>
      </ModalOrDrawerContent>
    </ModalOrDrawer>
  );
}

export default TransactionHistoryDrawer;

export type TransactionHistoryDrawerStore = {
  isOpen: boolean;
  toggle: (isOpen?: boolean) => void;
};

export const useTransactionHistoryDrawerStore =
  create<TransactionHistoryDrawerStore>(
    resetWhenRouterChange((set) => ({
      isOpen: false,
      toggle: (isOpen) => {
        set((state) => {
          return {
            isOpen: typeof isOpen !== 'undefined' ? isOpen : !state.isOpen,
          };
        });
      },
    })),
  );
