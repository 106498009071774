export const DEFAULT_NUMBER = '-';
export const intlNumberFormatterCache: Map<string, Intl.NumberFormat> =
  new Map();

export type Locales = string | string[];
export enum NumberFormatterType {
  Fraction = 'Fraction',
  // only for decimal < 1
  Significant = 'Significant',
}

export type FormatFunction = (
  value: number | bigint,
  minDigits?: number,
  maxDigits?: number,
  locales?: Locales,
) => string;

const genCacheKey = (
  type = NumberFormatterType.Fraction,
  minDigits?: number,
  maxDigits?: number,
  locales?: Locales,
) => [type, minDigits, maxDigits, locales?.toString()].join('_');

export const getFormatter = (...args: Parameters<typeof genCacheKey>) => {
  const min =
    args[0] === NumberFormatterType.Fraction ? args[1] ?? 0 : undefined;
  const max = args[2] ?? min;
  const cacheKey = genCacheKey(args[0], min, max, args[3]);
  const formatter =
    intlNumberFormatterCache.get(cacheKey) ||
    new Intl.NumberFormat(args[3], {
      [`minimum${args[0]}Digits`]: min,
      [`maximum${args[0]}Digits`]: max,
    });
  if (!intlNumberFormatterCache.has(cacheKey)) {
    intlNumberFormatterCache.set(cacheKey, formatter);
  }
  return formatter;
};

type FormatInvalidOpts = {
  NaNString?: string;
  zeroString?: string;
};

export const formatInvalidHOC =
  (
    f: FormatFunction,
    { NaNString = DEFAULT_NUMBER, zeroString }: FormatInvalidOpts = {},
  ): FormatFunction =>
  (value, ...args) => {
    if (typeof value === 'number' && !value) {
      if (isNaN(value)) return NaNString;
      if (typeof zeroString !== 'undefined') return zeroString;
    }
    return f(value, ...args);
  };

export type Unit = 'B' | 'M' | 'K';

export const UNITS = [
  {
    unit: 'B',
    base: 10 ** 9,
  },
  {
    unit: 'M',
    base: 10 ** 6,
  },
  {
    unit: 'K',
    base: 10 ** 3,
  },
].sort((a, b) => b.base - a.base) as { unit: Unit; base: number }[];

export const formatFraction: FormatFunction = (value, ...args) =>
  getFormatter(NumberFormatterType.Fraction, ...args).format(value);

export const formatSignificant: FormatFunction = (value, min, ...args) =>
  getFormatter(NumberFormatterType.Significant, undefined, ...args).format(
    value,
  );
