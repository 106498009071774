export const Spinner = {
    baseStyle() {
        return {
            borderLeftColor: 'currentColor',
        };
    },
    variants: {},
    sizes: {
        md: {
            w: '23px',
            h: '23px',
        },
        lg: {
            w: '67px',
            h: '67px',
            borderWidth: '4px',
        },
    },
    defaultProps: {},
};
