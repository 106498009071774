import type { BigNumberish } from '@ethersproject/bignumber';
import type { VaultStatus } from '@ui/components/VaultCard';
import type { Pool } from '@ui/queries/types';
import type { Stake } from '@veno-app/sdk';

export type ReservoirOrFountainPool = Pool;

export type VaultInfo = Omit<ReservoirOrFountainPool, 'totalStaked'> & {
  status: VaultStatus;
  allocation: BigNumberish;
  lockedStakes: Stake[];
  unlockedStakes: Stake[];
  stakeCount: number;
  type: VaultType;
};

export enum UpgradeStep {
  SelectStake = 0,
  Upgrade = 1,
  Confirm = 2,
}

export type VaultType = 'RESERVOIR' | 'FOUNTAIN';
