import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { QueryKey } from '@ui/queries/queryKey';
import { currentWallet } from '@veno-app/wallet';

const { useAccount } = currentWallet;

const useVenoHarvest = (
  venoContractInstance:
    | ReturnType<typeof useVenoSDK>['VenoReservoir']
    | ReturnType<typeof useVenoSDK>['VenoFountain'],
  useMutationOptions?: UseMutationOptions,
) => {
  const account = useAccount();

  return useMutation(async () => {
    if (!account) {
      return;
    }

    /**
     * when amount === 0, it will do harvest only
     * @see https://docs.google.com/presentation/d/1lnz8LDucLQsPauZ0WANvKF4Q4OtFewBol95Zy32xC9A/edit#slide=id.g17b1d41964d_0_85
     */
    const harvestRet = await venoContractInstance.deposit(
      BigNumber.from('0'),
      parseUnits('0', 18),
    );

    await harvestRet.txReceiptPromise;
  }, useMutationOptions);
};

export const useVenoReservoirHarvest = (options?: UseMutationOptions) => {
  const sdk = useVenoSDK();
  const queryClient = useQueryClient();
  return useVenoHarvest(sdk.VenoReservoir, {
    ...options,
    onSuccess() {
      options?.onSuccess && options.onSuccess(undefined, undefined, undefined);
      queryClient.invalidateQueries([
        QueryKey.GET_VENO_RESERVOIR_PENDING_REWARDS,
      ]);
      queryClient.invalidateQueries([QueryKey.GET_VENO_RESERVOIR_USER_INFO]);
    },
  });
};

export const useVenoFountainHarvest = (options?: UseMutationOptions) => {
  const sdk = useVenoSDK();
  const queryClient = useQueryClient();
  return useVenoHarvest(sdk.VenoFountain, {
    ...options,
    onSuccess() {
      options?.onSuccess && options.onSuccess(undefined, undefined, undefined);
      queryClient.invalidateQueries([
        QueryKey.GET_VENO_FOUNTAIN_PENDING_REWARDS,
      ]);
      queryClient.invalidateQueries([QueryKey.GET_VENO_FOUNTAIN_USER_INFO]);
    },
  });
};
