import { TokenDelegator__factory } from '@contracts';
import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from 'ethers/lib/utils';
import { VenoSDKModule } from './base';
import { expandDecimals } from './constants';
import FerroSwapModule from './ferroSwap';
import FerroVaultModule from './ferroVault';
import { wrapSdkTransaction } from './util';
export default class WcroDelegatorModule extends VenoSDKModule {
    constructor() {
        super(...arguments);
        this.contractFactory = TokenDelegator__factory;
        this.contractAddress = this.sdk.addresses.wcroDelegator;
        this.ferroVault = new FerroVaultModule(this.sdk);
        this.ferroSwap = new FerroSwapModule(this.sdk, this.addresses.ferroSwap);
    }
    async deposit(amount, slippage) {
        return await wrapSdkTransaction(this.getContract()['deposit(uint256,uint256)'](amount, slippage));
    }
    async withdraw(amount, slippage) {
        return await wrapSdkTransaction(this.getContract()['withdraw(uint256,uint256)'](amount, slippage));
    }
    async getWithdrawExchangeRate(vaultTokenAmount) {
        if (vaultTokenAmount.eq(0)) {
            return BigNumber.from(0);
        }
        const lpPerVaultToken = await this.ferroVault.exchangeRate();
        const lpReceived = lpPerVaultToken.mul(vaultTokenAmount);
        const croReceived = await this.ferroSwap.calculateRemoveLiquidityOneToken(lpReceived.div(expandDecimals(1, 18)), 1);
        return croReceived.mul(expandDecimals(1, 18)).div(vaultTokenAmount);
    }
    async getDepositExchangeRate(tokenAmount) {
        if (tokenAmount.lte(0))
            return BigNumber.from(0);
        const lpTokensToReceive = await this.ferroSwap.calculateTokenAmount(BigNumber.from(0), tokenAmount);
        const exchangeRate = (await this.ferroVault.exchangeRate())
            .mul(tokenAmount)
            .div(lpTokensToReceive);
        return exchangeRate;
    }
    async getVaultTokensForDeposit(tokenAmount) {
        return await this.getContract().callStatic['deposit(uint256)'](tokenAmount);
    }
    async shouldApproveWhenWithdraw(account, amount) {
        return (await this.ferroVault.allowance(account, this.contractAddress)).lt(amount);
    }
    async getDepositPriceImpact(tokenAmount) {
        if (tokenAmount.lte(0))
            0;
        const [lpTokensToReceive, virtualPrice] = await Promise.all([
            this.ferroSwap.calculateTokenAmount(BigNumber.from(0), tokenAmount),
            this.ferroSwap.getVirtualPrice(),
        ]);
        const virtualPriceHuman = parseFloat(formatUnits(virtualPrice));
        const tokenAmountHuman = parseFloat(formatUnits(tokenAmount));
        const lpReceiveHuman = parseFloat(formatUnits(lpTokensToReceive));
        const result = (virtualPriceHuman * lpReceiveHuman) / tokenAmountHuman - 1;
        return result;
    }
    async getWithdrawPriceImpact(vaultTokenAmount) {
        const [lpPerVaultToken, virtualPrice] = await Promise.all([
            this.ferroVault.exchangeRate(),
            this.ferroSwap.getVirtualPrice(),
        ]);
        const lpReceived = lpPerVaultToken
            .mul(vaultTokenAmount)
            .div(expandDecimals(1, 18));
        const croReceived = await this.ferroSwap.calculateRemoveLiquidityOneToken(lpReceived, 1);
        const croReceivedHuman = parseFloat(formatUnits(croReceived));
        const virtualPriceHuman = parseFloat(formatUnits(virtualPrice));
        const lpReceiveHuman = parseFloat(formatUnits(lpReceived));
        const result = (croReceivedHuman - virtualPriceHuman * lpReceiveHuman) /
            (virtualPriceHuman * lpReceiveHuman);
        return result;
    }
}
