import { BigNumber } from '@ethersproject/bignumber';
import { MaxUint256 } from '@ethersproject/constants';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import type { VenoSDK } from '@veno-app/sdk';

import type { SupplyLcroModalType } from './types';

export async function getAllowance(
  coinMode: CoinMode,
  type: SupplyLcroModalType,
  sdk: VenoSDK,
  account?: string,
): Promise<BigNumber> {
  if (!account) return BigNumber.from(0);
  switch (type) {
    case 'FERRO':
      if (coinMode === 'atom') {
        return await sdk.LiquidAtom.allowance(
          account,
          sdk.FerroSwapAtom.contractAddress,
        );
      }
      return await sdk.LiquidCro.allowance(
        account,
        sdk.FerroSwap.contractAddress,
      );
    case 'VVS':
      return await sdk.VenoToken.allowance(account, sdk.VvsZap.contractAddress);
    case 'VVS_CRO':
      return await sdk.LiquidCro.allowance(account, sdk.VvsZap.contractAddress);
    case 'VVS_TIA':
      return await sdk.LiquidTia.allowance(account, sdk.VvsZap.contractAddress);
    case 'TECTONIC':
      return coinMode === 'atom'
        ? sdk.LiquidAtom.allowance(account, sdk.TLatom.contractAddress)
        : sdk.LiquidCro.allowance(account, sdk.TLcro.contractAddress);
  }
}

export async function approve(
  coinMode: CoinMode,
  type: SupplyLcroModalType,
  sdk: VenoSDK,
) {
  switch (type) {
    case 'FERRO':
      if (coinMode === 'atom') {
        return await sdk.LiquidAtom.approve(
          sdk.FerroSwapAtom.contractAddress,
          MaxUint256,
        );
      }
      return await sdk.LiquidCro.approve(
        sdk.FerroSwap.contractAddress,
        MaxUint256,
      );
    case 'VVS':
      return await sdk.VenoToken.approve(
        sdk.VvsZap.contractAddress,
        MaxUint256,
      );
    case 'VVS_CRO':
      return await sdk.LiquidCro.approve(
        sdk.VvsZap.contractAddress,
        MaxUint256,
      );
    case 'VVS_TIA':
      return await sdk.LiquidTia.approve(
        sdk.VvsZap.contractAddress,
        MaxUint256,
      );
    case 'TECTONIC':
      return coinMode === 'atom'
        ? await sdk.LiquidAtom.approve(sdk.TLatom.contractAddress, MaxUint256)
        : await sdk.LiquidCro.approve(sdk.TLcro.contractAddress, MaxUint256);
  }
}

export function getReceived(
  coinMode: CoinMode,
  type: SupplyLcroModalType,
  sdk: VenoSDK,
  // don't import type just for this line
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  receipt: any,
): BigNumber {
  switch (type) {
    case 'FERRO':
      if (coinMode === 'atom') {
        return sdk.FerroSwapAtom.getReceivedLp(receipt) ?? BigNumber.from(0);
      }
      return sdk.FerroSwap.getReceivedLp(receipt) ?? BigNumber.from(0);
    case 'VVS':
    case 'VVS_CRO':
    case 'VVS_TIA':
      return sdk.VvsZap.getReceivedVvsLp(receipt) ?? BigNumber.from(0);
    case 'TECTONIC':
      return sdk.TLcro.getReceivedTLcro(receipt) ?? BigNumber.from(0);
  }
}

export async function getDepositDirectUsdValue(
  type: SupplyLcroModalType,
  sdk: VenoSDK,
  amount: BigNumber,
  oneVnoUsdValue: number,
): Promise<BigNumber> {
  switch (type) {
    case 'FERRO':
    case 'TECTONIC':
    case 'VVS_CRO':
      return sdk.LiquidCro.getUsdWorth(amount);
    case 'VVS_TIA':
      return sdk.LiquidTia.getUsdWorth(amount);
    case 'VVS':
      return amount
        .mul(Math.round(100_000_000 * oneVnoUsdValue))
        .div(100_000_000);
  }
}
