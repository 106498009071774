import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { DragDrawerContent } from '@ui/components/DragDrawer';
import { ArrowBack, Close } from '@veno-app/icons';
import type { FC } from 'react';

import { RenderInDesktop, RenderInMobile } from '../MobileOrDesktop';
import { ModalBodyWithMask } from './ModalBodyWithMask';

export const ModalOrDrawer = hocModalOrDrawer('ModalOrDrawer', Drawer, Modal);
export const ModalOrDrawerContent = hocModalOrDrawer(
  'ModalOrDrawerContent',
  DrawerContent,
  ModalContent,
);
export const ModalOrDragDrawerContent = hocModalOrDrawer(
  'ModalOrDragDrawerContent',
  DragDrawerContent,
  ModalContent,
);
export const ModalOrDrawerOverlay = hocModalOrDrawer(
  'ModalOrDrawerOverlay',
  DrawerOverlay,
  ModalOverlay,
);
export const ModalOrDrawerBody = hocModalOrDrawer(
  'ModalOrDrawerBody',
  DrawerBody,
  ModalBody,
);
export const ModalOrDrawerBodyWithMask = hocModalOrDrawer(
  'ModalOrDrawerBodyWithMask',
  DrawerBody,
  ModalBodyWithMask,
);
export const ModalOrDrawerHeader = hocModalOrDrawer(
  'ModalOrDrawerHeader',
  DrawerHeader,
  ModalHeader,
);
export const ModalOrDrawerFooter = hocModalOrDrawer(
  'ModalOrDrawerFooter',
  DrawerFooter,
  ModalFooter,
);

export const ModalOrDrawerCloseButton = hocModalOrDrawer(
  'ModalOrDrawerCloseButton',
  DrawerCloseButton,
  ModalCloseButton,
);
ModalOrDrawerCloseButton.defaultProps = {
  variant: {
    base: 'raised',
    xxs: 'raised',
    xs: 'raised',
    md: 'raised',
    desktop: 'default',
  },
  children: (
    <>
      <RenderInMobile>
        <Icon as={ArrowBack} />
      </RenderInMobile>
      <RenderInDesktop>
        <Icon as={Close} width="1em" height="1em" />
      </RenderInDesktop>
    </>
  ),
};

type CompProps = Record<string, any> & JSX.IntrinsicAttributes;
type CompType<T> = FC<T> | ((props: T) => JSX.Element);
function hocModalOrDrawer<D extends CompProps, M>(
  name: string,
  DrawerPart: CompType<D>,
  ModalPart: CompType<M>,
) {
  const Hoc = (props: D & M) => {
    return (
      <>
        <RenderInMobile>
          <DrawerPart {...props} />
        </RenderInMobile>
        <RenderInDesktop>
          <ModalPart {...props} />
        </RenderInDesktop>
      </>
    );
  };
  Hoc.displayName = name;
  Hoc.defaultProps = {} as Partial<D & M>;
  return Hoc;
}
