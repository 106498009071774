import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import { create } from 'zustand';

export type FaqModalStore = {
  isOpen: boolean;
  toggleFaqModal: (isOpen?: boolean) => void;
};

export const useFaqModalStore = create<FaqModalStore>(
  resetWhenRouterChange((set) => ({
    isOpen: false,
    toggleFaqModal: (isOpen) =>
      set((state) => ({
        isOpen: typeof isOpen !== 'undefined' ? isOpen : !state.isOpen,
      })),
  })),
);
