import type { FlexProps, TooltipProps } from '@chakra-ui/react';
import {
  Box,
  Flex,
  Icon as ChakraIcon,
  Link,
  Skeleton,
  Tooltip,
} from '@chakra-ui/react';
import { textStyles } from '@veno-app/chakra';
import { Info as InfoIcon } from '@veno-app/icons';
import type { ReactNode } from 'react';

export type InfoProps = Omit<FlexProps, 'title'> & {
  title: ReactNode;
  value?: ReactNode;
  tooltip?: ReactNode;
  tooltipProps?: Omit<TooltipProps, 'children'>;
  icon?: (props: {
    width?: number | string;
    height?: number | string;
  }) => JSX.Element;
  convertedValue?: ReactNode;
  convertedValueLoading?: boolean;
  link?: {
    text: string;
    action: () => void;
  };
  infoButtonAction?: () => void;
};

const Info = ({
  title,
  value,
  icon: Icon,
  convertedValue,
  convertedValueLoading,
  link,
  tooltip,
  tooltipProps,
  infoButtonAction,
  ...props
}: InfoProps) => {
  return (
    <Flex
      flexDir="row"
      align="flex-start"
      justify="space-between"
      gap="4px"
      {...props}
    >
      {title && (
        <Flex textStyle={{ base: 'body', desktop: 'h4' }} flexShrink="0">
          {tooltip ? (
            <Tooltip
              p={0}
              m={0}
              shadow="card.normal"
              color="text.normal"
              bgColor="bg.main"
              label={tooltip}
              {...tooltipProps}
            >
              <Flex cursor="pointer">
                {title}{' '}
                <ChakraIcon
                  onClick={() => infoButtonAction && infoButtonAction()}
                  color="text.active"
                  ml={1}
                  w="24px"
                  h="24px"
                  as={InfoIcon}
                />
              </Flex>
            </Tooltip>
          ) : (
            title
          )}
        </Flex>
      )}
      <Flex flexDir="column" alignItems="flex-end" gap="2px" textAlign="right">
        <Flex gap="4px">
          {value !== undefined && (
            <Box
              textStyle={{ base: 'bodyBold', desktop: 'h4Bold' }}
              wordBreak="break-all"
            >
              {value}
            </Box>
          )}
          {value === undefined && (
            <Skeleton w="100px" h={{ base: '24px', desktop: '23px' }} />
          )}
          {!!Icon && <Icon width="24px" height="24px" />}
        </Flex>
        {convertedValue && (
          <Box
            color="text.light"
            textStyle={{ base: 'bodySmall', desktop: 'body' }}
          >
            {convertedValue}
          </Box>
        )}
        {convertedValueLoading && (
          <Skeleton
            w="80px"
            h={{
              base: textStyles.bodySmall.lineHeight,
              desktop: textStyles.body.lineHeight,
            }}
          />
        )}
        {link && (
          <Link
            color="pink.500"
            textStyle="bodySmall"
            lineHeight="18px"
            onClick={link.action}
          >
            {link.text}
          </Link>
        )}
      </Flex>
    </Flex>
  );
};

export default Info;

export const InfoWithLoading = ({
  isLoading,
  value,
  convertedValue,
  convertedValueLoading,
  ...props
}: { isLoading: boolean } & InfoProps) => {
  return isLoading ? (
    <Info convertedValueLoading={isLoading} {...props} />
  ) : (
    <Info
      {...props}
      value={value}
      convertedValue={convertedValue}
      convertedValueLoading={convertedValueLoading}
    />
  );
};
