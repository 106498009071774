import type { ModalProps } from '@chakra-ui/react';
import { Button, Text } from '@chakra-ui/react';
import { useTranslations } from '@ui/i18n';
import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import type { FC, ReactNode } from 'react';
import { create } from 'zustand';

import { InDesktop } from './MobileOrDesktop';
import {
  ModalOrDragDrawerContent,
  ModalOrDrawer,
  ModalOrDrawerBody,
  ModalOrDrawerCloseButton,
  ModalOrDrawerFooter,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from './ModalOrDrawer';

export const DisclaimerDrawer: FC = () => {
  const isOpen = useDisclaimerDrawer((s) => s.isOpen);
  const onClose = useDisclaimerDrawer((s) => s.onClose);
  const title = useDisclaimerDrawer((s) => s.title);
  const DrawerContainer = useDisclaimerDrawer((s) => s.DrawerContainer);
  const variant = useDisclaimerDrawer((s) => s.variant);

  return (
    <DrawerContainer>
      <ModalOrDrawer
        variant={variant}
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom"
        size={{
          base: 'full',
          desktop: 'md',
        }}
      >
        <ModalOrDrawerOverlay />
        <ModalOrDragDrawerContent>
          <InDesktop>
            <ModalOrDrawerCloseButton />
          </InDesktop>
          <ModalOrDrawerHeader>{title}</ModalOrDrawerHeader>
          <DisclaimerDrawerContent />
        </ModalOrDragDrawerContent>
      </ModalOrDrawer>
    </DrawerContainer>
  );
};

export const DisclaimerDrawerContent: FC = () => {
  const t = useTranslations();
  const url = useDisclaimerDrawer((s) => s.url);
  const onClose = useDisclaimerDrawer((s) => s.onClose);

  return (
    <>
      <ModalOrDrawerBody>
        <Text textStyle="h3" textAlign="center">
          {t('Leaving {platform}', {
            platform: 'veno.finance',
          })}
        </Text>
        <Text color="text-light" mt="42px">
          {t('leaving-veno-desc')}
        </Text>
      </ModalOrDrawerBody>
      <ModalOrDrawerFooter>
        <Button as="a" href={url ?? undefined} target="_blank">
          {t('Agree & Continue')}
        </Button>
        <Button variant="link" onClick={onClose} mt="10px">
          {t('Dismiss')}
        </Button>
      </ModalOrDrawerFooter>
    </>
  );
};

type UseDisclaimerDrawerStore = {
  isOpen: boolean;
  url: string | null;
  title: ReactNode;
  variant?: ModalProps['variant'];
  DrawerContainer: FC<{ children: ReactNode }>;
  onOpen: (options: {
    url: string;
    DrawerContainer?: FC<{ children: ReactNode }>;
    variant?: ModalProps['variant'];
    title?: ReactNode;
  }) => void;
  onClose: () => void;
};

export const useDisclaimerDrawer = create(
  resetWhenRouterChange<UseDisclaimerDrawerStore>((set) => ({
    isOpen: false,
    url: null,
    title: null,
    variant: undefined,
    DrawerContainer: ({ children }) => <>{children}</>,
    onOpen: ({ url, DrawerContainer, title, variant }) => {
      set((store) => ({
        isOpen: true,
        url,
        DrawerContainer: DrawerContainer || store.DrawerContainer,
        title: title || store.title,
        variant,
      }));
    },
    onClose: () => {
      useDisclaimerDrawer.getState().$$resetData?.();
    },
  })),
);
