export const textStyles = {
    // headers text
    h1: {
        fontSize: '48px',
        fontWeight: 'bold',
        lineHeight: '57px',
    },
    h2: {
        fontSize: '32px',
        fontWeight: 'normal',
        lineHeight: '48px',
    },
    h3: {
        fontSize: '24px',
        fontWeight: 'normal',
        lineHeight: '31px',
    },
    h3Bold: {
        fontSize: '24px',
        fontWeight: 'bold',
        lineHeight: '31px',
    },
    h4: {
        fontSize: '18px',
        fontWeight: 'normal',
        lineHeight: '23px',
    },
    h4Bold: {
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '23px',
    },
    // body text
    body: {
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '24px',
    },
    bodyBold: {
        fontSize: '16px',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    bodySmall: {
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: '21px',
    },
    bodySmallBold: {
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: '21px',
    },
    // captions text
    textLink: {
        fontSize: '14px',
        fontWeight: 'extrabold',
        lineHeight: '18px',
    },
    ctaCopy: {
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: '17px',
    },
    buttonLink: {
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: '18px',
    },
    caption: {
        fontSize: '12px',
        fontWeight: 'normal',
        lineHeight: '14px',
    },
    captionBold: {
        fontSize: '12px',
        fontWeight: 'bold',
        lineHeight: '14px',
    },
    captionSmall: {
        fontSize: '10px',
        fontWeight: 'normal',
        lineHeight: '13px',
    },
};
