import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { features } from '@ui/components/Features';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import type { FetchTectonicSupplyResponse } from '@ui/queries/partnerTvl';
import { getQuery } from '@ui/queries/queries';
import { QueryKey } from '@ui/queries/queryKey';
import { getTectonicExchangeRate } from '@ui/queries/sdk';

import type { CoinMode } from './useCoinMode';
import { useCoinMode } from './useCoinMode';
import useCoinPerStakedCoin from './useCoinPerStakedCoin';
import { useCoinUsdValue } from './useCoinUsdValue';
import { isFeatureEnabled } from './useIsFeatureEnabled';

const tectonicEnabled = isFeatureEnabled(features.TectonicGarden);

/**
 * @returns USD value for total supply for LCRO on tectonic, scaled by 1e18
 */
export const useTectonicSupplyUsd = ({
  enabled,
}: { enabled?: boolean } = {}) => {
  const coinMode = useCoinMode();
  const { data: croPerStcro } = useCoinPerStakedCoin();

  const tassetInfoQuery = getQuery(QueryKey.TASSET_INFO)(coinMode);

  const { data: tectonicSupply, error: tectonicSupplyError } = useQuery(
    tassetInfoQuery.queryKey,
    tassetInfoQuery.queryFn,
    { enabled: enabled === false ? false : tectonicEnabled },
  );

  const totalSupply = tectonicSupply?.totalSupply;

  const ret = useCoinUsdValue(
    croPerStcro
      ? totalSupply?.mul(Math.round(croPerStcro * 100_000_000)).div(100_000_000)
      : undefined,
  );

  return {
    ...ret,
    error: ret.error || tectonicSupplyError,
  };
};

export const useTectonicApy = (enabled?: boolean, _coinMode?: CoinMode) => {
  const globalCoinMode = useCoinMode();
  const coinMode = _coinMode ?? globalCoinMode;
  const tassetInfoQuery = getQuery(QueryKey.TASSET_INFO)(coinMode);

  const queryResult = useQuery<
    Awaited<ReturnType<typeof tassetInfoQuery.queryFn>>,
    Error
  >(tassetInfoQuery.queryKey, tassetInfoQuery.queryFn, { enabled });

  const { supplyApy: apy, tonicSupplyApy } = queryResult.data ?? {
    apy: undefined,
  };

  const result =
    apy !== undefined && tonicSupplyApy !== undefined
      ? apy + tonicSupplyApy * 100
      : undefined;

  return {
    ...queryResult,
    data: result,
  };
};

export const useTectonicVnoApy = (enabled?: boolean) => {
  return useQuery(
    ['useTectonicVnoApy'],
    async () => {
      const fetchResponse = await fetch(
        'https://api.tectonic.finance/info/tasset-info/0x5F2E412392A317b8b80234B8a6941e0282161A25',
      );
      const response =
        (await fetchResponse.json()) as FetchTectonicSupplyResponse;
      return response.supplyApy + response.tonicSupplyApy * 100;
    },
    {
      enabled,
    },
  );
};

export const useTectonicExchangeRate = (enabled?: boolean) => {
  const coinMode = useCoinMode();
  const sdk = useVenoSDK();
  const query = getTectonicExchangeRate(coinMode, sdk);

  const { data: exchangeRate, isFetching } = useQuery(
    query.queryKey,
    query.queryFn,
    { enabled },
  );

  return {
    isFetching,
    data: exchangeRate,
  };
};

export const useMintedTotalVolume = (options?: UseQueryOptions<number>) => {
  const query = getQuery(QueryKey.MINTED_TOTAL_VOLUME);

  // TODO: enable when we have API
  return useQuery<number>(query.queryKey, query.queryFn, {
    ...options,
    enabled: false,
  });
};
