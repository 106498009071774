import { SyncSwapClassicPool__factory } from '@contracts';
import { utils } from 'ethers';
import { VenoSDKModule } from './base';
import { ONE_USD } from './constants';
import { getEthUsdPrice } from './util';
export default class SyncSwapVnoEthClassicPoolModule extends VenoSDKModule {
    constructor() {
        super(...arguments);
        this.contractFactory = SyncSwapClassicPool__factory;
        this.contractAddress = this.sdk.addresses.vvsPair;
    }
    async balanceOf(address) {
        return this.getContract().balanceOf(address);
    }
    async getLpUsdWorth(lpAmount) {
        const [[ethReserves, lethReserves], ethPriceInitial, totalSupply] = await Promise.all([
            this.getContract().getReserves(),
            getEthUsdPrice(),
            this.getContract().totalSupply(),
        ]);
        // e.g. 3424.2442 will be 34242442000000000000000000 (18 decimals)
        const ethPrice = utils.parseUnits(ethPriceInitial.toString(), 18);
        const lethPrice = ethPrice.mul(ethReserves).div(lethReserves);
        // ONE_USD is also 18 decimals
        // ethPrice / ONE_USD * ethReserves + lethPrice / ONE_USD * lethReserves
        const tvl = ethPrice
            .mul(ethReserves)
            .add(lethPrice.mul(lethReserves))
            .div(ONE_USD);
        const lpPrice = ONE_USD.mul(tvl).div(totalSupply);
        return lpPrice.mul(lpAmount).div(ONE_USD);
    }
}
