import { BigNumber } from '@ethersproject/bignumber';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import type { GardenType } from '@ui/utils/garden';
import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import type { VaultType } from '@ui/views/Vno/ReservoirAndFountain/types';
import type { EthersError } from '@veno-app/sdk';
import { create } from 'zustand';

import { GARDEN_PLANT_STEP } from './utils';

export type UseGardenPlantDrawerStore = {
  isOpen: boolean;
  isWithdraw: boolean;
  coinMode: CoinMode;
  gardenType: GardenType;
  tLcroPlantAmount: BigNumber;
  plantDirectly: boolean;
  onOpen: (options: {
    coinMode: CoinMode;
    tLcroPlantAmount: BigNumber;
    gardenType: GardenType;
    plantDirectly?: boolean;
    isWithdraw: boolean;
  }) => void;
  onClose: () => void;
  step: GARDEN_PLANT_STEP;
  setStep: (step: GARDEN_PLANT_STEP) => void;
  vaultType: VaultType | null;
  setVaultType: (vaultType: VaultType) => void;
  error: EthersError | null;
  confirmStep?: number;
  confirmStepTotal?: number;
  setConfirmStep: (confirmStep: number, confirmStepTotal: number) => void;
};

export const useGardenPlantDrawer = create(
  resetWhenRouterChange<UseGardenPlantDrawerStore>((set) => ({
    isOpen: false,
    isWithdraw: false,
    coinMode: 'cro',
    plantDirectly: false,
    tLcroPlantAmount: BigNumber.from('0'),
    gardenType: 'TECTONIC',
    onOpen: ({
      coinMode,
      tLcroPlantAmount,
      gardenType,
      plantDirectly,
      isWithdraw,
    }) => {
      set({
        isOpen: true,
        coinMode,
        tLcroPlantAmount,
        gardenType,
        plantDirectly: plantDirectly ?? false,
        isWithdraw,
        // ignored in SC logic if plantDirectly, i.e. nothing to harvest
        vaultType: plantDirectly ? 'FOUNTAIN' : null,
      });
    },
    onClose: () => {
      useGardenPlantDrawer.getState().$$resetData?.();
    },
    step: GARDEN_PLANT_STEP.default,
    setStep: (step) => {
      set({
        step,
      });
    },
    vaultType: null,
    setVaultType: (vaultType) => {
      set({
        vaultType,
      });
    },
    error: null,
    setConfirmStep: (confirmStep, confirmStepTotal) => {
      set({
        confirmStep,
        confirmStepTotal,
      });
    },
  })),
);
