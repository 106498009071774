import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

export const useSingleFinanceLendInfo = (
  options: UseQueryOptions<{
    tvlInUSD: number | undefined;
    apy: number | undefined;
  }> = {},
) => {
  return useQuery<{
    tvlInUSD: number | undefined;
    apy: number | undefined;
  }>(
    ['useSingleFinanceLendInfo'],
    async () => {
      const fetchResponse = await fetch(
        'https://api.singlefinance.io/api/info/vaults?chainid=25',
      );
      const response = (await fetchResponse.json()) as {
        data: {
          vaults: {
            name: string;
            tvlInUSD?: number | undefined;
            apy?: number | undefined;
          }[];
        };
      };

      const tokenInfo = response.data.vaults.find((v) => v.name === 'ibLCRO');

      return {
        tvlInUSD: tokenInfo?.tvlInUSD,
        apy: tokenInfo?.apy,
      };
    },
    {
      ...options,
    },
  );
};
