import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import { create } from 'zustand';

export type UseAnnouncementPopoverStore = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const useAnnouncementPopover = create(
  resetWhenRouterChange<UseAnnouncementPopoverStore>((set) => ({
    isOpen: false,
    onOpen: () => {
      set({
        isOpen: true,
      });
    },
    onClose: () => {
      useAnnouncementPopover.getState().$$resetData?.();
    },
  })),
);
