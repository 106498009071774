import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { useGlobalCoinMode } from '@ui/hooks/useCoinMode';
import { useCoinUsdValue } from '@ui/hooks/useCoinUsdValue';
import { useStrategyTokenUsdValue } from '@ui/hooks/useStrategyTokenUsdValue';
import bigNumberToNumber, {
  numberToBigNumber,
} from '@ui/utils/bigNumberToNumber';
import type { LiquidityStrategyDepositToken } from '@ui/utils/strategy';
import {
  useDepositTokenBalanceUsd,
  useFerroLpBurnedOnWithdraw,
  useFerroLpMintedOnDeposit,
  useVaultBalance,
  useVaultDepositExchangeRate,
  useVaultWithdrawExchangeRate,
} from '@ui/utils/strategy';
import { useMemo } from 'react';

export const useReceiveInfoForToken = (
  token: LiquidityStrategyDepositToken,
  amount: BigNumber,
  isWithdraw: boolean,
) => {
  const {
    receivedAmountFromWithdraw,
    receivedAmountWithdrawUsd,
    exchangeRate: exchangeRateWithdraw,
  } = useWithdrawReceiveInfoForToken(token, amount, {
    enabled: isWithdraw,
  });

  const {
    receivedAmountFromDeposit,
    receivedAmountDepositUsd,
    exchangeRate: exchangeRateDeposit,
  } = useDepositReceiveInfoForToken(token, amount, {
    enabled: !isWithdraw,
  });

  const ferroLpMintedRet = useFerroLpMintedOnDeposit(token, amount, {
    enabled: !isWithdraw,
  });

  const ferroLpBurnedRet = useFerroLpBurnedOnWithdraw(token, amount, {
    enabled: isWithdraw,
  });

  return isWithdraw
    ? {
        receivedAmount: receivedAmountFromWithdraw,
        receivedAmountUsd: receivedAmountWithdrawUsd,
        exchangeRate: exchangeRateWithdraw,
        ferroLpAmountTransferred: ferroLpBurnedRet.data,
      }
    : {
        receivedAmount: receivedAmountFromDeposit,
        receivedAmountUsd: receivedAmountDepositUsd,
        exchangeRate: exchangeRateDeposit,
        ferroLpAmountTransferred: ferroLpMintedRet.data,
      };
};

const useWithdrawReceiveInfoForToken = (
  token: LiquidityStrategyDepositToken,
  amount?: BigNumber,
  options: UseQueryOptions<number | undefined> = {},
) => {
  const exchangeRateWithdrawRet = useVaultWithdrawExchangeRate(
    token,
    amount ?? BigNumber.from(0),
    {
      ...options,
      enabled:
        options.enabled !== undefined ? options.enabled : amount !== undefined,
    },
  );
  const receivedAmountFromWithdraw = amount?.lte(0)
    ? '0.00'
    : exchangeRateWithdrawRet.data !== undefined
    ? (
        exchangeRateWithdrawRet.data *
        bigNumberToNumber(amount ?? BigNumber.from(0))
      ).toFixed(2)
    : null;
  const receivedAmountWithdrawUsd = useDepositTokenBalanceUsd({
    token,
    amount: receivedAmountFromWithdraw
      ? parseUnits(receivedAmountFromWithdraw)
      : undefined,
  });

  return {
    receivedAmountFromWithdraw,
    receivedAmountWithdrawUsd,
    exchangeRate: exchangeRateWithdrawRet.data,
  };
};

export const useDepositReceiveInfoForToken = (
  token: LiquidityStrategyDepositToken,
  amount: BigNumber,
  options: UseQueryOptions<number | undefined> = {},
) => {
  const exchangeRateDepositRet = useVaultDepositExchangeRate(token, amount, {
    enabled: options.enabled,
  });

  const receivedAmountFromDeposit = amount.lte(0)
    ? '0.00'
    : exchangeRateDepositRet.data !== undefined
    ? (bigNumberToNumber(amount) / exchangeRateDepositRet.data).toFixed(2)
    : null;

  const receivedAmountDepositUsdRet = useStrategyTokenUsdValue(
    receivedAmountFromDeposit
      ? parseUnits(receivedAmountFromDeposit)
      : undefined,
  );
  const receivedAmountDepositUsd = numberToBigNumber(
    receivedAmountDepositUsdRet,
  );

  return {
    receivedAmountFromDeposit,
    receivedAmountDepositUsd,
    exchangeRate: exchangeRateDepositRet.data,
  };
};

export const useReceiveTokenInfo = <T extends BigNumber | undefined>(
  amount: T,
):
  | {
      lowest: undefined;
      highest: undefined;
      maxAmount: undefined;
      minAmount: undefined;
    }
  | {
      lowest: LiquidityStrategyDepositToken;
      highest: LiquidityStrategyDepositToken;
      maxAmount: BigNumber;
      minAmount: BigNumber;
    } => {
  const { receivedAmountWithdrawUsd: receiveForCroUsd } =
    useWithdrawReceiveInfoForToken('cro', amount);
  const { receivedAmountWithdrawUsd: receiveForLcroUsd } =
    useWithdrawReceiveInfoForToken('lcro', amount);
  const { receivedAmountWithdrawUsd: receiveForWcroUsd } =
    useWithdrawReceiveInfoForToken('wcro', amount);
  const { receivedAmountWithdrawUsd: receiveForLPUsd } =
    useWithdrawReceiveInfoForToken('ferroLP', amount);

  const values: Record<LiquidityStrategyDepositToken, BigNumber | undefined> = {
    cro: receiveForCroUsd,
    lcro: receiveForLcroUsd,
    wcro: receiveForWcroUsd,
    ferroLP: receiveForLPUsd,
  };

  if (amount?.lte(0)) {
    return {
      lowest: 'cro',
      highest: 'cro',
      minAmount: BigNumber.from(0),
      maxAmount: BigNumber.from(0),
    };
  }

  if (
    amount === undefined ||
    Object.values(values).some((value) => value === undefined)
  )
    return {
      lowest: undefined,
      highest: undefined,
      minAmount: undefined,
      maxAmount: undefined,
    };

  const minEntry = Object.entries(
    values as Record<LiquidityStrategyDepositToken, BigNumber>,
  ).reduce((min, entry) => (min[1].lt(entry[1]) ? min : entry)) as [
    LiquidityStrategyDepositToken,
    BigNumber,
  ];
  const maxEntry = Object.entries(
    values as Record<LiquidityStrategyDepositToken, BigNumber>,
  ).reduce((max, entry) => (max[1].gt(entry[1]) ? max : entry)) as [
    LiquidityStrategyDepositToken,
    BigNumber,
  ];

  if (
    minEntry[0] === 'wcro' &&
    receiveForCroUsd?.eq(receiveForWcroUsd ?? BigNumber.from(0))
  )
    minEntry[0] = 'cro';
  if (
    maxEntry[0] === 'wcro' &&
    receiveForCroUsd?.eq(receiveForWcroUsd ?? BigNumber.from(0))
  )
    maxEntry[0] = 'cro';

  return {
    lowest: minEntry[0],
    highest: maxEntry[0],
    maxAmount: maxEntry[1],
    minAmount: minEntry[1],
  };
};

/**
 * Returns the highest CRO value that can be withdrawn from the strategy.
 * It's calculated by getting the highest USD amount that can be withdrawn and converting it
 * with crypto.com CRO exchange rate. It works because all withdrawable tokens USD prices
 * are based on CRO price and exchange rates on Veno.
 *
 * @param _coinMode
 * @returns highest CRO value that can be withdrawn from the strategy
 */
export const useBestStrategyCroValue = (_coinMode?: CoinMode) => {
  const globalCoinMode = useGlobalCoinMode();
  const enabled = (_coinMode ?? globalCoinMode) === 'cro';
  const vaultBalanceRet = useVaultBalance({ enabled });
  // get max usd equivalent to be withdrawn from strategy
  const { maxAmount } = useReceiveTokenInfo(vaultBalanceRet.data);

  const oneCroUsdValue = useCoinUsdValue(
    numberToBigNumber(1),
    'cro',
    enabled && !!maxAmount,
  ).data;

  return useMemo(
    () =>
      maxAmount && oneCroUsdValue !== undefined
        ? maxAmount.mul(numberToBigNumber(1)).div(oneCroUsdValue)
        : undefined,
    [maxAmount, oneCroUsdValue],
  );
};
