import {
  getPathInfo,
  isCategoryInSameView,
  useRealPathname,
} from '@ui/hooks/router';
import useCoinBalance from '@ui/hooks/useCoinBalance';
import { withReset } from '@ui/utils/zustandMiddleware';
import { useGardenPlantDrawer } from '@ui/views/Garden/GardenPlantDrawer/useGardenPlantDrawer';
import { useGardenModalStore } from '@ui/views/Garden/useGardenModalStore';
import { useSupplyLcroModalStore } from '@ui/views/UseLcroDirectly/useSupplyLcroModalStore';
import { useEffect } from 'react';
import type { Step } from 'react-joyride';
import { create } from 'zustand';

import { VenoTour } from './utils/VenoTour';

export const UseLcroTour = () => {
  const pathInfo = getPathInfo(useRealPathname());
  const stepIndex = useUseLcroTour((s) => s.stepIndex);
  const inputFerroAmountStr = useUseLcroTour((s) => s.inputFerroAmountStr);
  const inputGardenAmountStr = useUseLcroTour((s) => s.inputGardenAmountStr);
  const balanceRet = useCoinBalance('cro');
  const hasBalance = balanceRet.data && balanceRet.data >= 1;
  const isFerroModalOpen = useSupplyLcroModalStore(
    (s) => s.isOpen && s.type === 'FERRO',
  );
  const isFerroGardenOpen = useGardenModalStore(
    (s) => s.isOpen && s.type === 'FERRO',
  );
  const isFerroPlantModalOpen = useGardenPlantDrawer(
    (s) => s.isOpen && s.gardenType === 'FERRO',
  );
  const isFerroPlantModalDirectly = useGardenPlantDrawer(
    (s) => s.isOpen && s.gardenType === 'FERRO' && s.plantDirectly,
  );
  const isFerroPlantModalVaultType = useGardenPlantDrawer(
    (s) => s.isOpen && s.gardenType === 'FERRO' && s.vaultType,
  );

  const isUseLcroPage =
    pathInfo.coinMode === 'cro' &&
    isCategoryInSameView(pathInfo.category, 'useLiquid');

  const isGardenPage =
    pathInfo.gardenType === 'FERRO' &&
    isCategoryInSameView(pathInfo.category, 'garden');

  const plantModalConfirmingSteps: Step[] = [
    {
      target: '.veno-tour__plant-modal-vault-selection',
      content: 'Select vault to harvest',
    },
    {
      target: '.veno-tour__plant-modal-confirm-button',
      content: 'Click on Plant',
    },
  ];

  const steps: Step[] = [
    {
      target: '.veno-tour__use-liquid-ferro-supply',
      content: 'Provide liquidity of CRO on Ferro',
    },
    {
      target: '.veno-tour__supply-modal-input',
      content: 'Enter LCRO amount',
      hideFooter: inputFerroAmountStr ? false : true,
    },
    {
      target: '.veno-tour__supply-modal-received-row',
      content: 'This is how much LP tokens you will receive',
      spotlightClicks: false,
    },
    {
      target: '.veno-tour__supply-modal-confirm-button',
      content: 'Click on Supply Now',
    },
    {
      target: 'body',
      content: 'Confirming Transaction',
      placement: 'center',
    },
    {
      target: '.veno-tour__supply-modal-plant-button',
      content: 'Click on Take me to the Garden',
    },
    {
      target: '.veno-tour__garden-modal-plant-input',
      content: 'Enter LP Amount',
      hideFooter: inputGardenAmountStr ? false : true,
    },
    {
      target: '.veno-tour__garden-modal-plant-button',
      content: 'Click on Plant',
    },
    ...(isFerroPlantModalDirectly ? [] : plantModalConfirmingSteps),
    {
      target: 'body',
      content: 'Confirming Transaction',
      placement: 'center',
    },
    {
      target: '.veno-tour__plant-modal-grow-button',
      content: 'Click on Grow Your Garden',
    },
  ];

  const nextStepTarget = steps[stepIndex + 1]?.target;
  const currentStepTarget = steps[stepIndex]?.target;

  useEffect(() => {
    if (
      isFerroModalOpen &&
      nextStepTarget === '.veno-tour__supply-modal-input'
    ) {
      useUseLcroTour.getState().next();
    }
  }, [isFerroModalOpen, nextStepTarget]);

  useEffect(() => {
    if (
      isFerroGardenOpen &&
      nextStepTarget === '.veno-tour__garden-modal-plant-input'
    ) {
      useUseLcroTour.getState().next();
    }
  }, [isFerroGardenOpen, nextStepTarget]);

  useEffect(() => {
    if (
      isFerroPlantModalOpen &&
      nextStepTarget === '.veno-tour__plant-modal-vault-selection'
    ) {
      useUseLcroTour.getState().next();
    }
  }, [isFerroPlantModalOpen, nextStepTarget]);

  useEffect(() => {
    if (
      isFerroPlantModalOpen &&
      isFerroPlantModalVaultType &&
      nextStepTarget === '.veno-tour__plant-modal-confirm-button'
    ) {
      useUseLcroTour.getState().next();
    }
  }, [isFerroPlantModalOpen, nextStepTarget, isFerroPlantModalVaultType]);

  useEffect(() => {
    if (
      !isFerroPlantModalOpen &&
      currentStepTarget === '.veno-tour__plant-modal-grow-button'
    ) {
      useUseLcroTour.getState().next();
    }
  }, [currentStepTarget, isFerroPlantModalOpen]);

  /**
   * only working on the garden page and useLCRO page
   */
  if (!hasBalance || (!isUseLcroPage && !isGardenPage)) {
    return null;
  }

  return (
    <VenoTour
      tourId="UseLcroTour"
      withWalletTour
      steps={steps}
      stepIndex={stepIndex}
      onStepIndexChange={(stepIndex, isFinished) => {
        if (isFinished) {
          useUseLcroTour.getState().$$resetData?.();
        } else {
          useUseLcroTour.setState({
            stepIndex: stepIndex,
          });
        }
      }}
    />
  );
};

type UseUseLcroTourStore = {
  inputFerroAmountStr: string;
  inputGardenAmountStr: string;
  stepIndex: number;
  next: () => void;
};

export const useUseLcroTour = create(
  withReset<UseUseLcroTourStore>((set) => ({
    inputFerroAmountStr: '',
    inputGardenAmountStr: '',
    stepIndex: 0,
    next: () => {
      set((s) => {
        return {
          stepIndex: s.stepIndex + 1,
        };
      });
    },
  })),
);
