import type { BoxProps, ButtonProps } from '@chakra-ui/react';
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useAccountModalStore } from '@ui/components/AccountModal';
import { InDesktop, InMobile } from '@ui/components/MobileOrDesktop';
import { pathDict } from '@ui/config/paths';
import { Footer } from '@ui/Footer';
import {
  getPathInfo,
  isCategoryInSameView,
  useRealPathname,
} from '@ui/hooks/router';
import { useCoinMode } from '@ui/hooks/useCoinMode';
import { useTranslations } from '@ui/i18n';
import Link from '@ui/overrides/next/link';
import { useRouter } from '@ui/overrides/next/router';
import { MAXW_DESKTOP_CONTENT } from '@ui/utils/constants';
import {
  Alarm,
  AlarmFilled,
  ArrowBack,
  Close,
  Faq,
  Lang,
  MenuTrigger,
  Stats,
  Vno,
} from '@veno-app/icons';
import { currentWallet, ZKSYNC_ENABLED } from '@veno-app/wallet';
import type { Dispatch, ReactNode, SetStateAction, SVGProps } from 'react';
import { createContext, forwardRef, useEffect, useMemo, useState } from 'react';

import { AnnouncementPopover } from './AnnouncementPopover';
import { useAnnouncementPopover } from './AnnouncementPopover/useAnnouncementPopover';
import { useUnreadAnnouncementQuery } from './AnnouncementPopover/utils';
import FaqInfo from './Faq/FaqInfo';
import Features from './Features';
import { LangMenu } from './LangMenu';
import { useTopNavMenuDrawer } from './TopNavMenuDrawer';
import { useWalletModal } from './Wallet';
import { WalletSwitchNetworkDrawer } from './WalletSwitchNetworkDrawer';

const {
  useIsConnected,
  useIsZksyncChainId,
  useIsConnectedToSupportedChain,
  useWalletConfig,
} = currentWallet;
interface TopNavContextValue {
  setBackAction: Dispatch<SetStateAction<(() => void) | undefined>>;
}

const defaultContextValue: TopNavContextValue = {
  setBackAction: () => {},
};

export const TopNavContext =
  createContext<TopNavContextValue>(defaultContextValue);

const TOP_BAR_SPACE_PX = 81;
const MOBILE_BOTTOM_BAR_SPACE = 80;

const TopNavProvider = ({ children }: { children: ReactNode }) => {
  const t = useTranslations();
  const isZksync = useIsZksyncChainId();
  const router = useRouter();
  const globalCoinMode = useCoinMode();
  const realPathname = useRealPathname();
  const pathInfo = getPathInfo(realPathname);

  const toggleAccountModal = useAccountModalStore(
    (store) => store.toggleAccountModal,
  );
  const [showFaq, setShowFaq] = useState(false);

  const { open: openWalletModal } = useWalletModal();
  const [backAction, setBackAction] = useState<(() => void) | undefined>(
    undefined,
  );
  const isConnected = useIsConnected();
  const walletConfig = useWalletConfig();
  const isDesktop = useBreakpointValue({
    base: false,
    desktop: true,
  });
  const isConnectedToSupportedChain = useIsConnectedToSupportedChain();
  const {
    isOpen: isWalletSwitchNetworkDrawerOpen,
    onClose: onWalletSwitchNetworkDrawerClose,
    onOpen: onWalletSwitchNetworkDrawerOpen,
  } = useDisclosure();
  const isMenuOpen = useTopNavMenuDrawer((s) => s.isOpen);

  useEffect(() => {
    setShowFaq(false);
  }, [router.asPath]);

  return (
    <TopNavContext.Provider value={{ setBackAction }}>
      <Box
        bgColor="bg.main"
        zIndex="banner"
        position={{ base: 'relative', desktop: 'fixed' }}
        insetX="0"
        pr="var(--removed-body-scroll-bar-size)"
      >
        <Flex
          mx="auto"
          maxW={MAXW_DESKTOP_CONTENT}
          justify="space-between"
          align="center"
          height="61px"
          mb="20px"
          paddingInline={4}
          position="relative"
        >
          <Box flex={1}>
            {backAction && (
              <TopNavButton
                onClick={backAction}
                icon={ArrowBack}
                aria-label="go back button"
              />
            )}
            {!backAction &&
              (!isCategoryInSameView(pathInfo.category, 'landing') ||
                isDesktop) && (
                <Link href={pathDict[globalCoinMode].landing}>
                  <Box>
                    <InDesktop>
                      <Image
                        cursor="pointer"
                        alt="VENO"
                        w="124px"
                        h="30px"
                        src="/images/text-logo-small.png"
                      />
                    </InDesktop>
                    <InMobile>
                      <Icon color="text.active" w="36px" h="40px" as={Vno} />
                    </InMobile>
                  </Box>
                </Link>
              )}
          </Box>
          <InDesktop>
            <Flex flex={1} textStyle="h4" color="text.inactive" gap={8}>
              <TopNavLink
                linkPath={pathDict[globalCoinMode].dashboard}
                text={t('Dashboard')}
              />
              <TopNavLink
                className="veno-tour__stake-nav-link"
                linkPath={pathDict[globalCoinMode].stake}
                text={t('Stake')}
              />
              <TopNavLink
                linkPath={pathDict[globalCoinMode].unstake}
                text={t('Unstake')}
              />
              <TopNavLink
                linkPath={pathDict[globalCoinMode].vno}
                text={'VNO'}
              />
            </Flex>
          </InDesktop>
          {!backAction && (
            <Flex flex={1} gap="8px" justify="flex-end">
              <InDesktop>
                <LangMenu>
                  <TopNavButton
                    as="span"
                    icon={Lang}
                    aria-label="Language button"
                    hoverLabel={t('Language')}
                  />
                </LangMenu>
                <Features.statsPage>
                  <Link href={pathDict[globalCoinMode].statsVno}>
                    <TopNavButton
                      icon={Stats}
                      aria-label="stats button"
                      hoverLabel={t('Statistics')}
                      onClick={() => {
                        setShowFaq(false);
                      }}
                    />
                  </Link>
                </Features.statsPage>
                <TopNavButton
                  active={showFaq}
                  onClick={() => {
                    setShowFaq((showFaq) => !showFaq);
                    toggleAccountModal(false);
                  }}
                  icon={Faq}
                  aria-label="FAQ button"
                  hoverLabel="FAQs"
                />
                <AnnouncementButton />
              </InDesktop>

              {isConnected && !isConnectedToSupportedChain ? (
                <TopNavButton
                  onClick={onWalletSwitchNetworkDrawerOpen}
                  color={'error'}
                  text={t('Wrong network')}
                  aria-label="switch network button"
                />
              ) : (
                <TopNavButton
                  className="veno-tour__wallet"
                  onClick={() => {
                    setShowFaq(false);
                    if (isConnected) {
                      toggleAccountModal();
                    } else {
                      openWalletModal();
                    }
                  }}
                  icon={
                    isConnected ? (
                      ZKSYNC_ENABLED ? (
                        <Flex p={2} rounded="5px" gap={2} shadow="card.normal">
                          {isZksync ? (
                            <Image
                              src="/images/zksync-light.svg"
                              w="24px"
                              h="24px"
                              alt="zksync-network"
                            />
                          ) : (
                            <Image
                              src="/tokens/cro.svg"
                              w="24px"
                              h="24px"
                              alt="cronos-network"
                            />
                          )}
                          <Image
                            src={walletConfig?.icon}
                            width="24px"
                            height="24px"
                            alt="wallet icon"
                          />
                        </Flex>
                      ) : (
                        <Image
                          src={walletConfig?.icon}
                          width="24px"
                          height="24px"
                          alt="wallet icon"
                        />
                      )
                    ) : undefined
                  }
                  text={!isConnected ? t('Connect wallet') : undefined}
                  aria-label="account modal button"
                />
              )}

              <InMobile>
                <AnnouncementButton />
                <TopNavButton
                  active={isMenuOpen}
                  icon={MenuTrigger}
                  aria-label="menu button"
                  onClick={() => {
                    useTopNavMenuDrawer.getState().onOpen();
                  }}
                />
              </InMobile>
            </Flex>
          )}
        </Flex>
      </Box>
      {/* subtract top and bottom bars */}
      <Flex
        minH={{
          base: `calc(100vh - ${TOP_BAR_SPACE_PX}px - ${MOBILE_BOTTOM_BAR_SPACE}px)`,
          desktop: `100vh`,
        }}
        pt={{ desktop: `${TOP_BAR_SPACE_PX}px` }}
        flexDir="column"
        mx="auto"
        maxW={MAXW_DESKTOP_CONTENT}
        px={{
          base: '0',
          desktop: 4,
        }}
      >
        {!showFaq ? (
          children
        ) : (
          <>
            <FaqInfo showTitle={true} closeFaq={() => setShowFaq(false)} />
            <Footer
              hidden={!isCategoryInSameView(pathInfo.category, 'landing')}
            />
          </>
        )}
      </Flex>
      <WalletSwitchNetworkDrawer
        isOpen={isWalletSwitchNetworkDrawerOpen}
        onClose={onWalletSwitchNetworkDrawerClose}
      />
    </TopNavContext.Provider>
  );
};

type TopNavLinkProps = {
  className?: string;
  linkPath: string;
  text: string;
};

const TopNavLink = ({ linkPath, text, className }: TopNavLinkProps) => {
  const realPathname = useRealPathname();
  const pathInfo = getPathInfo(realPathname);
  const linkInfo = getPathInfo(linkPath);
  const active = isCategoryInSameView(linkInfo.category, pathInfo.category);

  return (
    <Link href={linkPath}>
      <Text
        className={className}
        px={'18px'}
        pb={1}
        color={active ? 'text.active' : undefined}
        borderBottom={active ? '1px solid' : undefined}
        borderColor="text.active"
        cursor="pointer"
        fontWeight={active ? 'bold' : 'normal'}
        whiteSpace="nowrap"
      >
        {text}
      </Text>
    </Link>
  );
};

interface TopNavButtonProps extends ButtonProps {
  onClick?: () => void;
  icon?: ReactNode | ((props: SVGProps<SVGSVGElement>) => JSX.Element);
  text?: string;
  active?: boolean;
  color?: ButtonProps['color'];
  'aria-label': ButtonProps['aria-label'];
  hoverLabel?: ReactNode;
}

const TopNavButton = forwardRef<HTMLButtonElement, TopNavButtonProps>(
  ({ onClick, icon, text, active, color, hoverLabel, ...props }, ref) => {
    const iconNode = useMemo(() => {
      if (!icon) {
        return null;
      }
      if (active) {
        return <Icon as={Close} />;
      }
      if (typeof icon === 'function') {
        return <Icon as={icon} />;
      }
      return icon;
    }, [active, icon]);

    return (
      <Button
        ref={ref}
        variant="ghost"
        onClick={onClick}
        isActive={active}
        color={color}
        px="0px"
        position="relative"
        {...props}
      >
        {iconNode}
        {text}
        {hoverLabel && (
          <Box
            display="none"
            textStyle="captionSmall"
            fontWeight="700"
            position="absolute"
            top="100%"
            sx={{
              '.chakra-button:hover &': {
                display: 'block',
              },
            }}
          >
            {hoverLabel}
          </Box>
        )}
      </Button>
    );
  },
);
TopNavButton.displayName = 'TopNavButton';

const AnnouncementButton = (props: BoxProps) => {
  const t = useTranslations();
  const isOpen = useAnnouncementPopover((s) => s.isOpen);
  const unreadAnnouncementRet = useUnreadAnnouncementQuery();
  const hasUnreadAnnouncement = Boolean(unreadAnnouncementRet.data?.length);

  return (
    <Features.announcement>
      <AnnouncementPopover>
        <Box position="relative" {...props}>
          <TopNavButton
            active={isOpen}
            icon={hasUnreadAnnouncement ? AlarmFilled : Alarm}
            aria-label="announcement button"
            hoverLabel={t('Announcements')}
          />
          <Box
            hidden={!hasUnreadAnnouncement || isOpen}
            w="6px"
            h="6px"
            position="absolute"
            top="12px"
            right="12px"
            borderRadius="100%"
            bg="error"
          />
        </Box>
      </AnnouncementPopover>
    </Features.announcement>
  );
};

export default TopNavProvider;
