export var ChainId;
(function (ChainId) {
    ChainId[ChainId["Mainnet"] = 25] = "Mainnet";
    ChainId[ChainId["Dryrun"] = 28] = "Dryrun";
    ChainId[ChainId["Testnet"] = 338] = "Testnet";
    ChainId[ChainId["ZksyncMainnet"] = 324] = "ZksyncMainnet";
    ChainId[ChainId["ZksyncTestnet"] = 300] = "ZksyncTestnet";
})(ChainId || (ChainId = {}));
export const ZKSYNC_ENABLED = process.env.NEXT_PUBLIC_ZKSYNC_ENABLED === 'true';
export const CRONOS_CHAIN_ID = (() => {
    switch (process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK) {
        case 'cronos':
            return ChainId.Mainnet;
        case 'cronos_testnet':
            return ChainId.Testnet;
        case 'veno_dryrun':
            return ChainId.Dryrun;
        default:
            throw new Error('process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK is incorrect');
    }
})();
export const ZKSYNC_CHAIN_ID = (() => {
    switch (process.env.NEXT_PUBLIC_DEFAULT_ZKSYNC_NETWORK) {
        case 'zksync':
            return ChainId.ZksyncMainnet;
        case 'zksync_testnet':
            return ChainId.ZksyncTestnet;
        default:
            throw new Error('process.env.NEXT_PUBLIC_DEFAULT_ZKSYNC_NETWORK is incorrect');
    }
})();
export const SUPPORTED_CHAIN_IDS = [
    CRONOS_CHAIN_ID,
    ZKSYNC_CHAIN_ID,
];
export const MINTED_URL = {
    [ChainId.Mainnet]: 'https://minted.network',
    [ChainId.Testnet]: 'https://msta-minted.crorc.co',
    [ChainId.Dryrun]: 'https://msta-minted.crorc.co',
    [ChainId.ZksyncMainnet]: '',
    [ChainId.ZksyncTestnet]: '',
};
export const explorers = {
    [ChainId.Mainnet]: 'https://explorer.cronos.org',
    [ChainId.Testnet]: 'https://explorer.cronos.org/testnet',
    [ChainId.Dryrun]: 'https://cronos.org/explorer/dryrun',
    [ChainId.ZksyncMainnet]: 'https://explorer.zksync.io',
    [ChainId.ZksyncTestnet]: 'https://sepolia.explorer.zksync.io',
};
export const NETWORK_CONFIG = {
    [ChainId.Mainnet]: {
        chainId: ChainId.Mainnet,
        chainName: 'Cronos',
        nativeCurrency: {
            name: 'CRO',
            symbol: 'cro',
            decimals: 18,
        },
        rpcUrls: [
            'https://mainnet.cronoslabs.com/v1/89433fdc930781343d3465d593a76dfd',
        ],
        blockExplorerUrls: [explorers[ChainId.Mainnet]],
    },
    [ChainId.Testnet]: {
        chainId: ChainId.Testnet,
        chainName: 'Cronos Testnet',
        nativeCurrency: {
            name: 'CRO',
            symbol: 'cro',
            decimals: 18,
        },
        rpcUrls: [
            'https://testnet.cronoslabs.com/v1/8181e393e7bf74bbde5954c055b54e03',
        ],
        blockExplorerUrls: [explorers[ChainId.Testnet]],
    },
    [ChainId.Dryrun]: {
        chainId: ChainId.Dryrun,
        chainName: 'Veno Dryrun',
        nativeCurrency: {
            name: 'CRO',
            symbol: 'cro',
            decimals: 18,
        },
        rpcUrls: ['https://cronos-dryrun.3ona.co:8545'],
        blockExplorerUrls: [explorers[ChainId.Dryrun]],
    },
    [ChainId.ZksyncMainnet]: {
        chainId: ChainId.ZksyncMainnet,
        chainName: 'zkSync Era',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'eth',
            decimals: 18,
        },
        rpcUrls: ['https://mainnet.era.zksync.io'],
        blockExplorerUrls: [explorers[ChainId.ZksyncMainnet]],
    },
    [ChainId.ZksyncTestnet]: {
        chainId: ChainId.ZksyncTestnet,
        chainName: 'zkSync Test',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'eth',
            decimals: 18,
        },
        rpcUrls: ['https://sepolia.era.zksync.dev'],
        blockExplorerUrls: [explorers[ChainId.ZksyncTestnet]],
    },
};
export const RPC_URLS = {
    [ChainId.Mainnet]: NETWORK_CONFIG[ChainId.Mainnet].rpcUrls[0],
    [ChainId.Testnet]: NETWORK_CONFIG[ChainId.Testnet].rpcUrls[0],
    [ChainId.Dryrun]: NETWORK_CONFIG[ChainId.Dryrun].rpcUrls[0],
    [ChainId.ZksyncMainnet]: NETWORK_CONFIG[ChainId.ZksyncMainnet].rpcUrls[0],
    [ChainId.ZksyncTestnet]: NETWORK_CONFIG[ChainId.ZksyncTestnet].rpcUrls[0],
};
export function getNetworkConfig(isZksync) {
    if (process.env.NODE_ENV === 'test') {
        return NETWORK_CONFIG[isZksync ? ChainId.ZksyncTestnet : ChainId.Testnet];
    }
    const network = process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK;
    const networkZksync = process.env.NEXT_PUBLIC_DEFAULT_ZKSYNC_NETWORK;
    if (isZksync) {
        if (networkZksync === 'zksync') {
            return NETWORK_CONFIG[ChainId.ZksyncMainnet];
        }
        else {
            return NETWORK_CONFIG[ChainId.ZksyncTestnet];
        }
    }
    if (network === 'cronos') {
        return NETWORK_CONFIG[ChainId.Mainnet];
    }
    else if (network === 'veno_dryrun') {
        return NETWORK_CONFIG[ChainId.Dryrun];
    }
    else {
        return NETWORK_CONFIG[ChainId.Testnet];
    }
}
