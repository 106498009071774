/**
 * Checkbox theme
 * @see https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src/components/checkbox.ts
 */
import { checkboxAnatomy as parts } from '@chakra-ui/anatomy';
const baseStyle = {
    control: {
        borderColor: 'text.active',
        rounded: '2px',
        borderWidth: '1px',
    },
};
export const Checkbox = {
    parts: parts.keys,
    baseStyle,
    variants: {
        pink: {
            control: {
                ...baseStyle.control,
                borderColor: 'pink.500',
            },
        },
    },
    defaultProps: {
        colorScheme: 'primary',
    },
};
