import type { CoinMode } from '@ui/hooks/useCoinMode';
import type { GardenType } from '@ui/utils/garden';
import { getPidType } from '@ui/utils/garden';
import type { CollectionNft } from '@ui/views/Garden/GardenViewGrowHelpers/types';
import type { VenoSDK } from '@veno-app/sdk';

import { QueryKey } from './queryKey';
import type { NftCollection } from './types';
import { getNftCollectionAddress, getNftCollections } from './types';

const API_URL = process.env.NEXT_PUBLIC_OWNER_NFTS_URL || '';

export type OwnedNftResponse = {
  pageInfo: { hasNextPage: boolean; endCursor?: string };
  assets: { name: string; tokenId: string; image: { url: string } }[];
};

export const getStakedNfts = (
  coinMode: CoinMode,
  type: GardenType,
  address: string,
  sdk: VenoSDK,
  isZksync: boolean,
) => {
  const queryKey = [
    QueryKey.STAKED_NFTS,
    sdk,
    coinMode,
    address,
    type,
    isZksync,
  ];
  const queryFn = async (): Promise<CollectionNft[]> => {
    const idsPromiseList: {
      collection: NftCollection;
      idsPromise: Promise<number[]>;
    }[] = getNftCollections(sdk.chainType).map((collection) => {
      return {
        collection,
        idsPromise: sdk.VenoStorm.getStakedNftTokenIds(
          getPidType(coinMode, type),
          collection,
          address,
        ),
      };
    });

    const idsList = await Promise.all(
      idsPromiseList.map(async ({ collection, idsPromise }) => {
        const ids = await idsPromise;
        return ids.map((id) => ({
          id: String(id),
          collection,
        }));
      }),
    );

    return idsList.flat();
  };

  return { queryKey, queryFn };
};

export const getBoostMultipliersForNfts = (
  tokens: CollectionNft[],
  sdk: VenoSDK,
) => {
  const queryKey = [
    QueryKey.BOOST_MULTIPLIERS_NFTS,
    sdk,
    tokens.map((t) => `${t.collection}:${t.id}`).join('-'),
  ];

  const queryFn = async (): Promise<number[]> => {
    const nftCollections = getNftCollections(sdk.chainType);

    const idsPerCollection: Record<NftCollection, string[]> = Object.assign(
      {},
      ...Object.keys(nftCollections).map((x) => ({ [x]: [] })),
    );

    for (let i = 0; i < nftCollections.length; i++) {
      const collection = nftCollections[i];
      idsPerCollection[collection] = tokens
        .filter((t) => t.collection === collection)
        .map((t) => t.id);
    }

    const boostPromises: Promise<number[]>[] = nftCollections.map(
      (collection) =>
        sdk.VenoStormMultiplierReader.getBoostMultiplierFromTokenIds(
          idsPerCollection[collection].map((id) => parseInt(id)),
          collection,
        ),
    );
    const multipliersPerCollectionResults = await Promise.allSettled(
      boostPromises,
    );

    const multipliers = tokens.map((token) => {
      const collectionIndex = nftCollections.findIndex(
        (c) => c === token.collection,
      );
      const nftIndex = idsPerCollection[token.collection].findIndex(
        (id) => id === token.id,
      );

      const multipliersResult =
        multipliersPerCollectionResults[collectionIndex];
      if (multipliersResult.status === 'fulfilled') {
        return multipliersResult.value[nftIndex] ?? 0;
      }

      return 0;
    });

    return multipliers;
  };

  return { queryKey, queryFn };
};

export const getOwnedNfts = (
  address: string,
  collection: NftCollection,
  sdk: VenoSDK,
  cursor?: string,
) => {
  const queryKey = [
    QueryKey.OWNED_NFTS,
    sdk,
    address,
    collection,
    cursor ?? '',
  ];
  const queryFn = async (): Promise<OwnedNftResponse> => {
    const network = process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK;
    if (network === 'veno_dryrun') {
      const ids: number[] = await (collection === 'CRUISER'
        ? sdk.Cruiser.getOwnerTokenIds(address)
        : []);
      return {
        // this is just data for testing purposes, image only for demonstration
        pageInfo: { hasNextPage: false },
        assets: ids.map((id) => ({
          name: `Cruiser #${id}`,
          tokenId: id.toString(),
          image: {
            url: 'https://images.minted.network/?image=c9466ee916d24d88ef29422c738015d5ece27f5724cdefa212ae33e2f8937c75&width=750&quality=75',
          },
        })),
      };
    }
    let fetchResponse: Response | null = null;
    if (!API_URL) throw new Error('nft api endpoint config missing');
    fetchResponse = await fetch(
      API_URL + `${getNftCollectionAddress(collection)}/${address}`,
      cursor ? { body: JSON.stringify({ after: cursor }) } : undefined,
    );
    const response = (await fetchResponse.json()) as OwnedNftResponse;
    return response;
  };

  return { queryFn, queryKey };
};
