import { Box, Button, Flex, Image } from '@chakra-ui/react';
import { useSwitchNetwork } from '@ui/hooks/useSwitchNetwork';
import { currentWallet } from '@veno-app/wallet';
import React from 'react';

const { useIsZksyncChainId } = currentWallet;

const AccountModalNetworkSwitchButton: React.FC = () => {
  const { switchNetwork, isSwitching } = useSwitchNetwork();
  const isZksync = useIsZksyncChainId();
  const activeChain = isZksync ? 'Zksync' : 'Cronos';

  if (isSwitching) return <Button isLoading={true} disabled={true} />;

  return (
    <Flex
      cursor="pointer"
      p="3px"
      borderRadius="50px"
      gap="16px"
      bg="bg.button"
      alignItems="center"
      onClick={() => switchNetwork(activeChain === 'Cronos')}
    >
      {activeChain === 'Cronos' ? (
        <>
          {/* Active Cronos Side */}
          <Flex
            w="103px"
            h="34px"
            alignItems="center"
            justifyContent="center"
            bgColor="bg.main"
            borderRadius="27px"
            bgImage="linear-gradient(to bottom, #EBF0F3, #D6E0E7)"
          >
            <Image
              src="/images/cronos-network.svg"
              w="80px"
              h="19px"
              alt="cronos-network"
            />
          </Flex>
          {/* Inactive zkSync Side */}
          <Box>
            <Image
              mr="9px"
              src="/images/zksync-dark.svg"
              w="24px"
              h="24px"
              alt="zksync-network"
            />
          </Box>
        </>
      ) : (
        <>
          {/* Inactive Cronos Side */}
          <Box>
            <Image
              ml="9px"
              src="/images/cronos-dark.svg"
              w="24px"
              h="24px"
              alt="cronos-network"
            />
          </Box>
          {/* Active zkSync Side */}
          <Flex
            w="103px"
            h="34px"
            alignItems="center"
            justifyContent="center"
            bgColor="bg.main"
            borderRadius="27px"
            bgImage="linear-gradient(to bottom, #EBF0F3, #D6E0E7)"
          >
            <Image
              src="/images/zksync-network.svg"
              w="82px"
              h="16px"
              alt="zksync-network"
            />
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default AccountModalNetworkSwitchButton;
