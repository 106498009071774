import { SyncSwapClassicPool__factory } from '@contracts';
import { ethers } from 'ethers';
import { VenoSDKModule } from './base';
import { ONE_USD } from './constants';
import { getEthUsdPrice } from './util';
export default class SyncSwapLethEthClassicPoolModule extends VenoSDKModule {
    constructor() {
        super(...arguments);
        this.contractFactory = SyncSwapClassicPool__factory;
        this.contractAddress = this.sdk.addresses.ferroLp;
    }
    async balanceOf(address) {
        return this.getContract().balanceOf(address);
    }
    async getLpUsdWorth(lpAmount, lethPerEthPromise) {
        const [[ethReserves, lethReserves], ethPriceInitial, totalSupply, lethPerEth,] = await Promise.all([
            this.getContract().getReserves(),
            getEthUsdPrice(),
            this.getContract().totalSupply(),
            Promise.resolve(lethPerEthPromise),
        ]);
        // e.g. 3424.2442 will be 34242442000000000000000000 (18 decimals)
        const ethPrice = ethers.utils.parseUnits(ethPriceInitial.toString(), 18);
        const lethPerEthBN = ethers.utils.parseUnits(lethPerEth.toString(), 18);
        // Calculate the TVL using the exchange rate (lethPerEth)
        // ONE_USD is also 18 decimals
        // ethPrice / ONE_USD * ethReserves + (ethPrice / ONE_USD * (lethReserves * lethPerEth))
        const lethValueInEth = lethReserves.mul(lethPerEthBN).div(ONE_USD);
        const tvl = ethPrice
            .mul(ethReserves)
            .add(ethPrice.mul(lethValueInEth))
            .div(ONE_USD);
        const lpPrice = tvl.mul(ONE_USD).div(totalSupply);
        return lpPrice.mul(lpAmount).div(ONE_USD);
    }
}
