import type { BigNumberish } from '@ethersproject/bignumber';
import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits, parseUnits } from '@ethersproject/units';
import numbro from 'numbro';

export const formatNumber = (
  n: number,
  displayDecimals = 4,
  trimMantissa?: boolean,
  average?: boolean,
) =>
  numbro(n).format({
    thousandSeparated: true,
    mantissa: displayDecimals,
    trimMantissa: trimMantissa ?? false,
    average: average ?? false,
  });

function formatTokenAmount(
  amount: undefined,
  displayDecimals?: number,
  unitDecimals?: number,
  trimMantissa?: boolean,
  average?: boolean,
): null;
function formatTokenAmount(
  amount: BigNumberish,
  displayDecimals?: number,
  unitDecimals?: number,
  trimMantissa?: boolean,
  average?: boolean,
): string;
function formatTokenAmount(
  amount: BigNumberish | undefined,
  displayDecimals?: number,
  unitDecimals?: number,
  trimMantissa?: boolean,
  average?: boolean,
): string | null;

function formatTokenAmount(
  amount?: BigNumberish,
  displayDecimals = 4,
  unitDecimals = 18,
  trimMantissa?: boolean,
  average?: boolean,
): string | null {
  if (amount === undefined) return null;
  if (String(amount) === '0') return '0';
  const result = formatUnits(amount, unitDecimals);
  return numbro(result).format({
    thousandSeparated: true,
    mantissa: displayDecimals,
    trimMantissa: trimMantissa ?? false,
    average: average ?? false,
  });
}

export { formatTokenAmount };

export function formatPendingRewards(
  amount?: BigNumberish,
  displayDecimals?: number,
  unitDecimals?: number,
  trimMantissa?: boolean,
  average?: boolean,
) {
  const ret = formatTokenAmount(
    amount,
    displayDecimals,
    unitDecimals,
    trimMantissa,
    average,
  );
  if (amount && Number(ret) <= 0 && BigNumber.from(amount)?.gt(0)) {
    return `~${ret}`;
  }
  return ret;
}

const limitDecimals = (
  value: string | number | BigNumber,
  maxDecimals?: number,
): string => {
  let valueStr = value.toString();

  if (!value) return valueStr;
  if (maxDecimals === undefined) return valueStr;
  if (maxDecimals === 0) return valueStr.split('.')[0];

  const dotIndex = valueStr.indexOf('.');

  if (dotIndex === -1) return valueStr;

  const decimals = valueStr.length - dotIndex - 1;

  if (decimals > maxDecimals) {
    valueStr = valueStr.substring(
      0,
      valueStr.length - (decimals - maxDecimals),
    );
  }

  return valueStr;
};

export const parseValue = (
  value: string,
  decimals: number,
  defaultValue = BigNumber.from(0),
): BigNumber => {
  const pValue = Number.parseFloat(value);

  if (Number.isNaN(pValue) || !value.match(/^-?[0-9.]+$/)) return defaultValue;

  /**
   * using limitDecimals to avoid "fractional component exceeds decimals" error
   */
  value = limitDecimals(value, decimals);
  const amount = parseUnits(value, decimals);

  return BigNumber.from(amount);
};

export const formatPercentage = (apy: number, decimals = 2) => {
  return `${(apy * 100).toFixed(decimals)}%`;
};
