import { VenoFountain__factory } from '@contracts';
import { BigNumber } from '@ethersproject/bignumber';
import { VenoSDKModule } from './base';
import { DIGITS, SECONDS_PER_YEAR } from './constants';
import { wrapSdkTransaction } from './util';
export default class VenoFountainModule extends VenoSDKModule {
    constructor() {
        super(...arguments);
        this.contractFactory = VenoFountain__factory;
        this.contractAddress = this.sdk.addresses.venoFountain;
    }
    get poolLength() {
        return this.getContract().poolLength();
    }
    async deposit(pid, amount) {
        return await wrapSdkTransaction(this.getContract().deposit(pid, amount));
    }
    async batchWithdraw(stakeIds) {
        return await wrapSdkTransaction(this.getContract().batchWithdraw(stakeIds));
    }
    async withdrawEarly(stakeIds) {
        return await wrapSdkTransaction(this.getContract().batchWithdrawEarly(stakeIds));
    }
    async claimVaultPenalty() {
        return await wrapSdkTransaction(this.getContract().claimVaultPenalty());
    }
    getReceivedPenalty(receipt) {
        const args = receipt.events?.find((e) => e.event === 'ClaimVaultPenalty')?.args;
        if (!args || args.length !== 2)
            return null;
        return args[1];
    }
    batchUpgrade(stakeIds, targetPid) {
        return wrapSdkTransaction(this.getContract().batchUpgrade(stakeIds, new Array(stakeIds.length).fill(targetPid)));
    }
    async pendingVaultPenaltyReward(address) {
        return await this.getContract().pendingVaultPenaltyReward(address);
    }
    async getUserInfo(owner) {
        const ref = await this.getContract().getUserInfo(owner);
        return {
            weightedAmount: ref[0],
            rewardDebt: ref[1],
            pendingVaultPenaltyReward: ref[2],
            vaultPenaltyDebt: ref[3],
            stakes: ref[4].map((stake, stakeId) => ({
                ...stake,
                // stake id for this user is just the index in this list of stakes
                stakeId: BigNumber.from(stakeId),
            })),
        };
    }
    async getPoolInfos() {
        try {
            const [poolCounts, vnoRewardPerSecond] = await Promise.all([
                this.poolLength,
                this.sdk.VenoStorm.vnoRewardPerSecond(),
            ]);
            const rewardPerYear = vnoRewardPerSecond.mul(SECONDS_PER_YEAR);
            const rawPoolInfos = await Promise.all([...Array(poolCounts.toNumber()).keys()].map((pid) => this.getContract().poolInfo(pid)));
            const totalWeightedStaked = rawPoolInfos.reduce((acc, curr) => acc.add(curr.totalStaked.mul(curr.multiplier)), BigNumber.from(0));
            return rawPoolInfos.map(({ multiplier, lockPeriod, totalStaked, earlyVaultPenalty }, i) => {
                const weightedStaked = totalStaked.mul(multiplier);
                return {
                    pid: i,
                    multiplier,
                    totalStaked,
                    lockPeriod,
                    earlyVaultPenalty,
                    apr: totalStaked.isZero()
                        ? null
                        : rewardPerYear
                            .mul(weightedStaked)
                            .mul(10 ** DIGITS)
                            .div(totalWeightedStaked)
                            .div(totalStaked)
                            .toNumber() /
                            10 ** DIGITS,
                };
            });
        }
        catch (error) {
            console.error(error);
            return [];
        }
    }
    async pendingVno(owner) {
        return this.getContract().pendingVno(owner);
    }
    async balanceOf(address) {
        return await this.getContract().balanceOf(address);
    }
}
