import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import type { ColorTheme } from '@veno-app/chakra';
import { ColorThemeVars, InColorTheme } from '@veno-app/chakra';
import { forwardRef } from 'react';

export type CardProps = BoxProps & {
  variant?: 'default' | 'button' | 'raised-button' | 'vvs';
  colorTheme?: ColorTheme;
  isActive?: boolean;
};
export const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      variant = 'default',
      colorTheme = 'inherit',
      isActive,
      children,
      ...props
    }: CardProps,
    ref,
  ) => {
    return (
      <InColorTheme colorTheme={colorTheme}>
        <Box
          ref={ref}
          border={
            variant === 'vvs'
              ? isActive
                ? '2px solid var(--primary-aqua, #46A1C2)'
                : '1px solid var(--tertiary-beige, #E2E0DB)'
              : undefined
          }
          boxShadow={
            variant !== 'vvs'
              ? `var(${
                  isActive
                    ? ColorThemeVars.shadowActive
                    : ColorThemeVars.parentShadow
                })`
              : undefined
          }
          _active={
            variant === 'button' || variant === 'raised-button'
              ? {
                  boxShadow: `var(${ColorThemeVars.shadowActive})`,
                }
              : undefined
          }
          bgGradient={
            variant === 'raised-button'
              ? 'linear(to-br, bg.card 14.99%, #D6E0E7 84.37%)'
              : ''
          }
          rounded={variant === 'vvs' ? '12px' : '5px'}
          bgColor={
            variant === 'vvs'
              ? 'bg.cardVvs'
              : `var(${ColorThemeVars.mainColorElevate})`
          }
          m="10px"
          {...props}
        >
          <InColorTheme colorTheme="inherit" isRoot>
            {children}
          </InColorTheme>
        </Box>
      </InColorTheme>
    );
  },
);

Card.displayName = 'Card';
