export enum QueryKey {
  APR = 'apr',
  CRO_PRICE = 'cro-price',
  ATOM_PRICE = 'atom-price',
  VNO_PRICE = 'vno-price',
  WCRO_PRICE = 'wcro-price',
  CRO_ID = 'cro-id',
  FERRO_APY = 'ferro-apy',
  GET_FERRO_TOTAL_LOCKED_VALUE = 'getFerroTotalLockedValue',
  GET_NFT_TOKENS = 'get-nft-tokens',
  HISTORIC_APYS = 'historic-apys',
  WCRO_BALANCE = 'wcro-balance',
  MINTED_TOTAL_VOLUME = 'minted-total-volume',
  STAKE_TRANSACTION_FEE = 'stake-transaction-fee',
  TASSET_INFO = 'tasset-info',
  TOTAL_POOLED = 'total-pooled',
  TOTAL_SUPPLY_STCRO = 'total-supply-stcro',
  VNO_BALANCE = 'vno-balance',
  UNBOND_UNLOCK_DATE = 'unbond-unlock-date',
  VVS_APY = 'vvs-apy',
  GET_VENO_RESERVOIR_USER_INFO = 'getVenoReservoirUserInfo',
  GET_VENO_RESERVOIR_PENDING_REWARDS = 'getVenoReservoirPendingRewards',
  GET_VENO_FOUNTAIN_USER_INFO = 'getVenoFountainUserInfo',
  GET_VENO_FOUNTAIN_PENDING_REWARDS = 'getVenoFountainPendingRewards',
  GET_VENO_RESERVOIR_POOL_INFOS = 'getVenoReservoirPoolInfos',
  GET_VENO_FOUNTAIN_POOL_INFOS = 'getFountainPoolInfos',
  GET_FEE_DISTRIBUTOR_PERIOD_END_BLOCK = 'getFeeDistributorPeriodEndBlock',
  EARNINGS = 'earnings',
  TRANSACTION_HISTORY = 'transaction-history',
  EARNINGS_HISTORY = 'earnings-history',
  EXCHANGE_RATE_HISTORY = 'exchange-rate-history',
  PENDING_VAULT_PENALTY = 'pending-vault-penalty',
  VVS_TVL = 'vvs-tvl',
  FERRO_EXCHANGE_RATE = 'ferro-exchange-rate',
  VVS_EXCHANGE_RATE = 'vvs-exchange-rate',
  FERRO_LP_BALANCE = 'ferro-lp-balance',
  VVS_LP_BALANCE = 'vvs-lp-balance',
  VVS_CRO_LP_BALANCE = 'vvs-cro-lp-balance',
  TLCRO_BALANCE = 'tlcro-balance',
  BOOST_MULT_LOCKED_VNO = 'boost-mult-locked-vno',
  LP_AMOUNT_STAKED = 'lp-amount-staked',
  PENDING_VNO = 'pending-vno',
  GARDEN_APR = 'garden-apr',
  BOOST_TOKENS = 'boost-tokens',
  FERRO_LP_PRICE = 'ferro-lp-price',
  FERRO_LP_PRICE_ATOM = 'ferro-lp-price-atom',
  OWNED_NFTS = 'owned-cruisers',
  STAKED_NFTS = 'staked-nfts',
  BOOST_MULTIPLIERS_NFTS = 'boost-multipliers-nfts',
  BOOST_MULTIPLIER_POOL = 'boost-multiplier-pool',
  TECTONIC_EXCHANGE_RATE = 'tectonic-exchange-rate',
  COIN_PER_STAKED_COIN = 'coin-per-staked-coin',
}
