/* eslint-disable @typescript-eslint/ban-ts-comment */
import { NoSSR } from '@ui/components/NoSSR';

/**
 *
 * @param Component
 * @param ssrPlaceholder
 * @returns
 */
export function withNoSSR<Props>(
  Component: React.ComponentType<Props>,
  ssrPlaceholder?: React.ReactNode,
): React.ComponentType<Props> {
  return function WrappedComponent(props: Props) {
    return (
      <NoSSR ssrPlaceholder={ssrPlaceholder}>
        {/* @ts-ignore */}
        <Component {...props} />
      </NoSSR>
    );
  };
}
