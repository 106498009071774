import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { Step } from 'react-joyride';
import { useMount, useRafLoop } from 'react-use';

export const TOUR_PREFIX = 'veno-tour';

export const useDeepCompareMemo = <T>(fn: () => T, deps: any[]): T => {
  const valueRef = useRef<T>();
  const depsRef = useRef<any[] | undefined>();

  if (!isEqual(deps, depsRef.current)) {
    valueRef.current = fn();
    depsRef.current = deps;
  }

  return valueRef.current as T;
};

export const useIsWalletReady = (enabled: boolean) => {
  const [isReady, setIsReady] = useState(false);

  useMount(() => {
    setTimeout(
      () => {
        setIsReady(true);
      },
      enabled ? 3_000 : 0,
    );
  });

  return isReady;
};

export const useIsFirstStepReady = (nextStepIndex: number, steps: Step[]) => {
  const [isFirstStepReady, setIsFirstStepReady] = useState(false);

  const [stopLoop] = useRafLoop(() => {
    if (nextStepIndex > 0) {
      stopLoop();
      return;
    }

    if (!steps[nextStepIndex] || nextStepIndex !== 0) {
      return;
    }

    const targetElem = initSteps([steps[nextStepIndex]])[0].target;
    const isHtmlElement = targetElem && typeof targetElem === 'object';

    if (!isHtmlElement) {
      return;
    }

    /**
     * make sure first step's target is on the top layer
     */
    setIsFirstStepReady(isElementOnTopLayer(targetElem));
  }, true);

  return isFirstStepReady;
};

/**
 * return previous stepIndex utils the next step's target element is ready
 */
export const useStepIndexWhenStepReady = (
  nextStepIndex: number,
  steps: Step[],
) => {
  const [stepIndex, setStepIndex] = useState(-1);

  useEffect(() => {
    if (nextStepIndex === stepIndex || !steps[nextStepIndex]) {
      return;
    }

    let raf: number;

    const onFrame = () => {
      const targetElem = initSteps([steps[nextStepIndex]])[0].target;
      if (targetElem && typeof targetElem === 'object') {
        setStepIndex(nextStepIndex);
        cancelAnimationFrame(raf);
        return;
      }
      loop();
    };

    const loop = () => {
      raf = requestAnimationFrame(onFrame);
    };

    loop();

    return () => {
      cancelAnimationFrame(raf);
    };
  }, [nextStepIndex, stepIndex, steps]);

  return nextStepIndex > steps.length - 1 ? nextStepIndex : stepIndex;
};

const isElementVisible = (element: Element) => {
  if (!element) return false;
  let parentElement: Element | null = element;
  while (parentElement) {
    if (parentElement === document.body) break;

    if (parentElement instanceof HTMLElement) {
      const _getComputedStyle = getComputedStyle(parentElement);
      const display = _getComputedStyle.display;
      const visibility = _getComputedStyle.visibility;
      if (display === 'none' || visibility === 'hidden') {
        return false;
      }
    }
    parentElement = parentElement.parentNode as Element;
  }
  return true;
};

const isElementOnTopLayer = (element: Element) => {
  if (!element) return false;
  if (!(element instanceof HTMLElement)) {
    return false;
  }

  const ret = element.getBoundingClientRect();
  const elementFromPoint = document.elementFromPoint(ret.x, ret.y);

  return elementFromPoint
    ? element.isSameNode(elementFromPoint) ||
        element.contains(elementFromPoint) ||
        elementFromPoint.contains(element)
    : false;
};

export const initSteps = (
  steps: Step[],
  {
    skipElementInitialize = false,
  }: {
    skipElementInitialize?: boolean;
  } = {},
) => {
  return steps.map((step) => {
    const newStep: Step = {
      ...step,
      disableBeacon: step.disableBeacon ?? true,
    };

    if (!skipElementInitialize && typeof step.target === 'string') {
      const elemList = document.querySelectorAll(step.target);
      const targetElem = [...elemList].find((v) =>
        isElementVisible(v),
      ) as HTMLElement;
      newStep.target = targetElem ?? step.target;
    }

    return newStep;
  });
};

export const useTourHasShown = (tourId: string) => {
  const [hasShown, setHasShown] = useState(true);

  useMount(() => {
    setHasShown(Boolean(window.localStorage.getItem(tourId)));
  });

  return [
    hasShown,
    useCallback(() => {
      window.localStorage.setItem(tourId, 'true');
    }, [tourId]),
  ] as const;
};
