import type { ChainType } from '@veno-app/sdk';

import useBoostMultLockedVno from './useBoostMultLockedVno';

const useFarmAprs = () => {
  const { boostMultiplier, isLoading } = useBoostMultLockedVno();

  return {
    isLoading,
    boostMultiplier,
    isBoosted: !!boostMultiplier && boostMultiplier > 1,
  };
};

export const getMaxBoostMultiplier = (chainType: ChainType) => {
  return chainType === 'zksync' ? 1.7 : 1.75;
};

export const getNextMultiplier = (
  chainType: ChainType,
  boostMultiplier: number | undefined,
) => {
  const maxBoostMultiplier = getMaxBoostMultiplier(chainType);
  return boostMultiplier
    ? Math.min(maxBoostMultiplier, boostMultiplier + 0.05)
    : undefined;
};

export default useFarmAprs;
