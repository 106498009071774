import {
  Button,
  DrawerBody,
  DrawerFooter,
  Flex,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { useTranslations } from '@ui/i18n';

type GetAssetOnVvsContentProps = {
  tokenLogoSrc: string;
  tokenName: string;
  swapLink: string;
};

export const GetAssetOnVvsContent = ({
  tokenLogoSrc,
  tokenName,
  swapLink,
}: GetAssetOnVvsContentProps) => {
  const t = useTranslations();
  return (
    <>
      <DrawerBody>
        <Flex direction="column" alignItems="center" px={4}>
          <Image alt="logo" src={tokenLogoSrc} w="50px" h="50px" />
          <Text textAlign="center" color="text-light" mt="10px">
            {t('buy-direction-{platform}-{coin}', {
              platform: 'VVS Finance',
              coin: tokenName,
            })}
          </Text>
          <Text
            mt="20px"
            color="text-light"
            textStyle="bodySmall"
            textAlign="center"
          >
            {t('buy-coin-risk-{platform}', {
              platform: 'VVS Finance',
            })}
            <br />
            {t('More info can be found here {Link}', {
              Link: (chunks) => (
                <Link
                  href={EXTERNAL_LINK.vvsLanding}
                  color="pink.500"
                  isExternal
                >
                  {chunks}
                </Link>
              ),
            })}
          </Text>
        </Flex>
      </DrawerBody>
      <DrawerFooter>
        <Button as="a" flexGrow="1" href={swapLink} target="_blank">
          {t('Confirm')}
        </Button>
      </DrawerFooter>
    </>
  );
};
