import { BraveWallet } from '@web3-wallet/brave-wallet';
import { CoinbaseWallet } from '@web3-wallet/coinbase-wallet';
import { CryptocomDesktopWallet } from '@web3-wallet/cryptocom-desktop-wallet';
import { DeFiWallet } from '@web3-wallet/defiwallet';
import { MetaMask } from '@web3-wallet/metamask';
import { TrustWallet } from '@web3-wallet/trust-wallet';
import { WalletConnect } from '@web3-wallet/walletconnect';
import { getNetworkConfig, RPC_URLS } from './chainConfig';
import { ledgerLiveIcon } from './icons';
const walletConnectProviderOptions = {
    projectId: process.env.WALLET_CONNECT_PROJECT_ID,
    rpcMap: RPC_URLS,
    chains: [getNetworkConfig().chainId],
    showQrModal: true,
    optionalMethods: ['eth_signTypedData', 'eth_signTypedData_v4', 'eth_sign'],
    qrModalOptions: {
        // 'dark' | 'light'
        themeMode: 'light',
        themeVariables: {
            /**
             * @see packages/chakra/src/zIndices.ts
             */
            '--wcm-z-index': '1900',
        },
    },
};
export const walletConfigs = [
    {
        title: 'MetaMask',
        name: MetaMask.walletName,
        icon: MetaMask.walletIcon,
        connector: new MetaMask({
            // p.isTrust is to enable using MetaMask to connect on DeFi Wallet
            // Brave wallet and MetaMask can't co-exist
            providerFilter: (p) => (!!p.isMetaMask || !!p.isTrust) && !p.isBraveWallet,
        }),
        getDownloadLink: () => {
            if (typeof window === 'undefined')
                return undefined;
            /**
             * deeplink generator
             * https://metamask.github.io/metamask-deeplinks/
             */
            return `https://metamask.app.link/dapp/${window.location.hostname}`;
        },
        trackingId: 'injected',
    },
    {
        title: 'Crypto.com Onchain',
        name: DeFiWallet.walletName,
        icon: DeFiWallet.walletIcon,
        connector: new DeFiWallet(),
        getDownloadLink: ({ isMobile }) => {
            if (isMobile) {
                return 'https://crypto.com/onchain';
            }
            return 'https://chromewebstore.google.com/detail/cryptocom-onchain-extensi/hifafgmccdpekplomjjkcfgodnhcellj';
        },
        trackingId: 'defi_wallet',
    },
    {
        title: 'Crypto.com DeFi Desktop Wallet',
        name: CryptocomDesktopWallet.walletName,
        icon: CryptocomDesktopWallet.walletIcon,
        connector: new CryptocomDesktopWallet({
            providerFilter: (p) => {
                return Boolean(p.isDesktopWallet);
            },
        }),
        getDownloadLink: () => 'https://crypto.com/defi-wallet#defi_wallet_desktop',
        trackingId: 'defi_desktop_wallet',
    },
    {
        title: 'Brave Wallet',
        name: BraveWallet.walletName,
        icon: BraveWallet.walletIcon,
        connector: new BraveWallet(),
        getDownloadLink: () => undefined,
        trackingId: 'brave_wallet',
    },
    {
        title: 'WalletConnect',
        name: WalletConnect.walletName,
        icon: WalletConnect.walletIcon,
        // https://web3-wallet.github.io/web3-wallet/wallets/walletconnect
        connector: new WalletConnect({
            providerOptions: walletConnectProviderOptions,
        }),
        getDownloadLink: () => undefined,
        trackingId: 'wallet_connect',
    },
    {
        title: 'Trust Wallet',
        name: TrustWallet.walletName,
        icon: TrustWallet.walletIcon,
        connector: new TrustWallet(),
        getDownloadLink: () => 'https://trustwallet.com/download/',
        trackingId: 'trust_wallet',
    },
    {
        title: 'Coinbase Wallet',
        name: CoinbaseWallet.walletName,
        icon: CoinbaseWallet.walletIcon,
        connector: new CoinbaseWallet({
            providerOptions: {
                scanToConnectOptions: {
                    rpcUrl: getNetworkConfig().rpcUrls[0],
                    chainId: getNetworkConfig().chainId,
                },
                appName: 'Veno Finance',
            },
        }),
        getDownloadLink: () => 'https://www.coinbase.com/wallet/downloads',
        trackingId: 'coinbase_wallet',
    },
    {
        title: 'Ledger Live',
        name: WalletConnect.walletName,
        icon: ledgerLiveIcon,
        connector: new WalletConnect({
            providerOptions: walletConnectProviderOptions,
        }),
        getDownloadLink: () => 'https://www.ledger.com/ledger-live',
        trackingId: 'ledger_live',
    },
];
export const getWalletConfig = (name) => walletConfigs.find((v) => v.name === name);
