import { extendTheme } from '@chakra-ui/react';
import { useIFrameTheme } from '@ui/hooks/useIFrameTheme';
import { theme as _theme } from '@veno-app/chakra';
import { useMemo } from 'react';

export const theme = extendTheme(_theme);

export const useTheme = () => {
  const { colorMode } = useIFrameTheme();

  const { theme: iFrameTheme } = useIFrameTheme();
  const vvsTheme = useMemo(
    () =>
      extendTheme({
        ...theme,
        styles: {
          global: {
            body: {
              fontFamily: 'Soleil',

              color: colorMode === 'dark' ? 'white' : 'text-normal',
              fontSize: '16px',

              // disable selection on mobile
              touchCallout: {
                base: 'none',
                desktop: 'auto',
              },
              userSelect: {
                base: 'none',
                desktop: 'auto',
              },

              backgroundColor:
                colorMode === 'dark'
                  ? theme.colors.vvs.bg_dark
                  : theme.colors.vvs.bg,
            },
            'd1,d2,h1,h2,h3': {
              fontFamily: `Effra`,
            },
          },
        },
      }),
    [colorMode], // dependencies
  );

  if (iFrameTheme !== 'vvs') return theme;
  return vvsTheme;
};
