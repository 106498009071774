import { BigNumber } from '@ethersproject/bignumber';
import { useQuery } from '@tanstack/react-query';
import { getVnoUsdPrice } from '@ui/queries/prices';

export const useVnoUsdValue = <T extends BigNumber | number>(vnoValue?: T) => {
  const usdPriceQuery = getVnoUsdPrice();
  const usdPerVnoResult = useQuery(
    usdPriceQuery.queryKey,
    usdPriceQuery.queryFn,
    {
      staleTime: 20_000,
      enabled: vnoValue !== undefined,
    },
  );

  if (
    vnoValue === 0 ||
    (typeof vnoValue !== 'number' && BigNumber.from(0).gte(vnoValue ?? 1))
  ) {
    return { ...usdPerVnoResult, data: vnoValue };
  }

  let usdPrice: T | undefined = undefined;

  if (usdPerVnoResult.data && vnoValue) {
    usdPrice = (
      typeof vnoValue === 'number'
        ? usdPerVnoResult.data * vnoValue
        : BigNumber.from(Math.round(usdPerVnoResult.data * 100_000_000))
            .mul(vnoValue)
            .div(100_000_000)
    ) as T;
  }

  return { ...usdPerVnoResult, data: usdPrice };
};

export const useVnoUsdValues = <T extends BigNumber | number>(
  vnoValues?: T[],
) => {
  const usdPriceQuery = getVnoUsdPrice();
  const usdPriceResult = useQuery(
    usdPriceQuery.queryKey,
    usdPriceQuery.queryFn,
    {
      staleTime: 60_000,
      enabled: vnoValues !== undefined && !!vnoValues.length,
    },
  );

  let usdPrices: T[] | undefined = undefined;

  if (usdPriceResult.data && vnoValues) {
    usdPrices = (
      typeof vnoValues[0] === 'number'
        ? vnoValues.map(
            (vnoValue) => usdPriceResult.data * (vnoValue as number),
          )
        : vnoValues.map((vnoValue) =>
            BigNumber.from(Math.round(usdPriceResult.data * 100_000_000))
              .mul(vnoValue)
              .div(100_000_000),
          )
    ) as T[];
  }

  return { ...usdPriceResult, data: usdPrices };
};
