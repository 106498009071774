import { VenoStormMultiplierReader__factory } from '@contracts';
import { VenoSDKModule } from './base';
import { getTokenContract } from './util';
export default class VenoStormMultiplierReaderModule extends VenoSDKModule {
    constructor() {
        super(...arguments);
        this.contractFactory = VenoStormMultiplierReader__factory;
        this.contractAddress = this.sdk.addresses.venoStormMultiplierReader;
    }
    async getBoostMultiplierFromLockedVno(user) {
        return await this.getContract().getBoostMultiplierFromLockedVno(user);
    }
    async getBoostMultiplierFromTokenIds(tokenIds, collection) {
        const contractAddress = getTokenContract(collection, this.addresses);
        if (!tokenIds.length || !contractAddress)
            return [];
        return (await this.getContract().getBoostMultiplierFromNftTokenId(contractAddress, tokenIds)).map((bn) => bn.toNumber() / 10000);
    }
}
