import { LiquidAtom__factory } from '@contracts';
import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from 'ethers/lib/utils';
import LiquidCoinModule from './liquidCoin';
import { wrapSdkTransaction } from './util';
export default class LiquidAtomModule extends LiquidCoinModule {
    constructor() {
        super(...arguments);
        this.contractFactory = LiquidAtom__factory;
        this.contractAddress = this.sdk.addresses.liquidAtom;
    }
    async stake(receiver, value) {
        return await wrapSdkTransaction(this.getContract().stake(receiver, value));
    }
    getReceivedLatom(receipt) {
        return this.getReceived(receipt);
    }
    async estimateGasForStake(receiver) {
        const { maxFeePerGas } = await this.sdk.provider.getFeeData();
        const gasUnits = await this.getContract().estimateGas.stake(receiver, parseUnits('0.00000001', 6));
        if (!maxFeePerGas)
            return BigNumber.from(0);
        return maxFeePerGas.mul(gasUnits);
    }
    atomPerLatom() {
        return this.coinPerStakedCoin();
    }
    async totalPooled() {
        return await this.getContract().getTotalPooledToken();
    }
}
