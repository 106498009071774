import { BigNumber } from '@ethersproject/bignumber';
import { MaxUint256 } from '@ethersproject/constants';
import { features } from '@ui/components/Features';
import { getContractAddresses } from '@ui/config/base';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { COIN_MODE } from '@ui/hooks/useCoinMode';
import { isFeatureEnabled } from '@ui/hooks/useIsFeatureEnabled';
import { i18next } from '@ui/i18n';
import type { ColorTheme } from '@veno-app/chakra';
import {
  Grow,
  GrowFilled,
  Harvest,
  HarvestFilled,
  Plant,
  PlantFilled,
} from '@veno-app/icons';
import type { ChainType, PidType, VenoSDK } from '@veno-app/sdk';
import type { SVGProps } from 'react';

import { formatNumber } from './format';

export type GardenType = 'TECTONIC' | 'FERRO' | 'VVS' | 'VVS_CRO' | 'VVS_TIA';
export const gardenTypes: GardenType[] = [
  'FERRO',
  'VVS',
  'TECTONIC',
  'VVS_CRO',
  'VVS_TIA',
];
export type GardenView =
  | 'PLANT'
  | 'GROW_WATER'
  | 'GROW_HELPERS'
  | 'HARVEST'
  | 'WITHDRAW';
export const GARDEN_VIEWS: GardenView[] = ['PLANT', 'GROW_WATER', 'HARVEST'];
export type GARDEN_VAULT_TYPE = 'RESERVOIR' | 'FOUNTAIN';

export const getGardenName = (
  coinMode: CoinMode,
  garden: GardenType,
  showCoinModeName = true,
): string => {
  const coinModeInfo = COIN_MODE[coinMode];
  const tail = showCoinModeName ? ` (${coinModeInfo.name})` : '';

  if (coinMode === 'eth') {
    switch (garden) {
      case 'FERRO':
        return `${i18next.t('{gardenName} Garden', {
          gardenName: 'SyncSwap LETH',
        })}${tail}`;
      case 'VVS':
        return i18next.t('{gardenName} Garden', {
          gardenName: 'SyncSwap VNO',
        });
      default:
        return `${i18next.t('{gardenName} Garden', {
          gardenName: 'SyncSwap',
        })}${tail}`;
    }
  }

  switch (garden) {
    case 'TECTONIC':
      return `${i18next.t('{gardenName} Garden', {
        gardenName: 'Tectonic',
      })}${tail}`;
    case 'FERRO':
      return `${i18next.t('{gardenName} Garden', {
        gardenName: 'Ferro',
      })}${tail}`;
    case 'VVS':
      return i18next.t('{gardenName} Garden', {
        gardenName: 'VVS',
      });
    case 'VVS_CRO':
      return `${i18next.t('{gardenName} Garden', {
        gardenName: 'VVS',
      })}${tail}`;
    case 'VVS_TIA':
      return `${i18next.t('{gardenName} Garden', {
        gardenName: 'VVS',
      })}${tail}`;
  }
};

type GardenTokenBase = {
  suppliedTokenName: string;
  name: string;
  decimals: number;
  protocolName: string;
  poolName: string;
  displayDecimals?: number;
  abbreviateZeros?: boolean;
};

export type GardenToken =
  | ({
      enabled: true;
    } & GardenTokenBase)
  | ({
      enabled: false;
    } & Partial<GardenTokenBase>);

const _vvsToken: GardenTokenBase = {
  suppliedTokenName: 'VNO',
  name: 'VVS-VNO-LP',
  decimals: 18,
  protocolName: 'VVS finance',
  poolName: 'VVS-VNO',
};

export const gardenTokenDict: Record<
  CoinMode,
  Record<GardenType, GardenToken>
> = {
  cro: {
    VVS: {
      ..._vvsToken,
      enabled: true,
    },
    VVS_CRO: {
      enabled: true,
      suppliedTokenName: 'LCRO',
      name: 'LCRO-CRO-LP',
      decimals: 18,
      protocolName: 'VVS',
      poolName: 'LCRO-CRO',
    },
    FERRO: {
      enabled: true,
      suppliedTokenName: 'LCRO',
      name: 'LCRO-CRO-LP',
      decimals: 18,
      protocolName: 'Ferro',
      poolName: 'LCRO-CRO',
    },
    TECTONIC: {
      enabled: true,
      suppliedTokenName: 'LCRO',
      name: 'tLCRO',
      decimals: 8,
      protocolName: 'Tectonic',
      poolName: 'Veno',
    },
    VVS_TIA: {
      enabled: false,
      suppliedTokenName: 'LTIA',
      name: 'LTIA-TIA-LP',
      decimals: 18,
      protocolName: 'VVS',
      poolName: 'LTIA-TIA',
      displayDecimals: 16,
      abbreviateZeros: true,
    },
  },
  atom: {
    VVS: {
      enabled: false,
    },
    VVS_CRO: {
      enabled: false,
    },
    FERRO: {
      enabled: true,
      suppliedTokenName: 'LATOM',
      name: 'LATOM-ATOM-LP',
      decimals: 18,
      protocolName: 'Ferro',
      poolName: 'LATOM-ATOM',
    },
    TECTONIC: {
      enabled: false,
    },
    VVS_TIA: {
      enabled: false,
    },
  },
  eth: {
    VVS: {
      enabled: isFeatureEnabled(features.gardenZksync),
      suppliedTokenName: 'VNO',
      name: 'ETH-VNO-LP',
      decimals: 18,
      protocolName: 'SyncSwap',
      poolName: 'ETH-VNO',
    },
    VVS_CRO: {
      enabled: false,
    },
    FERRO: {
      enabled: isFeatureEnabled(features.gardenZksync),
      suppliedTokenName: 'LETH',
      name: 'LETH-ETH-LP',
      decimals: 18,
      protocolName: 'SyncSwap',
      poolName: 'LETH-ETH',
    },
    TECTONIC: {
      enabled: false,
    },
    VVS_TIA: {
      enabled: false,
    },
  },
  tia: {
    VVS: {
      enabled: false,
    },
    VVS_CRO: {
      enabled: false,
    },
    FERRO: {
      enabled: false,
    },
    TECTONIC: {
      enabled: false,
    },
    VVS_TIA: {
      enabled: true,
      suppliedTokenName: 'LTIA',
      name: 'LTIA-TIA-LP',
      decimals: 18,
      protocolName: 'VVS',
      poolName: 'LTIA-TIA',
      displayDecimals: 16,
      abbreviateZeros: true,
    },
  },
};

export const getViewName = (view: GardenView): string => {
  switch (view) {
    case 'PLANT':
    case 'WITHDRAW':
      return i18next.t('Plant');
    case 'GROW_HELPERS':
    case 'GROW_WATER':
      return i18next.t('Grow');
    case 'HARVEST':
      return i18next.t('Harvest');
  }
};

export const getViewIcon = (
  view: GardenView,
): {
  normal: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  active: (props: SVGProps<SVGSVGElement>) => JSX.Element;
} => {
  switch (view) {
    case 'PLANT':
    case 'WITHDRAW':
      return { normal: Plant, active: PlantFilled };
    case 'GROW_WATER':
    case 'GROW_HELPERS':
      return { normal: Grow, active: GrowFilled };
    case 'HARVEST':
      return { normal: Harvest, active: HarvestFilled };
  }
};

export async function getAllowance(
  coinMode: CoinMode,
  type: GardenType,
  sdk: VenoSDK,
  account?: string,
): Promise<BigNumber> {
  if (!account) return BigNumber.from(0);
  switch (type) {
    case 'FERRO':
      if (coinMode === 'atom') {
        return await sdk.FerroLpTokenAtom.allowance(
          account,
          sdk.VenoStorm.contractAddress,
        );
      }
      return await sdk.FerroLpToken.allowance(
        account,
        sdk.VenoStorm.contractAddress,
      );
    case 'VVS':
      return await sdk.VvsLpToken.allowance(
        account,
        sdk.VenoStorm.contractAddress,
      );
    case 'VVS_CRO':
      return await sdk.VvsCroLpToken.allowance(
        account,
        sdk.VenoStorm.contractAddress,
      );
    case 'TECTONIC':
      return sdk.TLcro.allowance(account, sdk.VenoStorm.contractAddress);
    case 'VVS_TIA':
      return sdk.VvsTiaLpToken.allowance(
        account,
        sdk.VenoStorm.contractAddress,
      );
  }
}

export async function approve(
  coinMode: CoinMode,
  type: GardenType,
  sdk: VenoSDK,
) {
  switch (type) {
    case 'FERRO':
      if (coinMode === 'atom') {
        return await sdk.FerroLpTokenAtom.approve(
          sdk.VenoStorm.contractAddress,
          MaxUint256,
        );
      }
      return await sdk.FerroLpToken.approve(
        sdk.VenoStorm.contractAddress,
        MaxUint256,
      );
    case 'VVS':
      return await sdk.VvsLpToken.approve(
        sdk.VenoStorm.contractAddress,
        MaxUint256,
      );
    case 'VVS_CRO':
      return await sdk.VvsCroLpToken.approve(
        sdk.VenoStorm.contractAddress,
        MaxUint256,
      );
    case 'TECTONIC':
      return await sdk.TLcro.approve(sdk.VenoStorm.contractAddress, MaxUint256);
    case 'VVS_TIA':
      return await sdk.VvsTiaLpToken.approve(
        sdk.VenoStorm.contractAddress,
        MaxUint256,
      );

    default:
      throw new Error(
        `garden.ts function approve not implemented for ${type}}`,
      );
  }
}

export const useAllocationOfPendingRewards = (
  pendingRewards: BigNumber | undefined,
) => {
  return getAllocationOfPendingRewards(pendingRewards);
};

export const getAllocationOfPendingRewards = (
  pendingRewards: BigNumber | undefined,
) => {
  return {
    intoWallet: pendingRewards?.mul(25).div(100),
    into12MonthVault: pendingRewards?.mul(75).div(100),
  };
};

export const getGardenTypeFromUrl = (): GardenType | undefined => {
  let gardenType: GardenType | undefined;

  if (typeof window !== 'undefined') {
    const { pathname } = window.location;
    if (pathname.includes('vvs_cro')) {
      gardenType = 'VVS_CRO';
    } else if (pathname.includes('vvs') && pathname.includes('tia')) {
      gardenType = 'VVS_TIA';
    } else if (pathname.includes('vvs')) {
      gardenType = 'VVS';
    } else if (pathname.includes('tectonic')) {
      gardenType = 'TECTONIC';
    } else if (pathname.includes('ferro')) {
      gardenType = 'FERRO';
    }
  }

  return gardenType;
};

export function getLpUsdValue(
  coinMode: CoinMode,
  type: GardenType,
  sdk: VenoSDK,
  amount: BigNumber,
): Promise<BigNumber> {
  return sdk.VenoStorm.getLpValueUsdV2(getPidType(coinMode, type), amount);
}

export const formatGardenApy = (apy: number | undefined) => {
  if (apy === undefined) return undefined;
  if (apy === 0) return '--';
  return formatNumber(apy * 100, 2) + '%';
};

export const isButtonActive = (
  buttonType: GardenView,
  activeTypeInStore: GardenView,
) => {
  if (activeTypeInStore === 'WITHDRAW' && buttonType === 'PLANT') return true;
  if (buttonType === activeTypeInStore) return true;

  return (
    buttonType.startsWith('GROW_') && activeTypeInStore.startsWith('GROW_')
  );
};

export const getGardenColorThemeByCoinMode = (
  coinMode: CoinMode,
  gardenType?: GardenType,
): ColorTheme => {
  if (coinMode === 'atom') {
    return 'atom';
  }
  if (coinMode === 'tia') {
    return 'tia';
  }
  if (gardenType === 'VVS') {
    return 'default';
  }
  if (coinMode === 'eth') {
    return 'eth';
  }
  return 'garden';
};

export const getGardenAvatarUrl = (
  chainType: ChainType,
  garden: GardenType,
) => {
  if (chainType === 'zksync') {
    return '/images/use-syncswap.svg';
  }
  switch (garden) {
    case 'TECTONIC':
      return '/images/use-tectonic.png';
    case 'FERRO':
      return '/images/use-ferro.png';
    case 'VVS':
      return '/images/use-vvs.png';
    case 'VVS_CRO':
      return '/images/use-vvs-lcro.png';
    case 'VVS_TIA':
      return '/images/use-vvs-lcro.png';
  }
};

export const getPidType = (
  coinMode: CoinMode,
  gardenType: GardenType,
): PidType => {
  return gardenType === 'FERRO' && coinMode === 'atom'
    ? 'FERRO_ATOM'
    : gardenType;
};

export const getLpPoolUrl = (
  chainType: ChainType,
  gardenType: GardenType,
): string => {
  if (chainType === 'cronos' && gardenType === 'VVS_TIA') {
    return EXTERNAL_LINK.vvsTiaDeposit;
  }
  if (chainType === 'cronos')
    throw new Error(`can't use getLpPoolUrl with ${gardenType} on cronos`);
  switch (gardenType) {
    case 'FERRO':
      return (
        EXTERNAL_LINK.syncSwapPool +
        '/' +
        getContractAddresses('zksync').ferroLp
      );
    case 'VVS':
      return (
        EXTERNAL_LINK.syncSwapPool +
        '/' +
        getContractAddresses('zksync').vvsPair
      );
    default:
      return EXTERNAL_LINK.syncSwapPool;
  }
};
