import {
  getPathInfo,
  isCategoryInSameView,
  useRealPathname,
} from '@ui/hooks/router';

export const useStakingTourEnabled = () => {
  const pathInfo = getPathInfo(useRealPathname());
  return (
    pathInfo.coinMode === 'cro' &&
    isCategoryInSameView(pathInfo.category, 'stake')
  );
};
