import type { MenuButtonProps, MenuProps } from '@chakra-ui/react';
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { languages, useLanguage, useTranslations } from '@ui/i18n';
import type { ReactNode } from 'react';

import { useSwitchLang } from './utils';

export type LangMenuProps = Omit<MenuProps, 'children'> & {
  children?: ReactNode;
  menuButtonProps?: MenuButtonProps;
};
export const LangMenu = ({
  children,
  menuButtonProps,
  ...props
}: LangMenuProps) => {
  const t = useTranslations();
  const language = useLanguage();
  const switchLang = useSwitchLang();

  return (
    <Menu placement="bottom-end" {...props}>
      <MenuButton as={Button} variant="ghost" px="0" {...menuButtonProps}>
        {children || language.name}
      </MenuButton>
      <MenuList shadow="common.normal" p="0px 16px 10px" bg="bg-main" w="400px">
        <Text textStyle="h4" py="10px">
          {t('Language')}
        </Text>
        {languages.map((langItem, i) => {
          const isActive = langItem.lang === language.lang;
          return (
            <MenuItem
              key={i}
              bg={isActive ? 'brand-shadowPositive' : undefined}
              textStyle="bodyBold"
              justifyContent="center"
              p="12px 16px"
              _hover={{
                bg: isActive ? 'brand-shadowPositive' : 'blackAlpha.100',
              }}
              onClick={() => {
                switchLang(langItem.lang);
              }}
            >
              {langItem.name}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};
