import { Flex, Icon, Link, Text } from '@chakra-ui/react';
import { InDesktop, InMobile } from '@ui/components/MobileOrDesktop';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import {
  getPathInfo,
  isCategoryInSameView,
  useRealPathname,
} from '@ui/hooks/router';
import { useTranslations } from '@ui/i18n';
import { MAXW_DESKTOP_CONTENT } from '@ui/utils/constants';
import { Discord, Medium, Telegram, Twitter } from '@veno-app/icons';

import { FooterActionsProvider } from './FooterActionsProvider';
import FooterSectionsDesktop from './FooterSectionsDesktop';
import FooterSectionsMobile from './FooterSectionsMobile';

const Footer = ({ hidden }: { hidden?: boolean }) => {
  const t = useTranslations();
  const realPathname = useRealPathname();
  const pathInfo = getPathInfo(realPathname);
  const isInLandingView = isCategoryInSameView(pathInfo.category, 'landing');
  const shouldHidden = hidden ?? isInLandingView;

  return (
    <>
      {!shouldHidden && (
        <Flex
          mt="auto"
          flexDir="column"
          mx="auto"
          px={4}
          maxW={MAXW_DESKTOP_CONTENT}
        >
          <Flex
            mt={6}
            flex="1"
            w="full"
            pt="16px"
            pb="24px"
            borderTopWidth="1px"
            borderColor="brand.transparentGrey"
            flexDir="column"
            align="flex-start"
          >
            <Text color="#000000" textStyle="bodySmall">
              {t('Join the community')}
            </Text>
            <Flex gap={6} pt="18px">
              <Link
                rel="noopener noreferrer"
                target="_blank"
                href={EXTERNAL_LINK.twitter}
              >
                <Icon color="text.active" w="16px" h="16px" as={Twitter} />
              </Link>
              <Link
                rel="noopener noreferrer"
                target="_blank"
                href={EXTERNAL_LINK.telegram}
              >
                <Icon color="text.active" w="16px" h="16px" as={Telegram} />
              </Link>
              <Link
                rel="noopener noreferrer"
                target="_blank"
                href={EXTERNAL_LINK.medium}
              >
                <Icon color="text.active" w="16px" h="16px" as={Medium} />
              </Link>
              <Link
                rel="noopener noreferrer"
                target="_blank"
                href={EXTERNAL_LINK.discord}
              >
                <Icon color="text.active" w="16px" h="16px" as={Discord} />
              </Link>
            </Flex>
            <FooterActionsProvider>
              <InDesktop>
                <FooterSectionsDesktop />
              </InDesktop>
              <InMobile>
                <FooterSectionsMobile />
              </InMobile>
            </FooterActionsProvider>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default Footer;
