import { keccak256 } from '@ethersproject/keccak256';
import { currentWallet } from '@veno-app/wallet';
import { useMemo } from 'react';

const { useAccount } = currentWallet;

export const useHashedAddress = (): string | null => {
  const address = useAccount();

  return useMemo(() => (address ? keccak256(address) : null), [address]);
};
