import { breakpoints } from './breakpoints';
import { colors } from './colors';
import * as components from './components';
import { semanticTokens } from './semanticTokens';
import { shadows } from './shadows';
import { space } from './space';
import { styles } from './styles';
import { textStyles } from './textStyles';
import { zIndices } from './zIndices';
export const theme = {
    config: {
        initialColorMode: 'light',
        useSystemColorMode: false,
    },
    components: {
        ...components,
    },
    space,
    semanticTokens,
    shadows,
    breakpoints,
    colors,
    styles,
    textStyles,
    zIndices,
};
