import type { FlexProps } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';
import type { TokenIconProps } from '@ui/components/TokenIcon';
import { TokenIcon } from '@ui/components/TokenIcon';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { COIN_MODE, getTokenFromName } from '@ui/hooks/useCoinMode';
import type { GardenType } from '@ui/utils/garden';
import { gardenTokenDict } from '@ui/utils/garden';
import type { ReactNode } from 'react';

export type LpTokenIconProps = FlexProps & {
  coinMode: CoinMode;
  gardenType: GardenType;
  children?: ReactNode;
  iconWidth?: TokenIconProps['width'];
  iconHeight?: TokenIconProps['width'];
};
export const LPTokenIcon = ({
  coinMode,
  gardenType,
  children,
  iconWidth = '24px',
  iconHeight = '24px',
  ...props
}: LpTokenIconProps) => {
  const gardenToken = gardenTokenDict[coinMode][gardenType];

  return (
    <Flex gap="2px" alignItems="center" {...props}>
      {children}
      <Flex>
        {gardenType === 'VVS' && coinMode === 'cro' && (
          <>
            <TokenIcon token="vno" w={iconWidth} h={iconHeight} />
            <TokenIcon
              token="vvs"
              ml={`calc(-${iconWidth} / 2)`}
              w={iconWidth}
              h={iconHeight}
            />
          </>
        )}
        {gardenType === 'VVS' && coinMode === 'eth' && (
          <>
            <TokenIcon token="vno" w={iconWidth} h={iconHeight} />
            <TokenIcon
              token="eth"
              ml={`calc(-${iconWidth} / 2)`}
              w={iconWidth}
              h={iconHeight}
            />
          </>
        )}
        {coinMode !== 'eth' &&
          (gardenType === 'FERRO' ||
            gardenType === 'VVS_CRO' ||
            gardenType === 'VVS_TIA') && (
            <>
              <TokenIcon token={coinMode} w={iconWidth} h={iconHeight} />
              <TokenIcon
                token={getTokenFromName(COIN_MODE[coinMode].liquidName)}
                ml={`calc(-${iconWidth} / 2)`}
                w={iconWidth}
                h={iconHeight}
              />
            </>
          )}
        {coinMode === 'eth' && gardenType === 'FERRO' && (
          <>
            <TokenIcon token="leth" w={iconWidth} h={iconHeight} />
            <TokenIcon
              token="eth"
              ml={`calc(-${iconWidth} / 2)`}
              w={iconWidth}
              h={iconHeight}
            />
          </>
        )}
      </Flex>
      {gardenType === 'TECTONIC' ? ` ${gardenToken.name}` : null}
    </Flex>
  );
};
