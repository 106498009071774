import { textStyles } from '../textStyles';
export const Link = {
    baseStyle() {
        return {
            ...textStyles.textLink,
            color: 'text-active',
            _hover: {
                textDecoration: 'none',
                '&[target=_blank]': {
                    textDecoration: 'underline',
                    textDecorationThickness: '2px',
                },
            },
        };
    },
    variants: {},
    sizes: {},
    defaultProps: {},
};
