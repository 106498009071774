import { VvsZap__factory } from '@contracts';
import { parseUnits } from 'ethers/lib/utils';
import { VenoSDKModule } from './base';
import { wrapSdkTransaction } from './util';
export default class VvsZapModule extends VenoSDKModule {
    constructor() {
        super(...arguments);
        this.contractFactory = VvsZap__factory;
        this.contractAddress = this.sdk.addresses.vvsZap;
    }
    async addLiquidity(amount, expected) {
        const expectedBn = parseUnits((expected * 0.98).toFixed(5));
        return await wrapSdkTransaction(this.getContract().zapInToken(this.sdk.addresses.venoToken, amount, this.sdk.addresses.vvsPair, expectedBn));
    }
    async addCroLiquidity(amount, expected) {
        const expectedBn = parseUnits((expected * 0.98).toFixed(5));
        return await wrapSdkTransaction(this.getContract().zapInToken(this.sdk.addresses.liquidCro, amount, this.sdk.addresses.vvsCroPair, expectedBn));
    }
    async addTiaLiquidity(amount, expected) {
        const expectedBn = parseUnits((expected * 0.98).toFixed(5));
        return await wrapSdkTransaction(this.getContract().zapInToken(this.sdk.addresses.liquidTia, amount, this.sdk.addresses.vvsTiaPair, expectedBn));
    }
    getReceivedVvsLp(receipt) {
        const args = receipt.events?.find((e) => e.event === 'ZapInToken')?.args;
        if (!args || args.length !== 4)
            return null;
        return args[3];
    }
}
