import { useMemo } from 'react';

import { useRealPathname } from './router';

export const useIsOnVvs = (): boolean => {
  const pathName = useRealPathname();

  return useMemo(
    () => pathName === '/iframe-vvs' || pathName === '/iframe-vvs-dark',
    [pathName],
  );
};
