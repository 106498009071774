import { useToast } from '@chakra-ui/react';
import type { Error } from '@veno-app/icons';
import { currentWallet, getNetworkConfig } from '@veno-app/wallet';
import { useCallback, useState } from 'react';

const { useIsConnected, connect } = currentWallet;

export const useSwitchNetwork = (onConnect?: () => void) => {
  const [isSwitching, setIsSwitching] = useState(false);
  const isConnected = useIsConnected();
  const toast = useToast();

  const switchNetwork = useCallback(
    async (toZkSync?: boolean) => {
      const networkConfig = getNetworkConfig(toZkSync);
      if (!isConnected) return;
      setIsSwitching(true);
      try {
        await connect(networkConfig);
        onConnect && onConnect();
      } catch (e: any) {
        toast({
          status: 'error',
          position: 'top',
          description: (e as Error)?.message,
        });
      } finally {
        setIsSwitching(false);
      }
    },
    [isConnected, onConnect, toast],
  );

  return { switchNetwork, isSwitching };
};
