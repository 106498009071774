import type { FlexProps } from '@chakra-ui/react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { LPTokenIcon } from '@ui/components/LPTokenIcon';
import { InDesktop } from '@ui/components/MobileOrDesktop';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import useFarmAprs from '@ui/hooks/useFarmAprs';
import useTotalGardenDeposit from '@ui/hooks/useTotalGardenDeposit';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { formatTokenAmount } from '@ui/utils/format';
import type { GardenType } from '@ui/utils/garden';
import {
  gardenTokenDict,
  getGardenAvatarUrl,
  getGardenName,
} from '@ui/utils/garden';
import { ColorThemeVars } from '@veno-app/chakra';
import React from 'react';

import { GardenBooster } from './GardenBooster';

export type GardenLogoProps = FlexProps & {
  coinMode: CoinMode;
  gardenType: GardenType;
  showGardenName?: boolean;
  showTVL?: boolean;
};
export const GardenLogo = ({
  coinMode,
  gardenType,
  showGardenName = true,
  showTVL = true,
  ...props
}: GardenLogoProps) => {
  const sdk = useVenoSDK();
  const { isBoosted } = useFarmAprs();
  const { totalBalance } = useTotalGardenDeposit(gardenType);

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      pt={{
        desktop: isBoosted ? '6px' : 0,
      }}
      {...props}
    >
      <Flex justify="center">
        <Flex
          w="60px"
          h="60px"
          justify="center"
          align="center"
          bg="white"
          rounded="full"
          boxShadow={`var(${ColorThemeVars.parentSmallShadow})`}
          position="relative"
        >
          <Image
            alt="Brand Image"
            w="full"
            h="full"
            src={getGardenAvatarUrl(sdk.chainType, gardenType)}
          />
          <InDesktop>
            {isBoosted && <GardenBooster position="absolute" top="0" />}
          </InDesktop>
        </Flex>
      </Flex>
      <InDesktop>
        <Text as="h1" hidden={!showGardenName} textStyle="h3" mt="6px">
          {getGardenName(coinMode, gardenType, false)}
        </Text>
        <Box
          hidden={!showTVL}
          mx="auto"
          textAlign="center"
          textStyle="body"
          color="text-light"
        >
          <LPTokenIcon coinMode={coinMode} gardenType={gardenType}>
            TVL{' '}
            {formatTokenAmount(
              totalBalance,
              3,
              gardenTokenDict[coinMode][gardenType].decimals,
            )}
          </LPTokenIcon>
        </Box>
      </InDesktop>
    </Flex>
  );
};
