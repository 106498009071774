import type { ChakraProps } from '@chakra-ui/react';
import { Flex, Image, Text } from '@chakra-ui/react';
import { pathDict } from '@ui/config/paths';
import { useApy } from '@ui/hooks/useApr';
import useCoinBalance from '@ui/hooks/useCoinBalance';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { COIN_MODE } from '@ui/hooks/useCoinMode';
import { useTranslations } from '@ui/i18n';
import Link from '@ui/overrides/next/link';
import { formatNumber } from '@ui/utils/format';
import type { ChainType } from '@veno-app/sdk';
import { ZKSYNC_ENABLED } from '@veno-app/wallet';
import type { FC } from 'react';
import React from 'react';

import { NavigationButton, NavigationCard } from './NavigationCard';

type StakeCoinNavigationCardProps = {
  coinMode: CoinMode;
  onClose: () => void;
  balanceDecimals?: number;
  network: ChainType;
};

const getBgProps = (network: ChainType): ChakraProps =>
  !ZKSYNC_ENABLED
    ? {}
    : network === 'cronos'
    ? {
        backgroundImage: '/images/navigation-card-cronos.svg',
        backgroundSize: 'auto 100%',
        backgroundPosition: 'top right',
        backgroundRepeat: 'no-repeat',
      }
    : {
        backgroundImage: '/images/navigation-card-zksync.svg',
        backgroundSize: 'auto 100%',
        backgroundPosition: 'top right',
        backgroundRepeat: 'no-repeat',
      };

const StakeCoinNavigationCard: FC<StakeCoinNavigationCardProps> = ({
  coinMode,
  onClose,
  balanceDecimals = 2,
  network,
}) => {
  const t = useTranslations();
  const coinModeInfo = COIN_MODE[coinMode];
  const { data: balance } = useCoinBalance(coinMode);
  const { data: apy } = useApy({ coinMode });

  return (
    <NavigationCard
      icon={<coinModeInfo.Icon width="32px" height="32px" />}
      title={
        <Flex justify="space-between">
          <Text textTransform="uppercase">
            {t('{coin} staking', {
              coin: coinModeInfo.name,
            })}
          </Text>
          {ZKSYNC_ENABLED && network === 'zksync' && (
            <Image
              src="/images/zksync-network.svg"
              w="53px"
              h="10px"
              alt="zksync-network"
            />
          )}
          {ZKSYNC_ENABLED && network === 'cronos' && (
            <Image
              src="/images/cronos-network.svg"
              w="48px"
              h="10px"
              alt="cronos-network"
            />
          )}
        </Flex>
      }
      subtitle={
        <Flex justify="space-between">
          <Text>
            {t('Balance')} : {formatNumber(balance ?? 0, balanceDecimals)}{' '}
            {coinModeInfo.name}
          </Text>
          <Text fontWeight="800" textAlign="right" textTransform="uppercase">
            {apy > 0 ? <>{(apy * 100).toFixed(2)}% APY</> : <>-- APY</>}
          </Text>
        </Flex>
      }
      {...getBgProps(network)}
    >
      <Link href={pathDict[coinMode].unstake} passHref>
        <NavigationButton
          as="a"
          flex={1}
          textTransform="uppercase"
          onClick={onClose}
        >
          {t('Unstake')}
        </NavigationButton>
      </Link>
      <Link href={pathDict[coinMode].useLiquid} passHref>
        <NavigationButton
          as="a"
          flex={1}
          textTransform="uppercase"
          onClick={onClose}
        >
          {t('Use {coin}', {
            coin: coinModeInfo.liquidName,
          })}
        </NavigationButton>
      </Link>
      <Link href={pathDict[coinMode].stake} passHref>
        <NavigationButton
          as="a"
          flex={1}
          textTransform="uppercase"
          isPrimary
          onClick={onClose}
        >
          {t('Stake')}
        </NavigationButton>
      </Link>
    </NavigationCard>
  );
};

export default StakeCoinNavigationCard;
