import { StrategyVault__factory } from '@contracts';
import { formatUnits } from 'ethers/lib/utils';
import { VenoSDKModule } from './base';
import { expandDecimals, FERRO_LP_DECIMALS } from './constants';
import { wrapSdkTransaction } from './util';
export default class FerroVaultModule extends VenoSDKModule {
    constructor() {
        super(...arguments);
        this.contractAddress = this.sdk.addresses.ferroVault;
        this.contractFactory = StrategyVault__factory;
    }
    async deposit(amount, _slippage) {
        return await wrapSdkTransaction(this.getContract()['deposit(uint256)'](amount));
    }
    async withdraw(amount, _slippage) {
        return await wrapSdkTransaction(this.getContract()['withdraw(uint256)'](amount));
    }
    async getLpTokenAssetPrice(_amount) {
        const amount = _amount && _amount.gt(0) ? _amount : expandDecimals(1, FERRO_LP_DECIMALS);
        const usdBn = await this.sdk.VenoStorm.getLpValueUsdV2('FERRO', amount);
        const amountHuman = parseFloat(formatUnits(amount));
        const usdHuman = parseFloat(formatUnits(usdBn));
        return usdHuman / amountHuman;
    }
    async balanceOf(account) {
        return this.getContract().balanceOf(account);
    }
    async allowance(account, operator) {
        return this.getContract().allowance(account, operator);
    }
    async exchangeRate() {
        return this.getContract().pricePerShare();
    }
    async getWithdrawExchangeRate(_) {
        return this.exchangeRate();
    }
    async getDepositExchangeRate(_) {
        return this.exchangeRate();
    }
    async approve(operator, amount) {
        return await wrapSdkTransaction(this.getContract().approve(operator, amount));
    }
    async getTvl() {
        const [totalShares, pricePerShare, wcroUsdPrice] = await Promise.all([
            this.getContract().totalSupply(),
            this.getContract().pricePerShare(),
            this.getLpTokenAssetPrice(),
        ]);
        const totalSharesHuman = parseFloat(formatUnits(totalShares, 18));
        const pricePerShareHuman = parseFloat(formatUnits(pricePerShare, 18));
        const totalTokens = totalSharesHuman * pricePerShareHuman;
        const totalValueFixed = totalTokens * (wcroUsdPrice ?? 0);
        return totalValueFixed;
    }
    async getUsdValue(vaultTokenAmount) {
        const pricePerShare = await this.getContract().pricePerShare();
        const lpTokens = vaultTokenAmount
            .mul(pricePerShare)
            .div(expandDecimals(1, 18));
        const lpTokensUsdPrice = await this.getLpTokenAssetPrice(lpTokens);
        const lpTokensHuman = parseFloat(formatUnits(lpTokens, 18));
        return lpTokensHuman * lpTokensUsdPrice;
    }
    async shouldApproveWhenWithdraw(_account, _amount) {
        return false;
    }
    async approveWhenWithdraw(_amount) {
        // do nothing, not needed. just for interface.
        return;
    }
    async getDepositPriceImpact(_tokenAmount) {
        return 0;
    }
    async getWithdrawPriceImpact(_tokenAmount) {
        return 0;
    }
}
