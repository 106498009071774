import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import React from 'react';

import { GetAssetOnVvsContent } from '../GetAssetDrawers/GetAssetOnVvs';
import { useAccountModalStore } from './useAccountModalStore';

function AccountModalDrawerContentsZksync() {
  const openDrawer = useAccountModalStore((s) => s.openDrawer);

  return (
    <>
      {openDrawer === 'vno' && (
        <GetAssetOnVvsContent
          tokenLogoSrc="/tokens/vno.svg"
          tokenName="VNO"
          swapLink={EXTERNAL_LINK.vvs}
        />
      )}
    </>
  );
}

export default AccountModalDrawerContentsZksync;
