import { Flex } from '@chakra-ui/react';
import { TokenIcon } from '@ui/components/TokenIcon';
import { pathDict } from '@ui/config/paths';
import { useCoinUsdValue } from '@ui/hooks/useCoinUsdValue';
import useNftTokens from '@ui/hooks/useNftTokens';
import { useTranslations } from '@ui/i18n';
import { formatTokenAmount } from '@ui/utils/format';
import { useRouter } from 'next/router';

import AccountModalInfo from './AccountModalInfo';
import { useAccountModalStore } from './useAccountModalStore';

const NftPanelZksync = () => {
  const t = useTranslations();
  const toggleAccountModal = useAccountModalStore((s) => s.toggleAccountModal);
  const router = useRouter();
  const ethNftTokens = useNftTokens({
    coinMode: 'eth',
  });
  const ethAmountUsd = useCoinUsdValue(
    ethNftTokens.redeemableTokenAmount,
    'eth',
  ).data;

  return (
    <>
      <AccountModalInfo
        title={
          <Flex>
            <TokenIcon token="eth" />
            {t('Claimable {coin}', {
              coin: 'ETH',
            })}
          </Flex>
        }
        value={
          formatTokenAmount(ethNftTokens.redeemableTokenAmount) ?? undefined
        }
        convertedValue={
          ethAmountUsd ? formatTokenAmount(ethAmountUsd) + ' USD' : undefined
        }
        convertedValueLoading={!ethAmountUsd}
        actionType="claim"
        action={() => {
          router.push(pathDict.eth.claim);
          toggleAccountModal(false);
        }}
      />
    </>
  );
};

export default NftPanelZksync;
