import type { BigNumber } from '@ethersproject/bignumber';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { useCoinMode } from '@ui/hooks/useCoinMode';

import useCoinPerStakedCoin from './useCoinPerStakedCoin';
import { useCoinUsdValue } from './useCoinUsdValue';

export type UseLiquidCoinBalanceOptions = {
  coinMode?: CoinMode;
  amount: BigNumber | undefined;
};
export const useLiquidCoinUsd = (options: UseLiquidCoinBalanceOptions) => {
  return useCoinUsdValue(
    useCoinAmountFromLiquidCoin(options),
    options.coinMode,
  );
};

export const useCoinAmountFromLiquidCoin = ({
  coinMode: _coinMode,
  amount,
}: UseLiquidCoinBalanceOptions) => {
  const globalCoinMode = useCoinMode();
  const coinMode = _coinMode ?? globalCoinMode;

  const { data: croPerStcro } = useCoinPerStakedCoin(coinMode);
  return croPerStcro
    ? amount?.mul(Math.round(croPerStcro * 1_000_000)).div(1_000_000)
    : undefined;
};
