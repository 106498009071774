import type { BigNumber } from '@ethersproject/bignumber';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';

const useVvsTiaSupply = () => {
  const queryClient = useQueryClient();
  const sdk = useVenoSDK();
  const supply = async (amount: BigNumber, expected: number) => {
    const data = await sdk.VvsZap.addTiaLiquidity(amount, expected);
    return await data.txReceiptPromise;
  };

  return useMutation(
    ({ amount, expected }: { amount: BigNumber; expected: number }) =>
      supply(amount, expected),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(['vvs-tia-lp-balance']);
      },
    },
  );
};

export default useVvsTiaSupply;
