import type { BoxProps } from '@chakra-ui/react';
import { Box, Button, Flex, Skeleton, Text } from '@chakra-ui/react';
import { BigNumber } from '@ethersproject/bignumber';
import type { CardProps } from '@ui/components/Card';
import { Card } from '@ui/components/Card';
import type { TokenIconProps } from '@ui/components/TokenIcon';
import { TokenIcon } from '@ui/components/TokenIcon';
import {
  useVenoFountainData,
  useVenoReservoirData,
} from '@ui/components/VenoInfoView/useVenoInfoData';
import { COIN_MODE } from '@ui/hooks/useCoinMode';
import { useCoinUsdValue } from '@ui/hooks/useCoinUsdValue';
import {
  useVenoFountainHarvest,
  useVenoReservoirHarvest,
} from '@ui/hooks/useVenoHarvest';
import { useVnoUsdValue } from '@ui/hooks/useVnoUsdValue';
import { useWcroUsdValue } from '@ui/hooks/useWcroUsdPrice';
import { useTranslations } from '@ui/i18n';
import { PRELAUNCH } from '@ui/utils/constants';
import { formatPendingRewards, formatTokenAmount } from '@ui/utils/format';
import type { ReactNode } from 'react';
import React from 'react';

export const ClaimPendingVnoCard = ({
  type,
  ...props
}: Partial<CardProps> & { type: 'RESERVOIR' | 'FOUNTAIN' }) => {
  const t = useTranslations();

  const {
    isLoading: isVenoFountainLoading,
    pendingRewards: pendingRewardsFountain,
  } = useVenoFountainData(!PRELAUNCH && type === 'FOUNTAIN');
  const {
    isLoading: isVenoReservoirLoading,
    pendingRewardsMap: pendingRewardsReservoir,
  } = useVenoReservoirData(!PRELAUNCH && type === 'RESERVOIR');

  const { data: fountainPendingRewardsUsd } = useVnoUsdValue(
    pendingRewardsFountain,
  );
  const { data: reservoirPendingWcroUsd } = useWcroUsdValue(
    pendingRewardsReservoir?.wcro,
  );
  const { data: reservoirPendingWethUsd } = useCoinUsdValue(
    pendingRewardsReservoir?.weth,
    'eth',
  );
  const { data: reservoirPendingAtomUsd } = useCoinUsdValue(
    pendingRewardsReservoir?.atom,
    'atom',
  );

  const {
    mutate: venoFountainHarvest,
    isLoading: isVenoFountainHarvestLoading,
  } = useVenoFountainHarvest();
  const {
    mutate: venoReservoirHarvest,
    isLoading: isVenoReservoirHarvestLoading,
  } = useVenoReservoirHarvest();

  return (
    <Card p="10px" {...props}>
      <Flex alignItems="center">
        <Text flex="1">{t('Unclaimed rewards')}</Text>
        <Button
          variant="primary"
          size="sm"
          disabled={
            type === 'FOUNTAIN'
              ? isVenoFountainLoading || pendingRewardsFountain?.lte(0)
              : isVenoReservoirLoading ||
                ((pendingRewardsReservoir.wcro ?? BigNumber.from(0)).lte(0) &&
                  (pendingRewardsReservoir.atom ?? BigNumber.from(0)).lte(0) &&
                  (pendingRewardsReservoir.weth ?? BigNumber.from(0)).lte(0))
          }
          isLoading={
            isVenoFountainHarvestLoading || isVenoReservoirHarvestLoading
          }
          onClick={() =>
            type === 'FOUNTAIN' ? venoFountainHarvest() : venoReservoirHarvest()
          }
        >
          {t('Harvest')}
        </Button>
      </Flex>
      <Flex direction="column" gap="16px" mt="16px" alignItems="stretch">
        {type === 'FOUNTAIN' ? (
          <RewardRow
            token="vno"
            content={formatPendingRewards(pendingRewardsFountain)}
            footer={`${formatTokenAmount(fountainPendingRewardsUsd, 2)} USD`}
            isLoaded={!isVenoFountainLoading}
          />
        ) : (
          <>
            {pendingRewardsReservoir.wcro !== null &&
              reservoirPendingWcroUsd !== null && (
                <RewardRow
                  token="cro"
                  content={formatPendingRewards(pendingRewardsReservoir.wcro)}
                  footer={`${formatTokenAmount(
                    reservoirPendingWcroUsd,
                    2,
                  )} USD`}
                  isLoaded={!isVenoReservoirLoading}
                />
              )}
            {pendingRewardsReservoir.atom !== null &&
              reservoirPendingAtomUsd !== null && (
                <RewardRow
                  token="atom"
                  content={formatPendingRewards(
                    pendingRewardsReservoir.atom,
                    undefined,
                    COIN_MODE.atom.decimals,
                  )}
                  footer={`${formatTokenAmount(
                    reservoirPendingAtomUsd,
                    2,
                  )} USD`}
                  isLoaded={!isVenoReservoirLoading}
                />
              )}
            {pendingRewardsReservoir.weth !== null &&
              reservoirPendingWethUsd !== null && (
                <RewardRow
                  token="atom"
                  content={formatPendingRewards(
                    pendingRewardsReservoir.weth,
                    undefined,
                    COIN_MODE.atom.decimals,
                  )}
                  footer={`${formatTokenAmount(
                    reservoirPendingWethUsd,
                    2,
                  )} USD`}
                  isLoaded={!isVenoReservoirLoading}
                />
              )}
          </>
        )}
      </Flex>
    </Card>
  );
};

const RewardRow = ({
  token,
  content,
  footer,
  isLoaded = true,
  ...props
}: BoxProps & {
  token: TokenIconProps['token'];
  content: ReactNode;
  footer: ReactNode;
  isLoaded?: boolean;
}) => {
  return (
    <Skeleton isLoaded={isLoaded} {...props}>
      <Flex alignItems="center" gap="8px">
        <TokenIcon token={token} />
        <Box flex="1" textStyle="bodyBold">
          {content}
        </Box>
        <Box color="text-light">{footer}</Box>
      </Flex>
    </Skeleton>
  );
};
