import { formatUnits } from '@ethersproject/units';

import type { Locales, Unit } from './numberHelper';
import {
  DEFAULT_NUMBER,
  formatFraction,
  formatInvalidHOC,
  formatSignificant,
  UNITS,
} from './numberHelper';

export const PURE_DECIMAL_REG = /^[0-9]*\.[0-9]+$/;

const MAX_DIGITS_FRACTION = 2;
const MAX_DIGITS_SIGNIFICANT = 4;

/**
 * 1000 => 1,000
 */
const formatInt = (value: number | bigint, locales?: Locales) =>
  formatInvalidHOC(formatFraction)(value, 0, 0, locales);

/**
 * 1000 => 1,000, 1000.005 => 1,000.01
 */
const formatDecimal = formatInvalidHOC(
  (value, minDigits = 0, maxDigits = MAX_DIGITS_FRACTION, locales) =>
    formatFraction(value, minDigits, maxDigits, locales),
);

/**
 * 0 => 0, 0.12345 => 0.1235
 */
const formatPureDecimal = (
  value: number | bigint,
  effective = MAX_DIGITS_SIGNIFICANT,
  locales?: Locales,
) =>
  // ignore min param which is meaningless
  formatInvalidHOC(formatSignificant)(value, undefined, effective, locales);

type FormatUnitOpts = {
  minUnit?: Unit;
  units?: typeof UNITS;
  format?: (value: string, unit: string) => string;
};
type DigitsOpts = {
  // for formatSignificant maxDigits
  effective?: number;
  // for formatFraction digits
  min?: number;
  max?: number;
};

const formatUnit = (
  value: number | bigint,
  {
    minUnit,
    units = UNITS,
    format = (value, unit) => `${value}${unit}`,
  }: FormatUnitOpts = {},
  { min = MAX_DIGITS_FRACTION, max = min }: DigitsOpts = {},
) => {
  for (const { unit, base } of units) {
    if (value >= base) {
      const text = formatDecimal(
        typeof value === 'bigint' ? BigInt(value) / BigInt(base) : value / base,
        min,
        max,
      );
      return text === DEFAULT_NUMBER ? null : format(text, unit);
    }
    if (unit === minUnit) break;
  }
  return null;
};

/**
 * smaller than 1 formatPureDecimal, otherwise formatDecimal
 * @param value
 * @param DigitsOpts
 * @returns 2222.005 => 2,222.01, 0.12345 => 0.1235
 */
const formatPrice = (
  value: number | bigint,
  { min = MAX_DIGITS_FRACTION, max, effective }: DigitsOpts = {},
) =>
  typeof value === 'bigint' || Math.abs(value) >= 1
    ? formatDecimal(value, min, max)
    : formatPureDecimal(value, effective);

/**
 * formatUnit if can, otherwise formatPrice
 * @param value
 * @param opts
 * @param digitsOpts
 * @returns
 */
const formatAbbreviation = (
  value: number | bigint,
  opts?: FormatUnitOpts,
  digitsOpts?: DigitsOpts,
) => formatUnit(value, opts, digitsOpts) || formatPrice(value, digitsOpts);

/**
 * formatUnit if can, otherwise formatInt
 * @param value
 * @param opts
 * @returns
 */
const formatIntAbbreviation = (value: number | bigint, opts?: FormatUnitOpts) =>
  formatUnit(value, opts, { min: 0, max: MAX_DIGITS_FRACTION }) ||
  formatInt(value);

/**
 * formatUnit if can, otherwise formatDecimal
 * @param value
 * @param opts
 * @returns 1005 => 1.01K, 22.1234 => 22.12
 */
const formatVolume = (value: number | bigint, opts?: FormatUnitOpts) =>
  formatUnit(value, opts) || formatDecimal(value, MAX_DIGITS_FRACTION);

const formatAbbreviationFromWei: typeof formatAbbreviation = (...args) => {
  args[0] = Number(formatUnits(args[0], 'ether'));
  return formatAbbreviation(...args);
};

/**
 * formatDecimal and combine %
 * @param value
 * @param withoutSign
 * @returns
 */
const formatPercentage = (
  value: number | bigint,
  withoutSign = false,
  format = (sign: string, value: string, symbol: string) =>
    sign + value + symbol,
  abbr = false,
) => {
  const res = abbr
    ? formatVolume(
        typeof value === 'number' ? value * 100 : value * BigInt(100),
      )
    : formatDecimal(
        typeof value === 'number' ? value * 100 : value * BigInt(100),
        MAX_DIGITS_FRACTION,
      );
  if (res === DEFAULT_NUMBER) return res;
  return format(!withoutSign && value >= 0 ? '+' : '', res, '%');
};

const formatApr = (value: number | bigint) =>
  formatPercentage(value, true, (s, v, sy) => `${v} ${sy}`, true);

export {
  formatAbbreviation,
  formatAbbreviationFromWei,
  formatApr,
  formatDecimal,
  formatInt,
  formatIntAbbreviation,
  formatPercentage,
  formatPrice,
  formatPureDecimal,
  formatVolume,
};
