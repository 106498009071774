import { useMutation } from '@tanstack/react-query';
import { useUseLcroTour } from '@ui/components/Tours';
import { useCoinMode } from '@ui/hooks/useCoinMode';
import {
  useGardenDeposit,
  useGardenWithdraw,
} from '@ui/hooks/useGardenDeposit';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { approve, getAllowance } from '@ui/utils/garden';
import type { EthersError } from '@veno-app/sdk';
import { currentWallet } from '@veno-app/wallet';

import { useGardenPlantDrawer } from './useGardenPlantDrawer';

export enum GARDEN_PLANT_STEP {
  default,
  confirming,
  success,
  failed,
}

const { useAccount } = currentWallet;

export const useGardenPlant = () => {
  const coinMode = useCoinMode();
  const isWithdraw = useGardenPlantDrawer((s) => s.isWithdraw);
  const setStep = useGardenPlantDrawer((s) => s.setStep);
  const vaultType = useGardenPlantDrawer((s) => s.vaultType);
  const gardenType = useGardenPlantDrawer((s) => s.gardenType);
  const tLcroPlantAmount = useGardenPlantDrawer((s) => s.tLcroPlantAmount);
  const setConfirmStep = useGardenPlantDrawer((s) => s.setConfirmStep);

  const account = useAccount();
  const sdk = useVenoSDK();
  const { mutateAsync: doGardenDeposit } = useGardenDeposit();
  const { mutateAsync: doGardenWithdraw } = useGardenWithdraw();

  return useMutation(async () => {
    if (!vaultType || !gardenType) {
      return null;
    }

    useUseLcroTour.getState().next();

    setStep(GARDEN_PLANT_STEP.confirming);

    try {
      if (!isWithdraw) {
        const allowance = await getAllowance(
          coinMode,
          gardenType,
          sdk,
          account,
        );

        if (allowance.lt(tLcroPlantAmount)) {
          setConfirmStep(1, 2);
          const approveRet = await approve(coinMode, gardenType, sdk);
          await approveRet.txReceiptPromise;
          setConfirmStep(2, 2);
        }

        await doGardenDeposit({
          gardenType,
          vaultType,
          amount: tLcroPlantAmount,
        });
      } else {
        await doGardenWithdraw({
          gardenType,
          vaultType,
          amount: tLcroPlantAmount,
        });
      }

      setStep(GARDEN_PLANT_STEP.success);

      useUseLcroTour.getState().next();
    } catch (err) {
      useGardenPlantDrawer.setState({
        error: err as EthersError,
      });

      setStep(GARDEN_PLANT_STEP.failed);

      return null;
    }
  });
};
