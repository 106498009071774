import { VenoStormZksync__factory } from '@contracts';
import { BigNumber } from '@ethersproject/bignumber';
import LiquidEthModule from './liquidEth';
import SyncSwapLethEthClassicPoolModule from './SyncSwapLethEthClassicPool';
import SyncSwapVnoEthClassicPoolModule from './SyncSwapVnoEthClassicPool';
import VenoStormModule from './venoStorm';
export default class VenoStormZksyncModule extends VenoStormModule {
    constructor() {
        super(...arguments);
        this.contractFactory = VenoStormZksync__factory;
        this.contractAddress = this.sdk.addresses.venoStorm;
        this.SyncSwapLethEthClassicPool = new SyncSwapLethEthClassicPoolModule(this.sdk);
        this.SyncSwapVnoEthClassicPool = new SyncSwapVnoEthClassicPoolModule(this.sdk);
        this.LiquidEth = new LiquidEthModule(this.sdk);
    }
    getZksyncContract() {
        const contract = super.getContract();
        return contract;
    }
    async vnoPerSecond() {
        return this.getZksyncContract().vnoPerSecond();
    }
    async getLpValueUsdV2(type, lpStaked) {
        if (type === 'FERRO') {
            const lethPerEthPromise = this.LiquidEth.coinPerStakedCoin();
            return this.SyncSwapLethEthClassicPool.getLpUsdWorth(lpStaked, lethPerEthPromise);
        }
        if (type === 'VVS') {
            return this.SyncSwapVnoEthClassicPool.getLpUsdWorth(lpStaked);
        }
        return BigNumber.from(0);
    }
    async getLpStaked(type) {
        if (type === 'FERRO') {
            return this.SyncSwapLethEthClassicPool.balanceOf(this.getZksyncContract().address);
        }
        if (type === 'VVS') {
            return this.SyncSwapVnoEthClassicPool.balanceOf(this.getZksyncContract().address);
        }
        return BigNumber.from(0);
    }
}
