import {
  Box,
  Checkbox,
  DrawerCloseButton,
  Flex,
  Grid,
  Icon,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import type { CardProps } from '@ui/components/Card';
import { Card } from '@ui/components/Card';
import { InDesktop, InMobile } from '@ui/components/MobileOrDesktop';
import {
  ModalOrDragDrawerContent,
  ModalOrDrawer,
  ModalOrDrawerBodyWithMask,
  ModalOrDrawerCloseButton,
  ModalOrDrawerFooter,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from '@ui/components/ModalOrDrawer';
import type { TransactionStatus } from '@ui/components/TransactionDrawer';
import {
  DepositUpgradeCompleted,
  TransactionError,
  TransactionPending,
} from '@ui/components/TransactionDrawer';
import { VnoAprSkeleton } from '@ui/components/VnoAprSkeleton';
import { usePoolInfos } from '@ui/hooks/usePoolInfos';
import { useVaultUpgrade } from '@ui/hooks/useVenoUpgrade';
import { useVnoUsdValues } from '@ui/hooks/useVnoUsdValue';
import { useTranslations } from '@ui/i18n';
import { formatNumber } from '@ui/utils/format';
import { formatLockPeriod } from '@ui/utils/formatLockPeriod';
import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import { colors } from '@veno-app/chakra';
import { ArrowBack, Check } from '@veno-app/icons';
import type { EthersError } from '@veno-app/sdk';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { create } from 'zustand';

import { useVaultSelectionStore } from '../VaultSelectionStore';
import DepositUpgradeConfirmLock from './DepositUpgradeConfirmLock';
import DepositUpgradeFooter from './DepositUpgradeFooter';
import StakeCards, { useStakeStore } from './StakeCards';
import { UpgradeStep } from './types';

type SelectableVaultCardProps = {
  pid: number;
  lockPeriod: number;
  apr: number | null;
  multiplier: number;
  isSelected: boolean;
  onSelect: (pid: number | null) => void;
  readOnly?: boolean;
} & Omit<CardProps, 'onSelect'>;
const SelectableVaultCard: React.FC<SelectableVaultCardProps> = ({
  pid,
  lockPeriod,
  apr,
  multiplier,
  isSelected,
  onSelect,
  readOnly,
  ...props
}) => {
  const t = useTranslations();
  return (
    <Card
      as={Flex}
      flexDir="column"
      p="10px"
      isActive={isSelected}
      onClick={() => {
        if (!readOnly) onSelect(isSelected ? null : pid);
      }}
      color="text.normal"
      m={2}
      {...props}
    >
      <Flex justifyContent="space-between" mb={4}>
        <Text textStyle="bodyBold">
          {formatLockPeriod(lockPeriod, { roughly: true })}
        </Text>
        <Checkbox
          isChecked={isSelected}
          pointerEvents="none"
          icon={<Check color={colors.bg.card} />}
        />
      </Flex>
      <Grid templateColumns="max-content 1fr" rowGap={3}>
        <Text textStyle="bodySmall">APR</Text>
        {apr !== null ? (
          <Text
            align="end"
            textStyle="bodyBold"
            color="text.light"
            wordBreak="break-all"
          >
            {formatNumber(apr * 100, 2)}%
          </Text>
        ) : (
          <VnoAprSkeleton />
        )}
        <Text textStyle="bodySmall">{t('Multiplier')}</Text>
        <Text
          align="end"
          textStyle="bodyBold"
          color="text.light"
          wordBreak="break-all"
        >
          {(multiplier / 100).toFixed(0)}x
        </Text>
      </Grid>
    </Card>
  );
};

const DepositUpgradeDrawer: React.FC = () => {
  const t = useTranslations();

  const { isOpen, onClose } = useDepositUpgradeDrawer();
  const [step, setStep] = useState<UpgradeStep>(UpgradeStep.SelectStake);
  const [targetPoolId, setTargetPoolId] = useState<number | null>(null);
  const [transactionStatus, setTransactionStatus] =
    useState<TransactionStatus>('CONFIRM');
  const [error, setError] = useState<EthersError | null>(null);
  const onResetSelectedStakes = useStakeStore((s) => s.onDeselectAll);
  const resetVault = useVaultSelectionStore((s) => s.resetSelectedVault);
  const selectedStakeIds = useStakeStore((s) => s.selectedStakeIds);
  const vaultInfo = useVaultSelectionStore((s) => s.selectedVault);
  const vaultType = vaultInfo?.type ?? 'RESERVOIR';
  const { mutateAsync: upgrade } = useVaultUpgrade(vaultType);

  const isFooterButtonDisabled =
    (step === UpgradeStep.SelectStake && selectedStakeIds.length === 0) ||
    (step === UpgradeStep.Upgrade && targetPoolId === null);

  const handleCloseAndReset = (): void => {
    onClose();
    setError(null);
    setStep(UpgradeStep.SelectStake);
    onResetSelectedStakes();
    setTargetPoolId(null);
    // reset selected vault on success, because data will be outdated
    if (transactionStatus === 'COMPLETED') resetVault();
    setTransactionStatus('CONFIRM');
  };

  const handleUpgrade = useCallback(async () => {
    if (selectedStakeIds.length === 0 || !targetPoolId) return;
    setTransactionStatus('PENDING');
    try {
      await upgrade({
        stakeIds: selectedStakeIds,
        targetPid: targetPoolId,
      });
    } catch (err) {
      setError(err as EthersError);
      setTransactionStatus('ERROR');
      return;
    }
    setTransactionStatus('COMPLETED');
  }, [selectedStakeIds, targetPoolId, upgrade]);

  return (
    <ModalOrDrawer
      isOpen={isOpen}
      onClose={handleCloseAndReset}
      scrollBehavior="inside"
      placement="bottom"
      size={{
        base: 'full',
        desktop: 'md',
      }}
    >
      <ModalOrDrawerOverlay />
      <ModalOrDragDrawerContent>
        <ModalOrDrawerHeader
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          textAlign="left"
        >
          <Box flex={1}>
            {step > 0 && (
              <ModalOrDrawerCloseButton
                left="10px"
                onClick={(e) => {
                  setStep((step) => step - 1);
                  e.preventDefault();
                }}
              >
                <Icon
                  as={ArrowBack}
                  boxSize={{
                    desktop: '16px',
                  }}
                />
              </ModalOrDrawerCloseButton>
            )}
          </Box>
          <Box flex={3} textAlign="center">
            {t('Upgrade locking period')}
          </Box>
          <Box flex={1} />
        </ModalOrDrawerHeader>
        <InDesktop>
          <DrawerCloseButton />
        </InDesktop>
        <ModalOrDrawerBodyWithMask>
          <DepositUpgradeContent
            step={step}
            transactionStatus={transactionStatus}
            error={error}
            targetPoolId={targetPoolId}
            onTargetPoolChange={setTargetPoolId}
          />
        </ModalOrDrawerBodyWithMask>
        <ModalOrDrawerFooter>
          <DepositUpgradeFooter
            step={step}
            transactionStatus={transactionStatus}
            onCloseDrawer={handleCloseAndReset}
            onUpgrade={handleUpgrade}
            onNextStep={() => setStep((step) => step + 1)}
            disabled={isFooterButtonDisabled}
          />
        </ModalOrDrawerFooter>
      </ModalOrDragDrawerContent>
    </ModalOrDrawer>
  );
};

interface DepositUpgradeContentProps {
  step: UpgradeStep;
  transactionStatus: TransactionStatus;
  error: EthersError | null;
  targetPoolId: number | null;
  onTargetPoolChange: (pid: number | null) => void;
}
const DepositUpgradeContent: React.FC<DepositUpgradeContentProps> = ({
  step,
  transactionStatus,
  error,
  targetPoolId,
  onTargetPoolChange,
}) => {
  const t = useTranslations();

  const vaultInfo = useVaultSelectionStore((s) => s.selectedVault);
  const vaultType = vaultInfo?.type || 'RESERVOIR';
  const { pools } = usePoolInfos(vaultType);
  const { stakes, selectedStakeIds } = useStakeStore();

  const selectedStakes = useMemo(
    () =>
      stakes?.filter((s) => selectedStakeIds.includes(Number(s.stakeId))) ?? [],
    [stakes, selectedStakeIds],
  );
  const { data: usdValues } = useVnoUsdValues(
    selectedStakes.map((s) => s.amount),
  );

  const upgradePools = pools?.filter(
    (p) => p.lockPeriod > (vaultInfo?.lockPeriod ?? 0),
  );
  const targetPool = upgradePools?.find((p) => p.pid === targetPoolId) ?? null;

  useEffect(() => {
    if (upgradePools?.length === 1) {
      onTargetPoolChange(upgradePools[0].pid);
    }
  }, [onTargetPoolChange, upgradePools]);

  if (!vaultInfo) return null;

  switch (transactionStatus) {
    case 'COMPLETED':
      return <DepositUpgradeCompleted.Content />;
    case 'PENDING':
      return <TransactionPending.Content />;
    case 'ERROR':
      return <TransactionError.Content error={error} />;
    default:
      switch (step) {
        case UpgradeStep.SelectStake:
          return (
            <Box mb="100px">
              <Text textStyle="h3" align="center" mb={8} mx={4}>
                {t('Which deposits would you like to upgrade?')}
              </Text>
              <StakeCards
                isCheckBox
                vaultType={vaultType}
                stakeCount={vaultInfo.stakeCount}
                lockedStakes={vaultInfo.lockedStakes}
                unlockedStakes={vaultInfo.unlockedStakes}
              />
            </Box>
          );
        case UpgradeStep.Confirm:
          return (
            <DepositUpgradeConfirmLock
              selectedStakes={selectedStakes}
              targetPool={targetPool}
              usdValues={usdValues}
            />
          );
        default:
          return (
            <Box color="text.normal">
              <Text textStyle="h3" align="center" mb={7}>
                {t('Select locking tier')}
              </Text>
              {pools && (
                <SimpleGrid
                  columns={(upgradePools?.length ?? 0) > 2 ? 2 : 1}
                  w={upgradePools?.length === 1 ? '50%' : 'full'}
                  mx="auto"
                  mb={8}
                >
                  {upgradePools?.map((pool) => (
                    <SelectableVaultCard
                      key={pool.pid}
                      pid={pool.pid}
                      lockPeriod={pool.lockPeriod}
                      apr={pool.apr}
                      multiplier={pool.multiplier}
                      isSelected={targetPoolId === pool.pid}
                      onSelect={onTargetPoolChange}
                      readOnly={upgradePools.length === 1}
                    />
                  ))}
                </SimpleGrid>
              )}
              <InMobile>
                <DepositUpgradeConfirmLock
                  selectedStakes={selectedStakes}
                  targetPool={targetPool}
                  usdValues={usdValues}
                />
              </InMobile>
            </Box>
          );
      }
  }
};

export default DepositUpgradeDrawer;

type UseDepositUpgradeDrawer = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};
export const useDepositUpgradeDrawer = create<UseDepositUpgradeDrawer>(
  resetWhenRouterChange((set) => ({
    isOpen: false,
    onOpen: (): void => set(() => ({ isOpen: true })),
    onClose: () => set(() => ({ isOpen: false })),
  })),
);
