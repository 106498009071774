import useShouldHideNavigation from '@ui/hooks/useShouldHideNavigation';
import React from 'react';

export function ShowIfNavigation({ children }: { children: React.ReactNode }) {
  const shouldHideNavigation = useShouldHideNavigation();

  return shouldHideNavigation ? null : <>{children}</>;
}

export function ShowIfNoNavigation({
  children,
}: {
  children: React.ReactNode;
}) {
  const shouldHideNavigation = useShouldHideNavigation();

  return shouldHideNavigation ? <>{children}</> : null;
}
