import type { FlexProps } from '@chakra-ui/react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useTranslations } from '@ui/i18n';
import { currentWallet } from '@veno-app/wallet';

import { useWalletModal } from './WalletModal';

export type ConnectWalletProps = FlexProps & {
  actionHint?: string;
};

const { useIsConnected, useIsConnectedToSupportedChain } = currentWallet;

const ConnectWallet = ({ actionHint, ...props }: ConnectWalletProps) => {
  const t = useTranslations();

  const connected = useIsConnected();
  const isConnectedToSupportedChain = useIsConnectedToSupportedChain();
  const { open: openWalletModal } = useWalletModal();

  return (
    <>
      {!(connected && isConnectedToSupportedChain) && (
        <Flex flexDir="column" gap={2} w="full" textAlign="center" {...props}>
          <Text
            textStyle={{ base: 'h3', desktop: 'h1' }}
            fontWeight={{ desktop: 'normal' }}
          >
            {t('Wallet not connected')}
          </Text>
          <Text
            pt={{ base: 0, desktop: 4 }}
            textStyle={{ base: 'body', desktop: 'h3' }}
            color="text.light"
            w="full"
          >
            {actionHint
              ? t('Connect wallet to {do}', {
                  do: actionHint,
                })
              : null}
          </Text>
          <Box m={{ base: 6, desktop: 8 }}>
            <Button onClick={openWalletModal} paddingInline={6}>
              {t('Connect wallet')}
            </Button>
          </Box>
        </Flex>
      )}
    </>
  );
};

export default ConnectWallet;
