export class VenoSDKBase {
    constructor(addresses) {
        this.addresses = addresses;
    }
}
export class VenoSDKModule {
    constructor(sdk) {
        this.sdk = sdk;
        this.invalidateContract = () => {
            this.contract = undefined;
        };
        this.addresses = sdk.addresses;
        this.sdk = sdk;
    }
    get interface() {
        return this.getContract().interface;
    }
    getContract() {
        if (this.contract)
            return this.contract;
        const contract = this.contractFactory.connect(this.contractAddress, this.sdk.walletNotConnected
            ? this.sdk.provider
            : this.sdk.provider.getSigner());
        this.contract = contract;
        return contract;
    }
}
