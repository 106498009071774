/**
 * Tabs theme
 * @see https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src/components/tabs.ts
 */
import { tabsAnatomy as parts } from '@chakra-ui/anatomy';
import { textStyles } from '../textStyles';
import { ColorThemeVars } from '../utils/colorThemes';
export const Tabs = {
    parts: parts.keys,
    baseStyle() {
        return {};
    },
    variants: {
        line(props) {
            const { orientation } = props;
            const borderProp = orientation === 'vertical' ? 'borderStart' : 'borderBottom';
            return {
                tablist: {
                    [borderProp]: 'none',
                },
                tab: {
                    // width=0 divide tabs' width equally
                    width: 0,
                    flexGrow: 1,
                    [borderProp]: '1px solid',
                    color: `var(${ColorThemeVars.tabColor})`,
                    py: '5px',
                    _selected: {
                        color: `var(${ColorThemeVars.tabColorActive})`,
                        fontWeight: 'bold',
                    },
                    _active: {
                        bg: 'none',
                    },
                    _disabled: {
                        color: 'text-disabled',
                        _selected: {
                            color: 'text-disabled',
                        },
                    },
                },
            };
        },
        secondary() {
            return {
                tablist: {
                    color: 'white',
                    bg: 'white',
                    rounded: '5px',
                    p: 1,
                },
                tab: {
                    rounded: '5px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: 'text-inactive',
                    bg: 'white',
                    p: 2,
                    width: 0,
                    flexGrow: 1,
                    _selected: {
                        color: 'white',
                        bg: 'bg-button',
                    },
                    _active: {
                        color: 'white',
                        bg: 'bg-button',
                    },
                },
            };
        },
    },
    sizes: {
        sm: {
            tab: {
                ...textStyles.textLink,
            },
        },
        md: {
            tab: {
                ...textStyles.h4,
            },
            tabpanel: {
                py: {
                    base: '16px',
                    desktop: '32px',
                },
                px: {
                    base: '16px',
                    desktop: '0',
                },
            },
        },
    },
    defaultProps: {
        size: 'md',
        variant: 'line',
        colorScheme: 'blue',
    },
};
