import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';
import { useQuery } from '@tanstack/react-query';
import { useGraphQLContext } from '@ui/providers/GraphQLProvider';
import { getVenoEarnings, getVenoEarningsZksync } from '@ui/queries/history';
import { currentWallet } from '@veno-app/wallet';

const { useAccount } = currentWallet;

const useVenoEarningsData = (address: string) => {
  const isZksync = currentWallet.useIsZksyncChainId();
  const clientContext = useGraphQLContext();
  const query = isZksync
    ? getVenoEarningsZksync(clientContext.venoEarningsClientZksync, address)
    : getVenoEarnings(clientContext.venoEarningsClient, address);
  return useQuery(query.queryKey, query.queryFn, { enabled: true });
};

const useVenoEarnings = (type: 'GARDEN' | 'FOUNTAIN' | 'ALL') => {
  const account = useAccount() ?? '';
  const { data, isLoading } = useVenoEarningsData(account);
  if (!data)
    return {
      isLoading,
      data: undefined,
      currentData: undefined,
      historicalData: undefined,
    };
  if (!data.vnoEarningSnapshots.length)
    return { isLoading, data: [], currentData: 0, historicalData: [] };

  let datapoints: number[] = [];

  if (type === 'GARDEN')
    datapoints = data.vnoEarningSnapshots.map((sn) =>
      parseFloat(sn.gardenEarning),
    );
  else if (type === 'FOUNTAIN')
    datapoints = data.vnoEarningSnapshots.map((sn) =>
      parseFloat(sn.fountainEarning),
    );
  else
    datapoints = data.vnoEarningSnapshots.map((sn) =>
      parseFloat(sn.totalEarning),
    );

  const currentData: number | undefined = datapoints.length
    ? datapoints[0]
    : undefined;

  const historicalData = [
    ...datapoints.map((dp, i) => ({
      value: dp,
      date: parseInt(data.vnoEarningSnapshots[i].date),
    })),
  ];

  const today = new Date();
  today.setUTCHours(0, 0, 0, 0);
  const nDays =
    (today.getTime() / 1000 - historicalData[historicalData.length - 1].date) /
      (3600 * 24) +
    1;
  const dates = Array.from({ length: nDays })
    .map(
      (_, i) =>
        new Date(
          (historicalData[historicalData.length - 1].date + i * 3600 * 24) *
            1000,
        ),
    )
    .slice(-200);

  const filledData = dates.map((date) => {
    const value = historicalData.find(
      (data) => data.date * 1000 <= date.getTime(),
    )?.value;
    let valueBigNumber = BigNumber.from('0');

    try {
      valueBigNumber = parseUnits(`${value ?? 0}`);
    } catch (e) {
      // do nothing
    }

    return {
      date,
      value,
      valueBigNumber,
    };
  });

  const result = { isLoading, currentData, historicalData: filledData };
  return result;
};

export default useVenoEarnings;
