import { StaticJsonRpcProvider } from '@ethersproject/providers';
import { getNetworkConfig } from '@veno-app/wallet';

const { rpcUrls: cronosRpcUrls, chainId: chainIdCronos } = getNetworkConfig();
const { rpcUrls: zksyncRpcUrls, chainId: chainIdZksync } =
  getNetworkConfig(true);
const defaultCronosProvider = new StaticJsonRpcProvider(cronosRpcUrls[0]);
const defaultZksyncProvider = new StaticJsonRpcProvider(zksyncRpcUrls[0]);

export const defaultRpcMap: Record<number, StaticJsonRpcProvider> = {
  [chainIdCronos]: defaultCronosProvider,
  [chainIdZksync]: defaultZksyncProvider,
};
