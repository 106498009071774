import type { BoxProps } from '@chakra-ui/react';
import {
  Box,
  Flex,
  Text,
  useBreakpointValue,
  useToken,
} from '@chakra-ui/react';
import { TinyColor } from '@ctrl/tinycolor';
import { flattenDeep, max, min } from 'lodash-es';
import type { ReactNode } from 'react';
import { Suspense, useEffect, useMemo, useRef, useState } from 'react';

import { Card } from './Card';
import { DynamicHighchartsReact } from './DynamicHighchartsReact';

export type SimpleLineChartSeries = {
  data: {
    y: number;
    additionalValues?: number[];
    name: string;
    key?: string;
  }[];
  color: string;
  label?: string;
}[];

export type SimpleLineChartProps = Omit<
  BoxProps,
  'w' | 'h' | 'width' | 'height' | 'title'
> & {
  series: SimpleLineChartSeries;
  width?: number | string | null;
  height?: number | string | null;
  lineColorAlphaBase?: number;
  title?: ReactNode;
  tooltipFormatter?: (dataItem: {
    y: number;
    additionalValues?: number[];
    name: string;
    key?: string;
  }) => string | number;
  onActiveElementChanged?: (elementIndex: number) => void;
  AdditionalInfo?: ReactNode;
  xAxisLineColor?: string;
};
export const SimpleLineChart = ({
  series,
  width = null,
  height = 126,
  lineColorAlphaBase = 0.2,
  title,
  AdditionalInfo,
  tooltipFormatter = ({ y }) => y,
  onActiveElementChanged,
  xAxisLineColor,
  ...props
}: SimpleLineChartProps) => {
  const [tooltipEnabled, setTooltipEnabled] = useState(false);
  const [pointIndex, setPointIndex] = useState(-1);
  const [tooltipHtml, setTooltipHtml] = useState('');
  const tooltipElemRef = useRef<HTMLDivElement>(null);
  const isDesktop =
    useBreakpointValue({
      base: false,
      desktop: true,
    }) || false;

  useEffect(() => {
    if (!tooltipElemRef.current) {
      return;
    }
    setTooltipHtml(tooltipElemRef.current.innerHTML);
  }, [pointIndex, AdditionalInfo]);

  const safePointIndex = useMemo(() => {
    return Math.min(series[0]?.data?.length - 1 ?? 0, pointIndex);
  }, [pointIndex, series]);

  const chartOptions = useChartOptions({
    tooltipEnabled: isDesktop || tooltipEnabled,
    series,
    width,
    height,
    lineColorAlphaBase,
    tooltipHtml,
    onPointIndexChanged: (pointIndex) => {
      setPointIndex(pointIndex);
      onActiveElementChanged && onActiveElementChanged(pointIndex);
    },
    xAxisLineColor,
  });
  return (
    <Suspense>
      <Box
        position="relative"
        {...props}
        onContextMenu={(e) => {
          // avoid showing context menu
          e.preventDefault();
          return false;
        }}
        onTouchStartCapture={() => !isDesktop && setTooltipEnabled(true)}
        onMouseDownCapture={() => !isDesktop && setTooltipEnabled(true)}
        onTouchEnd={() => !isDesktop && setTooltipEnabled(false)}
        onMouseUp={() => !isDesktop && setTooltipEnabled(false)}
      >
        {title ? (
          <Box textStyle="caption" color="text-light" mb="10px">
            {title}
          </Box>
        ) : null}
        <DynamicHighchartsReact options={chartOptions} />
        <Box ref={tooltipElemRef} display="none">
          <Card
            colorTheme="default"
            boxShadow="chartTooltipCard"
            rounded="5px"
            textStyle="captionSmall"
            p="8px"
            pointerEvents="none"
            m="0"
            color="brand-black"
            _dark={{
              boxShadow: 'unset',
            }}
          >
            <Text
              mb="8px"
              textStyle={{ base: 'caption', desktop: 'bodySmall' }}
            >
              {series[0].data[safePointIndex]?.name}
            </Text>
            {series.map((item, i) => {
              if (series.length < 2) {
                return (
                  <Text
                    key={i}
                    textAlign="center"
                    textStyle={{ base: 'caption', desktop: 'bodySmall' }}
                  >
                    {tooltipFormatter(
                      item.data[safePointIndex] || {
                        y: 0,
                        name: '',
                      },
                    )}
                  </Text>
                );
              }
              return (
                <Flex key={i} gap="4px" mt="4px" align="center">
                  <Box w="7px" h="7px" bg={item.color} rounded="999px" />
                  <Text textStyle={{ base: 'caption', desktop: 'bodySmall' }}>
                    {tooltipFormatter(
                      item.data[safePointIndex] || {
                        y: 0,
                        name: '',
                      },
                    )}
                  </Text>
                </Flex>
              );
            })}
            {AdditionalInfo}
          </Card>
        </Box>
        {series.some((v) => Boolean(v.label)) ? (
          <Flex
            wrap="wrap"
            mt={{
              base: '13px',
              desktop: '17px',
            }}
          >
            {series.map((seriesItem, i) => {
              if (!seriesItem.label) {
                return null;
              }
              return (
                <Flex mr={6} key={i} align="center">
                  <Box
                    w="20px"
                    h="5px"
                    borderRadius="999px"
                    bgColor={seriesItem.color}
                  />
                  <Text color="text-light" textStyle="bodySmall" ml="4px">
                    {seriesItem.label}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        ) : null}
      </Box>
    </Suspense>
  );
};

const useChartOptions = ({
  tooltipEnabled,
  series,
  width,
  height,
  lineColorAlphaBase,
  tooltipHtml,
  onPointIndexChanged,
  xAxisLineColor = '#D1E2EC',
}: {
  tooltipEnabled: boolean;
  series: SimpleLineChartSeries;
  width?: number | string | null;
  height?: number | string | null;
  lineColorAlphaBase?: number;
  tooltipHtml: string;
  onPointIndexChanged: (index: number) => void;
  xAxisLineColor?: string;
}): Highcharts.Options => {
  const [shadowColor] = useToken('colors', ['shadow']);
  const options: Highcharts.Options = {
    chart: {
      width: width,
      height: height,
      animation: false,
      backgroundColor: 'transparent',
      spacing: [0, 2, 2, 2],
    },
    legend: {
      enabled: false,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    plotOptions: {
      area: {
        threshold: null,
        lineWidth: 1,
        marker: {
          lineColor: 'transparent',
          fillColor: 'transparent',
          symbol: 'circle',
        },
      },
      series: {
        pointPlacement: 'on',
        point: {
          events: {
            mouseOver() {
              onPointIndexChanged(this.index);
            },
          },
        },
        states: {
          hover: {
            halo: {
              size: 0,
            },
          },
        },
      },
    },
    tooltip: {
      enabled: tooltipEnabled,
      shared: true,
      followPointer: false,
      useHTML: true,
      backgroundColor: 'transparent',
      padding: 0,
      shadow: false,
      borderWidth: 0,
      hideDelay: 0,
      style: {
        fontFamily: 'inherit',
      },
      formatter() {
        return tooltipHtml;
      },
      positioner(labelWidth, labelHeight, point) {
        const paddingX = 5;
        const pointX = this.chart.plotLeft + point.plotX;
        const position = {
          x:
            pointX - labelWidth < 0
              ? pointX + paddingX
              : pointX - labelWidth - paddingX,
          y: this.getPosition(labelWidth, labelHeight, point).y,
        };
        return position;
      },
    },
    yAxis: {
      visible: false,
      // fit the max and min tick to the available/full height
      endOnTick: false,
      startOnTick: false,
      min: min(flattenDeep(series.map((v) => v.data.map((v) => v.y)))),
      max: max(flattenDeep(series.map((v) => v.data.map((v) => v.y)))),
    },
    xAxis: {
      lineWidth: 1,
      lineColor: xAxisLineColor,
      minPadding: 0,
      tickmarkPlacement: 'on',
      labels: {
        enabled: false,
      },
      tickWidth: 0,
      crosshair: tooltipEnabled
        ? {
            color: shadowColor,
          }
        : undefined,
    },
    series: series.map(({ data, color, label }) => {
      const colorValue = getColorValueFromCssVar(color);
      return {
        type: 'area',
        name: label,
        lineColor: color,
        marker: {
          states: {
            hover: tooltipEnabled
              ? {
                  radius: 3,
                  fillColor: colorValue,
                  lineColor: '#fff',
                  lineWidth: 2,
                }
              : undefined,
          },
        },
        data,
        color: {
          // equals: linear-gradient(180deg, rgba($color, 0.2) 0%, rgba($color, 0) 100%)
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [
              0,
              new TinyColor(colorValue)
                .setAlpha(lineColorAlphaBase)
                .toRgbString(),
            ],
            [1, new TinyColor(colorValue).setAlpha(0).toRgbString()],
          ],
        },
      };
    }),
  };
  return options;
};

const getColorValueFromCssVar = (color: string) => {
  const tinyColor = new TinyColor(color);
  if (tinyColor.isValid) {
    return color;
  }
  const match = color.match(/var\(([^)]+)\)/);
  if (typeof window !== 'undefined' && match) {
    return window
      .getComputedStyle(window.document.documentElement)
      .getPropertyValue(match[1]);
  }
  return color;
};
