/**
 * Radio theme
 * @see https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src/components/radio.ts
 */
import { radioAnatomy as parts } from '@chakra-ui/anatomy';
export const Radio = {
    parts: parts.keys,
    baseStyle() {
        return {};
    },
    variants: {
        primary() {
            return {
                control: {
                    borderColor: 'bg-button',
                    rounded: 'full',
                    borderWidth: '1px',
                    p: '16%',
                    _checked: {
                        borderColor: 'bg-button',
                        backgroundColor: 'text-link',
                        backgroundClip: 'content-box',
                        _before: {
                            display: 'none',
                        },
                    },
                },
            };
        },
    },
    sizes: {
        md: {
            control: {
                w: '18px',
                h: '18px',
            },
        },
    },
    defaultProps: {
        variant: 'primary',
    },
};
