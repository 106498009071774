import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { useQuery } from '@tanstack/react-query';
import { useFountainPoolInfos } from '@ui/hooks/useFountainPoolInfos';
import usePendingVaultPenaltyRewards from '@ui/hooks/usePendingVaultPenaltyRewards';
import { useReservoirPoolInfos } from '@ui/hooks/useReservoirPoolInfos';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import type { Pool } from '@ui/queries/types';
import {
  getVenoFountainPendingRewards,
  getVenoFountainUserInfo,
  getVenoReservoirPendingRewards,
  getVenoReservoirUserInfo,
} from '@ui/queries/vno';
import { PRELAUNCH } from '@ui/utils/constants';
import type { Stake } from '@veno-app/sdk';
import { currentWallet } from '@veno-app/wallet';

const { useAccount, useIsZksyncChainId } = currentWallet;

export const useVenoReservoirData = (enabled?: boolean) => {
  const sdk = useVenoSDK();
  const account = useAccount();
  const isZksync = useIsZksyncChainId();

  const pendingRewardsQuery = getVenoReservoirPendingRewards(sdk, account);
  const {
    data: pendingRewardsInitial,
    isLoading: pendingRewardsIsLoading,
    refetch: refetchRewards,
  } = useQuery(pendingRewardsQuery.queryKey, pendingRewardsQuery.queryFn, {
    enabled: Boolean(account) && enabled !== false,
    initialData: pendingRewardsQuery.initialData,
  });

  const pendingRewards = isZksync
    ? { wcro: null, atom: null, weth: pendingRewardsInitial.weth }
    : {
        wcro: pendingRewardsInitial.wcro,
        atom: pendingRewardsInitial.atom,
        weth: null,
      };

  const userInfoQuery = getVenoReservoirUserInfo(sdk, account);
  const { data: userInfo, isLoading: userInfoIsLoading } = useQuery(
    userInfoQuery.queryKey,
    userInfoQuery.queryFn,
    {
      enabled: Boolean(account) && enabled !== false,
    },
  );

  const { pools, isLoading: poolsLoading } = useReservoirPoolInfos(
    !!enabled && !PRELAUNCH,
  );

  const data = useVenoInfoData({
    userInfo,
    pools,
  });

  return {
    isLoading: userInfoIsLoading || pendingRewardsIsLoading || poolsLoading,
    ...data,
    pendingRewardsMap: pendingRewards,
    pools,
    refetchRewards,
  };
};

export const useVenoFountainData = (enabled?: boolean) => {
  const sdk = useVenoSDK();
  const account = useAccount();

  const {
    pendingVaultPenalty,
    pendingVaultPenaltyFetching,
    pendingVaultPenaltyLoading,
  } = usePendingVaultPenaltyRewards();

  const pendingRewardsQuery = getVenoFountainPendingRewards(sdk, account);
  const {
    data: pendingRewards,
    isLoading: pendingRewardsIsLoading,
    refetch: refetchRewards,
  } = useQuery(pendingRewardsQuery.queryKey, pendingRewardsQuery.queryFn, {
    enabled: Boolean(account) && enabled !== false,
  });

  const userInfoQuery = getVenoFountainUserInfo(sdk, account);
  const { data: userInfo, isLoading: userInfoIsLoading } = useQuery(
    userInfoQuery.queryKey,
    userInfoQuery.queryFn,
    {
      enabled: Boolean(account) && enabled !== false,
    },
  );

  const { pools, isLoading: poolsLoading } = useFountainPoolInfos(enabled);

  const data = useVenoInfoData({
    userInfo,
    pools,
  });

  return {
    isLoading: userInfoIsLoading || pendingRewardsIsLoading || poolsLoading,
    ...data,
    pendingRewards,
    pools,
    pendingVaultPenalty,
    pendingVaultPenaltyFetching,
    pendingVaultPenaltyLoading,
    refetchRewards,
  };
};

export const useVenoInfoData = ({
  userInfo,
  pools,
}: {
  userInfo:
    | {
        stakes: Stake[];
      }
    | undefined;
  pools: Pool[] | undefined;
}) => {
  // filter out the inactive ones
  const stakes = userInfo?.stakes.filter(({ active }) => active) ?? [];
  const lockedStakes = stakes.filter(
    (v) => v.unlockTimestamp.toNumber() * 1000 >= Date.now(),
  );
  const unlockedStakes = stakes.filter(
    (v) => v.unlockTimestamp.toNumber() * 1000 < Date.now(),
  );
  const lockedVno = lockedStakes.reduce(
    (sum, v) => sum.add(v.amount),
    BigNumber.from('0'),
  );
  const unlockedVno = unlockedStakes.reduce(
    (sum, v) => sum.add(v.amount),
    BigNumber.from('0'),
  );

  const vnoAllocations: BigNumber[] = [];
  if (pools) {
    for (const pool of pools) {
      vnoAllocations[pool.pid] = BigNumber.from('0');
    }
  }
  for (const stake of stakes) {
    const pid = stake.poolId.toNumber();
    if (!pools || !vnoAllocations[pid]) {
      vnoAllocations[pid] = BigNumber.from('0');
    }
    vnoAllocations[pid] = vnoAllocations[pid].add(stake.amount);
  }

  let averageApr: number | undefined = undefined;
  if (userInfo !== undefined) {
    const weightedApr = stakes.reduce((sum, stake) => {
      const apr = parseUnits(
        String(pools?.[stake.poolId.toNumber()]?.apr || 0),
      );
      return sum.add(apr.mul(stake.amount));
    }, BigNumber.from('0'));
    averageApr = weightedApr.gt(BigNumber.from('0'))
      ? Number(formatUnits(weightedApr.div(lockedVno.add(unlockedVno))))
      : 0;
  }

  return {
    lockedStakes,
    unlockedStakes,
    lockedVno,
    unlockedVno,
    vnoAllocations,
    averageApr,
  };
};
