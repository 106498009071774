import { VenoStorm__factory } from '@contracts';
import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from 'ethers/lib/utils';
import { VenoSDKModule } from './base';
import { BLOCK_TIME, expandDecimals, SECONDS_PER_YEAR } from './constants';
import FerroLpTokenModule from './ferroLpToken';
import FerroLpTokenAtomModule from './ferroLpTokenAtom';
import TLcroModule from './tLcro';
import { getCroUsdPrice, getDecimalsForType, getPid, getTokenContract, getVnoUsdPrice, wrapSdkTransaction, } from './util';
import VvsCroLpTokenModule from './vvsCroLpToken';
import VvsLpTokenModule from './vvsLpToken';
import VvsTiaLpTokenModule from './vvsTiaLpToken';
export default class VenoStormModule extends VenoSDKModule {
    constructor() {
        super(...arguments);
        this.contractFactory = VenoStorm__factory;
        this.contractAddress = this.sdk.addresses.venoStorm;
        this.TLcro = new TLcroModule(this.sdk);
        this.FerroLpToken = new FerroLpTokenModule(this.sdk);
        this.FerroLpTokenAtom = new FerroLpTokenAtomModule(this.sdk);
        this.VvsLpToken = new VvsLpTokenModule(this.sdk);
        this.VvsCroLpToken = new VvsCroLpTokenModule(this.sdk);
        this.VvsTiaLpToken = new VvsTiaLpTokenModule(this.sdk);
    }
    async vnoPerSecond() {
        const vnoPerBlock = await this.getContract().vnoPerBlock();
        const factor = 1000;
        return vnoPerBlock.div(Math.floor(factor * BLOCK_TIME)).mul(factor);
    }
    totalAllocPoint() {
        return this.getContract().totalAllocPoint();
    }
    async fountainAllocPoint() {
        const pool = await this.getContract().poolInfo(0);
        return pool.allocPoint;
    }
    async vnoRewardPerSecond() {
        const [vnoPerSecond, totalAllocPoint, fountainAllocPoint] = await Promise.all([
            this.vnoPerSecond(),
            this.totalAllocPoint(),
            this.fountainAllocPoint(),
        ]);
        return vnoPerSecond.mul(fountainAllocPoint).div(totalAllocPoint);
    }
    async stakeNft(type, collections, tokenIds) {
        const pid = getPid(this.sdk.chainType, type);
        if (pid === undefined) {
            throw new Error(`${type}'s pid is undefined on ${this.sdk.chainType}`);
        }
        const collectionAddresses = collections.map((collection) => getTokenContract(collection, this.addresses));
        const nfts = tokenIds.map((tokenId, i) => ({
            tokenContract: collectionAddresses[i],
            tokenId,
        }));
        return await wrapSdkTransaction(this.getContract().stakeNft(pid, nfts));
    }
    async unstakeNft(type, collections, tokenIds) {
        const pid = getPid(this.sdk.chainType, type);
        if (pid === undefined) {
            throw new Error(`${type}'s pid is undefined on ${this.sdk.chainType}`);
        }
        const collectionAddresses = collections.map((collection) => getTokenContract(collection, this.addresses));
        const nfts = tokenIds.map((tokenId, i) => ({
            tokenContract: collectionAddresses[i],
            tokenId,
        }));
        return await wrapSdkTransaction(this.getContract().unstakeNft(pid, nfts));
    }
    async getStakedNftTokenIds(type, collection, user) {
        const pid = getPid(this.sdk.chainType, type);
        const tokenContract = getTokenContract(collection, this.addresses);
        if (!tokenContract || pid === undefined) {
            return [];
        }
        return (await this.getContract().getNftStakedByAddress(pid, user, tokenContract)).map((tokenId) => tokenId.toNumber());
    }
    async getBoostMultiplier(type, user) {
        const pid = getPid(this.sdk.chainType, type);
        if (pid === undefined) {
            return 0;
        }
        return ((await this.getContract().getBoostMultiplier(pid, user)).toNumber() /
            10000);
    }
    async deposit(type, amount, contractAddress) {
        const pid = getPid(this.sdk.chainType, type);
        if (pid === undefined) {
            throw new Error(`${type}'s pid is undefined on ${this.sdk.chainType}`);
        }
        return await wrapSdkTransaction(this.getContract().deposit(pid, amount, contractAddress));
    }
    async withdraw(type, amount, contractAddress) {
        const pid = getPid(this.sdk.chainType, type);
        if (pid === undefined) {
            throw new Error(`${type}'s pid is undefined on ${this.sdk.chainType}`);
        }
        return await wrapSdkTransaction(this.getContract().withdraw(pid, amount, contractAddress));
    }
    async getPendingVno(type, user) {
        const pid = getPid(this.sdk.chainType, type);
        if (pid === undefined) {
            return BigNumber.from(0);
        }
        return await this.getContract().pendingVno(pid, user);
    }
    async getLpAmountStaked(type, user) {
        const pid = getPid(this.sdk.chainType, type);
        if (pid === undefined) {
            return BigNumber.from(0);
        }
        const [amount] = await this.getContract().userInfo(pid, user);
        return amount;
    }
    async getGardenAprV2(type, user) {
        const pid = getPid(this.sdk.chainType, type);
        if (pid === undefined) {
            return 0;
        }
        const gardenType = type === 'FERRO_ATOM' ? 'FERRO' : type;
        const factorExponent = 11;
        const factor = Math.pow(10, factorExponent);
        const [{ allocPoint, vnoPerSecond, totalAllocPoint, vnoUsdPrice, totalBoostedAmount, }, boostMultiplier, oneLpUsdValue,] = await Promise.all([
            this.getDataForApr(type),
            user ? this.getContract().getBoostMultiplier(pid, user) : (() => 10000)(),
            this.getLpValueUsdV2(type, BigNumber.from(expandDecimals(1, getDecimalsForType(gardenType)))),
        ]);
        // something went wrong, be safe and return 0
        if (!oneLpUsdValue ||
            oneLpUsdValue.lte(0) ||
            totalBoostedAmount.lte(0) ||
            totalAllocPoint.lte(0)) {
            return 0;
        }
        const aprBn = allocPoint
            .mul(vnoPerSecond)
            .mul(SECONDS_PER_YEAR)
            .mul(Math.round(factor * (vnoUsdPrice ?? 0)))
            .mul(boostMultiplier)
            // make sure its scaled correctly for coins like t tokens
            .div(expandDecimals(1, 18 - getDecimalsForType(gardenType)))
            .div(totalBoostedAmount)
            .div(totalAllocPoint)
            .mul(expandDecimals(1, getDecimalsForType(gardenType)))
            .div(oneLpUsdValue);
        const apr = Number(formatUnits(aprBn, factorExponent));
        return apr;
    }
    async getLpStaked(type) {
        let lpStakedPromise = (async () => BigNumber.from(0))();
        if (type === 'TECTONIC') {
            // for Tectonic, save calls/calculations by getting value in LCRO terms
            lpStakedPromise = this.TLcro.balanceOfUnderlying(this.getContract().address);
        }
        else if (type === 'FERRO') {
            lpStakedPromise = this.FerroLpToken.balanceOf(this.getContract().address);
        }
        else if (type === 'FERRO_ATOM') {
            lpStakedPromise = this.FerroLpTokenAtom.balanceOf(this.getContract().address);
        }
        else if (type === 'VVS') {
            lpStakedPromise = this.VvsLpToken.balanceOf(this.getContract().address);
        }
        else if (type === 'VVS_CRO') {
            lpStakedPromise = this.VvsCroLpToken.balanceOf(this.getContract().address);
        }
        else if (type === 'VVS_TIA') {
            lpStakedPromise = this.VvsTiaLpToken.balanceOf(this.getContract().address);
        }
        return lpStakedPromise;
    }
    async getDataForApr(type) {
        const pid = getPid(this.sdk.chainType, type);
        const lpStakedPromise = this.getLpStaked(type);
        const poolInfoPromise = pid !== undefined
            ? this.getContract().poolInfo(pid)
            : {
                allocPoint: BigNumber.from(0),
                totalBoostedAmount: BigNumber.from(0),
            };
        const [{ allocPoint, totalBoostedAmount }, vnoPerSecond, totalAllocPoint, lpStaked, vnoUsdPrice,] = await Promise.all([
            poolInfoPromise,
            this.vnoPerSecond(),
            this.getContract().totalAllocPoint(),
            lpStakedPromise,
            getVnoUsdPrice(),
        ]);
        const lpValueUsd = await this.getLpValueUsdV2(type, lpStaked);
        return {
            allocPoint,
            vnoPerSecond,
            totalAllocPoint,
            vnoUsdPrice,
            lpValueUsd,
            totalBoostedAmount,
            lpStaked,
        };
    }
    async getLpValueUsdV2(type, lpStaked) {
        let lpValueUsdPromise = (async () => BigNumber.from(0))();
        if (type === 'FERRO') {
            lpValueUsdPromise = this.FerroLpToken.getLpUsdWorth(lpStaked);
        }
        else if (type === 'FERRO_ATOM') {
            lpValueUsdPromise = this.FerroLpTokenAtom.getLpUsdWorth(lpStaked);
        }
        else if (type === 'VVS') {
            lpValueUsdPromise = this.VvsLpToken.getLpUsdWorth(lpStaked);
        }
        else if (type === 'VVS_CRO') {
            lpValueUsdPromise = this.VvsCroLpToken.getLpUsdWorth(lpStaked);
        }
        else if (type === 'VVS_TIA') {
            lpValueUsdPromise = this.VvsTiaLpToken.getLpUsdWorth(lpStaked);
        }
        else if (type === 'TECTONIC') {
            const [exchangeRate, croUsdPrice] = await Promise.all([
                this.TLcro.getExchangeRate(),
                getCroUsdPrice(),
            ]);
            const tLcroPerUsd = BigNumber.from(10)
                .pow(28)
                .mul(100000000)
                .div(exchangeRate)
                .toNumber() /
                ((croUsdPrice ?? 1) * 100000000);
            const usdPerTlcro = 1 / tLcroPerUsd;
            const result = lpStaked
                .mul(Math.round(usdPerTlcro * 100000000))
                .div(100000000);
            return result;
        }
        const lpValueUsd = await lpValueUsdPromise;
        return lpValueUsd;
    }
    async rarityRegistries(collection) {
        const contractAddress = getTokenContract(collection, this.addresses);
        if (!contractAddress) {
            return '0';
        }
        return this.getContract().rarityRegistries(contractAddress);
    }
}
