import { getCroId } from './ens';
import { getFerroTotalLockedValue } from './ferroTotalLockedValue';
import {
  getEarnings,
  getEarningsHistory,
  getExchangeRateHistory,
  getFerroAtomLpPrice,
  getFerroLpPrice,
  getTransactionHistory,
} from './history';
import {
  getBoostMultipliersForNfts,
  getOwnedNfts,
  getStakedNfts,
} from './nfts';
import { getFerroApy, getVvsApy } from './partnerApys';
import { getTAssetInfo } from './partnerTvl';
import { getMintedTotalVolume } from './partnerVolume';
import {
  getAtomUsdPrice,
  getCroUsdPrice,
  getVnoUsdPrice,
  getWcroUsdPrice,
} from './prices';
import { QueryKey } from './queryKey';
import {
  getBoostMultiplerForPool,
  getBoostMultiplierFromLockedVno,
  getBoostTokens,
  getCoinPerStakedCoin,
  getFeeDistributorPeriodEndBlock,
  getFerroExchangeRate,
  getFerroLpBalance,
  getFountainPoolInfos,
  getGardenApr,
  getLpAmountStaked,
  getNftTokens,
  getPendingVaultPenaltyReward,
  getPendingVno,
  getReservoirPoolInfos,
  getStakeTransactionFee,
  getTectonicExchangeRate,
  getTLcroBalance,
  getTotalPooled,
  getTotalSupplyStcro,
  getUnbondUnlockDate,
  getVnoBalance,
  getVvsCroLpBalance,
  getVvsExchangeRate,
  getVvsLpBalance,
  getWcroBalance,
} from './sdk';
import { getAprQuery, getHistoricApysQuery } from './subgraph';
import {
  getVenoFountainPendingRewards,
  getVenoFountainUserInfo,
  getVenoReservoirPendingRewards,
  getVenoReservoirUserInfo,
} from './vno';
import { getVvsTvl } from './vvsTvl';

const queries = {
  [QueryKey.APR]: getAprQuery,
  [QueryKey.CRO_PRICE]: getCroUsdPrice(),
  [QueryKey.ATOM_PRICE]: getAtomUsdPrice(),
  [QueryKey.VNO_PRICE]: getVnoUsdPrice(),
  [QueryKey.WCRO_PRICE]: getWcroUsdPrice(),
  [QueryKey.CRO_ID]: getCroId,
  [QueryKey.FERRO_APY]: getFerroApy,
  [QueryKey.GET_NFT_TOKENS]: getNftTokens,
  [QueryKey.HISTORIC_APYS]: getHistoricApysQuery,
  [QueryKey.WCRO_BALANCE]: getWcroBalance,
  [QueryKey.MINTED_TOTAL_VOLUME]: getMintedTotalVolume(),
  [QueryKey.STAKE_TRANSACTION_FEE]: getStakeTransactionFee,
  [QueryKey.TASSET_INFO]: getTAssetInfo,
  [QueryKey.TOTAL_POOLED]: getTotalPooled,
  [QueryKey.TOTAL_SUPPLY_STCRO]: getTotalSupplyStcro,
  [QueryKey.VNO_BALANCE]: getVnoBalance,
  [QueryKey.UNBOND_UNLOCK_DATE]: getUnbondUnlockDate,
  [QueryKey.VVS_APY]: getVvsApy(),
  [QueryKey.GET_VENO_RESERVOIR_USER_INFO]: getVenoReservoirUserInfo,
  [QueryKey.GET_VENO_RESERVOIR_PENDING_REWARDS]: getVenoReservoirPendingRewards,
  [QueryKey.GET_VENO_FOUNTAIN_USER_INFO]: getVenoFountainUserInfo,
  [QueryKey.GET_VENO_FOUNTAIN_PENDING_REWARDS]: getVenoFountainPendingRewards,
  [QueryKey.GET_VENO_RESERVOIR_POOL_INFOS]: getReservoirPoolInfos,
  [QueryKey.GET_VENO_FOUNTAIN_POOL_INFOS]: getFountainPoolInfos,
  [QueryKey.GET_FERRO_TOTAL_LOCKED_VALUE]: getFerroTotalLockedValue,
  [QueryKey.GET_FEE_DISTRIBUTOR_PERIOD_END_BLOCK]:
    getFeeDistributorPeriodEndBlock,
  [QueryKey.EARNINGS]: getEarnings,
  [QueryKey.TRANSACTION_HISTORY]: getTransactionHistory,
  [QueryKey.EARNINGS_HISTORY]: getEarningsHistory,
  [QueryKey.EXCHANGE_RATE_HISTORY]: getExchangeRateHistory,
  [QueryKey.PENDING_VAULT_PENALTY]: getPendingVaultPenaltyReward,
  [QueryKey.VVS_TVL]: getVvsTvl,
  [QueryKey.FERRO_EXCHANGE_RATE]: getFerroExchangeRate,
  [QueryKey.FERRO_LP_BALANCE]: getFerroLpBalance,
  [QueryKey.VVS_LP_BALANCE]: getVvsLpBalance,
  [QueryKey.VVS_CRO_LP_BALANCE]: getVvsCroLpBalance,
  [QueryKey.TLCRO_BALANCE]: getTLcroBalance,
  [QueryKey.VVS_EXCHANGE_RATE]: getVvsExchangeRate,
  [QueryKey.BOOST_MULT_LOCKED_VNO]: getBoostMultiplierFromLockedVno,
  [QueryKey.LP_AMOUNT_STAKED]: getLpAmountStaked,
  [QueryKey.PENDING_VNO]: getPendingVno,
  [QueryKey.GARDEN_APR]: getGardenApr,
  [QueryKey.BOOST_TOKENS]: getBoostTokens,
  [QueryKey.FERRO_LP_PRICE]: getFerroLpPrice,
  [QueryKey.FERRO_LP_PRICE_ATOM]: getFerroAtomLpPrice,
  [QueryKey.OWNED_NFTS]: getOwnedNfts,
  [QueryKey.STAKED_NFTS]: getStakedNfts,
  [QueryKey.BOOST_MULTIPLIERS_NFTS]: getBoostMultipliersForNfts,
  [QueryKey.BOOST_MULTIPLIER_POOL]: getBoostMultiplerForPool,
  [QueryKey.TECTONIC_EXCHANGE_RATE]: getTectonicExchangeRate,
  [QueryKey.COIN_PER_STAKED_COIN]: getCoinPerStakedCoin,
};

export const getQuery = <T extends QueryKey>(key: T) => queries[key];
