import { IERC20__factory } from '@contracts';
import { BigNumber } from 'ethers';
import { VenoSDKModule } from './base';
import FerroSwapModule from './ferroSwap';
import LiquidCroModule from './liquidCro';
import { getCroUsdPrice, getLiquidTokenUsdPrice, wrapSdkTransaction, } from './util';
export default class FerroLpTokenModule extends VenoSDKModule {
    constructor() {
        super(...arguments);
        this.contractFactory = IERC20__factory;
        this.contractAddress = this.sdk.addresses.ferroLp;
        this.FerroSwap = new FerroSwapModule(this.sdk, this.sdk.addresses.ferroSwap);
        this.LiquidCro = new LiquidCroModule(this.sdk);
    }
    async balanceOf(address) {
        return await this.getContract().balanceOf(address);
    }
    async allowance(owner, operator) {
        return await this.getContract().allowance(owner, operator);
    }
    async approve(operator, amount) {
        return await wrapSdkTransaction(this.getContract().approve(operator, amount));
    }
    async getLpUsdWorth(amount) {
        if (amount.lte(0))
            return BigNumber.from(0);
        const [lcroAmount, croAmount] = await this.FerroSwap.calculateRemoveLiquidity(amount);
        const [croUsdPrice, croPerLcro] = await Promise.all([
            getCroUsdPrice(),
            this.LiquidCro.croPerLcro(),
        ]);
        if (!croUsdPrice || !croPerLcro)
            return BigNumber.from(0);
        const lcroUsdPrice = getLiquidTokenUsdPrice(croUsdPrice, croPerLcro);
        const factor = 100000000000;
        const result = lcroAmount
            .mul(Math.round(lcroUsdPrice * factor))
            .add(croAmount.mul(Math.round(croUsdPrice * factor)))
            .div(factor);
        return result;
    }
}
