export const shadows = {
    common: {
        normal: '-4px -4px 5px rgba(255,255,255,0.58), 3px 4px 5px rgba(0,0,0,0.16)',
        active: 'inset -4px -4px 5px rgba(255,255,255,0.58), inset 4px 4px 5px 3px 4px 5px rgba(0,0,0,0.16)',
    },
    smallCommon: {
        normal: '-2px -2px 3px rgba(255,255,255,0.48), 2px 2px 3px rgba(0,0,0,0.48)',
        active: 'inset -2px -2px 3px rgba(255,255,255,0.48), inset 2px 2px 3px rgba(0,0,0,0.48)',
    },
    card: {
        normal: '-4px -4px 5px #FFF, 3px 4px 5px #B4C1D5',
        active: 'inset -4px -4px 5px #FFF, inset 4px 4px 5px #B4C1D5',
    },
    smallCard: {
        normal: '-2px -2px 3px #FFFFFF, 2px 2px 3px #B4C1D5',
        active: 'inset -2px -2px 3px #FFFFFF, inset 1px 2px 3px #B4C1D5',
    },
    inverted: {
        normal: '-2px -2px 2px #68688D, 2px 2px 2px #000001',
        active: 'inset -2px -2px 2px #68688D, inset 2px 2px 2px #000000',
    },
    garden: {
        normal: '-2px -2px 2px #E9F9CC, 2px 2px 2px #788E4D',
        active: 'inset -2px -2px 2px #E9F9CC, inset 2px 2px 2px #788E4D',
    },
    bigGarden: {
        normal: '-3px -3px 5px #E9F9CC, 3px 3px 5px #788E4D',
        active: 'inset -3px -3px 5px #E9F9CC, inset 3px 3px 5px #788E4D',
    },
    atom: {
        normal: '-3px -3px 5px #7459B4, 3px 3px 5px #000000',
        active: 'inset -3px -3px 5px #7459B4, inset 3px 3px 5px #000000',
    },
    tia: {
        normal: '-3px -3px 5px #B67AFF, 3px 3px 5px #2D1252',
        active: 'inset -3px -3px 5px #B67AFF, inset 3px 3px 5px #2D1252',
    },
    smallTia: {
        normal: '-2px -2px 2px #B67AFF, 2px 2px 2px #2D1252',
        active: 'inset -2px -2px 2px #B67AFF, inset 2px 2px 2px #2D1252',
    },
    smallAtom: {
        normal: '-2px -2px 2px #7459B4, 2px 2px 2px #000000;',
        active: 'inset -2px -2px 2px #7459B4, inset 2px 2px 2px #000000;',
    },
    eth: {
        normal: '-3px -3px 5px 0px #D8F0FB, 3px 3px 5px 0px #8BA4AE',
        active: '4px 4px 5px 0px #8BA4AE inset, -3px -3px 5px 0px #D8F0FB inset',
    },
    smallEth: {
        normal: '2px 2px 2px 0px #8BA4AE, -2px -2px 2px 0px #D8F0FB',
        active: '2px 2px 2px 0px #8BA4AE inset, -2px -2px 2px 0px #D8F0FB inset',
    },
    gauzeCard: {
        normal: '-3px -3px 5px #C5779E, 3px 3px 5px #371C2A',
        active: 'inset -3px -3px 5px #E9F9CC, inset 4px 4px 5px #788E4D',
    },
    chartTooltipCard: '-2px -2px 3px #FFFFFF, 2px 2px 3px #B4C1D5',
};
