import type { ModalProps } from '@chakra-ui/react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { Card } from '@ui/components/Card';
import { languages, useLanguage, useTranslations } from '@ui/i18n';
import type { FC } from 'react';

import { useLangModal } from './useLangModal';
import { useSwitchLang } from './utils';

export type LangModalProps = Omit<
  ModalProps,
  'onClose' | 'isOpen' | 'children'
>;

export const LangModal: FC<LangModalProps> = ({ ...props }) => {
  const t = useTranslations();
  const isOpen = useLangModal((s) => s.isOpen);
  const onClose = useLangModal((s) => s.onClose);
  const currentLanguage = useLanguage();
  const switchLang = useSwitchLang();

  return (
    <Modal
      size="full"
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={onClose}
      {...props}
    >
      <ModalOverlay />
      <ModalContent
        bgImage="url(/images/pattern-3.png)"
        bgRepeat="no-repeat"
        bgSize="100% 500px"
      >
        <ModalCloseButton />
        <ModalHeader>
          <Text textStyle="h3">{t('Language')}</Text>
        </ModalHeader>
        <ModalBody>
          {languages.map((langItem, i) => {
            const isActive = langItem.lang === currentLanguage.lang;
            return (
              <Card
                key={i}
                isActive={isActive}
                p="10px"
                textStyle={isActive ? 'h4Bold' : 'h4'}
                textAlign="center"
                mt="16px"
                onClick={() => {
                  switchLang(langItem.lang);
                  onClose();
                }}
              >
                {langItem.name}
              </Card>
            );
          })}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
