export const PRELAUNCH = process.env.NEXT_PUBLIC_PRELAUNCH === 'true';

export enum ApyMode {
  'day',
  'week',
}
/**
 * show APR based on last 24h data (first week of Veno launch) or 7 days (more accurate)
 */
export const ACTIVE_APY_MODE_STRATEGY: ApyMode = ApyMode.week;
export const ACTIVE_COMPOUND_FREQUENCY_PER_DAY_STRATEGY = 12;

/**
 * PID of the pool with the longest locking time
 */
export const MAX_POOL_ID = parseInt(process.env.NEXT_PUBLIC_MAX_POOL_ID ?? '3');

export const MAXW_DESKTOP_CTA = '343px';

export const MAX_W_FAQ_CARD = '500px';

export const MAXW_IFRAME = '343px';

export const MAXW_DESKTOP_CONTENT = '1200px';

export const POST_MVP_DASHBOARD =
  process.env.NEXT_PUBLIC_POST_MVP_DASHBOARD === 'true';
