import { Box, Image, Text } from '@chakra-ui/react';
import type { CardProps } from '@ui/components/Card';
import { Card } from '@ui/components/Card';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { useTranslations } from '@ui/i18n';
import { format } from 'date-fns';
import { forwardRef } from 'react';

import { useNftDetailDrawerStore } from './NftDetailDrawer';
import type { NftToken } from './utils';

export type NFTCardProps = {
  coinMode: CoinMode;
  nft: NftToken;
  readonly?: boolean;
  width?: string;
  height?: string;
  showUnlockTime?: boolean;
} & Omit<CardProps, 'w' | 'width' | 'height' | 'h'>;
export const NFTCard = forwardRef<HTMLDivElement, NFTCardProps>(
  (
    {
      nft,
      readonly,
      width = '160px',
      height = '270px',
      showUnlockTime,
      coinMode,
      ...props
    }: NFTCardProps,
    ref,
  ) => {
    const t = useTranslations();
    const openNftDetailDrawer = useNftDetailDrawerStore((s) => s.onOpen);

    return (
      <Card
        ref={ref}
        variant={readonly ? 'default' : 'button'}
        flexShrink="0"
        p="10px 10px 0px"
        mx="0"
        cursor={readonly ? 'auto' : 'pointer'}
        onClick={
          readonly
            ? undefined
            : () => {
                openNftDetailDrawer({
                  coinMode,
                  nft,
                });
              }
        }
        {...props}
      >
        <Image
          src={nft.image}
          alt="nft avatar"
          width={width}
          height={height}
          objectFit="cover"
          borderRadius={props.borderRadius}
          rounded={props.rounded}
        />
        {showUnlockTime ? (
          <Text textStyle="bodyBold" textAlign="center" my="10px">
            {nft.chainType === 'zksync'
              ? t('Est unlock date')
              : t('Unlock end date')}
            <br />
            {format(
              nft.unlockEndTime.toNumber() * 1000,
              nft.chainType === 'zksync' ? '~ dd/MM/yyyy' : 'dd/MM/yyyy HH:mm',
            )}
            <br />
            {t('Local Time')}
          </Text>
        ) : (
          <Box h="16px" />
        )}
      </Card>
    );
  },
);

NFTCard.displayName = 'NFTCard';
