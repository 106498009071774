import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { pathDict } from '@ui/config/paths';
import type { ReactNode } from 'react';

/**
 * Defines the structure for a footer section.
 * Each section contains a title and an array of entry objects.
 *
 * @property title - The title of the footer section.
 * @property entries - An array of entry objects, each containing:
 *   @property title - The title of the entry. It can be a simple string or a tuple that will be passed to the translation function.
 *   @property link - The URL associated with the entry.
 *   @property [isExternal=false] - Optional flag to indicate if the link should open in a new window (external link).
 */
export type Section = {
  title:
    | string
    | [
        string,
        Record<string, string | number | ((value: string[]) => ReactNode)>,
      ];
  entries: {
    title:
      | string
      | [
          string,
          Record<string, string | number | ((value: string[]) => ReactNode)>,
        ];
    link: string | FooterAction;
    isExternal?: boolean;
  }[];
};

export enum FooterAction {
  OpenUseVnoModal = 0,
}

export const sections: Section[] = [
  {
    title: 'Features',
    entries: [
      {
        title: [
          '{coin} staking',
          {
            coin: 'CRO',
          },
        ],
        link: pathDict.cro.stake,
        isExternal: false,
      },
      {
        title: [
          '{coin} staking',
          {
            coin: 'ATOM',
          },
        ],
        link: pathDict.atom.stake,
        isExternal: false,
      },
      {
        title: [
          '{coin} staking',
          {
            coin: 'ETH',
          },
        ],
        link: pathDict.eth.stake,
        isExternal: false,
      },
      {
        title: 'CRO liquid strategy',
        link: pathDict.cro.strategy.deposit,
        isExternal: false,
      },
      {
        title: [
          'Earn {coin}',
          {
            coin: 'VNO',
          },
        ],
        link: pathDict.cro.vno,
        isExternal: false,
      },
    ],
  },
  {
    title: ['Partners & Ecosystem', {}],
    entries: [
      { title: 'Ferro Protocol', link: EXTERNAL_LINK.ferro, isExternal: true },
      {
        title: 'VVS Finance',
        link: EXTERNAL_LINK.vvsLanding,
        isExternal: true,
      },
      {
        title: 'Tectonic Finance',
        link: EXTERNAL_LINK.tectonic,
        isExternal: true,
      },
      { title: 'Maverick', link: EXTERNAL_LINK.maverick, isExternal: true },
      {
        title: 'Single Finance',
        link: EXTERNAL_LINK.singleFinance,
        isExternal: true,
      },
      {
        title: 'Sync Swap',
        link: EXTERNAL_LINK.syncSwapPool,
        isExternal: true,
      },
      { title: 'PWN', link: EXTERNAL_LINK.pwnCreateLoan, isExternal: true },
    ],
  },
  {
    title: ['Documentation', {}],
    entries: [
      { title: 'Gitbook', link: EXTERNAL_LINK.gitbook, isExternal: true },
      {
        title: 'Fountain',
        link: 'https://docs.veno.finance/veno-finance/protocol/fountain',
        isExternal: true,
      },
      {
        title: 'Reservoir',
        link: 'https://docs.veno.finance/veno-finance/protocol/reservoir',
        isExternal: true,
      },
      {
        title: ['Gardens', {}],
        link: 'https://docs.veno.finance/veno-finance/protocol/veno-garden',
        isExternal: true,
      },
      {
        title: ['Helpers (NFT staking)', {}],
        link: 'https://docs.veno.finance/veno-finance/protocol/veno-garden#helpers',
        isExternal: true,
      },
      {
        title: ['Security', {}],
        link: 'https://docs.veno.finance/veno-finance/protocol/security-and-audits',
        isExternal: true,
      },
      {
        title: ['Smart Contract', {}],
        link: 'https://docs.veno.finance/veno-finance/additional/contract-addresses',
        isExternal: true,
      },
    ],
  },
];
