import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { getBoostMultiplierFromLockedVno } from '@ui/queries/sdk';
import { currentWallet } from '@veno-app/wallet';

const { useAccount } = currentWallet;

const useBoostMultLockedVno = (
  options?: Partial<Pick<UseQueryOptions, 'enabled'>>,
  address?: string,
) => {
  const sdk = useVenoSDK();
  const userAccount = useAccount();
  const account = address ?? userAccount;
  const query = getBoostMultiplierFromLockedVno(sdk, account ?? '');
  const { data: boostMultiplier, isLoading } = useQuery(
    query.queryKey,
    query.queryFn,
    {
      ...options,
      enabled:
        !!account && (options?.enabled === undefined || options?.enabled),
    },
  );

  return {
    isLoading,
    boostMultiplier: boostMultiplier
      ? boostMultiplier.toNumber() / 10000 + 1
      : undefined,
  };
};

export default useBoostMultLockedVno;
