import {
  FERRO_POOL_ATOM_LATOM,
  FERRO_POOL_CRO_LCRO,
} from '@ui/config/partners';
import type { CoinMode } from '@ui/hooks/useCoinMode';

import { QueryKey } from './queryKey';

interface FerroTotalLockedValueResponse {
  data: {
    tvl: number;
  };
}

export const getFerroTotalLockedValue = ({
  coinMode,
}: {
  coinMode: CoinMode;
}) => ({
  queryKey: [QueryKey.GET_FERRO_TOTAL_LOCKED_VALUE, coinMode],
  queryFn: async () => {
    const aprKey =
      coinMode === 'atom' ? FERRO_POOL_ATOM_LATOM : FERRO_POOL_CRO_LCRO;
    const fetchUrl = `https://api.ferroprotocol.com/info/api/getTVL/${aprKey}`;
    if (!fetchUrl) throw new Error('ferro URL env variable must be set');
    const fetchResponse = await fetch(fetchUrl);
    const response =
      (await fetchResponse.json()) as FerroTotalLockedValueResponse;

    try {
      return response.data.tvl;
    } catch (err) {
      throw new Error(`invalid response from ${fetchUrl}`);
    }
  },
});
