import {
  getPathInfo,
  isCategoryInSameView,
  useRealPathname,
} from '@ui/hooks/router';
import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import type { Step } from 'react-joyride';
import { create } from 'zustand';

import { VenoTour } from './utils/VenoTour';

export const LandingTour = () => {
  const pathInfo = getPathInfo(useRealPathname());
  const stepIndex = useLandingTour((s) => s.stepIndex);

  const steps: Step[] = [
    {
      target: '.veno-tour__stake-nav-link',
      content: 'go to stake page',
    },
  ];

  /**
   * only working on the stake cro page
   */
  if (!isCategoryInSameView(pathInfo.category, 'landing')) {
    return null;
  }

  return (
    <VenoTour
      tourId="LadingTour"
      withWalletTour
      steps={steps}
      stepIndex={stepIndex}
      onStepIndexChange={(stepIndex, isFinished) => {
        if (isFinished) {
          useLandingTour.getState().$$resetData?.();
        } else {
          useLandingTour.setState({
            stepIndex: stepIndex,
          });
        }
      }}
    />
  );
};

type UseLandingTourStore = {
  stepIndex: number;
  next: () => void;
};

export const useLandingTour = create(
  resetWhenRouterChange<UseLandingTourStore>((set) => ({
    stepIndex: 0,
    next: () => {
      set((s) => {
        return {
          stepIndex: s.stepIndex + 1,
        };
      });
    },
  })),
);
