import { Button, Flex, Image, Text } from '@chakra-ui/react';
import { BigNumber } from '@ethersproject/bignumber';
import Balance from '@ui/components/Balance';
import { Card } from '@ui/components/Card';
import Info from '@ui/components/Info';
import { InDesktop } from '@ui/components/MobileOrDesktop';
import {
  ModalOrDragDrawerContent,
  ModalOrDrawer,
  ModalOrDrawerBody,
  ModalOrDrawerCloseButton,
  ModalOrDrawerFooter,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from '@ui/components/ModalOrDrawer';
import type { TransactionStatus } from '@ui/components/TransactionDrawer';
import {
  TransactionError,
  TransactionPending,
  VaultWithdrawCompleted,
} from '@ui/components/TransactionDrawer';
import {
  useVenoFountainEarlyWithdrawal,
  useVenoFountainWithdrawal,
  useVenoReservoirWithdrawal,
} from '@ui/hooks/useVenoWithdrawal';
import { useVnoUsdValue } from '@ui/hooks/useVnoUsdValue';
import { useTranslations } from '@ui/i18n';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { formatTokenAmount } from '@ui/utils/format';
import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import type { EthersError } from '@veno-app/sdk';
import { currentWallet } from '@veno-app/wallet';
import { useCallback, useState } from 'react';
import { create } from 'zustand';

import { useVaultSelectionStore } from './ReservoirAndFountain';

export const VaultWithdrawDrawer: React.FC = () => {
  const t = useTranslations();
  const {
    handleCloseAndReset,
    handleWithdraw,
    isOpen,
    allocation,
    error,
    transactionStatus,
    isWithdrawEarly,
    harvestReward,
    allocationUsd,
    type,
    earlyWithdrawAmount,
    earlyWithdrawAmountUsd,
    earlyWithdrawFee,
    earlyWithdrawFeeUsd,
  } = useVaultWithdrawDrawer();

  return (
    <ModalOrDrawer
      placement="bottom"
      isOpen={isOpen}
      onClose={handleCloseAndReset}
    >
      <ModalOrDrawerOverlay zIndex="modal" />
      <ModalOrDragDrawerContent h={{ base: '60vh', desktop: undefined }}>
        <InDesktop>
          <ModalOrDrawerCloseButton />
        </InDesktop>
        <ModalOrDrawerHeader>
          {isWithdrawEarly ? t('Withdraw early with penalty') : t('Withdraw')}
        </ModalOrDrawerHeader>
        <ModalOrDrawerBody>
          {(() => {
            switch (transactionStatus) {
              case 'COMPLETED':
                return (
                  <VaultWithdrawCompleted.Content
                    type={type}
                    reward={harvestReward ?? BigNumber.from(0)}
                    allocation={allocation ?? BigNumber.from(0)}
                    isWithdrawEarly={isWithdrawEarly}
                    earlyWithdrawAmount={earlyWithdrawAmount}
                  />
                );
              case 'PENDING':
                return <TransactionPending.Content />;
              case 'ERROR':
                return <TransactionError.Content error={error} />;
              default:
                return (
                  <Flex direction="column" gap={7}>
                    <Text
                      align="center"
                      textStyle="bodySmall"
                      fontSize={{ desktop: '16px' }}
                      color="text.light"
                      pt={2}
                    >
                      {isWithdrawEarly ? (
                        <>
                          {t(
                            'Note: You will no longer earn Fountain rewards when you remove your locked VNO',
                          )}
                        </>
                      ) : (
                        <>
                          {t(
                            'Note: You will stop earning the {platform} rewards by removing your unlocked VNO',
                            {
                              platform:
                                type === 'RESERVOIR' ? 'Reservoir' : 'Fountain',
                            },
                          )}
                        </>
                      )}
                    </Text>
                    {!isWithdrawEarly && (
                      <Card px={4} py={8}>
                        <Balance
                          title={t('Amount to withdraw')}
                          icon={<Image alt="VNO" src="/tokens/vno.svg" />}
                          amount={allocation ?? BigNumber.from(0)}
                          usdAmount={allocationUsd}
                          showUsdAmount
                        />
                      </Card>
                    )}
                    {isWithdrawEarly && (
                      <>
                        <Info
                          icon={(props) => (
                            <Image alt="vno" src="/tokens/vno.svg" {...props} />
                          )}
                          title={t('Amount of penalty')}
                          value={`${formatTokenAmount(earlyWithdrawFee, 2)}`}
                          convertedValue={
                            earlyWithdrawFeeUsd !== undefined
                              ? `${formatTokenAmount(
                                  earlyWithdrawFeeUsd,
                                  2,
                                )} USD`
                              : undefined
                          }
                          convertedValueLoading={
                            earlyWithdrawFeeUsd === undefined
                          }
                        />
                        <Info
                          icon={(props) => (
                            <Image alt="vno" src="/tokens/vno.svg" {...props} />
                          )}
                          title={t('Amount to withdraw')}
                          value={`${formatTokenAmount(earlyWithdrawAmount, 2)}`}
                          convertedValue={
                            earlyWithdrawAmountUsd !== undefined
                              ? `${formatTokenAmount(
                                  earlyWithdrawAmountUsd,
                                  2,
                                )} USD`
                              : undefined
                          }
                          convertedValueLoading={
                            earlyWithdrawAmountUsd === undefined
                          }
                        />
                      </>
                    )}
                  </Flex>
                );
            }
          })()}
        </ModalOrDrawerBody>
        <ModalOrDrawerFooter>
          {(() => {
            switch (transactionStatus) {
              case 'COMPLETED':
                return (
                  <VaultWithdrawCompleted.Footer
                    onCloseDrawer={handleCloseAndReset}
                  />
                );
              case 'PENDING':
                return <TransactionPending.Footer />;
              case 'ERROR':
                return null;
              default:
                return <Button onClick={handleWithdraw}>{t('Confirm')}</Button>;
            }
          })()}
        </ModalOrDrawerFooter>
      </ModalOrDragDrawerContent>
    </ModalOrDrawer>
  );
};

const { useAccount } = currentWallet;

const useVaultWithdrawDrawer = () => {
  const { isOpen, allocation, onClose, stakeId, isWithdrawEarly } =
    useVaultWithdrawDrawerStore();
  const sdk = useVenoSDK();
  const account = useAccount();
  const [transactionStatus, setTransactionStatus] =
    useState<TransactionStatus>('CONFIRM');
  const resetVault = useVaultSelectionStore((s) => s.resetSelectedVault);
  const vault = useVaultSelectionStore((s) => s.selectedVault);
  const [harvestReward, setHarvestReward] = useState<BigNumber | undefined>(
    undefined,
  );

  let earlyWithdrawPenalty = 0.9;
  switch (vault?.multiplier) {
    case 300:
      earlyWithdrawPenalty = 0.6;
      break;
    case 1200:
      earlyWithdrawPenalty = 0.7;
      break;
    case 4800:
      earlyWithdrawPenalty = 0.8;
      break;
  }

  const earlyWithdrawFee = BigNumber.from(earlyWithdrawPenalty * 10)
    .mul(allocation ?? 0)
    .div(10);
  const earlyWithdrawAmount = (allocation ?? BigNumber.from(0)).sub(
    earlyWithdrawFee,
  );

  const { data: earlyWithdrawAmountUsd } = useVnoUsdValue(earlyWithdrawAmount);
  const { data: earlyWithdrawFeeUsd } = useVnoUsdValue(earlyWithdrawFee);

  const [error, setError] = useState<EthersError | null>(null);

  const { mutateAsync: reservoirWithdrawal } = useVenoReservoirWithdrawal();
  const { mutateAsync: fountainWithdrawal } = useVenoFountainWithdrawal();
  const { mutateAsync: fountainWithdrawalEarly } =
    useVenoFountainEarlyWithdrawal();

  const handleWithdraw = useCallback(async () => {
    if (!stakeId) return;
    setTransactionStatus('PENDING');
    try {
      // first, find out how much harvest we get
      // after withdraw, it will be 0
      const harvestAmount =
        vault?.type === 'FOUNTAIN'
          ? await sdk.VenoFountain.pendingVno(account ?? '')
          : await (async () => {
              const rewardTokens = await sdk.VenoReservoir.getRewardTokens();
              const pendingTokens = await sdk.VenoReservoir.pendingTokens(
                account ?? '',
                rewardTokens,
              );

              return pendingTokens?.wcro;
            })();
      setHarvestReward(harvestAmount);
      vault?.type === 'FOUNTAIN'
        ? isWithdrawEarly
          ? await fountainWithdrawalEarly({ ids: [stakeId] })
          : await fountainWithdrawal({ ids: [stakeId] })
        : await reservoirWithdrawal({ ids: [stakeId] });
    } catch (err) {
      setError(err as EthersError);
      setTransactionStatus('ERROR');
      return;
    }
    setTransactionStatus('COMPLETED');
  }, [
    account,
    fountainWithdrawal,
    fountainWithdrawalEarly,
    isWithdrawEarly,
    reservoirWithdrawal,
    sdk.VenoFountain,
    sdk.VenoReservoir,
    stakeId,
    vault?.type,
  ]);

  const handleCloseAndReset = (): void => {
    onClose();
    setError(null);
    // reset selected vault on success, because data will be outdated
    if (transactionStatus === 'COMPLETED') resetVault();
    setTransactionStatus('CONFIRM');
  };

  const { data: allocationUsd } = useVnoUsdValue(allocation ?? undefined);

  return {
    handleCloseAndReset,
    handleWithdraw,
    isOpen,
    allocation,
    allocationUsd,
    stakeId,
    error,
    transactionStatus,
    harvestReward,
    type: vault?.type ?? 'RESERVOIR',
    isWithdrawEarly,
    earlyWithdrawPenalty,
    earlyWithdrawAmount,
    earlyWithdrawAmountUsd,
    earlyWithdrawFee,
    earlyWithdrawFeeUsd,
  };
};

export type VaultWithdrawDrawerStore = {
  isOpen: boolean;
  onOpen: (
    allocation: BigNumber,
    stakeId: BigNumber,
    isWithdrawEarly?: boolean,
  ) => void;
  onClose: () => void;
  allocation: BigNumber | null;
  stakeId: BigNumber | null;
  isWithdrawEarly: boolean;
};
export const useVaultWithdrawDrawerStore = create<VaultWithdrawDrawerStore>(
  resetWhenRouterChange((set) => ({
    isOpen: false,
    allocation: null,
    stakeId: null,
    isWithdrawEarly: false,
    onOpen: (
      allocation: BigNumber,
      stakeId: BigNumber,
      isWithdrawEarly?: boolean,
    ): void =>
      set(() => ({
        isOpen: true,
        allocation,
        stakeId,
        isWithdrawEarly: isWithdrawEarly ?? false,
      })),
    onClose: () => set(() => ({ isOpen: false, allocation: null })),
  })),
);
