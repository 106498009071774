import type { ChakraProps } from '@chakra-ui/react';

export type BgImage =
  | 'PATTERN_1'
  | 'PATTERN_1_ATOM'
  | 'PATTERN_2'
  | 'PATTERN_2_ATOM'
  | 'PATTERN_2_1'
  | 'PATTERN_3'
  | 'PATTERN_4'
  | 'PATTERN_4_LARGE'
  | 'PATTERN_STAKE_ETH'
  | 'PATTERN_UNSTAKE_ETH'
  | 'PATTERN_STAKE_TIA'
  | 'PATTERN_UNSTAKE_TIA';

const getBgImage = (image: BgImage): Partial<ChakraProps> => {
  const commonProps = {
    backgroundRepeat: 'no-repeat',
  };
  switch (image) {
    case 'PATTERN_1':
      return {
        bgImage: { base: '/images/pattern-1.png', desktop: 'none' },
        backgroundSize: '100vw auto',
        ...commonProps,
      };
    case 'PATTERN_1_ATOM':
      return {
        bgImage: { base: '/images/atom-pattern-1.png', desktop: 'none' },
        backgroundSize: '100vw auto',
        backgroundPosition: '0px -10px',
        ...commonProps,
      };
    case 'PATTERN_2':
      return {
        bgImage: { base: '/images/pattern-2.png', desktop: 'none' },
        backgroundSize: '356px 432px',
        ...commonProps,
      };
    case 'PATTERN_2_ATOM':
      return {
        bgImage: { base: '/images/atom-pattern-2.png', desktop: 'none' },
        backgroundSize: '100vw auto',
        backgroundPosition: '0px -50px',
        ...commonProps,
      };
    case 'PATTERN_2_1':
      return {
        bgImage: { base: '/images/pattern-2-1.png', desktop: 'none' },
        backgroundSize: '318px 572px',
        ...commonProps,
      };
    case 'PATTERN_3':
      return {
        bgImage: { base: '/images/pattern-3.png', desktop: 'none' },
        backgroundSize: '100vw auto',
        ...commonProps,
      };
    case 'PATTERN_4':
      return {
        bgImage: { base: '/images/pattern-4.png', desktop: 'none' },
        backgroundSize: '430px auto',
        ...commonProps,
      };
    case 'PATTERN_STAKE_ETH':
      return {
        bgImage: { base: '/images/stake-eth-bg.png', desktop: 'none' },
        backgroundSize: '100vw auto',
        backgroundPosition: '0px -130px',
        ...commonProps,
      };
    case 'PATTERN_UNSTAKE_ETH':
      return {
        bgImage: { base: '/images/unstake-eth-bg.png', desktop: 'none' },
        backgroundSize: '120vw auto',
        backgroundPosition: '-10vw -100px',
        ...commonProps,
      };
    case 'PATTERN_STAKE_TIA':
      return {
        bgImage: { base: '/images/stake-tia-bg.svg', desktop: 'none' },
        backgroundSize: '100vw auto',
        backgroundPosition: '0px -130px',
        ...commonProps,
      };
    case 'PATTERN_UNSTAKE_TIA':
      return {
        bgImage: { base: '/images/unstake-tia-bg.svg', desktop: 'none' },
        backgroundSize: '120vw auto',
        backgroundPosition: '-10vw -100px',
        ...commonProps,
      };
    case 'PATTERN_4_LARGE':
    default:
      return {
        bgImage: { base: '/images/pattern-4-large.png', desktop: 'none' },
        backgroundSize: '316px 593px',
        ...commonProps,
      };
  }
};

export default getBgImage;
