import type { CoinMode } from '@ui/hooks/useCoinMode';
import { GraphQLClient } from 'graphql-request';
import type { ReactNode } from 'react';
import { createContext, memo, useContext } from 'react';

const subgraphClient = new GraphQLClient(
  process.env.NEXT_PUBLIC_SUBGRAPH_URL || '',
  {
    headers: {},
  },
);

const subgraphClientAtom = new GraphQLClient(
  process.env.NEXT_PUBLIC_SUBGRAPH_URL_ATOM || '',
  {
    headers: {},
  },
);

const subgraphClientEth = new GraphQLClient(
  process.env.NEXT_PUBLIC_SUBGRAPH_URL_ETH || '',
  {
    headers: {},
  },
);

const subgraphClientTia = new GraphQLClient(
  process.env.NEXT_PUBLIC_SUBGRAPH_URL_TIA || '',
  {
    headers: {},
  },
);

const historyClient = new GraphQLClient(
  process.env.NEXT_PUBLIC_HISTORY_URL || '',
  {
    headers: {},
  },
);

const historyClientAtom = new GraphQLClient(
  process.env.NEXT_PUBLIC_HISTORY_URL_ATOM || '',
  {
    headers: {},
  },
);

const venoEarningsClient = new GraphQLClient(
  process.env.NEXT_PUBLIC_VNO_EARNINGS_URL || '',
  {
    headers: {},
  },
);

const venoEarningsClientZksync = new GraphQLClient(
  process.env.NEXT_PUBLIC_VNO_EARNINGS_URL_Zksync || '',
  {
    headers: {},
  },
);

const strategyClient = new GraphQLClient(
  process.env.NEXT_PUBLIC_STRATEGY_SUBGRAPH || '',
  {
    headers: {},
  },
);

interface GraphQLProviderProps {
  children: ReactNode;
}

type GraphQLContextValue = {
  subgraphClient: GraphQLClient;
  subgraphClientAtom: GraphQLClient;
  subgraphClientEth: GraphQLClient;
  subgraphClientTia: GraphQLClient;
  historyClient: GraphQLClient;
  historyClientAtom: GraphQLClient;
  venoEarningsClient: GraphQLClient;
  venoEarningsClientZksync: GraphQLClient;
  strategyClient: GraphQLClient;
};

export const GraphQLContext = createContext<GraphQLContextValue>({
  subgraphClient: new GraphQLClient(''),
  subgraphClientAtom: new GraphQLClient(''),
  subgraphClientEth: new GraphQLClient(''),
  subgraphClientTia: new GraphQLClient(''),
  historyClient: new GraphQLClient(''),
  historyClientAtom: new GraphQLClient(''),
  venoEarningsClient: new GraphQLClient(''),
  venoEarningsClientZksync: new GraphQLClient(''),
  strategyClient: new GraphQLClient(''),
});

export const useGraphQLContext = () => {
  return useContext(GraphQLContext);
};

export const useHistoryClient = (coinMode: CoinMode) => {
  const atomClient = useGraphQLContext().historyClientAtom;
  const croClient = useGraphQLContext().historyClient;

  // TODO: eth
  if (coinMode === 'cro') return croClient;
  return atomClient;
};

export const useSubgraphClient = (coinMode: CoinMode) => {
  const atomClient = useGraphQLContext().subgraphClientAtom;
  const croClient = useGraphQLContext().subgraphClient;
  const ethClient = useGraphQLContext().subgraphClientEth;
  const tiaClient = useGraphQLContext().subgraphClientTia;

  if (coinMode === 'cro') return croClient;
  if (coinMode === 'eth') return ethClient;
  if (coinMode === 'tia') return tiaClient;
  if (coinMode === 'atom') return atomClient;

  throw new Error('useSubgraphClient coinMode not supported');
};

function GraphQLProvider({ children }: GraphQLProviderProps): JSX.Element {
  return (
    <GraphQLContext.Provider
      value={{
        subgraphClient,
        historyClient,
        venoEarningsClient,
        venoEarningsClientZksync,
        subgraphClientAtom,
        subgraphClientEth,
        historyClientAtom,
        strategyClient,
        subgraphClientTia,
      }}
    >
      {children}
    </GraphQLContext.Provider>
  );
}

export default memo(GraphQLProvider);
