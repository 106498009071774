import type { DrawerContentProps, FlexProps } from '@chakra-ui/react';
import {
  Box,
  DrawerContent,
  Flex,
  useDrawerContext,
  useModalContext,
} from '@chakra-ui/react';
import { uniqueId } from 'lodash-es';
import type { TouchEvent } from 'react';
import React, { useRef, useState } from 'react';

const MAX_DRAG_DISTANCE = 100;
const CLASS_NAME = 'drag-drawer-content';

export type DragDrawerContentProps = DrawerContentProps & {
  dragBarProps?: FlexProps;
};
export const DragDrawerContent: React.FC<DragDrawerContentProps> = ({
  children,
  dragBarProps,
  ...props
}) => {
  const className = useRef(uniqueId(`${CLASS_NAME}-`)).current;
  const { onClose } = useModalContext();
  const { placement } = useDrawerContext();
  const [isTouching, setIsTouching] = useState(false);
  const startYRef = useRef(0);
  const offsetYRef = useRef(0);

  const handleClose = onClose;
  const handleTouch = (e: TouchEvent<HTMLDivElement>): void => {
    const elem = document.querySelector<HTMLElement>(`.` + className);

    if (startYRef.current && elem) {
      const nextOffsetY = Math.max(e.touches[0].pageY - startYRef.current, 0);
      elem.style.bottom = `-${nextOffsetY}px`;
      offsetYRef.current = nextOffsetY;
    }
  };
  const handleTouchEnd = (): void => {
    const elem = document.querySelector<HTMLElement>(`.` + className);

    if (
      elem &&
      offsetYRef.current >= Math.min(elem.clientHeight / 2, MAX_DRAG_DISTANCE)
    ) {
      handleClose();
    } else {
      if (elem) {
        elem.style.bottom = '0px';
      }
      offsetYRef.current = 0;
      startYRef.current = 0;
    }
    setIsTouching(false);
  };

  if (placement !== 'bottom') {
    return <DrawerContent {...props}>{children}</DrawerContent>;
  }

  return (
    <DrawerContent
      position="relative"
      {...props}
      className={`${props.className || ''} ${CLASS_NAME} ${className}`}
    >
      <Flex
        w="100%"
        h="40px"
        position="absolute"
        top="0"
        left="0"
        pt="8px"
        justify="center"
        {...dragBarProps}
        onTouchStart={(e) => {
          setIsTouching(true);
          startYRef.current = e.touches[0].pageY;
        }}
        onTouchMove={handleTouch}
        onTouchEnd={handleTouchEnd}
      >
        <Box
          w="35px"
          h="4px"
          borderRadius="100px"
          backgroundColor={isTouching ? 'black' : 'shadow'}
          _hover={{ cursor: 'pointer', bgColor: 'black' }}
          _active={{ bgColor: 'black' }}
          onClick={handleClose}
        />
      </Flex>
      {children}
    </DrawerContent>
  );
};

DragDrawerContent.displayName = 'DragDrawerContent';
