import type { FlexProps } from '@chakra-ui/react';
import { Button, Flex, Text } from '@chakra-ui/react';
import {
  getSupportedCoinModesFromPath,
  useRealPathname,
} from '@ui/hooks/router';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { coinModesCro, coinModesZksync } from '@ui/hooks/useCoinMode';
import { useSwitchNetwork } from '@ui/hooks/useSwitchNetwork';
import { useTranslations } from '@ui/i18n';
import { MAXW_DESKTOP_CTA } from '@ui/utils/constants';
import {
  currentWallet,
  getNetworkConfig,
  SUPPORTED_CHAIN_IDS,
  ZKSYNC_ENABLED,
} from '@veno-app/wallet';
import { useMemo } from 'react';

export type WithConnectNetworkProps = FlexProps & {
  actionHint?: string;
  supportedCoinModes?: CoinMode[];
};

export const WithConnectNetwork = ({
  actionHint,
  children,
  supportedCoinModes,
  ...props
}: WithConnectNetworkProps) => {
  const t = useTranslations();
  const chainId = currentWallet.useChainId();
  const isSupportedChainId = SUPPORTED_CHAIN_IDS.includes(chainId as number);
  const isZksync = currentWallet.useIsZksyncChainId();
  const { switchNetwork } = useSwitchNetwork();
  const realPathname = useRealPathname();
  const supportedCoinModesOfPage =
    supportedCoinModes || getSupportedCoinModesFromPath(realPathname);

  const { isCronosButtonVisible, isZksyncButtonVisible } = useMemo(() => {
    const isSupportCro = supportedCoinModesOfPage.some((v) =>
      (coinModesCro as CoinMode[]).includes(v),
    );

    const isSupportZksync = supportedCoinModesOfPage.some((v) =>
      (coinModesZksync as CoinMode[]).includes(v),
    );

    return {
      isCronosButtonVisible:
        (!isSupportedChainId && isSupportCro) || (!isSupportZksync && isZksync),
      isZksyncButtonVisible:
        ZKSYNC_ENABLED &&
        ((!isSupportedChainId && isSupportZksync) ||
          (!isSupportCro && !isZksync)),
    };
  }, [supportedCoinModesOfPage, isSupportedChainId, isZksync]);

  const hint = useMemo(() => {
    if (!actionHint) {
      return null;
    }
    return isCronosButtonVisible && isZksyncButtonVisible
      ? t('Switch network to {chain} or {chain2} to {do}', {
          chain: getNetworkConfig().chainName,
          chain2: getNetworkConfig(true).chainName,
          do: actionHint,
        })
      : t('Switch network to {chain} to {do}', {
          chain: getNetworkConfig(isZksyncButtonVisible).chainName,
          do: actionHint,
        });
  }, [actionHint, isCronosButtonVisible, isZksyncButtonVisible, t]);

  if (!isCronosButtonVisible && !isZksyncButtonVisible) {
    return <>{children}</>;
  }

  return (
    <Flex
      flexDir="column"
      gap={2}
      w="full"
      textAlign="center"
      alignItems="center"
      {...props}
    >
      <Text
        textStyle={{ base: 'h3', desktop: 'h1' }}
        fontWeight={{ desktop: 'normal' }}
      >
        {'Network not supported'}
      </Text>
      <Text
        pt={{ base: 0, desktop: 4 }}
        textStyle={{ base: 'body', desktop: 'h3' }}
        color="text.light"
        w="full"
      >
        {hint}
      </Text>
      <Flex
        m={{ base: 6, desktop: 8 }}
        flexDir="column"
        gap="8px"
        maxW={MAXW_DESKTOP_CTA}
      >
        {isCronosButtonVisible ? (
          <Button onClick={() => switchNetwork(false)}>
            {t('Switch to {chain}', { chain: getNetworkConfig().chainName })}
          </Button>
        ) : null}
        {isZksyncButtonVisible ? (
          <Button onClick={() => switchNetwork(true)}>
            {t('Switch to {chain}', {
              chain: getNetworkConfig(true).chainName,
            })}
          </Button>
        ) : null}
      </Flex>
    </Flex>
  );
};
