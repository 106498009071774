import { TToken__factory } from '@contracts';
import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { VenoSDKModule } from './base';
import { LCRO_DECIMALS } from './constants';
import LiquidCroModule from './liquidCro';
import { getCroUsdPrice, getLiquidTokenUsdPrice, wrapSdkTransaction, } from './util';
export default class TLcroModule extends VenoSDKModule {
    constructor() {
        super(...arguments);
        this.contractFactory = TToken__factory;
        this.contractAddress = this.sdk.addresses.tLcro;
        this.LiquidCro = new LiquidCroModule(this.sdk);
    }
    mint(amount) {
        return wrapSdkTransaction(this.getContract().mint(amount));
    }
    getReceivedTLcro(receipt) {
        const args = receipt.events?.find((e) => e.event === 'Mint')?.args;
        if (!args || args.length !== 3)
            return null;
        return args[2];
    }
    async balanceOf(address) {
        const balance = await this.getContract().balanceOf(address);
        return balance;
    }
    async balanceOfUnderlying(address) {
        return await this.getContract().callStatic.balanceOfUnderlying(address);
    }
    async allowance(owner, operator) {
        return await this.getContract().allowance(owner, operator);
    }
    async getExchangeRate() {
        return await this.getContract().callStatic.exchangeRateCurrent();
    }
    async getLpCoinPerLiquidCoin() {
        const exchangeRate = await this.getExchangeRate();
        const exchangeRateDecimals = LCRO_DECIMALS + 10;
        return (BigNumber.from(10)
            .pow(exchangeRateDecimals)
            .mul(100000000)
            .div(exchangeRate)
            .toNumber() / 100000000);
    }
    async getTLcroUsdWorth(amount) {
        if (amount.lte(0))
            return BigNumber.from(0);
        const [croUsdPrice, croPerLcro, exchangeRate] = await Promise.all([
            getCroUsdPrice(),
            this.LiquidCro.croPerLcro(),
            this.getExchangeRate(),
        ]);
        if (!croUsdPrice || !croPerLcro)
            return BigNumber.from(0);
        const tlcroPerCro = BigNumber.from(10).pow(28).mul(100000000).div(exchangeRate).toNumber() /
            100000000;
        const lcroEquivalent = parseFloat(formatUnits(amount, 8)) / tlcroPerCro;
        const lcroUsdPrice = getLiquidTokenUsdPrice(croUsdPrice, croPerLcro);
        return parseUnits((lcroEquivalent * lcroUsdPrice).toString(), 8);
    }
    async approve(operator, amount) {
        return await wrapSdkTransaction(this.getContract().approve(operator, amount));
    }
}
