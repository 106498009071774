import {
  Box,
  Button,
  DrawerCloseButton,
  Link,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { InDesktop } from '@ui/components/MobileOrDesktop';
import {
  ModalOrDragDrawerContent,
  ModalOrDrawer,
  ModalOrDrawerBodyWithMask,
  ModalOrDrawerFooter,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from '@ui/components/ModalOrDrawer';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { COIN_MODE } from '@ui/hooks/useCoinMode';
import { i18next, useTranslations } from '@ui/i18n';
import type { GardenToken } from '@ui/utils/garden';
import { gardenTokenDict } from '@ui/utils/garden';
import { InColorTheme } from '@veno-app/chakra';
import React from 'react';

import type { SupplyLcroModalType } from './types';
import { useSupplyLcroModalStore } from './useSupplyLcroModalStore';

type SupplyLcroDisclaimerProps = {
  type: SupplyLcroModalType;
};

function SupplyLcroDisclaimer({ type }: SupplyLcroDisclaimerProps) {
  const t = useTranslations();
  const coinMode = useSupplyLcroModalStore((s) => s.coinMode);
  const { isOpen: learnMoreIsOpen, onToggle: toggleLearnMore } =
    useDisclosure();
  const gardenToken = gardenTokenDict[coinMode][type] as Extract<
    GardenToken,
    { enabled: true }
  >;

  return (
    <>
      <InColorTheme colorTheme="default" isRoot>
        <SupplyLearnMoreDrawer
          type={type}
          open={learnMoreIsOpen}
          close={toggleLearnMore}
        />
      </InColorTheme>
      {type === 'TECTONIC' && (
        <Box>
          {t(
            'supply-disclaimer-{suppliedTokenName}-{poolName}-{Link}-{platform}',
            {
              suppliedTokenName: gardenToken.suppliedTokenName,
              poolName: gardenToken.poolName,
              platform: 'Tectonic',
              Link: () => (
                <Link isExternal color="pink.500" href={EXTERNAL_LINK.tectonic}>
                  Tectonic
                </Link>
              ),
            },
          )}
        </Box>
      )}
      {type === 'FERRO' && (
        <Box>
          {t(
            'supply-disclaimer-{suppliedTokenName}-{poolName}-{Link}-{platform}',
            {
              suppliedTokenName: gardenToken.suppliedTokenName,
              poolName: gardenToken.poolName,
              platform: 'Ferro',
              Link: () => (
                <Link isExternal color="pink.500" href={EXTERNAL_LINK.ferro}>
                  Ferro
                </Link>
              ),
            },
          )}
        </Box>
      )}
      {type === 'VVS' && (
        <Box>
          {t(
            'supply-disclaimer-{suppliedTokenName}-{poolName}-{Link}-{platform}',
            {
              suppliedTokenName: gardenToken.suppliedTokenName,
              poolName: gardenToken.poolName,
              platform: 'VVS Finance',
              Link: () => (
                <Link
                  isExternal
                  color="pink.500"
                  href={EXTERNAL_LINK.vvsLanding}
                >
                  VVS Finance
                </Link>
              ),
            },
          )}
        </Box>
      )}
      {(type === 'VVS_CRO' || type === 'VVS_TIA') && (
        <Box>
          {t(
            'supply-disclaimer-{suppliedTokenName}-{poolName}-{Link}-{platform}',
            {
              suppliedTokenName: gardenToken.suppliedTokenName,
              poolName: gardenToken.poolName,
              platform: 'VVS Finance',
              Link: () => (
                <Link
                  isExternal
                  color="pink.500"
                  href={EXTERNAL_LINK.vvsLanding}
                >
                  VVS Finance
                </Link>
              ),
            },
          )}
        </Box>
      )}
      <Button
        variant="link"
        mt={1.5}
        color="text-link"
        onClick={toggleLearnMore}
        alignSelf="flex-start"
      >
        {t('Learn more')}
      </Button>
    </>
  );
}

type SupplyLearnMoreDrawerProps = {
  open: boolean;
  close: () => void;
  type: SupplyLcroModalType;
};

const SupplyLearnMoreDrawer = ({
  open,
  close,
  type,
}: SupplyLearnMoreDrawerProps) => {
  const t = useTranslations();
  const coinMode = useSupplyLcroModalStore((s) => s.coinMode);
  const coinModeInfo = COIN_MODE[coinMode];
  const gardenToken = gardenTokenDict[coinMode][type] as Extract<
    GardenToken,
    { enabled: true }
  >;

  return (
    <ModalOrDrawer
      variant="wholeScreen"
      isOpen={open}
      onClose={close}
      scrollBehavior="inside"
      placement="bottom"
      size={{
        base: 'full',
        desktop: 'md',
      }}
    >
      <ModalOrDrawerOverlay />
      <ModalOrDragDrawerContent>
        <ModalOrDrawerHeader>{t('Learn more')}</ModalOrDrawerHeader>
        <InDesktop>
          <DrawerCloseButton />
        </InDesktop>
        <ModalOrDrawerBodyWithMask>
          <Text textAlign="center" mb="40px" textStyle="h3">
            {getLearnMoreTitle(type, coinMode)}
          </Text>
          <Box textStyle="body">
            {type === 'FERRO' && (
              <Box color="text-light">
                {t(
                  'ferro-learn-more-{suppliedTokenName}-{coin}-{br}-{b}-{FerroLink}',
                  {
                    coin: coinModeInfo.name,
                    suppliedTokenName: gardenToken.suppliedTokenName,
                    br: () => <br />,
                    b: (chunks) => <b>{chunks}</b>,
                    FerroLink: () => (
                      <Link
                        isExternal
                        color="pink.500"
                        href={EXTERNAL_LINK.ferro}
                      >
                        {t('{platform} Website', {
                          platform: 'Ferro',
                        })}
                      </Link>
                    ),
                  },
                )}
              </Box>
            )}
            {type === 'TECTONIC' && (
              <Box color="text-light">
                {t(
                  'tectonic-learn-more-{suppliedTokenName}-{br}-{TectonicLink}',
                  {
                    suppliedTokenName: gardenToken.suppliedTokenName,
                    br: () => <br />,
                    TectonicLink: () => (
                      <Link
                        isExternal
                        color="pink.500"
                        href={EXTERNAL_LINK.tectonic}
                      >
                        Tectonic App
                      </Link>
                    ),
                  },
                )}
              </Box>
            )}
            {(type === 'VVS' || type === 'VVS_CRO' || type === 'VVS_TIA') && (
              <Box color="text-light">
                {t('vvs-learn-more-{br}-{b}-{VvsLink}', {
                  br: () => <br />,
                  b: (chunks) => <b>{chunks}</b>,
                  VvsLink: () => (
                    <Link
                      isExternal
                      color="pink.500"
                      href={EXTERNAL_LINK.vvsRemove}
                    >
                      {t('{platform} Website', {
                        platform: 'VVS Finance',
                      })}
                    </Link>
                  ),
                })}
              </Box>
            )}
          </Box>
        </ModalOrDrawerBodyWithMask>
        <ModalOrDrawerFooter>
          <Button mt="auto" onClick={close}>
            {t('Close')}
          </Button>
        </ModalOrDrawerFooter>
      </ModalOrDragDrawerContent>
    </ModalOrDrawer>
  );
};

const getLearnMoreTitle = (
  type: SupplyLcroModalType,
  coinMode: CoinMode,
): string => {
  const gardenToken = gardenTokenDict[coinMode][type] as Extract<
    GardenToken,
    { enabled: true }
  >;

  return i18next.t('Supply {coin} on {platform}', {
    coin: gardenToken.suppliedTokenName,
    platform: gardenToken.protocolName,
  });
};

export default SupplyLcroDisclaimer;
