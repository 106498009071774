import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { features } from '@ui/components/Features';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { getQuery } from '@ui/queries/queries';
import { QueryKey } from '@ui/queries/queryKey';
import { currentWallet } from '@veno-app/wallet';

import { isFeatureEnabled } from './useIsFeatureEnabled';

const { useAccount, useIsZksyncChainId } = currentWallet;

const useVnoBalance = (options?: Partial<Pick<UseQueryOptions, 'enabled'>>) => {
  const sdk = useVenoSDK();
  const isZksync = useIsZksyncChainId();
  const account = useAccount();
  const vnoBalanceQuery = getQuery(QueryKey.VNO_BALANCE)(sdk, account ?? '');
  const { data: vnoBalance, isLoading } = useQuery(
    vnoBalanceQuery.queryKey,
    vnoBalanceQuery.queryFn,
    {
      ...options,
      enabled:
        !!account &&
        (!isZksync || isFeatureEnabled(features.vnoTokenZksync)) &&
        (options?.enabled === undefined || options?.enabled),
    },
  );

  return {
    loading: isLoading,
    vnoBalance,
  };
};

export default useVnoBalance;
