import type { TextProps } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import React from 'react';

type TextWithAbbreviateZeros = {
  children: string | null | undefined;
  abbreviateZeros?: boolean;
} & TextProps;

const TextWithAbbreviateZeros: React.FC<TextWithAbbreviateZeros> = ({
  children,
  abbreviateZeros,
  ...textProps
}) => {
  const formattedContent = React.useMemo(() => {
    if (!abbreviateZeros || !children) {
      return <Text {...textProps}>{children}</Text>;
    }

    // Check if the value is a valid number and handle non-numeric strings
    if (isNaN(Number(children))) {
      return <Text {...textProps}>Invalid number</Text>;
    }

    // Split the string on the decimal point
    const [integerPart, decimalPart = ''] = children.split('.');

    // Handle numbers greater than or equal to 1 differently
    if (Number(integerPart) >= 1) {
      const formattedNumber = parseFloat(children).toFixed(8); // Adjust precision as needed
      return <Text {...textProps}>{formattedNumber}</Text>;
    }

    // For numbers less than 1, process leading zeros and significant digits
    const match = decimalPart.match(/^(0*)(\d.*)/); // Updated to capture all digits after zeros
    if (match) {
      const [, leadingZeros, significantDigits] = match;

      if (!leadingZeros.length) return <Text {...textProps}>{children}</Text>;

      return (
        <Text {...textProps}>
          0.0
          <sub>{leadingZeros.length}</sub>
          {significantDigits}
        </Text>
      );
    }

    // Handle case with no significant digits after the decimal (i.e., the number is exactly zero)
    if (integerPart === '0' && decimalPart === '') {
      return <Text {...textProps}>0.0</Text>;
    }

    return <Text {...textProps}>0.{decimalPart}</Text>; // Fallback for any other unusual cases
  }, [children, textProps, abbreviateZeros]);

  return formattedContent;
};

export default TextWithAbbreviateZeros;
