import type { FlexProps } from '@chakra-ui/react';
import { Flex, Text } from '@chakra-ui/react';
import type { ReactNode } from 'react';

export type VnoAprSkeletonProps = FlexProps & {
  unavailableText?: ReactNode;
};
export const VnoAprSkeleton = ({
  unavailableText = '--%',
  ...props
}: VnoAprSkeletonProps) => {
  return (
    <Flex
      width="38px"
      ml="auto"
      justify="flex-end"
      textStyle="bodyBold"
      {...props}
    >
      <Text>{unavailableText}</Text>
    </Flex>
  );
};
