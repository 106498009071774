import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { getFerroLpBalance, getFerroLpBalanceAtom } from '@ui/queries/sdk';
import { currentWallet } from '@veno-app/wallet';

import type { CoinMode } from './useCoinMode';
import { useCoinMode } from './useCoinMode';

const { useAccount } = currentWallet;

const useFerroLpBalance = (
  {
    coinMode: _coinMode,
    ...options
  }: Partial<Pick<UseQueryOptions, 'enabled'>> & {
    coinMode?: CoinMode;
  } = {},
  address?: string,
) => {
  const globalCoinMode = useCoinMode();
  const coinMode = _coinMode ?? globalCoinMode;
  const sdk = useVenoSDK();
  const userAccount = useAccount();
  const account = address ?? userAccount;
  const query =
    coinMode === 'atom'
      ? getFerroLpBalanceAtom(sdk, account ?? '')
      : getFerroLpBalance(sdk, account ?? '');

  const ret = useQuery(query.queryKey, query.queryFn, {
    ...options,
    enabled: !!account && (options?.enabled === undefined || options?.enabled),
  });

  return {
    ...ret,
    loading: ret.isLoading,
    ferroLpBalance: ret.data,
  };
};

export default useFerroLpBalance;
