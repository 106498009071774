import type { FlexProps } from '@chakra-ui/react';
import { Button, Flex } from '@chakra-ui/react';
import { convertScientificToDecimal } from '@ui/utils/bigNumberToNumber';
import numbro from 'numbro';

export type InputCardStepperProps = Omit<FlexProps, 'onChange'> & {
  onChange?: (value: string) => void;
  max: number | string;
  value?: string;
  steps?: ('max' | number)[];
  maxDecimals?: number;
  variant?: 'vvs';
};
export const InputCardStepper = ({
  onChange,
  max,
  value,
  steps = [0.25, 0.5, 0.75, 'max'],
  maxDecimals,
  variant,
  ...props
}: InputCardStepperProps) => {
  return (
    <Flex
      gap={{
        base: '21px',
        desktop: '23px',
      }}
      {...props}
    >
      {steps.map((step, i) => {
        let buttonValue = '';
        if (step === 'max') {
          buttonValue = convertScientificToDecimal(String(max), maxDecimals);
        } else {
          const result = String(
            step * (typeof max === 'string' ? parseFloat(max) : max),
          );
          buttonValue = convertScientificToDecimal(result, maxDecimals);
        }
        const buttonText =
          step === 'max'
            ? 'Max'
            : numbro(step).format({
                output: 'percent',
              });

        return (
          <Button
            key={i}
            variant={!variant ? 'smallCard' : 'vvs'}
            size="sm"
            w="0"
            flex="1"
            isActive={value === buttonValue}
            onClick={() => onChange?.(buttonValue)}
          >
            {buttonText}
          </Button>
        );
      })}
    </Flex>
  );
};
