const UNITS: Record<string, number> = {
  month: 30.4 * 86400,
  day: 86400,
  hour: 3600,
  minute: 60,
  second: 1,
} as const;

function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const DEFAULT_OPTIONS = { uppercase: true, roughly: false };
type Options = {
  uppercase?: boolean;
  roughly?: boolean;
};
export const formatLockPeriod = (
  lockPeriod: number,
  options?: Options,
): string => {
  const { uppercase, roughly } = { ...DEFAULT_OPTIONS, ...options };
  let value = lockPeriod;

  const result: string[] = [];

  for (const [unit, timestamp] of Object.entries(UNITS)) {
    if (!value) break;

    const p = Math.round(value / timestamp);
    const isOmitted =
      result.length > 0 &&
      (roughly ||
        result[0].includes(
          uppercase
            ? capitalizeFirstLetter(Object.keys(UNITS)[0])
            : Object.keys(UNITS)[0],
        ));

    // singular
    if (p === 1 && !isOmitted)
      result.push(` ${p} ${uppercase ? capitalizeFirstLetter(unit) : unit}`);
    // plural
    if (p >= 2 && !isOmitted)
      result.push(` ${p} ${uppercase ? capitalizeFirstLetter(unit) : unit}s`);
    value %= timestamp;
  }

  return result.join('').trimStart();
};
