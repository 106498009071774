import { getLangUrls } from '@ui/i18n';

/**
 * @see https://github.com/vercel/next.js/discussions/18072#discussioncomment-109059
 */
export const replaceRouterWithoutRerender = (url: string) => {
  const [newUrl, newAsUrl] = getLangUrls(url);
  window.history.replaceState(
    { ...window.history.state, as: newAsUrl, url: newUrl },
    '',
    newAsUrl,
  );
};

/**
 * @see https://github.com/vercel/next.js/discussions/18072#discussioncomment-109059
 */
export const pushRouterWithoutRerender = (url: string) => {
  const [newUrl, newAsUrl] = getLangUrls(url);
  window.history.pushState(
    { ...window.history.state, as: newAsUrl, url: newUrl },
    '',
    newAsUrl,
  );
};
