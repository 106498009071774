import type { ColorMode } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

export const useIFrameTheme = () => {
  const router = useRouter();

  return useMemo(() => {
    const { pathname } = router;
    const theme =
      pathname === '/iframe-vvs' || pathname === '/iframe-vvs-dark'
        ? 'vvs'
        : 'default';
    const colorMode: ColorMode =
      pathname === '/iframe-vvs-dark' ? 'dark' : 'light';

    return { theme, colorMode };
  }, [router]);
};
