import { VvsZapEstimator__factory } from '@contracts';
import { VenoSDKModule } from './base';
export default class VvsZapEstimatorModule extends VenoSDKModule {
    constructor() {
        super(...arguments);
        this.contractFactory = VvsZapEstimator__factory;
        this.contractAddress = this.sdk.addresses.vvsZapEstimator;
    }
    async getExchangeRate(amount) {
        // https://docs.google.com/presentation/d/1wVcbrXzdxv-SKdw6ta_S89jMO_967mGA/edit#slide=id.g205a860bf26_0_28
        const [, , amt1, amt2] = await this.getContract().estimateZapInToLpSwapPaths(this.sdk.addresses.venoToken, amount, this.sdk.addresses.vvsPair);
        const received = await this.getContract().estimateAddLiquidityOutputAmount(amt1, amt2, this.sdk.addresses.vvsPair);
        return received.mul(100000000).div(amount).toNumber() / 100000000;
    }
    async getVvsCroExchangeRate(amount) {
        const [, , amt1, amt2] = await this.getContract().estimateZapInToLpSwapPaths(this.sdk.addresses.liquidCro, amount, this.sdk.addresses.vvsCroPair);
        const received = await this.getContract().estimateAddLiquidityOutputAmount(amt1, amt2, this.sdk.addresses.vvsCroPair);
        return received.mul(100000000).div(amount).toNumber() / 100000000;
    }
    async getVvsTiaExchangeRate(amount) {
        const [, , amt1, amt2] = await this.getContract().estimateZapInToLpSwapPaths(this.sdk.addresses.liquidTia, amount, this.sdk.addresses.vvsTiaPair);
        const received = await this.getContract().estimateAddLiquidityOutputAmount(amt1, amt2, this.sdk.addresses.vvsTiaPair);
        return (received.mul(100000000).div(amount).toNumber() /
            100000000 /
            // divide by 10e12 to account for: LP has 18 decimals, but TIA has 6
            1000000000000);
    }
}
