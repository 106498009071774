import { useQuery } from '@tanstack/react-query';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { getQuery } from '@ui/queries/queries';
import { QueryKey } from '@ui/queries/queryKey';
import type { FountainPool } from '@ui/queries/types';

export const useFountainPoolInfos = (enabled?: boolean) => {
  const sdk = useVenoSDK();
  const query = getQuery(QueryKey.GET_VENO_FOUNTAIN_POOL_INFOS)(sdk);

  const queryResult = useQuery<FountainPool[]>({
    queryKey: query.queryKey,
    queryFn: async () => {
      const data = await query.queryFn();
      return data?.map((pool) => ({
        ...pool,
        multiplier: pool.multiplier.toNumber(),
        lockPeriod: pool.lockPeriod.toNumber(),
      }));
    },
    enabled: enabled !== false,
  });

  return {
    ...queryResult,
    pools: queryResult.data,
  };
};
