import { useRealPathname } from '@ui/hooks/router';
import usePersistentContext from '@ui/hooks/usePersistentContext';
import { useRouter } from '@ui/overrides/next/router';

export const useSwitchLang = () => {
  const router = useRouter();
  const realPathname = useRealPathname();
  const [_, setDefaultLanguage] = usePersistentContext('selectedLanguage');

  return (lang: string) => {
    router.push(realPathname, undefined, {
      locale: lang,
    });
    setDefaultLanguage(lang);
  };
};
