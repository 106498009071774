import type { DrawerProps } from '@chakra-ui/react';
import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import type { BigNumber } from '@ethersproject/bignumber';
import { useQueryClient } from '@tanstack/react-query';
import Balance from '@ui/components/Balance';
import { Card } from '@ui/components/Card';
import {
  ModalOrDragDrawerContent,
  ModalOrDrawer,
  ModalOrDrawerBody,
  ModalOrDrawerCloseButton,
  ModalOrDrawerFooter,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from '@ui/components/ModalOrDrawer';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import {
  COIN_MODE,
  useCoinMode,
  useLiquidModuleWithSigner,
} from '@ui/hooks/useCoinMode';
import { useCoinUsdValue } from '@ui/hooks/useCoinUsdValue';
import { getTokenAmountOfNfts } from '@ui/hooks/useNftTokens';
import { useTranslations } from '@ui/i18n';
import { QueryKey } from '@ui/queries/queryKey';
import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import { ArrowBack } from '@veno-app/icons';
import type { EthersError } from '@veno-app/sdk';
import { currentWallet } from '@veno-app/wallet';
import { useEffect, useMemo, useRef, useState } from 'react';
import { create } from 'zustand';

import { InDesktop } from '../MobileOrDesktop';
import { TransactionError, TransactionPending } from '../TransactionDrawer';
import type { NftToken } from './utils';

const { useAccount } = currentWallet;

enum ClaimStep {
  default,
  loading,
  failed,
  success,
}

export type NftClaimDrawerProps = Partial<DrawerProps>;
export const NftClaimDrawer = (props: NftClaimDrawerProps) => {
  const t = useTranslations();
  const { isOpen, coinMode, nftList, onClose, onBackward, onSuccessClose } =
    useNftClaimDrawerStore();
  const coinModeInfo = COIN_MODE[coinMode];
  const [step, setStep] = useState<ClaimStep>(ClaimStep.default);
  const [error, setError] = useState<EthersError | null>(null);
  const isOpenRef = useRef(isOpen);
  isOpenRef.current = isOpen;

  const claimNft = useNftClaim();
  const claimAll = useNftBatchClaim();

  const doClaim = async () => {
    setStep(ClaimStep.loading);

    try {
      if (nftList.length > 1) {
        await claimAll(nftList.map((nft) => nft.id));
      } else {
        await claimNft(nftList[0]);
      }
    } catch (e) {
      setError(e as EthersError);
      setStep(ClaimStep.failed);
      console.error('nft claim error', e);
      return;
    }

    setStep(ClaimStep.success);
  };

  const tokenAmount = useMemo(() => getTokenAmountOfNfts(nftList), [nftList]);
  const { data: croAmountUsd } = useCoinUsdValue(tokenAmount);

  // reset when open or closed
  useEffect(() => {
    setStep(ClaimStep.default);
  }, [isOpen]);

  return (
    <ModalOrDrawer
      placement="bottom"
      {...props}
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={() => {
        if (step === ClaimStep.success) {
          onSuccessClose?.();
        }
        onClose();
      }}
    >
      <ModalOrDrawerOverlay zIndex="modal" />
      <ModalOrDragDrawerContent h={{ base: '60vh', desktop: undefined }}>
        <InDesktop>
          {onBackward &&
            (step === ClaimStep.default || step === ClaimStep.failed) && (
              <ModalOrDrawerCloseButton
                left="10px"
                onClick={() => {
                  onClose();
                  onBackward();
                }}
              >
                <Icon as={ArrowBack} boxSize="16px" />
              </ModalOrDrawerCloseButton>
            )}
          <ModalOrDrawerCloseButton />
        </InDesktop>
        <ModalOrDrawerHeader>{t('Claim')}</ModalOrDrawerHeader>
        <ModalOrDrawerBody as={Flex} direction="column" alignItems="center">
          {step === ClaimStep.default && (
            <Card
              as={Flex}
              width="90%"
              height="154px"
              justifyContent="center"
              alignItems="center"
            >
              <Balance
                title={t('Amount to claim')}
                icon={<coinModeInfo.Icon />}
                amount={tokenAmount}
                unitDecimals={coinModeInfo.decimals}
                usdAmount={croAmountUsd}
                usdDisplayDecimals={3}
                showUsdAmount
              />
            </Card>
          )}
          {step === ClaimStep.loading && <TransactionPending.Content />}
          {step === ClaimStep.failed && (
            <TransactionError.Content error={error} />
          )}
          {step === ClaimStep.success && (
            <>
              <Text textStyle="h3">{t('Transaction completed!')}</Text>
              <Balance
                title={t('You have claimed')}
                icon={<coinModeInfo.Icon />}
                amount={tokenAmount}
                unitDecimals={coinModeInfo.decimals}
                usdAmount={croAmountUsd}
                usdDisplayDecimals={3}
                mt="24px"
              />
            </>
          )}
        </ModalOrDrawerBody>
        <ModalOrDrawerFooter>
          {step === ClaimStep.default || step === ClaimStep.loading ? (
            <Button isLoading={step === ClaimStep.loading} onClick={doClaim}>
              {t('Confirm')}
            </Button>
          ) : (
            <InDesktop>
              <Button onClick={() => onClose()}>{t('Done')}</Button>
            </InDesktop>
          )}
        </ModalOrDrawerFooter>
      </ModalOrDragDrawerContent>
    </ModalOrDrawer>
  );
};

export type NftClaimDrawerStore = {
  isOpen: boolean;
  coinMode: CoinMode;
  nftList: NftToken[];
  onBackward: (() => void) | null;
  onSuccessClose: (() => void) | null;
  onOpen: (options: {
    coinMode: CoinMode;
    nfts: NftToken | NftToken[];
    onBackward?: () => void;
    onSuccessClose?: () => void;
  }) => void;
  onClose: () => void;
};

export const useNftClaimDrawerStore = create(
  resetWhenRouterChange<NftClaimDrawerStore>((set) => ({
    isOpen: false,
    coinMode: 'cro',
    nftList: [] as NftToken[],
    onBackward: null,
    onSuccessClose: null,
    onOpen: ({ coinMode, nfts, onBackward, onSuccessClose }) => {
      set({
        isOpen: true,
        coinMode,
        nftList: nfts ? (Array.isArray(nfts) ? [...nfts] : [nfts]) : [],
        onBackward: onBackward || null,
        onSuccessClose: onSuccessClose || null,
      });
    },
    onClose: () => {
      useNftClaimDrawerStore.getState().$$resetData?.();
    },
  })),
);

/**
 * @see https://docs.google.com/presentation/d/1lnz8LDucLQsPauZ0WANvKF4Q4OtFewBol95Zy32xC9A/edit#slide=id.g17bffd39b19_0_0
 */
const useNftClaim = () => {
  const coinMode = useCoinMode();
  const account = useAccount();
  const queryClient = useQueryClient();
  const liquidModule = useLiquidModuleWithSigner(coinMode);

  return async (nft: NftToken) => {
    if (!account || !nft) {
      return;
    }

    const unbondData = await liquidModule.unbond(nft.id, account);

    await unbondData.txReceiptPromise;

    queryClient.invalidateQueries([QueryKey.GET_NFT_TOKENS]);
  };
};

const useNftBatchClaim = () => {
  const coinMode = useCoinMode();
  const liquidModule = useLiquidModuleWithSigner(coinMode);
  const account = useAccount();
  const queryClient = useQueryClient();

  return async (ids: BigNumber[]) => {
    if (!account || !ids.length) {
      return;
    }

    const unbondData = await liquidModule.batchUnbond(ids, account);
    await unbondData.txReceiptPromise;

    queryClient.invalidateQueries([QueryKey.GET_NFT_TOKENS]);
  };
};
