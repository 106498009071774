import type { ImageProps } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';

export type Token =
  | 'vno'
  | 'cro'
  | 'atom'
  | 'latom'
  | 'lcro'
  | 'vvs'
  | 'eth'
  | 'leth'
  | 'tia'
  | 'ltia';

const TOKENS: Record<
  Token,
  {
    src: string;
    srcLight?: string;
    defaultSize: string;
  }
> = {
  vno: {
    src: '/tokens/vno.svg',
    srcLight: '/tokens/vno-light.svg',
    defaultSize: '24px',
  },
  cro: {
    src: '/tokens/cro.svg',
    srcLight: '/tokens/cro-light.svg',
    defaultSize: '24px',
  },
  atom: {
    src: '/tokens/atom.svg',
    defaultSize: '24px',
  },
  latom: {
    src: '/tokens/latom.svg',
    srcLight: '/tokens/latom-light.svg',
    defaultSize: '24px',
  },
  lcro: {
    src: '/tokens/lcro.svg',
    srcLight: '/tokens/lcro-light.svg',
    defaultSize: '24px',
  },
  vvs: {
    src: '/tokens/vvs.svg',
    srcLight: '/tokens/vvs-light.svg',
    defaultSize: '24px',
  },
  eth: {
    src: '/tokens/eth.svg',
    defaultSize: '24px',
  },
  leth: {
    src: '/tokens/leth.svg',
    srcLight: '/tokens/leth-light.svg',
    defaultSize: '24px',
  },
  tia: {
    src: '/tokens/tia.svg',
    defaultSize: '24px',
  },
  ltia: {
    src: '/tokens/ltia.svg',
    srcLight: '/tokens/ltia-light.svg',
    defaultSize: '24px',
  },
};

export type TokenIconProps = ImageProps & {
  token: Token;
};

export const TokenIcon = ({ token, ...props }: TokenIconProps) => {
  const tokenInfo = TOKENS[token];

  return (
    <>
      <Image
        alt={`${token} icon`}
        src={tokenInfo.src}
        w={tokenInfo.defaultSize}
        h={tokenInfo.defaultSize}
        {...props}
        _dark={
          tokenInfo.srcLight
            ? {
                ...props._dark,
                display: 'none',
              }
            : props._dark
        }
      />
      {tokenInfo.srcLight && (
        <Image
          alt={`${token} icon`}
          src={tokenInfo.srcLight}
          w={tokenInfo.defaultSize}
          h={tokenInfo.defaultSize}
          {...props}
          _light={{
            ...props._light,
            display: 'none',
          }}
        />
      )}
    </>
  );
};
