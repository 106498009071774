import { PATH_VNO, pathDict } from '@ui/config/paths';
import { getPathInfo, isCategoryInSameView } from '@ui/hooks/router';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { parsePathname } from '@ui/i18n';
import router from '@ui/overrides/next/router';
import type { GardenType, GardenView } from '@ui/utils/garden';
import { getGardenColorThemeByCoinMode } from '@ui/utils/garden';
import { replaceRouterWithoutRerender } from '@ui/utils/urlHistory';
import { withReset } from '@ui/utils/zustandMiddleware';
import type { ColorTheme } from '@veno-app/chakra';
import { MODAL_MOTION_SCALE_EXIT_DURATION } from '@veno-app/chakra';
import { create } from 'zustand';

type GardenModalStore = {
  colorTheme: ColorTheme;
  coinMode: CoinMode;
  isOpen: boolean;
  close: () => void;
  open: (options: {
    type: GardenType;
    activeView?: GardenView;
    coinMode: CoinMode;
  }) => void;
  type: GardenType;
  activeView: GardenView;
  setActiveView: (activeView: GardenView) => void;
};

export const useGardenModalStore = create(
  withReset<GardenModalStore>((set, get) => ({
    isOpen: false,
    colorTheme: getGardenColorThemeByCoinMode('cro'),
    coinMode: 'cro',
    close: () => {
      set({
        isOpen: false,
      });
      /**
       * as chakra modal's closing has delay for animation,
       * reset store only after the modal is fully closed,
       * otherwise the ATOM amount will be display in CRO units immediately, make users confused.
       * @see https://cronoslabs.slack.com/archives/C05GJC6K1HT/p1697563396279399
       */
      setTimeout(() => {
        useGardenModalStore.getState().$$resetData?.();
        replaceRouterWithoutRerender(PATH_VNO);
      }, MODAL_MOTION_SCALE_EXIT_DURATION);
    },
    open: ({ type, activeView, coinMode }) => {
      const nextActiveView = activeView ?? get().activeView;
      set({
        isOpen: true,
        type,
        activeView: nextActiveView,
        coinMode: coinMode,
        colorTheme: getGardenColorThemeByCoinMode(coinMode, type),
      });

      changeRouter({
        coinMode,
        gardenType: type,
        gardenView: nextActiveView,
      });
    },
    setActiveView: (activeView: GardenView) => {
      set({ activeView });

      changeRouter({
        coinMode: get().coinMode,
        gardenType: get().type,
        gardenView: activeView,
      });
    },
    type: 'TECTONIC',
    activeView: 'PLANT',
  })),
);

const changeRouter = ({
  coinMode,
  gardenType,
  gardenView,
}: {
  coinMode: CoinMode;
  gardenType: GardenType;
  gardenView: GardenView;
}) => {
  if (
    typeof window !== 'undefined' &&
    !isCategoryInSameView(
      getPathInfo(parsePathname(window.location.pathname).pathname).category,
      'vno',
    )
  ) {
    router.push(pathDict[coinMode].garden[gardenType][gardenView]);
  } else {
    replaceRouterWithoutRerender(
      pathDict[coinMode].garden[gardenType][gardenView],
    );
  }
};
