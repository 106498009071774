import type { ChainType, ContractAddresses } from '@veno-app/sdk';

export enum Network {
  cronos_testnet = 'cronos_testnet',
  cronos = 'cronos',
  veno_dryrun = 'veno_dryrun',
  zksync = 'zksync',
  zksync_testnet = 'zksync_testnet',
}

export const getContractAddresses = (
  chainType: ChainType = 'cronos',
): ContractAddresses => {
  if (chainType === 'zksync') {
    switch (process.env.NEXT_PUBLIC_DEFAULT_ZKSYNC_NETWORK) {
      case Network.zksync:
        return {
          venoNft: '0xfeA4195BE610C4dD1b25a97a1c1402333c404936',
          liquidCro: '',
          venoToken: '0xE75A17b4F5c4F844688d5670B684515d7c785E63',
          venoFountain: '0x4E313FF3A7210b9356be34fd35007d42a0B8cd24',
          venoReservoir: '0x28516f186A50A5da55a61e562c12bBa97d5Abff2',
          feeDistributor: '0x6249e87a9b824f23C6f50Aa4C0A9aFdac0a8E759',
          wcro: '',
          venoStorm: '0xBDdD1Aa977AcD01510A9E9627eEE8AC1C5e20aCA',
          // leth-eth-lp
          ferroLp: '0x724299572209d25aD8C1864737cA0accCB0925C6',
          tLcro: '',
          tLatom: '',
          cruiserRarityRegistry: '',
          ferroSwap: '',
          // vno-eth-lp
          vvsPair: '0xeFF7ea0713764A38D45c2a75b39486b2BbcE28ce',
          vvsZap: '',
          vvsZapEstimator: '',
          venoStormMultiplierReader:
            '0xD778d23Fa71AAB0fD1FcaFa071b59F5fe63b8975',
          cronosCruisers: '',
          minerMoles: '',
          boomerSquad: '',
          ballies: '',
          topDogCorgi: '',
          cronosChimp: '',
          liquidAtom: '',
          atomNft: '',
          atom: '',
          ferroSwapAtom: '',
          ferroLpAtom: '',
          vvsCroPair: '',
          wcroDelegator: '',
          croDelegator: '',
          lcroDelegator: '',
          ferroVault: '',
          liquidEth: '0xE7895ed01a1a6AAcF1c2E955aF14E7cf612E7F9d',
          tia: '',
          tiaNft: '',
          liquidTia: '',
          weth: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
          vvsTiaPair: '',
        };
      case Network.zksync_testnet:
        return {
          venoNft: '0xF8B19e020eCA23353DC0079e2eaEf19A87E903aB',
          liquidCro: '',
          venoToken: '0x509bb357d9d03c524547C36B3714ACaf5CF2b7d7',
          venoFountain: '0x808FB5DA7BF138D9C3f63Aa77a3bB596F82Fd44d',
          venoReservoir: '0x02D748Ba57f0eed0E53736FD5BFbFf7195Bc8c14',
          feeDistributor: '0x7B60249C1b7d0Bc049D709255482d830AAEA16B2',
          wcro: '',
          venoStorm: '0x0513b91D6592883D67f6449Ed735dCDc45c65bD7',
          // leth-eth-lp
          ferroLp: '0x23915a875E23aAa791CE4E423ca470272321a973',
          tLcro: '',
          tLatom: '',
          cruiserRarityRegistry: '',
          ferroSwap: '',
          // vno-eth-lp
          vvsPair: '0xE168e5B6C98381b297424deFFadd2d651d7b6b6E',
          vvsZap: '',
          vvsZapEstimator: '',
          venoStormMultiplierReader:
            '0xB4366d91F0a0c415F5444cc3aE323c819368034A',
          cronosCruisers: '',
          minerMoles: '',
          boomerSquad: '',
          ballies: '',
          topDogCorgi: '',
          cronosChimp: '',
          liquidAtom: '',
          atomNft: '',
          atom: '',
          ferroSwapAtom: '',
          ferroLpAtom: '',
          vvsCroPair: '',
          wcroDelegator: '',
          croDelegator: '',
          lcroDelegator: '',
          ferroVault: '',
          liquidEth: '0x0d0d7aE483047dB18731cf819DE78ff6e5C60a3E',
          weth: '0x701f3b10b5cc30ca731fb97459175f45e0ac1247',
          tia: '',
          tiaNft: '',
          liquidTia: '',
          vvsTiaPair: '',
        };
    }
  }

  switch (process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK) {
    case Network.cronos:
      return {
        venoNft: '0xb15533a0bc7c530d692a9660785226dfd3633965',
        liquidCro: '0x9fae23a2700feecd5b93e43fdbc03c76aa7c08a6',
        venoToken: '0xdb7d0a1ec37de1de924f8e8adac6ed338d4404e9',
        venoFountain: '0xb4be51216f4926ab09ddf4e64bc20f499fd6ca95',
        venoReservoir: '0x21179329c1dcfd36ffe0862cca2c7e85538cca07',
        feeDistributor: '0x4758de8640cf7fef229c20299ad853c86c0c1e39',
        wcro: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
        venoStorm: '0x579206e4e49581ca8ada619e9e42641f61a84ac3',
        ferroLp: '0xC73B40a6a57cC8203462397F64527fD46766Dd85',
        tLcro: '0xCd8F4147A4F9452e027f5203bfc5B7A786055138',
        tLatom: '0x0a927384CC7ff3D250ac276ae2158D837e42f667',
        cruiserRarityRegistry: '0xbB2921C2d790fB4918C15811822F8593843fB5E7',
        ferroSwap: '0x1578C5CF4f8f6064deb167d1eeAD15dF43185afa',
        vvsPair: '0x34d1856ED8BBc20FA7b29776ad273FD8b22967BE',
        vvsZap: '0x8d13982c702fe7c6537529986df67dabeafc4c19',
        vvsZapEstimator: '0x0a18b3430a6a1Fa0d403BCc729E26040942b14e3',
        venoStormMultiplierReader: '0x6354823558223085af27cc9eec18ba3f1b219ee2',
        cronosCruisers: '0xd25358e2cAD3E1Fd165887569892A99fFFA674ac',
        minerMoles: '0x06596eD89aC4609DE47A21aF7E36b38b2dF57C26',
        boomerSquad: '0xb650279e3d726b0c75c984caa55341cb87a7f501',
        ballies: '0xf12b07570804c56b4ce8f63e7121bec8c7ca1542',
        topDogCorgi: '0x7e378f70816a41c396f19cf9cbebe26ea8c00023',
        cronosChimp: '0x562f021423d75a1636db5be1c4d99bc005ccebfe',
        liquidAtom: '0xac974ee7fc5d083112c809ccb3fce4a4f385750d',
        atomNft: '0x10a1d7d9abefbc84f4ebe9daa546f50eb9e5d191',
        atom: '0xb888d8dd1733d72681b30c00ee76bde93ae7aa93',
        ferroSwapAtom: '0x5FA9412C2563c0B13CD9F96F0bd1A971F8eBdF96',
        ferroLpAtom: '0x260481aD807C95Ea30AcEaB5D01Afe0a76f41929',
        vvsCroPair: '0xbA5cc14D3D83Ba0881B046D5487760cf669f6F4e',
        wcroDelegator: '0x186a963f78ba0ca8e1799a32e3106c9fee05c7c1',
        croDelegator: '0xd73863c0d3637ca805d449aac7ed04c605bc456c',
        lcroDelegator: '0xbd69099e29dd0d4a1dd24f3c2058fd7f07e0aee5',
        ferroVault: '0x6b5E1fC9b73aBeE02926F796DCD948DffA139419',
        liquidEth: '',
        tia: '0x982b59aae4f0bc66960b4bf06d6fe96b9f33d3f7',
        tiaNft: '0x0bbe7ee32c2dc26f51467431f2f61b8c572af25d',
        liquidTia: '0x276e28664dec4982f892a5b836e11f23040b6995',
        weth: '',
        vvsTiaPair: '0xBc0ec7390eC37d2Efc98F1b6E51561EbC86714FA',
      };
    case Network.veno_dryrun:
      return {
        venoNft: '0x11d9f28dce1cae0cb7be8ca7cf0e53417feb718d',
        liquidCro: '0x47fcf468b092a0241ea13174042c7c727602a17d',
        venoToken: '0xf6acfc3a7290e56b9444c2e500918bdb61f6e489',
        venoFountain: '0x74e46091df74785f695e8565cb5fb9dfc7fcbafc',
        venoReservoir: '0xfefea029996b24354d1836e1477a5658ce5fca45',
        feeDistributor: '0x74865f74a3671c6da97133306d1146f322afe533',
        wcro: '0xcB6831C6F5202dd871BB3cc9B7bBc18EEF8A2B79',
        venoStorm: '0x8ea02e27a068954ee04a135c0e2e9bea2b872dec',
        ferroLp: '0x25b2696A76A44172b674163DCABb00A08B11Af87',
        tLcro: '0xa1e9cb17e3329e7349661D31704F393909123D44',
        /**
         * using this fork mainnet rpc to test: https://psta-forkenv.crorc.co/endpoints/580af827f1fa12d5
         * ask @zhiyong.hu for some CROs and ATOMs on the fork mainnet node
         */
        tLatom: '',
        cruiserRarityRegistry: '0x5B41fD7D77520ABbD99198A9Cfac59Aa18d3777e',
        ferroSwap: '0x1578C5CF4f8f6064deb167d1eeAD15dF43185afa',
        vvsPair: '0xD005Be3E17b6558C218f9CE4003BfA304ea6c29e',
        vvsZap: '0x6e1308f8d2f2030667ccb7b83ed580d81405f318',
        vvsZapEstimator: '0x0a18b3430a6a1Fa0d403BCc729E26040942b14e3',
        venoStormMultiplierReader: '0x767593124218a9b61fab4f8fee0056447b827fe5',
        cronosCruisers: '0xbFF00b0ab9Ee21B2b272186C603104adC525920E',
        minerMoles: '0x06596eD89aC4609DE47A21aF7E36b38b2dF57C26',
        boomerSquad: '0xe831d478cBd0427b633F78bB60aa21bb917AAf5F',
        ballies: '0x7ec2dbc9293c24addf381526745501470570ad92',
        topDogCorgi: '0x18bb56953cf56cc478bffab25184cca74b58d160',
        cronosChimp: '0x0cbf4646cfe50717ccefac075c272e7cdf1adb69',
        liquidAtom: '0x6afbc8f1f71968d9d474c5e8113fca31aad836f3',
        atomNft: '0xb319dbfa2c1bf4172a7417cd65c1351bbf0c5b3c',
        atom: '0xe66B1507c62bF2c5089DDECdd77F56cd2327eCF8',
        ferroSwapAtom: '',
        ferroLpAtom: '',
        // TODO: correct address
        vvsCroPair: '0xD005Be3E17b6558C218f9CE4003BfA304ea6c29e',
        wcroDelegator: '',
        croDelegator: '',
        lcroDelegator: '',
        ferroVault: '',
        liquidEth: '',
        tia: '0x69fA5FE14c1644399c4F70AF0e8df02408F24D14',
        tiaNft: '0x4c86f72904c95bcdc2cbdeee606e40e565130b72',
        liquidTia: '0xf7a5c2efc578ac9ed8356314764981192bc5049d',
        weth: '',
        vvsTiaPair: '',
      };
    case Network.cronos_testnet:
    default:
      return {
        venoNft: '0xc7178d0c0c770b1505c3b1e741a5b2db8171b4ae',
        liquidCro: '0x612f914109fc24f3aaa200b27ee19c79a3426850',
        venoToken: '0x5024739e1db8d7d1d1d7d3ca06daae786c2f27f6',
        venoFountain: '0xe4fa4237b04934b71c9563119f19ec0c1b15c6f8',
        venoReservoir: '0xac9e4730340722540012aef8af3d7e0b74e52d70',
        feeDistributor: '0xd8a01dc0cde884ad3378f7dc155ed12acb0bcbe1',
        wcro: '0x6a3173618859C7cd40fAF6921b5E9eB6A76f1fD4',
        venoStorm: '0x56fefec44ec1915f33e1d355ebb2f22ff51de156',
        ferroLp: '0x23DdAC69D23bD95B3886AF5E0Fb2e1b1E8Dc3549',
        tLcro: '0x440aB4Cdb8A7C4D017700027C122D3E73A2e5e5b',
        /**
         * using this fork mainnet rpc to test: https://psta-forkenv.crorc.co/endpoints/580af827f1fa12d5
         * ask @zhiyong.hu for some CROs and ATOMs on the fork mainnet node
         */
        tLatom: '',
        cruiserRarityRegistry: '0x9C4CFEcb3812de48C03A295FE4efb29172C51CBe',
        ferroSwap: '0x3a8067b89547495fb96d16d08bcc3de839f1fb9f',
        vvsPair: '0x2541c15bc0C481dca40FD8a2289Fd4CE3FE0641A',
        vvsZap: '0x6e1308f8d2f2030667ccb7b83ed580d81405f318',
        vvsZapEstimator: '0x4a7b94205eaa1fe68fd51a5b76fd48b12733e671',
        venoStormMultiplierReader: '0x52765318f82b0e4048d121767a864281b9935c4a',
        cronosCruisers: '0x966F20db537c5239739CE57D079E6bC766632B2E',
        minerMoles: '0x55B7ebB858D5cD8b208eb79f6CB63A4f46DbD84A',
        boomerSquad: '0xe831d478cBd0427b633F78bB60aa21bb917AAf5F',
        ballies: '0x7ec2dbc9293c24addf381526745501470570ad92',
        topDogCorgi: '0x18bb56953cf56cc478bffab25184cca74b58d160',
        cronosChimp: '0x0cbf4646cfe50717ccefac075c272e7cdf1adb69',
        liquidAtom: '0xa90ee4bbe1d2a5b098f44d314d31d4764e8d1666',
        atomNft: '0x8f101084ca4ada1e2b039b869f7002c0e81413fa',
        atom: '0xadd62ff655c1e428040b38da93cf281c3cef39ea',
        ferroSwapAtom: '0xD9549ecf522a805af494d56eB03938B4870fA93e',
        ferroLpAtom: '0x9a749Df5136ECC2d1E76b7B7956d0bCFD4243dc2',
        vvsCroPair: '0x7e842971ad99cff1132ee996ec93952dd14b19dc',
        wcroDelegator: '0x89ccc2fee737eb9838aab4083675989148f01587',
        croDelegator: '0xc07a8accb05ee538cdd9bcd73427ccc9c3439c58',
        lcroDelegator: '0x655c5e4782f5a656228ce9f200fac8c47deddf55',
        ferroVault: '0xF67DcaE6B333a784c62a9EE77fa0e4178A9002de',
        liquidEth: '',
        tia: '0x69fA5FE14c1644399c4F70AF0e8df02408F24D14',
        tiaNft: '0x4c86f72904C95bcDC2CbdeeE606e40E565130b72',
        liquidTia: '0xf7a5C2efc578AC9ed8356314764981192Bc5049D',
        weth: '',
        vvsTiaPair: '0xe0A4F24658732ed30303c8D6A42A5E1824Fd3B1c',
      };
  }
};
