import { Button, DrawerBody, Flex, Image, Text } from '@chakra-ui/react';
import type { BigNumber } from '@ethersproject/bignumber';
import { useTranslations } from '@ui/i18n';
import { formatTokenAmount } from '@ui/utils/format';
import type { VaultType } from '@ui/views/Vno/ReservoirAndFountain/types';

import Balance from '../Balance';

interface VaultWithdrawCompletedContentProps {
  reward: BigNumber;
  allocation: BigNumber;
  type: VaultType;
  earlyWithdrawAmount?: BigNumber;
  isWithdrawEarly: boolean;
}
const VaultWithdrawCompletedContent: React.FC<
  VaultWithdrawCompletedContentProps
> = ({ reward, allocation, type, earlyWithdrawAmount, isWithdrawEarly }) => {
  const t = useTranslations();

  return (
    <DrawerBody>
      <Flex flexDir="column" gap={6} align="center" px={4}>
        <Text textStyle="h3">Transaction completed!</Text>
        <Balance
          title={t('Amount withdrawn')}
          icon={<Image alt="VNO" src="/tokens/vno.svg" />}
          amount={isWithdrawEarly ? earlyWithdrawAmount : allocation}
        />
        <Text color="text.light" align="center">
          {t('Also, {amount} {coin} has been harvested', {
            amount: formatTokenAmount(reward) || '',
            coin: type === 'FOUNTAIN' ? 'VNO' : 'WCRO',
          })}
        </Text>
      </Flex>
    </DrawerBody>
  );
};

const VaultWithdrawCompletedFooter: React.FC<{
  onCloseDrawer: () => void;
}> = ({ onCloseDrawer }) => {
  const t = useTranslations();
  return <Button onClick={onCloseDrawer}>{t('Done')}</Button>;
};

const VaultWithdrawCompleted = {
  Content: VaultWithdrawCompletedContent,
  Footer: VaultWithdrawCompletedFooter,
};

export default VaultWithdrawCompleted;
