import { BigNumber } from 'ethers';
import LiquidAtomModule from './liquidAtom';
import { getTiaUsdPrice } from './util';
export default class LiquidTiaModule extends LiquidAtomModule {
    constructor() {
        super(...arguments);
        this.contractAddress = this.sdk.addresses.liquidTia;
    }
    async getUsdWorth(amount) {
        if (amount.lte(0))
            return BigNumber.from(0);
        const [price, tiaPerLtia] = await Promise.all([
            getTiaUsdPrice(),
            this.atomPerLatom(),
        ]);
        if (!price)
            return BigNumber.from(0);
        const factor = 100000000000;
        return amount.mul(Math.round(factor * price * tiaPerLtia)).div(factor);
    }
}
