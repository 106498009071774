import type { BoxProps } from '@chakra-ui/react';
import { Box, Text } from '@chakra-ui/react';
import { useTranslations } from '@ui/i18n';
import type { ReactNode } from 'react';

export const NoteBox = ({ desc, ...props }: BoxProps & { desc: ReactNode }) => {
  const t = useTranslations();

  return (
    <Box
      p="10px 8px"
      backdropFilter="auto"
      backdropBlur="2px"
      bgColor="rgba(131, 133, 136, 0.4)"
      borderRadius="5px"
      {...props}
    >
      <Text
        as="span"
        color="brand-white"
        textStyle="bodyBold"
        mr="4px"
        _dark={{
          color: '#FECA47',
        }}
      >
        {t('Note')}:
      </Text>
      {desc}
    </Box>
  );
};
