import {
  AccountModal,
  useAccountModalStore,
} from '@ui/components/AccountModal';
import { FaqModal, useFaqModalStore } from '@ui/components/Faq';
import {
  NftClaimDrawer,
  NftDetailDrawer,
  useNftClaimDrawerStore,
  useNftDetailDrawerStore,
} from '@ui/components/NFTCard';
import {
  TransactionDetailDrawer,
  TransactionHistoryDrawer,
} from '@ui/components/TransactionHistoryDrawer';
import SupplyModal from '@ui/views/UseLcroDirectly/SupplyLcroModal';
import { useSupplyLcroModalStore } from '@ui/views/UseLcroDirectly/useSupplyLcroModalStore';
import {
  useVaultDetailDrawer,
  VaultDetailDrawer,
} from '@ui/views/Vno/ReservoirAndFountain';
import dynamic from 'next/dynamic';
import NextProgress from 'nextjs-progressbar';

import { AnnouncementDrawer } from './AnnouncementPopover';
import { useAnnouncementPopover } from './AnnouncementPopover/useAnnouncementPopover';
import { DisclaimerDrawer, useDisclaimerDrawer } from './DisclaimerDrawer';
import Features from './Features';
import { LangModal, useLangModal } from './LangMenu';
import { LazyComponent } from './LazyComponent';
import { RenderInMobile } from './MobileOrDesktop';
import { TopNavMenuDrawer, useTopNavMenuDrawer } from './TopNavMenuDrawer';
import { useStakingTourEnabled } from './Tours/utils/useStakingTourEnabled';
import { useTransactionHistoryDrawerStore } from './TransactionHistoryDrawer/TransactionHistoryDrawer';
import { TransakModal, useTransakModal } from './TransakModal';
import { useWalletModal, WalletModal } from './Wallet';

export const GlobalComponents = () => {
  const isAnnouncementOpen = useAnnouncementPopover((s) => s.isOpen);
  const isDisclaimerDrawerOpen = useDisclaimerDrawer((s) => s.isOpen);
  const isTopNavMenuDrawerOpen = useTopNavMenuDrawer((s) => s.isOpen);
  const isLangModalOpen = useLangModal((s) => s.isOpen);
  const isTransactionHistoryOpen = useTransactionHistoryDrawerStore(
    (s) => s.isOpen,
  );
  const nftClaimDrawerOpen = useNftClaimDrawerStore((s) => s.isOpen);
  const nftDetailDrawerOpen = useNftDetailDrawerStore((s) => s.isOpen);
  const transakModalOpen = useTransakModal((s) => s.isOpen);
  const vaultDetailDrawerOpen = useVaultDetailDrawer((s) => s.isOpen);
  const accountModalOpen = useAccountModalStore((s) => s.isOpen);
  const walletModalOpen = useWalletModal((s) => s.isOpen);
  const faqModalOpen = useFaqModalStore((s) => s.isOpen);
  const isSupplyLcroModalOpen = useSupplyLcroModalStore((s) => s.isOpen);

  const tourEnabled = useStakingTourEnabled();

  return (
    <>
      <NextProgress />
      <LazyComponent isOpen={walletModalOpen}>
        <WalletModal />
      </LazyComponent>
      <LazyComponent isOpen={accountModalOpen}>
        <AccountModal />
      </LazyComponent>
      <LazyComponent isOpen={vaultDetailDrawerOpen}>
        <VaultDetailDrawer />
      </LazyComponent>
      <LazyComponent isOpen={faqModalOpen}>
        <FaqModal />
      </LazyComponent>
      <LazyComponent isOpen={nftDetailDrawerOpen}>
        <NftDetailDrawer />
      </LazyComponent>
      <LazyComponent isOpen={nftClaimDrawerOpen}>
        <NftClaimDrawer />
      </LazyComponent>
      <LazyComponent isOpen={isTransactionHistoryOpen}>
        <TransactionHistoryDrawer />
      </LazyComponent>
      <TransactionDetailDrawer />
      <LazyComponent isOpen={transakModalOpen}>
        <TransakModal />
      </LazyComponent>
      <LazyComponent isOpen={isAnnouncementOpen}>
        <RenderInMobile>
          <AnnouncementDrawer />
        </RenderInMobile>
      </LazyComponent>
      <LazyComponent isOpen={isDisclaimerDrawerOpen}>
        <DisclaimerDrawer />
      </LazyComponent>
      <LazyComponent isOpen={isTopNavMenuDrawerOpen}>
        <RenderInMobile>
          <TopNavMenuDrawer />
        </RenderInMobile>
      </LazyComponent>
      <LazyComponent isOpen={isLangModalOpen}>
        <RenderInMobile>
          <LangModal />
        </RenderInMobile>
      </LazyComponent>
      <LazyComponent isOpen={isSupplyLcroModalOpen}>
        <SupplyModal />
      </LazyComponent>
      <Features.stakingTour>
        {tourEnabled && <LazyStakingTour />}
      </Features.stakingTour>
    </>
  );
};

const LazyStakingTour = dynamic(
  () => import('./Tours').then((mod) => mod.StakingTour),
  { ssr: false },
);
