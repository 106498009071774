import { VenoSDKError, VenoSDKErrorType } from './error';
import { MathError, TokenError, TokenFailureInfo } from './types';
export async function wrapSdkTransaction(contractTxPromise) {
    const tx = await contractTxPromise;
    const txHash = tx.hash;
    return {
        txHash,
        txReceiptPromise: executeTxEnsureSuccess(tx),
    };
}
async function executeTxEnsureSuccess(contractTxPromise) {
    let receipt;
    if (isPromise(contractTxPromise)) {
        receipt = await executeTx(contractTxPromise);
    }
    else {
        receipt = await contractTxPromise.wait();
    }
    const txEvents = receipt.events;
    if (!txEvents) {
        throw new VenoSDKError('This transaction is expected to emit events but it did not. Check params for sending the transaction.');
    }
    const failureEvent = txEvents.find((e) => e.event === 'Failure');
    if (failureEvent && failureEvent.args && failureEvent.args.length) {
        const code = TokenError[failureEvent.args[0]];
        const failureInfo = failureEvent.args.length > 1
            ? TokenFailureInfo[failureEvent.args[1]]
            : undefined;
        let rootCause;
        if (failureEvent.args.length &&
            failureEvent.args[0] == TokenError.MATH_ERROR) {
            rootCause = MathError[failureEvent.args[2]];
        }
        throw new VenoSDKError(`Receive Failure event but expect transaction to be successful. Error:${failureEvent.args}`, VenoSDKErrorType.TokenError, code, failureInfo, rootCause);
    }
    return receipt;
}
async function executeTx(contractTxPromise) {
    return contractTxPromise.then((tx) => tx.wait());
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isPromise(value) {
    return Boolean(value && typeof value.then === 'function');
}
const FETCH_URI = 'https://price-api.crypto.com/price/v1/currency/all';
export const getCroUsdPrice = async () => {
    const result = await fetch(FETCH_URI)
        .then((res) => res.json())
        .then(({ data }) => {
        const croPerUsd = data.crypto['CRO'];
        return 1 / croPerUsd;
    });
    return result;
};
export const getWcroUsdPrice = getCroUsdPrice;
const FETCH_URI_VVS = 'https://api.vvs.finance/info/api/tokens';
const getPriceFromVvs = async (tokenAddress) => {
    return fetch(FETCH_URI_VVS)
        .then((res) => res.json())
        .then(({ data }) => {
        const price = parseFloat(data[tokenAddress]?.price);
        if (price)
            return price;
        throw new Error('Could not fetch VNO asset price from vvs finance api');
    })
        .catch((err) => console.error(err));
};
export const getUsdPriceFromCdc = async (tokenHandle) => {
    const fetchResponse = await fetch(`https://price-api.crypto.com/price/v1/token-price/${tokenHandle}`);
    const response = (await fetchResponse.json());
    if (response.usd_price)
        return response.usd_price;
    throw new Error(`Could not fetch ${tokenHandle} asset price from price-api.crypto.com`);
};
export const getVnoUsdPrice = () => {
    return getUsdPriceFromCdc('veno-finance-vno');
};
export const getAtomUsdPrice = () => {
    return getUsdPriceFromCdc('cosmos');
};
export const getEthUsdPrice = () => {
    return getUsdPriceFromCdc('ethereum');
};
export const getTiaUsdPrice = () => {
    return getUsdPriceFromCdc('celestia');
};
export const getVvsUsdPrice = () => {
    return getPriceFromVvs('0x2D03bECE6747ADC00E1a131BBA1469C15fD11e03');
};
export const getLiquidTokenUsdPrice = (basicUsdPrice, basicPerLiquid) => {
    return basicUsdPrice * basicPerLiquid;
};
export const getPid = (chainType, pidType) => {
    let pidMap;
    if (chainType === 'zksync') {
        pidMap = pidMapMainnetZksync;
        if (process.env.NEXT_PUBLIC_DEFAULT_ZKSYNC_NETWORK === 'zksync_testnet') {
            pidMap = pidMapTestnetZksync;
        }
    }
    else {
        pidMap = pidMapMainnet;
        if (process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK === 'cronos_testnet') {
            pidMap = pidMapTestnet;
        }
        else if (process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK === 'veno_dryrun') {
            pidMap = pidMapDryrun;
        }
    }
    return pidMap[pidType];
};
export const getDecimalsForType = (type) => {
    return type === 'TECTONIC' ? 8 : 18;
};
export const getTokenContract = (collection, addresses) => {
    switch (collection) {
        case 'CRUISER':
            return addresses.cronosCruisers;
        case 'MOLE':
            return addresses.minerMoles;
        case 'BoomerSquad':
            return addresses.boomerSquad;
        case 'Ballies':
            return addresses.ballies;
        case 'TopDogCorgi':
            return addresses.topDogCorgi;
        case 'CronosChimp':
            return addresses.cronosChimp;
    }
};
const pidMapDryrun = {
    FERRO: 2,
    VVS: 3,
    VVS_CRO: 5,
    TECTONIC: 1,
    FERRO_ATOM: 4,
};
const pidMapTestnet = {
    FERRO: 1,
    VVS: 2,
    TECTONIC: 3,
    FERRO_ATOM: 4,
    VVS_CRO: 5,
    VVS_TIA: 6,
};
const pidMapMainnet = {
    FERRO: 1,
    VVS: 2,
    TECTONIC: 3,
    FERRO_ATOM: 4,
    VVS_CRO: 5,
    // TODO confirm
    VVS_TIA: 6,
};
const pidMapTestnetZksync = {
    // vno-eth-lp
    VVS: 1,
    // leth-eth-lp
    FERRO: 2,
};
/**
 * todo cover.lv need update
 */
const pidMapMainnetZksync = {
    // vno-eth-lp
    VVS: 2,
    // leth-eth-lp
    FERRO: 1,
};
