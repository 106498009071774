import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';

export const GardenBooster = (props: BoxProps) => {
  return (
    <Box
      p="2px 4px 4px"
      textStyle={{
        base: 'bodyBold',
        desktop: 'bodySmallBold',
      }}
      lineHeight={{ base: '17px', desktop: 'auto' }}
      borderColor="brand-green"
      rounded="2px"
      borderWidth="1px"
      color="white"
      bg="#4f775c"
      _dark={{
        borderColor: '#00FFCF',
        bg: 'rgba(0, 255, 83, 0.5)',
      }}
      {...props}
    >
      Boosted
    </Box>
  );
};
