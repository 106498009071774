import type { FlexProps } from '@chakra-ui/react';
import {
  Button,
  Flex,
  Icon,
  Skeleton,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import type { BigNumberish } from '@ethersproject/bignumber';
import { useTranslations } from '@ui/i18n';
import {
  useDepositDrawer,
  useVaultDetailDrawer,
  useVaultSelectionStore,
} from '@ui/views/Vno/ReservoirAndFountain';
import type {
  VaultInfo,
  VaultType,
} from '@ui/views/Vno/ReservoirAndFountain/types';
import { Locked, Unlocked } from '@veno-app/icons';
import type { Stake } from '@veno-app/sdk';
import React, { useCallback, useMemo } from 'react';

import { formatNumber, formatTokenAmount } from '../../utils/format';
import { formatLockPeriod } from '../../utils/formatLockPeriod';
import { Card } from '../Card';
import { InDesktop } from '../MobileOrDesktop';
import { RotatingBg } from '../RotatingBg';
import type { VaultStatus } from '.';

type VaultImageProps = {
  status: VaultStatus;
  period: string;
  size?: string;
  innerSize?: string;
} & FlexProps;
export const VaultImage: React.FC<VaultImageProps> = ({
  status,
  period,
  innerSize,
  size,
  ...props
}) => {
  return (
    <Flex
      textAlign="center"
      direction="column"
      justify="center"
      align="center"
      minH={size}
      w={size}
      rounded="full"
      shadow={status === 'INACTIVE' ? 'card.normal' : 'card.active'}
      position="relative"
      background={
        status === 'INACTIVE'
          ? 'linear-gradient(135.52deg, #EBF0F3 14.99%, #D6E0E7 84.37%)'
          : 'transparent'
      }
      flexShrink="0"
      overflow="hidden"
      {...props}
    >
      {status !== 'INACTIVE' && (
        <RotatingBg boxSize={innerSize} position="absolute" zIndex={1} />
      )}
      {status === 'LOCKED' && (
        <Icon as={Locked} color="text.active" zIndex={2} />
      )}
      {status === 'UNLOCKED' && (
        <Icon as={Unlocked} color="text.active" zIndex={2} />
      )}
      <Text
        color={status === 'INACTIVE' ? 'text.disabled' : 'text.normal'}
        zIndex={2}
        mx="4px"
      >
        {period}
      </Text>
    </Flex>
  );
};

type VaultCardInfoProps = {
  name: string;
  value: string | null;
};
export const VaultCardInfo: React.FC<VaultCardInfoProps> = ({
  name,
  value,
}) => (
  <Flex gap={1}>
    <Text textStyle="bodySmall" color="text.normal">
      {name}
    </Text>
    {value ? (
      <Text textStyle="bodySmallBold" color="text.normal" wordBreak="break-all">
        {value}
      </Text>
    ) : (
      <Skeleton w="36px" />
    )}
  </Flex>
);

type VaultCardProps = {
  pid: number;
  lockPeriod: number;
  apr: number | null;
  multiplier: number;
  lockedStakes: Stake[];
  unlockedStakes: Stake[];
  allocation: BigNumberish;
  type: VaultType;
};
const VaultCard: React.FC<VaultCardProps> = (props) => {
  const t = useTranslations();

  const {
    lockPeriod,
    apr,
    multiplier,
    lockedStakes,
    unlockedStakes,
    allocation,
    type,
  } = props;
  const onVaultSelect = useVaultSelectionStore((s) => s.onVaultSelect);
  const onVaultDetailDrawerOpen = useVaultDetailDrawer((s) => s.onOpen);
  const onOpenDepositDrawer = useDepositDrawer((s) => s.onOpen);
  const isDesktop = useBreakpointValue({
    base: false,
    desktop: true,
  });

  const status = useMemo<VaultStatus>(() => {
    if (lockedStakes.length === 0 && unlockedStakes.length === 0)
      return 'INACTIVE';
    return unlockedStakes.length > 0 ? 'UNLOCKED' : 'LOCKED';
  }, [lockedStakes, unlockedStakes]);

  const vault = useMemo<VaultInfo>(() => {
    const stakeCount = props.unlockedStakes.length + props.lockedStakes.length;
    return {
      ...props,
      status,
      stakeCount,
    };
  }, [props, status]);

  const lockPeriodString = formatLockPeriod(lockPeriod, { roughly: true });

  const onView = useCallback(() => {
    onVaultSelect(vault);

    if (!isDesktop) onVaultDetailDrawerOpen(type);
  }, [isDesktop, onVaultDetailDrawerOpen, onVaultSelect, type, vault]);

  return (
    <Card
      as={Flex}
      py={4}
      m={0}
      mx={0}
      mb={{ base: '16px', desktop: 0 }}
      px={3}
      gap={6}
      onClick={!isDesktop ? onView : undefined}
      flexDir={{ base: 'row', desktop: 'column' }}
      flex={1}
      position="relative"
      alignItems={{ base: 'center', desktop: 'space-between' }}
    >
      <VaultImage
        status={status}
        period={lockPeriodString}
        alignSelf="center"
        size="100px"
        innerSize="96px"
      />
      <Flex direction="column" gap={3} w={{ desktop: 'full' }}>
        <Flex gap={3} justify="space-between" flexWrap="wrap">
          <VaultCardInfo
            name="APR"
            value={apr !== null ? `${formatNumber(apr * 100, 2)}%` : '--%'}
          />
          <VaultCardInfo
            name={t('Multiplier')}
            value={`${(multiplier / 100).toFixed(0)}x`}
          />
        </Flex>
        <Flex
          gap={3}
          justify="space-between"
          flexDir={{ base: 'column', desktop: 'row' }}
          flexWrap="wrap"
        >
          <VaultCardInfo
            name={t('Deposits')}
            value={String(lockedStakes.length + unlockedStakes.length)}
          />
          <VaultCardInfo
            name="VNO"
            value={formatTokenAmount(allocation, 2) ?? '0'}
          />
        </Flex>
        <Flex
          justify={{
            base: 'flex-start',
            desktop: 'space-around',
          }}
        >
          <Button
            variant="primary"
            onClick={(e) => {
              onOpenDepositDrawer({
                vaultInfo: vault,
              });
              e.stopPropagation();
            }}
          >
            {t('Deposit')}
          </Button>
          <InDesktop>
            <Button variant="secondary" onClick={onView}>
              {t('View')}
            </Button>
          </InDesktop>
        </Flex>
      </Flex>
    </Card>
  );
};

export default VaultCard;
