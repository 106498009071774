import type { ModalBodyProps } from '@chakra-ui/react';
import { Box, ModalBody } from '@chakra-ui/react';
import React from 'react';

export type ModalBodyWithMaskProps = ModalBodyProps & {
  maskHeight?: number;
};
export const ModalBodyWithMask = ({
  children,
  maskHeight = 30,
  ...props
}: ModalBodyWithMaskProps) => {
  return (
    <ModalBody
      {...props}
      className={`${props.className || ''} no-scrollbar`}
      position="relative"
      pb="0"
    >
      {children}
      <Box
        pointerEvents="none"
        position="sticky"
        bottom="0"
        left="0"
        right="0"
        h={`${maskHeight}px`}
        bgGradient="linear(to-b, rgba(0,0,0,0) 0%, bg.card 100%)"
      />
    </ModalBody>
  );
};

ModalBodyWithMask.displayName = 'ModalBodyWithMask';
