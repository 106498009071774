import type { DrawerProps } from '@chakra-ui/react';
import {
  Button,
  DrawerBody,
  DrawerFooter,
  Flex,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { pathDict } from '@ui/config/paths';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { COIN_MODE } from '@ui/hooks/useCoinMode';
import { useTranslations } from '@ui/i18n';
import NextLink from '@ui/overrides/next/link';

import { GetAssetByStakingContent } from './GetAssetByStaking';
import { GetAssetDrawer } from './GetAssetDrawer';
import { GetAssetOnVvsContent } from './GetAssetOnVvs';

export const CroDrawer = (props: Omit<DrawerProps, 'children'>) => {
  return (
    <GetAssetDrawer tokenName="CRO" {...props}>
      <CroDrawerContent />
    </GetAssetDrawer>
  );
};

export const CroDrawerContent = () => {
  const t = useTranslations();
  return (
    <>
      <DrawerBody>
        <Flex direction="column" alignItems="center">
          <Image alt="logo" src="/tokens/cro.svg" w="50px" h="50px" />
          <Text textAlign="center" color="text-light" mt="10px">
            {t('buy-direction-{platform}-{coin}', {
              platform: 'Ferro',
              coin: 'CRO',
            })}
          </Text>
          <Text
            textStyle="bodySmall"
            textAlign="center"
            color="text-light"
            mt="20px"
          >
            {t('buy-coin-risk-{platform}', {
              platform: 'Ferro',
            })}
            <br />
            {t('More info can be found here {Link}', {
              Link: (chunks) => (
                <Link isExternal href={EXTERNAL_LINK.ferro} color="text-link">
                  {chunks}
                </Link>
              ),
            })}
          </Text>
        </Flex>
      </DrawerBody>
      <DrawerFooter>
        <Button
          as="a"
          flexGrow="1"
          href={EXTERNAL_LINK.ferroSwapToCro}
          target="_blank"
        >
          {t('Confirm')}
        </Button>
      </DrawerFooter>
    </>
  );
};

export const LcroDrawer = (props: Omit<DrawerProps, 'children'>) => {
  return (
    <GetAssetDrawer tokenName="LCRO" {...props}>
      <LcroDrawerContent />
    </GetAssetDrawer>
  );
};

export const LcroDrawerContent = () => {
  const t = useTranslations();
  const coinMode: CoinMode = 'cro';
  const coinModeInfo = COIN_MODE[coinMode];
  return (
    <>
      <DrawerBody>
        <Flex direction="column" alignItems="center">
          <coinModeInfo.LiquidIcon w="50px" h="50px" />
          <Text textAlign="center" color="text-light" mt="10px">
            {t('buy-lcro-desc-1')}
          </Text>
          <Text
            textStyle="bodySmall"
            textAlign="center"
            color="text-light"
            mt="20px"
          >
            {t('buy-coin-risk-{platform}', {
              platform: 'Ferro',
            })}
            <br />
            {t('More info can be found here {Link}', {
              Link: (chunks) => (
                <Link isExternal href={EXTERNAL_LINK.ferro} color="text-link">
                  {chunks}
                </Link>
              ),
            })}
          </Text>
        </Flex>
      </DrawerBody>
      <DrawerFooter>
        <Flex flexGrow="1" direction="column" gap="20px">
          <NextLink href={pathDict[coinMode].stake} passHref>
            <Button as="a">
              {t('Stake your {coin}', {
                coin: coinModeInfo.name,
              })}
            </Button>
          </NextLink>
          <Link
            href={EXTERNAL_LINK.ferroSwapToLcro}
            isExternal
            alignSelf="center"
          >
            {t('Buy {coin} on {platform}', {
              coin: coinModeInfo.liquidName,
              platform: 'Ferro',
            })}
          </Link>
        </Flex>
      </DrawerFooter>
    </>
  );
};

export const LtiaDrawer = (props: Omit<DrawerProps, 'children'>) => {
  return (
    <GetAssetDrawer tokenName="LTIA" {...props}>
      <GetAssetByStakingContent i18nkey="buy-ltia-desc" coinMode="tia" />
    </GetAssetDrawer>
  );
};

export const LatomDrawer = (props: Omit<DrawerProps, 'children'>) => {
  return (
    <GetAssetDrawer tokenName="LATOM" {...props}>
      <GetAssetByStakingContent i18nkey="buy-latom-desc" coinMode="atom" />
    </GetAssetDrawer>
  );
};

export const TiaDrawer = (props: Omit<DrawerProps, 'children'>) => {
  return (
    <GetAssetDrawer tokenName="VNO" {...props}>
      <GetAssetOnVvsContent
        tokenLogoSrc="/tokens/tia.svg"
        tokenName="TIA"
        swapLink={EXTERNAL_LINK.vvsTia}
      />
    </GetAssetDrawer>
  );
};

export const VnoDrawer = (props: Omit<DrawerProps, 'children'>) => {
  return (
    <GetAssetDrawer tokenName="VNO" {...props}>
      <GetAssetOnVvsContent
        tokenLogoSrc="/tokens/vno.svg"
        tokenName="VNO"
        swapLink={EXTERNAL_LINK.vvs}
      />
    </GetAssetDrawer>
  );
};

export const AtomDrawer = (props: Omit<DrawerProps, 'children'>) => {
  return (
    <GetAssetDrawer tokenName="ATOM" {...props}>
      <GetAssetOnVvsContent
        tokenLogoSrc="/tokens/atom.svg"
        tokenName="ATOM"
        swapLink={EXTERNAL_LINK.vvsSwapCroToAtom}
      />
    </GetAssetDrawer>
  );
};
