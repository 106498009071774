import type { FlexProps } from '@chakra-ui/react';
import { Flex, Icon, Text } from '@chakra-ui/react';
import { BuyCoin, Claim, GetCoin } from '@veno-app/icons';
import React from 'react';

export type AccountModalActionButtonType = 'claim' | 'get' | 'buy';

type AccountModalActionButtonProps = {
  action: () => void;
  type: AccountModalActionButtonType;
} & FlexProps;

const AccountModalActionButton: React.FC<AccountModalActionButtonProps> = ({
  action,
  type,
  ...flexProps
}) => {
  const icon = type === 'claim' ? Claim : type === 'get' ? GetCoin : BuyCoin;
  const text = type === 'claim' ? 'CLAIM' : type === 'get' ? 'GET' : 'BUY';

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      color="text.link"
      cursor="pointer"
      onClick={action}
      {...flexProps}
    >
      <Icon w="24px" h="24px" as={icon} />
      <Text fontSize="8px" fontWeight="800" textTransform="uppercase">
        {text}
      </Text>
    </Flex>
  );
};

export default AccountModalActionButton;
