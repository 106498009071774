export const TRANSACTION_TYPE_FERRO_IN = 'LP Ferro In';
export const TRANSACTION_TYPE_FERRO_OUT = 'LP Ferro Out';
export const TRANSACTION_TYPE_VVS_IN = 'LP VVS In';
export const TRANSACTION_TYPE_VVS_OUT = 'LP VVS Out';
export const TRANSACTION_TYPE_REDEEM = 'Redeem';
export const TRANSACTION_TYPE_STAKE = 'Stake';
export const TRANSACTION_TYPE_TECTONIC_IN = 'Tectonic Supply In';
export const TRANSACTION_TYPE_TECTONIC_OUT = 'Tectonic Supply Out';
export const TRANSACTION_TYPE_TRANSFER = 'Transfer';
export const TRANSACTION_TYPE_UNSTAKE = 'Unstake';

export const TRANSACTION_TYPES_CRO_NEGATIVE = [
  TRANSACTION_TYPE_STAKE,
  TRANSACTION_TYPE_FERRO_IN,
  TRANSACTION_TYPE_VVS_IN,
];
export const TRANSACTION_TYPES_LCRO_NEGATIVE = [
  // TRANSACTION_TYPE_UNSTAKE,
  TRANSACTION_TYPE_TECTONIC_IN,
  TRANSACTION_TYPE_FERRO_IN,
  TRANSACTION_TYPE_VVS_IN,
];

export const TRANSACTION_TYPES_LP_NEGATIVE = [
  TRANSACTION_TYPE_FERRO_OUT,
  TRANSACTION_TYPE_VVS_OUT,
];

export const TRANSACTION_TYPES_LP = [
  TRANSACTION_TYPE_FERRO_OUT,
  TRANSACTION_TYPE_VVS_OUT,
  TRANSACTION_TYPE_FERRO_IN,
  TRANSACTION_TYPE_VVS_IN,
];

export const TRANSACTION_TYPES_TLCRO_NEGATIVE = [TRANSACTION_TYPE_TECTONIC_OUT];
