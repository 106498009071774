import { Box, Divider, SimpleGrid, Skeleton, Text } from '@chakra-ui/react';
import type { BigNumber } from '@ethersproject/bignumber';
import { useTranslations } from '@ui/i18n';
import { formatTokenAmount } from '@ui/utils/format';
import type { Stake } from '@veno-app/sdk';
import { format } from 'date-fns';
import React from 'react';

import type { ReservoirOrFountainPool } from './types';

type ConfirmLockProps = {
  selectedStakes: Stake[];
  usdValues?: BigNumber[];
  targetPool: ReservoirOrFountainPool | null;
};

const DepositUpgradeConfirmLock = ({
  selectedStakes,
  usdValues,
  targetPool,
}: ConfirmLockProps) => {
  const t = useTranslations();
  return (
    <>
      <Text textStyle="h4" mb={5}>
        {t("You'll lock")}
      </Text>
      {selectedStakes.map((s, i) => (
        <React.Fragment key={Number(s.stakeId)}>
          {i > 0 ? <Divider borderColor="shadow" /> : null}
          <SimpleGrid columns={2} gap={10} py={3}>
            <Text>{t('Deposit')}</Text>
            <Box textAlign="end">
              <Text textStyle="bodyBold">
                {formatTokenAmount(s.amount, 2)} VNO
              </Text>
              {usdValues && usdValues[i] ? (
                <Text textStyle="bodySmall" color="text.light">
                  {formatTokenAmount(usdValues ? usdValues[i] : '', 2)} USD
                </Text>
              ) : (
                <Skeleton h="14px" w="100px" />
              )}
            </Box>
            <Text>{t('Lock end date')}</Text>
            {targetPool && (
              <Text align="end" textStyle="bodyBold">
                {format(
                  s.stakeTimestamp
                    .add(targetPool.lockPeriod ?? 0)
                    .mul(1000)
                    .toNumber(),
                  'dd MMM yyyy',
                )}
              </Text>
            )}
          </SimpleGrid>
        </React.Fragment>
      ))}
    </>
  );
};

export default DepositUpgradeConfirmLock;
