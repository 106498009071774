import { Button, Flex, Link } from '@chakra-ui/react';
import { RenderInMobile } from '@ui/components/MobileOrDesktop';
import { ModalOrDrawerBodyWithMask } from '@ui/components/ModalOrDrawer';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { useTranslations } from '@ui/i18n';
import type { ChainId } from '@veno-app/wallet';
import {
  currentWallet,
  getNetworkConfig,
  ZKSYNC_ENABLED,
} from '@veno-app/wallet';
import { useMemo, useState } from 'react';
import { useScrollContainer } from 'react-indiana-drag-scroll';

import { useDisclaimerDrawer } from '../DisclaimerDrawer';
import { ProtocolCard } from './ProtocolCard';
import { useUseYourTokenDrawer } from './useUseYourTokenDrawer';

export const DefaultContent = () => {
  const t = useTranslations();
  const scrollContainer = useScrollContainer();
  const openDisclaimerDrawer = useDisclaimerDrawer((s) => s.onOpen);
  const title = useUseYourTokenDrawer((s) => s.title);
  const protocols = useUseYourTokenDrawer((s) => s.protocols);
  const isZksync = currentWallet.useIsZksyncChainId();
  const [chainFilter, setChainFilter] = useState<ChainId | null>(
    // By default the list is filtered in the current network
    getNetworkConfig(isZksync).chainId,
  );

  const isShowFilterTabs =
    ZKSYNC_ENABLED && protocols.some((v) => Boolean(v.chainIds?.length));

  const filteredProtocols = useMemo(() => {
    return chainFilter
      ? protocols.filter((v) => !v.chainIds || v.chainIds.includes(chainFilter))
      : protocols;
  }, [chainFilter, protocols]);

  return (
    <ModalOrDrawerBodyWithMask maskHeight={60} pt="0">
      <Flex
        ref={scrollContainer.ref}
        hidden={!isShowFilterTabs}
        className="no-scrollbar"
        gap="16px"
        flexWrap="nowrap"
        overflowX="auto"
        mx="-16px"
        mb="16px"
        px="16px"
        py="16px"
        bg="brand-background"
        position="sticky"
        top="0"
        left="0"
        zIndex="1"
      >
        <Button
          variant={!chainFilter ? 'primary' : 'secondary'}
          size="sm"
          onClick={() => {
            setChainFilter(null);
          }}
          flexShrink="0"
        >
          {t('All Networks')}
        </Button>
        {[getNetworkConfig(), getNetworkConfig(true)].map((network, i) => {
          return (
            <Button
              key={i}
              variant={
                network.chainId === chainFilter ? 'primary' : 'secondary'
              }
              size="sm"
              onClick={() => {
                setChainFilter(network.chainId);
              }}
              flexShrink="0"
            >
              {network.chainName}
            </Button>
          );
        })}
      </Flex>
      {filteredProtocols.map((protocol, i) => {
        return (
          <ProtocolCard
            key={i}
            mt="16px"
            {...protocol}
            onClick={() => {
              openDisclaimerDrawer({
                url: protocol.url,
                title,
                DrawerContainer: RenderInMobile,
              });
            }}
          />
        );
      })}
      <Flex w="full" justify="center">
        <Link href={EXTERNAL_LINK.listingForm} isExternal>
          <Button mt={4} mb={4} variant="link">
            {t('List your protocol')}
          </Button>
        </Link>
      </Flex>
    </ModalOrDrawerBodyWithMask>
  );
};
