import { BigNumber } from '@ethersproject/bignumber';
import { useQuery } from '@tanstack/react-query';
import { getWcroUsdPrice } from '@ui/queries/prices';

export const useWcroUsdValue = <T extends BigNumber | number | null>(
  wcroValue?: T,
) => {
  const usdPriceQuery = getWcroUsdPrice();
  const usdPriceResult = useQuery(
    usdPriceQuery.queryKey,
    usdPriceQuery.queryFn,
    {
      staleTime: 20_000,
      enabled: wcroValue !== undefined,
    },
  );

  let usdPrice: T | undefined = undefined;

  if (usdPriceResult.data && wcroValue) {
    usdPrice = (
      typeof wcroValue === 'number'
        ? usdPriceResult.data * wcroValue
        : BigNumber.from(Math.round(usdPriceResult.data * 100_000_000))
            .mul(wcroValue)
            .div(100_000_000)
    ) as T;
  }

  return { ...usdPriceResult, data: usdPrice };
};
