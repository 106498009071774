import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { getTLcroBalance } from '@ui/queries/sdk';
import { currentWallet } from '@veno-app/wallet';

const { useAccount } = currentWallet;

const useTLcroBalance = (
  options?: Partial<Pick<UseQueryOptions, 'enabled'>>,
  address?: string,
) => {
  const sdk = useVenoSDK();
  const userAccount = useAccount();
  const account = address ?? userAccount;
  const query = getTLcroBalance(sdk, account ?? '');
  const { data: tlcroBalance, isLoading } = useQuery(
    query.queryKey,
    query.queryFn,
    {
      ...options,
      enabled:
        !!account && (options?.enabled === undefined || options?.enabled),
    },
  );

  return {
    loading: isLoading,
    tlcroBalance,
  };
};

export default useTLcroBalance;
