import { Box, DarkMode, LightMode } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import merge from 'lodash/merge';
// eslint-disable-next-line unused-imports/no-unused-imports
import React, { forwardRef } from 'react';
import { breakpoints } from '../breakpoints';
import { colors } from '../colors';
import { addAlpha } from './colors';
export const getChakraVar = (type, name) => {
    return `--chakra-${type}-${name.replace(/\.+/g, '-')}`;
};
export const getChakraVarExpression = (type, name) => {
    return `var(${getChakraVar(type, name)})`;
};
/**
 * only in mobile breakpoint
 */
export const inMobileSelector = `@media(max-width:${breakpoints.desktop})`;
/**
 * focusing the light mode
 * @see https://github.com/chakra-ui/chakra-ui/issues/6916#issuecomment-1304080946
 */
export const InLight = forwardRef((props, ref) => (React.createElement(LightMode, null,
    React.createElement(Box, { ref: ref, color: "text-normal", ...props, display: "contents" }))));
InLight.displayName = 'InLight';
/**
 * focusing the dark mode
 * @see https://github.com/chakra-ui/chakra-ui/issues/6916#issuecomment-1304080946
 */
export const InDark = forwardRef((props, ref) => (React.createElement(DarkMode, null,
    React.createElement(Box, { ref: ref, color: "text-normal", ...props, display: "contents" }))));
InDark.displayName = 'InDark';
export const InColorMode = forwardRef(({ colorMode, ...props }, ref) => colorMode === 'dark' ? (React.createElement(DarkMode, null,
    React.createElement(Box, { ref: ref, color: "text-normal", ...props, display: "contents" }))) : (React.createElement(LightMode, null,
    React.createElement(Box, { ref: ref, color: "text-normal", ...props, display: "contents" }))));
InColorMode.displayName = 'InColorMode';
export var ColorThemeVars;
(function (ColorThemeVars) {
    ColorThemeVars["mainColor"] = "--color-theme-main-color";
    ColorThemeVars["mainColorLight"] = "--color-theme-main-color-light";
    ColorThemeVars["mainColorElevate"] = "--color-theme-main-color-elevate";
    ColorThemeVars["tabColor"] = "--color-theme-tab-color";
    ColorThemeVars["tabColorActive"] = "--color-theme-tab-color-active";
    ColorThemeVars["tabColorActiveDark"] = "--color-theme-tab-color-active_dark";
    ColorThemeVars["shadow"] = "--color-theme-shadow";
    ColorThemeVars["shadowActive"] = "--color-theme-shadow-active";
    ColorThemeVars["smallShadow"] = "--color-theme-small-shadow";
    ColorThemeVars["smallShadowActive"] = "--color-theme-small-shadow-active";
    ColorThemeVars["accent"] = "--color-theme-accent";
    ColorThemeVars["parentMainColor"] = "--color-theme-parent-main-color";
    ColorThemeVars["parentMainColorLight"] = "--color-theme-parent-main-color-light";
    ColorThemeVars["parentTabColor"] = "--color-theme-parent-tab-color";
    ColorThemeVars["parentTabColorActive"] = "--color-theme-parent-tab-color-active";
    ColorThemeVars["parentShadow"] = "--color-theme-parent-shadow";
    ColorThemeVars["parentShadowActive"] = "--color-theme-parent-shadow-active";
    ColorThemeVars["parentSmallShadow"] = "--color-theme-parent-small-shadow";
    ColorThemeVars["parentSmallShadowActive"] = "--color-theme-parent-small-shadow-active";
    ColorThemeVars["highlight"] = "--color-theme-highlight";
    ColorThemeVars["parentHighlight"] = "--color-theme-parent-highlight";
})(ColorThemeVars || (ColorThemeVars = {}));
const DefaultColorThemeConfig = {
    mainColor: getChakraVarExpression('colors', 'bg-main'),
    mainColorLight: getChakraVarExpression('colors', 'bg-main'),
    mainColorElevate: getChakraVarExpression('colors', 'bg-main'),
    tabColor: getChakraVarExpression('colors', 'text-inactive'),
    tabColorActive: getChakraVarExpression('colors', 'text-active'),
    shadow: getChakraVarExpression('shadows', 'card.normal'),
    shadowActive: getChakraVarExpression('shadows', 'card.active'),
    smallShadow: getChakraVarExpression('shadows', 'smallCard.normal'),
    smallShadowActive: getChakraVarExpression('shadows', 'smallCard.active'),
    highlight: getChakraVarExpression('colors', 'garden-highlight'),
    accent: getChakraVarExpression('colors', 'brand-pink'),
};
const injectColorThemeVars = ({ isRoot, css, ...props }, options) => {
    const variablesForParent = {
        [ColorThemeVars.parentMainColor]: `var(${ColorThemeVars.mainColor}, ${DefaultColorThemeConfig.mainColor})`,
        [ColorThemeVars.parentTabColor]: `var(${ColorThemeVars.tabColor}, ${DefaultColorThemeConfig.tabColor})`,
        [ColorThemeVars.parentTabColorActive]: `var(${ColorThemeVars.tabColorActive}, ${DefaultColorThemeConfig.tabColorActive})`,
        [ColorThemeVars.parentShadow]: `var(${ColorThemeVars.shadow}, ${DefaultColorThemeConfig.shadow})`,
        [ColorThemeVars.parentShadowActive]: `var(${ColorThemeVars.shadowActive}, ${DefaultColorThemeConfig.shadowActive})`,
        [ColorThemeVars.parentSmallShadow]: `var(${ColorThemeVars.smallShadow}, ${DefaultColorThemeConfig.smallShadow})`,
        [ColorThemeVars.parentSmallShadowActive]: `var(${ColorThemeVars.smallShadowActive}, ${DefaultColorThemeConfig.smallShadowActive})`,
        [ColorThemeVars.parentHighlight]: `var(${ColorThemeVars.highlight}, ${DefaultColorThemeConfig.highlight})`,
    };
    const variablesForChildren = options
        ? {
            [ColorThemeVars.mainColor]: options.mainColor,
            [ColorThemeVars.mainColorLight]: options.mainColorLight,
            [ColorThemeVars.mainColorElevate]: options.mainColorElevate,
            [ColorThemeVars.tabColor]: options.tabColor,
            [ColorThemeVars.tabColorActive]: options.tabColorActive,
            [ColorThemeVars.shadow]: options.shadow,
            [ColorThemeVars.shadowActive]: options.shadowActive,
            [ColorThemeVars.smallShadow]: options.smallShadow,
            [ColorThemeVars.smallShadowActive]: options.smallShadowActive,
            [ColorThemeVars.highlight]: options.highlight,
            [ColorThemeVars.accent]: options.accent,
        }
        : {};
    return {
        ...props,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        'data-color-theme-root': isRoot ? true : undefined,
        css: merge(css, variablesForParent, 
        /**
         * if isRoot = true, inject variables in the current scope
         * if not, inject variables in the children scope
         */
        isRoot
            ? variablesForChildren
            : {
                '& > *:not([data-color-theme-root])': variablesForChildren,
            }),
    };
};
export const ColorThemeProvider = (props) => {
    const isRoot = true;
    return (React.createElement(React.Fragment, null,
        React.createElement(Global, { styles: {
                html: injectColorThemeVars({
                    isRoot,
                }, DefaultColorThemeConfig).css,
            } }),
        React.createElement(InDefault, { ...props, isRoot: isRoot })));
};
/**
 * focusing the inherit style
 * @private
 */
const InInherit = forwardRef((props, ref) => (React.createElement(Box, { ref: ref, "data-color-theme": "inherit", ...injectColorThemeVars(props), display: "contents" })));
InInherit.displayName = 'InInherit';
/**
 * focusing the default style
 */
export const inDefaultSelector = '[data-color-theme="default"] &';
export const InDefault = forwardRef((props, ref) => (React.createElement(InLight, { ref: ref, "data-color-theme": "default", ...injectColorThemeVars(props, DefaultColorThemeConfig) })));
InDefault.displayName = 'InDefault';
/**
 * focusing the garden style
 */
export const inGardenSelector = '[data-color-theme="garden"] &';
export const InGarden = forwardRef((props, ref) => (React.createElement(InLight, { ref: ref, "data-color-theme": "garden", ...injectColorThemeVars(props, {
        mainColor: getChakraVarExpression('colors', 'bg-garden'),
        mainColorLight: getChakraVarExpression('colors', 'garden-light'),
        mainColorElevate: getChakraVarExpression('colors', 'bg-garden'),
        tabColor: getChakraVarExpression('colors', 'text-inactive'),
        tabColorActive: getChakraVarExpression('colors', 'text-active'),
        shadow: getChakraVarExpression('shadows', 'bigGarden.normal'),
        shadowActive: getChakraVarExpression('shadows', 'bigGarden.active'),
        smallShadow: getChakraVarExpression('shadows', 'garden.normal'),
        smallShadowActive: getChakraVarExpression('shadows', 'garden.active'),
        highlight: getChakraVarExpression('colors', 'garden.highlight'),
        accent: getChakraVarExpression('colors', 'brand-pink'),
    }) })));
InGarden.displayName = 'InGarden';
/**
 * focusing the atom style
 */
export const inAtomSelector = '[data-color-theme="atom"] &';
export const InAtom = forwardRef((props, ref) => (React.createElement(InDark, { ref: ref, "data-color-theme": "atom", ...injectColorThemeVars(props, {
        mainColor: getChakraVarExpression('colors', 'bg-atom'),
        mainColorLight: getChakraVarExpression('colors', 'brand-atomMainColorLight'),
        mainColorElevate: getChakraVarExpression('colors', 'bg-atom'),
        tabColor: getChakraVarExpression('colors', 'brand-pinkLight'),
        tabColorActive: getChakraVarExpression('colors', 'brand-pinkInDark'),
        shadow: getChakraVarExpression('shadows', 'atom.normal'),
        shadowActive: getChakraVarExpression('shadows', 'atom.active'),
        smallShadow: getChakraVarExpression('shadows', 'smallAtom.normal'),
        smallShadowActive: getChakraVarExpression('shadows', 'smallAtom.active'),
        highlight: getChakraVarExpression('colors', 'garden.highlight'),
        accent: getChakraVarExpression('colors', 'brand-pink'),
    }) })));
InAtom.displayName = 'InAtom';
/**
 * focusing the tia style
 */
export const inTiaSelector = '[data-color-theme="tia"] &';
export const InTia = forwardRef((props, ref) => (React.createElement(InDark, { ref: ref, "data-color-theme": "tia", ...injectColorThemeVars(props, {
        mainColor: getChakraVarExpression('colors', 'bg-tia'),
        mainColorLight: getChakraVarExpression('colors', 'brand-tiaMainColorLight'),
        mainColorElevate: getChakraVarExpression('colors', 'brand-tiaMainColorLight'),
        tabColor: getChakraVarExpression('colors', 'brand-tiaTab'),
        tabColorActive: getChakraVarExpression('colors', 'brand-tiaTabActive'),
        shadow: getChakraVarExpression('shadows', 'tia.normal'),
        shadowActive: getChakraVarExpression('shadows', 'tia.active'),
        smallShadow: getChakraVarExpression('shadows', 'smallTia.normal'),
        smallShadowActive: getChakraVarExpression('shadows', 'smallTia.active'),
        highlight: getChakraVarExpression('colors', 'brand-tiaTabActive'),
        accent: getChakraVarExpression('colors', 'brand-white'),
    }) })));
InTia.displayName = 'InTia';
/**
 * focusing the eth style
 */
export const inEthSelector = '[data-color-theme="eth"] &';
export const InEth = forwardRef((props, ref) => (React.createElement(InLight, { ref: ref, "data-color-theme": "eth", ...injectColorThemeVars(props, {
        mainColor: '#C7EAF9',
        mainColorLight: '#D8F0FB',
        mainColorElevate: '#C7EAF9',
        tabColor: '#6F609D',
        tabColorActive: '#1D0063',
        shadow: getChakraVarExpression('shadows', 'eth.normal'),
        shadowActive: getChakraVarExpression('shadows', 'eth.active'),
        smallShadow: getChakraVarExpression('shadows', 'smallEth.normal'),
        smallShadowActive: getChakraVarExpression('shadows', 'smallEth.active'),
        highlight: getChakraVarExpression('colors', 'garden.highlight'),
        accent: getChakraVarExpression('colors', 'brand-pink'),
    }) })));
InEth.displayName = 'InEth';
/**
 * focusing the gauze style
 */
export const inGauzeSelector = '[data-color-theme="gauze"] &';
export const InGauze = forwardRef((props, ref) => (React.createElement(InDark, { ref: ref, "data-color-theme": "gauze", ...injectColorThemeVars(props, {
        mainColor: getChakraVarExpression('colors', 'bg-gauze'),
        mainColorLight: getChakraVarExpression('colors', 'bg-gauze'),
        mainColorElevate: getChakraVarExpression('colors', 'bg-gauze'),
        tabColor: addAlpha(colors.brand.white, 0.75),
        tabColorActive: getChakraVarExpression('colors', 'brand-white'),
        shadow: getChakraVarExpression('shadows', 'gauzeCard.normal'),
        shadowActive: getChakraVarExpression('shadows', 'gauzeCard.active'),
        smallShadow: getChakraVarExpression('shadows', 'gauzeCard.normal'),
        smallShadowActive: getChakraVarExpression('shadows', 'gauzeCard.active'),
        highlight: getChakraVarExpression('colors', 'garden.highlight'),
        accent: getChakraVarExpression('colors', 'brand-pink'),
    }) })));
InGauze.displayName = 'InGauze';
/**
 * focusing the tectonic style
 */
export const inTectonicSelector = '[data-color-theme="tectonic"] &';
export const InTectonic = forwardRef((props, ref) => (React.createElement(InDark, { ref: ref, "data-color-theme": "tectonic", ...injectColorThemeVars(props, {
        ...DefaultColorThemeConfig,
        mainColor: '#1F1F3F',
        mainColorElevate: '#1F1F3F',
        shadow: '-4px -4px 5px #68688d,3px 4px 5px #000001',
        shadowActive: 'inset -4px -4px 5px #68688d,inset 3px 4px 5px #000001',
        smallShadow: '-2px -2px 3px #68688d,2px 2px 3px #000001',
        smallShadowActive: 'inset -2px -2px 3px #68688d,inset 2px 2px 3px #000001',
    }) })));
InTectonic.displayName = 'InTectonic';
/**
 * focusing the ferro style
 */
export const inFerroSelector = '[data-color-theme="ferro"] &';
export const InFerro = forwardRef((props, ref) => (React.createElement(InDark, { ref: ref, "data-color-theme": "ferro", ...injectColorThemeVars(props, {
        ...DefaultColorThemeConfig,
        mainColor: '#1A1A1A',
        mainColorElevate: '#1A1A1A',
        shadow: '-4px -4px 5px #666666,3px 4px 5px #000000',
        shadowActive: 'inset -4px -4px 5px #666666,inset 3px 4px 5px #000000',
        smallShadow: '-2px -2px 3px #666666,2px 2px 3px #000000',
        smallShadowActive: 'inset -2px -2px 3px #666666,inset 2px 2px 3px #000000',
    }) })));
InFerro.displayName = 'InFerro';
/**
 * focusing the vvs style
 */
export const inVvsSelector = '[data-color-theme="vvs"] &';
export const InVvs = forwardRef((props, ref) => (React.createElement(InLight, { ref: ref, "data-color-theme": "vvs", ...injectColorThemeVars(props, {
        ...DefaultColorThemeConfig,
        mainColor: '#F4F5F1',
        mainColorElevate: '#F4F5F1',
        shadow: '-4px -4px 5px #ffffff,3px 4px 5px #c5c6c3',
        shadowActive: 'inset -4px -4px 5px #ffffff,inset 3px 4px 5px #c5c6c3',
        smallShadow: '-2px -2px 3px #ffffff,2px 2px 3px #c5c6c3',
        smallShadowActive: 'inset -2px -2px 3px #ffffff,inset 2px 2px 3px #c5c6c3',
    }) })));
InVvs.displayName = 'InVvs';
export const InColorTheme = forwardRef(({ colorTheme, ...props }, ref) => {
    if (colorTheme === 'inherit') {
        return React.createElement(InInherit, { ref: ref, ...props });
    }
    if (colorTheme === 'atom') {
        return React.createElement(InAtom, { ref: ref, ...props });
    }
    if (colorTheme === 'tia') {
        return React.createElement(InTia, { ref: ref, ...props });
    }
    if (colorTheme === 'eth') {
        return React.createElement(InEth, { ref: ref, ...props });
    }
    if (colorTheme === 'garden') {
        return React.createElement(InGarden, { ref: ref, ...props });
    }
    if (colorTheme === 'gauze') {
        return React.createElement(InGauze, { ref: ref, ...props });
    }
    if (colorTheme === 'TECTONIC') {
        return React.createElement(InTectonic, { ref: ref, ...props });
    }
    if (colorTheme === 'FERRO') {
        return React.createElement(InFerro, { ref: ref, ...props });
    }
    if (colorTheme === 'VVS') {
        return React.createElement(InVvs, { ref: ref, ...props });
    }
    return React.createElement(InDefault, { ref: ref, ...props });
});
InColorTheme.displayName = 'InColorTheme';
