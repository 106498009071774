import { Box, Button, Flex, Image, Link, Text } from '@chakra-ui/react';
import { PATH_DASHBOARD_VNO } from '@ui/config/paths';
import { useTranslations } from '@ui/i18n';
import NextLink from '@ui/overrides/next/link';
import type { VaultType } from '@ui/views/Vno/ReservoirAndFountain/types';

import { formatLockPeriod } from '../../utils/formatLockPeriod';
import Balance from '../Balance';
import { InMobile } from '../MobileOrDesktop';

interface DepositTransactionCompletedContentProps {
  amount: number;
  lockPeriod: number;
  type: VaultType;
}
const DepositTransactionCompletedContent: React.FC<
  DepositTransactionCompletedContentProps
> = ({ amount, lockPeriod, type }) => {
  const t = useTranslations();

  return (
    <Flex flexDir="column" grow={1} gap={6} minH="full" align="center">
      <Text textStyle="h3">{t('Transaction completed!')}</Text>
      <Box>
        <Balance
          title={t('You have locked')}
          icon={<Image alt="VNO" src="/tokens/vno.svg" />}
          amount={amount}
        />
        <Text textStyle="bodySmall" color="text.light" align="center">
          {t('in {platform}', {
            platform:
              formatLockPeriod(lockPeriod, {
                uppercase: false,
                roughly: true,
              }) +
              ' ' +
              (type === 'RESERVOIR' ? 'Reservoir' : 'Fountain'),
          })}
        </Text>
      </Box>
      <Text textStyle="body" color="text.light" textAlign="center">
        {t('You can harvest your earned rewards any time under Earnings')}
      </Text>
    </Flex>
  );
};

const DepositTransactionCompletedFooter: React.FC<{
  onCloseDrawer: () => void;
}> = ({ onCloseDrawer }) => {
  const t = useTranslations();
  return (
    <>
      <Button onClick={onCloseDrawer}>{t('Done')}</Button>
      <InMobile>
        <NextLink href={PATH_DASHBOARD_VNO} passHref>
          <Link textAlign="center">{t('My dashboard')}</Link>
        </NextLink>
      </InMobile>
    </>
  );
};

const DepositTransactionCompleted = {
  Content: DepositTransactionCompletedContent,
  Footer: DepositTransactionCompletedFooter,
};
export default DepositTransactionCompleted;
