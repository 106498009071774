import type { BigNumber } from '@ethersproject/bignumber';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { getVvsLpBalance } from '@ui/queries/sdk';
import type { VenoSDK } from '@veno-app/sdk';
import { currentWallet } from '@veno-app/wallet';

const { useAccount } = currentWallet;

const useVvsLpBalance = (
  options?: Partial<Pick<UseQueryOptions, 'enabled'>>,
  address?: string,
  _query?: (
    sdk: VenoSDK,
    address: string,
  ) => {
    queryKey: unknown[];
    queryFn: () => Promise<BigNumber>;
  },
) => {
  const sdk = useVenoSDK();
  const userAccount = useAccount();
  const account = address ?? userAccount;
  const query = _query
    ? _query(sdk, account ?? '')
    : getVvsLpBalance(sdk, account ?? '');
  const { data: vvsLpBalance, isLoading } = useQuery(
    query.queryKey,
    query.queryFn,
    {
      ...options,
      enabled:
        !!account && (options?.enabled === undefined || options?.enabled),
    },
  );

  return {
    loading: isLoading,
    vvsLpBalance,
  };
};

export default useVvsLpBalance;
