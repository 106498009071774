import {
  Box,
  Button,
  Flex,
  Link as ExternalLink,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import type { NftToken } from '@ui/components/NFTCard';
import { NFTCard } from '@ui/components/NFTCard';
import { getContractAddresses } from '@ui/config/base';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { COIN_MODE } from '@ui/hooks/useCoinMode';
import { useExplorerLabel, useExplorerUrl } from '@ui/hooks/useExplorer';
import { useTranslations } from '@ui/i18n';
import { currentWallet, MINTED_URL } from '@veno-app/wallet';

import { InDesktop, InMobile } from '../MobileOrDesktop';

const { useSupportedChainId } = currentWallet;

interface UnstakeTransactionCompletedContentProps {
  coinMode: CoinMode;
  txHash: string;
  isNftsTokenLoading: boolean;
  latestNftToken: NftToken | null;
}
export const UnstakeTransactionCompletedContent: React.FC<
  UnstakeTransactionCompletedContentProps
> = ({ txHash, isNftsTokenLoading, latestNftToken, coinMode }) => {
  const t = useTranslations();
  const coinModeInfo = COIN_MODE[coinMode];
  const supportedChainId = useSupportedChainId();
  const explorerLabel = useExplorerLabel();
  const explorerUrl = useExplorerUrl();

  return (
    <Flex flexDir="column" align="center" gap={4}>
      <Flex flexDir="column" align="center" gap={3}>
        <Text textStyle="h3">Transaction completed!</Text>
        {supportedChainId && (
          <ExternalLink href={`${explorerUrl}/tx/${txHash}`} isExternal>
            {explorerLabel}
          </ExternalLink>
        )}
      </Flex>
      <Text textAlign="center" textStyle="bodySmall" color="error">
        {t(
          'By selling or transferring this NFT you forfeit your claim to the unstaked {coin}',
          {
            coin: coinModeInfo.name,
          },
        )}
      </Text>
      {isNftsTokenLoading || !latestNftToken ? (
        <Skeleton w="170px" h="277px" />
      ) : (
        <NFTCard
          coinMode={coinMode}
          nft={latestNftToken}
          width="150px"
          height="257px"
          p="10px"
          mt={1}
        />
      )}
      <Box>
        <Text textStyle="body" alignSelf="flex-start" color="text.light">
          {t(
            'This NFT represents your share of {coin} while it undergoes its unbonding period You can either hold onto this NFT until expiry or sell it in the meantime on Minted',
            {
              coin: coinModeInfo.name,
            },
          )}
        </Text>
      </Box>
    </Flex>
  );
};

interface UnstakeTransactionCompletedDrawerFooterProps {
  latestNftTokenId: string | null;
  onCloseDrawer: () => void;
}
const UnstakeTransactionCompletedFooter: React.FC<
  UnstakeTransactionCompletedDrawerFooterProps
> = ({ onCloseDrawer, latestNftTokenId }) => {
  const t = useTranslations();
  const supportedChainId = useSupportedChainId();
  return (
    <>
      <InMobile>
        {supportedChainId && (
          <ExternalLink
            // supported chain should match cronos network env
            href={`${MINTED_URL[supportedChainId]}/collections/cronos/${
              getContractAddresses().venoNft
            }/${latestNftTokenId ?? ''}`}
            isExternal
          >
            <Button w="full">
              {t('View {coin}', {
                coin: 'NFT',
              })}
            </Button>
          </ExternalLink>
        )}
        <Box textAlign="center" mt="12px">
          <Text textStyle="textLink" onClick={onCloseDrawer}>
            {t('Done')}
          </Text>
        </Box>
      </InMobile>
      <InDesktop>
        <Box textAlign="center" bg="bg.main" pointerEvents="auto">
          <Button w="full" onClick={onCloseDrawer}>
            {t('Done')}
          </Button>
        </Box>
      </InDesktop>
    </>
  );
};

const UnstakeTransactionCompleted = {
  Content: UnstakeTransactionCompletedContent,
  Footer: UnstakeTransactionCompletedFooter,
} as const;

export default UnstakeTransactionCompleted;
