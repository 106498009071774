import {
  Box,
  Button,
  Flex,
  Icon as ChakraIcon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import Balance from '@ui/components/Balance';
import Info from '@ui/components/Info';
import { InfoTooltip } from '@ui/components/InfoTooltip';
import { InputCard } from '@ui/components/InputCard';
import {
  RenderInDesktop,
  RenderInMobile,
} from '@ui/components/MobileOrDesktop';
import TextWithAbbreviateZeros from '@ui/components/TextWithAbbreviateZeros';
import { TokenIcon } from '@ui/components/TokenIcon';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { COIN_MODE, CoinModeProvider } from '@ui/hooks/useCoinMode';
import { i18next, useTranslations } from '@ui/i18n';
import { MAXW_DESKTOP_CTA } from '@ui/utils/constants';
import { formatNumber } from '@ui/utils/format';
import type { GardenType } from '@ui/utils/garden';
import {
  formatGardenApy,
  gardenTokenDict,
  getGardenName,
} from '@ui/utils/garden';
import { ColorThemeVars, InColorTheme, InGauze } from '@veno-app/chakra';
import { ArrowBack } from '@veno-app/icons';
import React from 'react';

import { NoteBox } from '../../components/NoteBox';
import AssetExplainer from './AssetExplainer';
import DoSupplyDrawer from './DoSupplyDrawer';
import SupplyLcroDisclaimer from './SupplyLcroDisclaimer';
import SupplyLcroModalCoinToReceive from './SupplyLcroModalCoinToReceive';
import useSupplyLcroModal from './useSupplyLcroModal';
import { useSupplyLcroModalStore } from './useSupplyLcroModalStore';

function SupplyLcroModal() {
  const t = useTranslations();

  const {
    isOpen,
    closeAndReset,
    apy,
    apyError,
    gradient1,
    gradient2,
    name,
    balance,
    cover,
    balanceUsd,
    maxAmount,
    handleInputChange,
    inputError,
    inputWarning,
    supplyAmount,
    transactionStatus,
    error,
    handleSupply,
    closeDrawer,
    drawerOpen,
    exchangeRateFetching,
    type,
    exchangeRate,
    receiveAmount,
    receiveAmountUsd,
    currentStep,
    receivedAmount,
    nSteps,
    gardenApy,
    lpUsdWorth,
  } = useSupplyLcroModal();

  const coinMode = useSupplyLcroModalStore((s) => s.coinMode);
  const onBackward = useSupplyLcroModalStore((s) => s.onBackward);

  const coinModeInfo = COIN_MODE[coinMode];
  const suppliedTokenName = gardenTokenDict[coinMode][type].suppliedTokenName;
  const maxDecimalsAllowed = coinModeInfo.liquidDecimals;

  return (
    <>
      <DoSupplyDrawer
        coinMode={coinMode}
        name={name}
        isOpen={drawerOpen}
        onClose={closeDrawer}
        onDone={closeAndReset}
        transactionStatus={transactionStatus}
        error={error}
        receivedAmount={receivedAmount}
        type={type}
        nSteps={nSteps}
        currentStep={currentStep}
      />
      <Modal
        variant="wholeScreen"
        size={{
          base: 'full',
          desktop: '3xl',
        }}
        isOpen={isOpen}
        onClose={closeAndReset}
      >
        <ModalOverlay bg="bg-gauze" />
        <InColorTheme colorTheme={type} isRoot>
          <ModalContent bgColor={`var(${ColorThemeVars.mainColor})`}>
            <ModalHeader textAlign="center" position="relative">
              <RenderInDesktop>
                <Button
                  hidden={!onBackward}
                  variant="link"
                  color="text-link"
                  position="absolute"
                  left="10px"
                  top="16px"
                  leftIcon={<ChakraIcon as={ArrowBack} boxSize="15px" />}
                  onClick={() => {
                    if (onBackward) {
                      onBackward();
                    }
                    closeAndReset();
                  }}
                >
                  {t('Back to {page}', {
                    page: getGardenName(coinMode, type),
                  })}
                </Button>
              </RenderInDesktop>
              <Text
                css={{
                  '--test-a-b':
                    'inset -4px -4px 5px #ffffff,inset 3px 4px 5px #c5c6c3',
                }}
              >
                {t('Supply {coin} on {platform}', {
                  coin: suppliedTokenName ?? '',
                  platform: name,
                })}
              </Text>
            </ModalHeader>
            <RenderInMobile>
              <ModalCloseButton variant="smallCard" size="xs" />
            </RenderInMobile>
            <RenderInDesktop>
              <InGauze>
                <InColorTheme colorTheme={type}>
                  <ModalCloseButton
                    variant="smallCard"
                    size="xs"
                    position="absolute"
                    left="calc(100% + 10px)"
                    top="0"
                  />
                </InColorTheme>
              </InGauze>
            </RenderInDesktop>
            <ModalBody pb={{ base: 6, desktop: 11 }} px={0} position="relative">
              <Box w="full" position="absolute" top="0" left="0" zIndex={0}>
                <Box
                  background={gradient1}
                  h="61px"
                  w="full"
                  position="absolute"
                  zIndex={1}
                />
                <Box
                  background={gradient2}
                  h="80px"
                  transform="rotate(-180deg)"
                  w="full"
                  bottom={0}
                  position="absolute"
                  zIndex={1}
                />
                <Image
                  zIndex={0}
                  w="full"
                  alt="Partner"
                  src={cover}
                  height={{
                    base: 'auto',
                    desktop: '211px',
                  }}
                  opacity={
                    type === 'VVS' || type === 'VVS_CRO' || type === 'VVS_TIA'
                      ? '0.6'
                      : '1'
                  }
                  objectFit={
                    type === 'VVS' || type === 'VVS_CRO' || type === 'VVS_TIA'
                      ? 'contain'
                      : 'cover'
                  }
                  objectPosition="center center"
                />
              </Box>
              <Balance
                maxW={{
                  base: 'unset',
                  desktop: '500px',
                }}
                mx="auto"
                mt={{
                  base: '150px',
                  desktop: '80px',
                }}
                title={t('Wallet {coin} Balance', {
                  coin: suppliedTokenName ?? '',
                })}
                amount={balance}
                unitDecimals={coinModeInfo.liquidDecimals}
                icon={
                  type === 'VVS' ? (
                    <TokenIcon token="vvs" />
                  ) : (
                    <coinModeInfo.LiquidIcon />
                  )
                }
                usdAmount={balanceUsd}
                usdDisplayDecimals={2}
                zIndex={1}
                position="relative"
                invertedColors={
                  type !== 'VVS' && type !== 'VVS_CRO' && type !== 'VVS_TIA'
                }
                showUsdAmount
                displayDecimals={coinModeInfo.displayDecimals}
              />
              <Flex
                flexDir="column"
                mt="20px"
                maxW={{
                  base: 'unset',
                  desktop: '500px',
                }}
                mx={{
                  base: 4,
                  desktop: 'auto',
                }}
                position="relative"
                zIndex="1"
              >
                <SupplyLcroDisclaimer type={type} />
                {type !== 'VVS_TIA' && (
                  <Info
                    mt={6}
                    title={
                      <>
                        APY
                        <InfoTooltip
                          title="APY"
                          label={
                            <Text
                              px={{ base: 0, desktop: 8 }}
                              py={{ base: 0, desktop: 4 }}
                            >
                              {getLpApyExplainer(coinMode, type)}
                            </Text>
                          }
                        />
                      </>
                    }
                    value={
                      apyError
                        ? '--'
                        : typeof apy === 'number'
                        ? `${formatNumber(apy, 2)}%`
                        : undefined
                    }
                  />
                )}
                <InputCard
                  className="veno-tour__supply-modal-input"
                  mt={3}
                  max={maxAmount}
                  label={t('Input {coin} amount', {
                    coin: suppliedTokenName ?? '',
                  })}
                  maxDecimals={maxDecimalsAllowed}
                  value={supplyAmount}
                  onChange={handleInputChange}
                  errorMessage={inputError}
                  usdValue={lpUsdWorth}
                />
                {inputWarning && (
                  <NoteBox mt="14px" mb="10px" desc={inputWarning} />
                )}
                <Button
                  className="veno-tour__supply-modal-confirm-button"
                  onClick={handleSupply}
                  variant="pink"
                  mt="14px"
                  disabled={!!inputError || !receiveAmount}
                  w={{ desktop: MAXW_DESKTOP_CTA }}
                  mx={{ desktop: 'auto' }}
                >
                  {t('Supply now')}
                </Button>
                <Flex flexDir="column" mt={6} gap={3}>
                  <SupplyLcroModalCoinToReceive
                    coinMode={coinMode}
                    type={type}
                    exchangeRateFetching={exchangeRateFetching}
                    receiveAmount={receiveAmount}
                    receiveAmountUsd={receiveAmountUsd}
                  />
                  <Info
                    title={t('Exchange rate')}
                    value={
                      exchangeRateFetching ? undefined : exchangeRate ? (
                        <>
                          1 {coinModeInfo.liquidName} :{' '}
                          <TextWithAbbreviateZeros
                            display="inline"
                            abbreviateZeros={
                              gardenTokenDict[coinMode][type].abbreviateZeros
                            }
                          >
                            {exchangeRate.toFixed(
                              gardenTokenDict[coinMode][type].displayDecimals ??
                                4,
                            )}
                          </TextWithAbbreviateZeros>
                        </>
                      ) : (
                        '--'
                      )
                    }
                  />
                  <Info
                    hidden={!gardenTokenDict[coinMode][type].enabled}
                    title={
                      <>
                        {getGardenName(coinMode, type)} APR
                        <InfoTooltip
                          title={getGardenName(coinMode, type)}
                          label={
                            <AssetExplainer
                              coinMode={coinMode}
                              explainText="GARDEN"
                              type={type}
                            />
                          }
                        />
                      </>
                    }
                    value={
                      gardenApy !== undefined
                        ? `${formatGardenApy(gardenApy)}`
                        : undefined
                    }
                  />
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </InColorTheme>
      </Modal>
    </>
  );
}

const getLpApyExplainer = (coinMode: CoinMode, gardenType: GardenType) => {
  const gardenName = getGardenName(coinMode, gardenType);
  const gardenToken = gardenTokenDict[coinMode][gardenType];
  switch (gardenType) {
    case 'FERRO':
      return i18next.t('ferro-explainer-{gardenName}', {
        gardenName,
      });
    case 'VVS':
    case 'VVS_CRO':
    case 'VVS_TIA':
      return i18next.t('vvs-explainer-{gardenName}', {
        gardenName,
      });
    case 'TECTONIC':
      if (!gardenTokenDict[coinMode].TECTONIC.enabled) {
        return i18next.t(
          'tectonic-explainer-without-garden-{suppliedTokenName}',
          {
            suppliedTokenName: gardenToken.suppliedTokenName ?? '',
          },
        );
      }
      return i18next.t(
        'tectonic-explainer-{suppliedTokenName}-{coin}-{gardenName}',
        {
          suppliedTokenName: gardenToken.suppliedTokenName ?? '',
          coin: gardenToken.name ?? '',
          gardenName,
        },
      );
  }
};

const SupplyModal = () => {
  const coinMode = useSupplyLcroModalStore((s) => s.coinMode);
  return (
    <CoinModeProvider value={coinMode}>
      <SupplyLcroModal />
    </CoinModeProvider>
  );
};

export default SupplyModal;
