import { useAtomBalance } from '@ui/hooks/useAtomBalance';
import { useBalance } from '@ui/hooks/useBalance';
import { useCoinUsdValue } from '@ui/hooks/useCoinUsdValue';
import { useExplorerUrl } from '@ui/hooks/useExplorer';
import { useLiquidCoinBalance } from '@ui/hooks/useLiquidCoinBalance';
import { useLiquidCoinUsd } from '@ui/hooks/useLiquidCoinUsd';
import { useTiaBalance } from '@ui/hooks/useTiaBalance';
import useVnoBalance from '@ui/hooks/useVnoBalance';
import { useVnoUsdValue } from '@ui/hooks/useVnoUsdValue';
import useWcroBalance from '@ui/hooks/useWcroBalance';
import { useWcroUsdValue } from '@ui/hooks/useWcroUsdPrice';
import { currentWallet } from '@veno-app/wallet';

import { useAccountModalStore } from './useAccountModalStore';

const { useAccount, useIsConnected } = currentWallet;

const useAccountModalData = () => {
  const explorerUrl = useExplorerUrl();
  const account = useAccount();
  const isConnected = useIsConnected();

  return {
    account,
    isConnected,
    explorerUrl,
  };
};

export const useAccountModalCronosBalances = () => {
  const isOpen = useAccountModalStore((s) => s.isOpen);
  const { data: balance } = useBalance();
  const { data: atomBalance } = useAtomBalance();
  const { data: lcroBalance } = useLiquidCoinBalance({
    coinMode: 'cro',
    enabled: isOpen,
  });
  const { data: latomBalance } = useLiquidCoinBalance({
    coinMode: 'atom',
    enabled: isOpen,
  });
  const { wcroBalance } = useWcroBalance({ enabled: isOpen });
  const { vnoBalance } = useVnoBalance({ enabled: isOpen });

  const balanceUsdValue = useCoinUsdValue(balance, 'cro').data;
  const lcroBalanceUsd = useLiquidCoinUsd({
    coinMode: 'cro',
    amount: lcroBalance,
  }).data;
  const atomBalanceUsd = useCoinUsdValue(atomBalance, 'atom').data;
  const latomBalanceUsd = useLiquidCoinUsd({
    coinMode: 'atom',
    amount: latomBalance,
  }).data;
  const vnoBalanceUsd = useVnoUsdValue(vnoBalance).data;
  const wcroBalanceUsd = useWcroUsdValue(wcroBalance).data;
  const { data: tiaBalance } = useTiaBalance({ enabled: isOpen });
  const tiaBalanceUsd = useCoinUsdValue(tiaBalance, 'tia').data;
  const { data: ltiaBalance } = useLiquidCoinBalance({
    coinMode: 'tia',
    enabled: isOpen,
  });
  const ltiaBalanceUsd = useLiquidCoinUsd({
    coinMode: 'tia',
    amount: ltiaBalance,
  }).data;

  return {
    balance,
    balanceUsdValue,
    lcroBalance,
    lcroBalanceUsd,
    atomBalance,
    atomBalanceUsd,
    latomBalance,
    latomBalanceUsd,
    vnoBalance,
    vnoBalanceUsd,
    wcroBalance,
    wcroBalanceUsd,
    tiaBalance,
    tiaBalanceUsd,
    ltiaBalance,
    ltiaBalanceUsd,
  };
};

export default useAccountModalData;
