/**
 * Accordion theme
 * @see https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src/components/accordion.ts
 */
import { accordionAnatomy as parts } from '@chakra-ui/anatomy';
export const Accordion = {
    parts: parts.keys,
    baseStyle() {
        return {};
    },
    variants: {
        footer: {
            container: {
                _first: { mt: 0 },
                mt: 8,
                pb: 2,
                borderTopWidth: 0,
                borderBottomWidth: '1px',
                borderColor: 'brand.transparentGrey',
            },
            button: {
                height: '24px',
                _expanded: {
                    bg: 'transparent',
                },
                pt: 0,
                pr: 0,
                pl: 0,
                pb: 0,
            },
            panel: { mt: 2, py: 0, pb: 0, pl: 0, pr: 0 },
        },
    },
    sizes: {},
    defaultProps: {},
};
