import { Router } from '@ui/overrides/next/router';
import { cloneDeep, omit } from 'lodash-es';
import type { StateCreator, StoreMutatorIdentifier } from 'zustand';

export const withReset = <
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends Object & {
    $$resetData?: () => void;
  },
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = [],
>(
  initializer: StateCreator<T, Mps, Mcs>,
) => {
  type InitialDataType = T & {
    $$resetData?: () => void;
  };

  let initialData: InitialDataType;

  const newFn: StateCreator<InitialDataType, Mps, Mcs> = (
    set,
    get,
    store,
    ...args
  ) => {
    initialData = {
      ...initializer(set, get, store, ...args),
      $$resetData: () => {
        let dataToReset: Partial<InitialDataType> = initialData;
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const persist = store.persist;
          const partialize = persist?.getOptions()?.partialize;

          if (partialize) {
            const persistState = partialize(get()) as Record<string, unknown>;
            dataToReset = {
              ...omit(dataToReset, Object.keys(persistState)),
              ...persistState,
            };
          } else if (persist) {
            // do nothing
            return;
          }
        } catch (e) {
          if (__DEV__) {
            console.error(e);
          }
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        store.setState(cloneDeep(dataToReset) as any, true);
      },
    };

    return cloneDeep(initialData);
  };

  return newFn;
};

// @see https://github.com/pmndrs/zustand/blob/main/docs/typescript.md#middleware-that-does-not-change-the-store-type
export const resetWhenRouterChange = <
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends Object,
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = [],
>(
  initializer: StateCreator<T, Mps, Mcs>,
) => {
  type InitialDataType = T & {
    $$resetData?: () => void;
  };

  let currentUrl =
    typeof window === 'undefined' ? '' : window.location.pathname;

  const newFn: StateCreator<InitialDataType, Mps, Mcs> = (
    set,
    get,
    store,
    ...args
  ) => {
    Router.events.on('routeChangeComplete', (url: string) => {
      if (getRoutePath(currentUrl) === getRoutePath(url)) {
        return;
      }
      currentUrl = url;
      store.getState().$$resetData?.();
    });

    return withReset(initializer)(set, get, store, ...args);
  };

  return newFn;
};

const getRoutePath = (url: string) => {
  return (url || '').replace(/\?.*/, '');
};
