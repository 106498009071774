import type { FlexProps } from '@chakra-ui/react';
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { useExplorerLabel, useExplorerUrl } from '@ui/hooks/useExplorer';
import { useTranslations } from '@ui/i18n';
import { Error } from '@veno-app/icons';
import type { EthersError } from '@veno-app/sdk';
import { useMemo } from 'react';

export type TransactionErrorContentProps = {
  error: EthersError | null | undefined;
} & FlexProps;
const TransactionErrorContent: React.FC<TransactionErrorContentProps> = ({
  error,
  ...flexProps
}) => {
  const t = useTranslations();
  const errorText = useErrorText(error);

  return (
    <Flex mt="18px" flexDir="column" gap="30px" align="center" {...flexProps}>
      <Text textStyle="h3">{t('Transaction failed!')}</Text>
      <Error color="red" height="50px" width="50px" />
      <Box maxW="full">
        <Text textStyle="bodySmall" color="text.light">
          {errorText}
        </Text>
      </Box>
    </Flex>
  );
};

export const useErrorText = (error: EthersError | null | undefined) => {
  const t = useTranslations();
  const explorerLabel = useExplorerLabel();
  const explorerUrl = useExplorerUrl();

  return useMemo(() => {
    if (!error) {
      return t('Something went wrong');
    }
    const errorCode = error.code;
    const errorMessage = error.reason ?? error.message ?? error.toString();
    const txHash = error.transaction?.hash;

    // TODO constant when we know more about error codes to be handled
    if (errorCode === 'ACTION_REJECTED') return t('User declined transaction');

    const txLink =
      explorerUrl && txHash ? (
        <Link href={`${explorerUrl}/tx/${txHash}`} mr="6px" isExternal>
          {explorerLabel}
        </Link>
      ) : null;

    return (
      <>
        {txLink}
        {t('Something went wrong')}
        {` (${new Date().toUTCString()}): ${errorCode}. ${
          errorMessage ? errorMessage.substring(0, 700) + '...' : ''
        }`}
      </>
    );
  }, [error, explorerLabel, explorerUrl, t]);
};

const TransactionError = { Content: TransactionErrorContent } as const;

export default TransactionError;
