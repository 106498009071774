import type { CoinMode } from '@ui/hooks/useCoinMode';
import type { GardenType, GardenView } from '@ui/utils/garden';
import type { LiquidityStrategyView } from '@ui/utils/strategy';

export const PATH_LANDING = '/';
export const PATH_DASHBOARD = '/dashboard';
export const PATH_DASHBOARD_ATOM = '/dashboard-atom';
export const PATH_DASHBOARD_VNO = '/dashboard-vno';
export const PATH_DASHBOARD_TIA = '/dashboard-tia';
export const PATH_VNO = '/vno';
export const PATH_STATS_VNO = '/stats/vno';
export const PATH_STATS_STAKING = '/stats/staking';
export const PATH_RESERVOIR = '/vno/reservoir';
export const PATH_FOUNTAIN = '/vno/fountain';

// TODO most likely different path, this just for example
export const PATH_CAMPAIGN = '/offer';

const NOT_SUPPORTED_URL = '__NOT_SUPPORTED_URL__';

type PathDict = {
  landing: string;
  landingDetail: string;
  dashboard: string;
  dashboardVno: string;
  dashboardAtom: string;
  vno: string;
  vnoReservoir?: string;
  vnoFountain?: string;
  stake: string;
  useLiquid: string;
  unstake: string;
  claim: string;
  statsVno: string;
  statsStaking: string;
  garden: Record<GardenType, Record<GardenView, string>>;
  strategy: Record<LiquidityStrategyView, string>;
};

const croPathDict: PathDict = {
  landing: '/',
  landingDetail: '/cro',
  dashboard: PATH_DASHBOARD,
  dashboardVno: PATH_DASHBOARD_VNO,
  dashboardAtom: PATH_DASHBOARD_ATOM,
  vno: PATH_VNO,
  vnoReservoir: PATH_RESERVOIR,
  vnoFountain: PATH_FOUNTAIN,
  stake: '/cro/stake',
  useLiquid: '/cro/use-lcro',
  unstake: '/cro/unstake',
  claim: '/cro/claim',
  statsVno: PATH_STATS_VNO,
  statsStaking: PATH_STATS_STAKING,
  garden: {
    FERRO: {
      PLANT: '/vno/garden/ferro',
      WITHDRAW: '/vno/garden/ferro/withdraw',
      GROW_WATER: '/vno/garden/ferro/grow',
      GROW_HELPERS: '/vno/garden/ferro/grow/helper',
      HARVEST: '/vno/garden/ferro/harvest',
    },
    TECTONIC: {
      PLANT: '/vno/garden/tectonic',
      WITHDRAW: '/vno/garden/tectonic/withdraw',
      GROW_WATER: '/vno/garden/tectonic/grow',
      GROW_HELPERS: '/vno/garden/tectonic/grow/helper',
      HARVEST: '/vno/garden/tectonic/harvest',
    },
    VVS: {
      PLANT: '/vno/garden/vvs',
      WITHDRAW: '/vno/garden/vvs/withdraw',
      GROW_WATER: '/vno/garden/vvs/grow',
      GROW_HELPERS: '/vno/garden/vvs/grow/helper',
      HARVEST: '/vno/garden/vvs/harvest',
    },
    VVS_CRO: {
      PLANT: '/vno/garden/vvs-cro',
      WITHDRAW: '/vno/garden/vvs-cro/withdraw',
      GROW_WATER: '/vno/garden/vvs-cro/grow',
      GROW_HELPERS: '/vno/garden/vvs-cro/grow/helper',
      HARVEST: '/vno/garden/vvs-cro/harvest',
    },
    VVS_TIA: {
      PLANT: NOT_SUPPORTED_URL,
      WITHDRAW: NOT_SUPPORTED_URL,
      GROW_WATER: NOT_SUPPORTED_URL,
      GROW_HELPERS: NOT_SUPPORTED_URL,
      HARVEST: NOT_SUPPORTED_URL,
    },
  },
  strategy: {
    deposit: '/strategy/cro-liquidity',
    withdraw: '/strategy/cro-liquidity/withdraw',
    dashboard: '/dashboard?tab=strategy',
  },
};

const atomPathDict: PathDict = {
  landing: '/',
  landingDetail: '/atom',
  dashboard: PATH_DASHBOARD_ATOM,
  dashboardVno: PATH_DASHBOARD_VNO,
  dashboardAtom: PATH_DASHBOARD_ATOM,
  vno: PATH_VNO,
  stake: '/atom/stake',
  useLiquid: '/atom/use-latom',
  unstake: '/atom/unstake',
  claim: '/atom/claim',
  statsVno: PATH_STATS_VNO,
  statsStaking: PATH_STATS_STAKING,
  garden: {
    FERRO: {
      PLANT: '/vno/garden/ferro-atom',
      WITHDRAW: '/vno/garden/ferro-atom/withdraw',
      GROW_WATER: '/vno/garden/ferro-atom/grow',
      GROW_HELPERS: '/vno/garden/ferro-atom/grow/helper',
      HARVEST: '/vno/garden/ferro-atom/harvest',
    },
    TECTONIC: {
      PLANT: '/vno/garden/tectonic-atom',
      WITHDRAW: '/vno/garden/tectonic-atom/withdraw',
      GROW_WATER: '/vno/garden/tectonic-atom/grow',
      GROW_HELPERS: '/vno/garden/tectonic-atom/grow/helper',
      HARVEST: '/vno/garden/tectonic-atom/harvest',
    },
    VVS: {
      PLANT: '/vno/garden/vvs-atom',
      WITHDRAW: '/vno/garden/vvs-atom/withdraw',
      GROW_WATER: '/vno/garden/vvs-atom/grow',
      GROW_HELPERS: '/vno/garden/vvs-atom/grow/helper',
      HARVEST: '/vno/garden/vvs-atom/harvest',
    },
    // TODO will not be used
    VVS_CRO: {
      PLANT: NOT_SUPPORTED_URL,
      WITHDRAW: NOT_SUPPORTED_URL,
      GROW_WATER: NOT_SUPPORTED_URL,
      GROW_HELPERS: NOT_SUPPORTED_URL,
      HARVEST: NOT_SUPPORTED_URL,
    },
    VVS_TIA: {
      PLANT: NOT_SUPPORTED_URL,
      WITHDRAW: NOT_SUPPORTED_URL,
      GROW_WATER: NOT_SUPPORTED_URL,
      GROW_HELPERS: NOT_SUPPORTED_URL,
      HARVEST: NOT_SUPPORTED_URL,
    },
  },
  strategy: {
    deposit: '/strategy/atom-liquidity',
    withdraw: '/strategy/atom-liquidity/withdraw',
    dashboard: '/dashboard?tab=strategy',
  },
};

const tiaPathDict: PathDict = {
  landing: '/',
  landingDetail: '/tia',
  dashboard: PATH_DASHBOARD_TIA,
  dashboardVno: PATH_DASHBOARD_VNO,
  dashboardAtom: PATH_DASHBOARD_ATOM,
  vno: PATH_VNO,
  stake: '/tia/stake',
  useLiquid: '/tia/use-ltia',
  unstake: '/tia/unstake',
  claim: '/tia/claim',
  statsVno: PATH_STATS_VNO,
  statsStaking: PATH_STATS_STAKING,
  garden: {
    FERRO: {
      PLANT: NOT_SUPPORTED_URL,
      WITHDRAW: NOT_SUPPORTED_URL,
      GROW_WATER: NOT_SUPPORTED_URL,
      GROW_HELPERS: NOT_SUPPORTED_URL,
      HARVEST: NOT_SUPPORTED_URL,
    },
    TECTONIC: {
      PLANT: NOT_SUPPORTED_URL,
      WITHDRAW: NOT_SUPPORTED_URL,
      GROW_WATER: NOT_SUPPORTED_URL,
      GROW_HELPERS: NOT_SUPPORTED_URL,
      HARVEST: NOT_SUPPORTED_URL,
    },
    VVS: {
      PLANT: NOT_SUPPORTED_URL,
      WITHDRAW: NOT_SUPPORTED_URL,
      GROW_WATER: NOT_SUPPORTED_URL,
      GROW_HELPERS: NOT_SUPPORTED_URL,
      HARVEST: NOT_SUPPORTED_URL,
    },
    VVS_CRO: {
      PLANT: NOT_SUPPORTED_URL,
      WITHDRAW: NOT_SUPPORTED_URL,
      GROW_WATER: NOT_SUPPORTED_URL,
      GROW_HELPERS: NOT_SUPPORTED_URL,
      HARVEST: NOT_SUPPORTED_URL,
    },
    VVS_TIA: {
      PLANT: '/vno/garden/vvs-tia',
      WITHDRAW: '/vno/garden/vvs-tia/withdraw',
      GROW_WATER: '/vno/garden/vvs-tia/grow',
      GROW_HELPERS: '/vno/garden/vvs-tia/grow/helper',
      HARVEST: '/vno/garden/vvs-tia/harvest',
    },
  },
  strategy: {
    deposit: '/strategy/tia-liquidity',
    withdraw: '/strategy/tia-liquidity/withdraw',
    dashboard: '/dashboard?tab=strategy',
  },
};

const ethPathDict: PathDict = {
  landing: '/',
  landingDetail: '/eth',
  dashboard: PATH_DASHBOARD,
  dashboardVno: PATH_DASHBOARD_VNO,
  dashboardAtom: PATH_DASHBOARD_ATOM,
  vno: PATH_VNO,
  stake: '/eth/stake',
  useLiquid: '/eth/use-leth',
  unstake: '/eth/unstake',
  claim: '/eth/claim',
  statsVno: PATH_STATS_VNO,
  statsStaking: PATH_STATS_STAKING,
  garden: {
    FERRO: {
      PLANT: '/vno/garden/ferro-eth',
      WITHDRAW: '/vno/garden/ferro-eth/withdraw',
      GROW_WATER: '/vno/garden/ferro-eth/grow',
      GROW_HELPERS: '/vno/garden/ferro-eth/grow/helper',
      HARVEST: '/vno/garden/ferro-eth/harvest',
    },
    TECTONIC: {
      PLANT: '/vno/garden/tectonic-eth',
      WITHDRAW: '/vno/garden/tectonic-eth/withdraw',
      GROW_WATER: '/vno/garden/tectonic-eth/grow',
      GROW_HELPERS: '/vno/garden/tectonic-eth/grow/helper',
      HARVEST: '/vno/garden/tectonic-eth/harvest',
    },
    VVS: {
      PLANT: '/vno/garden/vvs-eth',
      WITHDRAW: '/vno/garden/vvs-eth/withdraw',
      GROW_WATER: '/vno/garden/vvs-eth/grow',
      GROW_HELPERS: '/vno/garden/vvs-eth/grow/helper',
      HARVEST: '/vno/garden/vvs-eth/harvest',
    },
    // TODO will not be used
    VVS_CRO: {
      PLANT: NOT_SUPPORTED_URL,
      WITHDRAW: NOT_SUPPORTED_URL,
      GROW_WATER: NOT_SUPPORTED_URL,
      GROW_HELPERS: NOT_SUPPORTED_URL,
      HARVEST: NOT_SUPPORTED_URL,
    },
    VVS_TIA: {
      PLANT: NOT_SUPPORTED_URL,
      WITHDRAW: NOT_SUPPORTED_URL,
      GROW_WATER: NOT_SUPPORTED_URL,
      GROW_HELPERS: NOT_SUPPORTED_URL,
      HARVEST: NOT_SUPPORTED_URL,
    },
  },
  strategy: {
    deposit: '/strategy/eth-liquidity',
    withdraw: '/strategy/eth-liquidity/withdraw',
    dashboard: '/dashboard?tab=strategy',
  },
};

export const pathDict: Record<CoinMode, PathDict> = {
  cro: croPathDict,
  atom: atomPathDict,
  eth: ethPathDict,
  tia: tiaPathDict,
};
