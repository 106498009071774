import type { BigNumber } from '@ethersproject/bignumber';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from '@ui/overrides/next/router';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { QueryKey } from '@ui/queries/queryKey';
import bigNumberToNumber from '@ui/utils/bigNumberToNumber';
import { trackEvent } from '@ui/utils/tracking';
import type { VenoSDK } from '@veno-app/sdk';
import { currentWallet } from '@veno-app/wallet';

import type { CoinMode } from './useCoinMode';
import { useCoinMode } from './useCoinMode';

const { useAccount } = currentWallet;

const useFerroSupply = ({
  coinMode: _coinMode,
}: {
  coinMode?: CoinMode;
} = {}) => {
  const globalCoinMode = useCoinMode();
  const coinMode = _coinMode ?? globalCoinMode;
  const queryClient = useQueryClient();
  const sdk = useVenoSDK();
  const userAccount = useAccount();
  const { pathname } = useRouter();

  const supply = async (amount: BigNumber, expected: number) => {
    const data =
      coinMode === 'atom'
        ? await sdk.FerroSwapAtom.addLiquidity(amount, expected)
        : await sdk.FerroSwap.addLiquidity(amount, expected);
    return await data.txReceiptPromise;
  };

  const directDepositFerro = ({
    amount,
    expected,
  }: {
    amount: BigNumber;
    expected: number;
  }) => supply(amount, expected);
  return useMutation(directDepositFerro, {
    onSuccess: async (_, vars) => {
      queryClient.invalidateQueries([QueryKey.FERRO_LP_BALANCE]);
      trackFerroDirectDepositEvent(sdk, vars, userAccount ?? '', pathname);
    },
  });
};

async function trackFerroDirectDepositEvent(
  sdk: VenoSDK,
  vars: { amount: BigNumber; expected: number },
  userAccount: string,
  pathname: string,
) {
  const remainingBalance = bigNumberToNumber(
    await sdk.LiquidCro.balanceOf(userAccount ?? ''),
  );
  const protocol = 'ferro';
  const isTransaction = true;
  const amount = bigNumberToNumber(vars.amount);
  const entrypoint = pathname.includes('garden') ? 'garden' : 'use_lcro';
  trackEvent('Deposit LCRO Confirmed', {
    Amount: amount,
    isTransaction,
    Protocol: protocol,
    'Entry Point': entrypoint,
    'Remaining Balance': remainingBalance,
  });
}

export default useFerroSupply;
