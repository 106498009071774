import { useRealPathname } from '@ui/hooks/router';
import { getLangUrls } from '@ui/i18n';
import type { LinkProps as NextLinkProps } from 'next/link';
import NextLink from 'next/link';
import type { FC, ReactNode } from 'react';

export type LinkProps = Omit<NextLinkProps, 'as' | 'href' | 'locale'> & {
  as?: string;
  href: string;
  locale?: string;
  children?: ReactNode;
};

const Link: FC<LinkProps> = (props) => {
  const { as, href, locale, ...restProps } = props;
  const realPathname = useRealPathname();

  if (href[0] !== '/') {
    return <NextLink {...props} legacyBehavior />;
  }

  return (
    <NextLink
      href={getLangUrls(href, as, locale, realPathname)[0]}
      as={getLangUrls(href, as, locale, realPathname)[1]}
      {...restProps}
      legacyBehavior />
  );
};
export default Link;
