import type { Document } from '@contentful/rich-text-types';
import { BigNumber } from '@ethersproject/bignumber';
import { useQuery } from '@tanstack/react-query';
import { features } from '@ui/components/Features';
import { getContractAddresses } from '@ui/config/base';
import { isFeatureEnabled } from '@ui/hooks/useIsFeatureEnabled';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import type { ChainType, NftCollection } from '@veno-app/sdk';
import { getTokenContract } from '@veno-app/sdk';

export type { NftCollection } from '@veno-app/sdk';

export interface Pool {
  pid: number;
  multiplier: number;
  lockPeriod: number;
  totalStaked: BigNumber;
  apr: number | null;
}

export type ReservoirPool = Pool;

export type FountainPool = Pool & {
  earlyVaultPenalty: BigNumber;
};

export type NftInfo = {
  multiplier: number | undefined;
  name: string;
  id: string;
  imageUrl: string;
  collection: NftCollection;
  tokenId: string;
  image: {
    url: string;
  };
};

interface Asset {
  sys: { id: string };
  url: string;
  title: string;
  description: string;
  contentType: string;
  width: number;
  height: number;
}

interface AssetBlock {
  block: Asset[];
}

interface DescriptionRichTextLinks {
  assets: AssetBlock;
}

export interface DescriptionRichText {
  json: Document;
  links: DescriptionRichTextLinks;
}

export type StakedNftInfo = Omit<NftInfo, 'multiplier'> & {
  multiplier: number;
};

export const getNftCollections = (chainType: ChainType): NftCollection[] => {
  if (chainType === 'zksync') {
    return [];
  }
  return isFeatureEnabled(features.topDogCorgiAndCronosChimpNft)
    ? [
        'CRUISER',
        'MOLE',
        'BoomerSquad',
        'Ballies',
        'TopDogCorgi',
        'CronosChimp',
      ]
    : ['CRUISER', 'MOLE', 'BoomerSquad', 'Ballies'];
};

export const getNftCollectionNftName = (collection: NftCollection): string => {
  switch (collection) {
    case 'CRUISER':
      return 'Cruiser';
    case 'MOLE':
      return 'Miner Mole';
    case 'BoomerSquad':
      return 'Boomer Squad';
    case 'Ballies':
      return 'Ballie';
    case 'TopDogCorgi':
      return 'Top Dog Corgi';
    case 'CronosChimp':
      return 'Cronos Chimp';
  }
};
export const getNftCollectionName = (collection: NftCollection): string => {
  switch (collection) {
    case 'CRUISER':
      return 'Cronos Cruisers';
    case 'MOLE':
      return 'VVS Miner Moles';
    case 'BoomerSquad':
      return 'Boomer Squads';
    case 'Ballies':
      return 'Ballies';
    case 'TopDogCorgi':
      return 'Top Dog Corgi';
    case 'CronosChimp':
      return 'Cronos Chimp';
  }
};
export const getNftCollectionImageUrl = async (
  collection: NftCollection,
  tokenId: string,
): Promise<string> => {
  switch (collection) {
    case 'CRUISER':
      return `https://cronoscruisers.infura-ipfs.io/ipfs/QmXsqishrf1te1WXHDkbzCYaAAVM8ybYf9okwe9HuHzejX/${
        (2226 + parseInt(tokenId)) % 8000
      }.jpg`;
    case 'MOLE':
      return `https://vvs.infura-ipfs.io/ipfs/Qmcnc79Kxkd4Np7eER4TMNDfScUswYpWnHtoHWLDtZWsUU/${
        (8956 + parseInt(tokenId)) % 10000
      }.jpg`;
    case 'BoomerSquad':
      return `https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/${parseInt(
        tokenId,
      )}.png`;
    case 'Ballies': {
      const balliesHashArray = (await import('./balliesHashArray.json'))
        .default;
      return `https://images.minted.network/?image=${
        balliesHashArray[Number(tokenId) - 1]
      }&width=100&quality=75`;
    }
    case 'TopDogCorgi':
      return `https://images.corgiai.xyz/0x7e378f70816a41c396f19cf9cbebe26ea8c00023/${parseInt(
        tokenId,
      )}.png`;
    case 'CronosChimp':
      return `https://arweave.net/KOnXn6F7zRoLrJYu-SIrY9wJZATcQG2fYnXwG5xMBQA/${parseInt(
        tokenId,
      )}.png`;
  }
};
export const getNftCollectionAddress = (collection: NftCollection): string => {
  return getTokenContract(collection, getContractAddresses());
};
export const getNftCollectionMintedLink = (
  collection: NftCollection,
): string => {
  return `https://minted.network/collections/cronos/${getNftCollectionAddress(
    collection,
  )}`;
};
export const getNftCollectionStartTime = (
  collection: NftCollection,
): Date | null => {
  switch (collection) {
    case 'CRUISER':
      return null;
    case 'MOLE':
      return null;
    case 'BoomerSquad':
      return null;
    case 'Ballies':
      return null;
    case 'TopDogCorgi':
      return null;
    case 'CronosChimp':
      return null;
  }
};

export const getNftCollectionEndTime = (
  collection: NftCollection,
): Date | null => {
  switch (collection) {
    case 'CRUISER':
      return null;
    case 'MOLE':
      // Jul 05 2023 15:00:00 GMT+0800
      return new Date(1688540400000);
    case 'BoomerSquad':
      return null;
    case 'Ballies':
      return null;
    case 'TopDogCorgi':
      return null;
    case 'CronosChimp':
      return null;
  }
};

export const useIsNftCollectionDisabled = (collection: NftCollection) => {
  const sdk = useVenoSDK();

  return useQuery(['useIsNftCollectionDisabled', collection], async () => {
    const rarityAddress = await sdk.VenoStorm.rarityRegistries(collection);
    return BigNumber.from(rarityAddress).eq(0);
  });
};

export const useIsNftCollectionEnded = (collection: NftCollection): boolean => {
  const endTime = getNftCollectionEndTime(collection);
  const isNftDisabledRet = useIsNftCollectionDisabled(collection);

  return (
    isNftDisabledRet.data === true ||
    (!!endTime && new Date().getTime() > endTime.getTime())
  );
};
