import { useQuery } from '@tanstack/react-query';
import { useSpecificVenoSDK } from '@ui/providers/VenoSDKProvider';
import { getCoinPerStakedCoin } from '@ui/queries/sdk';

import type { CoinMode } from './useCoinMode';
import { COIN_MODE, useCoinMode } from './useCoinMode';

const useCoinPerStakedCoin = (coinMode?: CoinMode) => {
  const globalCoinMode = useCoinMode();
  const mode = coinMode ?? globalCoinMode;
  const sdk = useSpecificVenoSDK(COIN_MODE[mode].chainType);
  const coinPerStakedCoinQuery = getCoinPerStakedCoin(sdk, mode);
  return useQuery(coinPerStakedCoinQuery);
};

export default useCoinPerStakedCoin;
