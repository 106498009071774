import {
  getAtomUsdPrice as getAtomUsdPriceFromSdk,
  getCroUsdPrice as getCroUsdPriceFromSdk,
  getEthUsdPrice as getEthUsdPriceFromSdk,
  getTiaUsdPrice as getTiaUsdPriceFromApi,
  getVnoUsdPrice as getVnoUsdPriceFromSdk,
} from '@veno-app/sdk';

import { QueryKey } from './queryKey';

export const getCroUsdPrice = () => ({
  queryKey: [QueryKey.CRO_PRICE],
  queryFn: getCroUsdPriceFromSdk,
});

export const getEthUsdPrice = () => ({
  queryKey: ['eth-price'],
  queryFn: getEthUsdPriceFromSdk,
});

export const getAtomUsdPrice = () => ({
  queryKey: [QueryKey.ATOM_PRICE],
  queryFn: getAtomUsdPriceFromSdk,
});

export const getTiaUsdPrice = () => ({
  queryKey: ['getTiaUsdPrice'],
  queryFn: getTiaUsdPriceFromApi,
});

export const getVnoUsdPrice = () => ({
  queryKey: [QueryKey.VNO_PRICE],
  queryFn: getVnoUsdPriceFromSdk,
});

type FetchTokenPriceResponse = {
  slug: string;
  usd_price: number;
};
export const getWcroUsdPrice = () => ({
  queryKey: [QueryKey.WCRO_PRICE],
  queryFn: async () => {
    const fetchResponse = await fetch(
      'https://price-api.crypto.com/price/v1/token-price/wrapped-cro',
    );
    const response = (await fetchResponse.json()) as FetchTokenPriceResponse;
    if (response.usd_price) return response.usd_price;
    throw new Error(
      'Could not fetch WCRO asset price from price-api.crypto.com',
    );
  },
});
