import type { BigNumber } from '@ethersproject/bignumber';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { COIN_MODE } from '@ui/hooks/useCoinMode';
import { i18next } from '@ui/i18n';
import type { ChainType } from '@veno-app/sdk';
import { UnbondingStatus } from '@veno-app/sdk';
import { ChainId, CRONOS_CHAIN_ID, ZKSYNC_CHAIN_ID } from '@veno-app/wallet';

export type NftToken = {
  id: BigNumber;
  chainType: ChainType;
  name: string;
  image: string;
  unlockEndTime: BigNumber;
  unlockStartTime: BigNumber;
  liquidTokenAmount: BigNumber;
  liquidToken2TokenExchangeRate: BigNumber;
  batchNo: BigNumber;
  unbondingStatus: UnbondingStatus;
  liquidTokenExchangeRateFactor: BigNumber;
};

export const isNftTokenRedeemable = (nft?: NftToken | null) => {
  /**
   * @see https://github.com/cronos-labs/liquid-staking-contracts-eth/blob/master/src/LiquidEth.sol#L212
   */
  if (nft?.chainType === 'zksync') {
    return nft && nft.unbondingStatus === UnbondingStatus.UNBONDED;
  }

  /**
   * @see https://github.com/cronos-labs/liquid-staking-contracts-core/blob/master/src/LiquidCro.sol#L200
   */
  return (
    nft &&
    isNftTokenOverUnlockTime(nft) &&
    nft.unbondingStatus === UnbondingStatus.UNBONDED
  );
};

export const isNftTokenOverUnlockTime = (nft?: NftToken | null) => {
  return !!nft && nft.unlockEndTime.toNumber() * 1000 <= Date.now();
};

export const getNftTokenStatusTitle = (nft?: NftToken | null): string => {
  if (isNftTokenRedeemable(nft)) return i18next.t('Claimable');
  if (nft?.unbondingStatus === UnbondingStatus.UNBONDING)
    return i18next.t('Unbonding');
  return i18next.t('Batching');
};

export const nftMarketplaces = {
  [CRONOS_CHAIN_ID]: 'Minted',
  [ZKSYNC_CHAIN_ID]: 'Element Marketplace',
};

export const sellNftOnMinted = (
  nft: NftToken | null | undefined,
  globalCoinMode: CoinMode,
  openDisclaimerDrawer: (options: { url: string }) => void,
  chainId: ChainId,
) => {
  let url =
    chainId === ChainId.Mainnet
      ? EXTERNAL_LINK.mintedCollectionBaseUrl +
        COIN_MODE[globalCoinMode].nftAddress
      : EXTERNAL_LINK.mintedCollectionBaseUrlDev +
        COIN_MODE[globalCoinMode].nftAddress;

  if (chainId === ZKSYNC_CHAIN_ID) {
    url = EXTERNAL_LINK.elementMarketplaceBaseUrl;
  } else if (nft) {
    url = url + '/' + nft.id.toString();
  }

  openDisclaimerDrawer({ url });
};
