import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { expandDecimals } from './constants';
import WcroDelegatorModule from './wcroDelegator';
export default class LcroDelegatorModule extends WcroDelegatorModule {
    constructor() {
        super(...arguments);
        this.contractAddress = this.sdk.addresses.lcroDelegator;
    }
    async getDepositExchangeRate(tokenAmount) {
        if (tokenAmount.lte(0))
            return BigNumber.from(0);
        const lpTokensToReceive = await this.ferroSwap.calculateTokenAmount(tokenAmount, BigNumber.from(0));
        const exchangeRate = (await this.ferroVault.exchangeRate())
            .mul(tokenAmount)
            .div(lpTokensToReceive);
        return exchangeRate;
    }
    async getWithdrawExchangeRate(vaultTokenAmount) {
        if (vaultTokenAmount.eq(0)) {
            return BigNumber.from(0);
        }
        const lpPerVaultToken = await this.ferroVault.exchangeRate();
        const lpReceived = lpPerVaultToken.mul(vaultTokenAmount);
        const croReceived = await this.ferroSwap.calculateRemoveLiquidityOneToken(lpReceived.div(expandDecimals(1, 18)), 0);
        return croReceived.mul(expandDecimals(1, 18)).div(vaultTokenAmount);
    }
    async getDepositPriceImpact(tokenAmount) {
        if (tokenAmount.lte(0))
            0;
        const [lpTokensToReceive, virtualPrice] = await Promise.all([
            this.ferroSwap.calculateTokenAmount(tokenAmount, BigNumber.from(0)),
            this.ferroSwap.getVirtualPrice(),
        ]);
        const virtualPriceHuman = parseFloat(formatUnits(virtualPrice));
        const tokenAmountHuman = parseFloat(formatUnits(tokenAmount));
        const lpReceiveHuman = parseFloat(formatUnits(lpTokensToReceive));
        const result = (virtualPriceHuman * lpReceiveHuman) / tokenAmountHuman - 1;
        return result;
    }
    async getWithdrawPriceImpact(vaultTokenAmount) {
        const [lpPerVaultToken, virtualPrice] = await Promise.all([
            this.ferroVault.exchangeRate(),
            this.ferroSwap.getVirtualPrice(),
        ]);
        const lpReceived = lpPerVaultToken
            .mul(vaultTokenAmount)
            .div(expandDecimals(1, 18));
        const lcroReceived = await this.ferroSwap.calculateRemoveLiquidityOneToken(lpReceived, 0);
        const lcroReceivedHuman = parseFloat(formatUnits(lcroReceived));
        const virtualPriceHuman = parseFloat(formatUnits(virtualPrice));
        const lpReceiveHuman = parseFloat(formatUnits(lpReceived));
        return ((lcroReceivedHuman - virtualPriceHuman * lpReceiveHuman) /
            (virtualPriceHuman * lpReceiveHuman));
    }
}
