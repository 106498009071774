import { Box, Button, Flex } from '@chakra-ui/react';
import type { FC } from 'react';
import type { TooltipRenderProps } from 'react-joyride';

export const ToursTooltip: FC<TooltipRenderProps> = ({
  step,
  index,
  size,
  tooltipProps,
  skipProps,
  primaryProps,
}) => {
  const isNextButtonEnabled =
    step.spotlightClicks === false || step.hideFooter === false;

  return (
    <Box
      {...tooltipProps}
      w="min(460px, 95vw)"
      bg="brand-background"
      rounded="10px"
      p={{
        base: '18px 18px',
        desktop: '24px 48px 32px',
      }}
      position="relative"
    >
      <Box
        textStyle={{
          base: 'body',
          lg: 'h3',
        }}
        textAlign="center"
      >
        {step.content}
      </Box>
      <Flex
        mt={{
          base: '12px',
          desktop: '24px',
        }}
        justifyContent="center"
        gap="32px"
      >
        <Button
          {...skipProps}
          variant="link"
          color="brand-primary"
          textStyle="textLink"
        >
          Skip onboarding
        </Button>
        {isNextButtonEnabled && (
          <Button
            variant="primary"
            w={{
              base: 'auto',
              desktop: '120px',
            }}
            size={{
              base: 'sm',
              desktop: 'md',
            }}
            {...primaryProps}
          >
            {index === size - 1 ? 'Done' : 'Next'}
          </Button>
        )}
      </Flex>
    </Box>
  );
};
