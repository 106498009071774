import { useUseYourTokenDrawer } from '@ui/components/useYourTokenDrawer';
import { useTranslations } from '@ui/i18n';
import { useVnoProtocols } from '@ui/views/Vno/UseVnoCard/utils';
import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo } from 'react';

import { FooterAction } from './const';

type FooterActionsContextType = Record<FooterAction, () => void>;

const FooterActionsContext = createContext<FooterActionsContextType>(
  {} as FooterActionsContextType,
);

interface FooterActionsProviderProps {
  children: ReactNode;
}

export const FooterActionsProvider: React.FC<FooterActionsProviderProps> = ({
  children,
}) => {
  const t = useTranslations();
  const vnoProtocols = useVnoProtocols();

  const action = useCallback(() => {
    useUseYourTokenDrawer.getState().onOpen({
      title: t('Use your {coin}', {
        coin: 'VNO',
      }),
      protocols: vnoProtocols,
    });
  }, [t, vnoProtocols]);

  const actionsMap = useMemo(() => {
    return { [FooterAction.OpenUseVnoModal]: action };
  }, [action]);

  return (
    <FooterActionsContext.Provider value={actionsMap}>
      {children}
    </FooterActionsContext.Provider>
  );
};

export const useFooterActions = (): FooterActionsContextType =>
  useContext(FooterActionsContext);
