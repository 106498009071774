import { trackPageView } from '@ui/utils/telemetry';
import { useInitializeMixpanel } from '@ui/utils/tracking';
import { useInjectedCssVarOfViewport } from '@veno-app/chakra';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useInitializeWallet } from './useInitializeWallet';
import { usePosthog } from './usePosthog';

export const useInitializeApp = () => {
  useInitializeWallet();
  useInitializeMixpanel();
  usePosthog();
  useInjectedCssVarOfViewport();
  const router = useRouter();

  useEffect(() => {
    const onRouteChange = (url: string): void => trackPageView(url);

    router.events.on('routeChangeComplete', onRouteChange);

    return () => {
      router.events.off('routeChangeComplete', onRouteChange);
    };
  }, [router.events]);
};
