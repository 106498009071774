import { useQuery } from '@tanstack/react-query';
import { features } from '@ui/components/Features';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import {
  getFerroApy,
  getSyncSwapEthVnoApr,
  getSyncSwapLethEthApr,
  getVvsApy,
  getVvsCroApy,
  getVvsTiaApy,
} from '@ui/queries/partnerApys';
import { getBoostMultiplerForPool, getGardenApr } from '@ui/queries/sdk';
import type { GardenType } from '@ui/utils/garden';
import { currentWallet } from '@veno-app/wallet';

import type { CoinMode } from './useCoinMode';
import { useCoinMode } from './useCoinMode';
import { isFeatureEnabled } from './useIsFeatureEnabled';
import { useTectonicApy } from './usePartnerStats';

const { useAccount } = currentWallet;

type GardenApyRet = {
  ferroGardenApy: number | undefined;
  ferroGardenAprLoading: boolean;
  vvsGardenApy: number | undefined;
  vvsGardenAprLoading: boolean;
  tectonicGardenApy: number | undefined;
  tectonicGardenAprLoading: boolean;
  vvsCroGardenApy: number | undefined;
  vvsCroGardenAprLoading: boolean;
  vvsTiaGardenApy: number | undefined;
  vvsTiaGardenAprLoading: boolean;
};

const useGardenApyOnCronos = (
  type?: GardenType,
  includeProtocolApy = false,
  _coinMode?: CoinMode,
  enabled?: boolean,
): GardenApyRet => {
  const globalCoinMode = useCoinMode();
  const coinMode = _coinMode ?? globalCoinMode;
  const sdk = useVenoSDK();
  const account = useAccount();
  const tectonicEnabled = isFeatureEnabled(features.TectonicGarden);
  const ferroQuery = getGardenApr(coinMode, sdk, 'FERRO', account);

  const ferroBoostMultiplierQuery = getBoostMultiplerForPool(
    coinMode,
    sdk,
    account ?? '',
    'FERRO',
  );
  const { data: ferroGardenOnlyApr, isLoading: ferroGardenOnlyAprLoading } =
    useQuery(ferroQuery.queryKey, ferroQuery.queryFn, {
      enabled:
        enabled === false ? false : type === undefined || type === 'FERRO',
      staleTime: 60000,
    });
  const { data: ferroBoostMultiplier, isLoading: ferroBoostMultiplierLoading } =
    useQuery(
      ferroBoostMultiplierQuery.queryKey,
      ferroBoostMultiplierQuery.queryFn,
      {
        enabled:
          enabled === false
            ? false
            : type === undefined || (type === 'FERRO' && !!account),
      },
    );

  const ferroApyQuery = getFerroApy(coinMode, true);
  const { data: ferroApy, isLoading: ferroApyLoading } = useQuery(
    ferroApyQuery.queryKey,
    ferroApyQuery.queryFn,
    {
      enabled:
        enabled === false
          ? false
          : (type === undefined || type === 'FERRO') && includeProtocolApy,
    },
  );

  const vvsQuery = getGardenApr(coinMode, sdk, 'VVS', account);
  const vvsBoostMultiplierQuery = getBoostMultiplerForPool(
    coinMode,
    sdk,
    account ?? '',
    'VVS',
  );
  const { data: vvsGardenOnlyApr, isLoading: vvsGardenOnlyAprLoading } =
    useQuery(vvsQuery.queryKey, vvsQuery.queryFn, {
      enabled: enabled === false ? false : type === undefined || type === 'VVS',
      staleTime: 60000,
    });
  const { data: vvsBoostMultiplier, isLoading: vvsBoostMultiplierLoading } =
    useQuery(
      vvsBoostMultiplierQuery.queryKey,
      vvsBoostMultiplierQuery.queryFn,
      {
        enabled:
          enabled === false
            ? false
            : type === undefined || (type === 'VVS' && !!account),
      },
    );
  const vvsApyQuery = getVvsApy(true);
  const { data: vvsApy, isLoading: vvsApyLoading } = useQuery(
    vvsApyQuery.queryKey,
    vvsApyQuery.queryFn,
    {
      enabled:
        enabled === false
          ? false
          : (type === undefined || type === 'VVS') && includeProtocolApy,
    },
  );

  const tectonicQuery = getGardenApr(coinMode, sdk, 'TECTONIC', account);
  const tectonicBoostMultiplierQuery = getBoostMultiplerForPool(
    coinMode,
    sdk,
    account ?? '',
    'TECTONIC',
  );
  const {
    data: tectonicOnlyGardenApr,
    isLoading: tectonicOnlyGardenAprLoading,
  } = useQuery(tectonicQuery.queryKey, tectonicQuery.queryFn, {
    enabled:
      enabled === false
        ? false
        : tectonicEnabled && (type === undefined || type === 'TECTONIC'),
    staleTime: 60000,
  });
  const {
    data: tectonicBoostMultiplier,
    isLoading: tectonicBoostMultiplierLoading,
  } = useQuery(
    tectonicBoostMultiplierQuery.queryKey,
    tectonicBoostMultiplierQuery.queryFn,
    {
      enabled:
        enabled === false
          ? false
          : tectonicEnabled &&
            !!account &&
            (type === undefined || type === 'TECTONIC'),
    },
  );
  const { data: tectonicApy } = useTectonicApy(
    enabled === false
      ? false
      : (type === undefined || type === 'TECTONIC') && includeProtocolApy,
  );

  const ferroGardenAprLoading =
    ferroGardenOnlyAprLoading ||
    (ferroApyLoading && includeProtocolApy) ||
    ferroBoostMultiplierLoading;
  const vvsGardenAprLoading =
    vvsGardenOnlyAprLoading ||
    (vvsApyLoading && includeProtocolApy) ||
    vvsBoostMultiplierLoading;
  const tectonicGardenAprLoading =
    (tectonicApy === undefined && includeProtocolApy) ||
    tectonicOnlyGardenAprLoading ||
    tectonicBoostMultiplierLoading;

  const ferroGardenApy = getGardenApy(
    ferroGardenOnlyApr,
    ferroApy,
    ferroBoostMultiplier,
    includeProtocolApy,
  );
  const vvsGardenApy = getGardenApy(
    vvsGardenOnlyApr,
    vvsApy,
    vvsBoostMultiplier,
    includeProtocolApy,
  );
  const tectonicGardenApy = getGardenApy(
    tectonicOnlyGardenApr,
    tectonicApy,
    tectonicBoostMultiplier,
    includeProtocolApy,
  );

  const {
    gardenApy: vvsCroGardenApy,
    gardenAprLoading: vvsCroGardenAprLoading,
  } = useSpecificGardenApy({
    outerGardenType: type,
    myGardenType: 'VVS_CRO',
    getApyQuery: getVvsCroApy,
    includeProtocolApy,
    _coinMode,
    enabled,
  });

  const {
    gardenApy: vvsTiaGardenApy,
    gardenAprLoading: vvsTiaGardenAprLoading,
  } = useSpecificGardenApy({
    outerGardenType: type,
    myGardenType: 'VVS_TIA',
    getApyQuery: getVvsTiaApy,
    includeProtocolApy,
    _coinMode,
    enabled,
  });

  return {
    ferroGardenApy,
    ferroGardenAprLoading,
    vvsGardenApy,
    vvsGardenAprLoading,
    tectonicGardenApy,
    tectonicGardenAprLoading,
    vvsCroGardenApy,
    vvsCroGardenAprLoading,
    vvsTiaGardenApy,
    vvsTiaGardenAprLoading,
  };
};

const useSpecificGardenApy = (params: {
  outerGardenType: GardenType | undefined;
  myGardenType: GardenType;
  includeProtocolApy: boolean;
  getApyQuery: (skipFarmApr?: boolean) => {
    queryKey: (boolean | string)[];
    queryFn: () => Promise<number>;
  };
  _coinMode?: CoinMode;
  enabled?: boolean;
}) => {
  const {
    outerGardenType,
    myGardenType,
    includeProtocolApy,
    _coinMode,
    enabled,
  } = params;

  const globalCoinMode = useCoinMode();
  const coinMode = _coinMode ?? globalCoinMode;
  const sdk = useVenoSDK();
  const account = useAccount();

  const vvsCroQuery = getGardenApr(coinMode, sdk, myGardenType, account);
  const vvsCroBoostMultiplierQuery = getBoostMultiplerForPool(
    coinMode,
    sdk,
    account ?? '',
    myGardenType,
  );
  const { data: gardenOnlyApr, isLoading: gardenOnlyAprLoading } = useQuery(
    vvsCroQuery.queryKey,
    vvsCroQuery.queryFn,
    {
      enabled:
        enabled === false
          ? false
          : outerGardenType === undefined || outerGardenType === myGardenType,
      staleTime: 60000,
    },
  );
  const { data: boostMultiplier, isLoading: vvsCroBoostMultiplierLoading } =
    useQuery(
      vvsCroBoostMultiplierQuery.queryKey,
      vvsCroBoostMultiplierQuery.queryFn,
      {
        enabled:
          enabled === false
            ? false
            : (outerGardenType === undefined ||
                outerGardenType === myGardenType) &&
              !!account,
      },
    );
  const vvsCroApyQuery = getVvsApy(true);
  const { data: apy, isLoading: apyLoading } = useQuery(
    vvsCroApyQuery.queryKey,
    vvsCroApyQuery.queryFn,
    {
      enabled:
        enabled === false
          ? false
          : (outerGardenType === undefined ||
              outerGardenType === myGardenType) &&
            includeProtocolApy,
    },
  );

  const gardenAprLoading =
    gardenOnlyAprLoading ||
    (apyLoading && includeProtocolApy) ||
    vvsCroBoostMultiplierLoading;
  const gardenApy = getGardenApy(
    gardenOnlyApr,
    apy,
    boostMultiplier,
    includeProtocolApy,
  );

  return { gardenAprLoading, gardenApy };
};

const useGardenApyOnZksync = (
  type?: GardenType,
  includeProtocolApy: boolean = false,
  _coinMode?: CoinMode,
  enabled?: boolean,
): GardenApyRet => {
  const globalCoinMode = useCoinMode();
  const coinMode = _coinMode ?? globalCoinMode;

  const lethGardenApyRet = useLethLpGardenApyOnZksync(
    type,
    includeProtocolApy,
    coinMode,
    enabled,
  );

  const vnoLpGardenApyRet = useVnoLpGardenApyOnZksync(
    type,
    includeProtocolApy,
    coinMode,
    enabled,
  );

  return {
    ferroGardenApy: lethGardenApyRet.data,
    ferroGardenAprLoading: lethGardenApyRet.isLoading,
    vvsGardenApy: vnoLpGardenApyRet.data,
    vvsGardenAprLoading: vnoLpGardenApyRet.isLoading,
    tectonicGardenApy: undefined,
    tectonicGardenAprLoading: false,
    vvsCroGardenApy: undefined,
    vvsCroGardenAprLoading: false,
    vvsTiaGardenApy: undefined,
    vvsTiaGardenAprLoading: false,
  };
};

const useLethLpGardenApyOnZksync = (
  type?: GardenType,
  includeProtocolApy: boolean = false,
  _coinMode?: CoinMode,
  enabled?: boolean,
) => {
  const sdk = useVenoSDK();
  const account = useAccount();
  const globalCoinMode = useCoinMode();
  const coinMode = _coinMode ?? globalCoinMode;

  const lethBoostMultiplierRet = useQuery({
    ...getBoostMultiplerForPool(coinMode, sdk, account ?? '', 'FERRO'),
    enabled:
      enabled === false
        ? false
        : type === undefined || (type === 'FERRO' && !!account),
  });

  const lethLpAprRet = useQuery({
    ...getSyncSwapLethEthApr(),
    enabled: enabled === false ? false : type === undefined || type === 'FERRO',
  });

  const lethGardenAprRet = useQuery({
    ...getGardenApr(coinMode, sdk, 'FERRO', account),
    enabled: enabled === false ? false : type === undefined || type === 'FERRO',
    staleTime: 60000,
  });

  return {
    data: getGardenApy(
      lethGardenAprRet.data,
      lethLpAprRet.data,
      lethBoostMultiplierRet.data,
      includeProtocolApy,
    ),
    isLoading:
      lethBoostMultiplierRet.isLoading ||
      lethLpAprRet.isLoading ||
      lethGardenAprRet.isLoading,
  };
};

const useVnoLpGardenApyOnZksync = (
  type?: GardenType,
  includeProtocolApy: boolean = false,
  _coinMode?: CoinMode,
  enabled?: boolean,
) => {
  const sdk = useVenoSDK();
  const account = useAccount();
  const globalCoinMode = useCoinMode();
  const coinMode = _coinMode ?? globalCoinMode;

  const boostMultiplierRet = useQuery({
    ...getBoostMultiplerForPool(coinMode, sdk, account ?? '', 'VVS'),
    enabled:
      enabled === false
        ? false
        : type === undefined || (type === 'VVS' && !!account),
  });

  const lpAprRet = useQuery({
    ...getSyncSwapEthVnoApr(),
    enabled: enabled === false ? false : type === undefined || type === 'VVS',
  });

  const gardenAprRet = useQuery({
    ...getGardenApr(coinMode, sdk, 'VVS', account),
    enabled: enabled === false ? false : type === undefined || type === 'VVS',
    staleTime: 60000,
  });

  return {
    data: getGardenApy(
      gardenAprRet.data,
      lpAprRet.data,
      boostMultiplierRet.data,
      includeProtocolApy,
    ),
    isLoading:
      boostMultiplierRet.isLoading ||
      lpAprRet.isLoading ||
      gardenAprRet.isLoading,
  };
};

const useGardenApy = (
  type?: GardenType,
  includeProtocolApy = false,
  _coinMode?: CoinMode,
  enabled?: boolean,
): GardenApyRet => {
  const globalCoinMode = useCoinMode();
  const coinMode = _coinMode ?? globalCoinMode;

  const sdk = useVenoSDK();

  const gardenApyOnCronos = useGardenApyOnCronos(
    type,
    includeProtocolApy,
    coinMode,
    enabled === false ? false : sdk.chainType === 'cronos',
  );

  const gardenApyOnZksync = useGardenApyOnZksync(
    type,
    includeProtocolApy,
    coinMode,
    enabled === false ? false : sdk.chainType === 'zksync',
  );

  return sdk.chainType === 'zksync' ? gardenApyOnZksync : gardenApyOnCronos;
};

export default useGardenApy;

function getGardenApy(
  gardenOnlyApr: number | undefined,
  baseApy: number | undefined,
  boostMultiplier: number | undefined,
  includeProtocolApy: boolean,
) {
  let gardenApy: number | undefined = undefined;
  if (gardenOnlyApr === 0) {
    gardenApy = 0;
  } else if (
    gardenOnlyApr !== undefined &&
    (baseApy !== undefined || !includeProtocolApy) &&
    boostMultiplier !== undefined
  ) {
    gardenApy =
      gardenOnlyApr *
        (!isFeatureEnabled(features.NewGardenApr) ? boostMultiplier : 1) +
      (includeProtocolApy && baseApy ? baseApy : 0) / 100;
  }
  return gardenApy;
}
