import { LiquidEth__factory } from '@contracts';
import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from 'ethers/lib/utils';
import LiquidCoinModule from './liquidCoin';
import { getCroUsdPrice, wrapSdkTransaction } from './util';
export default class LiquidEthModule extends LiquidCoinModule {
    constructor() {
        super(...arguments);
        this.contractFactory = LiquidEth__factory;
        this.contractAddress = this.sdk.addresses.liquidEth;
    }
    async stake(receiver, value) {
        return await wrapSdkTransaction(this.getContract().stake(receiver, { value }));
    }
    getReceivedLeth(receipt) {
        const args = receipt.events?.find((e) => e.event === 'Transfer' &&
            e.args?.length &&
            BigNumber.from(e.args[0]).eq(BigNumber.from(0)))?.args;
        if (!args || args.length !== 3)
            return null;
        return args[2];
    }
    async estimateGasForStake(receiver) {
        const { maxFeePerGas } = await this.sdk.provider.getFeeData();
        const gasUnits = await this.getContract().estimateGas.stake(receiver, {
            value: parseUnits('0.00000001', 18),
        });
        if (!maxFeePerGas)
            return BigNumber.from(0);
        return maxFeePerGas.mul(gasUnits);
    }
    async totalSupply() {
        return await this.getContract().totalSupply();
    }
    async totalPooled() {
        return await this.getContract().getTotalPooledToken();
    }
    async getUsdWorth(amount) {
        if (amount.lte(0))
            return BigNumber.from(0);
        const [price, coinPerStakedCoin] = await Promise.all([
            getCroUsdPrice(),
            this.coinPerStakedCoin(),
        ]);
        if (!price)
            return BigNumber.from(0);
        const factor = 100000000000;
        return amount
            .mul(Math.round(factor * price * coinPerStakedCoin))
            .div(factor);
    }
}
