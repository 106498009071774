import { Flex } from '@chakra-ui/react';
import React from 'react';

import type { InfoProps } from '../Info';
import Info from '../Info';
import type { AccountModalActionButtonType } from './AccountModalActionButton';
import AccountModalActionButton from './AccountModalActionButton';

type AccountModalInfoProps = InfoProps & {
  action?: () => void;
  actionType?: AccountModalActionButtonType;
};

function AccountModalInfo({
  action,
  actionType,
  ...infoProps
}: AccountModalInfoProps) {
  return (
    <Flex gap={1} w="full" align="center">
      <Info flex={1} {...infoProps} />
      {action && (
        <AccountModalActionButton type={actionType || 'get'} action={action} />
      )}
    </Flex>
  );
}

export default AccountModalInfo;
