import { inAtomSelector, inGardenSelector } from '../utils/colorThemes';
export const Divider = {
    baseStyle() {
        return {
            opacity: 0.6,
            borderColor: 'shadow',
            [inGardenSelector]: {
                opacity: 0.2,
                borderColor: 'garden-dark',
            },
            [inAtomSelector]: {
                opacity: 0.2,
                borderColor: 'brand-blue',
            },
        };
    },
    variants: {},
    sizes: {},
    defaultProps: {},
};
