import { BigNumber } from '@ethersproject/bignumber';
import type { VenoSDK } from '@veno-app/sdk';

import { QueryKey } from './queryKey';

export const getVenoReservoirUserInfo = (
  sdk: VenoSDK,
  owner: string | undefined,
) => ({
  queryKey: [QueryKey.GET_VENO_RESERVOIR_USER_INFO, sdk, owner],
  queryFn: async (): ReturnType<typeof sdk.VenoReservoir.getUserInfo> => {
    if (!owner) {
      return {
        weightedAmount: BigNumber.from('0'),
        stakes: [],
      };
    }
    return sdk.VenoReservoir.getUserInfo(owner);
  },
});

const getVenoReservoirPendingRewardsInitialData: Awaited<
  ReturnType<VenoSDK['VenoReservoir']['pendingTokens']>
> = {
  wcro: BigNumber.from('0'),
  atom: BigNumber.from('0'),
  weth: BigNumber.from('0'),
};
export const getVenoReservoirPendingRewards = (
  sdk: VenoSDK,
  owner: string | undefined,
) => ({
  queryKey: [QueryKey.GET_VENO_RESERVOIR_PENDING_REWARDS, sdk, owner],
  queryFn: async (): ReturnType<VenoSDK['VenoReservoir']['pendingTokens']> => {
    if (!owner) {
      return getVenoReservoirPendingRewardsInitialData;
    }
    const rewardTokens = await sdk.VenoReservoir.getRewardTokens();
    return await sdk.VenoReservoir.pendingTokens(owner, rewardTokens);
  },
  initialData: getVenoReservoirPendingRewardsInitialData,
});

export const getVenoFountainUserInfo = (
  sdk: VenoSDK,
  owner: string | undefined,
) => ({
  queryKey: [QueryKey.GET_VENO_FOUNTAIN_USER_INFO, sdk, owner],
  queryFn: async (): ReturnType<typeof sdk.VenoFountain.getUserInfo> => {
    if (!owner) {
      return {
        weightedAmount: BigNumber.from('0'),
        rewardDebt: BigNumber.from('0'),
        pendingVaultPenaltyReward: BigNumber.from('0'),
        vaultPenaltyDebt: BigNumber.from('0'),
        stakes: [],
      };
    }
    return sdk.VenoFountain.getUserInfo(owner);
  },
});

export const getVenoFountainPendingRewards = (
  sdk: VenoSDK,
  owner: string | undefined,
) => ({
  queryKey: [QueryKey.GET_VENO_FOUNTAIN_PENDING_REWARDS, sdk, owner],
  queryFn: async (): Promise<BigNumber> => {
    if (!owner) {
      return BigNumber.from('0');
    }
    return sdk.VenoFountain.pendingVno(owner);
  },
});
