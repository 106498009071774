import router from '@ui/overrides/next/router';
import type { Options as IntlMessageFormatOptions } from 'intl-messageformat';
import type { ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import { defaultLanguage, languages } from './config';
import { i18next } from './provider';

export const getLanguage = (lang: string | undefined | null) => {
  const language = languages.find((item) => item.lang === lang);

  return (
    language ||
    languages.find((item) => item.lang === i18next.language) ||
    defaultLanguage
  );
};

export const useLanguage = () => {
  const { locale } = useIntl();
  return useMemo(() => {
    return getLanguage(locale);
  }, [locale]);
};

export const parsePathname = (pathname: string) => {
  pathname = pathname.indexOf('/') === 0 ? pathname : '/' + pathname;

  const slug = pathname.split('/')[1];
  const isLang = Boolean(languages.find((v) => v.lang === slug));
  const isLangPage = slug === '[lang]' || isLang;

  return {
    isLangPage,
    lang: isLang ? slug : null,
    pathname: isLangPage ? pathname.slice(slug.length + 1) : pathname,
  };
};

export const getLangUrls = (
  pathname: string,
  asPath: string = pathname,
  lang?: string | false,
  realPathname?: string,
): [string, string] => {
  const asPathInfo = parsePathname(asPath);
  const pathnameInfo = parsePathname(pathname);
  const targetLang = getLanguage(lang || asPathInfo.lang).lang;
  const hasLangValueInPath = realPathname
    ? parsePathname(realPathname).isLangPage
    : typeof window !== 'undefined' &&
      parsePathname(router.pathname).isLangPage;

  if (!hasLangValueInPath && targetLang === defaultLanguage.lang) {
    return [pathname, pathname];
  }

  return [
    `/[lang]${pathnameInfo.pathname}`,
    `/${targetLang}${asPathInfo.pathname}`,
  ];
};

export type TranslationFunction = {
  (id: string): string;

  <
    V extends Record<
      string,
      string | number | ((value: string[]) => ReactNode)
    >,
  >(
    id: string,
    values?: V,
    options?: IntlMessageFormatOptions,
  ): V extends Record<
    string,
    string | number | ((value: string[]) => string | string[] | number)
  >
    ? string
    : ReactNode;
};

export const createTranslationFunction = (intl: IntlShape) => {
  const translationFunction: TranslationFunction = (
    id: any,
    values?: any,
    options?: any,
  ) => {
    return intl.formatMessage(
      {
        id,
      },
      values,
      options,
    );
  };
  return translationFunction;
};

export const useTranslations = () => {
  const intl = useIntl();

  const translationFunction = createTranslationFunction(intl);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(translationFunction, [intl]);
};
