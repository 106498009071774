import type { BoxProps, FlexProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import useShowCronosUpgradeWarning from '@ui/hooks/useShowCronosUpgradeWarning';
import { currentWallet } from '@veno-app/wallet';
import type { ReactNode } from 'react';
import { usePreviousDistinct } from 'react-use';

import MaintenanceWarning from '../MaintenanceWarning';
import ConnectWallet from './ConnectWallet';
import { WithConnectNetwork } from './WithConnectNetwork';

type WithWalletProps = BoxProps & {
  children: ReactNode;
  actionHint?: string;
  connectWalletProps?: FlexProps;
  supportedCoinModes?: CoinMode[];
};

/**
 * Renders children if wallet connected. Shows connect wallet component if not connected.
 */
const WithWallet = ({
  children,
  actionHint,
  connectWalletProps,
  supportedCoinModes,
  ...boxProps
}: WithWalletProps) => {
  const connected = currentWallet.useIsConnected();
  const isConnecting = currentWallet.useIsConnecting();
  const { showWarning } = useShowCronosUpgradeWarning();
  const isConnectedButSwitchingNetwork =
    usePreviousDistinct(connected) && isConnecting;

  if (showWarning) {
    return (
      <Box {...boxProps}>
        <MaintenanceWarning />
      </Box>
    );
  }

  return (
    <Box {...boxProps}>
      {connected || isConnectedButSwitchingNetwork ? (
        <WithConnectNetwork
          actionHint={actionHint}
          supportedCoinModes={supportedCoinModes}
          {...connectWalletProps}
        >
          {children}
        </WithConnectNetwork>
      ) : (
        <ConnectWallet actionHint={actionHint} {...connectWalletProps} />
      )}
    </Box>
  );
};

export default WithWallet;
