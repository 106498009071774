import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { getQuery } from '@ui/queries/queries';
import { QueryKey } from '@ui/queries/queryKey';
import { currentWallet } from '@veno-app/wallet';

const { useAccount } = currentWallet;

const useWcroBalance = (
  options?: Partial<Pick<UseQueryOptions, 'enabled'>>,
) => {
  const sdk = useVenoSDK();
  const account = useAccount();

  const wcroBalanceQuery = getQuery(QueryKey.WCRO_BALANCE)(sdk, account ?? '');
  const wcroBalanceRet = useQuery(
    wcroBalanceQuery.queryKey,
    wcroBalanceQuery.queryFn,
    {
      ...options,
      enabled: !!account && (options?.enabled === undefined || options.enabled),
    },
  );

  return {
    ...wcroBalanceRet,
    loading: wcroBalanceRet.isLoading,
    wcroBalance: wcroBalanceRet.data,
  };
};

export default useWcroBalance;
