import { useQuery } from '@tanstack/react-query';
import {
  GraphQLContext,
  useGraphQLContext,
  useSubgraphClient,
} from '@ui/providers/GraphQLProvider';
import { getQuery } from '@ui/queries/queries';
import { QueryKey } from '@ui/queries/queryKey';
import {
  getAprQuery,
  getHistoricFerroGardenApysQuery,
  getImmediateHistoricApysStrategyQuery,
  getLatomAprQuery,
  getLethAprQuery,
  getLtiaAprQuery,
  getStrategyAprQuery,
} from '@ui/queries/subgraph';
import {
  DEFAULT_COMPOUND_FREQUENCY_CRO,
  DEFAULT_COMPOUND_FREQUENCY_ETH,
  getApy,
  getApyFromApr7d,
} from '@ui/utils/aprToApy';
import {
  ACTIVE_APY_MODE_STRATEGY,
  ACTIVE_COMPOUND_FREQUENCY_PER_DAY_STRATEGY,
  ApyMode,
  POST_MVP_DASHBOARD,
} from '@ui/utils/constants';
import { useContext, useMemo } from 'react';

import type { CoinMode } from './useCoinMode';
import { COIN_MODE, useCoinMode } from './useCoinMode';

export const useApr = (options?: { coinMode: CoinMode }) => {
  const globalCoinMode = useCoinMode();
  const coinMode = options?.coinMode ?? globalCoinMode;
  const client = useSubgraphClient(coinMode);
  let query: ReturnType<typeof getAprQuery> = getAprQuery(client);

  switch (coinMode) {
    case 'cro':
      query = getAprQuery(client);
      break;
    case 'atom':
      query = getLatomAprQuery(client);
      break;
    case 'eth':
      query = getLethAprQuery(client);
      break;
    case 'tia':
      query = getLtiaAprQuery(client);
      break;
    default:
      throw new Error('useApr with unknown coinMode');
  }

  return useQuery(query);
};

export const useHistoricApys = (coinMode?: CoinMode) => {
  const globalCoinMode = useCoinMode();
  const mode = coinMode ?? globalCoinMode;
  const client = useSubgraphClient(mode);
  const query = getQuery(QueryKey.HISTORIC_APYS)(client, mode);
  return useQuery(query.queryKey, query.queryFn, {
    enabled: POST_MVP_DASHBOARD,
  });
};

export const useApy = (options?: { coinMode: CoinMode }) => {
  const globalCoinMode = useCoinMode();
  const coinMode = options?.coinMode ?? globalCoinMode;
  const aprResult = useApr({
    coinMode,
  });
  const data =
    COIN_MODE[coinMode].apyMode === ApyMode.day
      ? getApy(
          aprResult.data ?? 0,
          globalCoinMode === 'eth'
            ? DEFAULT_COMPOUND_FREQUENCY_ETH
            : DEFAULT_COMPOUND_FREQUENCY_CRO,
        )
      : getApyFromApr7d(
          aprResult.data ?? 0,
          globalCoinMode === 'eth'
            ? DEFAULT_COMPOUND_FREQUENCY_ETH
            : DEFAULT_COMPOUND_FREQUENCY_CRO,
        );

  return { ...aprResult, data };
};

export const useHistoricStrategyApys = () => {
  const historicCroApys = useHistoricApys('cro').data;

  const client = useContext(GraphQLContext).strategyClient;
  const immediateApysQuery = getImmediateHistoricApysStrategyQuery(client);

  const historicFerroGardenApysQuery = getHistoricFerroGardenApysQuery(client);
  const ferroGardenApys = useQuery(
    historicFerroGardenApysQuery.queryKey,
    historicFerroGardenApysQuery.queryFn,
  ).data;

  const strategyApys = useMemo(
    () =>
      historicCroApys?.map((croApy) => ({
        date: croApy.date,
        apy:
          croApy.apy +
          (ferroGardenApys?.find(
            (ferroApy) => ferroApy.date.getTime() === croApy.date.getTime(),
          )?.apy ?? 0),
      })) ?? [],
    [ferroGardenApys, historicCroApys],
  );

  const immediateApysRet = useQuery(
    immediateApysQuery.queryKey,
    immediateApysQuery.queryFn,
  );

  const apyList = useMemo(
    () =>
      immediateApysRet.data &&
      immediateApysRet.data.map(({ apy, date }) => {
        return {
          immediateApy: apy,
          date: date.getTime(),
          strategyApy:
            strategyApys.find(
              (strategyApy) => strategyApy.date.getTime() === date.getTime(),
            )?.apy ?? 0,
        };
      }),
    [immediateApysRet.data, strategyApys],
  );
  return apyList;
};

export const useStrategyApr = () => {
  const gqlContext = useGraphQLContext();
  const strategyClient = gqlContext.strategyClient;
  const croClient = gqlContext.subgraphClient;
  return useQuery(getStrategyAprQuery(strategyClient, croClient));
};

export const useStrategyApyQuery = () => {
  const aprResult = useStrategyApr();
  const immediateApy =
    ACTIVE_APY_MODE_STRATEGY === ApyMode.day
      ? getApy(
          aprResult.data?.immediateApr ?? 0,
          ACTIVE_COMPOUND_FREQUENCY_PER_DAY_STRATEGY,
        )
      : getApyFromApr7d(
          aprResult.data?.immediateApr ?? 0,
          ACTIVE_COMPOUND_FREQUENCY_PER_DAY_STRATEGY,
        );
  const strategyApy = aprResult.data?.strategyApy ?? 0;

  return { ...aprResult, data: { immediateApy, strategyApy } };
};
