import { StaticJsonRpcProvider, Web3Provider, WebSocketProvider, } from '@ethersproject/providers';
import AtomModule from './atom';
import { VenoSDKBase } from './base';
import { LATOM_DECIMALS } from './constants';
import CroDelegatorModule from './croDelegator';
import FeeDistributorModule from './feeDistributor';
import FerroLpTokenModule from './ferroLpToken';
import FerroLpTokenAtomModule from './ferroLpTokenAtom';
import FerroSwapModule from './ferroSwap';
import FerroVaultModule from './ferroVault';
import LcroDelegatorModule from './lcroDelegator';
import LiquidAtomModule from './liquidAtom';
import LiquidCroModule from './liquidCro';
import LiquidEthModule from './liquidEth';
import LiquidTiaModule from './liquidTia';
import NftModule from './nft';
import RarityRegistryModule from './rarityRegistry';
import StakableNftModule from './stakableNft';
import TiaModule from './tia';
import TLatomModule from './tLatom';
import TLcroModule from './tLcro';
import VenoFountainModule from './venoFountain';
import VenoReservoirModule from './venoReservoir';
import VenoStormModule from './venoStorm';
import VenoStormMultiplierReaderModule from './venoStormMultiplierReader';
import VenoStormZksyncModule from './venoStormZksynct';
import VenoTokenModule from './venoToken';
import VvsCroLpTokenModule from './vvsCroLpToken';
import VvsLpTokenModule from './vvsLpToken';
import VvsTiaLpTokenModule from './vvsTiaLpToken';
import VvsZapModule from './vvsZap';
import VvsZapEstimatorModule from './vvsZapEstimator';
import WcroModule from './wcro';
import WcroDelegatorModule from './wcroDelegator';
export * from './util';
export class VenoSDK extends VenoSDKBase {
    constructor(chainType, addresses) {
        super(addresses);
        this.LiquidCro = new LiquidCroModule(this);
        this.VenoToken = new VenoTokenModule(this);
        this.VenoReservoir = new VenoReservoirModule(this);
        this.VenoFountain = new VenoFountainModule(this);
        this.FeeDistributor = new FeeDistributorModule(this);
        this.Wcro = new WcroModule(this);
        this.RarityRegistry = new RarityRegistryModule(this);
        this.FerroLpToken = new FerroLpTokenModule(this);
        this.FerroLpTokenAtom = new FerroLpTokenAtomModule(this);
        this.VvsZap = new VvsZapModule(this);
        this.VvsLpToken = new VvsLpTokenModule(this);
        this.VvsCroLpToken = new VvsCroLpTokenModule(this);
        this.TLcro = new TLcroModule(this);
        this.TLatom = new TLatomModule(this);
        this.VvsZapEstimator = new VvsZapEstimatorModule(this);
        this.VenoStormMultiplierReader = new VenoStormMultiplierReaderModule(this);
        this.LiquidAtom = new LiquidAtomModule(this);
        this.LiquidTia = new LiquidTiaModule(this);
        this.LiquidEth = new LiquidEthModule(this);
        this.Atom = new AtomModule(this);
        this.Tia = new TiaModule(this);
        this.CroDelegator = new CroDelegatorModule(this);
        this.WcroDelegator = new WcroDelegatorModule(this);
        this.LcroDelegator = new LcroDelegatorModule(this);
        this.FerroVault = new FerroVaultModule(this);
        this.VvsTiaLpToken = new VvsTiaLpTokenModule(this);
        this.invalidateContracts = () => {
            this.modules.map((module) => module.invalidateContract());
        };
        this.chainType = chainType;
        this.VenoStorm =
            this.chainType === 'zksync'
                ? new VenoStormZksyncModule(this)
                : new VenoStormModule(this);
        this.Nft = new NftModule(this, addresses.venoNft);
        this.Cruiser = new StakableNftModule(this, addresses.cronosCruisers);
        this.MinerMole = new StakableNftModule(this, addresses.minerMoles);
        this.BoomerSquad = new StakableNftModule(this, addresses.boomerSquad);
        this.ballies = new StakableNftModule(this, addresses.ballies);
        this.topDogCorgi = new StakableNftModule(this, addresses.topDogCorgi);
        this.cronosChimp = new StakableNftModule(this, addresses.cronosChimp);
        this.AtomNft = new NftModule(this, addresses.atomNft);
        this.TiaNft = new NftModule(this, addresses.tiaNft);
        this.FerroSwap = new FerroSwapModule(this, addresses.ferroSwap);
        this.FerroSwapAtom = new FerroSwapModule(this, addresses.ferroSwapAtom, LATOM_DECIMALS);
        this.modules = [
            this.Nft,
            this.LiquidCro,
            this.VenoReservoir,
            this.VenoFountain,
            this.VenoToken,
            this.FeeDistributor,
            this.VenoStorm,
            this.Wcro,
            this.RarityRegistry,
            this.FerroSwap,
            this.FerroSwapAtom,
            this.FerroLpToken,
            this.FerroLpTokenAtom,
            this.VvsZap,
            this.VvsZapEstimator,
            this.VvsLpToken,
            this.TLcro,
            this.VenoStormMultiplierReader,
            this.Cruiser,
            this.AtomNft,
            this.LiquidAtom,
            this.LiquidTia,
            this.LiquidEth,
            this.MinerMole,
            this.Atom,
            this.VvsCroLpToken,
            this.CroDelegator,
            this.WcroDelegator,
            this.LcroDelegator,
            this.FerroVault,
            this.Tia,
            this.VvsTiaLpToken,
        ];
    }
    initWithProvider(provider) {
        let externalProvider;
        const injectedProvider = provider;
        if (injectedProvider._isProvider) {
            externalProvider = new Web3Provider(injectedProvider['provider']);
        }
        else {
            externalProvider = provider;
        }
        this.provider = externalProvider;
        this.walletNotConnected = false;
        this.invalidateContracts();
    }
    initWithRpc(rpcUrl, useWebSocket) {
        this.provider = useWebSocket
            ? new WebSocketProvider(rpcUrl)
            : new StaticJsonRpcProvider(rpcUrl);
        this.walletNotConnected = true;
        this.invalidateContracts();
    }
    getBalance(addressOrName) {
        return this.provider.getBalance(addressOrName);
    }
    toJSON() {
        return {
            chainId: this.provider?.network?.chainId || '',
            chainType: this.chainType,
        };
    }
}
export * from './constants';
export { default as NftModule } from './nft';
export * from './types';
