import type { UseQueryOptions } from '@tanstack/react-query';
import { useSpecificVenoSDK } from '@ui/providers/VenoSDKProvider';

import { COIN_MODE } from './useCoinMode';
import { useTokenBalance } from './useTokenBalance';

export const useTiaBalance = (
  options: UseQueryOptions<number | undefined> = {},
) => {
  const decimals = COIN_MODE.tia.decimals;
  const sdk = useSpecificVenoSDK('cronos');
  const sdkModule = sdk.Tia;
  return useTokenBalance(sdkModule, decimals, options);
};
