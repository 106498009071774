import { BigNumber } from '@ethersproject/bignumber';
import { getTectonicTotalSupplyEndpoint } from '@ui/config/partners';
import type { CoinMode } from '@ui/hooks/useCoinMode';

import { QueryKey } from './queryKey';

export interface FetchTectonicSupplyResponse {
  tokenAmount: string;
  timestamp: string;
  exchangeRate: string;
  supplyApy: number;
  tonicSupplyApy: number;
}

export const getTAssetInfo = (coinMode: CoinMode) => ({
  queryKey: [QueryKey.TASSET_INFO, coinMode],
  queryFn: async () => {
    const endpoint = getTectonicTotalSupplyEndpoint(coinMode);
    const fetchResponse = await fetch(endpoint);
    const response =
      (await fetchResponse.json()) as FetchTectonicSupplyResponse;

    try {
      return {
        totalSupply: BigNumber.from(response.tokenAmount),
        exchangeRate: BigNumber.from(response.exchangeRate),
        supplyApy: response.supplyApy,
        tonicSupplyApy: response.tonicSupplyApy,
      };
    } catch (err) {
      throw new Error(`invalid total supply response from ${endpoint}`);
    }
  },
});
