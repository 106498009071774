import { useInfiniteQuery } from '@tanstack/react-query';
import { GraphQLContext } from '@ui/providers/GraphQLProvider';
import { getTransactionHistory } from '@ui/queries/history';
import { QueryKey } from '@ui/queries/queryKey';
import transformTransaction from '@ui/utils/transformTransaction';
import { currentWallet } from '@veno-app/wallet';
import { useContext, useMemo } from 'react';

const { useAccount } = currentWallet;

export type Transaction = {
  txnHash: string;
  type: string;
  date: Date;
  croAmount: number;
  usdAmount: number;
  id: string;
  walletAddress: string;
  lcroAmount: number;
  croPerLcro: number;
  lpTokenAmount: number;
  tTokenAmount: number;
};

const formatAddress = (address: string) =>
  address.substring(0, 5) + '...' + address.substring(address.length - 7);

const N_PER_FETCH = 15;

type PageParam =
  | undefined
  | {
      first: number;
      skip: number;
    };

export const useTransactionHistory = () => {
  const client = useContext(GraphQLContext).historyClient;
  const account = useAccount();
  const formattedAddress = useMemo(
    () => formatAddress(account ?? ''),
    [account],
  );

  return useInfiniteQuery(
    [QueryKey.TRANSACTION_HISTORY, account, formattedAddress],
    async ({ pageParam: _pageParam }) => {
      const pageParam: NonNullable<PageParam> = _pageParam || {
        first: N_PER_FETCH,
        skip: 0,
      };

      const { queryFn: getMoreTransactions } = getTransactionHistory(
        client,
        account ?? '',
        pageParam.first,
        pageParam.skip,
      );

      const next = await getMoreTransactions();

      return next.transactions.map(
        transformTransaction(account ?? '', formattedAddress),
      );
    },
    {
      getNextPageParam: (lastPage, allPages): PageParam => {
        if (lastPage.length < N_PER_FETCH) {
          // there is no next page available.
          return undefined;
        }

        return {
          first: N_PER_FETCH,
          skip: allPages.length * N_PER_FETCH,
        };
      },
    },
  );
};
