import { Button, Text } from '@chakra-ui/react';
import { useTranslations } from '@ui/i18n';

const DepositUpgradeCompletedContent: React.FC = () => {
  const t = useTranslations();
  return (
    <>
      <Text textStyle="h3" mb={8} align="center" color="text.normal">
        {t('Transaction completed!')}
      </Text>
      <Text align="center">{t('You have upgraded in vault tier')}</Text>
    </>
  );
};

const DepositUpgradeCompletedFooter: React.FC<{
  onCloseDrawer: () => void;
}> = ({ onCloseDrawer }) => {
  const t = useTranslations();
  return <Button onClick={onCloseDrawer}>{t('Done')}</Button>;
};

const DepositUpgradeCompleted = {
  Content: DepositUpgradeCompletedContent,
  Footer: DepositUpgradeCompletedFooter,
} as const;

export default DepositUpgradeCompleted;
