import type { AnnouncementMessage } from 'telegram-announcement-components';

const oldAnnouncements: AnnouncementMessage[] = [
  {
    message_id: 104,
    date: 1690366199,
    text: "\ud83d\udce2  Boomer Squad helpers is LIVE on Veno Finance \ud83d\udce2\n\nStake your Boomers now and level up your yield in your Veno Garden!\nDon't have one?  Get one from the link below! \ud83d\ude0e\n\n\ud83d\udd25 Get in on the action!\n\nLinks\nVeno: https://veno.finance/vno/\nBoomer Squad Collection: https://minted.network/collections/cronos/0xb650279e3d726b0c75c984caa55341cb87a7f501\nTweet link: https://twitter.com/VenoFinance/status/1684141555135963136?s=20",
    entities: [
      { offset: 4, length: 44, type: 'bold' },
      { offset: 197, length: 6, type: 'underline' },
      { offset: 203, length: 4, type: 'bold' },
      { offset: 209, length: 25, type: 'url' },
      { offset: 235, length: 23, type: 'bold' },
      { offset: 260, length: 84, type: 'url' },
      { offset: 345, length: 10, type: 'bold' },
      { offset: 357, length: 63, type: 'url' },
    ],
  },
  {
    message_id: 103,
    date: 1690164183,
    text: '📢 Veno x Boomer Squad Zealy Event 📢\n\nGet ready to experience another zealy sprint celebrating the launch of Boomer Squad as helpers on Veno! 🚀\n\n We are giving away 3 x Boomer Squad NFTs to the lucky winners!🏆\n\nThe more XP you earn, the higher your chance of being selected as one of the winners!🏃‍♂️ \n\n⏰ Event Duration: 24th July 2 AM UTC - 7th August 12 AM UTC.\n\nJoin now👉https://zealy.io/c/veno/questboard\n\nSpread the word and invite your friends to join the excitement!\n\nGood luck to all participants!🎉🥳👏',
    entities: [
      {
        offset: 3,
        length: 31,
        type: 'bold',
      },
      {
        offset: 379,
        length: 34,
        type: 'url',
      },
    ],
  },
  {
    message_id: 102,
    date: 1689826580,
    image: '/images/announcement-photo-102.jpg',
    caption:
      'Hey #Venofam!\n\n📣📣 Introducing Boomer Squad NFT as helpers in Veno Garden!\n\nGet those boomers working in the garden starting 26 July 2023 👉 https://veno.finance/vno/\n\nRead more here: https://twitter.com/VenoFinance/status/1681876605822717952?s=20',
    caption_entities: [
      {
        offset: 4,
        length: 8,
        type: 'hashtag',
      },
      {
        offset: 32,
        length: 12,
        type: 'bold',
      },
      {
        offset: 126,
        length: 12,
        type: 'bold',
      },
      {
        offset: 142,
        length: 25,
        type: 'url',
      },
      {
        offset: 185,
        length: 63,
        type: 'url',
      },
    ],
  },
  {
    message_id: 101,
    date: 1689322363,
    text: "🎉 LFG! Veno Finance reaches a monumental milestone of over 100M $CRO staked!\n\n🙌 A massive shoutout to the amazing #CROFam for their unwavering support. \n\n🌐 To celebrate this. we're giving away $300 worth of VNO tokens 👇\n\n🔗 https://veno.finance/cro/stake/",
    entities: [
      {
        offset: 65,
        length: 4,
        type: 'text_link',
        url: 'https://twitter.com/search?q=%24CRO&src=cashtag_click',
      },
      {
        offset: 116,
        length: 7,
        type: 'text_link',
        url: 'https://twitter.com/hashtag/CROFam?src=hashtag_click',
      },
      {
        offset: 228,
        length: 31,
        type: 'url',
      },
    ],
  },
  {
    message_id: 100,
    date: 1688969604,
    text: 'Unlock the power of $LATOM as collateral on Tectonic Finance !\n\n\nBorrow against a diverse range of tokens and earn mind-blowing 21% staking APY on ATOM 📊\n\nExperience it firsthand at https://app.tectonic.finance/markets/veno\n\n\nhttps://twitter.com/VenoFinance/status/1678227649326358532?s=20',
    entities: [
      {
        offset: 20,
        length: 6,
        type: 'cashtag',
      },
      {
        offset: 183,
        length: 41,
        type: 'url',
      },
      {
        offset: 227,
        length: 63,
        type: 'url',
      },
    ],
  },
  {
    message_id: 99,
    date: 1688616226,
    text: "Greetings #Venofam! \n\n📈 We're thrilled to share that our community has achieved an incredible milestone with 50K ATOM staked and growing rapidly!\n\n💪💼 Unlock the full potential of your assets and maximize your returns with Veno Finance.\n\n🌱 Don't miss out on this exciting opportunity! Join us now and be part of the future of decentralized finance.\n\n🎉 To mark this incredible achievement, we're hosting a special giveaway! Participate for a chance to win $100 worth of $VNO tokens!\n\n🎁 Join the giveaway here: https://gleam.io/WKa8W/veno-finance-100-vno-giveaway\n\n\nhttps://twitter.com/VenoFinance/status/1676803175716904961?s=20",
    entities: [
      {
        offset: 10,
        length: 8,
        type: 'hashtag',
      },
      {
        offset: 110,
        length: 15,
        type: 'bold',
      },
      {
        offset: 473,
        length: 4,
        type: 'cashtag',
      },
      {
        offset: 514,
        length: 52,
        type: 'url',
      },
      {
        offset: 569,
        length: 63,
        type: 'url',
      },
    ],
  },
  {
    message_id: 98,
    date: 1688552970,
    text: 'Attention all gardeners 🌱 !\n\nClaiming rewards just got a major upgrade! Introducing the Harvest All button, your one-stop shop for claiming rewards from multiple areas like Gardens, Fountains, and Reservoirs.\n\nHarvesting has never been more convenient with our sleek new UI.\n\nhttps://twitter.com/VenoFinance/status/1676503776239419393?s=20',
    entities: [
      {
        offset: 277,
        length: 63,
        type: 'url',
      },
    ],
  },
];

export default oldAnnouncements;
