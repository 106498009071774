import { VvsPair__factory } from '@contracts';
import { BigNumber } from 'ethers';
import { VenoSDKModule } from './base';
import LiquidCroModule from './liquidCro';
import { getCroUsdPrice, getLiquidTokenUsdPrice, wrapSdkTransaction, } from './util';
export default class VvsCroLpTokenModule extends VenoSDKModule {
    constructor() {
        super(...arguments);
        this.contractFactory = VvsPair__factory;
        this.contractAddress = this.sdk.addresses.vvsCroPair;
        this.LiquidCro = new LiquidCroModule(this.sdk);
    }
    async balanceOf(address) {
        return await this.getContract().balanceOf(address);
    }
    async allowance(owner, operator) {
        return await this.getContract().allowance(owner, operator);
    }
    async approve(operator, amount) {
        return await wrapSdkTransaction(this.getContract().approve(operator, amount));
    }
    async getLpUsdWorth(amount) {
        if (amount.lte(0))
            return BigNumber.from(0);
        const [[reservesCro, reservesLcro], totalSupply, croUsdPrice, croPerLcro] = await Promise.all([
            this.getContract().getReserves(),
            this.getContract().totalSupply(),
            getCroUsdPrice(),
            this.LiquidCro.croPerLcro(),
        ]);
        // be safe if something went wrong
        if (!croUsdPrice || !croPerLcro)
            return BigNumber.from(0);
        const lcroUsdPrice = getLiquidTokenUsdPrice(croUsdPrice, croPerLcro);
        const croAmount = amount.mul(reservesCro).div(totalSupply);
        const lcroAmount = amount.mul(reservesLcro).div(totalSupply);
        const factor = 100000000000;
        const result = lcroAmount
            .mul(Math.round(lcroUsdPrice * factor))
            .add(croAmount.mul(Math.round(croUsdPrice * factor)))
            .div(factor);
        return result;
    }
    async getLcroRedeemable(amount) {
        if (!amount)
            return undefined;
        if (amount.lte(0))
            return BigNumber.from(0);
        const [[_, reservesLcro], totalSupply] = await Promise.all([
            this.getContract().getReserves(),
            this.getContract().totalSupply(),
        ]);
        const lcroAmount = amount.mul(reservesLcro).div(totalSupply);
        return lcroAmount;
    }
}
