import type { DrawerProps } from '@chakra-ui/react';
import { Button, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import { useDisclaimerDrawer } from '@ui/components/DisclaimerDrawer';
import {
  ModalOrDrawer,
  ModalOrDrawerBody,
  ModalOrDrawerCloseButton,
  ModalOrDrawerContent,
  ModalOrDrawerFooter,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from '@ui/components/ModalOrDrawer';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { COIN_MODE, useGlobalCoinMode } from '@ui/hooks/useCoinMode';
import { useTranslations } from '@ui/i18n';
import { MAXW_DESKTOP_CTA } from '@ui/utils/constants';
import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import { CRONOS_CHAIN_ID, currentWallet } from '@veno-app/wallet';
import { create } from 'zustand';

import { NFTCard } from './NFTCard';
import { useNftClaimDrawerStore } from './NftClaimDrawer';
import type { NftToken } from './utils';
import {
  getNftTokenStatusTitle,
  isNftTokenRedeemable,
  nftMarketplaces,
  sellNftOnMinted,
} from './utils';

const { useChainId } = currentWallet;

export type NftDetailDrawerProps = Partial<DrawerProps>;
export const NftDetailDrawer = (props: NftDetailDrawerProps) => {
  const t = useTranslations();
  const { isOpen, nft, onClose, coinMode, onOpen } = useNftDetailDrawerStore();
  const openDisclaimerDrawer = useDisclaimerDrawer((s) => s.onOpen);
  const globalCoinMode = useGlobalCoinMode();
  const isRedeemable = isNftTokenRedeemable(nft);
  const openNftClaimDrawer = useNftClaimDrawerStore((s) => s.onOpen);
  const isDesktop = useBreakpointValue({
    base: false,
    desktop: true,
  });
  const chainId = useChainId();

  return (
    <ModalOrDrawer
      variant={{ base: 'backward', desktop: 'default' }}
      placement="right"
      {...props}
      size={{
        base: 'full',
        desktop: 'md',
      }}
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <ModalOrDrawerOverlay />
      <ModalOrDrawerContent>
        <ModalOrDrawerCloseButton />
        <ModalOrDrawerHeader />
        <ModalOrDrawerBody as={Flex} direction="column" alignItems="center">
          {nft ? (
            <NFTCard
              coinMode={coinMode}
              nft={nft}
              width="242px"
              height="412px"
              rounded="100px"
              readonly
            />
          ) : null}
          {isRedeemable ? null : (
            <Text textStyle="body" color="error" mt="10px" textAlign="center">
              {t(
                'By selling or transferring this NFT you forfeit your claim to the unstaked {coin}',
                {
                  coin: COIN_MODE[coinMode].name,
                },
              )}
            </Text>
          )}
        </ModalOrDrawerBody>
        <ModalOrDrawerFooter>
          <Flex justify="space-between">
            <Text>{t('Status')}</Text>
            <Text textStyle="bodyBold">{getNftTokenStatusTitle(nft)}</Text>
          </Flex>
          {isRedeemable ? (
            <Button
              onClick={(e) => {
                if (!nft) return;
                if (isDesktop) {
                  onClose();
                }
                openNftClaimDrawer({
                  coinMode: coinMode,
                  nfts: nft,
                  onBackward: () => {
                    onOpen({
                      coinMode,
                      nft,
                    });
                  },
                  onSuccessClose: () => {
                    onClose();
                  },
                });
                e.stopPropagation();
              }}
            >
              {t('Claim')}
            </Button>
          ) : (
            <Button
              w={{ desktop: MAXW_DESKTOP_CTA }}
              mx={{ desktop: 'auto' }}
              onClick={(e) => {
                sellNftOnMinted(
                  nft,
                  globalCoinMode,
                  openDisclaimerDrawer,
                  chainId ?? CRONOS_CHAIN_ID,
                );
                e.stopPropagation();
                onClose();
              }}
            >
              {t('View on {explorer}', {
                explorer: nftMarketplaces[chainId ?? CRONOS_CHAIN_ID],
              })}
            </Button>
          )}
          {(globalCoinMode === 'cro' || globalCoinMode === 'atom') && (
            <Button
              variant="link"
              onClick={() => {
                useDisclaimerDrawer.getState().onOpen({
                  url: EXTERNAL_LINK.pwnCreateLoan,
                });
              }}
              mt="10px"
            >
              {t('Get Loan on {platform}', {
                platform: 'PWN',
              })}
            </Button>
          )}
        </ModalOrDrawerFooter>
      </ModalOrDrawerContent>
    </ModalOrDrawer>
  );
};

export type NftDetailDrawerStore = {
  isOpen: boolean;
  coinMode: CoinMode;
  nft: NftToken | null;
  onOpen: (options: { coinMode: CoinMode; nft: NftToken }) => void;
  onClose: () => void;
};

export const useNftDetailDrawerStore = create(
  resetWhenRouterChange<NftDetailDrawerStore>((set) => ({
    isOpen: false,
    coinMode: 'cro',
    nft: null,
    onOpen: ({ coinMode, nft }) => {
      set({
        isOpen: true,
        coinMode,
        nft,
      });
    },
    onClose: () => {
      useNftDetailDrawerStore.getState().$$resetData?.();
    },
  })),
);
