import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import {
  ModalOrDragDrawerContent,
  ModalOrDrawer,
  ModalOrDrawerBody,
  ModalOrDrawerCloseButton,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from '@ui/components/ModalOrDrawer';
import { getContractAddresses } from '@ui/config/base';
import useCoinPerStakedCoin from '@ui/hooks/useCoinPerStakedCoin';
import { useCoinUsdValue } from '@ui/hooks/useCoinUsdValue';
import { type TranslationFunction, useTranslations } from '@ui/i18n';
import { formatNumber } from '@ui/utils/format';
import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import { format } from 'date-fns';
import { create } from 'zustand';

import { InDesktop } from '../MobileOrDesktop';
import UsdValue from '../UsdValue/UsdValue';
import {
  TRANSACTION_TYPE_FERRO_IN,
  TRANSACTION_TYPE_FERRO_OUT,
  TRANSACTION_TYPE_REDEEM,
  TRANSACTION_TYPE_STAKE,
  TRANSACTION_TYPE_TECTONIC_IN,
  TRANSACTION_TYPE_TECTONIC_OUT,
  TRANSACTION_TYPE_TRANSFER,
  TRANSACTION_TYPE_UNSTAKE,
  TRANSACTION_TYPE_VVS_IN,
  TRANSACTION_TYPE_VVS_OUT,
} from './transactionConstants';
import TransactionDetailContent from './TransactionDetailContent';
import type { Transaction } from './useTransactionHistory';

function TransactionDetailDrawer() {
  const t = useTranslations();
  const { isOpen, toggle, transaction } = useTransactionDetailDrawerStore();

  return (
    <ModalOrDrawer
      placement="bottom"
      size={{
        base: 'full',
        desktop: 'md',
      }}
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={() => toggle(false)}
    >
      <ModalOrDrawerOverlay />
      <ModalOrDragDrawerContent>
        <InDesktop>
          <ModalOrDrawerCloseButton />
        </InDesktop>
        <ModalOrDrawerHeader>Transaction details</ModalOrDrawerHeader>
        {transaction && (
          <ModalOrDrawerBody mt={0} as={Flex} direction="column">
            <Flex flexDir="column" gap={1}>
              <Text textStyle="h3">
                {getTransactionTypeText(t, transaction)}
              </Text>
              <Text textStyle="bodySmall">
                {transaction?.date
                  ? format(transaction?.date, "dd/MM/yyyy 'at' h:mm")
                  : null}
              </Text>
            </Flex>
            <Flex flexDir="column" gap={2} mt="65px">
              <TransactionAmount transaction={transaction} />
              <TransactionUsd transaction={transaction} />
            </Flex>
            <TransactionDetailContent transaction={transaction} mt="56px" />
          </ModalOrDrawerBody>
        )}
      </ModalOrDragDrawerContent>
    </ModalOrDrawer>
  );
}

const TransactionUsd = ({ transaction }: { transaction: Transaction }) => {
  const { data: croPerStcro } = useCoinPerStakedCoin();
  const transactionLcroCroValue = croPerStcro
    ? (transaction?.lcroAmount ?? 0) * croPerStcro
    : undefined;

  const { data: lcroCurrentUsdValue } = useCoinUsdValue(
    transactionLcroCroValue,
  );
  const { data: croCurrentUsdValue } = useCoinUsdValue(transaction?.croAmount);

  let usdCurrentValue = '0 USD';
  switch (transaction.type) {
    case TRANSACTION_TYPE_FERRO_IN:
    case TRANSACTION_TYPE_FERRO_OUT:
    case TRANSACTION_TYPE_VVS_IN:
    case TRANSACTION_TYPE_VVS_OUT:
      usdCurrentValue = `${formatNumber(
        (lcroCurrentUsdValue ?? 0) + (croCurrentUsdValue ?? 0),
        2,
        false,
        true,
      )} USD`;
      break;
    case TRANSACTION_TYPE_STAKE:
    case TRANSACTION_TYPE_UNSTAKE:
    case TRANSACTION_TYPE_REDEEM:
      usdCurrentValue = `${formatNumber(
        croCurrentUsdValue ?? 0,
        2,
        false,
        true,
      )} USD`;
      break;
    case TRANSACTION_TYPE_TECTONIC_IN:
    case TRANSACTION_TYPE_TECTONIC_OUT:
    case TRANSACTION_TYPE_TRANSFER:
      usdCurrentValue = `${formatNumber(
        lcroCurrentUsdValue ?? 0,
        2,
        false,
        true,
      )} USD`;
  }

  return (
    <Box textStyle="body" color="text.active">
      <UsdValue
        currentValue={usdCurrentValue}
        historicValue={
          formatNumber(transaction.usdAmount, 2, false, true) + ' USD'
        }
      />
    </Box>
  );
};

const TransactionAmount = ({ transaction }: { transaction: Transaction }) => {
  const croAmountNode = (
    <Flex>
      <Text
        textStyle="h3Bold"
        as="span"
        color={transaction.croAmount >= 0 ? 'green-500' : undefined}
        wordBreak="break-all"
      >
        {formatNumber(
          transaction.croAmount,
          Math.abs(transaction.croAmount) >= 100 ? 2 : 6,
          false,
          true,
        )}
      </Text>
      <Image alt="" ml={1} src="/tokens/cro.svg" w="32px" h="32px" />
    </Flex>
  );
  const lcroAmountNode = (
    <Flex>
      <Text
        textStyle="h3Bold"
        as="span"
        color={transaction.lcroAmount >= 0 ? 'green-500' : undefined}
        wordBreak="break-all"
      >
        {formatNumber(
          transaction.lcroAmount,
          Math.abs(transaction.croAmount) >= 100 ? 2 : 6,
          false,
          true,
        )}
      </Text>
      <Image alt="" ml={1} src="/tokens/lcro.svg" w="32px" h="32px" />
    </Flex>
  );
  switch (transaction.type) {
    case TRANSACTION_TYPE_FERRO_IN:
    case TRANSACTION_TYPE_FERRO_OUT:
      return (
        <>
          {croAmountNode}
          {lcroAmountNode}
        </>
      );
    case TRANSACTION_TYPE_TECTONIC_IN:
    case TRANSACTION_TYPE_TECTONIC_OUT:
      return (
        <Flex>
          <Text
            textStyle="h3Bold"
            as="span"
            color={
              transaction.type === TRANSACTION_TYPE_TECTONIC_IN
                ? 'green-500'
                : undefined
            }
          >
            {formatNumber(
              transaction.tTokenAmount,
              Math.abs(transaction.tTokenAmount) >= 100 ? 2 : 6,
              false,
              true,
            )}{' '}
            <Link
              href={`https://explorer.cronos.org/address/${
                getContractAddresses().tLcro
              }`}
              isExternal
            >
              tLCRO
            </Link>
          </Text>
        </Flex>
      );
    case TRANSACTION_TYPE_STAKE:
    case TRANSACTION_TYPE_UNSTAKE:
    case TRANSACTION_TYPE_REDEEM:
      return croAmountNode;
    case TRANSACTION_TYPE_TRANSFER:
    default:
      return lcroAmountNode;
  }
};

export const getTransactionTypeText = (
  t: TranslationFunction,
  transaction: Transaction,
): string => {
  switch (transaction.type) {
    case TRANSACTION_TYPE_STAKE:
      return t('Stake {coin}', {
        coin: 'CRO',
      });
    case TRANSACTION_TYPE_UNSTAKE:
      return t('Unstake {coin}', {
        coin: 'CRO',
      });
    case TRANSACTION_TYPE_REDEEM:
      return t('Redeem {coin}', {
        coin: 'CRO',
      });

    case TRANSACTION_TYPE_FERRO_IN:
      return t('Add Liquidity to {platform}', {
        platform: 'Ferro',
      });
    case TRANSACTION_TYPE_FERRO_OUT:
      return t('Remove Liquidity from {platform}', {
        platform: 'Ferro',
      });
    case TRANSACTION_TYPE_TECTONIC_IN:
      return t('Supply {coin} on {platform}', {
        coin: 'LCRO',
        platform: 'Tectonic',
      });
    case TRANSACTION_TYPE_TECTONIC_OUT:
      return t('Withdraw {coin} from {platform}', {
        coin: 'LCRO',
        platform: 'Tectonic',
      });
    case TRANSACTION_TYPE_TRANSFER:
      return transaction.croAmount > 0
        ? t('Received {coin}', {
            coin: 'LCRO',
          })
        : t('Sent {coin}', {
            coin: 'LCRO',
          });
    default:
      return transaction.type;
  }
};

export default TransactionDetailDrawer;

export type TransactionDetailDrawerStore = {
  isOpen: boolean;
  transaction: Transaction | null;
  toggle: (isOpen?: boolean, transaction?: Transaction | null) => void;
};

export const useTransactionDetailDrawerStore =
  create<TransactionDetailDrawerStore>(
    resetWhenRouterChange((set) => ({
      isOpen: false,
      transaction: null,
      toggle: (isOpen, transaction) => {
        set((state) => {
          return {
            transaction,
            isOpen: typeof isOpen !== 'undefined' ? isOpen : !state.isOpen,
          };
        });
      },
    })),
  );
