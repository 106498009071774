import type { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import { formatUnits, parseUnits } from '@ethersproject/units';

const bigNumberToNumber = (bn: BigNumberish, decimals = 18): number => {
  return parseFloat(formatUnits(bn, decimals));
};

// https://gist.github.com/jiggzson/b5f489af9ad931e3d186
export function convertScientificToDecimal(
  numStr: string,
  decimals: number | undefined = undefined,
): string {
  let num: number | string = Number(numStr);
  const nsign = Math.sign(num);
  //remove the sign
  num = Math.abs(num);
  //if the number is in scientific notation remove it
  if (/\d+\.?\d*e[+-]*\d+/i.test(`${num}`)) {
    const zero = '0';
    const parts = String(num).toLowerCase().split('e'); //split into coeff and exponent
    const e = Number(parts.pop()); //store the exponential part
    let l = Math.abs(e); //get the number of zeros
    const sign = e / l,
      coeff_array = parts[0].split('.');
    if (sign === -1) {
      l = l - coeff_array[0].length;
      if (l < 0) {
        num =
          coeff_array[0].slice(0, l) +
          '.' +
          coeff_array[0].slice(l) +
          (coeff_array.length === 2 ? coeff_array[1] : '');
      } else {
        num = zero + '.' + new Array(l + 1).join(zero) + coeff_array.join('');
      }
    } else {
      const dec = coeff_array[1];
      if (dec) l = l - dec.length;
      if (l < 0) {
        num = coeff_array[0] + dec.slice(0, l) + '.' + dec.slice(l);
      } else {
        num = coeff_array.join('') + new Array(l + 1).join(zero);
      }
    }
  }

  if (decimals) num = parseFloat(num.toString()).toFixed(decimals);
  return nsign < 0 ? '-' + num.toString() : num.toString();
}

export function numberToBigNumber(n: number): BigNumber;
export function numberToBigNumber(n: undefined | null): undefined;
export function numberToBigNumber(
  n: number | undefined | null,
): BigNumber | undefined;
export function numberToBigNumber(
  n: number | undefined | null,
): BigNumber | undefined {
  if (n === undefined || n === null) return undefined;
  const decimalNumStr = convertScientificToDecimal(n.toString());
  return parseUnits(decimalNumStr);
}

export default bigNumberToNumber;
