import { getPathInfo, useRealPathname } from '@ui/hooks/router';
import type { PropsWithChildren } from 'react';
import React, { createContext, useContext } from 'react';
import { usePreviousDistinct } from 'react-use';

type EntryPointContextValue = {
  prevPath: string;
  currentPath: string;
};

const EntryPointContext = createContext<EntryPointContextValue>({
  prevPath: '',
  currentPath: '',
});

export const usePrevPath = () => {
  const context = useContext(EntryPointContext);
  return context.prevPath;
};

export const useEntryPoint = (fromCurrentPath = false) => {
  const { prevPath, currentPath } = useContext(EntryPointContext);

  const path = fromCurrentPath ? currentPath : prevPath;
  const pathInfo = getPathInfo(path);

  if (pathInfo.category === 'useLiquid') {
    return 'use_lcro';
  }

  return pathInfo.category;
};

const EntryPointProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const realPathname = useRealPathname();
  const prevRealPathname = usePreviousDistinct(realPathname) ?? realPathname;

  return (
    <EntryPointContext.Provider
      value={{ prevPath: prevRealPathname, currentPath: realPathname }}
    >
      {children}
    </EntryPointContext.Provider>
  );
};

export default EntryPointProvider;
