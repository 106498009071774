import { features } from '@ui/components/Features';
import { isFeatureEnabled } from '@ui/hooks/useIsFeatureEnabled';
import usePersistentContext from '@ui/hooks/usePersistentContext';
import type { ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';
import type { IntlShape } from 'react-intl';
import { createIntl, createIntlCache, IntlProvider } from 'react-intl';

import { defaultLanguage, languages } from './config';
import type { TranslationFunction } from './utils';
import { createTranslationFunction } from './utils';

export type I18nPageProps = {
  langResource?: any;
  lang?: string;
  usingDefaultLanguage: boolean;
};

export type I18nProviderProps = I18nPageProps & {
  children: ReactNode;
};

const intlCache = createIntlCache();

export const i18next: {
  langResource: undefined | Record<string, string>;
  language: string;
  intl: IntlShape | undefined;
  t: TranslationFunction;
} = {
  langResource: undefined,
  language: defaultLanguage.lang,
  intl: undefined,
  // only used in non-react environment
  t: (id: any, values?: any, options?: any) => {
    if (i18next.intl) {
      return createTranslationFunction(i18next.intl)(
        id,
        values,
        options,
      ) as string;
    }
    return id;
  },
};

export const I18nProvider = ({
  children,
  lang,
  langResource,
  usingDefaultLanguage,
}: I18nProviderProps) => {
  const [selectedLanguage] = usePersistentContext('selectedLanguage');

  const useBrowserLanguage = useMemo(
    () =>
      isFeatureEnabled(features.i18n) &&
      usingDefaultLanguage &&
      typeof window !== 'undefined' &&
      !!navigator.language,
    [usingDefaultLanguage],
  );

  const [newLangParams, setNewLangParams] = useState({
    lang: lang ?? defaultLanguage.lang,
    resource: langResource,
  });

  useEffect(() => {
    const updateLanguage = async () => {
      if (useBrowserLanguage || selectedLanguage) {
        const languageToUse = selectedLanguage ?? navigator.language;
        const isLanguageSupported = languages
          .map((l) => l.lang)
          .includes(languageToUse);
        isLanguageSupported &&
          setNewLangParams({
            lang: languageToUse,
            resource: await import(`@ui/locales/${languageToUse}/common.json`),
          });
      }
    };

    updateLanguage();
  }, [useBrowserLanguage, selectedLanguage]);

  if (!i18next.intl || i18next.intl.locale !== newLangParams.lang) {
    i18next.language = newLangParams.lang;
    i18next.langResource = newLangParams.resource;
    i18next.intl = createIntl(
      {
        locale: newLangParams.lang,
        messages: newLangParams.resource,
      },
      intlCache,
    );
    if (typeof window !== 'undefined') {
      window.document.documentElement.lang = newLangParams.lang;
    }
  }

  return (
    <IntlProvider
      messages={newLangParams.resource}
      locale={newLangParams.lang}
      defaultLocale={defaultLanguage.lang}
    >
      {children}
    </IntlProvider>
  );
};
