import { IERC20__factory } from '@contracts';
import { VenoSDKModule } from './base';
import { wrapSdkTransaction } from './util';
export default class WcroModule extends VenoSDKModule {
    constructor() {
        super(...arguments);
        this.contractFactory = IERC20__factory;
        this.contractAddress = this.sdk.addresses.wcro;
    }
    async balanceOf(address) {
        return await this.getContract().balanceOf(address);
    }
    async allowance(owner, operator) {
        return await this.getContract().allowance(owner, operator);
    }
    async approve(operator, amount) {
        return await wrapSdkTransaction(this.getContract().approve(operator, amount));
    }
}
