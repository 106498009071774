import { useQuery } from '@tanstack/react-query';
import { TokenIcon } from '@ui/components/TokenIcon';
import type { TokenProtocol } from '@ui/components/useYourTokenDrawer';
import { useSingleFinanceLendInfo } from '@ui/hooks/useSingleFinanceLendInfo';
import { useTranslations } from '@ui/i18n';
import { getVvsApy } from '@ui/queries/partnerApys';
import { getVvsTvl } from '@ui/queries/vvsTvl';
import { formatNumber, formatPercentage } from '@ui/utils/format';
import { CRONOS_CHAIN_ID, ZKSYNC_CHAIN_ID } from '@veno-app/wallet';

export const useVnoProtocols = (): TokenProtocol[] => {
  const t = useTranslations();

  const vvsApyRet = useQuery({
    ...getVvsApy(),
  });
  const vvsTvlRet = useQuery({
    ...getVvsTvl(),
  });
  const singleFinanceLendTvlRet = useSingleFinanceLendInfo();
  const singleFinanceFarmTvlRet = useSingleFinanceLendInfo();

  const result: TokenProtocol[] = [];

  const list: (TokenProtocol | null)[] = [
    {
      logo: '/images/use-tectonic.png',
      title: 'Tectonic',
      subtitle: t('Supply {coin}', {
        coin: 'VNO',
      }),
      description: t(
        'Supply and borrow {coin} on the largest money market on Cronos',
        {
          coin: 'VNO',
        },
      ),

      tokens: (
        <>
          <TokenIcon token="vno" w="32px" h="32px" />
          <TokenIcon token="lcro" w="32px" h="32px" />
          <TokenIcon token="latom" w="32px" h="32px" />
        </>
      ),
      url: 'https://app.tectonic.finance/markets/defi',
      chainIds: [CRONOS_CHAIN_ID],
    },
    {
      logo: '/images/single-finance-logo.png',
      title: 'Single Finance',
      titleTail: singleFinanceLendTvlRet.data?.apy
        ? formatPercentage(singleFinanceLendTvlRet.data.apy)
        : undefined,
      tvl: singleFinanceLendTvlRet.data?.tvlInUSD
        ? `$${formatNumber(singleFinanceLendTvlRet.data.tvlInUSD, 2)}`
        : undefined,
      subtitle: t('Lend {coin}', {
        coin: 'VNO',
      }),
      description: t('Supply VNO to leveraged yield farmers and earn interest'),
      tokens: <TokenIcon token="vno" w="32px" h="32px" />,
      url: 'https://app.singlefinance.io/lend',
      chainIds: [CRONOS_CHAIN_ID],
    },
    {
      logo: '/images/single-finance-logo.png',
      title: 'Single Finance',
      titleTail: singleFinanceFarmTvlRet.data?.apy
        ? formatPercentage(singleFinanceFarmTvlRet.data.apy)
        : undefined,
      tvl: singleFinanceFarmTvlRet.data?.tvlInUSD
        ? `$${formatNumber(singleFinanceFarmTvlRet.data.tvlInUSD, 2)}`
        : undefined,
      subtitle: t('Leveraged VNO Farm'),
      description: t('Farm VNO with leverage to increase your APY'),
      tokens: <TokenIcon token="vno" w="32px" h="32px" />,
      url: 'https://app.singlefinance.io/farm',
      chainIds: [CRONOS_CHAIN_ID],
    },
    {
      logo: '/images/use-vvs.png',
      title: 'VVS Finance',
      titleTail: vvsApyRet.data
        ? `${vvsApyRet.data.toFixed(2)}% APY`
        : undefined,
      tvl: vvsTvlRet.data ? `$${formatNumber(vvsTvlRet.data, 2)}` : undefined,
      subtitle: t('Farm {coin}', {
        coin: 'VNO',
      }),
      description: t('Farm VNO to earn VVS rewards'),
      tokens: (
        <>
          <TokenIcon token="vno" w="32px" h="32px" />
          <TokenIcon token="lcro" w="32px" h="32px" />
        </>
      ),
      url: 'https://vvs.finance/farms',
      chainIds: [CRONOS_CHAIN_ID],
    },
    {
      logo: '/images/bored-candy-city-logo.webp',
      title: 'Bored Candy City',
      subtitle: t('Provide {coin} Liquidity', {
        coin: 'VNO',
      }),
      description: t('Become a liquidity provider and earn trading fees'),
      tokens: (
        <>
          <TokenIcon token="vno" w="32px" h="32px" />
          <TokenIcon token="lcro" w="32px" h="32px" />
        </>
      ),
      url: 'https://candycity.finance/?chainId=25',
      chainIds: [CRONOS_CHAIN_ID],
    },
    {
      logo: '/images/crypto-com-exchange-logo.webp',
      title: 'Crypto.com Exchange',
      subtitle: t('Trade {coin}', {
        coin: 'VNO',
      }),
      description: t(
        "Trade VNO on the world's fastest and most secure crypto exchange",
      ),
      tokens: <TokenIcon token="vno" w="32px" h="32px" />,
      url: 'https://crypto.com/exchange',
      chainIds: [CRONOS_CHAIN_ID, ZKSYNC_CHAIN_ID],
    },
    {
      logo: '/images/crypto-com-exchange-logo.webp',
      title: 'Crypto.com App',
      subtitle: t('Buy and Sell {coin}', {
        coin: 'VNO',
      }),
      description: t('Buy and Sell {coin} on the {platform}', {
        coin: 'VNO',
        platform: 'Crypto.com App',
      }),
      tokens: <TokenIcon token="vno" w="32px" h="32px" />,
      url: 'https://crypto.com/app',
      chainIds: [CRONOS_CHAIN_ID, ZKSYNC_CHAIN_ID],
    },
    {
      logo: '/images/gate-io-icon.png',
      title: 'Gate.io',
      subtitle: t('Trade {coin}', {
        coin: 'VNO',
      }),
      description: t('Buy and Sell {coin} on the {platform}', {
        coin: 'VNO',
        platform: 'Gate.io spot exchange',
      }),
      tokens: <TokenIcon token="vno" w="32px" h="32px" />,
      url: 'https://www.gate.io/',
      chainIds: [CRONOS_CHAIN_ID, ZKSYNC_CHAIN_ID],
    },
    {
      logo: '/images/PWN_logo_black.svg',
      title: 'PWN.xyz',
      subtitle: t('Borrow against your {coin}', {
        coin: 'VNO',
      }),
      description: t('Use your {coin} to take out a peer to peer loan', {
        coin: 'VNO',
      }),
      tokens: (
        <>
          <TokenIcon token="lcro" w="32px" h="32px" />
          <TokenIcon token="vno" w="32px" h="32px" />
          <TokenIcon token="latom" w="32px" h="32px" />
        </>
      ),
      url: 'https://app.pwn.xyz/#/create-loan',
      chainIds: [CRONOS_CHAIN_ID],
    },
    {
      logo: '/images/use-syncswap.svg',
      title: 'SyncSwap',
      subtitle: t('Supply {coin}', {
        coin: 'VNO',
      }),
      description: t('Become a liquidity provider and earn trading fees'),
      tokens: (
        <>
          <TokenIcon token="vno" w="32px" h="32px" />
          <TokenIcon token="leth" w="32px" h="32px" />
        </>
      ),
      url: 'https://syncswap.xyz/pool/0xeFF7ea0713764A38D45c2a75b39486b2BbcE28ce',
      chainIds: [ZKSYNC_CHAIN_ID],
    },
  ];

  for (const item of list) {
    if (item) {
      result.push(item);
    }
  }

  return result;
};
