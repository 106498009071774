/**
 * Modal theme
 * @see https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src/components/modal.ts
 */
import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import merge from 'lodash/merge';
import { textStyles } from '../textStyles';
import { ColorThemeVars } from '../utils/colorThemes';
import { viewportHeightVariable } from '../utils/useInjectedCssVarOfViewport';
const CLOSE_BUTTON_TOP = 12;
const CLOSE_BUTTON_SIZE = 40;
const HEADER_TOP = (CLOSE_BUTTON_TOP + CLOSE_BUTTON_SIZE) / 2 - 7;
const NAV_HEIGHT = 61;
const DIALOG_MY = 64;
export const Modal = {
    parts: parts.keys,
    baseStyle(props) {
        const maxHeightInScrollInside = `calc(var(${viewportHeightVariable}) - ${NAV_HEIGHT + DIALOG_MY + DIALOG_MY}px)`;
        return {
            dialogContainer: {
                top: `${NAV_HEIGHT}px`,
            },
            dialog: {
                bg: 'bg.card',
                mt: `${DIALOG_MY}px`,
                mb: `${DIALOG_MY}px`,
                minHeight: `min(600px, ${maxHeightInScrollInside})`,
                maxH: props['scrollBehavior'] === 'inside'
                    ? maxHeightInScrollInside
                    : undefined,
            },
            overlay: {
                top: {
                    base: 0,
                    desktop: `${NAV_HEIGHT}px`,
                },
                bg: {
                    base: 'bg.gauze',
                    desktop: 'rgba(23, 24, 26, 0.75)',
                },
            },
            header: {
                ...textStyles.bodySmall,
                px: '16px',
                pt: `${HEADER_TOP}px`,
                textAlign: {
                    base: 'left',
                    lg: 'center',
                },
            },
            closeButton: {
                color: props.colorMode === 'light'
                    ? `var(${ColorThemeVars.tabColorActive})`
                    : `var(${ColorThemeVars.tabColorActiveDark})`,
                rounded: '4px',
                top: `${CLOSE_BUTTON_TOP}px`,
                right: '16px',
                p: 0,
            },
            body: {
                px: '16px',
            },
            footer: {
                px: '16px',
                pt: '24px',
                pb: '40px',
                flexDirection: 'column',
                gap: '10px',
                alignItems: 'stretch',
                backgroundColor: 'bg.mobileAppBar',
                backdropFilter: 'auto',
                backdropBlur: '2px',
            },
        };
    },
    variants: {
        wholeScreen: wholeScreenVariant,
    },
    sizes: {
        md: {
            dialog: {
                maxWidth: 'unset',
                width: '400px',
            },
        },
        '3xl': {
            dialog: {
                maxWidth: 'unset',
                width: '744px',
                minH: '712px',
            },
            dialogContainer: {
                overflow: 'auto',
            },
        },
        full(props) {
            /**
             * Fix missing modal footer on iOS.
             * @see https://github.com/chakra-ui/chakra-ui/issues/4903
             */
            const isScrollInside = props['scrollBehavior'] === 'inside';
            return merge(wholeScreenVariant(props), {
                dialogContainer: {
                    ...(!isScrollInside && fullStyles),
                },
                dialog: {
                    ...(isScrollInside && fullStyles),
                    my: 0,
                    mt: 0,
                    mb: 0,
                },
            });
        },
    },
    defaultProps: {},
};
function wholeScreenVariant(props) {
    const maxHeightInScrollInside = `calc(var(${viewportHeightVariable}) - ${DIALOG_MY + DIALOG_MY}px)`;
    return {
        dialogContainer: {
            // big modal don't need to keep away from the navbar
            top: '0',
        },
        overlay: {
            // big modal don't need to keep away from the navbar
            top: '0',
        },
        dialog: {
            maxH: props['scrollBehavior'] === 'inside'
                ? maxHeightInScrollInside
                : undefined,
        },
    };
}
const fullStyles = {
    minHeight: 'auto',
    maxHeight: 'unset',
    height: `var(${viewportHeightVariable}, -webkit-fill-available)`,
};
