import { BigNumber } from '@ethersproject/bignumber';
import { VenoSDKModule } from './base';
import { wrapSdkTransaction } from './util';
export default class LiquidCoinModule extends VenoSDKModule {
    getReceived(receipt) {
        const events = receipt.events ?? [];
        events.reverse();
        const args = events.find((e) => e.event === 'Transfer')?.args;
        if (!args || args.length !== 3)
            return null;
        return args[2];
    }
    async requestUnbond(shareAmount, receiver) {
        return await wrapSdkTransaction(this.getContract().requestUnbond(shareAmount, receiver));
    }
    async unbond(tokenID, receiver) {
        return await wrapSdkTransaction(this.getContract().unbond(tokenID, receiver));
    }
    async batchUnbond(tokenIDs, receiver) {
        return await wrapSdkTransaction(this.getContract().batchUnbond(tokenIDs, receiver));
    }
    async balanceOf(address) {
        return await this.getContract().balanceOf(address);
    }
    async totalSupply() {
        return await this.getContract().totalSupply();
    }
    async coinPerStakedCoin() {
        const [pooled, supply] = await Promise.all([
            this.totalPooled(),
            this.totalSupply(),
        ]);
        const precision = 1000000000000000;
        const result = ((pooled?.gt(0) ? pooled : BigNumber.from(1))
            ?.mul(precision)
            .div(supply.gt(0) ? supply : 1)
            ?.toNumber() ?? precision) / precision;
        return result;
    }
    async token2UnbondRequest(tokenId) {
        return await this.getContract().token2UnbondRequest(tokenId);
    }
    async EXCHANGE_RATE_PRECISION() {
        return await this.getContract().EXCHANGE_RATE_PRECISION();
    }
    async batch2UnbondingStatus(batchNo) {
        return await this.getContract().batch2UnbondingStatus(batchNo);
    }
    /**
     * @param value amount of Atom to stake
     * @returns expected amount of lAtom received in return
     */
    async convertToShare(value) {
        return await this.getContract().convertToShare(value);
    }
    async getUnbondUnlockDate() {
        return await this.getContract().getUnbondUnlockDate();
    }
    async allowance(owner, operator) {
        return await this.getContract().allowance(owner, operator);
    }
    async approve(operator, amount) {
        return await wrapSdkTransaction(this.getContract().approve(operator, amount));
    }
}
