import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';
import type { QueryKey } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import {
  getAtomUsdPrice,
  getCroUsdPrice,
  getEthUsdPrice,
  getTiaUsdPrice,
} from '@ui/queries/prices';
import { useMemo } from 'react';

import type { CoinMode } from './useCoinMode';
import { COIN_MODE, useCoinMode } from './useCoinMode';

/**
 * @param croValue cro value with 1e18 decimals
 * @returns corresponding usd value according to price api, scaled by 1e18
 */
export const useCoinUsdValue = <T extends BigNumber | number | null>(
  croValue?: T,
  _coinMode?: CoinMode,
  enabled?: boolean,
): {
  data?: T;
  isLoading: boolean;
  isFetched: boolean;
  error: unknown;
} => {
  const globalCoinMode = useCoinMode();
  const coinMode = _coinMode ?? globalCoinMode;
  let usdPriceQuery: {
    queryKey: string[] | QueryKey[];
    queryFn: () => Promise<number>;
  };

  if (coinMode === 'cro') usdPriceQuery = getCroUsdPrice();
  else if (coinMode === 'eth') usdPriceQuery = getEthUsdPrice();
  else if (coinMode === 'atom') usdPriceQuery = getAtomUsdPrice();
  else if (coinMode === 'tia') usdPriceQuery = getTiaUsdPrice();
  else {
    throw new Error(`Unsupported coin mode in useCoinUsdValue: ${coinMode}`);
  }
  const {
    data: usdPrice,
    isLoading,
    isFetched,
    error,
  } = useQuery(usdPriceQuery.queryKey, usdPriceQuery.queryFn, {
    staleTime: 20_000,
    enabled: enabled !== false && croValue !== undefined,
  });

  const data = useMemo(
    () =>
      (!usdPrice || croValue === undefined || croValue === null
        ? undefined
        : typeof croValue === 'number'
        ? usdPrice * croValue
        : BigNumber.from(Math.round(usdPrice * 100_000_000))
            .mul(10 ** (18 - COIN_MODE[coinMode].decimals))
            .mul(croValue)
            .div(100_000_000)) as T,
    [coinMode, croValue, usdPrice],
  );
  return { data, isLoading, isFetched, error };
};

export const useOneCoinUsdPrice = () => {
  const coinMode = useCoinMode();
  return useCoinUsdValue(parseUnits('1', COIN_MODE[coinMode].decimals));
};
