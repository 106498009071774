import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Image,
} from '@chakra-ui/react';
import { useTranslations } from '@ui/i18n';
import { ArrowDown } from '@veno-app/icons';
import React, { useMemo } from 'react';

import { sections } from './const';
import { useFooterArticlesSection } from './FooterArticlesProvider';
import { FooterSectionEntry } from './FooterSectionEntry';

function FooterSectionsMobile() {
  const t = useTranslations();
  const footerArticlesSection = useFooterArticlesSection();
  const mergedSections = useMemo(
    () =>
      footerArticlesSection.entries.length
        ? [...sections, footerArticlesSection]
        : sections,
    [footerArticlesSection],
  );

  return (
    <>
      <Accordion variant="footer" w="full" mt={6} allowToggle>
        {mergedSections.map((section, i) => {
          const titleToUse =
            typeof section.title === 'string'
              ? section.title
              : t(...section.title);
          return (
            <AccordionItem key={i}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box
                        textStyle="h4Bold"
                        as="span"
                        flex="1"
                        textAlign="left"
                      >
                        {titleToUse}
                      </Box>
                      <Icon
                        as={ArrowDown}
                        transition="transform 0.2s"
                        transform={isExpanded ? 'rotate(180deg)' : undefined}
                      />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {section.entries.map((entry) => (
                      <FooterSectionEntry key={entry.link} {...entry} />
                    ))}
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          );
        })}
      </Accordion>
      <Flex align="center" mt={8} gap={4}>
        <Image
          alt="Powered by Cronos"
          src="/images/cronoslabs.svg"
          w="92px"
          h="26px"
        />
        <Image
          alt="Powered by Cronos"
          src="/images/zksync-network.svg"
          w="93px"
          h="18px"
        />
      </Flex>
    </>
  );
}

export default FooterSectionsMobile;
