import type { ButtonProps, FlexProps } from '@chakra-ui/react';
import { Box, Button, Flex } from '@chakra-ui/react';
import type { FC, ReactNode } from 'react';
import React from 'react';

type NavigationCardProps = {
  icon: ReactNode;
  title: ReactNode;
  subtitle: ReactNode;
  children: ReactNode;
} & Omit<FlexProps, 'title'>;

export const NavigationCard: FC<NavigationCardProps> = ({
  icon,
  title,
  subtitle,
  children,
  ...flexProps
}) => {
  return (
    <Flex
      flexDir="column"
      gap={6}
      rounded="5px"
      bgColor="bg-main"
      p="8px 16px 16px"
      {...flexProps}
    >
      <Flex gap={2}>
        {icon}
        <Flex flex="1" flexDir="column" gap={2}>
          <Box textStyle="ctaCopy" color="brand-primary">
            {title}
          </Box>
          <Box textStyle="captionBold" color="text-light">
            {subtitle}
          </Box>
        </Flex>
      </Flex>
      <Flex gap={3} justify="flex-end">
        {children}
      </Flex>
    </Flex>
  );
};

export const NavigationButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    isPrimary?: boolean;
  }
>(({ isPrimary, ...props }, ref) => {
  const primaryProps: ButtonProps = isPrimary
    ? {
        bgColor: 'brand-primary',
        color: 'white',
        bgImage: 'linear-gradient(135.52deg, #5E4C8A 14.99%, #1D0063 84.37%)',
      }
    : {};
  return (
    <Button
      ref={ref}
      size="sm"
      w="full"
      fontSize="12px"
      variant="raised"
      rounded="full"
      {...primaryProps}
      {...props}
    />
  );
});
NavigationButton.displayName = 'NavigationButton';
