import type { ModalProps } from '@chakra-ui/react';
import { Box, Flex, Image, Modal, ModalContent, Text } from '@chakra-ui/react';
import { pathDict } from '@ui/config/paths';
import { useTranslations } from '@ui/i18n';
import Link from '@ui/overrides/next/link';
import {
  formatNumber,
  formatPercentage,
  formatTokenAmount,
} from '@ui/utils/format';
import { useStrategyApy, useStrategyTvl } from '@ui/utils/strategy';
import { useBestStrategyCroValue } from '@ui/views/Strategy/useReceiveTokenInfo';
import { ZKSYNC_ENABLED } from '@veno-app/wallet';

import Features from '../Features';
import { TokenIcon } from '../TokenIcon';
import { SAFE_BOTTOM_SPACE } from '.';
import { NavigationButton, NavigationCard } from './NavigationCard';
import StakeCoinNavigationCard from './StakeCoinNavigationCard';

const strategyBgProps = !ZKSYNC_ENABLED
  ? {
      bgImage: "url('/images/pattern-liquidity.png')",
      bgSize: 'cover',
      bgRepeat: 'no-repeat',
      bgPos: 'center center',
    }
  : {
      bgImage:
        "url('/images/navigation-card-cronos.svg'), url('/images/navigation-card-strategy.png')",
      bgSize: 'auto 100%, cover',
      bgRepeat: 'no-repeat, no-repeat',
      bgPos: 'top right, center center',
    };

export const NavigationModal = (props: Omit<ModalProps, 'children'>) => {
  const t = useTranslations();
  const strategyTvl = useStrategyTvl();
  const strategyApyRet = useStrategyApy();
  const ownedDepositAmountRet = useBestStrategyCroValue();

  return (
    <Modal size="full" scrollBehavior="outside" {...props}>
      <ModalContent
        bgColor="bg-gauze"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        pb={`${SAFE_BOTTOM_SPACE}px`}
        containerProps={{
          zIndex: 'banner',
        }}
      >
        <Box
          bg="linear-gradient(180deg, #743956 0%, rgba(116, 57, 86, 0.00) 100%)"
          position="fixed"
          h="87px"
          w="full"
          top="0"
          pointerEvents="none"
          zIndex="sticky"
        />
        <Flex w="full" pt={4} px={4} flexDir="column" gap={3}>
          <Features.strategyVault>
            <NavigationCard
              {...strategyBgProps}
              icon={<TokenIcon token="cro" w="32px" h="32px" />}
              title={
                <Flex justify="space-between">
                  <Text textTransform="uppercase">
                    {t('Liquidity strategy')}
                  </Text>
                  {ZKSYNC_ENABLED && (
                    <Image
                      src="/images/cronos-network.svg"
                      w="48px"
                      h="10px"
                      alt="cronos-network"
                    />
                  )}
                </Flex>
              }
              subtitle={
                <Flex flexDir="column" gap={1.5}>
                  <Text>
                    {t('Your value')}:{' '}
                    {formatTokenAmount(ownedDepositAmountRet ?? 0, 2)} CRO
                  </Text>
                  <Flex justify="space-between">
                    <Text>
                      {t('TVL')}: {formatNumber(strategyTvl.data ?? 0, 2)} USD
                    </Text>
                    <Text
                      fontWeight="800"
                      textAlign="right"
                      textTransform="uppercase"
                    >
                      {strategyApyRet.data &&
                      strategyApyRet.data.strategyApy > 0
                        ? formatPercentage(strategyApyRet.data.strategyApy)
                        : '--'}{' '}
                      APY
                    </Text>
                  </Flex>
                </Flex>
              }
            >
              <Link href={pathDict.cro.strategy.withdraw} passHref>
                <NavigationButton
                  as="a"
                  w="30%"
                  textTransform="uppercase"
                  onClick={props.onClose}
                >
                  {t('Withdraw')}
                </NavigationButton>
              </Link>
              <Link href={pathDict.cro.strategy.deposit} passHref>
                <NavigationButton
                  as="a"
                  w="30%"
                  isPrimary
                  textTransform="uppercase"
                  onClick={props.onClose}
                >
                  {t('Deposit')}
                </NavigationButton>
              </Link>
            </NavigationCard>
          </Features.strategyVault>
          <StakeCoinNavigationCard
            coinMode="eth"
            balanceDecimals={4}
            onClose={props.onClose}
            network="zksync"
          />
          <StakeCoinNavigationCard
            coinMode="atom"
            network="cronos"
            onClose={props.onClose}
          />
          <StakeCoinNavigationCard
            coinMode="cro"
            network="cronos"
            onClose={props.onClose}
          />
          <StakeCoinNavigationCard
            coinMode="tia"
            network="cronos"
            onClose={props.onClose}
          />
        </Flex>
      </ModalContent>
    </Modal>
  );
};
