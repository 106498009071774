import type { FlexProps } from '@chakra-ui/react';
import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

export type MaintenanceWarningProps = FlexProps;

function MaintenanceWarning({ ...props }: MaintenanceWarningProps) {
  return (
    <Flex flexDir="column" gap={2} w="full" textAlign="center" {...props}>
      <Text
        textStyle={{ base: 'h3', desktop: 'h1' }}
        fontWeight={{ desktop: 'normal' }}
      >
        Mainnet upgrade in progress
      </Text>
      <Text
        pt={{ base: 0, desktop: 4 }}
        textStyle={{ base: 'body', desktop: 'h3' }}
        color="text.light"
        w="full"
      >
        Veno is currently unavailable as the underlying Cronos Chain Mainnet is
        being upgraded.
        <br />
        <br />
        The upgrade should be complete within hours, and all the chain states
        will be paused and resume normally after the upgrade
      </Text>
    </Flex>
  );
}

export default MaintenanceWarning;
