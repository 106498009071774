import {
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useExplorerLabel } from '@ui/hooks/useExplorer';
import { useIFrameTheme } from '@ui/hooks/useIFrameTheme';
import { useIsOnVvs } from '@ui/hooks/useIsOnVvs';
import { useMaxContentWidth } from '@ui/hooks/useMaxContentWidth';
import { useTranslations } from '@ui/i18n';
import { MAXW_DESKTOP_CTA } from '@ui/utils/constants';
import { withNoSSR } from '@ui/utils/withNoSSR';
import { InColorMode } from '@veno-app/chakra';
import { currentWallet, ZKSYNC_ENABLED } from '@veno-app/wallet';
import { useEffect } from 'react';

import {
  AtomDrawer,
  CroDrawer,
  LatomDrawer,
  LcroDrawer,
  LtiaDrawer,
  TiaDrawer,
  VnoDrawer,
} from '../GetAssetDrawers';
import {
  InDesktop,
  InMobile,
  RenderInMobile,
  useIsDesktop,
} from '../MobileOrDesktop';
import { AccountCopyButton } from './AccountCopyButton';
import AccountModalDrawerContentsCronos from './AccountModalDrawerContentsCronos';
import AccountModalDrawerContentsZksync from './AccountModalDrawerContentsZksync';
import AccountModalInfoCronos from './AccountModalInfoCronos';
import AccountModalInfoZksync from './AccountModalInfoZksync';
import AccountModalNetworkSwitchButton from './AccountModalNetworkSwitchButton';
import useAccountModalData from './useAccountModalData';
import { useAccountModalStore } from './useAccountModalStore';

const { disconnect, useIsCronosChainId, useIsZksyncChainId } = currentWallet;

export const AccountModal = withNoSSR(() => {
  const t = useTranslations();
  const isDesktop = useIsDesktop();
  const isOnVvs = useIsOnVvs();
  const { account, explorerUrl } = useAccountModalData();
  const isCronos = !ZKSYNC_ENABLED || useIsCronosChainId();
  const isZksync = ZKSYNC_ENABLED && useIsZksyncChainId();
  const maxContentWidth = useMaxContentWidth();
  const explorerLabel = useExplorerLabel();
  const {
    openDrawer,
    setOpenDrawer,
    isOpen,
    toggleAccountModal: toggle,
  } = useAccountModalStore();

  const { colorMode } = useIFrameTheme();

  const showDrawerContentInModal = isDesktop && !!openDrawer;

  // close all the drawers when modal is closed
  useEffect(() => {
    if (setOpenDrawer && !isOpen) {
      setOpenDrawer('');
    }
  }, [isOpen, setOpenDrawer]);

  return (
    <>
      <InColorMode colorMode={colorMode}>
        <Modal
          size={{
            base: 'full',
            desktop: 'md',
          }}
          scrollBehavior="inside"
          onClose={toggle}
          isOpen={isOpen}
        >
          <ModalOverlay />
          <ModalContent position="relative" w={maxContentWidth}>
            <ModalHeader textAlign="left">
              <Text
                textStyle={{
                  base: 'h3',
                  desktop: 'h4',
                }}
              >
                {t('Your wallet')}
              </Text>
            </ModalHeader>
            <ModalCloseButton
              variant={
                isDesktop ? undefined : isOnVvs ? 'vvsLight' : 'raisedActive'
              }
            />
            <ModalBody
              display="flex"
              flexDir="column"
              css={{
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                scrollbarWidth: 'none',
              }}
            >
              {ZKSYNC_ENABLED && !openDrawer && (
                <Flex mb="10px" justify="space-between" alignItems="center">
                  <Text>{t('Switch network')}</Text>
                  <AccountModalNetworkSwitchButton />
                </Flex>
              )}
              {!isOnVvs && <AccountCopyButton />}
              {explorerUrl && !isOnVvs ? (
                <Box hidden={showDrawerContentInModal} mt="27px">
                  <Link href={`${explorerUrl}/address/${account}`} isExternal>
                    {explorerLabel}
                  </Link>
                </Box>
              ) : null}
              <Flex
                hidden={showDrawerContentInModal}
                direction="column"
                gap="16px"
                my="30px"
              >
                {isCronos && <AccountModalInfoCronos />}
                {isZksync && <AccountModalInfoZksync />}
              </Flex>
              {showDrawerContentInModal && (
                <Flex
                  flexGrow={1}
                  mt="62px"
                  minH="100%"
                  flexDir="column"
                  justify="space-between"
                >
                  {isCronos && <AccountModalDrawerContentsCronos />}
                  {isZksync && <AccountModalDrawerContentsZksync />}
                </Flex>
              )}
            </ModalBody>
            <ModalFooter hidden={showDrawerContentInModal} bottom="0">
              <InMobile>
                <Button
                  variant={isOnVvs ? 'vvs' : undefined}
                  onClick={() => toggle()}
                >
                  {t('Close wallet')}
                </Button>
                <Link
                  mt="22px"
                  mb="5px"
                  variant="secondary"
                  onClick={async () => {
                    await disconnect();
                    toggle(false);
                  }}
                  textAlign="center"
                >
                  {t('Disconnect wallet')}
                </Link>
              </InMobile>
              <InDesktop>
                <Flex gap={2}>
                  <Button
                    mx="auto"
                    w={MAXW_DESKTOP_CTA}
                    variant="secondary"
                    onClick={async () => {
                      await disconnect();
                      toggle(false);
                    }}
                  >
                    {t('Disconnect wallet')}
                  </Button>
                </Flex>
              </InDesktop>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </InColorMode>
      <RenderInMobile>
        <CroDrawer
          isOpen={openDrawer === 'cro'}
          onClose={() => setOpenDrawer('')}
        />
        <LcroDrawer
          isOpen={openDrawer === 'lcro'}
          onClose={() => setOpenDrawer('')}
        />
        <AtomDrawer
          isOpen={openDrawer === 'atom'}
          onClose={() => setOpenDrawer('')}
        />
        <LatomDrawer
          isOpen={openDrawer === 'latom'}
          onClose={() => setOpenDrawer('')}
        />
        <VnoDrawer
          isOpen={openDrawer === 'vno'}
          onClose={() => setOpenDrawer('')}
        />
        <LtiaDrawer
          isOpen={openDrawer === 'ltia'}
          onClose={() => setOpenDrawer('')}
        />
        <TiaDrawer
          isOpen={openDrawer === 'tia'}
          onClose={() => setOpenDrawer('')}
        />
      </RenderInMobile>
    </>
  );
});
