import type { FlexProps } from '@chakra-ui/react';
import {
  Box,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
} from '@chakra-ui/react';
import { pathDict } from '@ui/config/paths';
import { useLanguage, useTranslations } from '@ui/i18n';
import NextLink from '@ui/overrides/next/link';
import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import { Faq, Lang, Stats } from '@veno-app/icons';
import type { ReactNode } from 'react';
import { create } from 'zustand';

import { useFaqModalStore } from './Faq';
import Features from './Features';
import { useLangModal } from './LangMenu';

export const TopNavMenuDrawer = () => {
  const t = useTranslations();
  const language = useLanguage();
  const isOpen = useTopNavMenuDrawer((s) => s.isOpen);
  const onClose = useTopNavMenuDrawer((s) => s.onClose);

  return (
    <Modal
      isOpen={isOpen}
      size="full"
      scrollBehavior="inside"
      onClose={onClose}
    >
      <ModalContent bgColor="bg-gauze" pt="60px">
        <ModalCloseButton color="white" />
        <ModalBody
          display="flex"
          flexDir="column"
          gap="16px"
          alignItems="flex-end"
        >
          <TopNavMenuItem
            icon={<Icon as={Faq} boxSize="24px" />}
            onClick={() => {
              onClose();
              useFaqModalStore.getState().toggleFaqModal();
            }}
          >
            FAQs
          </TopNavMenuItem>
          <TopNavMenuItem
            icon={<Icon as={Lang} boxSize="24px" />}
            onClick={() => {
              onClose();
              useLangModal.setState({
                isOpen: true,
              });
            }}
          >
            {language.name}
          </TopNavMenuItem>
          <Features.statsPage>
            <NextLink href={pathDict.cro.statsVno}>
              <TopNavMenuItem
                icon={<Icon as={Stats} boxSize="24px" />}
                onClick={onClose}
              >
                {t('Statistics')}
              </TopNavMenuItem>
            </NextLink>
          </Features.statsPage>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export type UseTopNavMenuDrawerStore = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const useTopNavMenuDrawer = create(
  resetWhenRouterChange<UseTopNavMenuDrawerStore>((set) => ({
    isOpen: false,
    onOpen: () => {
      set({
        isOpen: true,
      });
    },
    onClose: () => {
      useTopNavMenuDrawer.getState().$$resetData?.();
    },
  })),
);

const TopNavMenuItem = ({
  icon,
  children,
  ...props
}: FlexProps & {
  icon: ReactNode;
}) => {
  return (
    <Flex alignItems="center" gap="8px" {...props}>
      <Box textStyle="ctaCopy" color="bg-main" textTransform="uppercase">
        {children}
      </Box>
      <Flex
        w="40px"
        h="40px"
        rounded="100%"
        bgColor="bg-main"
        justifyContent="center"
        alignItems="center"
        color="brand-primary"
      >
        {icon}
      </Flex>
    </Flex>
  );
};
