import { BigNumber } from '@ethersproject/bignumber';
export const MaxUint256 = BigNumber.from('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff');
export const BLOCK_TIME = 5.6;
export const BLOCKS_PER_YEAR = Math.ceil((60 * 60 * 24 * 365) / BLOCK_TIME);
export const SECONDS_PER_YEAR = 60 * 60 * 24 * 365;
export const DIGITS = 8;
export function expandDecimals(n, decimals) {
    if (decimals === undefined) {
        decimals = Number(n);
        n = 1;
    }
    return BigNumber.from(n).mul(BigNumber.from(10).pow(decimals));
}
export const CRO_DECIMALS = 18;
export const LCRO_DECIMALS = 18;
export const ATOM_DECIMALS = 6;
export const LATOM_DECIMALS = 6;
export const FERRO_LP_DECIMALS = 18;
export const USD_DECIMALS = 18;
export const TLCRO_DECIMALS = 8;
export const TLATOM_DECIMALS = 8;
export const ETH_DECIMALS = 18;
export const USDT_DECIMALS = 6;
export const ONE_USD = expandDecimals(USD_DECIMALS);
