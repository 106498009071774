import type { FlexProps } from '@chakra-ui/react';
import { Box, Flex, Skeleton, Text } from '@chakra-ui/react';
import type { BigNumber } from '@ethersproject/bignumber';
import type { ReactElement, ReactNode } from 'react';
import { useMemo } from 'react';

import { formatNumber, formatTokenAmount } from '../../utils/format';
import TextWithAbbreviateZeros from '../TextWithAbbreviateZeros';

type BalanceProps = FlexProps & {
  amount?: BigNumber | number;
  icon?: ReactElement;
  title?: string;
  usdAmount?: BigNumber | number;
  unitDecimals?: number;
  displayDecimals?: number;
  usdDisplayDecimals?: number;
  showUsdAmount?: boolean;
  invertedColors?: boolean;
  usdNode?: ReactNode;
  abbreviateZeros?: boolean;
};

const Balance = ({
  amount,
  icon: Icon,
  title,
  usdAmount,
  displayDecimals = 3,
  unitDecimals,
  usdDisplayDecimals = 2,
  showUsdAmount,
  invertedColors,
  usdNode,
  abbreviateZeros,
  ...props
}: BalanceProps) => {
  const displayText = useMemo(() => {
    if (amount === undefined) return undefined;
    if (typeof amount === 'number') {
      return formatNumber(amount, displayDecimals);
    }
    return formatTokenAmount(amount, displayDecimals, unitDecimals);
  }, [amount, displayDecimals, unitDecimals]);

  return (
    <Flex flexDir="column" align="center" {...props}>
      {title && (
        <Text textStyle={{ base: 'bodySmall', desktop: 'body' }}>{title}</Text>
      )}
      <Flex flexDir="row" alignItems="center">
        {Icon && (
          <Flex
            height="32px"
            mr="4px"
            alignItems="center"
            justifyContent="center"
            flexShrink="0"
          >
            {Icon}
          </Flex>
        )}
        {displayText !== undefined ? (
          <TextWithAbbreviateZeros
            textStyle="h2"
            abbreviateZeros={abbreviateZeros}
          >
            {displayText}
          </TextWithAbbreviateZeros>
        ) : (
          <Skeleton w="100px" h="48px" />
        )}
      </Flex>
      {showUsdAmount &&
        (usdAmount !== undefined || usdNode ? (
          <Box
            textStyle={{ base: 'bodySmall', desktop: 'body' }}
            color={invertedColors ? 'white' : 'text.light'}
            opacity={invertedColors ? 0.75 : undefined}
          >
            {usdNode
              ? usdNode
              : typeof usdAmount === 'number'
              ? `${formatNumber(usdAmount, usdDisplayDecimals)} USD`
              : `${formatTokenAmount(usdAmount, usdDisplayDecimals, 18)} USD`}
          </Box>
        ) : (
          <Skeleton w="80px" h={{ base: '21px', desktop: '24px' }} />
        ))}
    </Flex>
  );
};

export default Balance;
