import { VenoNFT__factory } from '@contracts';
import { VenoSDKModule } from './base';
import { wrapSdkTransaction } from './util';
export default class NftModule extends VenoSDKModule {
    constructor(sdk, address) {
        super(sdk);
        this.contractFactory = VenoNFT__factory;
        this.contractAddress = this.sdk.addresses.venoNft;
        this.contractAddress = address;
    }
    async ownerTokenIds(owner) {
        return await this.getContract().ownerTokenIds(owner);
    }
    async setApprovalForAll(operator, approved) {
        return await wrapSdkTransaction(this.getContract().setApprovalForAll(operator, approved));
    }
    async isApprovedForAll(owner, operator) {
        return await this.getContract().isApprovedForAll(owner, operator);
    }
    async tokenURI(tokenId) {
        return await this.getContract().tokenURI(tokenId);
    }
}
