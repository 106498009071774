import type { BoxProps } from '@chakra-ui/react';
import { Box, Tooltip } from '@chakra-ui/react';
import React, { useState } from 'react';

type UsdValueProps = {
  historicValue: string;
  currentValue: string;
} & BoxProps;

enum DisplayMode {
  INITIAL,
  HISTORIC,
  CURRENT,
}

const UsdValue = ({
  historicValue,
  currentValue,
  ...boxProps
}: UsdValueProps) => {
  const [displayMode, setDisplayMode] = useState(DisplayMode.INITIAL);

  return (
    <Tooltip hasArrow label={getTooltipText(displayMode)}>
      <Box
        display="inline-block"
        cursor="pointer"
        onClick={() => {
          setDisplayMode((mode) =>
            mode === DisplayMode.HISTORIC
              ? DisplayMode.CURRENT
              : DisplayMode.HISTORIC,
          );
        }}
        {...boxProps}
      >
        {displayMode !== DisplayMode.HISTORIC ? currentValue : historicValue}
      </Box>
    </Tooltip>
  );
};

const getTooltipText = (displayMode: DisplayMode): string => {
  if (displayMode === DisplayMode.HISTORIC) {
    return 'Estimated value on day of transaction';
  }
  if (displayMode === DisplayMode.CURRENT) {
    return 'Current value';
  }

  return 'Displaying current value. Click to show value on day of Transaction';
};

export default UsdValue;
