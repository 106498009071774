import type { CoinMode } from '@ui/hooks/useCoinMode';
import useFerroSupply from '@ui/hooks/useFerroSupply';
import useTectonicSupply from '@ui/hooks/useTectonicSupply';
import useVvsCroSupply from '@ui/hooks/useVvsCroSupply';
import useVvsSupply from '@ui/hooks/useVvsSupply';
import useVvsTiaSupply from '@ui/hooks/useVvsTiaSupply';

import type { SupplyLcroModalType } from './types';

const useSupplyAction = (coinMode: CoinMode, type: SupplyLcroModalType) => {
  const { mutateAsync: doSupplyFerro } = useFerroSupply({
    coinMode,
  });
  const { mutateAsync: doSupplyTectonic } = useTectonicSupply();
  const { mutateAsync: doSupplyVvs } = useVvsSupply();
  const { mutateAsync: doSupplyVvsCro } = useVvsCroSupply();
  const { mutateAsync: doSupplyVvsTia } = useVvsTiaSupply();

  switch (type) {
    case 'FERRO':
      return doSupplyFerro;
    case 'VVS':
      return doSupplyVvs;
    case 'VVS_CRO':
      return doSupplyVvsCro;
    case 'VVS_TIA':
      return doSupplyVvsTia;
    case 'TECTONIC':
      return doSupplyTectonic;
  }
};

export default useSupplyAction;
