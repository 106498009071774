import { useSafeLayoutEffect } from '@chakra-ui/react';

// Prevent body from scrolling when enter search screen
export const useFreezeScroll = (isFreeze: boolean) => {
  useSafeLayoutEffect(() => {
    if (isFreeze) {
      document.body.style.position = 'fixed';
      document.body.style.overflowY = 'scroll';
      document.body.style.width = '100%';
      return () => {
        document.body.style.position = 'static';
        document.body.style.overflowY = 'auto';
      };
    }
  }, [isFreeze]);
};
