import type { ButtonProps, FlexProps } from '@chakra-ui/react';
import { Button, Flex, Icon, Image, Text } from '@chakra-ui/react';
import { useIsOnVvs } from '@ui/hooks/useIsOnVvs';
import {
  atomWatchAsset,
  ferroLpWatchAsset,
  latomWatchAsset,
  lcroWatchAsset,
  ltiaWatchAsset,
  tiaWatchAsset,
  tlcroWatchAsset,
  useWatchAsset,
  vvsCroLcroLpWatchAsset,
  vvsTiaLtiaLpWatchAsset,
  wcroWatchAsset,
} from '@ui/hooks/useWatchAsset';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { Plus } from '@veno-app/icons';
import { walletConfigs } from '@veno-app/wallet';
import React from 'react';

type AddTokenButtonProps = FlexProps & {
  tokenType: TokenType;
  buttonProps?: ButtonProps;
  text?: string;
};

export enum TokenType {
  LCRO,
  VNO,
  TLCRO,
  FERRO_LP,
  VVS_CRO_LCRO_LP,
  VVS_TIA_LTIA_LP,
  WCRO,
  ATOM,
  LATOM,
  LETH,
  TIA,
  LTIA,
}

export default function AddTokenButton({
  tokenType,
  text,
  buttonProps,
  ...props
}: AddTokenButtonProps) {
  const sdk = useVenoSDK();
  const { canWatch, watchAsset } = useWatchAsset();
  const isOnVvs = useIsOnVvs();

  let asset = lcroWatchAsset;
  switch (tokenType) {
    case TokenType.VNO:
      asset = {
        address: sdk.addresses.venoToken,
        decimals: 18,
        symbol: 'VNO',
        image: '',
      };
      break;
    case TokenType.TLCRO:
      asset = tlcroWatchAsset;
      break;
    case TokenType.FERRO_LP:
      asset = ferroLpWatchAsset;
      break;
    case TokenType.VVS_CRO_LCRO_LP:
      asset = vvsCroLcroLpWatchAsset;
      break;
    case TokenType.VVS_TIA_LTIA_LP:
      asset = vvsTiaLtiaLpWatchAsset;
      break;
    case TokenType.WCRO:
      asset = wcroWatchAsset;
      break;
    case TokenType.ATOM:
      asset = atomWatchAsset;
      break;
    case TokenType.LATOM:
      asset = latomWatchAsset;
      break;
    case TokenType.TIA:
      asset = tiaWatchAsset;
      break;
    case TokenType.LTIA:
      asset = ltiaWatchAsset;
      break;
  }

  return canWatch ? (
    <Flex gap={4} {...props}>
      <Button
        variant={isOnVvs ? 'vvs' : 'raised'}
        size="xs"
        onClick={async () => {
          await watchAsset(asset);
        }}
        {...buttonProps}
      >
        <Icon as={Plus} style={{ height: '24px', width: '24px' }} />
        <Image
          mr="4px"
          w="16px"
          h="16px"
          alt="Metamask"
          src={walletConfigs.find((c) => c.title === 'MetaMask')?.icon}
        />
      </Button>
      {text && <Text as="span">{text}</Text>}
    </Flex>
  ) : null;
}
