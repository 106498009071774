import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useIFrameTheme } from '@ui/hooks/useIFrameTheme';
import { isFeatureEnabled } from '@ui/hooks/useIsFeatureEnabled';
import { useMaxContentWidth } from '@ui/hooks/useMaxContentWidth';
import getBgImage from '@ui/utils/getBgImageProps';
import { withNoSSR } from '@ui/utils/withNoSSR';
import { currentWallet } from '@veno-app/wallet';

import { features } from '../Features';
import { InMobile } from '../MobileOrDesktop';
import FaqInfo from './FaqInfo';
import { useFaqModalStore } from './useFaqModalStore';

const { useIsConnected } = currentWallet;

export const FaqModal = withNoSSR(() => {
  const isOpen = useFaqModalStore((s) => s.isOpen);
  const toggle = useFaqModalStore((s) => s.toggleFaqModal);
  const connected = useIsConnected();
  const maxContentWidth = useMaxContentWidth();
  const { theme } = useIFrameTheme();

  return (
    <>
      <Modal
        size="full"
        scrollBehavior="inside"
        onClose={toggle}
        isOpen={isOpen}
      >
        <ModalOverlay />
        <ModalContent position="relative" w={maxContentWidth}>
          <ModalHeader>
            <Text textStyle="h3">FAQs</Text>
          </ModalHeader>
          <InMobile>
            <ModalCloseButton
              variant="raisedActive"
              right={
                theme !== 'vvs' && !isFeatureEnabled(features.statsPage)
                  ? '80px'
                  : 4
              }
              mr={!connected && theme !== 'vvs' ? '76px' : 0}
            />
          </InMobile>
          <ModalBody mx="auto" bgPos="right" {...getBgImage('PATTERN_4_LARGE')}>
            <FaqInfo showTitle={false} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
});
