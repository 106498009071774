import { useBreakpointValue } from '@chakra-ui/react';
import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import { useQuery } from '@tanstack/react-query';
import { useUseLcroTour } from '@ui/components/Tours';
import type { TransactionStatus } from '@ui/components/TransactionDrawer';
import { pathDict } from '@ui/config/paths';
import { COIN_MODE } from '@ui/hooks/useCoinMode';
import useDebounce from '@ui/hooks/useDebounce';
import useGardenApy from '@ui/hooks/useGardenApy';
import { useLiquidCoinBalance } from '@ui/hooks/useLiquidCoinBalance';
import { useLiquidCoinUsd } from '@ui/hooks/useLiquidCoinUsd';
import useLpReceiveValue from '@ui/hooks/useLpReceiveValue';
import useLpUsdValue from '@ui/hooks/useLpUsdValue';
import { useTectonicApy } from '@ui/hooks/usePartnerStats';
import { useVnoUsdValue } from '@ui/hooks/useVnoUsdValue';
import { useTranslations } from '@ui/i18n';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import {
  getFerroApy,
  getVvsApy,
  getVvsCroApy,
  getVvsTiaApy,
} from '@ui/queries/partnerApys';
import { getQuery } from '@ui/queries/queries';
import { QueryKey } from '@ui/queries/queryKey';
import bigNumberToNumber from '@ui/utils/bigNumberToNumber';
import { parseValue } from '@ui/utils/format';
import { gardenTokenDict } from '@ui/utils/garden';
import { replaceRouterWithoutRerender } from '@ui/utils/urlHistory';
import type { EthersError } from '@veno-app/sdk';
import { currentWallet } from '@veno-app/wallet';
import { useCallback, useMemo, useState } from 'react';

import useSupplyAction from './useSupplyAction';
import { useSupplyLcroModalStore } from './useSupplyLcroModalStore';
import { approve, getAllowance, getReceived } from './util';

const { useIsConnected, useAccount } = currentWallet;

const useSupplyLcroModal = () => {
  const t = useTranslations();
  const { isOpen, close, type, coinMode } = useSupplyLcroModalStore();
  const coinModeInfo = COIN_MODE[coinMode];
  const account = useAccount();
  const isConnected = useIsConnected();
  const sdk = useVenoSDK();
  const tectonicApyRet = useTectonicApy(type === 'TECTONIC');
  const ferroApyQuery = getFerroApy(coinMode, true);
  const vvsApyQuery = getVvsApy(true);
  const vvsCroApyQuery = getVvsCroApy(true);
  const vvsTiaApyQuery = getVvsTiaApy();
  const { data: oneVnoUsdValue } = useVnoUsdValue(1);
  const [inputError, setInputError] = useState<string>('');
  const [supplyAmount, setSupplyAmount] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [nSteps, setNSteps] = useState(2);
  const [currentStep, setCurrentStep] = useState(0);
  const [receivedAmount, setReceivedAmount] = useState(BigNumber.from(0));
  const { data: ferroApy, error: ferroApyError } = useQuery<number, Error>(
    ferroApyQuery.queryKey,
    ferroApyQuery.queryFn,
    { enabled: type === 'FERRO' },
  );
  const { data: vvsApy } = useQuery(vvsApyQuery.queryKey, vvsApyQuery.queryFn, {
    enabled: type === 'VVS',
  });
  const { data: vvsCroApy } = useQuery(
    vvsCroApyQuery.queryKey,
    vvsCroApyQuery.queryFn,
    {
      enabled: type === 'VVS_CRO',
    },
  );
  const { data: vvsTiaApy } = useQuery(
    vvsTiaApyQuery.queryKey,
    vvsTiaApyQuery.queryFn,
    {
      enabled: type === 'VVS_TIA',
    },
  );
  const debouncedSupply = useDebounce(supplyAmount, 500);
  const isDesktop = useBreakpointValue({
    base: false,
    desktop: true,
  });

  const [assetInfoDrawerOpen, setAssetInfoDrawerOpen] = useState(false);
  const [error, setError] = useState<EthersError | null>(null);
  const [transactionStatus, setTransactionStatus] =
    useState<TransactionStatus>('CONFIRM');
  const doSupply = useSupplyAction(coinMode, type);

  const closeAndReset = useCallback(() => {
    close();
    setDrawerOpen(false);
    setSupplyAmount('');
    setInputError('');
    setAssetInfoDrawerOpen(false);
    setTransactionStatus('CONFIRM');
    setError(null);
    setNSteps(2);
    setReceivedAmount(BigNumber.from(0));
    if (
      typeof window !== 'undefined' &&
      window.location.search.includes('deposit')
    ) {
      replaceRouterWithoutRerender(pathDict[coinMode].useLiquid);
    }
  }, [close, coinMode]);

  const closeDrawer = () => {
    setDrawerOpen(false);
    if (transactionStatus === 'COMPLETED') closeAndReset();
    else {
      setInputError('');
      setError(null);
    }
  };
  const {
    ferroGardenApy,
    tectonicGardenApy,
    vvsGardenApy,
    vvsCroGardenApy,
    vvsTiaGardenApy,
  } = useGardenApy(type);

  let gardenApy = tectonicGardenApy;

  let apy = tectonicApyRet.data;
  let apyError: Error | null = tectonicApyRet.error;
  let cover = '/images/tectonic-cover.png';
  let brandColor = '#1F1F3F';
  let name = 'Tectonic';
  let gradient1 =
    'linear-gradient(182.36deg, #1F1F3F 1.98%, rgba(31, 31, 63, 0) 119.28%)';
  let gradient2 =
    'linear-gradient(182.88deg, #1F1F3F 2.4%, rgba(31, 31, 63, 0) 97.61%)';
  let shadows: [string, string] = ['#68688d', '#000001'];
  switch (type) {
    case 'FERRO':
      brandColor = '#1A1A1A';
      apy = ferroApy;
      apyError = ferroApyError;
      name = 'Ferro';
      gradient1 = '';
      gradient2 = '';
      cover = '/images/ferro-cover.png';
      shadows = ['#666666', '#000000'];
      gardenApy = ferroGardenApy;
      break;
    case 'VVS':
      brandColor = '#F4F5F1';
      apy = vvsApy;
      name = 'VVS';
      gradient1 = '';
      gradient2 =
        'linear-gradient(182.88deg, #F4F5F1 2.4%, rgba(244, 245, 241, 0) 80%);';
      cover = '/images/vvs-cover.png';
      shadows = ['#ffffff', '#c5c6c3'];
      gardenApy = vvsGardenApy;
      break;
    case 'VVS_CRO':
      brandColor = '#F4F5F1';
      apy = vvsCroApy;
      name = 'VVS CRO';
      gradient1 = '';
      gradient2 =
        'linear-gradient(182.88deg, #EBF0F3 2.4%, rgba(235, 240, 243, 0) 97.61%)';
      cover = '/images/vvs-cover.png';
      shadows = ['#ffffff', '#c5c6c3'];
      gardenApy = vvsCroGardenApy;
      break;
    case 'VVS_TIA':
      brandColor = '#F4F5F1';
      apy = vvsTiaApy;
      name = 'VVS TIA';
      gradient1 = '';
      gradient2 =
        'linear-gradient(182.88deg, #EBF0F3 2.4%, rgba(235, 240, 243, 0) 97.61%)';
      cover = '/images/vvs-cover.png';
      shadows = ['#ffffff', '#c5c6c3'];
      gardenApy = vvsTiaGardenApy;
      break;
  }

  const {
    exchangeRate,
    isFetching: exchangeRateFetching,
    receiveAmount,
  } = useLpReceiveValue(coinMode, type, supplyAmount);

  const receiveAmountUsd = useLpUsdValue(
    type,
    receiveAmount ? String(receiveAmount) : '',
  );

  const handleSupply = useCallback(async () => {
    useUseLcroTour.getState().next();

    setDrawerOpen(true);
    setTransactionStatus('PENDING');

    try {
      const allowance = await getAllowance(coinMode, type, sdk, account);
      const amount = parseValue(supplyAmount, coinModeInfo.liquidDecimals);

      if (allowance.lt(amount)) {
        // not yet approved.
        // 2 steps:
        // 1. approve, 2. deposit
        setNSteps(2);
        setCurrentStep(1);
        const approveRet = await approve(coinMode, type, sdk);
        await approveRet.txReceiptPromise;
        // approval done, now got to step 2, deposit
        setCurrentStep(2);
      } else {
        // it was already approved, only one step, which is "deposit"
        setNSteps(1);
        setCurrentStep(1);
      }

      const p = await doSupply({
        amount: amount,
        expected: receiveAmount ?? 0,
      });
      setTransactionStatus('COMPLETED');
      const received: BigNumber = getReceived(coinMode, type, sdk, p);
      setReceivedAmount(received);

      useUseLcroTour.getState().next();
    } catch (e) {
      console.error(e);
      setTransactionStatus('ERROR');
      setError(e as EthersError);
    }
  }, [
    account,
    coinMode,
    coinModeInfo.liquidDecimals,
    doSupply,
    receiveAmount,
    sdk,
    supplyAmount,
    type,
  ]);

  const { data: liquidCoinBalance } = useLiquidCoinBalance({
    coinMode,
    enabled: !!account && type !== 'VVS',
  });

  const { data: liquidCoinUsd } = useLiquidCoinUsd({
    coinMode,
    amount: liquidCoinBalance,
  });

  const debouncedSupplyBn = debouncedSupply
    ? parseValue(debouncedSupply, coinModeInfo.liquidDecimals)
    : undefined;

  const { data: lpUsdWorthBn } = useLiquidCoinUsd({
    coinMode,
    amount: debouncedSupplyBn,
  });

  const lpUsdWorth = useMemo(() => {
    if (
      !debouncedSupplyBn ||
      (inputError && inputError !== t('Insufficient Balance'))
    ) {
      return undefined;
    }
    if (type === 'VVS') {
      return bigNumberToNumber(
        debouncedSupplyBn
          .mul(Math.round(100_000_000 * (oneVnoUsdValue ?? 0)))
          .div(100_000_000),
      );
    }
    return lpUsdWorthBn ? bigNumberToNumber(lpUsdWorthBn) : undefined;
  }, [debouncedSupplyBn, inputError, lpUsdWorthBn, oneVnoUsdValue, t, type]);

  const inputWarning = useMemo(() => {
    if (
      typeof lpUsdWorth === 'undefined' ||
      typeof receiveAmountUsd === 'undefined'
    ) {
      return undefined;
    }
    if (receiveAmountUsd <= lpUsdWorth * 0.9) {
      return t('supply-reduce-warning-{coin}', {
        coin: gardenTokenDict[coinMode][type].name ?? '',
      });
    }
  }, [coinMode, lpUsdWorth, receiveAmountUsd, t, type]);

  const vnoBalanceQuery = getQuery(QueryKey.VNO_BALANCE)(sdk, account ?? '');
  const { data: vnoBalance } = useQuery(
    vnoBalanceQuery.queryKey,
    vnoBalanceQuery.queryFn,
    { enabled: !!account && type === 'VVS' },
  );
  const { data: vnoBalanceUsd } = useVnoUsdValue(vnoBalance);

  const balance = type === 'VVS' ? vnoBalance : liquidCoinBalance;
  const balanceUsd = type === 'VVS' ? vnoBalanceUsd : liquidCoinUsd;

  const maxAmount = balance
    ? Math.floor(
        100_000_000 *
          parseFloat(formatUnits(balance, coinModeInfo.liquidDecimals) ?? '0'),
      ) / 100_000_000
    : undefined;

  const handleInputChange = useCallback(
    (value: string) => {
      if (value && !/^[0-9]+\.?[0-9]*$/.test(value)) {
        setInputError(t('invalid format'));
      } else if (maxAmount !== undefined && parseFloat(value) > maxAmount) {
        setInputError(t('Insufficient Balance'));
      } else {
        setInputError('');
        useUseLcroTour.setState({
          inputFerroAmountStr: value,
        });
      }
      setSupplyAmount(value);
    },
    [maxAmount, t],
  );

  return {
    brandColor,
    apy: useMemo(() => {
      if (!gardenTokenDict[coinMode][type].enabled) {
        return typeof apy === 'number' ? apy : undefined;
      }

      return typeof apy === 'number' && typeof gardenApy === 'number'
        ? apy + gardenApy * 100
        : undefined;
    }, [apy, coinMode, gardenApy, type]),
    apyError,
    name,
    balance,
    balanceUsd,
    gradient1,
    gradient2,
    cover,
    maxAmount,
    shadows,
    handleInputChange,
    supplyAmount,
    inputError,
    inputWarning,
    assetInfoDrawerOpen,
    isOpen: isOpen && isConnected,
    closeAndReset,
    type,
    setAssetInfoDrawerOpen,
    isDesktop,
    transactionStatus,
    error,
    nSteps,
    currentStep,
    handleSupply,
    closeDrawer,
    drawerOpen,
    exchangeRate,
    receiveAmount,
    receiveAmountUsd,
    receivedAmount,
    exchangeRateFetching,
    gardenApy,
    lpUsdWorth,
  };
};

export default useSupplyLcroModal;
