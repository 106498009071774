import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import type { ReactNode } from 'react';
import { create } from 'zustand';

import { useDisclaimerDrawer } from '../DisclaimerDrawer';
import type { TokenProtocol } from './utils';

export type UseUseYourTokenDrawerStore = {
  isOpen: boolean;
  title: ReactNode;
  protocols: TokenProtocol[];
  onOpen: (options: { title: ReactNode; protocols: TokenProtocol[] }) => void;
  onClose: () => void;
};

export const useUseYourTokenDrawer = create(
  resetWhenRouterChange<UseUseYourTokenDrawerStore>((set) => ({
    isOpen: false,
    title: null,
    protocols: [],
    onOpen: ({ title, protocols }) => {
      set({
        isOpen: true,
        title,
        protocols,
      });
    },
    onClose: () => {
      useUseYourTokenDrawer.getState().$$resetData?.();
      useDisclaimerDrawer.getState().onClose();
    },
  })),
);
