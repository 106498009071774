import { Box, Flex, ModalCloseButton } from '@chakra-ui/react';
import { useBalance } from '@ui/hooks/useBalance';
import { AppEnvironment } from '@ui/hooks/useIsFeatureEnabled';
import { useLiquidCoinBalance } from '@ui/hooks/useLiquidCoinBalance';
import useVnoBasicInfo from '@ui/hooks/useVnoBasicInfo';
import { trackEvent } from '@ui/utils/tracking';
import { withNoSSR } from '@ui/utils/withNoSSR';
import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import { currentWallet } from '@veno-app/wallet';
import queryString from 'query-string';
import { useEffect, useMemo, useRef } from 'react';
import { create } from 'zustand';

import { RenderInDesktop } from './MobileOrDesktop';
import {
  ModalOrDragDrawerContent,
  ModalOrDrawer,
  ModalOrDrawerBody,
  ModalOrDrawerOverlay,
} from './ModalOrDrawer';

/**
 * @see https://docs.transak.com/docs/query-parameters
 */
interface TransakSettings {
  sdkVersion: string;
  hostURL: string;
  apiKey: string;
  defaultCryptoCurrency?: string;
  themeColor?: string;
  exchangeScreenTitle?: string;
  hideMenu?: boolean;
  disableWalletAddressForm?: boolean;
  isFeeCalculationHidden?: boolean;
  isDisableCrypto?: boolean;
  network?: string;
  networks?: string;
  walletAddress?: string;
  cryptoCurrencyCode?: string;
  fiatCurrency?: string;
}

export const TransakModal = withNoSSR(() => {
  const isOpen = useTransakModal((s) => s.isOpen);
  const onClose = useTransakModal((s) => s.onClose);
  const setIsBuyingSuccessFul = useTransakModal((s) => s.setIsBuyingSuccessFul);

  const walletAddress = currentWallet.useAccount();
  const isConnectedToSupportedChain =
    currentWallet.useIsConnectedToSupportedChain();
  const isProductionEnv = useMemo(() => {
    return [AppEnvironment.UAT, AppEnvironment.Production].includes(
      process.env.NEXT_PUBLIC_RELEASE_STAGE as AppEnvironment,
    );
  }, []);

  const transakUrl = useMemo(() => {
    return isProductionEnv
      ? 'https://global.transak.com'
      : 'https://global-stg.transak.com';
  }, [isProductionEnv]);

  const transakUrlSearch = useMemo(() => {
    const settings: TransakSettings = {
      sdkVersion: '1.3.0',
      hostURL: typeof window !== 'undefined' ? window.location.origin : '',
      apiKey: process.env.NEXT_PUBLIC_TRANSAK_APK,
      exchangeScreenTitle: 'Buy CRO to your wallet',
      cryptoCurrencyCode: 'cro',
      walletAddress: walletAddress,
      disableWalletAddressForm: true,
      hideMenu: false,
    };
    return queryString.stringify(settings);
  }, [walletAddress]);

  /**
   * @see https://github.com/Transak/transak-sdk/blob/v1.3.0/src/index.js#L79
   */
  useEffect(() => {
    const handleMessage = (
      event: MessageEvent<{
        event_id: string;
      }>,
    ) => {
      if (event.origin !== transakUrl) {
        return;
      }

      switch (event?.data?.event_id) {
        case 'TRANSAK_ORDER_SUCCESSFUL':
          setIsBuyingSuccessFul(true);
          break;
        case 'TRANSAK_WIDGET_CLOSE':
          onClose();
          break;
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [onClose, setIsBuyingSuccessFul, transakUrl]);

  useTransakModalTrackEvent();

  if (!walletAddress || !isConnectedToSupportedChain) {
    return null;
  }

  return (
    <ModalOrDrawer
      variant="wholeScreen"
      placement="bottom"
      size={{
        base: 'full',
        desktop: 'md',
      }}
      onClose={onClose}
      isOpen={isOpen}
    >
      <ModalOrDrawerOverlay />
      <ModalOrDragDrawerContent
        dragBarProps={{
          /**
           * avoid the dragging-bar block blocking the
           * backward/menu button inner the transak iframe
           */
          w: 'calc(100% - 100px)',
          mx: '50px',
        }}
      >
        <RenderInDesktop>
          <ModalCloseButton
            rounded="100%"
            bg="black"
            color="white"
            _hover={{
              bg: 'rgba(0,0,0,0.8)',
            }}
            top="-20px"
            right="-20px"
          />
        </RenderInDesktop>
        <ModalOrDrawerBody
          as={Flex}
          flexDirection="column"
          px="0"
          py="0"
          overflow="hidden"
          rounded="12px"
        >
          <Box
            as="iframe"
            width="100%"
            flex="1"
            title="Transak On/Off Ramp Widget"
            src={`${transakUrl}?${transakUrlSearch}`}
            frameBorder="no"
            border="none"
          />
        </ModalOrDrawerBody>
      </ModalOrDragDrawerContent>
    </ModalOrDrawer>
  );
});

type TransakModalEntryPoint = 'Wallet' | 'Popup' | 'Stake' | 'Strategy';
type UseTransakModalStore = {
  isOpen: boolean;
  onSuccessClose?: () => void;
  entryPoint: TransakModalEntryPoint;
  onOpen: (options?: {
    onSuccessClose?: () => void;
    entryPoint?: TransakModalEntryPoint;
  }) => void;
  onClose: () => void;
  isBuyingSuccessFul: boolean;
  setIsBuyingSuccessFul: (isBuyingSuccessFul: boolean) => void;
};

export const useTransakModal = create(
  resetWhenRouterChange<UseTransakModalStore>((set, get) => ({
    isOpen: false,
    onSuccessClose: undefined,
    entryPoint: 'Wallet',
    onOpen: ({ onSuccessClose, entryPoint } = {}) => {
      set({
        isOpen: true,
        onSuccessClose,
        entryPoint: entryPoint ?? get().entryPoint,
      });
    },
    onClose: () => {
      if (get().isBuyingSuccessFul) {
        get().onSuccessClose?.();
      }
      useTransakModal.getState().$$resetData?.();
    },
    isBuyingSuccessFul: false,
    setIsBuyingSuccessFul: (isBuyingSuccessFul) => {
      set({
        isBuyingSuccessFul,
      });
    },
  })),
);

const useTransakModalTrackEvent = () => {
  const isOpen = useTransakModal((s) => s.isOpen);
  const entryPoint = useTransakModal((s) => s.entryPoint);
  const { data: croBalance } = useBalance();
  const { data: lcroBalance } = useLiquidCoinBalance({
    coinMode: 'cro',
  });
  const fountainQueryResult = useVnoBasicInfo('FOUNTAIN');
  const reservoirQueryResult = useVnoBasicInfo('RESERVOIR');

  const isTrackNeededRef = useRef(false);

  useEffect(() => {
    if (isOpen) {
      isTrackNeededRef.current = true;
    }
  }, [isOpen]);

  if (
    isTrackNeededRef.current &&
    typeof croBalance !== 'undefined' &&
    typeof lcroBalance !== 'undefined' &&
    !fountainQueryResult.isLoading &&
    !reservoirQueryResult.isLoading
  ) {
    isTrackNeededRef.current = false;

    trackEvent('Transak Modal Opened', {
      'CRO Balance': croBalance,
      isTransaction: false,
      'Entry Point': entryPoint,
      'Has Staked CRO': lcroBalance.gt(0),
      'Invested in VNO':
        fountainQueryResult.totalStakes.gt(0) ||
        reservoirQueryResult.totalStakes.gt(0),
    });
  }
};
