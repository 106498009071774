import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import React from 'react';

import { CroDrawerContent, LcroDrawerContent } from '../GetAssetDrawers';
import { GetAssetByStakingContent } from '../GetAssetDrawers/GetAssetByStaking';
import { GetAssetOnVvsContent } from '../GetAssetDrawers/GetAssetOnVvs';
import { useAccountModalStore } from './useAccountModalStore';

function AccountModalDrawerContentsCronos() {
  const openDrawer = useAccountModalStore((s) => s.openDrawer);

  return (
    <>
      {openDrawer === 'cro' && <CroDrawerContent />}
      {openDrawer === 'vno' && (
        <GetAssetOnVvsContent
          tokenLogoSrc="/tokens/vno.svg"
          tokenName="VNO"
          swapLink={EXTERNAL_LINK.vvs}
        />
      )}
      {openDrawer === 'atom' && (
        <GetAssetOnVvsContent
          tokenLogoSrc="/tokens/atom.svg"
          tokenName="ATOM"
          swapLink={EXTERNAL_LINK.vvsSwapCroToAtom}
        />
      )}
      {openDrawer === 'lcro' && <LcroDrawerContent />}
      {openDrawer === 'latom' && (
        <GetAssetByStakingContent i18nkey="buy-latom-desc" coinMode="atom" />
      )}
      {openDrawer === 'ltia' && (
        <GetAssetByStakingContent i18nkey="buy-ltia-desc" coinMode="tia" />
      )}
      {openDrawer === 'tia' && (
        <GetAssetOnVvsContent
          tokenLogoSrc="/tokens/tia.svg"
          tokenName="TIA"
          swapLink={EXTERNAL_LINK.vvsTia}
        />
      )}
    </>
  );
}

export default AccountModalDrawerContentsCronos;
