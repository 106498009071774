import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import { VaultImage } from '@ui/components/VaultCard';
import { VnoAprSkeleton } from '@ui/components/VnoAprSkeleton';
import { useTranslations } from '@ui/i18n';
import { formatNumber, formatTokenAmount } from '@ui/utils/format';
import { formatLockPeriod } from '@ui/utils/formatLockPeriod';

import { useVaultSelectionStore } from '../VaultSelectionStore';
import StakeCards from './StakeCards';

export const VaultDetail: React.FC = () => {
  const t = useTranslations();
  const vaultInfo = useVaultSelectionStore((s) => s.selectedVault);

  if (!vaultInfo) return null;
  return (
    <>
      <VaultImage
        status={vaultInfo.status}
        period={formatLockPeriod(vaultInfo.lockPeriod, { roughly: true })}
        size="150px"
        innerSize="140px"
        textStyle="h3"
        mx="auto"
      />
      <SimpleGrid columns={2} width="full" gap={4}>
        <Text>APR</Text>
        {vaultInfo.apr !== null ? (
          <Text textStyle="bodyBold" align="end">
            {formatNumber(vaultInfo.apr * 100, 2)}%
          </Text>
        ) : (
          <VnoAprSkeleton />
        )}
        <Text>{t('Multiplier')}</Text>
        <Text textStyle="bodyBold" align="end">
          {`${(vaultInfo.multiplier / 100).toFixed(0)}x`}
        </Text>
        <Text>{t('Total locked')}</Text>
        <Text textStyle="bodyBold" align="end">
          {formatTokenAmount(vaultInfo.allocation)} VNO
        </Text>
        <Text>{t('Number of deposits')}</Text>
        <Text textStyle="bodyBold" align="end">
          {vaultInfo.stakeCount}
        </Text>
      </SimpleGrid>
      {vaultInfo.stakeCount > 0 && (
        <Box w="full" mt={4}>
          <Text mb={3}>{t('Deposits')}</Text>
          <StakeCards
            displayStakeNumber={4}
            stakeCount={vaultInfo.stakeCount}
            lockedStakes={vaultInfo.lockedStakes}
            unlockedStakes={vaultInfo.unlockedStakes}
            vaultType={vaultInfo?.type || 'RESERVOIR'}
          />
        </Box>
      )}
    </>
  );
};
