export class VenoSDKError extends Error {
    constructor(message, type, code, failureInfo, rootCause) {
        super(message);
        this.type = type ?? VenoSDKErrorType.other;
        this.code = code;
        this.failureInfo = failureInfo;
        this.rootCause = rootCause;
    }
}
export var VenoSDKErrorType;
(function (VenoSDKErrorType) {
    VenoSDKErrorType[VenoSDKErrorType["TectonicCoreError"] = 0] = "TectonicCoreError";
    VenoSDKErrorType[VenoSDKErrorType["TokenError"] = 1] = "TokenError";
    VenoSDKErrorType[VenoSDKErrorType["other"] = 2] = "other";
})(VenoSDKErrorType || (VenoSDKErrorType = {}));
