import type { BigNumber } from '@ethersproject/bignumber';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { QueryKey } from '@ui/queries/queryKey';

const useVvsSupply = () => {
  const queryClient = useQueryClient();
  const sdk = useVenoSDK();
  const supply = async (amount: BigNumber, expected: number) => {
    const data = await sdk.VvsZap.addCroLiquidity(amount, expected);
    return await data.txReceiptPromise;
  };

  return useMutation(
    ({ amount, expected }: { amount: BigNumber; expected: number }) =>
      supply(amount, expected),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([QueryKey.VVS_CRO_LP_BALANCE]);
      },
    },
  );
};

export default useVvsSupply;
