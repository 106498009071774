import { Link, Text } from '@chakra-ui/react';
import { useTranslations } from '@ui/i18n';
import NextLink from '@ui/overrides/next/link';
import React from 'react';

import type { Section } from './const';
import { useFooterActions } from './FooterActionsProvider';

export const FooterSectionEntry = ({
  title,
  link,
  isExternal,
}: Section['entries'][0]) => {
  const t = useTranslations();
  const isFooterAction = typeof link !== 'string';
  const actions = useFooterActions();
  const titleToUse = typeof title === 'string' ? title : t(...title);

  if (isExternal || isFooterAction) {
    return (
      <Link
        href={!isFooterAction ? link : undefined}
        isExternal
        textStyle="textLink"
        color="brand.primary"
        display="block"
        onClick={isFooterAction ? actions[link] : undefined}
        mb={2.5}
      >
        {titleToUse}
      </Link>
    );
  }

  return (
    <Text
      _hover={{ textDecoration: 'underline', textDecorationThickness: '2px' }}
      textStyle="textLink"
      color="brand.primary"
      mb={2.5}
    >
      <NextLink href={link} passHref>
        {titleToUse}
      </NextLink>
    </Text>
  );
};
