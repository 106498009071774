import { Flex, Text } from '@chakra-ui/react';
import { features } from '@ui/components/Features';
import { pathDict } from '@ui/config/paths';
import { COIN_MODE } from '@ui/hooks/useCoinMode';
import { isFeatureEnabled } from '@ui/hooks/useIsFeatureEnabled';
import { useIsOnVvs } from '@ui/hooks/useIsOnVvs';
import { formatNumber, formatTokenAmount } from '@ui/utils/format';
import { formatPrice } from '@ui/utils/formatters/number';
import { useRouter } from 'next/router';
import React from 'react';

import AddTokenButton, { TokenType } from '../AddTokenButton';
import { TokenIcon } from '../TokenIcon';
import { useTransakModal } from '../TransakModal';
import AccountModalInfo from './AccountModalInfo';
import NftPanelCronos from './NftPanelCronos';
import { useAccountModalCronosBalances } from './useAccountModalData';
import { useAccountModalStore } from './useAccountModalStore';

function AccountModalInfoCronos() {
  const {
    balance,
    lcroBalance,
    atomBalance,
    latomBalance,
    vnoBalance,
    wcroBalance,
    balanceUsdValue,
    lcroBalanceUsd,
    atomBalanceUsd,
    latomBalanceUsd,
    vnoBalanceUsd,
    wcroBalanceUsd,
    tiaBalance,
    tiaBalanceUsd,
    ltiaBalance,
    ltiaBalanceUsd,
  } = useAccountModalCronosBalances();
  const router = useRouter();
  const isOnVvs = useIsOnVvs();
  const openTransakModal = useTransakModal((s) => s.onOpen);
  const {
    setOpenDrawer,
    isOpen,
    toggleAccountModal: toggle,
  } = useAccountModalStore();

  return (
    <>
      <AccountModalInfo
        title={
          <Flex gap={1}>
            <TokenIcon token="cro" />
            <Text>CRO</Text>
          </Flex>
        }
        convertedValue={
          balanceUsdValue !== undefined
            ? `${formatNumber(balanceUsdValue, 2)} USD`
            : undefined
        }
        convertedValueLoading={balanceUsdValue === undefined}
        actionType="buy"
        action={
          isFeatureEnabled(features.Transak)
            ? () => {
                toggle(false);
                openTransakModal({
                  onSuccessClose: () => {
                    router.push(pathDict.cro.stake, '', {
                      shallow: true,
                    });
                  },
                });
              }
            : () => setOpenDrawer('cro')
        }
        value={
          typeof balance !== 'undefined' ? formatPrice(balance) : undefined
        }
      />
      <AccountModalInfo
        className="veno-tour__account-modal-lcro"
        title={
          <Flex gap={1}>
            <TokenIcon token="lcro" />
            LCRO
            <AddTokenButton ml={1} tokenType={TokenType.LCRO} />
          </Flex>
        }
        actionType="get"
        action={isOnVvs ? undefined : () => setOpenDrawer('lcro')}
        value={
          typeof lcroBalance !== 'undefined'
            ? formatTokenAmount(lcroBalance)
            : undefined
        }
        convertedValueLoading={!lcroBalanceUsd}
        convertedValue={
          lcroBalanceUsd
            ? `${formatTokenAmount(lcroBalanceUsd, 2)} USD`
            : undefined
        }
      />
      <AccountModalInfo
        title={
          <Flex gap={1}>
            <TokenIcon token="atom" />
            ATOM
            <AddTokenButton ml={1} tokenType={TokenType.ATOM} />
          </Flex>
        }
        actionType="get"
        action={isOnVvs ? undefined : () => setOpenDrawer('atom')}
        value={
          typeof atomBalance !== 'undefined'
            ? formatPrice(atomBalance)
            : undefined
        }
        convertedValue={
          atomBalanceUsd !== undefined
            ? `${formatNumber(atomBalanceUsd, 2)} USD`
            : undefined
        }
        convertedValueLoading={atomBalanceUsd === undefined}
      />
      <AccountModalInfo
        title={
          <Flex gap={1}>
            <TokenIcon token="latom" />
            LATOM
            <AddTokenButton ml={1} tokenType={TokenType.LATOM} />
          </Flex>
        }
        actionType="get"
        action={isOnVvs ? undefined : () => setOpenDrawer('latom')}
        value={
          typeof latomBalance !== 'undefined'
            ? formatTokenAmount(
                latomBalance,
                undefined,
                COIN_MODE.atom.liquidDecimals,
              )
            : undefined
        }
        convertedValue={
          latomBalanceUsd
            ? `${formatTokenAmount(latomBalanceUsd, 2)} USD`
            : undefined
        }
        convertedValueLoading={!latomBalanceUsd}
      />
      <>
        <AccountModalInfo
          title={
            <Flex gap={1}>
              <TokenIcon token="vno" />
              VNO
              <AddTokenButton ml={1} tokenType={TokenType.VNO} />
            </Flex>
          }
          actionType="get"
          action={isOnVvs ? undefined : () => setOpenDrawer('vno')}
          value={
            typeof vnoBalance !== 'undefined'
              ? formatTokenAmount(vnoBalance)
              : undefined
          }
          convertedValue={
            vnoBalanceUsd
              ? `${formatTokenAmount(vnoBalanceUsd, 2)} USD`
              : undefined
          }
          convertedValueLoading={!vnoBalanceUsd}
        />
        <AccountModalInfo
          title={
            <Flex gap={1}>
              <TokenIcon token="cro" />
              WCRO
              <AddTokenButton ml={1} tokenType={TokenType.WCRO} />
            </Flex>
          }
          value={
            typeof wcroBalance !== 'undefined'
              ? formatTokenAmount(wcroBalance)
              : undefined
          }
          convertedValue={
            wcroBalanceUsd
              ? `${formatTokenAmount(wcroBalanceUsd, 2)} USD`
              : undefined
          }
          convertedValueLoading={!wcroBalanceUsd}
        />
        <AccountModalInfo
          title={
            <Flex gap={1}>
              <TokenIcon token="tia" />
              TIA
              <AddTokenButton ml={1} tokenType={TokenType.TIA} />
            </Flex>
          }
          actionType="get"
          action={isOnVvs ? undefined : () => setOpenDrawer('tia')}
          value={
            typeof tiaBalance !== 'undefined'
              ? formatPrice(tiaBalance)
              : undefined
          }
          convertedValue={
            tiaBalanceUsd !== undefined
              ? `${formatNumber(tiaBalanceUsd, 2)} USD`
              : undefined
          }
          convertedValueLoading={tiaBalanceUsd === undefined}
        />
        <AccountModalInfo
          title={
            <Flex gap={1}>
              <TokenIcon token="ltia" />
              LTIA
              <AddTokenButton ml={1} tokenType={TokenType.LTIA} />
            </Flex>
          }
          actionType="get"
          action={isOnVvs ? undefined : () => setOpenDrawer('ltia')}
          value={
            typeof ltiaBalance !== 'undefined'
              ? formatTokenAmount(
                  ltiaBalance,
                  undefined,
                  COIN_MODE.tia.liquidDecimals,
                )
              : undefined
          }
          convertedValue={
            ltiaBalanceUsd
              ? `${formatTokenAmount(ltiaBalanceUsd, 2)} USD`
              : undefined
          }
          convertedValueLoading={!ltiaBalanceUsd}
        />
      </>
      <NftPanelCronos />
    </>
  );
}

export default AccountModalInfoCronos;
