import { VvsPair__factory } from '@contracts';
import { BigNumber } from 'ethers';
import { VenoSDKModule } from './base';
import { getUsdPriceFromCdc, wrapSdkTransaction } from './util';
export default class VvsLpTokenModule extends VenoSDKModule {
    constructor(sdk, tokenCdcIdentifier1 = 'vvs-finance', tokenCdcIdentifier2 = 'veno-finance-vno', address) {
        super(sdk);
        this.contractFactory = VvsPair__factory;
        this.contractAddress = this.sdk.addresses.vvsPair;
        this.contractAddress = address ?? sdk.addresses.vvsPair;
        this.tokenCdcIdentifier1 = tokenCdcIdentifier1;
        this.tokenCdcIdentifier2 = tokenCdcIdentifier2;
    }
    async balanceOf(address) {
        return await this.getContract().balanceOf(address);
    }
    async allowance(owner, operator) {
        return await this.getContract().allowance(owner, operator);
    }
    async approve(operator, amount) {
        return await wrapSdkTransaction(this.getContract().approve(operator, amount));
    }
    async getLpUsdWorth(amount) {
        if (amount.lte(0))
            return BigNumber.from(0);
        const [[reservesToken1, reservesToken2], totalSupply, token1UsdPrice, token2UsdPrice,] = await Promise.all([
            this.getContract().getReserves(),
            this.getContract().totalSupply(),
            getUsdPriceFromCdc(this.tokenCdcIdentifier1),
            getUsdPriceFromCdc(this.tokenCdcIdentifier2),
        ]);
        // be safe if something went wrong
        if (!token1UsdPrice || !token2UsdPrice)
            return BigNumber.from(0);
        const token1Amount = amount.mul(reservesToken1).div(totalSupply);
        const token2Amount = amount.mul(reservesToken2).div(totalSupply);
        const factor = 100000000000000;
        const result = token1Amount
            .mul(Math.round(factor * token1UsdPrice))
            .add(token2Amount.mul(Math.round(factor * token2UsdPrice)))
            .div(factor);
        return result;
    }
}
