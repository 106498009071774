import { getContractAddresses } from '@ui/config/base';
import type { ChainType, VenoSDKWithoutTransaction } from '@veno-app/sdk';
import { VenoSDK } from '@veno-app/sdk';
import { currentWallet, getNetworkConfig } from '@veno-app/wallet';
import type { ReactNode } from 'react';
import { createContext, memo, useContext, useMemo } from 'react';

const VenoSDKContext = createContext<VenoSDK>(
  // we ensure this is always set in the provider
  null as unknown as VenoSDK,
);

const SpecificVenoSDKContext = createContext<
  Record<ChainType, VenoSDKWithoutTransaction>
>(
  // we ensure this is always set in the provider
  {
    cronos: null as unknown as VenoSDKWithoutTransaction,
    zksync: null as unknown as VenoSDKWithoutTransaction,
  },
);

interface TectonicSdkProviderProps {
  children: ReactNode;
}

function VenoSDKProvider({ children }: TectonicSdkProviderProps): JSX.Element {
  const { useProvider } = currentWallet;
  const web3Provider = useProvider();
  const isZksync = currentWallet.useIsZksyncChainId();

  const config = useMemo(() => getNetworkConfig(isZksync), [isZksync]);

  const sdk = useMemo(() => {
    const sdk = new VenoSDK(
      isZksync ? 'zksync' : 'cronos',
      getContractAddresses(isZksync ? 'zksync' : 'cronos'),
    );
    sdk.initWithRpc(config.rpcUrls[0], false);

    try {
      if (web3Provider) {
        sdk.initWithProvider(web3Provider);
      } else {
        sdk.initWithRpc(config.rpcUrls[0], false);
      }
    } catch (error) {
      console.error(error);
    }

    return sdk;
  }, [config.rpcUrls, isZksync, web3Provider]);

  const SpecificVenoSDKDict = useMemo((): Record<
    ChainType,
    VenoSDKWithoutTransaction
  > => {
    return {
      cronos: createSpecificVenoSDK('cronos'),
      zksync: createSpecificVenoSDK('zksync'),
    };
  }, []);

  return (
    <VenoSDKContext.Provider value={sdk}>
      <SpecificVenoSDKContext.Provider value={SpecificVenoSDKDict}>
        {children}
      </SpecificVenoSDKContext.Provider>
    </VenoSDKContext.Provider>
  );
}

export function useVenoSDK() {
  return useContext(VenoSDKContext);
}

export const useSpecificVenoSDK = (
  chain: ChainType,
): VenoSDKWithoutTransaction => {
  const SpecificVenoSDKDict = useContext(SpecificVenoSDKContext);
  return SpecificVenoSDKDict[chain];
};

const createSpecificVenoSDK = (
  chainType: ChainType,
): VenoSDKWithoutTransaction => {
  const sdk = new VenoSDK(chainType, getContractAddresses(chainType));
  sdk.initWithRpc(getNetworkConfig(chainType === 'zksync').rpcUrls[0], false);
  return sdk as unknown as VenoSDKWithoutTransaction;
};

export default memo(VenoSDKProvider);
