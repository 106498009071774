import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Link,
  Tab,
  TabList,
  Tabs,
} from '@chakra-ui/react';
import { features } from '@ui/components/Features';
import { useIsDesktop } from '@ui/components/MobileOrDesktop';
import { isFeatureEnabled } from '@ui/hooks/useIsFeatureEnabled';
import { useTranslations } from '@ui/i18n';
import { MAX_POOL_ID } from '@ui/utils/constants';
import getBgImage from '@ui/utils/getBgImageProps';
import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import { ArrowBack } from '@veno-app/icons';
import { currentWallet } from '@veno-app/wallet';
import { create } from 'zustand';

import { useVaultSelectionStore } from '../VaultSelectionStore';
import { useDepositDrawer } from './DepositDrawer';
import { useDepositUpgradeDrawer } from './DepositUpgradeDrawer';
import ReservoirOrFountain from './ReservoirOrFountain';
import type { VaultType } from './types';
import { VaultDetail } from './VaultDetail';

export const VaultDetailDrawer: React.FC = () => {
  const t = useTranslations();
  const isZksync = currentWallet.useIsZksyncChainId();

  const { isOpen, onClose, type, onOpen } = useVaultDetailDrawer();
  const vaultInfo = useVaultSelectionStore((s) => s.selectedVault);
  const resetSelectedVault = useVaultSelectionStore(
    (s) => s.resetSelectedVault,
  );
  const onOpenDepositDrawer = useDepositDrawer((s) => s.onOpen);
  const onOpenDepositUpgradeDrawer = useDepositUpgradeDrawer((s) => s.onOpen);
  const bgImageProps = !vaultInfo ? getBgImage('PATTERN_3') : {};
  const isDesktop = useIsDesktop();

  if (isDesktop) return null;

  return (
    <Drawer
      variant="backward"
      placement="right"
      size="full"
      isOpen={isOpen}
      onClose={() => {
        vaultInfo && resetSelectedVault();
        !vaultInfo && onClose();
      }}
    >
      <DrawerOverlay />
      <DrawerContent {...bgImageProps} bgPos={'right top -20px'}>
        <DrawerHeader>
          {type === 'RESERVOIR' ? 'Reservoir' : 'Fountain'}
        </DrawerHeader>
        <DrawerCloseButton variant="raised">
          <Icon as={ArrowBack} />
        </DrawerCloseButton>
        <DrawerBody
          as={Flex}
          direction="column"
          alignItems="stretch"
          gap={6}
          px="0"
        >
          {vaultInfo && (
            <Box px="16px">
              <VaultDetail />
            </Box>
          )}
          {!vaultInfo && (
            <Box>
              {(!isZksync || isFeatureEnabled(features.reservoirZksync)) && (
                <Tabs defaultIndex={type === 'FOUNTAIN' ? 0 : 1}>
                  <TabList>
                    <Tab onClick={() => onOpen('FOUNTAIN')}>Fountain</Tab>
                    <Tab onClick={() => onOpen('RESERVOIR')}>Reservoir</Tab>
                  </TabList>
                </Tabs>
              )}
              <ReservoirOrFountain type={type} />
            </Box>
          )}
        </DrawerBody>
        {vaultInfo && (
          <DrawerFooter>
            <Button onClick={() => onOpenDepositDrawer()}>
              {vaultInfo.stakeCount
                ? t('Deposit more')
                : t('Deposit in {platform}', {
                    platform:
                      vaultInfo.type === 'RESERVOIR' ? 'Reservoir' : 'Fountain',
                  })}
            </Button>
            {!!vaultInfo.stakeCount && vaultInfo.pid < MAX_POOL_ID && (
              <Link
                mt={2}
                variant="secondary"
                onClick={onOpenDepositUpgradeDrawer}
                textAlign="center"
              >
                {t('Upgrade locking period')}
              </Link>
            )}
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
};

type UseVaultDetailDrawer = {
  isOpen: boolean;
  onOpen: (type: VaultType) => void;
  onClose: () => void;
  type: VaultType;
};
export const useVaultDetailDrawer = create(
  resetWhenRouterChange<UseVaultDetailDrawer>((set, get) => ({
    isOpen: false,
    onOpen: (type: VaultType) => set(() => ({ isOpen: true, type })),
    onClose: () => {
      useVaultDetailDrawer.getState().$$resetData?.();
    },
    type: 'RESERVOIR',
  })),
);
