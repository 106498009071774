import type { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import { useQuery } from '@tanstack/react-query';
import { features } from '@ui/components/Features';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { getQuery } from '@ui/queries/queries';
import { QueryKey } from '@ui/queries/queryKey';
import {
  getVvsCroExchangeRate,
  getVvsExchangeRate,
  getVvsTiaExchangeRate,
} from '@ui/queries/sdk';
import type { GardenType } from '@ui/utils/garden';
import { gardenTokenDict } from '@ui/utils/garden';

import type { CoinMode } from './useCoinMode';
import { isFeatureEnabled } from './useIsFeatureEnabled';
import { useTectonicExchangeRate } from './usePartnerStats';

const isValidAmount = (lpAmount: number | string | undefined): boolean => {
  return (
    lpAmount !== undefined &&
    lpAmount !== '' &&
    !(typeof lpAmount === 'number' && isNaN(lpAmount))
  );
};

const tectonicEnabled = isFeatureEnabled(features.TectonicGarden);

const isBigNumber = (
  lpAmount: BigNumber | number | string | undefined,
): lpAmount is BigNumber => {
  return (
    lpAmount !== undefined && (lpAmount as BigNumber).toHexString !== undefined
  );
};

const useLpReceiveValue = (
  coinMode: CoinMode,
  type: GardenType,
  lpAmount: BigNumber | number | string | undefined,
): {
  receiveAmount: number | undefined;
  exchangeRate: number | undefined;
  isFetching: boolean;
} => {
  const sdk = useVenoSDK();

  const amountForCalculation = isBigNumber(lpAmount)
    ? formatUnits(lpAmount, gardenTokenDict[coinMode][type].decimals)
    : lpAmount;

  const ferroExchangeRateQuery = getQuery(QueryKey.FERRO_EXCHANGE_RATE)(
    coinMode,
    sdk,
    amountForCalculation ?? '',
  );
  const { data: ferroExchangeRate, isFetching: ferroExchangeRateFetching } =
    useQuery(ferroExchangeRateQuery.queryKey, ferroExchangeRateQuery.queryFn, {
      enabled: type === 'FERRO' && isValidAmount(amountForCalculation),
    });

  const vvsExchangeRateQuery = getVvsExchangeRate(
    sdk,
    amountForCalculation ?? '',
  );
  const { data: vvsExchangeRate, isFetching: vvsExchangeRateFetching } =
    useQuery(vvsExchangeRateQuery.queryKey, vvsExchangeRateQuery.queryFn, {
      enabled: type === 'VVS' && isValidAmount(amountForCalculation),
    });
  const tectonicExchangeRate = useTectonicExchangeRate(
    tectonicEnabled &&
      type === 'TECTONIC' &&
      isValidAmount(amountForCalculation),
  );

  const vvsCroExchangeRateQuery = getVvsCroExchangeRate(
    sdk,
    amountForCalculation ?? '',
  );
  const { data: vvsCroExchangeRate, isFetching: vvsCroExchangeRateFetching } =
    useQuery(
      vvsCroExchangeRateQuery.queryKey,
      vvsCroExchangeRateQuery.queryFn,
      {
        enabled: type === 'VVS_CRO' && isValidAmount(amountForCalculation),
      },
    );

  const vvsTiaExchangeRateQuery = getVvsTiaExchangeRate(
    sdk,
    amountForCalculation ?? '',
  );
  const { data: vvsTiaExchangeRate, isFetching: vvsTiaExchangeRateFetching } =
    useQuery(
      vvsTiaExchangeRateQuery.queryKey,
      vvsTiaExchangeRateQuery.queryFn,
      {
        enabled: type === 'VVS_TIA' && isValidAmount(amountForCalculation),
      },
    );

  let exchangeRate = ferroExchangeRate;
  let exchangeRateFetching = ferroExchangeRateFetching;
  switch (type) {
    case 'TECTONIC':
      exchangeRate = tectonicExchangeRate?.data;
      exchangeRateFetching = tectonicExchangeRate?.isFetching ?? false;
      break;
    case 'VVS':
      exchangeRate = vvsExchangeRate;
      exchangeRateFetching = vvsExchangeRateFetching;
      break;
    case 'VVS_CRO':
      exchangeRate = vvsCroExchangeRate;
      exchangeRateFetching = vvsCroExchangeRateFetching;
      break;
    case 'VVS_TIA':
      exchangeRate = vvsTiaExchangeRate;
      exchangeRateFetching = vvsTiaExchangeRateFetching;
      break;
  }

  const receiveAmount =
    exchangeRate && isValidAmount(amountForCalculation) && !exchangeRateFetching
      ? exchangeRate *
        (typeof amountForCalculation === 'number'
          ? amountForCalculation
          : parseFloat(amountForCalculation ?? '0'))
      : undefined;

  return { receiveAmount, exchangeRate, isFetching: exchangeRateFetching };
};

export default useLpReceiveValue;
