import { LiquidCro__factory } from '@contracts';
import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from 'ethers/lib/utils';
import LiquidCoinModule from './liquidCoin';
import { getCroUsdPrice, wrapSdkTransaction } from './util';
export default class LiquidCroModule extends LiquidCoinModule {
    constructor() {
        super(...arguments);
        this.contractFactory = LiquidCro__factory;
        this.contractAddress = this.sdk.addresses.liquidCro;
    }
    async stake(receiver, value) {
        return await wrapSdkTransaction(this.getContract().stake(receiver, { value }));
    }
    getReceivedLcro(receipt) {
        const args = receipt.events?.find((e) => e.event === 'Transfer')?.args;
        if (!args || args.length !== 3)
            return null;
        return args[2];
    }
    async estimateGasForStake(receiver) {
        const { maxFeePerGas } = await this.sdk.provider.getFeeData();
        const gasUnits = await this.getContract().estimateGas.stake(receiver, {
            value: parseUnits('0.00000001', 18),
        });
        if (!maxFeePerGas)
            return BigNumber.from(0);
        return maxFeePerGas.mul(gasUnits);
    }
    async totalSupply() {
        return await this.getContract().totalSupply();
    }
    croPerLcro() {
        return this.coinPerStakedCoin();
    }
    async totalPooled() {
        return await this.getContract().getTotalPooledCro();
    }
    async getUsdWorth(amount) {
        if (amount.lte(0))
            return BigNumber.from(0);
        const [price, croPerLcro] = await Promise.all([
            getCroUsdPrice(),
            this.croPerLcro(),
        ]);
        if (!price)
            return BigNumber.from(0);
        const factor = 100000000000;
        return amount.mul(Math.round(factor * price * croPerLcro)).div(factor);
    }
}
