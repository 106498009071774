import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { formatNumber } from '@ui/utils/format';
import {
  TRANSACTION_TYPE_FERRO_IN,
  TRANSACTION_TYPE_FERRO_OUT,
  TRANSACTION_TYPE_REDEEM,
  TRANSACTION_TYPE_STAKE,
  TRANSACTION_TYPE_TECTONIC_IN,
  TRANSACTION_TYPE_TECTONIC_OUT,
  TRANSACTION_TYPE_UNSTAKE,
  TRANSACTION_TYPE_VVS_IN,
  TRANSACTION_TYPE_VVS_OUT,
} from '@ui/utils/transformTransaction';
import React from 'react';

import type { Transaction } from './TransactionHistoryDrawer/useTransactionHistory';

const TransactionAmount = ({
  transaction,
  isSmall,
}: { transaction: Transaction } & { isSmall?: boolean }) => {
  const croAmountNode = (
    <>
      <Text
        fontWeight={isSmall ? undefined : 700}
        mr={isSmall ? 0.5 : 1}
        color={transaction.croAmount > 0 ? 'green-500' : undefined}
        wordBreak="break-all"
      >
        {formatNumber(
          transaction.croAmount,
          Math.abs(transaction.croAmount) >= 100 ? 2 : 6,
          false,
          true,
        )}
      </Text>
      <Image
        alt=""
        src="/tokens/cro.svg"
        w={isSmall ? { base: '12px', desktop: '20px' } : '24px'}
        h={isSmall ? { base: '12px', desktop: '20px' } : '24px'}
      />
    </>
  );
  const lcroAmountNode = (
    <>
      <Text
        fontWeight={isSmall ? undefined : 700}
        mr={isSmall ? 0.5 : 1}
        color={transaction.lcroAmount > 0 ? 'green-500' : undefined}
        wordBreak="break-all"
      >
        {formatNumber(
          transaction.lcroAmount,
          Math.abs(transaction.lcroAmount) >= 100 ? 2 : 6,
          false,
          true,
        )}
      </Text>
      <Image
        alt=""
        src="/tokens/lcro.svg"
        w={isSmall ? { base: '12px', desktop: '20px' } : '24px'}
        h={isSmall ? { base: '12px', desktop: '20px' } : '24px'}
      />
    </>
  );
  switch (transaction.type) {
    case TRANSACTION_TYPE_FERRO_IN:
    case TRANSACTION_TYPE_FERRO_OUT:
    case TRANSACTION_TYPE_VVS_IN:
    case TRANSACTION_TYPE_VVS_OUT:
      return (
        <Box>
          <Flex justify="flex-end">{croAmountNode}</Flex>
          <Flex justify="flex-end">{lcroAmountNode}</Flex>
        </Box>
      );
    case TRANSACTION_TYPE_TECTONIC_IN:
    case TRANSACTION_TYPE_TECTONIC_OUT:
      return (
        <>
          <Text
            fontWeight={700}
            mr={1}
            color={
              transaction.type === TRANSACTION_TYPE_TECTONIC_IN
                ? 'green-500'
                : undefined
            }
          >
            {formatNumber(
              transaction.tTokenAmount,
              Math.abs(transaction.tTokenAmount) >= 100 ? 2 : 6,
              false,
              true,
            )}{' '}
            tLCRO
          </Text>
        </>
      );
    case TRANSACTION_TYPE_STAKE:
    case TRANSACTION_TYPE_UNSTAKE:
    case TRANSACTION_TYPE_REDEEM:
      return croAmountNode;
    default:
      return lcroAmountNode;
  }
};

export default TransactionAmount;
