import { VenoReservoir__factory } from '@contracts';
import { BigNumber } from '@ethersproject/bignumber';
import { VenoSDKModule } from './base';
import { DIGITS } from './constants';
import FeeDistributorModule from './feeDistributor';
import { getCroUsdPrice, getEthUsdPrice, getVnoUsdPrice, wrapSdkTransaction, } from './util';
export default class VenoReservoirModule extends VenoSDKModule {
    constructor() {
        super(...arguments);
        this.contractFactory = VenoReservoir__factory;
        this.contractAddress = this.sdk.addresses.venoReservoir;
        this.FeeDistributor = new FeeDistributorModule(this.sdk);
    }
    get poolLength() {
        return this.getContract().poolLength();
    }
    async deposit(pid, amount) {
        return await wrapSdkTransaction(this.getContract().deposit(pid, amount));
    }
    async batchWithdraw(stakeIds) {
        return await wrapSdkTransaction(this.getContract().batchWithdraw(stakeIds));
    }
    batchUpgrade(stakeIds, targetPid) {
        return wrapSdkTransaction(this.getContract().batchUpgrade(stakeIds, new Array(stakeIds.length).fill(targetPid)));
    }
    async getUserInfo(owner) {
        const ret = await this.getContract().getUserInfo(owner);
        return {
            weightedAmount: ret[0],
            stakes: ret[1].map((stake, stakeId) => ({
                ...stake,
                // stake id for this user is just the index in this list of stakes
                stakeId: BigNumber.from(stakeId),
            })),
        };
    }
    async getPoolInfos() {
        try {
            const [rewardPerYear, poolCounts, rewardTokenPrice, vnoPrice] = await Promise.all([
                this.FeeDistributor.rewardsPerYear(this.sdk.chainType === 'zksync'
                    ? this.sdk.addresses.weth
                    : this.sdk.addresses.wcro),
                this.poolLength,
                this.sdk.chainType === 'zksync' ? getEthUsdPrice() : getCroUsdPrice(),
                getVnoUsdPrice(),
            ]);
            const rawPoolInfos = await Promise.all([...Array(poolCounts.toNumber()).keys()].map((pid) => this.getContract().poolInfo(pid)));
            const totalWeightedStaked = rawPoolInfos.reduce((acc, curr) => acc.add(curr.totalStaked.mul(curr.multiplier)), BigNumber.from(0));
            return rawPoolInfos.map(({ multiplier, lockPeriod, totalStaked }, i) => {
                const weightedStaked = totalStaked.mul(multiplier);
                return {
                    pid: i,
                    multiplier,
                    lockPeriod,
                    totalStaked,
                    apr: totalStaked.isZero() || !rewardTokenPrice || !vnoPrice
                        ? null
                        : rewardPerYear
                            .mul(Math.trunc((rewardTokenPrice / vnoPrice) * 10 ** DIGITS))
                            .mul(weightedStaked)
                            .div(totalWeightedStaked)
                            .div(totalStaked)
                            .toNumber() /
                            10 ** DIGITS,
                };
            });
        }
        catch (err) {
            console.error(err);
            return [];
        }
    }
    async pendingTokens(owner, rewardTokens) {
        const ret = await this.getContract().pendingTokens(owner, rewardTokens);
        return {
            wcro: ret[0] ?? BigNumber.from('0'),
            atom: ret[1] ?? BigNumber.from('0'),
            // for staking on zksync, there's one reward token only, which is weth
            weth: ret[0] ?? BigNumber.from('0'),
        };
    }
    async getRewardTokens() {
        return this.getContract().getRewardTokens();
    }
    async balanceOf(address) {
        return await this.getContract().balanceOf(address);
    }
}
