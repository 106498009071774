import type { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { currentWallet } from '@veno-app/wallet';

export const QUERY_KEY_TOKEN_BALANCE = 'useTokenBalance';

type SdkTokenModule = {
  balanceOf: (this: unknown, address: string) => Promise<BigNumber>;
  contractAddress: string;
};

export function useTokenBalance(
  sdkModule: SdkTokenModule,
  decimals: number,
  options: UseQueryOptions<number | undefined> = {},
) {
  const account = currentWallet.useAccount();
  const chainId = currentWallet.useChainId();

  return useQuery<number | undefined>(
    [QUERY_KEY_TOKEN_BALANCE, sdkModule.contractAddress, account, chainId],
    async () => {
      if (!account) {
        return;
      }

      const balance = await sdkModule.balanceOf(account);

      return Number(formatUnits(balance, decimals));
    },
    {
      ...options,
      enabled: account ? options.enabled : false,
    },
  );
}
