import { Flex, Icon, Skeleton, Text } from '@chakra-ui/react';
import type { CardProps } from '@ui/components/Card';
import { Card } from '@ui/components/Card';
import TransactionAmount from '@ui/components/TransactionAmount';
import { useTranslations } from '@ui/i18n';
import { formatNumber } from '@ui/utils/format';
import { ExpandLess, ExpandMore } from '@veno-app/icons';
import { format } from 'date-fns';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useUnmount } from 'react-use';

import TransactionDetailContent from './TransactionDetailContent';
import {
  getTransactionTypeText,
  useTransactionDetailDrawerStore,
} from './TransactionDetailDrawer';
import type { Transaction } from './useTransactionHistory';
import { useTransactionHistory } from './useTransactionHistory';

type TransactionHistoryContentProps = {
  isOpen: boolean;
  idRef: React.MutableRefObject<string>;
  expandHistoryInfo?: boolean;
};

const TransactionHistoryContent = ({
  isOpen,
  idRef,
  expandHistoryInfo,
}: TransactionHistoryContentProps) => {
  const transactionHistoryQuery = useTransactionHistory();
  const isEmpty = !transactionHistoryQuery.data?.pages[0].length;
  const hasMore = transactionHistoryQuery.hasNextPage !== false;

  useUnmount(() => {
    transactionHistoryQuery.remove();
  });

  return (
    <>
      <InfiniteScroll
        style={{
          overflow: 'unset',
        }}
        scrollableTarget={idRef.current}
        dataLength={transactionHistoryQuery.data?.pages.length ?? 0}
        next={() => {
          transactionHistoryQuery.fetchNextPage({
            cancelRefetch: false,
          });
        }}
        hasMore={hasMore}
        loader={
          transactionHistoryQuery.isInitialLoading ||
          transactionHistoryQuery.isFetchingNextPage ? (
            <>
              <Skeleton w="full" h="66px" mt={4} />
              <Skeleton w="full" h="66px" mt={4} />
            </>
          ) : null
        }
        endMessage={null}
      >
        <Flex direction="column" gap={4}>
          {transactionHistoryQuery.data?.pages.map((transactions) => {
            return transactions.map((transaction) => (
              <TransactionHistoryCard
                key={transaction.id}
                transaction={transaction}
                expandHistoryInfo={expandHistoryInfo}
                w="full"
              />
            ));
          })}
        </Flex>
      </InfiniteScroll>
      {!hasMore && isEmpty && <>Make your first transaction now!</>}
    </>
  );
};

export const TransactionHistoryCard = ({
  transaction,
  expandHistoryInfo,
  ...props
}: CardProps & {
  transaction: Transaction;
  expandHistoryInfo?: boolean;
}) => {
  const t = useTranslations();
  const toggleDetailDrawer = useTransactionDetailDrawerStore((s) => s.toggle);
  const [expandedTransaction, setExpandedTransaction] = useState('');

  return (
    <Card
      boxShadow={
        expandedTransaction === transaction.id ? 'card.active' : 'card.normal'
      }
      cursor="pointer"
      pl={4}
      pr={2}
      py={2}
      m={0}
      onClick={() =>
        expandHistoryInfo
          ? setExpandedTransaction((oldId) =>
              oldId === transaction.id ? '' : transaction.id,
            )
          : toggleDetailDrawer(true, transaction)
      }
      {...props}
    >
      <Flex justify="space-between" textStyle="body">
        <Text textStyle={{ desktop: 'h4' }}>
          {getTransactionTypeText(t, transaction)}
        </Text>
        <Flex>
          <TransactionAmount transaction={transaction} />
          <Icon
            h="24px"
            w="24px"
            color="bg.button"
            as={
              expandedTransaction === transaction.id ? ExpandLess : ExpandMore
            }
            ml={2}
          />
        </Flex>
      </Flex>
      <Flex justify="space-between" mt={0.5}>
        <Text textStyle={{ base: 'caption', desktop: 'body' }}>
          {format(transaction.date, 'dd/MM/yyyy')}
        </Text>
        <Text textStyle="body" mr={9} color="text.light">
          {formatNumber(transaction.usdAmount, 2, false, true)} USD
        </Text>
      </Flex>
      {expandedTransaction === transaction.id && (
        <TransactionDetailContent
          mt={3}
          mb={1}
          mr={{ base: 0, desktop: 8 }}
          transaction={transaction}
        />
      )}
    </Card>
  );
};

export default TransactionHistoryContent;
