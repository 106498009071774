import { useQuery } from '@tanstack/react-query';
import { EnsContext } from '@ui/providers/EnsProvider';
import { getQuery } from '@ui/queries/queries';
import { QueryKey } from '@ui/queries/queryKey';
import { useContext } from 'react';

export const useCroId = (address: string) => {
  const ensContext = useContext(EnsContext);
  const query = getQuery(QueryKey.CRO_ID)(ensContext, address);
  return useQuery({ ...query, enabled: !!address });
};
