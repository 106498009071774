import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import { create } from 'zustand';

export type AccountModalStore = {
  isOpen: boolean;
  toggleAccountModal: (isOpen?: boolean) => void;
  openDrawer: string;
  setOpenDrawer: (drawer: string) => void;
};

export const useAccountModalStore = create<AccountModalStore>(
  resetWhenRouterChange((set) => ({
    isOpen: false,
    openDrawer: '',
    setOpenDrawer: (openDrawer) => set(() => ({ openDrawer })),
    toggleAccountModal: (isOpen) =>
      set((state) => ({
        isOpen: typeof isOpen !== 'undefined' ? isOpen : !state.isOpen,
      })),
  })),
);
