import {
  FERRO_POOL_ATOM_LATOM,
  FERRO_POOL_CRO_LCRO,
  VVS_CRO_POOL,
  VVS_VNO_POOL,
} from '@ui/config/partners';
import type { CoinMode } from '@ui/hooks/useCoinMode';

import { QueryKey } from './queryKey';
import { getZksyncApr } from './zksyncApy';

interface FetchFerroApysResponse {
  data: Record<
    string,
    {
      baseApy: number;
      ferroApy: number;
      baseApr: number;
      ferroApr: number;
    }
  >;
}

interface FetchVvsApysResponse {
  data: { farmsData: Record<string, { baseApy: number; vvsApy: number }> };
}

const FETCH_URI_FERRO = 'https://api.ferroprotocol.com/info/api/getApys';
const FETCH_URI_VVS = 'https://api.vvs.finance/info/api/apys';

export const getFerroApy = (coinMode: CoinMode, skipFarmApr = false) => ({
  queryKey: [QueryKey.FERRO_APY, coinMode, skipFarmApr],
  queryFn: async () => {
    const aprKey =
      coinMode === 'atom' ? FERRO_POOL_ATOM_LATOM : FERRO_POOL_CRO_LCRO;
    const fetchResponse = await fetch(FETCH_URI_FERRO);
    const response = (await fetchResponse.json()) as FetchFerroApysResponse;

    try {
      return (
        response.data[aprKey].baseApr +
        (skipFarmApr ? 0 : response.data[aprKey].ferroApr)
      );
    } catch (err) {
      throw new Error(`invalid response from ${FETCH_URI_FERRO}`);
    }
  },
});

export const getVvsApy = (skipFarmApr = false) => ({
  queryKey: [QueryKey.VVS_APY, skipFarmApr],
  queryFn: async () => {
    const fetchResponse = await fetch(FETCH_URI_VVS);
    const response = (await fetchResponse.json()) as FetchVvsApysResponse;

    try {
      return (
        response.data.farmsData[VVS_VNO_POOL].baseApy +
        (skipFarmApr ? 0 : response.data.farmsData[VVS_VNO_POOL].vvsApy)
      );
    } catch (err) {
      throw new Error(`invalid response from ${FETCH_URI_VVS}`);
    }
  },
});

export const getVvsCroApy = (skipFarmApr = false) => ({
  queryKey: ['vvs-cro-apy', skipFarmApr],
  queryFn: async () => {
    const fetchResponse = await fetch(FETCH_URI_VVS);
    const response = (await fetchResponse.json()) as FetchVvsApysResponse;

    try {
      return (
        response.data.farmsData[VVS_CRO_POOL].baseApy +
        (skipFarmApr ? 0 : response.data.farmsData[VVS_CRO_POOL].vvsApy)
      );
    } catch (err) {
      throw new Error(`invalid response from ${FETCH_URI_VVS}`);
    }
  },
});

/**
 * Function for getting vvs tia lp provider apr, similar to other LP apr functions.
 * it will always return 0, because for tia lp, there is no API available.
 *
 * @param skipFarmApr
 * @returns
 */
export const getVvsTiaApy = () => ({
  queryKey: ['vvs-tia-apy'],
  // for tia, always assume 0. we'll add hints about liquidity provider earnings
  queryFn: async (): Promise<number> => {
    return 0;
  },
});

export const getSyncSwapLethEthApr = () => ({
  queryKey: ['leth-eth-apr'],
  queryFn: async () => {
    const apr = await getZksyncApr(
      '0xDFdE5467560C82464a6A78926a88ca7E605a8dA0',
    );
    return apr;
  },
});

export const getSyncSwapEthVnoApr = () => ({
  queryKey: ['eth-vno-apr'],
  queryFn: async () => {
    // todo call getZksyncApr with correct pool. just as above.
    return 0;
  },
});
