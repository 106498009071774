import { ModalCloseButton } from '@chakra-ui/react';
import { InDesktop } from '@ui/components/MobileOrDesktop';
import {
  ModalOrDragDrawerContent,
  ModalOrDrawer,
  ModalOrDrawerBody,
  ModalOrDrawerFooter,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from '@ui/components/ModalOrDrawer';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { useTranslations } from '@ui/i18n';
import { gardenTokenDict } from '@ui/utils/garden';
import React from 'react';

import type { DoSupplyContentProps } from './DoSupplyContent';
import DoSupplyContent, { DoSupplyContentFooter } from './DoSupplyContent';

type DoSupplyDrawerProps = {
  coinMode: CoinMode;
  name: string;
  isOpen: boolean;
  onClose: () => void;
  onDone: () => void;
} & DoSupplyContentProps;

function DoSupplyDrawer({
  isOpen,
  onClose,
  coinMode,
  name,
  onDone,
  type,
  ...supplyContentProps
}: DoSupplyDrawerProps) {
  const t = useTranslations();
  const suppliedTokenName =
    gardenTokenDict[coinMode][type].suppliedTokenName ?? '';

  return (
    <ModalOrDrawer
      variant="wholeScreen"
      placement="bottom"
      size={{
        base: 'full',
        desktop: 'md',
      }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOrDrawerOverlay bg="bg-gauze" />
      <ModalOrDragDrawerContent>
        <InDesktop>
          <ModalCloseButton />
        </InDesktop>
        <ModalOrDrawerHeader>
          {t('Supply {coin} on {platform}', {
            coin: suppliedTokenName,
            platform: name,
          })}
        </ModalOrDrawerHeader>
        <ModalOrDrawerBody>
          <DoSupplyContent
            coinMode={coinMode}
            type={type}
            {...supplyContentProps}
          />
        </ModalOrDrawerBody>
        <ModalOrDrawerFooter>
          <DoSupplyContentFooter
            onClose={onDone}
            type={type}
            transactionStatus={supplyContentProps.transactionStatus}
          />
        </ModalOrDrawerFooter>
      </ModalOrDragDrawerContent>
    </ModalOrDrawer>
  );
}

export default DoSupplyDrawer;
