import {
  Flex,
  Link as ChakraLink,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { useFreezeScroll } from '@ui/hooks/useFreezeScroll';
import { useTranslations } from '@ui/i18n';
import { cloneElement } from 'react';

import { InDesktop, InMobile, useIsDesktop } from '../MobileOrDesktop';
import { AnnouncementContent, MarkAllButton } from './AnnouncementContent';
import { useAnnouncementPopover } from './useAnnouncementPopover';

export type AnnouncementPopoverProps = {
  children: JSX.Element;
};
export const AnnouncementPopover = ({ children }: AnnouncementPopoverProps) => {
  const isDesktop = useIsDesktop();
  const isOpen = useAnnouncementPopover((s) => s.isOpen);
  const onOpen = useAnnouncementPopover((s) => s.onOpen);
  const onClose = useAnnouncementPopover((s) => s.onClose);
  const t = useTranslations();

  const clonedChildren = cloneElement(children, {
    onClick: (e: Event) => {
      if (isOpen) {
        onClose();
      } else {
        onOpen();
      }
      return children.props?.onClick?.(e);
    },
  });

  useFreezeScroll(isOpen);

  return (
    <>
      <InMobile>{clonedChildren}</InMobile>
      <Popover
        isOpen={isDesktop && isOpen}
        onClose={onClose}
        placement="bottom-end"
        isLazy
        strategy="fixed"
      >
        <InDesktop>
          <PopoverTrigger>{clonedChildren}</PopoverTrigger>
        </InDesktop>
        <PopoverContent width="400px">
          <PopoverHeader
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text textStyle="h4">{t('Announcements')}</Text>
            <MarkAllButton />
          </PopoverHeader>
          <PopoverBody height="550px" maxHeight="70vh" overflowY="auto" p="0">
            <AnnouncementContent />
          </PopoverBody>
          <PopoverFooter
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <ChakraLink
              width="100%"
              href={EXTERNAL_LINK.telegramAnnouncements}
              isExternal
              textAlign="center"
            >
              {t('view all announcements')}
            </ChakraLink>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </>
  );
};

export const AnnouncementDrawer = () => {
  const isOpen = useAnnouncementPopover((s) => s.isOpen);
  const onClose = useAnnouncementPopover((s) => s.onClose);
  const t = useTranslations();

  return (
    <Modal
      size="full"
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent>
        <ModalHeader>
          <Text textStyle="h3">{t('Announcements')}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px="0">
          <Flex p="0px 14px 12px" justify="flex-end">
            <MarkAllButton />
          </Flex>
          <AnnouncementContent />
        </ModalBody>
        <ModalFooter>
          <ChakraLink
            href={EXTERNAL_LINK.telegramAnnouncements}
            isExternal
            textAlign="center"
          >
            {t('view all announcements')}
          </ChakraLink>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
