import { currentWallet } from '@veno-app/wallet';
import Cookies from 'js-cookie';

import { trackEvent } from './tracking';

const { useIsConnected } = currentWallet;

const COOKIE_EXPIRATION_DAYS = 7;
const COOKIE = 'CAMPAIGN_VISITED';

/**
 * Can use on campaign page once it's ready; to find users that visited it and were already connected
 */
export const useTrackConnectedCampaignVisitor = () => {
  const isConnected = useIsConnected();

  if (isConnected) {
    const cookieValue = Cookies.get(COOKIE);
    if (cookieValue) return;
    Cookies.set(COOKIE, 'true', { expires: COOKIE_EXPIRATION_DAYS });
    trackEvent('Connected User visits Campaign');
  }
};

/**
 * Call this function if a user finished connecting on campaign page to track on GA/Mixpanel.
 * To be called from the navigation bar connect button, but also there might
 * be another button on campain page
 */
export const trackConnectionOnCampaignPage = () => {
  const cookieValue = Cookies.get(COOKIE);
  // we already know this user, do nothing
  if (cookieValue) return;

  Cookies.set(COOKIE, 'true', { expires: COOKIE_EXPIRATION_DAYS });
  trackEvent('User connected on Campaign');
};
