import { Box } from '@chakra-ui/react';
import router from '@ui/overrides/next/router';
import type { ErrorInfo, ReactNode } from 'react';
import { Component } from 'react';

interface Props {
  children: ReactNode;
}

interface State {
  error: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
  public reset: () => void;

  public constructor(props: Props) {
    super(props);
    this.state = { error: null };

    this.reset = () => {
      this.setState({ error: null });
    };
  }

  componentDidMount(): void {
    router.events.on('routeChangeComplete', this.reset);
  }

  componentWillUnmount(): void {
    router.events.off('routeChangeComplete', this.reset);
  }

  public static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.error) {
      return (
        <Box textStyle="bodySmall" p="10px">
          a client-side exception has occurred:
          <Box whiteSpace="pre-line" wordBreak="break-all">
            {this.state.error.stack ||
              this.state.error.message ||
              'see the browser console for more information'}
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
