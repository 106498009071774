import { QueryKey } from './queryKey';

interface VvsTvlResponse {
  data: Record<string, { liquidity: string }>;
}

const FETCH_URI = 'https://api.vvs.finance/info/api/pairs';
const VVS_VENO_PAIR =
  '0x2D03bECE6747ADC00E1a131BBA1469C15fD11e03_0xdb7d0A1eC37dE1dE924F8e8adac6Ed338D4404E9';

export const getVvsTvl = () => ({
  queryKey: [QueryKey.VVS_TVL],
  queryFn: async () => {
    const fetchResponse = await fetch(FETCH_URI);
    const response = (await fetchResponse.json()) as VvsTvlResponse;

    try {
      return parseFloat(response.data[VVS_VENO_PAIR].liquidity);
    } catch (err) {
      throw new Error(`invalid response from ${FETCH_URI}`);
    }
  },
});
