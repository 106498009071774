/* eslint-disable unused-imports/no-unused-vars */
import whitelists from '@ui/config/whitelist.json';
import { useHashedAddress } from '@ui/hooks/useHashedAddress';
import useIsFeatureEnabled, {
  AppEnvironment,
} from '@ui/hooks/useIsFeatureEnabled';
import type { FunctionComponent, ReactElement, ReactNode } from 'react';
import React from 'react';

const devOnly: DisabledEnvironments = [
  AppEnvironment.Staging1,
  AppEnvironment.Staging2,
  AppEnvironment.UAT,
  AppEnvironment.Production,
];
const devAndSta: DisabledEnvironments = [
  AppEnvironment.UAT,
  AppEnvironment.Production,
];

const noEnv: DisabledEnvironments = [
  AppEnvironment.Dev,
  AppEnvironment.Development,
  AppEnvironment.Staging1,
  AppEnvironment.Staging2,
  AppEnvironment.UAT,
  AppEnvironment.Production,
];

const noProd: DisabledEnvironments = [AppEnvironment.Production];
const all: DisabledEnvironments = [];

export const features = {
  TectonicGarden: all,
  MintFakeCruiser: noEnv,
  Transak: all,
  Atom: all,
  NewGardenApr: all,
  strategyVault: all,
  vvsLpTracking: all,
  lowCroBalanceDrawer: all,
  useLcroCard: all,
  HarvestAll: all,
  i18n: all,
  tectonicSupportVno: all,
  wowmaxxSupport: all,
  italianLanguage: all,
  boomerSquadNft: all,
  balliesNft: all,
  announcement: all,
  stakingTour: all,
  statsPage: all,
  statsTvl: devAndSta,
  vnoTokenZksync: all,
  campaignJanuary24: all,
  reservoirZksync: devAndSta,
  gardenZksync: all,
  earningsSubgraphZksync: noEnv,
  reservoirLockZksync: noEnv,
  topDogCorgiAndCronosChimpNft: all,
  contentfulMarketingCampaigns: noEnv,
  contentfulMarketingArticles: all,
};

type DisabledEnvironments = AppEnvironment[];

interface FeatureProps {
  children?: ReactNode | ((isFeatureEnabled: boolean) => ReactNode | null);
  disabledEnvironments: AppEnvironment[];
  whitelist?: Whitelist;
}

const createFeatures = () =>
  Object.entries(features).reduce((all, [name, disabledEnvironments]) => {
    all[name as keyof typeof features] = createFeatureComponent(
      name,
      disabledEnvironments,
    );
    return all;
  }, {} as Record<keyof typeof features, ReturnType<typeof createFeatureComponent>>);

function Feature({
  children,
  disabledEnvironments,
  whitelist,
}: FeatureProps): ReactElement | null {
  const isFeatureEnabled = useIsFeatureEnabled();
  const hashedAddress = useHashedAddress();
  if (typeof children === 'function') {
    return (
      <>
        {children(
          isFeatureEnabled({ disabledEnvironments, whitelist, hashedAddress }),
        )}
      </>
    );
  }

  if (!isFeatureEnabled({ disabledEnvironments, whitelist, hashedAddress })) {
    return null;
  }

  return <>{children || null}</>;
}

function createFeatureComponent(
  featureName: string,
  disabledEnvironments: AppEnvironment[],
) {
  const fn = featureName as keyof typeof whitelists;
  const whitelist: Whitelist | undefined = whitelists[fn];
  const Component: FunctionComponent<Pick<FeatureProps, 'children'>> = ({
    children,
  }) =>
    React.createElement(
      Feature,
      { disabledEnvironments, whitelist },
      <>{children}</>,
    );

  Component.displayName = featureName;
  return Component;
}

export type Whitelist = {
  main: string[];
  test: string[];
};

export default createFeatures();
