import merge from 'lodash/merge';
import { textStyles } from '../textStyles';
import { ColorThemeVars } from '../utils/colorThemes';
export const Button = {
    baseStyle() {
        return {
            '& > svg': {
                fontSize: '24px',
            },
        };
    },
    variants: {
        primary() {
            const disabledStyles = {
                bg: 'bg.disabled',
                opacity: 1,
                '&[data-loading]': {
                    bg: 'bg.button',
                },
            };
            return {
                bg: 'bg.button',
                opacity: 1,
                color: 'white',
                rounded: '999px',
                _hover: {
                    bg: 'bg.buttonHover',
                    _disabled: {
                        ...disabledStyles,
                    },
                },
                _disabled: {
                    ...disabledStyles,
                },
            };
        },
        pink() {
            const disabledStyles = {
                bg: 'bg.disabled',
                opacity: 1,
            };
            return {
                bg: 'pink.500',
                opacity: 1,
                color: 'white',
                rounded: '999px',
                _hover: {
                    bg: 'bg.buttonHover',
                    _disabled: {
                        ...disabledStyles,
                    },
                },
                _disabled: {
                    ...disabledStyles,
                },
            };
        },
        secondary() {
            return {
                opacity: 1,
                bg: 'transparent',
                color: 'text-active',
                rounded: '999px',
                border: '1px solid',
                borderColor: 'currentColor',
                _hover: {
                    ':not(:disabled)': {
                        bg: 'bg.buttonHover',
                        borderColor: 'bg.button',
                        color: 'white',
                    },
                },
                _disabled: {
                    bg: 'transparent',
                    color: 'text.disabled',
                    opacity: 1,
                    '&[data-loading]': {
                        bg: 'transparent',
                        color: 'text-active',
                    },
                },
            };
        },
        appMenu() {
            return {
                bg: 'bg.default',
                opacity: 1,
                color: 'text.active',
                shadow: 'card.normal',
                rounded: '999px',
                textTransform: 'uppercase',
                _hover: {
                    shadow: 'card.active',
                    _disabled: {
                        bg: 'bg.disabled',
                    },
                },
                _disabled: {
                    bg: 'bg.disabled',
                    opacity: 1,
                },
            };
        },
        link() {
            return {
                ...textStyles.textLink,
                color: 'text-active',
                _hover: {
                    color: 'text-inactive',
                    _disabled: {
                        color: 'text-disabled',
                    },
                    textDecoration: 'none',
                    '&[target=_blank]': {
                        textDecoration: 'underline',
                        textDecorationThickness: '2px',
                    },
                },
                _disabled: {
                    color: 'text-disabled',
                },
            };
        },
        raised(props) {
            return {
                display: 'flex',
                px: props.size === 'xs' ? '0px' : '8px',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'text-active',
                boxShadow: `var(${ColorThemeVars.parentShadow})`,
                _active: typeof props['isActive'] === 'undefined' || props['isActive']
                    ? {
                        bgColor: `var(${ColorThemeVars.mainColorElevate})`,
                        boxShadow: `var(${ColorThemeVars.shadowActive})`,
                        _disabled: {
                            boxShadow: 'unset',
                        },
                    }
                    : {
                        bgColor: `var(${ColorThemeVars.mainColorElevate})`,
                    },
                bgGradient: 'linear(to-br, bg.card 14.99%, #D6E0E7 84.37%)',
                rounded: '5px',
                bgColor: `var(${ColorThemeVars.mainColorElevate})`,
                _hover: {
                    bgColor: `var(${ColorThemeVars.mainColorElevate})`,
                    opacity: 0.8,
                },
                _disabled: {
                    boxShadow: 'unset',
                    bg: 'transparent',
                },
            };
        },
        raisedActive(props) {
            return {
                ...props.theme?.components?.Button?.variants?.raised?.(props),
                boxShadow: `var(${ColorThemeVars.shadowActive})`,
            };
        },
        card(props) {
            return {
                ...props.theme?.components?.Button?.variants?.raised?.(props),
                bgGradient: 'unset',
            };
        },
        navCard(props) {
            return {
                ...props.theme?.components?.Button?.variants?.raised?.(props),
                bgGradient: 'unset',
                rounded: '17px',
            };
        },
        navCardPrimary(props) {
            return {
                ...props.theme?.components?.Button?.variants?.navCard?.(props),
                color: 'white',
                bgColor: 'unset',
                bgGradient: 'linear(135.52deg, #5E4C8A 14.99%, #1D0063 84.37%)',
            };
        },
        smallCard(props) {
            return merge(props.theme?.components?.Button?.variants?.raised?.(props), {
                bgGradient: 'unset',
                boxShadow: `var(${ColorThemeVars.parentSmallShadow})`,
                _active: {
                    boxShadow: `var(${ColorThemeVars.smallShadowActive})`,
                },
            });
        },
        vvs() {
            return {
                bg: 'vvs.primary',
                opacity: 1,
                color: 'white',
                rounded: '50px',
                fontFamily: 'Soleil',
                _hover: {
                    _disabled: {
                        bg: 'vvs.primary',
                    },
                },
            };
        },
        vvsLight(props) {
            return merge(props.theme?.components?.Button?.variants?.vvs?.(props), {
                bg: 'vvs.primaryLight',
                color: 'vvs.darkBlue',
                fontWeight: 'bold',
            });
        },
        vvsLg(props) {
            return merge(props.theme?.components?.Button?.variants?.vvs?.(props), {
                h: '60px',
                rounded: '12px',
            });
        },
        ghost: {
            _hover: {
                bg: 'blackAlpha.100',
                _dark: {
                    bg: 'whiteAlpha.300',
                },
            },
            _active: {
                bg: 'blackAlpha.200',
                _dark: {
                    bg: 'whiteAlpha.400',
                },
            },
        },
    },
    sizes: {
        lg: {
            ...textStyles.buttonLink,
            h: '44px',
        },
        md: {
            ...textStyles.buttonLink,
            h: '40px',
            minW: '40px',
            px: '24px',
        },
        cta: {
            ...textStyles.buttonLink,
            h: '40px',
            mx: 'auto',
            w: { base: 'full', desktop: '343px' },
            px: '24px',
        },
        sm: {
            ...textStyles.buttonLink,
            h: '30px',
            minW: '30px',
            px: '16px',
        },
        xs: {
            ...textStyles.buttonLink,
            h: '24px',
            minW: '24px',
            p: '0px',
        },
    },
    defaultProps: {
        size: 'md',
        variant: 'primary',
    },
};
