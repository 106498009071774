import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import { create } from 'zustand';

export type UseLangModalStore = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const useLangModal = create(
  resetWhenRouterChange<UseLangModalStore>((set) => ({
    isOpen: false,
    onOpen: () => {
      set({
        isOpen: true,
      });
    },
    onClose: () => {
      useLangModal.getState().$$resetData?.();
    },
  })),
);
