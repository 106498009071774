import { BigNumber } from '@ethersproject/bignumber';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEntryPoint } from '@ui/components/EntryPointProvider/EntryPointProvider';
import { getContractAddresses } from '@ui/config/base';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { QueryKey } from '@ui/queries/queryKey';
import bigNumberToNumber from '@ui/utils/bigNumberToNumber';
import type { GardenType } from '@ui/utils/garden';
import { getPidType } from '@ui/utils/garden';
import { trackEvent } from '@ui/utils/tracking';
import type { VenoSDK } from '@veno-app/sdk';
import { currentWallet } from '@veno-app/wallet';
import { useCallback, useMemo } from 'react';

import type { CoinMode } from './useCoinMode';
import { useCoinMode } from './useCoinMode';

const { useAccount } = currentWallet;

const usePlantAction = (mode: 'DEPOSIT' | 'WITHDRAW') => {
  const coinMode = useCoinMode();
  const sdk = useVenoSDK();
  const userAccount = useAccount();
  const entryPoint = useEntryPoint();

  const plantAction = useMemo(
    () =>
      mode === 'DEPOSIT'
        ? (
            gardenType: GardenType,
            amount: BigNumber,
            contractAddress: string,
            _coinMode?: CoinMode,
          ) => {
            return sdk.VenoStorm.deposit(
              getPidType(_coinMode ?? coinMode, gardenType),
              amount,
              contractAddress,
            );
          }
        : (
            gardenType: GardenType,
            amount: BigNumber,
            contractAddress: string,
          ) =>
            sdk.VenoStorm.withdraw(
              getPidType(coinMode, gardenType),
              amount,
              contractAddress,
            ),
    [coinMode, mode, sdk.VenoStorm],
  );

  const queryClient = useQueryClient();
  const doPlantAction = useCallback(
    async (
      amount: BigNumber,
      gardenType: GardenType,
      vaultType: 'RESERVOIR' | 'FOUNTAIN',
      _coinMode?: CoinMode,
    ) => {
      const addresses = getContractAddresses(sdk.chainType);

      const data = await plantAction(
        gardenType,
        amount,
        vaultType === 'RESERVOIR'
          ? addresses.venoReservoir
          : addresses.venoFountain,
        _coinMode ?? coinMode,
      );
      return await data.txReceiptPromise;
    },
    [coinMode, plantAction, sdk.chainType],
  );

  const plantInGarden = ({
    amount,
    gardenType,
    vaultType,
    _coinMode,
  }: {
    amount: BigNumber;
    gardenType: GardenType;
    vaultType: 'RESERVOIR' | 'FOUNTAIN';
    _coinMode?: CoinMode;
  }) => doPlantAction(amount, gardenType, vaultType, _coinMode);

  return useMutation(plantInGarden, {
    onSuccess: async (data, vars) => {
      queryClient.invalidateQueries([QueryKey.LP_AMOUNT_STAKED]);
      queryClient.invalidateQueries([QueryKey.PENDING_VNO]);
      if (vars.gardenType === 'VVS') {
        queryClient.invalidateQueries([QueryKey.VVS_LP_BALANCE]);
      } else if (vars.gardenType === 'TECTONIC') {
        queryClient.invalidateQueries([QueryKey.TLCRO_BALANCE]);
      } else if (vars.gardenType === 'FERRO') {
        queryClient.invalidateQueries([QueryKey.FERRO_LP_BALANCE]);
      } else if (vars.gardenType === 'VVS_CRO') {
        queryClient.invalidateQueries([QueryKey.VVS_CRO_LP_BALANCE]);
      } else if (vars.gardenType === 'VVS_TIA') {
        queryClient.invalidateQueries(['vvs-tia-lp-balance']);
      }

      // only track if amount is positive, else on a ui level it's a harvest action
      if (mode === 'DEPOSIT' && vars.amount.gt(0)) {
        trackGardenDepositEvent(sdk, userAccount ?? '', vars, entryPoint);
      }
    },
  });
};

async function trackGardenDepositEvent(
  sdk: VenoSDK,
  userAccount: string,
  vars: { amount: BigNumber; gardenType: GardenType },
  entryPoint: string,
) {
  let remainingBalance = BigNumber.from(0);
  let token = 'tLCRO';
  if (vars.gardenType === 'TECTONIC') {
    remainingBalance = await sdk.TLcro.balanceOf(userAccount ?? '');
  } else if (vars.gardenType === 'FERRO') {
    remainingBalance = await sdk.FerroLpToken.balanceOf(userAccount ?? '');
    token = 'LCRO-CRO-LP';
  } else if (vars.gardenType === 'VVS') {
    remainingBalance = await sdk.VvsLpToken.balanceOf(userAccount ?? '');
    token = 'VVS-VNO-LP';
  } else if (vars.gardenType === 'VVS_CRO') {
    remainingBalance = await sdk.VvsCroLpToken.balanceOf(userAccount ?? '');
    token = 'LCRO-CRO-LP';
  } else if (vars.gardenType === 'VVS_TIA') {
    remainingBalance = await sdk.VvsTiaLpToken.balanceOf(userAccount ?? '');
    token = 'LTIA-TIA-LP';
  }

  trackEvent('Deposit Garden Confirmed', {
    isTransaction: true,
    Amount: bigNumberToNumber(
      vars.amount,
      vars.gardenType === 'TECTONIC' ? 8 : 18,
    ),
    'Remaining Balance': bigNumberToNumber(remainingBalance),
    Garden: vars.gardenType.toLowerCase(),
    Token: token,
    'Entry Point': entryPoint,
  });
}

export const useGardenDeposit = () => {
  return usePlantAction('DEPOSIT');
};

export const useGardenWithdraw = () => {
  return usePlantAction('WITHDRAW');
};
