import { BraveWallet } from '@web3-wallet/brave-wallet';
import { CoinbaseWallet } from '@web3-wallet/coinbase-wallet';
import { CryptocomDesktopWallet } from '@web3-wallet/cryptocom-desktop-wallet';
import { DeFiWallet } from '@web3-wallet/defiwallet';
import { MetaMask } from '@web3-wallet/metamask';
import { createCurrentWallet, createWallet } from '@web3-wallet/react';
import { TrustWallet } from '@web3-wallet/trust-wallet';
import { WalletConnect } from '@web3-wallet/walletconnect';
import { CRONOS_CHAIN_ID, SUPPORTED_CHAIN_IDS, ZKSYNC_CHAIN_ID, } from './chainConfig';
import { getWalletConfig } from './walletConfigs';
export const metaMask = createWallet(getWalletConfig(MetaMask.walletName).connector);
export const defiWallet = createWallet(getWalletConfig(DeFiWallet.walletName).connector);
export const braveWallet = createWallet(getWalletConfig(BraveWallet.walletName).connector);
export const walletConnect = createWallet(getWalletConfig(WalletConnect.walletName).connector);
export const desktopWallet = createWallet(getWalletConfig(CryptocomDesktopWallet.walletName).connector);
export const trustWallet = createWallet(getWalletConfig(TrustWallet.walletName).connector);
export const coinbaseWallet = createWallet(getWalletConfig(CoinbaseWallet.walletName).connector);
const walletMap = {
    [metaMask.getWalletName()]: metaMask,
    [defiWallet.getWalletName()]: defiWallet,
    [braveWallet.getWalletName()]: braveWallet,
    [walletConnect.getWalletName()]: walletConnect,
    [desktopWallet.getWalletName()]: desktopWallet,
    [trustWallet.getWalletName()]: trustWallet,
    [coinbaseWallet.getWalletName()]: coinbaseWallet,
};
export const getWallet = (name) => {
    const wallet = walletMap[name];
    if (!wallet)
        throw new Error(`unknown wallet ${name}`);
    return wallet;
};
const _currentWallet = createCurrentWallet([
    metaMask,
    defiWallet,
    braveWallet,
    walletConnect,
    desktopWallet,
    trustWallet,
    coinbaseWallet,
], {
    /**
     * if the users enter the page for the first time in DeFiWallet App,
     * at which point the `connector.autoConnect()` will be called,
     * we choose the DeFiWallet connector as default connector to connect,
     * aiming to show the DefiWallet icon to the user by default
     */
    defaultCurrentWallet: typeof window !== 'undefined' &&
        window.navigator?.userAgent?.includes('DeFiWallet')
        ? DeFiWallet.walletName
        : undefined,
});
const { useWalletName, useChainId, useIsConnected } = _currentWallet;
const useWalletConfig = () => {
    return getWalletConfig(useWalletName());
};
const useIsConnectedToSupportedChain = () => {
    const chainId = useChainId();
    const isConnected = useIsConnected();
    return isConnected && SUPPORTED_CHAIN_IDS.includes(chainId);
};
const useSupportedChainId = () => {
    const chainId = useChainId();
    return chainId && SUPPORTED_CHAIN_IDS.includes(chainId) ? chainId : undefined;
};
const useIsZksyncChainId = () => {
    const chainId = useChainId();
    return !!chainId && ZKSYNC_CHAIN_ID === chainId;
};
const useIsCronosChainId = () => {
    const chainId = useChainId();
    return !!chainId && CRONOS_CHAIN_ID === chainId;
};
const useIsWrongNetwork = () => {
    const chainId = useChainId();
    const isConnected = useIsConnected();
    return isConnected && !SUPPORTED_CHAIN_IDS.includes(chainId);
};
const useIsReadOnly = () => {
    return !useIsConnectedToSupportedChain();
};
export const currentWallet = {
    ..._currentWallet,
    useWalletConfig,
    useIsConnectedToSupportedChain,
    useSupportedChainId,
    useIsReadOnly,
    useIsWrongNetwork,
    useIsZksyncChainId,
    useIsCronosChainId,
};
