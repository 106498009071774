import { useToken } from '@chakra-ui/react';
import type { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import { useTranslations } from '@ui/i18n';
import type { ColorTheme } from '@veno-app/chakra';
import { format } from 'date-fns';
import numbro from 'numbro';

import type { SimpleLineChartProps } from './SimpleLineChart';
import { SimpleLineChart } from './SimpleLineChart';

export type ClaimedVnoSimpleLineChartProps = Partial<SimpleLineChartProps> & {
  colorTheme?: ColorTheme;
  dataList: {
    claimedVno: BigNumber;
    date: number;
  }[];
  legendLabel?: string;
};
export const ClaimedVnoSimpleLineChart = ({
  colorTheme = 'default',
  dataList,
  legendLabel,
  ...props
}: ClaimedVnoSimpleLineChartProps) => {
  const t = useTranslations();
  legendLabel =
    legendLabel ??
    t('{coin} claimed', {
      coin: 'VNO',
    });
  const vnoLineColor = useToken('colors', 'pink-500');
  const gardenXAxisLineColor = useToken('colors', 'garden-dark');

  return (
    <SimpleLineChart
      height={100}
      xAxisLineColor={
        colorTheme === 'garden'
          ? gardenXAxisLineColor
          : colorTheme === 'atom'
          ? 'rgba(102, 211, 255, 0.3)'
          : undefined
      }
      {...props}
      series={[
        {
          data: dataList.map(({ claimedVno, date }) => {
            return {
              y: Number(formatUnits(claimedVno, 18)) || 0,
              name: format(date, 'yyyy - MM - dd'),
            };
          }),
          color: vnoLineColor,
          label: legendLabel,
        },
      ]}
      tooltipFormatter={({ y }) => {
        return `${numbro(y).format({
          thousandSeparated: true,
          mantissa: 4,
        })} VNO`;
      }}
    />
  );
};
