import {
  Alert,
  AlertDescription,
  Box,
  Fade,
  Flex,
  Icon,
  Image,
  Text,
  useClipboard,
} from '@chakra-ui/react';
import { useCroId } from '@ui/hooks/useCroId';
import { Copy } from '@veno-app/icons';
import { currentWallet } from '@veno-app/wallet';

import { Card } from '../Card';

const { useAccount, useWalletConfig } = currentWallet;

export const AccountCopyButton = () => {
  const account = useAccount();
  const { data: croId } = useCroId(account ?? '');
  const { hasCopied, onCopy } = useClipboard(croId ?? (account || ''));
  const { icon: walletIcon } = useWalletConfig() || {};

  return (
    <Box position="relative" cursor="pointer">
      <Card
        as={Flex}
        variant="button"
        p="8px 10px"
        mx="0"
        alignItems="center"
        justifyContent="space-between"
        gap="10px"
        onClick={onCopy}
      >
        <Image src={walletIcon} width="auto" height="24px" alt="wallet icon" />
        <Text flexShrink="1" noOfLines={1} textStyle="body">
          {croId ?? account}
        </Text>
        <Icon as={Copy} color="bg-button" width="14px" height="17px" />
      </Card>
      <Fade in={hasCopied} unmountOnExit>
        <Alert status="success" position="absolute">
          <AlertDescription>Wallet address copied</AlertDescription>
        </Alert>
      </Fade>
    </Box>
  );
};
