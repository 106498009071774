import { pathDict } from '@ui/config/paths';
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';

import type { Section } from './const';
import { FooterAction } from './const';

type FooterArticlesContextType = Section;

const FooterArticlesContext = createContext<FooterArticlesContextType>(
  {} as Section,
);

interface FooterArticlesProviderProps {
  children: ReactNode;
  articles?: { slug: string; title: string }[];
}

export const FooterArticlesProvider: React.FC<FooterArticlesProviderProps> = ({
  children,
  articles,
}) => {
  const section: Section = useMemo(
    () => ({
      title: 'Learn',
      entries: [
        ...(articles?.map((article) => ({
          title: article.title,
          isExternal: false,
          link: `/crypto-101/${article.slug}`,
        })) ?? []),
        ...staticLearnArticles,
      ],
    }),
    [articles],
  );

  return (
    <FooterArticlesContext.Provider value={section}>
      {children}
    </FooterArticlesContext.Provider>
  );
};

const staticLearnArticles: Section['entries'] = [
  {
    title: ['How to buy CRO?', {}],
    link: 'https://docs.veno.finance/veno-finance/user-guide/how-to-buy-cro-with-fiat',
    isExternal: true,
  },
  {
    title: ['How to stake {coin}?', { coin: 'CRO' }],
    link: 'https://docs.veno.finance/veno-finance/user-guide/liquid-staking/how-to-stake',
    isExternal: true,
  },
  {
    title: ['How to stake {coin}?', { coin: 'ATOM' }],
    link: 'https://docs.veno.finance/veno-finance/protocol/liquid-atom-staking',
    isExternal: true,
  },
  {
    title: ['How to stake {coin}?', { coin: 'ETH' }],
    link: 'https://docs.veno.finance/veno-finance/protocol/liquid-eth-staking',
    isExternal: true,
  },
  {
    title: ['How to earn VNO?', {}],
    link: pathDict.cro.vno,
    isExternal: false,
  },
  {
    title: ['What is Veno Garden?', {}],
    link: 'https://docs.veno.finance/veno-finance/protocol/veno-garden',
    isExternal: true,
  },
  {
    title: ['What is Veno Fountain?', {}],
    link: 'https://docs.veno.finance/veno-finance/protocol/fountain',
    isExternal: true,
  },
  {
    title: ['How to use {coin}?', { coin: 'LCRO' }],
    link: pathDict.cro.useLiquid,
    isExternal: true,
  },
  {
    title: ['How to use {coin}?', { coin: 'LATOM' }],
    link: pathDict.atom.useLiquid,
    isExternal: true,
  },
  {
    title: ['How to use {coin}?', { coin: 'LETH' }],
    link: pathDict.eth.useLiquid,
    isExternal: true,
  },
  {
    title: ['How to use {coin}?', { coin: 'VNO' }],
    link: FooterAction.OpenUseVnoModal,
    isExternal: true,
  },
];

export const useFooterArticlesSection = (): FooterArticlesContextType =>
  useContext(FooterArticlesContext);
