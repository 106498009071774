import { getContractAddresses } from '@ui/config/base';
import { currentWallet, metaMask } from '@veno-app/wallet';
import { useCallback } from 'react';

type WatchAsset = {
  address: string;
  decimals: number;
  symbol: string;
  image: string;
};

export const venoWatchAsset: WatchAsset = {
  address: getContractAddresses().venoToken,
  decimals: 18,
  symbol: 'VNO',
  image: '',
};

export const wcroWatchAsset: WatchAsset = {
  address: getContractAddresses().wcro,
  decimals: 18,
  symbol: 'WCRO',
  image: '',
};

export const lcroWatchAsset: WatchAsset = {
  address: getContractAddresses().liquidCro,
  decimals: 18,
  symbol: 'LCRO',
  image: '',
};

export const tlcroWatchAsset: WatchAsset = {
  address: getContractAddresses().tLcro,
  decimals: 18,
  symbol: 'tLCRO',
  image: '',
};

export const ferroLpWatchAsset: WatchAsset = {
  address: getContractAddresses().ferroLp,
  decimals: 18,
  symbol: 'CRO-LCRO LP',
  image: '',
};

export const vvsCroLcroLpWatchAsset: WatchAsset = {
  address: getContractAddresses().vvsCroPair,
  decimals: 18,
  symbol: 'CRO-LCRO LP',
  image: '',
};

export const vvsTiaLtiaLpWatchAsset: WatchAsset = {
  address: getContractAddresses().vvsTiaPair,
  decimals: 18,
  symbol: 'TIA-LTIA LP',
  image: '',
};

export const atomWatchAsset: WatchAsset = {
  address: getContractAddresses().atom,
  decimals: 6,
  symbol: 'ATOM',
  image: '',
};

export const latomWatchAsset: WatchAsset = {
  address: getContractAddresses().liquidAtom,
  decimals: 6,
  symbol: 'LATOM',
  image: '',
};

export const lethWatchAsset: WatchAsset = {
  address: getContractAddresses('zksync').liquidEth,
  decimals: 18,
  symbol: 'LETH',
  image: '',
};

export const tiaWatchAsset: WatchAsset = {
  address: getContractAddresses().tia,
  decimals: 6,
  symbol: 'TIA',
  image: '',
};

export const ltiaWatchAsset: WatchAsset = {
  address: getContractAddresses().liquidTia,
  decimals: 6,
  symbol: 'LTIA',
  image: '',
};

export const useWatchAsset = () => {
  const {
    useSupportedChainId,
    useWalletConfig,
    watchAsset: watchAsset_,
    useHasProvider,
  } = currentWallet;
  const chainId = useSupportedChainId();
  const hasProvider = useHasProvider;

  const canWatch =
    useWalletConfig()?.name === metaMask.getWalletName() && hasProvider;

  const watchAsset = useCallback(
    (asset: WatchAsset) => {
      /**
       * Only metamask support watch asset
       */
      if (!canWatch || !chainId) return;
      if (asset.symbol === 'VNO') {
        asset.image =
          (window ? window.location.origin : '') + '/tokens/vno-big.png';
      } else if (asset.symbol === 'LCRO') {
        asset.image =
          (window ? window.location.origin : '') + '/tokens/lcro-big.png';
      }

      return watchAsset_(asset);
    },
    [watchAsset_, canWatch, chainId],
  );

  return {
    canWatch,
    watchAsset,
  };
};
