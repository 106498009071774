import { useRouter } from '@ui/overrides/next/router';
import { currentWallet } from '@veno-app/wallet';
import posthog from 'posthog-js';
import { useEffect } from 'react';
import { useEffectOnce } from 'react-use';

const isProductionEnv = process.env.NEXT_PUBLIC_RELEASE_STAGE === 'production';

export const usePosthog = () => {
  const router = useRouter();
  const account = currentWallet.useAccount();
  const shouldInitPosthog =
    typeof window !== 'undefined' && isProductionEnv && Boolean(account);

  useEffectOnce(() => {
    // Track page views
    const handleRouteChange = () => {
      posthog?.capture('$pageview');
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  });

  useEffect(() => {
    if (shouldInitPosthog && !posthog.__loaded) {
      posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
        api_host: 'https://app.posthog.com',
        loaded: (posthog) => {
          if (!isProductionEnv) {
            posthog.debug();
          }
        },
      });
      posthog.register({
        env: process.env.NEXT_PUBLIC_RELEASE_STAGE,
      });
    }
  }, [shouldInitPosthog]);

  useEffect(() => {
    if (account && shouldInitPosthog) {
      posthog.identify(account);
    }
  }, [account, shouldInitPosthog]);

  return posthog;
};
