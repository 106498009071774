import { QueryKey } from './queryKey';

const MINTED_URL = process.env.NEXT_PUBLIC_MINTED_URL || '';

export const getMintedTotalVolume = () => ({
  queryKey: [QueryKey.MINTED_TOTAL_VOLUME],
  queryFn: async () => {
    const endpoint = MINTED_URL;
    let fetchResponse: Response | null = null;
    if (!MINTED_URL) throw new Error('minted endpoint config missing');
    fetchResponse = await fetch(endpoint);
    const response = (await fetchResponse.json()) as number;
    try {
      return response;
    } catch (err) {
      throw new Error(`invalid MINTED_TOTAL_VOLUME response from ${endpoint}`);
    }
  },
});
