import { IERC20__factory } from '@contracts';
import { BigNumber } from 'ethers';
import { VenoSDKModule } from './base';
import { ATOM_DECIMALS, expandDecimals, LATOM_DECIMALS, USD_DECIMALS, } from './constants';
import FerroSwapModule from './ferroSwap';
import LiquidAtomModule from './liquidAtom';
import { getAtomUsdPrice, getLiquidTokenUsdPrice, wrapSdkTransaction, } from './util';
export default class FerroLpTokenAtomModule extends VenoSDKModule {
    constructor() {
        super(...arguments);
        this.contractFactory = IERC20__factory;
        this.contractAddress = this.sdk.addresses.ferroLpAtom;
        this.FerroSwap = new FerroSwapModule(this.sdk, this.sdk.addresses.ferroSwapAtom, LATOM_DECIMALS);
        this.LiquidAtom = new LiquidAtomModule(this.sdk);
    }
    async balanceOf(address) {
        return await this.getContract().balanceOf(address);
    }
    async allowance(owner, operator) {
        return await this.getContract().allowance(owner, operator);
    }
    async approve(operator, amount) {
        return await wrapSdkTransaction(this.getContract().approve(operator, amount));
    }
    async getLpUsdWorth(amount) {
        if (amount.lte(0))
            return BigNumber.from(0);
        const [latomAmount, atomAmount] = await this.FerroSwap.calculateRemoveLiquidity(amount);
        const [atomUsdPrice, atomPerLatom] = await Promise.all([
            getAtomUsdPrice(),
            this.LiquidAtom.atomPerLatom(),
        ]);
        if (!atomUsdPrice || !atomPerLatom)
            return BigNumber.from(0);
        const lcroUsdPrice = getLiquidTokenUsdPrice(atomUsdPrice, atomPerLatom);
        const factor = 100000000000;
        const result = latomAmount
            .mul(expandDecimals(USD_DECIMALS - LATOM_DECIMALS))
            .mul(Math.round(lcroUsdPrice * factor))
            .add(atomAmount
            .mul(expandDecimals(USD_DECIMALS - ATOM_DECIMALS))
            .mul(Math.round(atomUsdPrice * factor)))
            .div(factor);
        return result;
    }
}
