export var UnbondingStatus;
(function (UnbondingStatus) {
    UnbondingStatus[UnbondingStatus["PENDING_BOT"] = 0] = "PENDING_BOT";
    UnbondingStatus[UnbondingStatus["PROCESSING"] = 1] = "PROCESSING";
    UnbondingStatus[UnbondingStatus["UNBONDING"] = 2] = "UNBONDING";
    UnbondingStatus[UnbondingStatus["UNBONDED"] = 3] = "UNBONDED";
})(UnbondingStatus || (UnbondingStatus = {}));
export var TokenError;
(function (TokenError) {
    TokenError[TokenError["NO_ERROR"] = 0] = "NO_ERROR";
    TokenError[TokenError["UNAUTHORIZED"] = 1] = "UNAUTHORIZED";
    TokenError[TokenError["BAD_INPUT"] = 2] = "BAD_INPUT";
    TokenError[TokenError["TECTONIC_CORE_REJECTION"] = 3] = "TECTONIC_CORE_REJECTION";
    TokenError[TokenError["TECTONIC_CORE_CALCULATION_ERROR"] = 4] = "TECTONIC_CORE_CALCULATION_ERROR";
    TokenError[TokenError["INTEREST_RATE_MODEL_ERROR"] = 5] = "INTEREST_RATE_MODEL_ERROR";
    TokenError[TokenError["INVALID_ACCOUNT_PAIR"] = 6] = "INVALID_ACCOUNT_PAIR";
    TokenError[TokenError["INVALID_CLOSE_AMOUNT_REQUESTED"] = 7] = "INVALID_CLOSE_AMOUNT_REQUESTED";
    TokenError[TokenError["INVALID_COLLATERAL_FACTOR"] = 8] = "INVALID_COLLATERAL_FACTOR";
    TokenError[TokenError["MATH_ERROR"] = 9] = "MATH_ERROR";
    TokenError[TokenError["MARKET_NOT_FRESH"] = 10] = "MARKET_NOT_FRESH";
    TokenError[TokenError["MARKET_NOT_LISTED"] = 11] = "MARKET_NOT_LISTED";
    TokenError[TokenError["TOKEN_INSUFFICIENT_ALLOWANCE"] = 12] = "TOKEN_INSUFFICIENT_ALLOWANCE";
    TokenError[TokenError["TOKEN_INSUFFICIENT_BALANCE"] = 13] = "TOKEN_INSUFFICIENT_BALANCE";
    TokenError[TokenError["TOKEN_INSUFFICIENT_CASH"] = 14] = "TOKEN_INSUFFICIENT_CASH";
    TokenError[TokenError["TOKEN_TRANSFER_IN_FAILED"] = 15] = "TOKEN_TRANSFER_IN_FAILED";
    TokenError[TokenError["TOKEN_TRANSFER_OUT_FAILED"] = 16] = "TOKEN_TRANSFER_OUT_FAILED";
})(TokenError || (TokenError = {}));
export var MathError;
(function (MathError) {
    MathError[MathError["NO_ERROR"] = 0] = "NO_ERROR";
    MathError[MathError["DIVISION_BY_ZERO"] = 1] = "DIVISION_BY_ZERO";
    MathError[MathError["INTEGER_OVERFLOW"] = 2] = "INTEGER_OVERFLOW";
    MathError[MathError["INTEGER_UNDERFLOW"] = 3] = "INTEGER_UNDERFLOW";
})(MathError || (MathError = {}));
export var TokenFailureInfo;
(function (TokenFailureInfo) {
    TokenFailureInfo[TokenFailureInfo["ACCEPT_ADMIN_PENDING_ADMIN_CHECK"] = 0] = "ACCEPT_ADMIN_PENDING_ADMIN_CHECK";
    TokenFailureInfo[TokenFailureInfo["ACCRUE_INTEREST_ACCUMULATED_INTEREST_CALCULATION_FAILED"] = 1] = "ACCRUE_INTEREST_ACCUMULATED_INTEREST_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["ACCRUE_INTEREST_BORROW_RATE_CALCULATION_FAILED"] = 2] = "ACCRUE_INTEREST_BORROW_RATE_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["ACCRUE_INTEREST_NEW_BORROW_INDEX_CALCULATION_FAILED"] = 3] = "ACCRUE_INTEREST_NEW_BORROW_INDEX_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["ACCRUE_INTEREST_NEW_TOTAL_BORROWS_CALCULATION_FAILED"] = 4] = "ACCRUE_INTEREST_NEW_TOTAL_BORROWS_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["ACCRUE_INTEREST_NEW_TOTAL_RESERVES_CALCULATION_FAILED"] = 5] = "ACCRUE_INTEREST_NEW_TOTAL_RESERVES_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["ACCRUE_INTEREST_SIMPLE_INTEREST_FACTOR_CALCULATION_FAILED"] = 6] = "ACCRUE_INTEREST_SIMPLE_INTEREST_FACTOR_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["BORROW_ACCUMULATED_BALANCE_CALCULATION_FAILED"] = 7] = "BORROW_ACCUMULATED_BALANCE_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["BORROW_ACCRUE_INTEREST_FAILED"] = 8] = "BORROW_ACCRUE_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["BORROW_CASH_NOT_AVAILABLE"] = 9] = "BORROW_CASH_NOT_AVAILABLE";
    TokenFailureInfo[TokenFailureInfo["BORROW_FRESHNESS_CHECK"] = 10] = "BORROW_FRESHNESS_CHECK";
    TokenFailureInfo[TokenFailureInfo["BORROW_NEW_TOTAL_BALANCE_CALCULATION_FAILED"] = 11] = "BORROW_NEW_TOTAL_BALANCE_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["BORROW_NEW_ACCOUNT_BORROW_BALANCE_CALCULATION_FAILED"] = 12] = "BORROW_NEW_ACCOUNT_BORROW_BALANCE_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["BORROW_MARKET_NOT_LISTED"] = 13] = "BORROW_MARKET_NOT_LISTED";
    TokenFailureInfo[TokenFailureInfo["BORROW_TECTONIC_CORE_REJECTION"] = 14] = "BORROW_TECTONIC_CORE_REJECTION";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_ACCRUE_BORROW_INTEREST_FAILED"] = 15] = "LIQUIDATE_ACCRUE_BORROW_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_ACCRUE_COLLATERAL_INTEREST_FAILED"] = 16] = "LIQUIDATE_ACCRUE_COLLATERAL_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_COLLATERAL_FRESHNESS_CHECK"] = 17] = "LIQUIDATE_COLLATERAL_FRESHNESS_CHECK";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_TECTONIC_CORE_REJECTION"] = 18] = "LIQUIDATE_TECTONIC_CORE_REJECTION";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_TECTONIC_CORE_CALCULATE_AMOUNT_SEIZE_FAILED"] = 19] = "LIQUIDATE_TECTONIC_CORE_CALCULATE_AMOUNT_SEIZE_FAILED";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_CLOSE_AMOUNT_IS_UINT_MAX"] = 20] = "LIQUIDATE_CLOSE_AMOUNT_IS_UINT_MAX";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_CLOSE_AMOUNT_IS_ZERO"] = 21] = "LIQUIDATE_CLOSE_AMOUNT_IS_ZERO";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_FRESHNESS_CHECK"] = 22] = "LIQUIDATE_FRESHNESS_CHECK";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_LIQUIDATOR_IS_BORROWER"] = 23] = "LIQUIDATE_LIQUIDATOR_IS_BORROWER";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_REPAY_BORROW_FRESH_FAILED"] = 24] = "LIQUIDATE_REPAY_BORROW_FRESH_FAILED";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_SEIZE_BALANCE_INCREMENT_FAILED"] = 25] = "LIQUIDATE_SEIZE_BALANCE_INCREMENT_FAILED";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_SEIZE_BALANCE_DECREMENT_FAILED"] = 26] = "LIQUIDATE_SEIZE_BALANCE_DECREMENT_FAILED";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_SEIZE_TECTONIC_CORE_REJECTION"] = 27] = "LIQUIDATE_SEIZE_TECTONIC_CORE_REJECTION";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_SEIZE_LIQUIDATOR_IS_BORROWER"] = 28] = "LIQUIDATE_SEIZE_LIQUIDATOR_IS_BORROWER";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_SEIZE_TOO_MUCH"] = 29] = "LIQUIDATE_SEIZE_TOO_MUCH";
    TokenFailureInfo[TokenFailureInfo["MINT_ACCRUE_INTEREST_FAILED"] = 30] = "MINT_ACCRUE_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["MINT_TECTONIC_CORE_REJECTION"] = 31] = "MINT_TECTONIC_CORE_REJECTION";
    TokenFailureInfo[TokenFailureInfo["MINT_EXCHANGE_CALCULATION_FAILED"] = 32] = "MINT_EXCHANGE_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["MINT_EXCHANGE_RATE_READ_FAILED"] = 33] = "MINT_EXCHANGE_RATE_READ_FAILED";
    TokenFailureInfo[TokenFailureInfo["MINT_FRESHNESS_CHECK"] = 34] = "MINT_FRESHNESS_CHECK";
    TokenFailureInfo[TokenFailureInfo["MINT_NEW_ACCOUNT_BALANCE_CALCULATION_FAILED"] = 35] = "MINT_NEW_ACCOUNT_BALANCE_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["MINT_NEW_TOTAL_SUPPLY_CALCULATION_FAILED"] = 36] = "MINT_NEW_TOTAL_SUPPLY_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["MINT_TRANSFER_IN_FAILED"] = 37] = "MINT_TRANSFER_IN_FAILED";
    TokenFailureInfo[TokenFailureInfo["MINT_TRANSFER_IN_NOT_POSSIBLE"] = 38] = "MINT_TRANSFER_IN_NOT_POSSIBLE";
    TokenFailureInfo[TokenFailureInfo["REDEEM_ACCRUE_INTEREST_FAILED"] = 39] = "REDEEM_ACCRUE_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["REDEEM_TECTONIC_CORE_REJECTION"] = 40] = "REDEEM_TECTONIC_CORE_REJECTION";
    TokenFailureInfo[TokenFailureInfo["REDEEM_EXCHANGE_TOKENS_CALCULATION_FAILED"] = 41] = "REDEEM_EXCHANGE_TOKENS_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["REDEEM_EXCHANGE_AMOUNT_CALCULATION_FAILED"] = 42] = "REDEEM_EXCHANGE_AMOUNT_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["REDEEM_EXCHANGE_RATE_READ_FAILED"] = 43] = "REDEEM_EXCHANGE_RATE_READ_FAILED";
    TokenFailureInfo[TokenFailureInfo["REDEEM_FRESHNESS_CHECK"] = 44] = "REDEEM_FRESHNESS_CHECK";
    TokenFailureInfo[TokenFailureInfo["REDEEM_NEW_ACCOUNT_BALANCE_CALCULATION_FAILED"] = 45] = "REDEEM_NEW_ACCOUNT_BALANCE_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["REDEEM_NEW_TOTAL_SUPPLY_CALCULATION_FAILED"] = 46] = "REDEEM_NEW_TOTAL_SUPPLY_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["REDEEM_TRANSFER_OUT_NOT_POSSIBLE"] = 47] = "REDEEM_TRANSFER_OUT_NOT_POSSIBLE";
    TokenFailureInfo[TokenFailureInfo["REDUCE_RESERVES_ACCRUE_INTEREST_FAILED"] = 48] = "REDUCE_RESERVES_ACCRUE_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["REDUCE_RESERVES_ADMIN_CHECK"] = 49] = "REDUCE_RESERVES_ADMIN_CHECK";
    TokenFailureInfo[TokenFailureInfo["REDUCE_RESERVES_CASH_NOT_AVAILABLE"] = 50] = "REDUCE_RESERVES_CASH_NOT_AVAILABLE";
    TokenFailureInfo[TokenFailureInfo["REDUCE_RESERVES_FRESH_CHECK"] = 51] = "REDUCE_RESERVES_FRESH_CHECK";
    TokenFailureInfo[TokenFailureInfo["REDUCE_RESERVES_VALIDATION"] = 52] = "REDUCE_RESERVES_VALIDATION";
    TokenFailureInfo[TokenFailureInfo["REPAY_BEHALF_ACCRUE_INTEREST_FAILED"] = 53] = "REPAY_BEHALF_ACCRUE_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["REPAY_BORROW_ACCRUE_INTEREST_FAILED"] = 54] = "REPAY_BORROW_ACCRUE_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["REPAY_BORROW_ACCUMULATED_BALANCE_CALCULATION_FAILED"] = 55] = "REPAY_BORROW_ACCUMULATED_BALANCE_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["REPAY_BORROW_TECTONIC_CORE_REJECTION"] = 56] = "REPAY_BORROW_TECTONIC_CORE_REJECTION";
    TokenFailureInfo[TokenFailureInfo["REPAY_BORROW_FRESHNESS_CHECK"] = 57] = "REPAY_BORROW_FRESHNESS_CHECK";
    TokenFailureInfo[TokenFailureInfo["REPAY_BORROW_NEW_ACCOUNT_BORROW_BALANCE_CALCULATION_FAILED"] = 58] = "REPAY_BORROW_NEW_ACCOUNT_BORROW_BALANCE_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["REPAY_BORROW_NEW_TOTAL_BALANCE_CALCULATION_FAILED"] = 59] = "REPAY_BORROW_NEW_TOTAL_BALANCE_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["REPAY_BORROW_TRANSFER_IN_NOT_POSSIBLE"] = 60] = "REPAY_BORROW_TRANSFER_IN_NOT_POSSIBLE";
    TokenFailureInfo[TokenFailureInfo["SET_COLLATERAL_FACTOR_OWNER_CHECK"] = 61] = "SET_COLLATERAL_FACTOR_OWNER_CHECK";
    TokenFailureInfo[TokenFailureInfo["SET_COLLATERAL_FACTOR_VALIDATION"] = 62] = "SET_COLLATERAL_FACTOR_VALIDATION";
    TokenFailureInfo[TokenFailureInfo["SET_TECTONIC_CORE_OWNER_CHECK"] = 63] = "SET_TECTONIC_CORE_OWNER_CHECK";
    TokenFailureInfo[TokenFailureInfo["SET_INTEREST_RATE_MODEL_ACCRUE_INTEREST_FAILED"] = 64] = "SET_INTEREST_RATE_MODEL_ACCRUE_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["SET_INTEREST_RATE_MODEL_FRESH_CHECK"] = 65] = "SET_INTEREST_RATE_MODEL_FRESH_CHECK";
    TokenFailureInfo[TokenFailureInfo["SET_INTEREST_RATE_MODEL_OWNER_CHECK"] = 66] = "SET_INTEREST_RATE_MODEL_OWNER_CHECK";
    TokenFailureInfo[TokenFailureInfo["SET_MAX_ASSETS_OWNER_CHECK"] = 67] = "SET_MAX_ASSETS_OWNER_CHECK";
    TokenFailureInfo[TokenFailureInfo["SET_ORACLE_MARKET_NOT_LISTED"] = 68] = "SET_ORACLE_MARKET_NOT_LISTED";
    TokenFailureInfo[TokenFailureInfo["SET_PENDING_ADMIN_OWNER_CHECK"] = 69] = "SET_PENDING_ADMIN_OWNER_CHECK";
    TokenFailureInfo[TokenFailureInfo["SET_RESERVE_FACTOR_ACCRUE_INTEREST_FAILED"] = 70] = "SET_RESERVE_FACTOR_ACCRUE_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["SET_RESERVE_FACTOR_ADMIN_CHECK"] = 71] = "SET_RESERVE_FACTOR_ADMIN_CHECK";
    TokenFailureInfo[TokenFailureInfo["SET_RESERVE_FACTOR_FRESH_CHECK"] = 72] = "SET_RESERVE_FACTOR_FRESH_CHECK";
    TokenFailureInfo[TokenFailureInfo["SET_RESERVE_FACTOR_BOUNDS_CHECK"] = 73] = "SET_RESERVE_FACTOR_BOUNDS_CHECK";
    TokenFailureInfo[TokenFailureInfo["TRANSFER_TECTONIC_CORE_REJECTION"] = 74] = "TRANSFER_TECTONIC_CORE_REJECTION";
    TokenFailureInfo[TokenFailureInfo["TRANSFER_NOT_ALLOWED"] = 75] = "TRANSFER_NOT_ALLOWED";
    TokenFailureInfo[TokenFailureInfo["TRANSFER_NOT_ENOUGH"] = 76] = "TRANSFER_NOT_ENOUGH";
    TokenFailureInfo[TokenFailureInfo["TRANSFER_TOO_MUCH"] = 77] = "TRANSFER_TOO_MUCH";
    TokenFailureInfo[TokenFailureInfo["ADD_RESERVES_ACCRUE_INTEREST_FAILED"] = 78] = "ADD_RESERVES_ACCRUE_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["ADD_RESERVES_FRESH_CHECK"] = 79] = "ADD_RESERVES_FRESH_CHECK";
    TokenFailureInfo[TokenFailureInfo["ADD_RESERVES_TRANSFER_IN_NOT_POSSIBLE"] = 80] = "ADD_RESERVES_TRANSFER_IN_NOT_POSSIBLE";
})(TokenFailureInfo || (TokenFailureInfo = {}));
