import type { DrawerProps } from '@chakra-ui/react';
import { Button, DrawerBody, Flex, Icon, Text } from '@chakra-ui/react';
import { InDesktop } from '@ui/components/MobileOrDesktop';
import { useSwitchNetwork } from '@ui/hooks/useSwitchNetwork';
import { useTranslations } from '@ui/i18n';
import { Error } from '@veno-app/icons';
import type { ChainType } from '@veno-app/sdk';
import { currentWallet, ZKSYNC_ENABLED } from '@veno-app/wallet';

import {
  ModalOrDragDrawerContent,
  ModalOrDrawer,
  ModalOrDrawerCloseButton,
  ModalOrDrawerFooter,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from './ModalOrDrawer';

export const WalletSwitchNetworkDrawer = (
  props: Omit<DrawerProps, 'children'> & { supportedChains?: ChainType[] },
) => {
  const t = useTranslations();
  const { disconnect } = currentWallet;
  const chainTypes =
    props.supportedChains?.filter((sc) => ZKSYNC_ENABLED || sc !== 'zksync') ??
    (ZKSYNC_ENABLED ? ['zksync', 'cronos'] : ['cronos']);

  const { switchNetwork } = useSwitchNetwork(props.onClose);

  const handleDisconnect = async () => {
    await disconnect();
    props.onClose();
  };

  return (
    <ModalOrDrawer placement="bottom" {...props}>
      <ModalOrDrawerOverlay zIndex="modal" />
      <ModalOrDragDrawerContent minH={{ base: '60vh', desktop: '600px' }}>
        <InDesktop>
          <ModalOrDrawerCloseButton />
        </InDesktop>
        <ModalOrDrawerHeader />
        <DrawerBody>
          <WalletSwitchContent />
        </DrawerBody>
        <ModalOrDrawerFooter>
          <Button onClick={() => switchNetwork(false)}>
            {t('Switch to {chain}', { chain: 'Cronos Chain' })}
          </Button>
          {chainTypes.includes('zksync') && (
            <Button onClick={() => switchNetwork(true)}>
              {t('Switch to {chain}', { chain: 'ZkSync Era' })}
            </Button>
          )}
          <Button mt={5} variant="link" onClick={handleDisconnect}>
            {t('Disconnect wallet')}
          </Button>
        </ModalOrDrawerFooter>
      </ModalOrDragDrawerContent>
    </ModalOrDrawer>
  );
};

const WalletSwitchContent = () => {
  const t = useTranslations();
  return (
    <>
      <Flex direction="column" gap="30px" align="center" textAlign="center">
        <Text textStyle="h3" color="text.normal">
          {t('Wrong network')}
        </Text>
        <Icon as={Error} color="error" boxSize="40px" />
        <Text color="text.light">
          {t('Please connect to appropriate network to use the protocol')}
        </Text>
      </Flex>
    </>
  );
};
