import type { QueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { Network } from '@ui/config/base';
import type { AnnouncementMessage } from 'telegram-announcement-components';
import { useReadState } from 'telegram-announcement-components';

import oldAnnouncements from './oldAnnouncements';

export const MAX_ANNOUNCEMENT = 6;

export const useAnnouncementQuery = (
  options?: Partial<QueryOptions<AnnouncementMessage[]>>,
) => {
  return useQuery(
    ['useAnnouncementQuery'],
    async () => {
      const fetchResponse = await fetch(
        process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK === Network.cronos
          ? 'https://api.veno.finance/api/telegram-announcements?image=true'
          : 'https://psta-veno-api.crorc.co/api/telegram-announcements?image=true',
      );
      const response = (await fetchResponse.json()) as AnnouncementMessage[];

      for (const message of response) {
        if (message.image) {
          message.image = 'data:image/png;base64, ' + message.image;
        }
      }

      return [...response, ...oldAnnouncements].slice(0, MAX_ANNOUNCEMENT);
    },
    options,
  );
};

export const useUnreadAnnouncementQuery = () => {
  const announcementsRet = useAnnouncementQuery();
  const [readMap] = useReadState();

  return {
    ...announcementsRet,
    data: announcementsRet.data?.filter((message) => {
      return !readMap[message.message_id];
    }),
  };
};
