import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react';
import { BigNumber } from '@ethersproject/bignumber';
import Balance from '@ui/components/Balance';
import type { ClaimedVnoSimpleLineChartProps } from '@ui/components/ClaimedVnoSimpleLineChart';
import { ClaimedVnoSimpleLineChart } from '@ui/components/ClaimedVnoSimpleLineChart';
import { InDesktop, InMobile } from '@ui/components/MobileOrDesktop';
import { Retry } from '@ui/components/Retry';
import { VaultCard } from '@ui/components/VaultCard';
import {
  useVenoFountainData,
  useVenoReservoirData,
} from '@ui/components/VenoInfoView/useVenoInfoData';
import WithWallet from '@ui/components/Wallet/WithWallet';
import { useFountainPoolInfos } from '@ui/hooks/useFountainPoolInfos';
import { useReservoirPoolInfos } from '@ui/hooks/useReservoirPoolInfos';
import useVenoEarnings from '@ui/hooks/useVenoEarnings';
import useVnoBalance from '@ui/hooks/useVnoBalance';
import { useVnoUsdValue } from '@ui/hooks/useVnoUsdValue';
import { useTranslations } from '@ui/i18n';
import { PRELAUNCH } from '@ui/utils/constants';
import type { Stake } from '@veno-app/sdk';
import { currentWallet } from '@veno-app/wallet';
import React, { useMemo } from 'react';

import { ClaimPendingVnoCard } from './ClaimPendingVnoCard';
import { CollectPenaltyCard } from './CollectPenaltyCard';
import type { ReservoirOrFountainPool, VaultType } from './types';

type ReservoirOrFountainProps = {
  type: VaultType;
  hideBalance?: boolean;
};

const { useIsZksyncChainId } = currentWallet;

const ReservoirOrFountain: React.FC<ReservoirOrFountainProps> = ({
  type,
  hideBalance,
}) => {
  const t = useTranslations();
  const {
    vnoBalance,
    vnoBalanceUsd,
    isLoading,
    lockedStakes,
    unlockedStakes,
    vnoAllocations,
    pools,
  } = useReservoirOrFountain(type);
  const { historicalData } = useVenoEarnings('FOUNTAIN');
  const isZksync = useIsZksyncChainId();

  const descriptionNode = (
    <Text>
      {type === 'RESERVOIR' ? (
        <>
          {t(
            isZksync
              ? 'Deposit your VNO to receive WETH earned by Veno'
              : 'Deposit your VNO to receive WCRO and ATOM earned by Veno',
          )}
        </>
      ) : (
        <>{t('Deposit your VNO to earn more VNO')}</>
      )}
    </Text>
  );

  const claimedVnoList: ClaimedVnoSimpleLineChartProps['dataList'] = useMemo(
    () =>
      historicalData
        ? historicalData.map((d) => {
            return {
              claimedVno: d.valueBigNumber,
              date: d.date.getTime(),
            };
          })
        : [],
    [historicalData],
  );

  const reservoirQueryRet = useReservoirPoolInfos(false);
  const fountainQueryRet = useFountainPoolInfos(false);

  return (
    <WithWallet
      actionHint={t('lock VNO')}
      py={6}
      px={{ base: 4, desktop: 0 }}
      bgPos="right -150px"
      minH="572px"
    >
      <Flex direction="column" align="center" gap={6}>
        <InMobile>{descriptionNode}</InMobile>
        {!isZksync && type === 'FOUNTAIN' && (
          <InMobile>
            <CollectPenaltyCard
              type={type}
              my={2}
              minW="343px"
              w={{ base: 'full', desktop: 'auto' }}
            />
          </InMobile>
        )}
        {!hideBalance && (
          <Balance
            title={t('Wallet balance')}
            icon={<Image alt="VNO" src="/tokens/vno.svg" />}
            amount={vnoBalance}
            usdAmount={vnoBalanceUsd}
            showUsdAmount
          />
        )}

        <InMobile>
          <Box w="full">
            {!!claimedVnoList?.length && type === 'FOUNTAIN' && (
              <ClaimedVnoSimpleLineChart
                dataList={claimedVnoList}
                legendLabel={t('VNO claimed in Fountain')}
                mb="32px"
              />
            )}
            <ClaimPendingVnoCard type={type} />
          </Box>
        </InMobile>
        <InDesktop>{descriptionNode}</InDesktop>
        {!isLoading && !pools?.length && (
          <Retry
            hint={`An error occurred when loading ${
              type === 'FOUNTAIN' ? 'Fountain' : 'Reservoir'
            }.`}
            onRetry={async () => {
              if (type === 'FOUNTAIN') {
                await fountainQueryRet.refetch();
              } else {
                await reservoirQueryRet.refetch();
              }
            }}
          />
        )}
        {!isLoading && (
          <>
            <InMobile>
              <Box w="full">
                <VaultCards
                  lockedStakes={lockedStakes}
                  unlockedStakes={unlockedStakes}
                  vnoAllocations={vnoAllocations}
                  pools={pools as ReservoirOrFountainPool[]}
                  type={type}
                />
              </Box>
            </InMobile>
            <InDesktop>
              <Grid
                // make sure exact same width all columns
                templateColumns="repeat(2, minmax(0, 1fr))"
                w="full"
                gap={4}
              >
                <VaultCards
                  lockedStakes={lockedStakes}
                  unlockedStakes={unlockedStakes}
                  vnoAllocations={vnoAllocations}
                  pools={pools as ReservoirOrFountainPool[]}
                  type={type}
                />
              </Grid>
            </InDesktop>
          </>
        )}
      </Flex>
    </WithWallet>
  );
};

interface VaultCardsProps {
  lockedStakes: Stake[];
  unlockedStakes: Stake[];
  vnoAllocations: BigNumber[];
  pools: ReservoirOrFountainPool[];
  type: VaultType;
}

const VaultCards = ({
  lockedStakes,
  unlockedStakes,
  vnoAllocations,
  pools,
  type,
}: VaultCardsProps) => {
  return (
    <>
      {pools?.map((p) => {
        const lockedStakesInPool = lockedStakes.filter(
          (s) => Number(s.poolId) === p.pid,
        );
        const unlockedStakesInPool = unlockedStakes.filter(
          (s) => Number(s.poolId) === p.pid,
        );
        return (
          <VaultCard
            key={p.pid}
            pid={p.pid}
            lockPeriod={p.lockPeriod}
            apr={p.apr}
            multiplier={p.multiplier}
            lockedStakes={lockedStakesInPool}
            unlockedStakes={unlockedStakesInPool}
            allocation={vnoAllocations[p.pid]}
            type={type}
          />
        );
      })}
    </>
  );
};

export const useTotalStakedAmount = () => {
  const {
    isLoading: isLoadingReservoir,
    lockedStakes: lockedStakesReservoir,
    unlockedStakes: unlockedStakesReservoir,
  } = useVenoReservoirData(!PRELAUNCH);
  const {
    isLoading: isLoadingFountain,
    lockedStakes: lockedStakesFountain,
    unlockedStakes: unlockedStakesFountain,
  } = useVenoFountainData(!PRELAUNCH);

  const allStakes = [
    ...lockedStakesReservoir,
    ...unlockedStakesReservoir,
    ...lockedStakesFountain,
    ...unlockedStakesFountain,
  ];
  const totalAmount = allStakes.reduce(
    (prev, curr) => prev.add(curr.amount),
    BigNumber.from(0),
  );
  return { isLoading: isLoadingReservoir || isLoadingFountain, totalAmount };
};

export const useReservoirOrFountain = (type: VaultType) => {
  const {
    isLoading: isLoadingReservoir,
    lockedStakes: lockedStakesReservoir,
    unlockedStakes: unlockedStakesReservoir,
    vnoAllocations: vnoAllocationsReservoir,
    pools: poolsReservoir,
  } = useVenoReservoirData(type === 'RESERVOIR' && !PRELAUNCH);
  const {
    isLoading: isLoadingFountain,
    lockedStakes: lockedStakesFountain,
    unlockedStakes: unlockedStakesFountain,
    vnoAllocations: vnoAllocationsFountain,
    pools: poolsFountain,
    pendingVaultPenalty,
    pendingVaultPenaltyLoading,
    pendingVaultPenaltyFetching,
  } = useVenoFountainData(type === 'FOUNTAIN' && !PRELAUNCH);

  const { isLoading, lockedStakes, unlockedStakes, vnoAllocations, pools } =
    useMemo(() => {
      switch (type) {
        case 'RESERVOIR':
          return {
            isLoading: isLoadingReservoir,
            lockedStakes: lockedStakesReservoir,
            unlockedStakes: unlockedStakesReservoir,
            vnoAllocations: vnoAllocationsReservoir,
            pools: poolsReservoir,
          };
        case 'FOUNTAIN':
          return {
            isLoading: isLoadingFountain,
            lockedStakes: lockedStakesFountain,
            unlockedStakes: unlockedStakesFountain,
            vnoAllocations: vnoAllocationsFountain,
            pools: poolsFountain,
          };
      }
    }, [
      isLoadingFountain,
      isLoadingReservoir,
      lockedStakesFountain,
      lockedStakesReservoir,

      poolsFountain,
      poolsReservoir,
      type,
      unlockedStakesFountain,
      unlockedStakesReservoir,
      vnoAllocationsFountain,
      vnoAllocationsReservoir,
    ]);

  const { vnoBalance } = useVnoBalance({ enabled: !PRELAUNCH });
  const { data: vnoBalanceUsd } = useVnoUsdValue(vnoBalance);

  return {
    vnoBalance,
    vnoBalanceUsd,
    isLoading,
    lockedStakes,
    unlockedStakes,
    vnoAllocations,
    pools,
    pendingVaultPenalty,
    pendingVaultPenaltyFetching,
    pendingVaultPenaltyLoading,
  };
};

export default ReservoirOrFountain;
