import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { QueryKey } from '@ui/queries/queryKey';

const useClaimPenaltyRewards = () => {
  const queryClient = useQueryClient();
  const sdk = useVenoSDK();
  const claim = async () => await sdk.VenoFountain.claimVaultPenalty();

  return useMutation(claim, {
    onSuccess: async (data) => {
      await data.txReceiptPromise;
      queryClient.invalidateQueries([QueryKey.PENDING_VAULT_PENALTY]);
      queryClient.invalidateQueries([QueryKey.GET_VENO_FOUNTAIN_USER_INFO]);
      queryClient.invalidateQueries([QueryKey.BOOST_MULTIPLIER_POOL]);
      queryClient.invalidateQueries([QueryKey.BOOST_MULT_LOCKED_VNO]);
      queryClient.invalidateQueries([QueryKey.BOOST_TOKENS]);
    },
  });
};

export default useClaimPenaltyRewards;
