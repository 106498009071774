import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { QueryKey } from '@ui/queries/queryKey';
import type { VaultType } from '@ui/views/Vno/ReservoirAndFountain/types';
import { currentWallet } from '@veno-app/wallet';

const { useAccount } = currentWallet;

const useVenoUpgrade = (
  venoContractInstance:
    | ReturnType<typeof useVenoSDK>['VenoReservoir']
    | ReturnType<typeof useVenoSDK>['VenoFountain'],
  onSuccess: () => void,
) => {
  const account = useAccount();

  return {
    ...useMutation({
      mutationFn: async ({
        stakeIds,
        targetPid,
      }: {
        stakeIds: number[];
        targetPid: number;
      }) => {
        if (!account) return;

        const depositRet = await venoContractInstance.batchUpgrade(
          stakeIds,
          targetPid,
        );
        await depositRet.txReceiptPromise;
      },
      onSuccess,
    }),
  };
};

export const useVaultUpgrade = (type: VaultType) => {
  const reservoirUpgrade = useVenoReservoirUpgrade();
  const fountainUpgrade = useVenoFountainUpgrade();
  return type === 'RESERVOIR' ? reservoirUpgrade : fountainUpgrade;
};

export const useVenoReservoirUpgrade = () => {
  const sdk = useVenoSDK();
  const queryClient = useQueryClient();
  return useVenoUpgrade(sdk.VenoReservoir, () =>
    queryClient.invalidateQueries([QueryKey.GET_VENO_RESERVOIR_USER_INFO]),
  );
};

export const useVenoFountainUpgrade = () => {
  const sdk = useVenoSDK();
  const queryClient = useQueryClient();
  return useVenoUpgrade(sdk.VenoFountain, () =>
    queryClient.invalidateQueries([QueryKey.GET_VENO_FOUNTAIN_USER_INFO]),
  );
};
