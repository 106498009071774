import { features } from '@ui/components/Features';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { isFeatureEnabled } from '@ui/hooks/useIsFeatureEnabled';
import type { GraphQLClient } from 'graphql-request';

import { QueryKey } from './queryKey';

type EarningsResponse = {
  earnings: {
    id: string;
    lifeTimeEarningInCro: string;
    lifeTimeEarningInUSD: string;
    pendingEarningInCro: string;
    pendingEarningInUSD: string;
    lCroBalance: string;
  }[];
};

const EARNINGS_QUERY = `
query earnings($address: String!) {
  earnings(where:{id: $address}) {
    id
    lifeTimeEarningInCro
    lifeTimeEarningInUSD
    pendingEarningInCro
    pendingEarningInUSD
    lCroBalance
  }
}
`;

type FerroLpPriceResponse = {
  ferroInfo: {
    lpPriceInCro: string;
  };
};

const FERRO_LP_PRICE_QUERY = `
query ferroLpPrice {
  ferroInfo(id: "1") {
    id
    lpPriceInCro
  }
}
`;

type FerroLpPriceAtomResponse = {
  ferroInfo: {
    lpPriceInAtom: string;
  };
};

const FERRO_LP_PRICE_ATOM_QUERY = `
query ferroLpPrice {
  ferroInfo(id: "1") {
    id
    lpPriceInAtom
  }
}
`;

const TRANSACTION_HISTORY_QUERY = `
query transactions($address: String!, $first: Int, $skip: Int) {
  transactions(
    first: $first,
    skip: $skip,
    orderBy: timestamp,
    orderDirection: desc,
    where: {addresses_contains: [$address]})
    {
      id
      txnHash
      from
      to
      croAmount
      lcroAmount
      usdAmount
      exchangeRate
      timestamp
      type
      tTokenAmount
  }
}
`;

const TRANSACTION_HISTORY_BY_DAY_QUERY = `
query transactions($timestamp_gte: Int!, $timestamp_lt: Int!, $address: String!) {
  transactions(
    first: 12,
    orderBy: timestamp,
    orderDirection: asc,
    where: {timestamp_gte: $timestamp_gte timestamp_lt: $timestamp_lt addresses_contains: [$address]})
    {
      id
      txnHash
      from
      to
      croAmount
      lcroAmount
      usdAmount
      exchangeRate
      timestamp
      type
      tTokenAmount
  }
}
`;

// Todo add vvsLPBalance
const EARNINGS_HISTORY_QUERY = isFeatureEnabled(features.vvsLpTracking)
  ? `
query earningSnapshots($address: String!) {
  earningSnapshots(
    first: 200,
    orderBy: date,
    orderDirection: desc,
    where: {address: $address})
    {
      address
      date
      lifeTimeEarningInCro
      pendingEarningInCro
      exchangeRate
      lCroBalance
      ferroLPBalance
      ferroFactor
      earnedFromFerroLP
      tLCroBalance
      vvsLPBalance
      lcroAtVvsInCro
  }
}`
  : `query earningSnapshots($address: String!) {
  earningSnapshots(
    first: 200,
    orderBy: date,
    orderDirection: desc,
    where: {address: $address})
    {
      address
      date
      lifeTimeEarningInCro
      pendingEarningInCro
      exchangeRate
      lCroBalance
      ferroLPBalance
      ferroFactor
      earnedFromFerroLP
      tLCroBalance
  }
}
`;

const EXCHANGE_RATE_HISTORY_QUERY = `
query exchangeRateHistory {
  liquidCroDailySnapshots(
    first: 200,
    orderBy: date,
    orderDirection: desc)
    {
      date
      exchangeRate
  }
}
`;

export type EarningSnapshot = {
  address: string;
  date: string;
  lifeTimeEarningInCro: string;
  pendingEarningInCro: string;
  exchangeRate: string;
  lCroBalance: string;
  ferroLPBalance: string;
  ferroFactor: string;
  earnedFromFerroLP: string;
  tLCroBalance: string;
  vvsLPBalance: string;
  lcroAtVvsInCro: string;
};

type EarningsHistoryResponse = {
  earningSnapshots: EarningSnapshot[];
};

export type ExchangeRateHistoryResponse = {
  liquidCroDailySnapshots: {
    date: string;
    exchangeRate: string;
  }[];
};

export type TransactionHistoryResponse = {
  transactions: {
    id: string;
    txnHash: string;
    from: string;
    to: string;
    croAmount: string;
    lcroAmount: string;
    usdAmount: string;
    exchangeRate: string;
    timestamp: string;
    type: string;
    lpTokenAmount: string | null;
    tTokenAmount: string | null;
  }[];
};

const VENO_EARNINGS_QUERY = `
query vnoEarningSnapshots($address: String!) {
  vnoEarningSnapshots(
    first: 200,
    orderBy: date,
    where: { address: $address },
    orderDirection: desc)
    {
      id
      gardenEarning
      fountainEarning
      totalEarning
      date
      address
    }
}
`;

const VENO_EARNINGS_ZKSYNC_QUERY = `
query vnoEarningSnapshots($address: String!) {
  vnoEarningSnapshots(
    first: 200,
    orderBy: date,
    where: { address: $address },
    orderDirection: desc)
    {
      id
      gardenEarning
      fountainEarning
      totalEarning
      date
      address
    }
}
`;

export type VenoEarningsResponse = { vnoEarningSnapshots: VenoEarning[] };

export type VenoEarning = {
  id: string;
  address: string;
  date: string;
  fountainEarning: string;
  gardenEarning: string;
  totalEarning: string;
};

type SnapshotsAtomResponse = {
  snapshots: {
    ferroLPBalance: string;
    ferroFactor: string;
  }[];
};
const SNAPSHOTS_ATOM = `
query snapshotsAtom($address: String!, $first: Int) {
  snapshots(
    first: $first,
    orderBy: date,
    orderDirection: desc,
    where: {address: $address}
  )
  {
    ferroLPBalance
    ferroFactor
  }
}
`;

export const getVenoEarnings = (
  graphQlClient: GraphQLClient,
  address: string,
) => ({
  queryKey: ['veno-earnings', address],
  queryFn: async () => {
    const result = await graphQlClient.request<VenoEarningsResponse>(
      VENO_EARNINGS_QUERY,
      { address },
    );
    return result;
  },
});

export const getVenoEarningsZksync = (
  graphQlClient: GraphQLClient,
  address: string,
) => ({
  queryKey: ['veno-earnings-zksync', address],
  queryFn: async () => {
    const result = await graphQlClient.request<VenoEarningsResponse>(
      VENO_EARNINGS_ZKSYNC_QUERY,
      { address },
    );
    return result;
  },
});

export const getEarnings = (graphQlClient: GraphQLClient, address: string) => ({
  queryKey: [QueryKey.EARNINGS, address],
  queryFn: async () => {
    const result = await graphQlClient.request<EarningsResponse>(
      EARNINGS_QUERY,
      { address: address.toLowerCase() },
    );
    return result;
  },
});

export const getTransactionHistory = (
  graphQlClient: GraphQLClient,
  address: string,
  first?: number,
  skip?: number,
) => ({
  queryKey: [QueryKey.TRANSACTION_HISTORY, address, first, skip],
  queryFn: async () => {
    const result = await graphQlClient.request<TransactionHistoryResponse>(
      TRANSACTION_HISTORY_QUERY,
      { address, first, skip },
    );
    return result;
  },
});

export const getTransactionHistoryByDay = async (
  graphQlClient: GraphQLClient,
  address: string,
  dayTimestampSeconds: number,
) => {
  const result = await graphQlClient.request<TransactionHistoryResponse>(
    TRANSACTION_HISTORY_BY_DAY_QUERY,
    {
      address,
      timestamp_gte: dayTimestampSeconds,
      timestamp_lt: dayTimestampSeconds + 3600 * 24,
    },
  );
  return result;
};

export type TransformedEarningSnapshot = {
  address: number;
  date: number;
  lifeTimeEarningInCro: number;
  pendingEarningInCro: number;
  exchangeRate: number;
  lCroBalance: number;
  ferroLPBalance: number;
  ferroFactor: number;
  earnedFromFerroLP: number;
  tLCroBalance: number;
  vvsLPBalance: number;
  lcroAtVvsInCro: number;
};

export const getEarningsHistory = (
  graphQlClient: GraphQLClient,
  address: string,
) => ({
  queryKey: [QueryKey.EARNINGS_HISTORY, address],
  queryFn: async (): Promise<TransformedEarningSnapshot[]> => {
    const result = await graphQlClient.request<EarningsHistoryResponse>(
      EARNINGS_HISTORY_QUERY,
      { address },
    );
    const snapshots = result.earningSnapshots;

    if (snapshots.length) {
      const keys = Object.keys(snapshots[0]) as (keyof EarningSnapshot)[];
      return snapshots.map((snapshot) =>
        keys.reduce((acc, key) => {
          const value = snapshot[key];
          acc[key] =
            key === 'date'
              ? parseInt(value) * 1000
              : typeof value === 'string'
              ? parseFloat(value)
              : 0;
          return acc;
        }, {} as { [K in keyof EarningSnapshot]: number }),
      );
    }
    return [];
  },
});

export const getLpPrice = (
  graphQlClient: GraphQLClient,
  coinMode: CoinMode,
) => {
  switch (coinMode) {
    case 'atom':
      return getFerroAtomLpPrice(graphQlClient);
    case 'cro':
      return getFerroLpPrice(graphQlClient);
    case 'eth':
      // TODO
      return { queryKey: ['getLpPriceEth'], queryFn: async () => 0 };
  }
};

export const getFerroLpPrice = (graphQlClient: GraphQLClient) => ({
  queryKey: [QueryKey.FERRO_LP_PRICE],
  queryFn: async () => {
    const result = await graphQlClient.request<FerroLpPriceResponse>(
      FERRO_LP_PRICE_QUERY,
    );
    return parseFloat(result.ferroInfo.lpPriceInCro);
  },
});

export const getFerroAtomLpPrice = (graphQlClient: GraphQLClient) => ({
  queryKey: [QueryKey.FERRO_LP_PRICE_ATOM],
  queryFn: async () => {
    const result = await graphQlClient.request<FerroLpPriceAtomResponse>(
      FERRO_LP_PRICE_ATOM_QUERY,
    );
    if (!result.ferroInfo) {
      return 0;
    }
    return parseFloat(result.ferroInfo.lpPriceInAtom);
  },
});

export const getSnapshotsAtom = ({
  graphQlClient,
  address,
  first,
}: {
  graphQlClient: GraphQLClient;
  address: string;
  first?: number;
}) => ({
  queryKey: ['getSnapshotsAtom', address, first],
  queryFn: async () => {
    const result = await graphQlClient.request<SnapshotsAtomResponse>(
      SNAPSHOTS_ATOM,
      {
        address,
        first,
      },
    );
    return result.snapshots;
  },
});

export type ExchangeRateSnapshot = {
  date: number;
  exchangeRate: number;
};

export const getExchangeRateHistory = (graphQlClient: GraphQLClient) => ({
  queryKey: [QueryKey.EXCHANGE_RATE_HISTORY],
  queryFn: async (): Promise<ExchangeRateSnapshot[]> => {
    const result = await graphQlClient.request<ExchangeRateHistoryResponse>(
      EXCHANGE_RATE_HISTORY_QUERY,
    );
    return result.liquidCroDailySnapshots.map((sn) => ({
      date: parseInt(sn.date) * 1000,
      exchangeRate: parseFloat(sn.exchangeRate),
    }));
  },
});
