import type { QueryClient } from '@tanstack/react-query';
import { useBalance } from '@ui/hooks/useBalance';
import { queryClient } from '@ui/pages/_app';
import { currentWallet } from '@veno-app/wallet';
import type { Callback, Dict, RequestOptions } from 'mixpanel-browser';
import mixpanel from 'mixpanel-browser';
import { createContext, useEffect, useMemo } from 'react';

import { IS_DEVELOPMENT, IS_STAGING } from './envHelper';
import withMixpanel from './withMixpanel';

declare global {
  interface Window {
    gtag: any;
  }
}

export const useInitializeMixpanel = withMixpanel(() => {
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN ?? '', {
    debug: IS_DEVELOPMENT || IS_STAGING,
  });
  mixpanel.register({ dapp_id: 'veno' });

  useMixPanelRegisterSuperProps();
});

const useMixPanelRegisterSuperProps = () => {
  const isConnected = currentWallet.useIsConnected();
  const isConnectedToSupportedChain =
    currentWallet.useIsConnectedToSupportedChain();
  const balanceRet = useBalance(undefined, {
    context: useMemo(
      () => createContext<QueryClient | undefined>(queryClient),
      [],
    ),
  });

  useEffect(() => {
    if (
      isConnected &&
      isConnectedToSupportedChain &&
      typeof balanceRet.data !== 'undefined'
    ) {
      registerSuperProps({
        'CRO balance': String(balanceRet.data),
      });
    }
  }, [balanceRet.data, isConnected, isConnectedToSupportedChain]);
};

export const registerSuperProps = withMixpanel(
  (props: Dict, days?: number | undefined) => {
    mixpanel.register(props, days);
  },
);

type AnalyticsPlatform = 'mixpanel' | 'googleAnalytics';

/**
 * Track events with mix panel
 *
 * @param event_name name of event to be tracked
 * @param properties optional dictionary of keys/values with additional event info
 * @param optionsOrCallback
 * @param callback
 * @param platforms Platforms where to track this event on; keep undefined to track on all
 * @returns
 */
export const trackEvent = withMixpanel(
  (
    event_name: string,
    properties?: Dict,
    optionsOrCallback?: RequestOptions | Callback,
    callback?: Callback,
    platforms?: AnalyticsPlatform[],
  ) => {
    (!platforms || platforms.includes('mixpanel')) &&
      mixpanel.track(event_name, properties, optionsOrCallback, callback);
    (!platforms || platforms.includes('googleAnalytics')) &&
      // only can call this if google analytics configured
      process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID &&
      window.gtag(
        'event',
        event_name.replaceAll(' ', '_').toLowerCase(),
        transformKeysForGoogle(properties),
      );
  },
);

const transformKeysForGoogle = (properties: Dict | undefined) =>
  properties
    ? Object.fromEntries(
        Object.entries(properties).map(([k, v]) => [
          k.replace(/\s+/g, '_').toLowerCase(),
          v,
        ]),
      )
    : undefined;
