import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import type { CardProps } from '@ui/components/Card';
import { Card } from '@ui/components/Card';
import { useTranslations } from '@ui/i18n';
import type { FC } from 'react';

import type { TokenProtocol } from './utils';

export type ProtocolCardProps = Omit<CardProps, keyof TokenProtocol> &
  TokenProtocol;

export const ProtocolCard: FC<ProtocolCardProps> = ({
  title,
  titleTail,
  subtitle,
  logo,
  description,
  tokens,
  tvl,
  ...props
}) => {
  const t = useTranslations();

  return (
    <Card variant="button" mx="0" my="0" p="16px" cursor="pointer" {...props}>
      <Flex gap="8px">
        <Image
          src={logo}
          w="45px"
          h="45px"
          flexShrink="0"
          alt="protocol logo"
          objectFit="contain"
        />
        <Box flex="1">
          <Flex
            textStyle="h4Bold"
            justify="space-between"
            alignItems="center"
            gap="8px"
          >
            {title}
            {titleTail && <Box>{titleTail}</Box>}
          </Flex>
          <Box color="text-light" mt="4px">
            {subtitle}
          </Box>
        </Box>
      </Flex>
      {tvl && <Box mt="10px">TVL: {tvl}</Box>}
      <Box color="text-light" mt="10px">
        {description}
      </Box>
      <Flex mt="10px" gap="4px" alignItems="center">
        <Text>{t('Tokens')}: </Text>
        <Flex flex="1" alignItems="center">
          {tokens}
        </Flex>
        <Button variant="link" color="text-link" ml="10px" alignSelf="flex-end">
          {t('View')}
        </Button>
      </Flex>
    </Card>
  );
};
