/**
 * Takes a function and returns the same function but make sure it's never called
 * if mixpanel not enabled.
 *
 * @param fn a function that should only be called if mixpanel is enabled
 * @returns input function fn if mixpanel is enabled, otherwise a noop function
 */
const withMixpanel = <T>(fn: T): T | ((..._rest: Array<unknown>) => void) => {
  if (typeof window === 'undefined' || !process.env.NEXT_PUBLIC_MIXPANEL_TOKEN)
    return () => {};
  return fn;
};

export default withMixpanel;
