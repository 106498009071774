import type { CoinMode } from '@ui/hooks/useCoinMode';
import { withReset } from '@ui/utils/zustandMiddleware';
import { create } from 'zustand';

import type { SupplyLcroModalType } from './types';

type SupplyLcroModalStore = {
  isOpen: boolean;
  coinMode: CoinMode;
  close: () => void;
  open: (options: {
    type: SupplyLcroModalType;
    coinMode: CoinMode;
    onBackward?: () => void;
  }) => void;
  onBackward?: () => void;
  type: SupplyLcroModalType;
};

export const useSupplyLcroModalStore = create(
  withReset<SupplyLcroModalStore>((set) => ({
    isOpen: false,
    coinMode: 'cro',
    close: () => {
      useSupplyLcroModalStore.getState().$$resetData?.();
    },
    open: ({ type, coinMode, onBackward }) => {
      set({
        isOpen: true,
        type,
        coinMode,
        onBackward,
      });
    },
    onBackward: undefined,
    type: 'FERRO',
  })),
);
