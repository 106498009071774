import { Box, Button, Flex, Text } from '@chakra-ui/react';
import type { BigNumber } from '@ethersproject/bignumber';
import { Card } from '@ui/components/Card';
import { LPTokenIcon } from '@ui/components/LPTokenIcon';
import { useTranslations } from '@ui/i18n';
import { formatTokenAmount } from '@ui/utils/format';
import type { GardenToken } from '@ui/utils/garden';
import {
  formatGardenApy,
  gardenTokenDict,
  getGardenColorThemeByCoinMode,
  getGardenName,
} from '@ui/utils/garden';

import { GardenLogo } from '../Garden/GardenLogo';
import { useGardenModalStore } from '../Garden/useGardenModalStore';
import type { SupplyLcroModalType } from './types';
import { useSupplyLcroModalStore } from './useSupplyLcroModalStore';

interface SupplyTransactionCompletedContentProps {
  gardenApy: number;
  receivedAmount: BigNumber;
  type: SupplyLcroModalType;
}
const SupplyTransactionCompletedContent: React.FC<
  SupplyTransactionCompletedContentProps
> = ({ receivedAmount, gardenApy, type }) => {
  const t = useTranslations();
  const coinMode = useSupplyLcroModalStore((s) => s.coinMode);
  const gardenToken = gardenTokenDict[coinMode][type] as Extract<
    GardenToken,
    { enabled: true }
  >;

  return (
    <Flex mt="18px" flexDir="column" grow={1} gap={4} align="center">
      <Text textStyle="h3">{t('Transaction completed!')}</Text>
      <Flex flexDir="column" align="center" mt={2}>
        <Text textStyle="bodySmall">{t('You have received')}</Text>
        <Flex flexDir="row" alignItems="center">
          <LPTokenIcon
            coinMode={coinMode}
            gardenType={type}
            textStyle="h2"
            iconWidth="40px"
            iconHeight="40px"
          >
            {formatTokenAmount(
              receivedAmount,
              3,
              gardenTokenDict[coinMode][type].decimals,
            )}
          </LPTokenIcon>
        </Flex>
      </Flex>
      {/* TODO token type tLCRO */}
      {/* <AddTokenButton tokenType={TokenType.LCRO} text="Add !TODO! to wallet" /> */}
      <Text
        hidden={!gardenToken.enabled}
        textStyle="body"
        px={4}
        mt="24px"
        textAlign="center"
        alignSelf="flex-start"
        // opacity={0.75}
        w="full"
      >
        {t('Plant your {coin} in the {platform} to earn VNO rewards', {
          coin: gardenToken.name,
          platform: getGardenName(coinMode, type),
        })}
      </Text>
      <Card
        hidden={!gardenToken.enabled}
        colorTheme={getGardenColorThemeByCoinMode(coinMode, type)}
        p="10px 23px"
        alignSelf="stretch"
        mt="8px"
      >
        <Flex gap="21px">
          <GardenLogo
            coinMode={coinMode}
            gardenType={type}
            showGardenName={false}
            showTVL={false}
          />
          <Box>
            <Text textStyle="h4Bold">{getGardenName(coinMode, type)}</Text>
            <Text mt="8px">
              <Text as="span" color="text-light">
                APY:
              </Text>
              <Text as="span" textStyle="bodyBold" ml="4px">
                {formatGardenApy(gardenApy)}
              </Text>
            </Text>
          </Box>
        </Flex>
      </Card>
    </Flex>
  );
};

const SupplyTransactionCompletedFooter: React.FC<{
  onCloseDrawer: () => void;
  type: SupplyLcroModalType;
}> = ({ onCloseDrawer, type }) => {
  const t = useTranslations();
  const close = useSupplyLcroModalStore((s) => s.close);
  const coinMode = useSupplyLcroModalStore((s) => s.coinMode);
  const openGardenModal = useGardenModalStore((s) => s.open);
  const gardenToken = gardenTokenDict[coinMode][type] as Extract<
    GardenToken,
    { enabled: true }
  >;

  return (
    <>
      <Button
        className="veno-tour__supply-modal-plant-button"
        hidden={!gardenToken.enabled}
        w="full"
        variant="primary"
        mb="20px"
        onClick={() => {
          onCloseDrawer();
          close();
          openGardenModal({
            type,
            coinMode,
            activeView: 'PLANT',
          });
        }}
      >
        {t('Plant {coin}', {
          coin: gardenToken.name,
        })}
      </Button>
      <Button
        variant={!gardenToken.enabled ? 'primary' : 'link'}
        textAlign="center"
        onClick={onCloseDrawer}
      >
        <Text>{t('Done')}</Text>
      </Button>
    </>
  );
};

const SupplyTransactionCompleted = {
  Content: SupplyTransactionCompletedContent,
  Footer: SupplyTransactionCompletedFooter,
} as const;

export default SupplyTransactionCompleted;
