import { Button, Flex, Skeleton } from '@chakra-ui/react';
import Notification from '@ui/components/Notification';
import type { NotificationProps } from '@ui/components/Notification/Notification';
import { useTranslations } from '@ui/i18n';
import { formatTokenAmount } from '@ui/utils/format';
import React from 'react';

import { useCollectPenaltyDrawerStore } from './CollectPenaltyDrawer';
import { useReservoirOrFountain } from './ReservoirOrFountain';
import type { VaultType } from './types';

export const CollectPenaltyCard = ({
  type,
  ...props
}: Partial<NotificationProps> & {
  type: VaultType;
}) => {
  const t = useTranslations();
  const { pools, pendingVaultPenalty, pendingVaultPenaltyLoading } =
    useReservoirOrFountain(type);
  const onOpenCollectPenaltyDrawer = useCollectPenaltyDrawerStore(
    (s) => s.onOpen,
  );

  return (
    <Notification
      button={
        pools && pools.length === 4 && pools[3].apr !== null ? (
          <Button
            size={{ base: 'sm' }}
            onClick={() => onOpenCollectPenaltyDrawer(pools[3].apr ?? 0)}
          >
            {t('Collect')}
          </Button>
        ) : undefined
      }
      title="Penalty Fee Rewards"
      text={
        <>
          {t('Amount available to collect')}:
          <br />
          <Flex>
            {!pendingVaultPenaltyLoading ? (
              formatTokenAmount(pendingVaultPenalty, 5)
            ) : (
              <Skeleton mr={1} w="30px" h={{ base: '21px', desktop: '24px' }} />
            )}{' '}
            VNO
          </Flex>
        </>
      }
      {...props}
    />
  );
};
