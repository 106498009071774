import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEntryPoint } from '@ui/components/EntryPointProvider/EntryPointProvider';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { QueryKey } from '@ui/queries/queryKey';
import { trackEvent } from '@ui/utils/tracking';
import type { Stake } from '@veno-app/sdk';
import { currentWallet } from '@veno-app/wallet';

const { useAccount } = currentWallet;

export const useVenoWithdrawal = (
  venoContractInstance:
    | ReturnType<typeof useVenoSDK>['VenoReservoir']
    | ReturnType<typeof useVenoSDK>['VenoFountain'],
  onSuccess?: () => void,
) => {
  const account = useAccount();

  return useMutation(
    async ({ ids }: { ids: BigNumber[] }) => {
      if (!account) {
        return;
      }

      const withdrawRet = await venoContractInstance.batchWithdraw(ids);
      await withdrawRet.txReceiptPromise;
    },
    { onSuccess },
  );
};

export const useVenoReservoirWithdrawal = () => {
  const sdk = useVenoSDK();
  const queryClient = useQueryClient();
  return useVenoWithdrawal(sdk.VenoReservoir, () => {
    queryClient.invalidateQueries([
      QueryKey.GET_VENO_RESERVOIR_PENDING_REWARDS,
    ]);
    queryClient.invalidateQueries([QueryKey.GET_VENO_RESERVOIR_USER_INFO]);
  });
};

export const useVenoFountainWithdrawal = () => {
  const sdk = useVenoSDK();
  const queryClient = useQueryClient();
  return useVenoWithdrawal(sdk.VenoFountain, () => {
    queryClient.invalidateQueries([QueryKey.GET_VENO_FOUNTAIN_PENDING_REWARDS]);
    queryClient.invalidateQueries([QueryKey.GET_VENO_FOUNTAIN_USER_INFO]);
  });
};

export const useVenoFountainEarlyWithdrawal = () => {
  const sdk = useVenoSDK();
  const queryClient = useQueryClient();
  const account = useAccount();
  const entryPoint = useEntryPoint();

  return useMutation(
    async ({ ids }: { ids: BigNumber[] }) => {
      if (!account) {
        return;
      }

      const stakes: Stake[] = (await sdk.VenoFountain.getUserInfo(account))
        .stakes;
      const withdrawnStake = stakes.find((s) => s.stakeId.eq(ids[0]));
      const withdrawRet = await sdk.VenoFountain.withdrawEarly(ids);
      await withdrawRet.txReceiptPromise;

      return { withdrawnStake, entryPoint };
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          QueryKey.GET_VENO_FOUNTAIN_PENDING_REWARDS,
        ]);
        queryClient.invalidateQueries([QueryKey.GET_VENO_FOUNTAIN_USER_INFO]);
        const { withdrawnStake, entryPoint } = data ?? {};
        if (!withdrawnStake) return;
        const { poolId, amount } = withdrawnStake;
        let earlyWithdrawPenalty = 0.9;
        let period = '96-months';
        switch (poolId.toNumber()) {
          case 0:
            earlyWithdrawPenalty = 0.6;
            period = '3-months';
            break;
          case 1:
            period = '12-months';
            earlyWithdrawPenalty = 0.7;
            break;
          case 2:
            period = '48-months';
            earlyWithdrawPenalty = 0.8;
            break;
        }
        const earlyWithdrawFee = BigNumber.from(earlyWithdrawPenalty * 10)
          .mul(amount ?? 0)
          .div(10);
        trackEvent('Early Withdraw Fountain Confirmed', {
          'Locked Amount': formatUnits(amount),
          isTransaction: true,
          'Penalty Fee': formatUnits(earlyWithdrawFee),
          'Entry Point': entryPoint,
          Period: period,
        });
      },
    },
  );
};
