import { TinyColor } from '@ctrl/tinycolor';
export function lintColors(colors) {
    return colors;
}
export function lintBrandColors(colors) {
    return colors;
}
export function generateSemanticTokensFromColors(colors, nameSpace = '') {
    return Object.keys(colors)
        .filter((key) => !key.endsWith('_light') || !key.endsWith('_dark'))
        .map((key) => {
        const colorName = nameSpace ? `${nameSpace}-${key}` : key;
        const colorValue = colors[key];
        if (typeof colorValue === 'string') {
            return {
                [colorName]: {
                    default: colorValue,
                    _dark: colors[`${key}_dark`],
                    _light: colors[`${key}_light`],
                },
            };
        }
        else {
            return generateSemanticTokensFromColors(colorValue, colorName);
        }
    })
        .reduce((p, c) => ({ ...p, ...c }), {});
}
export const addAlpha = (color, alpha) => {
    return new TinyColor(color).setAlpha(alpha).toHex8String();
};
