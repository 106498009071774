interface UseShowCronosUpgradeWarningState {
  loading: boolean;
  showWarning: boolean;
}

function useShowCronosUpgradeWarning(): UseShowCronosUpgradeWarningState {
  return {
    showWarning: process.env.NEXT_PUBLIC_SHOW_MAINTENANCE === 'true',
    loading: false,
  };
}

export default useShowCronosUpgradeWarning;
