import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { formatUnits } from '@ethersproject/units';
import { PATH_CAMPAIGN } from '@ui/config/paths';
import { useRealPathname } from '@ui/hooks/router';
import { useIFrameTheme } from '@ui/hooks/useIFrameTheme';
import { useIsOnVvs } from '@ui/hooks/useIsOnVvs';
import { useMaxContentWidth } from '@ui/hooks/useMaxContentWidth';
import useTrackVisit from '@ui/hooks/useTrackVisit';
import { useTranslations } from '@ui/i18n';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { getIsMobile } from '@ui/utils';
import { trackConnectionOnCampaignPage } from '@ui/utils/trackCampaignWallets';
import { registerSuperProps, trackEvent } from '@ui/utils/tracking';
import { InColorMode } from '@veno-app/chakra';
import type { WalletConfig } from '@veno-app/wallet';
import {
  braveWallet,
  currentWallet,
  getWallet,
  walletConfigs,
} from '@veno-app/wallet';
import type { Dict } from 'mixpanel-browser';
import type { FC } from 'react';
import { Fragment, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';

import { useIsShowBraveWallet } from './useIsShowBraveWallet';
import { WalletCard } from './WalletCard';

type UseWalletModal = {
  isOpen: boolean;
  close: () => void;
  open: () => void;
};

export const useWalletModal = create<UseWalletModal>((set) => ({
  isOpen: false,
  close: () => {
    set({ isOpen: false });
  },
  open: () => {
    set({ isOpen: true });
  },
}));

export const WalletModal: FC<unknown> = () => {
  const t = useTranslations();
  const isOnVvs = useIsOnVvs();
  const { colorMode } = useIFrameTheme();

  const isOpen = useWalletModal((s) => s.isOpen);
  const { reset: resetVisit } = useTrackVisit(
    'Select Wallet',
    {},
    () => isOpen,
  );
  if (!isOpen) resetVisit();
  const close = useWalletModal((s) => s.close);
  const isShowBraveWallet = useIsShowBraveWallet();
  const connect = useWalletConnect();
  const maxContentWidth = useMaxContentWidth();

  return (
    <InColorMode colorMode={colorMode}>
      <Modal
        size={{
          base: 'full',
          desktop: 'md',
        }}
        isOpen={isOpen}
        onClose={close}
      >
        <ModalOverlay />
        <ModalContent w={maxContentWidth}>
          <ModalHeader textAlign="left">
            <Text
              textStyle={{
                base: 'h3',
                desktop: 'h4',
              }}
            >
              {t('Connect wallet')}
            </Text>
          </ModalHeader>
          <ModalCloseButton
            variant={{
              base: isOnVvs ? 'vvsLight' : 'raisedActive',
              desktop: 'default',
            }}
          />
          <ModalBody pb={6}>
            <Flex className="veno-tour__wallet-list" direction="column" gap={2}>
              {walletConfigs
                .filter(
                  (v) =>
                    v.name !== braveWallet.getWalletName() || isShowBraveWallet,
                )
                .map((v) => (
                  <Fragment key={v.title}>
                    <WalletCard
                      onClick={() => {
                        close();
                        connect({
                          walletConfig: v,
                        });
                      }}
                      walletConfig={v}
                    />
                    <Divider />
                  </Fragment>
                ))}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </InColorMode>
  );
};

const useWalletConnect = () => {
  const toast = useToast();
  const sdk = useVenoSDK();
  const pathname = useRealPathname();

  return useCallback(
    async ({ walletConfig }: { walletConfig: WalletConfig }) => {
      const wallet = getWallet(walletConfig.name);

      try {
        await wallet.detectProvider();
      } catch (error) {
        const link = walletConfig.getDownloadLink({
          isMobile: getIsMobile(),
        });

        if (link) {
          window.open(link);
        }

        toast({
          status: 'error',
          description: (error as Error)?.message,
          duration: null,
          isClosable: true,
        });
        return;
      }

      try {
        if (pathname === PATH_CAMPAIGN) {
          trackConnectionOnCampaignPage();
        } else {
          trackEvent('Connect Wallet Completed');
        }
        await currentWallet.connectAsCurrentWallet(
          walletConfig.name,
          // we can't set config because don't know if user wants to stake cro or eth,
          // will just have to click button again.

          // getNetworkConfig(),
        );
        const address = currentWallet.getStore()?.getState()?.accounts
          ? (currentWallet.getStore()?.getState()?.accounts ?? [''])[0]
          : '';
        const balance = address ? await sdk.getBalance(address) : undefined;
        const balanceProp: Dict = balance
          ? { 'CRO balance': formatUnits(balance) }
          : {};
        registerSuperProps({
          'Connected Wallet': walletConfig.trackingId,
          user_id: uuidv4(),
          ...balanceProp,
        });
      } catch (error) {
        console.error(error);
      }
    },
    [pathname, sdk, toast],
  );
};
