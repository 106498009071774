import { pathDict } from '@ui/config/paths';
import { parsePathname } from '@ui/i18n';
import { useRouter } from '@ui/overrides/next/router';
import type { GardenType, GardenView } from '@ui/utils/garden';
import type { LiquidityStrategyView } from '@ui/utils/strategy';
import { ZKSYNC_ENABLED } from '@veno-app/wallet';
import { get } from 'lodash-es';
import { useMemo } from 'react';
import { useLocation } from 'react-use';

import type { CoinMode } from './useCoinMode';
import { coinModes, coinModesCro } from './useCoinMode';

const normalizePathname = (pathname: string) => {
  return pathname.split('?')[0].replace(/\/$/, '').trim() || '/';
};

export const useRealPathname = () => {
  const locationState = useLocation();
  const { asPath } = useRouter();

  /**
   * locationState.pathname will be undefined in the server side
   * but asPath will not (if we have defined the getStaticPaths for the dynamic router)
   */
  const pathname = locationState.pathname
    ? parsePathname(locationState.pathname).pathname
    : asPath;

  return useMemo(() => normalizePathname(pathname), [pathname]);
};

const findKeyPaths = (obj: object, targetValue: string): string[] | null => {
  for (const [key, value] of Object.entries(obj)) {
    if (value === targetValue) {
      return [key];
    }
    if (value && typeof value === 'object') {
      const keys = findKeyPaths(value, targetValue);
      if (keys) {
        return [key, ...keys];
      }
    }
  }

  return null;
};

export type PageCategory =
  | 'landing'
  | 'landingDetail'
  | 'dashboard'
  | 'vno'
  | 'stake'
  | 'unstake'
  | 'useLiquid'
  | 'claim'
  | 'garden'
  | 'strategy';
export const getPathInfo = (
  realPathname: string,
  connectedToZksync?: boolean,
): {
  coinMode: CoinMode;
  category: PageCategory;
  gardenType: GardenType;
  gardenView: GardenView;
  strategyView: LiquidityStrategyView;
} => {
  realPathname = normalizePathname(realPathname);
  const keyPaths: string[] | null = findKeyPaths(pathDict, realPathname);

  if (keyPaths) {
    const [coinMode, category, gardenType, gardenView] = keyPaths;
    const isDashboardPage = category.includes('dashboard');
    const isDashboardAtomPage = category === 'dashboardAtom';
    const isLanding = category === 'landing';
    return {
      coinMode: isDashboardAtomPage
        ? 'atom'
        : ((isLanding ||
            isDashboardPage ||
            category.includes('stats') ||
            category === 'vno') &&
          ZKSYNC_ENABLED &&
          connectedToZksync
            ? 'eth'
            : (coinMode as CoinMode)) || 'cro',
      category: isDashboardPage ? 'dashboard' : (category as PageCategory),
      gardenType: (gardenType as GardenType) || 'FERRO',
      gardenView: (gardenView as GardenView) || 'PLANT',
      strategyView: (gardenType as LiquidityStrategyView) || 'deposit',
    };
  }

  const split = realPathname.split('/');

  return {
    coinMode: connectedToZksync && ZKSYNC_ENABLED ? 'eth' : 'cro',
    category: (split.length > 1 ? split[1] : split[0]) as PageCategory,
    gardenType: 'FERRO',
    gardenView: 'PLANT',
    strategyView: 'deposit',
  };
};

export const isCategoryInSameView = (
  categoryA: PageCategory,
  categoryB: PageCategory,
) => {
  if (categoryA === categoryB) {
    return true;
  }

  const categoriesInSameView: PageCategory[][] = [
    ['landing', 'landingDetail'],
    ['stake', 'useLiquid', 'strategy'],
    ['unstake', 'claim'],
    ['vno', 'garden'],
  ];

  for (const arr of categoriesInSameView) {
    if (arr.includes(categoryA) && arr.includes(categoryB)) {
      return true;
    }
  }
  return false;
};

/**
 * modify the pathname's coinMode value
 * @example
 * changeCoinModeInPath("cro", "/eth/stake"); // return "/cro/stake"
 * changeCoinModeInPath("eth", "/dashboard"); // return "/dashboard"
 */
export const changeCoinModeInPath = (
  targetCoinMode: CoinMode,
  realPathname: string,
) => {
  const [coinModeInPathname, ...keyPaths] =
    findKeyPaths(pathDict, realPathname) || [];

  if (!coinModeInPathname || targetCoinMode === coinModeInPathname) {
    return realPathname;
  }

  return get(pathDict, [targetCoinMode, ...keyPaths]) as string;
};

/**
 * return coinMode list supported by the pathname
 * @example
 * getSupportedCoinModesFromPath("/eth/stake"); // return ["eth"]
 * getSupportedCoinModesFromPath("/dashboard"); // return ["cro", "atom", "eth"]
 */
export const getSupportedCoinModesFromPath = (realPathname: string) => {
  return (ZKSYNC_ENABLED ? coinModes : coinModesCro).filter((coinMode) =>
    Boolean(findKeyPaths(pathDict[coinMode], realPathname)),
  );
};
