import type { UseMutateAsyncFunction } from '@tanstack/react-query';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const usePersistentContext = (
  key: string,
): [
  string | null,
  UseMutateAsyncFunction<void, unknown, string, string | null>,
] => {
  const queryClient = useQueryClient();

  const { data } = useQuery([key], () => localStorage.getItem(key));

  const { mutateAsync: setValue } = useMutation(
    async (value: string) => localStorage.setItem(key, value),
    {
      onMutate: (mutatedData) => {
        const current = data;
        queryClient.setQueryData([key], mutatedData);
        return current;
      },
    },
  );

  return [data ?? null, setValue];
};

export default usePersistentContext;
