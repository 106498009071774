/**
 * Drawer theme
 * @see https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src/components/drawer.ts
 */
import { drawerAnatomy as parts } from '@chakra-ui/anatomy';
import { textStyles } from '../textStyles';
import { viewportHeightVariable } from '../utils/useInjectedCssVarOfViewport';
export const Drawer = {
    parts: parts.keys,
    baseStyle(props) {
        return {
            dialog: {
                ...(props.isFullHeight && fullStyles),
                bg: 'bg.card',
            },
            overlay: {
                bg: {
                    base: 'bg.gauze',
                    lg: 'blackAlpha.600',
                },
            },
            header: {
                ...textStyles.bodySmall,
                justifyContent: 'center',
                py: '26px',
                textAlign: 'center',
            },
            closeButton: {
                color: 'bg.button',
                top: '12px',
                px: '16px',
                width: '40px',
                height: '40px',
            },
            body: {
                px: '16px',
                pb: 0,
            },
            footer: {
                px: '16px',
                pt: '24px',
                pb: '40px',
                flexDirection: 'column',
                gap: '10px',
                alignItems: 'stretch',
                backgroundColor: 'bg.mobileAppBar',
                backdropFilter: 'auto',
                backdropBlur: '2px',
            },
        };
    },
    variants: {
        backward() {
            return {
                closeButton: {
                    right: 'auto',
                    left: '16px',
                },
            };
        },
    },
    sizes: {
        full() {
            return {
                dialog: {
                    ...fullStyles,
                    /**
                     * add some space at top of drag drawer
                     * @see https://mcoproduct.atlassian.net/browse/VNO-107
                     */
                    '&.drag-drawer-content': {
                        height: `calc(${fullStyles.height} - 30px)`,
                    },
                },
            };
        },
    },
    defaultProps: {},
};
/**
 * Fix missing modal footer on iOS.
 * @see https://github.com/chakra-ui/chakra-ui/issues/4903
 */
const fullStyles = {
    minHeight: 'auto',
    maxHeight: 'unset',
    height: `var(${viewportHeightVariable}, -webkit-fill-available)`,
};
