import { Flex, Image, Text } from '@chakra-ui/react';
import { useTranslations } from '@ui/i18n';
import React, { useMemo } from 'react';

import type { Section } from './const';
import { sections } from './const';
import { useFooterArticlesSection } from './FooterArticlesProvider';
import { FooterSectionEntry } from './FooterSectionEntry';

function FooterSectionsDesktop() {
  const footerArticlesSection = useFooterArticlesSection();
  return (
    <Flex
      mt={6}
      gap={{ base: 8, desktop: 14 }}
      flexDir={{ base: 'column', desktop: 'row' }}
    >
      {sections.map((section, i) => (
        <FooterSection key={i} {...section} />
      ))}
      <FooterSection {...footerArticlesSection} />
      <Flex flexDir="column" gap={2}>
        <Image
          alt="Powered by Cronos"
          src="/images/cronoslabs.svg"
          w="92px"
          h="26px"
        />
        <Image
          alt="Powered by Cronos"
          src="/images/zksync-network.svg"
          w="93px"
          h="18px"
        />
      </Flex>
    </Flex>
  );
}

const FooterSection = ({ title, entries }: Section) => {
  const t = useTranslations();
  const titleToUse = typeof title === 'string' ? title : t(...title);
  const chunks = useMemo(() => splitArrayInChunks(entries, 7), [entries]);

  if (!entries.length) return null;

  return (
    <>
      <Flex flexDir="column">
        <Text mb={4} as="h2" textStyle="h4Bold">
          {titleToUse}
        </Text>
        {chunks[0].map((entry, i) => (
          <FooterSectionEntry key={i} {...entry} />
        ))}
      </Flex>
      {chunks.slice(1).map((chunk, i) => (
        <Flex key={i} flexDir="column">
          <Text mb={4} textStyle="h4Bold">
            &nbsp;
          </Text>
          {chunk.map((entry, i) => (
            <FooterSectionEntry key={i} {...entry} />
          ))}
        </Flex>
      ))}
    </>
  );
};

const splitArrayInChunks = <T,>(
  array: Array<T>,
  chunkSize: number,
): Array<Array<T>> => {
  return array.reduce((result: Array<Array<T>>, item: T, index: number) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!result[chunkIndex]) {
      result[chunkIndex] = [];
    }

    result[chunkIndex].push(item);

    return result;
  }, [] as T[][]);
};

export default FooterSectionsDesktop;
