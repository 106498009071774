import type { Network } from '@ethersproject/providers';
import { StaticJsonRpcProvider } from '@ethersproject/providers';
import { ChainId, currentWallet, getNetworkConfig } from '@veno-app/wallet';
import type { ReactNode } from 'react';
import { createContext, useMemo } from 'react';

const { useIsZksyncChainId } = currentWallet;

const ensAddresses: Record<ChainId, string> = {
  [ChainId.Mainnet]: '0x7F4C61116729d5b27E5f180062Fdfbf32E9283E5',
  [ChainId.Testnet]: '0x16a23bFBcE9c53998c90201629E4cDB40B81B127',
  [ChainId.Dryrun]: '0x16a23bFBcE9c53998c90201629E4cDB40B81B127',
  [ChainId.ZksyncMainnet]: '',
  [ChainId.ZksyncTestnet]: '',
} as const;

export type EnsContextValue = {
  lookupAddress: (address: string) => Promise<string | null>;
};

export const EnsContext = createContext<EnsContextValue>(
  // will be set in provider
  { lookupAddress: async () => '' },
);

interface EnsProviderProviderProps {
  children: ReactNode;
}

const config = getNetworkConfig();
const network: Network = getEnsConfig();
const provider = new StaticJsonRpcProvider(config.rpcUrls[0], network);

const lookupAddress = (address: string) => provider.lookupAddress(address);

function EnsProvider({ children }: EnsProviderProviderProps): JSX.Element {
  const isZksync = useIsZksyncChainId();

  // TODO not configured for zksync yet
  const value = useMemo(
    () => (isZksync ? { lookupAddress: async () => null } : { lookupAddress }),
    [isZksync],
  );

  return <EnsContext.Provider value={value}>{children}</EnsContext.Provider>;
}

export default EnsProvider;

function getEnsConfig(): Network {
  if (process.env.NODE_ENV === 'test') {
    return {
      ensAddress: ensAddresses[ChainId.Testnet],
      name: 'cronos-testnet',
      chainId: ChainId.Testnet,
    };
  }
  const network = process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK;
  if (network === 'cronos') {
    return {
      ensAddress: ensAddresses[ChainId.Mainnet],
      name: 'cronos-mainnet',
      chainId: ChainId.Mainnet,
    };
  } else if (network === 'veno_dryrun') {
    return {
      ensAddress: ensAddresses[ChainId.Dryrun],
      name: 'veno-dryrun',
      chainId: ChainId.Dryrun,
    };
  } else {
    return {
      ensAddress: ensAddresses[ChainId.Testnet],
      name: 'cronos-testnet',
      chainId: ChainId.Testnet,
    };
  }
}
