import { FerroSwap__factory } from '@contracts';
import { BigNumber } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';
import { VenoSDKModule } from './base';
import { expandDecimals, FERRO_LP_DECIMALS } from './constants';
import { wrapSdkTransaction } from './util';
export default class FerroSwapModule extends VenoSDKModule {
    constructor(sdk, address, decimals = 18) {
        super(sdk);
        this.contractFactory = FerroSwap__factory;
        this.contractAddress = this.sdk.addresses.ferroSwap;
        this.tokenDecimals = 18;
        this.contractAddress = address;
        this.tokenDecimals = decimals;
    }
    async getExchangeRate(amount) {
        return ((await this.getContract().calculateTokenAmount([amount ?? BigNumber.from('1000000000000000000'), 0], true))
            .mul(100000000)
            .div(amount ?? BigNumber.from('1000000000000000000'))
            .div(expandDecimals(FERRO_LP_DECIMALS - this.tokenDecimals))
            .toNumber() / 100000000);
    }
    async calculateTokenAmount(lcroAmount, croAmount) {
        return await this.getContract().calculateTokenAmount([lcroAmount ?? BigNumber.from(0), croAmount ?? BigNumber.from(0)], true);
    }
    async calculateRemoveLiquidity(amount) {
        try {
            return await this.getContract().calculateRemoveLiquidity(amount);
        }
        catch (e) {
            console.error(e);
            return [BigNumber.from(0), BigNumber.from(0)];
        }
    }
    async calculateRemoveLiquidityOneToken(amount, tokenIndex) {
        try {
            return await this.getContract().calculateRemoveLiquidityOneToken(amount, tokenIndex);
        }
        catch (e) {
            console.error(e);
            return BigNumber.from(0);
        }
    }
    async addLiquidity(amount, expected) {
        const expectedBn = parseUnits((expected * 0.98).toFixed(5));
        return await wrapSdkTransaction(this.getContract().addLiquidity([amount, BigNumber.from(0)], expectedBn, 
        // 5 min timeout
        Math.floor(new Date().getTime() / 1000) + 60 * 5));
    }
    getReceivedLp(receipt) {
        const { logs } = receipt;
        if (logs.length < 3)
            return null;
        return BigNumber.from(logs[logs.length - 2].data);
    }
    async getVirtualPrice() {
        return await this.getContract().getVirtualPrice();
    }
}
