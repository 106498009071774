import { getLanguage, getLangUrls, languages, parsePathname } from '@ui/i18n';
import type { NextRouter } from 'next/router';
import {
  default as defaultNextRouter,
  useRouter as useNextRouter,
} from 'next/router';
import { useMemo } from 'react';

export type { NextRouter, RouterEvent, SingletonRouter } from 'next/router';
export { Router } from 'next/router';

export interface TransitionOptions {
  shallow?: boolean;
  locale?: string;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
}

export const useRouter = () => {
  const router = useNextRouter();

  return useMemo(() => {
    return enhanceRouter(router);
  }, [router]);
};

export default new Proxy(defaultNextRouter, {
  get(defaultNextRouter, prop, receiver) {
    const router = Reflect.get(defaultNextRouter, 'router', receiver);

    if (router && typeof prop === 'string' && prop in router) {
      return enhanceRouter(router)[prop as keyof NextRouter];
    }

    return Reflect.get(defaultNextRouter, prop, receiver);
  },
});

const enhanceRouter = (router: NextRouter): NextRouter => {
  const lang = (() => {
    const { asPath, query } = router;
    const slug = asPath.split('/')[1];
    return getLanguage((query.lang as string) || slug).lang;
  })();

  return {
    ...router,

    /**
     * should not include locale/lang in the pathname or asPath
     * @see https://nextjs.org/docs/api-reference/next/router#router-object
     */
    pathname: parsePathname(router.pathname).pathname,
    asPath: parsePathname(router.asPath).pathname,
    locale: lang,
    locales: languages.map((item) => item.lang),
    push: (url: string, as?: string, options?: TransitionOptions) => {
      return router.push(...getLangUrls(url, as, options?.locale), options);
    },
    replace: (url: string, as?: string, options?: TransitionOptions) => {
      return router.replace(...getLangUrls(url, as, options?.locale), options);
    },
  };
};
