export const TRANSACTION_TYPE_FERRO_IN = 'LP Ferro In';
export const TRANSACTION_TYPE_FERRO_OUT = 'LP Ferro Out';
export const TRANSACTION_TYPE_VVS_IN = 'LP VVS In';
export const TRANSACTION_TYPE_VVS_OUT = 'LP VVS Out';
export const TRANSACTION_TYPE_REDEEM = 'Redeem';
export const TRANSACTION_TYPE_STAKE = 'Stake';
export const TRANSACTION_TYPE_TECTONIC_IN = 'Tectonic Supply In';
export const TRANSACTION_TYPE_TECTONIC_OUT = 'Tectonic Supply Out';
export const TRANSACTION_TYPE_TRANSFER = 'Transfer';
export const TRANSACTION_TYPE_UNSTAKE = 'Unstake';

export const TRANSACTION_TYPES_CRO_NEGATIVE = [
  TRANSACTION_TYPE_STAKE,
  TRANSACTION_TYPE_FERRO_IN,
  TRANSACTION_TYPE_VVS_IN,
];
export const TRANSACTION_TYPES_LCRO_NEGATIVE = [
  // TRANSACTION_TYPE_UNSTAKE,
  TRANSACTION_TYPE_TECTONIC_IN,
  TRANSACTION_TYPE_FERRO_IN,
  TRANSACTION_TYPE_VVS_IN,
];

export const TRANSACTION_TYPES_LP_NEGATIVE = [
  TRANSACTION_TYPE_FERRO_OUT,
  TRANSACTION_TYPE_VVS_OUT,
];
export const TRANSACTION_TYPES_TLCRO_NEGATIVE = [TRANSACTION_TYPE_TECTONIC_OUT];

function transformTransaction(
  account: string,
  formattedAddress?: string,
): (value: {
  id: string;
  txnHash: string;
  from: string;
  to: string;
  croAmount: string;
  lcroAmount: string;
  usdAmount: string;
  exchangeRate: string;
  timestamp: string;
  type: string;
  lpTokenAmount: string | null;
  tTokenAmount: string | null;
}) => {
  usdAmount: number;
  croAmount: number;
  date: Date;
  walletAddress: string;
  croPerLcro: number;
  lpTokenAmount: number;
  tTokenAmount: number;
  lcroAmount: number;
  id: string;
  txnHash: string;
  from: string;
  to: string;
  exchangeRate: string;
  timestamp: string;
  type: string;
} {
  return (t) => ({
    ...t,
    usdAmount: parseFloat(t.usdAmount),
    croAmount:
      (t.type === TRANSACTION_TYPE_TRANSFER &&
        t.from === (account || '').toLowerCase()) ||
      TRANSACTION_TYPES_CRO_NEGATIVE.includes(t.type)
        ? parseFloat(`-${t.croAmount}`)
        : parseFloat(t.croAmount),
    date: new Date(Number(t.timestamp) * 1000),
    walletAddress: formattedAddress ?? account,
    croPerLcro: parseFloat(t.exchangeRate),
    lpTokenAmount: TRANSACTION_TYPES_LCRO_NEGATIVE.includes(t.type)
      ? -parseFloat(t.lpTokenAmount ?? '0')
      : parseFloat(t.lpTokenAmount ?? '0'),
    tTokenAmount: TRANSACTION_TYPES_TLCRO_NEGATIVE.includes(t.type)
      ? -parseFloat(t.tTokenAmount ?? '0')
      : parseFloat(t.tTokenAmount ?? '0'),
    lcroAmount:
      (t.type === TRANSACTION_TYPE_TRANSFER &&
        t.from === (account || '').toLowerCase()) ||
      TRANSACTION_TYPES_LCRO_NEGATIVE.includes(t.type)
        ? parseFloat(`-${t.lcroAmount}`)
        : parseFloat(t.lcroAmount),
  });
}

export default transformTransaction;
