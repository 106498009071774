import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';
import type { NftToken } from '@ui/components/NFTCard';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { COIN_MODE, getLiquidModule } from '@ui/hooks/useCoinMode';
import type { GardenType } from '@ui/utils/garden';
import { gardenTokenDict, getPidType } from '@ui/utils/garden';
import type { VenoSDK, VenoSDKWithoutTransaction } from '@veno-app/sdk';
import type LiquidAtomModule from '@veno-app/sdk/dist/esm/liquidAtom';
import type LiquidCroModule from '@veno-app/sdk/dist/esm/liquidCro';
import type LiquidEthModule from '@veno-app/sdk/dist/esm/liquidEth';

import { QueryKey } from './queryKey';

const parseTokenURI = (
  tokenURI: string,
): {
  name: '';
  description: '';
  image: string;
} => {
  try {
    const parsedStr = window.atob(
      tokenURI.trim().replace('data:application/json;base64,', ''),
    );

    try {
      // JSON.parse cannot handle bad control characters
      return new Function(`return ` + parsedStr)();
    } catch (e) {
      // try to correct the syntax error
      return new Function(`return ` + parsedStr.replace(/""/g, '"'))();
    }
  } catch (e) {
    return {
      name: '',
      description: '',
      image: '',
    };
  }
};

export const getLpAmountStaked = (
  coinMode: CoinMode,
  sdk: VenoSDK,
  address: string,
  type: GardenType,
) => ({
  queryKey: [QueryKey.LP_AMOUNT_STAKED, sdk, coinMode, address, type],
  queryFn: async (): Promise<BigNumber> => {
    return await sdk.VenoStorm.getLpAmountStaked(
      getPidType(coinMode, type),
      address,
    );
  },
});

export const getPendingVno = (
  coinMode: CoinMode,
  sdk: VenoSDK,
  address: string,
  type: GardenType,
) => ({
  queryKey: [QueryKey.PENDING_VNO, sdk, coinMode, address, type],
  queryFn: async (): Promise<BigNumber> => {
    if (!gardenTokenDict[coinMode][type].enabled) {
      return BigNumber.from(0);
    }

    return await sdk.VenoStorm.getPendingVno(
      getPidType(coinMode, type),
      address,
    );
  },
});

export const getBoostMultiplerForPool = (
  coinMode: CoinMode,
  sdk: VenoSDK,
  address: string,
  type: GardenType,
) => ({
  queryKey: [QueryKey.BOOST_MULTIPLIER_POOL, sdk, coinMode, address, type],
  queryFn: async (): Promise<number> => {
    return await sdk.VenoStorm.getBoostMultiplier(
      getPidType(coinMode, type),
      address,
    );
  },
});

export const getWcroBalance = (sdk: VenoSDK, address: string) => ({
  queryKey: [QueryKey.WCRO_BALANCE, sdk, address],
  queryFn: async () => {
    return await sdk.Wcro.balanceOf(address);
  },
});

export const getPendingVaultPenaltyReward = (
  sdk: VenoSDK,
  address: string,
) => ({
  queryKey: [QueryKey.PENDING_VAULT_PENALTY, sdk, address],
  queryFn: async () => {
    return await sdk.VenoFountain.pendingVaultPenaltyReward(address);
  },
});

export const getFerroExchangeRate = (
  coinMode: CoinMode,
  sdk: VenoSDK,
  amount: string | number,
) => ({
  queryKey: [QueryKey.FERRO_EXCHANGE_RATE, sdk, coinMode, amount],
  queryFn: async (): Promise<number> => {
    const amountToConvert = typeof amount === 'string' ? amount : `${amount}`;
    const number = parseUnits(
      amountToConvert,
      COIN_MODE[coinMode].liquidDecimals,
    );

    switch (coinMode) {
      case 'atom':
        return await sdk.FerroSwapAtom.getExchangeRate(number);
      case 'eth': // TODO
      case 'cro':
      default:
        return await sdk.FerroSwap.getExchangeRate(number);
    }
  },
});

export const getVvsExchangeRate = (sdk: VenoSDK, amount: string | number) => ({
  queryKey: [QueryKey.VVS_EXCHANGE_RATE, sdk, amount],
  queryFn: async (): Promise<number> => {
    const amountToConvert = typeof amount === 'string' ? amount : `${amount}`;
    const number = parseUnits(amountToConvert);
    return await sdk.VvsZapEstimator.getExchangeRate(number);
  },
});

export const getVvsCroExchangeRate = (
  sdk: VenoSDK,
  amount: string | number,
) => ({
  queryKey: [QueryKey.VVS_EXCHANGE_RATE, sdk, amount],
  queryFn: async (): Promise<number> => {
    const amountToConvert = typeof amount === 'string' ? amount : `${amount}`;
    const number = parseUnits(amountToConvert);
    return await sdk.VvsZapEstimator.getVvsCroExchangeRate(number);
  },
});

export const getVvsTiaExchangeRate = (
  sdk: VenoSDK,
  amount: string | number,
) => ({
  queryKey: ['getVvsTiaExchangeRate', sdk, amount],
  queryFn: async (): Promise<number> => {
    const amountToConvert = typeof amount === 'string' ? amount : `${amount}`;
    const number = parseUnits(amountToConvert, COIN_MODE.tia.liquidDecimals);
    return await sdk.VvsZapEstimator.getVvsTiaExchangeRate(number);
  },
});

export const getTectonicExchangeRate = (coinMode: CoinMode, sdk: VenoSDK) => ({
  queryKey: [QueryKey.TECTONIC_EXCHANGE_RATE, sdk, coinMode],
  queryFn: async (): Promise<number> => {
    switch (coinMode) {
      case 'atom':
        return await sdk.TLatom.getLpCoinPerLiquidCoin();
      case 'eth': // TODO
      case 'cro':
      default:
        return await sdk.TLcro.getLpCoinPerLiquidCoin();
    }
  },
});

export const getVnoBalance = (sdk: VenoSDK, address: string) => ({
  queryKey: [QueryKey.VNO_BALANCE, sdk, address],
  queryFn: async () => {
    return await sdk.VenoToken.balanceOf(address);
  },
});

export const getFerroLpBalance = (sdk: VenoSDK, address: string) => ({
  queryKey: [QueryKey.FERRO_LP_BALANCE, sdk, 'cro', address],
  queryFn: async () => {
    return (await sdk.FerroLpToken.balanceOf(address)) as BigNumber;
  },
});

export const getFerroLpBalanceAtom = (sdk: VenoSDK, address: string) => ({
  queryKey: [QueryKey.FERRO_LP_BALANCE, sdk, 'atom', address],
  queryFn: async () => {
    return (await sdk.FerroLpTokenAtom.balanceOf(address)) as BigNumber;
  },
});

export const getTLcroBalance = (sdk: VenoSDK, address: string) => ({
  queryKey: [QueryKey.TLCRO_BALANCE, sdk, address],
  queryFn: async () => {
    return (await sdk.TLcro.balanceOf(address)) as BigNumber;
  },
});

export const getGardenApr = (
  coinMode: CoinMode,
  sdk: VenoSDK,
  type: GardenType,
  user?: string,
) => ({
  queryKey: [QueryKey.GARDEN_APR, sdk, coinMode, type, user],
  queryFn: async () => {
    return (await sdk.VenoStorm.getGardenAprV2(
      getPidType(coinMode, type),
      user,
    )) as number;
  },
});

export const getVvsLpBalance = (sdk: VenoSDK, address: string) => ({
  queryKey: [QueryKey.VVS_LP_BALANCE, sdk, address],
  queryFn: async () => {
    return (await sdk.VvsLpToken.balanceOf(address)) as BigNumber;
  },
});

export const getVvsCroLpBalance = (sdk: VenoSDK, address: string) => ({
  queryKey: [QueryKey.VVS_CRO_LP_BALANCE, sdk, address],
  queryFn: async () => {
    return (await sdk.VvsCroLpToken.balanceOf(address)) as BigNumber;
  },
});

export const getVvsTiaLpBalance = (sdk: VenoSDK, address: string) => ({
  queryKey: ['vvs-tia-lp-balance', sdk, address],
  queryFn: async () => {
    return (await sdk.VvsTiaLpToken.balanceOf(address)) as BigNumber;
  },
});

export const getBoostTokens = (sdk: VenoSDK, address: string) => ({
  queryKey: [QueryKey.BOOST_TOKENS, sdk, address],
  queryFn: async () => {
    return (await sdk.VenoFountain.balanceOf(address)).add(
      await sdk.VenoReservoir.balanceOf(address),
    ) as BigNumber;
  },
});

export const getBoostMultiplierFromLockedVno = (
  sdk: VenoSDK,
  address: string,
) => ({
  queryKey: [QueryKey.BOOST_MULT_LOCKED_VNO, sdk, address],
  queryFn: async () => {
    return (await sdk.VenoStormMultiplierReader.getBoostMultiplierFromLockedVno(
      address,
    )) as BigNumber;
  },
});

export const getUnbondUnlockDate = ({
  sdk,
  coinMode,
}: {
  coinMode: CoinMode;
  sdk: VenoSDK;
}) => {
  const liquidModule = getLiquidModule(sdk, coinMode);
  return {
    queryKey: [QueryKey.UNBOND_UNLOCK_DATE, sdk, coinMode],
    queryFn: async (): Promise<BigNumber[]> => {
      switch (coinMode) {
        case 'eth': {
          const fiveDaysSeconds = 5 * 24 * 3600;
          const nowBn = BigNumber.from(Date.now()).div(1000);
          return [
            nowBn.add(fiveDaysSeconds),
            nowBn.add(fiveDaysSeconds).add(fiveDaysSeconds),
          ];
        }
        default:
          return [await liquidModule.getUnbondUnlockDate()];
      }
    },
  };
};

export const getStakeTransactionFee = (
  sdk: VenoSDK,
  address: string,
  coinMode: CoinMode,
) => ({
  queryKey: [QueryKey.STAKE_TRANSACTION_FEE, sdk, coinMode],
  queryFn: async (): Promise<BigNumber> => {
    switch (coinMode) {
      case 'cro':
        return await sdk.LiquidCro.estimateGasForStake(address);
      case 'atom':
      case 'tia':
        return BigNumber.from(0);
      case 'eth':
        return await sdk.LiquidEth.estimateGasForStake(address);
    }
  },
});

export const getCoinPerStakedCoin = (
  sdk: VenoSDKWithoutTransaction,
  coinMode: CoinMode,
) => ({
  queryKey: [QueryKey.COIN_PER_STAKED_COIN, sdk, coinMode],
  queryFn: async (): Promise<number> => {
    switch (coinMode) {
      case 'cro':
        return await sdk.LiquidCro.croPerLcro();
      case 'atom':
        return await sdk.LiquidAtom.atomPerLatom();
      case 'eth':
        return await sdk.LiquidEth.coinPerStakedCoin();
      case 'tia':
        return await sdk.LiquidTia.coinPerStakedCoin();
    }
  },
});

export const getTotalPooled = ({
  coinMode,
  sdk,
}: {
  coinMode: CoinMode;
  sdk: VenoSDKWithoutTransaction;
}) => {
  const liquidModule = getLiquidModule(sdk, coinMode);
  return {
    queryKey: [QueryKey.TOTAL_POOLED, sdk, coinMode],
    queryFn: async (): Promise<BigNumber> => await liquidModule.totalPooled(),
  };
};

export const getTotalSupplyStcro = (sdk: VenoSDK) => ({
  queryKey: [QueryKey.TOTAL_SUPPLY_STCRO, sdk],
  queryFn: async () => {
    return await sdk.LiquidCro.totalSupply();
  },
});

export const getNftTokens = ({
  coinMode,
  sdk,
  owner,
  latest = false,
}: {
  coinMode: CoinMode;
  sdk: VenoSDK;
  owner: string;
  latest?: boolean;
}) => ({
  queryKey: [QueryKey.GET_NFT_TOKENS, sdk, coinMode, owner, latest],
  queryFn: async (): Promise<NftToken[]> => {
    if (!owner) {
      return [];
    }

    let nftModule = sdk.Nft;
    let liquidModule: LiquidAtomModule | LiquidCroModule | LiquidEthModule =
      sdk.LiquidCro;

    switch (coinMode) {
      case 'atom':
        nftModule = sdk.AtomNft;
        liquidModule = sdk.LiquidAtom;
        break;
      case 'eth':
        liquidModule = sdk.LiquidEth;
        break;
      case 'tia':
        liquidModule = sdk.LiquidTia;
        nftModule = sdk.TiaNft;
        break;
    }

    const [tokenIds, EXCHANGE_RATE_PRECISION] = await Promise.all([
      nftModule.ownerTokenIds(owner),
      liquidModule.EXCHANGE_RATE_PRECISION.bind(liquidModule)(),
    ]);

    return await Promise.all(
      (latest ? tokenIds.slice(-1) : tokenIds).map(async (id) => {
        const [tokenURI, tokenInfo] = await Promise.all([
          nftModule.tokenURI(id),
          liquidModule.token2UnbondRequest.bind(liquidModule)(id),
        ]);
        const unbondingStatus = await liquidModule.batch2UnbondingStatus.bind(
          liquidModule,
        )(tokenInfo.batchNo);

        return {
          id,
          chainType: sdk.chainType,
          ...parseTokenURI(tokenURI),
          ...tokenInfo,
          unbondingStatus,
          liquidTokenAmount:
            'liquidTokenAmount' in tokenInfo
              ? tokenInfo.liquidTokenAmount
              : tokenInfo.liquidCroAmount,

          /**
           * liquidCro2CroExchangeRate = (totalSupply() * EXCHANGE_RATE_PRECISION) / totalPooledCro;
           * @see https://github.com/monacohq/liquid-staking-contracts-core/blob/master/src/LiquidCro.sol#L151
           */
          liquidToken2TokenExchangeRate:
            'liquidToken2TokenExchangeRate' in tokenInfo
              ? tokenInfo.liquidToken2TokenExchangeRate
              : tokenInfo.liquidCro2CroExchangeRate,
          liquidTokenExchangeRateFactor: EXCHANGE_RATE_PRECISION,
        };
      }),
    );
  },
});

export const getReservoirPoolInfos = (sdk: VenoSDK) => ({
  queryKey: [QueryKey.GET_VENO_RESERVOIR_POOL_INFOS, sdk],
  queryFn: async () => {
    return await sdk.VenoReservoir.getPoolInfos();
  },
});

export const getFountainPoolInfos = (sdk: VenoSDK) => ({
  queryKey: [QueryKey.GET_VENO_FOUNTAIN_POOL_INFOS, sdk],
  queryFn: async () => {
    return await sdk.VenoFountain.getPoolInfos();
  },
});

export const getFeeDistributorPeriodEndBlock = (sdk: VenoSDK) => ({
  queryKey: [QueryKey.GET_FEE_DISTRIBUTOR_PERIOD_END_BLOCK, sdk],
  queryFn: async () => {
    return await sdk.FeeDistributor.periodEndBlock();
  },
});
