import type { BoxProps, LayoutProps } from '@chakra-ui/react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { ExpandLess, ExpandMore } from '@veno-app/icons';
import type { ReactElement, ReactNode } from 'react';
import { useState } from 'react';

import { InDesktop, InMobile } from '../MobileOrDesktop';

export type NotificationProps = Omit<BoxProps, 'title'> & {
  title: ReactNode;
  text: ReactNode;
  textDesktop?: ReactElement;
  textWidth?: LayoutProps['maxW'];
  longText?: ReactNode;
  button?: ReactElement;
};

const Notification = ({
  title,
  text,
  textDesktop,
  button,
  longText,
  textWidth,
  ...props
}: NotificationProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box
      p="10px"
      backgroundColor="bg.main"
      shadow={expanded ? 'card.active' : 'card.normal'}
      borderRadius="5px"
      bgColor="bg.main"
      {...props}
    >
      <Flex
        flexDir="row"
        align={!longText && textDesktop ? 'center' : 'flex-start'}
        justify="space-between"
        cursor={!longText ? 'default' : 'pointer'}
        onClick={() => setExpanded((expanded) => !!longText && !expanded)}
      >
        <Flex direction="column">
          <InMobile>
            <Text textStyle={{ base: 'bodySmall', desktop: 'body' }}>
              {title}
            </Text>
          </InMobile>
          <InDesktop>
            <Text textStyle={{ base: 'bodySmall', desktop: 'body' }}>
              {!textDesktop ? title : null}
            </Text>
          </InDesktop>
          <Box
            textStyle={{ base: 'bodySmall', desktop: 'body' }}
            color="text.light"
            maxW={textWidth}
            mt="2px"
          >
            {!expanded ? (
              <Box noOfLines={2}>
                <InDesktop>{textDesktop ?? text}</InDesktop>
                <InMobile>{text}</InMobile>
              </Box>
            ) : (
              longText
            )}
          </Box>
        </Flex>
        {button && <Flex justify="flex-end">{button}</Flex>}
        {longText && !expanded && (
          <Flex justify="flex-end">
            <ExpandMore width="24px" height="24px" />
          </Flex>
        )}
        {longText && expanded && (
          <Flex justify="flex-end">
            <ExpandLess width="24px" height="24px" />
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default Notification;
