import type { EnsContextValue } from '@ui/providers/EnsProvider';

import { QueryKey } from './queryKey';

export const getCroId = (ensContext: EnsContextValue, address: string) => {
  const { lookupAddress } = ensContext;
  return {
    queryKey: [QueryKey.CRO_ID, address],
    queryFn: async () => {
      return await lookupAddress(address);
    },
  };
};
