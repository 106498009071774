/**
 * Switch theme
 * @see https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src/components/switch.ts
 */
import { switchAnatomy as parts } from '@chakra-ui/anatomy';
import { cssVar } from '@chakra-ui/styled-system';
const $width = cssVar('switch-track-width');
const $height = cssVar('switch-track-height');
export const Switch = {
    parts: parts.keys,
    baseStyle() {
        return {
            thumb: {
                bg: 'linear-gradient(135.52deg, #EBF0F3 14.99%, #D6E0E7 84.37%)',
            },
            track: {
                p: '3px',
                backgroundColor: 'shadow',
            },
        };
    },
    variants: {},
    sizes: {
        md: {
            container: {
                [$width.variable]: '38px',
                [$height.variable]: '18px',
            },
        },
    },
    defaultProps: {
        colorScheme: 'primary',
    },
};
