import { BigNumber } from 'ethers';
import LiquidTiaModule from './liquidTia';
import { getLiquidTokenUsdPrice, getTiaUsdPrice } from './util';
import VvsLpTokenModule from './vvsLpToken';
export default class VvsTiaLpTokenModule extends VvsLpTokenModule {
    constructor() {
        super(...arguments);
        this.contractAddress = this.sdk.addresses.vvsTiaPair;
        this.LiquidTia = new LiquidTiaModule(this.sdk);
        // on testnet, token0 is tia, token1 is liquidTia
        this.getTokenIndexes = () => {
            if (process.env.NEXT_PUBLIC_DEFAULT_CRONOS_NETWORK === 'cronos_testnet') {
                return { liquid: 1, base: 0 };
            }
            else {
                return { liquid: 0, base: 1 };
            }
        };
    }
    async getLpUsdWorth(amount) {
        if (amount.lte(0))
            return BigNumber.from(0);
        const contract = this.getContract();
        const [reserves, totalSupply, tiaUsdPrice, tiaPerLtia] = await Promise.all([
            contract.getReserves(),
            contract.totalSupply(),
            getTiaUsdPrice(),
            this.LiquidTia.atomPerLatom(),
        ]);
        const tokenIndexes = this.getTokenIndexes();
        const reservesCro = reserves[tokenIndexes.base];
        const reservesLcro = reserves[tokenIndexes.liquid];
        // be safe if something went wrong
        if (!tiaUsdPrice || !tiaPerLtia)
            return BigNumber.from(0);
        const ltiaUsdPrice = getLiquidTokenUsdPrice(tiaUsdPrice, tiaPerLtia);
        const tiaAmount = amount.mul(reservesCro).div(totalSupply);
        const ltiaAmount = amount.mul(reservesLcro).div(totalSupply);
        const factor = 100000000000;
        const result = ltiaAmount
            .mul(Math.round(ltiaUsdPrice * factor))
            // scale up by 10e12, because tia or ltia only have 6 decimals but our usd prices need 18
            .mul(1000000000000)
            .add(tiaAmount.mul(Math.round(tiaUsdPrice * factor)).mul(1000000000000))
            .div(factor);
        return result;
    }
    async getLtiaRedeemable(amount) {
        if (!amount)
            return undefined;
        if (amount.lte(0))
            return BigNumber.from(0);
        const contract = this.getContract();
        const [reserves, totalSupply] = await Promise.all([
            contract.getReserves(),
            contract.totalSupply(),
        ]);
        const reservesLtia = reserves[this.getTokenIndexes().liquid];
        const ltiaAmount = amount.mul(reservesLtia).div(totalSupply);
        return ltiaAmount;
    }
}
