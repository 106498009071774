import { useMemo } from 'react';

import { useRealPathname } from './router';

const useShouldHideNavigation = (): boolean => {
  const pathName = useRealPathname();

  // should use constants if there will be more paths
  return useMemo(
    () => pathName === '/iframe-vvs' || pathName === '/iframe-vvs-dark',
    [pathName],
  );
};

export default useShouldHideNavigation;
