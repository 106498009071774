import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { getQuery } from '@ui/queries/queries';
import { QueryKey } from '@ui/queries/queryKey';
import { currentWallet } from '@veno-app/wallet';

const { useAccount } = currentWallet;

const usePendingVaultPenaltyRewards = (
  options?: Partial<Pick<UseQueryOptions, 'enabled'>>,
) => {
  const sdk = useVenoSDK();
  const account = useAccount();

  const query = getQuery(QueryKey.PENDING_VAULT_PENALTY)(sdk, account ?? '');
  const {
    data: pendingVaultPenalty,
    isLoading,
    isFetching,
  } = useQuery(query.queryKey, query.queryFn, {
    ...options,
    refetchInterval: 8000,
    enabled: !!account && (options?.enabled === undefined || options.enabled),
  });

  return {
    pendingVaultPenaltyLoading: isLoading,
    pendingVaultPenaltyFetching: isFetching,
    pendingVaultPenalty,
  };
};

export default usePendingVaultPenaltyRewards;
