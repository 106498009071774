import { Icon, ModalCloseButton } from '@chakra-ui/react';
import { InDesktop, useIsDesktop } from '@ui/components/MobileOrDesktop';
import {
  ModalOrDragDrawerContent,
  ModalOrDrawer,
  ModalOrDrawerCloseButton,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from '@ui/components/ModalOrDrawer';
import { ArrowBack } from '@veno-app/icons';

import {
  DisclaimerDrawerContent,
  useDisclaimerDrawer,
} from '../DisclaimerDrawer';
import { DefaultContent } from './DefaultContent';
import { useUseYourTokenDrawer } from './useUseYourTokenDrawer';

export * from './useUseYourTokenDrawer';
export * from './utils';

export const UseYourTokenDrawer = () => {
  const isOpen = useUseYourTokenDrawer((s) => s.isOpen);
  const onClose = useUseYourTokenDrawer((s) => s.onClose);
  const title = useUseYourTokenDrawer((s) => s.title);
  const url = useDisclaimerDrawer((s) => s.url);
  const protocols = useUseYourTokenDrawer((s) => s.protocols);
  const isDesktop = useIsDesktop();

  return (
    <>
      <ModalOrDrawer
        placement="bottom"
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={onClose}
        size={{
          base: 'full',
          desktop: 'md',
        }}
      >
        <ModalOrDrawerOverlay />
        <ModalOrDragDrawerContent>
          <InDesktop>
            <ModalCloseButton />
          </InDesktop>
          {url && !!protocols.length && (
            <ModalOrDrawerCloseButton
              left="10px"
              onClick={(e) => {
                useDisclaimerDrawer.getState().onClose();
                e.preventDefault();
              }}
            >
              <Icon as={ArrowBack} boxSize="16px" />
            </ModalOrDrawerCloseButton>
          )}
          <ModalOrDrawerHeader>{title}</ModalOrDrawerHeader>
          {url && isDesktop ? <DisclaimerDrawerContent /> : <DefaultContent />}
        </ModalOrDragDrawerContent>
      </ModalOrDrawer>
    </>
  );
};
