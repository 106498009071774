import { braveWallet } from '@veno-app/wallet';
import { useEffect, useState } from 'react';

/**
 * Should only display Brave browser in the following cases
 *  1. if user are using the Brave browser
 *  2. if MetaMask is not installed
 *
 * If users installed the MetaMask extension on Brave browser, Brave wallet will
 * disable itself. So there's no way to use Brave wallet, when MetaMask already exist.
 */
export const useIsShowBraveWallet = (): boolean => {
  const [isShowBraveWallet, setIsShowBraveWallet] = useState(false);

  useEffect(() => {
    braveWallet
      .detectProvider()
      .then((p) => {
        setIsShowBraveWallet(!!p?.isBraveWallet);
      })
      .catch(() => {});
  }, []);

  return isShowBraveWallet;
};
