import type { BigNumber } from '@ethersproject/bignumber';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { useCoinMode, useLiquidModule } from '@ui/hooks/useCoinMode';
import { currentWallet } from '@veno-app/wallet';

export type UseLiquidCoinBalanceOptions = UseQueryOptions<BigNumber> & {
  coinMode?: CoinMode;
};
export const useLiquidCoinBalance = ({
  coinMode: _coinMode,
  ...options
}: UseLiquidCoinBalanceOptions = {}) => {
  const account = currentWallet.useAccount() ?? '';
  const globalCoinMode = useCoinMode();
  const coinMode = _coinMode ?? globalCoinMode;
  const liquidModule = useLiquidModule(coinMode);

  return useQuery<BigNumber>(
    [
      ...useLiquidCoinBalance.queryKey(coinMode),
      liquidModule.contractAddress,
      account,
    ],
    () => {
      return liquidModule.balanceOf(account);
    },
    {
      ...options,
      enabled: account ? options.enabled : false,
    },
  );
};

useLiquidCoinBalance.queryKey = (coinMode: CoinMode) => [
  'useLiquidCoinBalance',
  coinMode,
];
