import type { FlexProps } from '@chakra-ui/react';
import { Button, Flex, Text } from '@chakra-ui/react';
import type { BigNumber } from '@ethersproject/bignumber';
import AddTokenButton from '@ui/components/AddTokenButton';
import { pathDict } from '@ui/config/paths';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { COIN_MODE } from '@ui/hooks/useCoinMode';
import { useTranslations } from '@ui/i18n';
import Link from '@ui/overrides/next/link';
import { formatTokenAmount } from '@ui/utils/format';

import { InMobile } from '../MobileOrDesktop';

export type StakeTransactionCompletedContentProps = {
  coinMode: CoinMode;
  receivedAmount: BigNumber;
} & FlexProps;

const StakeTransactionCompletedContent: React.FC<
  StakeTransactionCompletedContentProps
> = ({ receivedAmount, coinMode, ...flexProps }) => {
  const t = useTranslations();
  const coinModeInfo = COIN_MODE[coinMode];
  return (
    <Flex
      mt="18px"
      flexDir="column"
      grow={1}
      gap={4}
      align="center"
      {...flexProps}
    >
      <Text textStyle="h3">{t('Transaction completed!')}</Text>
      <Flex flexDir="column" align="center" mt={2}>
        <Text textStyle="bodySmall">{t('You have received')}</Text>
        <Flex flexDir="row" alignItems="center">
          <Flex
            width="32px"
            height="32px"
            alignItems="center"
            justifyContent="center"
          >
            <coinModeInfo.LiquidIcon />
          </Flex>
          <Text textStyle="h2">
            {formatTokenAmount(receivedAmount, 5, coinModeInfo.liquidDecimals)}
          </Text>
        </Flex>
      </Flex>
      <AddTokenButton
        tokenType={coinModeInfo.liquidTokenType}
        text={t('Add {coin} to wallet', {
          coin: coinModeInfo.liquidName,
        })}
      />
      <Text mt={2} textAlign="center" alignSelf="flex-start" color="text.light">
        {t(
          'Use your {coin} across our partner protocols to earn additional rewards',
          {
            coin: coinModeInfo.liquidName,
          },
        )}
      </Text>
    </Flex>
  );
};

const StakeTransactionCompletedFooter: React.FC<{
  coinMode: CoinMode;
  onCloseDrawer: () => void;
}> = ({ coinMode, onCloseDrawer }) => {
  const t = useTranslations();
  return (
    <>
      <InMobile>
        <Link href={pathDict[coinMode].useLiquid}>
          <Button className="veno-tour__stake-modal-use-coin-button" as="a">
            {t('Use {coin}', {
              coin: COIN_MODE[coinMode].liquidName,
            })}
          </Button>
        </Link>
      </InMobile>
      <Button
        className="veno-tour__stake-modal-done-button"
        variant={{ base: 'link', desktop: 'primary' }}
        textAlign="center"
        onClick={onCloseDrawer}
        mt="20px"
      >
        {t('Done')}
      </Button>
    </>
  );
};

const StakeTransactionCompleted = {
  Content: StakeTransactionCompletedContent,
  Footer: StakeTransactionCompletedFooter,
} as const;

export default StakeTransactionCompleted;
