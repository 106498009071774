/**
 * Popover theme
 * @see https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src/components/popover.ts
 */
import { popoverAnatomy as parts } from '@chakra-ui/anatomy';
import { ColorThemeVars } from '../utils/colorThemes';
export const Popover = {
    parts: parts.keys,
    baseStyle() {
        return {
            content: {
                border: 'none',
                borderRadius: '5px',
                bg: `var(${ColorThemeVars.mainColorElevate})`,
                _dark: {
                    bg: `var(${ColorThemeVars.mainColorElevate})`,
                },
                boxShadow: `var(${ColorThemeVars.parentShadow})`,
            },
        };
    },
    variants: {},
    sizes: {},
    defaultProps: {},
};
