import { BigNumber } from '@ethersproject/bignumber';
import { useQuery } from '@tanstack/react-query';
import type { NftToken } from '@ui/components/NFTCard';
import { isNftTokenRedeemable } from '@ui/components/NFTCard';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { useCoinMode } from '@ui/hooks/useCoinMode';
import { useCoinUsdValue } from '@ui/hooks/useCoinUsdValue';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { getNftTokens } from '@ui/queries/sdk';
import { currentWallet } from '@veno-app/wallet';

const { useAccount } = currentWallet;

export const getTokenAmountOfNfts = (nfts: NftToken[]) => {
  return nfts
    .reduce((sum, token) => {
      return sum.add(
        token.liquidTokenAmount.mul(token.liquidToken2TokenExchangeRate),
      );
    }, BigNumber.from('0'))
    .div(nfts.length ? nfts[0].liquidTokenExchangeRateFactor : 1);
};

const useNftTokens = ({
  coinMode: _coinMode,
}: {
  coinMode?: CoinMode;
} = {}) => {
  const sdk = useVenoSDK();
  const account = useAccount();
  const globalCoinMode = useCoinMode();
  const coinMode = _coinMode ?? globalCoinMode;
  const getNftTokensQuery = getNftTokens({
    coinMode,
    sdk,
    owner: account ?? '',
  });
  const { data: nftTokens, isLoading: isNftDataLoading } = useQuery(
    getNftTokensQuery.queryKey,
    getNftTokensQuery.queryFn,
    {
      enabled: Boolean(account),
    },
  );

  const redeemableNftTokens: NftToken[] = [];
  const pendingNftTokens: NftToken[] = [];

  for (const nftToken of nftTokens || []) {
    if (isNftTokenRedeemable(nftToken)) {
      redeemableNftTokens.push(nftToken);
    } else {
      pendingNftTokens.push(nftToken);
    }
  }

  const redeemableLiquidTokenAmount = redeemableNftTokens.reduce(
    (sum, token) => {
      return sum.add(token.liquidTokenAmount);
    },
    BigNumber.from('0'),
  );
  const redeemableTokenAmount = getTokenAmountOfNfts(redeemableNftTokens);

  const pendingLiquidTokenAmount = pendingNftTokens.reduce((sum, token) => {
    return sum.add(token.liquidTokenAmount);
  }, BigNumber.from('0'));
  const pendingTokenAmount = getTokenAmountOfNfts(pendingNftTokens);

  const { data: redeemableUsdAmount, isLoading: redeemableUsdAmountLoading } =
    useCoinUsdValue(isNftDataLoading ? undefined : redeemableTokenAmount);

  const { data: pendingUsdAmount, isLoading: pendingUsdAmountLoading } =
    useCoinUsdValue(isNftDataLoading ? undefined : pendingTokenAmount);

  return {
    isLoading:
      isNftDataLoading || redeemableUsdAmountLoading || pendingUsdAmountLoading,
    redeemableNftTokens,
    redeemableLiquidTokenAmount,
    redeemableTokenAmount,
    pendingNftTokens,
    pendingLiquidTokenAmount,
    pendingTokenAmount,
    redeemableUsdAmount,
    pendingUsdAmount,
  };
};

export default useNftTokens;
