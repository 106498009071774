import { Flex } from '@chakra-ui/react';
import Info from '@ui/components/Info';
import { pathDict } from '@ui/config/paths';
import { useBalance } from '@ui/hooks/useBalance';
import { useCoinUsdValue } from '@ui/hooks/useCoinUsdValue';
import { useLiquidCoinBalance } from '@ui/hooks/useLiquidCoinBalance';
import { useLiquidCoinUsd } from '@ui/hooks/useLiquidCoinUsd';
import useVnoBalance from '@ui/hooks/useVnoBalance';
import { useVnoUsdValue } from '@ui/hooks/useVnoUsdValue';
import { formatNumber, formatTokenAmount } from '@ui/utils/format';
import { useRouter } from 'next/router';
import React from 'react';

import AddTokenButton, { TokenType } from '../AddTokenButton';
import Features from '../Features';
import { TokenIcon } from '../TokenIcon';
import AccountModalInfo from './AccountModalInfo';
import NftPanelZksync from './NftPanelZksync';
import { useAccountModalStore } from './useAccountModalStore';

function AccountModalInfoZksync() {
  const {
    balance,
    balanceUsdValue,
    vnoBalance,
    vnoBalanceUsd,
    lethBalance,
    lethBalanceUsd,
  } = useAccountModalZksyncBalances();
  const { toggleAccountModal, isOpen, setOpenDrawer } = useAccountModalStore();
  const router = useRouter();

  return (
    <>
      <Info
        title={
          <Flex>
            <TokenIcon token="eth" />
            ETH
          </Flex>
        }
        value={
          typeof balance !== 'undefined' ? formatNumber(balance) : undefined
        }
        convertedValue={
          balanceUsdValue !== undefined
            ? `${formatNumber(balanceUsdValue, 2)} USD`
            : undefined
        }
        convertedValueLoading={balanceUsdValue === undefined}
      />
      <AccountModalInfo
        title={
          <Flex gap={1}>
            <TokenIcon token="leth" />
            LETH <AddTokenButton ml={1} tokenType={TokenType.LETH} />
          </Flex>
        }
        convertedValue={
          lethBalanceUsd
            ? `${formatTokenAmount(lethBalanceUsd, 2)} USD`
            : undefined
        }
        convertedValueLoading={!lethBalanceUsd}
        actionType="get"
        action={() => {
          router.push(pathDict.eth.stake);
          toggleAccountModal(false);
        }}
        value={
          typeof lethBalance !== 'undefined'
            ? formatTokenAmount(lethBalance)
            : undefined
        }
      />
      <Features.vnoTokenZksync>
        <AccountModalInfo
          title={
            <Flex gap={1}>
              <TokenIcon token="vno" />
              VNO <AddTokenButton ml={1} tokenType={TokenType.VNO} />
            </Flex>
          }
          convertedValue={
            vnoBalanceUsd
              ? `${formatTokenAmount(vnoBalanceUsd, 2)} USD`
              : undefined
          }
          convertedValueLoading={!vnoBalanceUsd}
          value={
            typeof vnoBalance !== 'undefined'
              ? formatTokenAmount(vnoBalance)
              : undefined
          }
        />
      </Features.vnoTokenZksync>
      {isOpen && <NftPanelZksync />}
    </>
  );
}

const useAccountModalZksyncBalances = () => {
  const { data: balance } = useBalance();
  const balanceUsdValue = useCoinUsdValue(balance, 'eth').data;
  const isOpen = useAccountModalStore((s) => s.isOpen);
  const { vnoBalance } = useVnoBalance({ enabled: isOpen });
  const vnoBalanceUsd = useVnoUsdValue(vnoBalance).data;
  const { data: lethBalance } = useLiquidCoinBalance({ coinMode: 'eth' });
  const { data: lethBalanceUsd } = useLiquidCoinUsd({
    amount: lethBalance,
    coinMode: 'eth',
  });

  return {
    balance,
    balanceUsdValue,
    vnoBalance,
    vnoBalanceUsd,
    lethBalance,
    lethBalanceUsd,
  };
};

export default AccountModalInfoZksync;
