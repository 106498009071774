import { FeeDistributor__factory } from '@contracts';
import { VenoSDKModule } from './base';
import { BLOCKS_PER_YEAR, SECONDS_PER_YEAR } from './constants';
export default class FeeDistributorModule extends VenoSDKModule {
    constructor() {
        super(...arguments);
        this.contractFactory = FeeDistributor__factory;
        this.contractAddress = this.sdk.addresses.feeDistributor;
    }
    async rewardsPerYear(address) {
        return this.sdk.chainType === 'zksync'
            ? (await this.getContract().rewardTokenToCurrRewardsPerSecond(address)).mul(SECONDS_PER_YEAR)
            : (await this.getContract().rewardTokenToCurrRewardsPerBlock(address)).mul(BLOCKS_PER_YEAR);
    }
    async periodEndBlock() {
        return await this.getContract().periodEndBlock();
    }
}
