import { Box, Flex } from '@chakra-ui/react';
import { PATH_DASHBOARD, PATH_VNO } from '@ui/config/paths';
import {
  getPathInfo,
  isCategoryInSameView,
  useRealPathname,
} from '@ui/hooks/router';
import { useTranslations } from '@ui/i18n';
import Link from '@ui/overrides/next/link';
import {
  Close,
  Dashboard,
  DashboardFilled,
  Vno,
  VnoFilled,
} from '@veno-app/icons';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { InMobile } from '../MobileOrDesktop';
import { NavigationModal } from './NavigationModal';

const BAR_HEIGHT_PX = 60;
const FAB_OFFSET_Y = -31;
const MARGIN_Y = 20;
export const SAFE_BOTTOM_SPACE = BAR_HEIGHT_PX - FAB_OFFSET_Y + MARGIN_Y;

interface MobileAppBarContextState {
  barVisible?: boolean;
  fabAction: () => void;
  fabContent: ReactNode;
}

interface MobileAppBarContextValue {
  setAppBarState: Dispatch<SetStateAction<MobileAppBarContextState>>;
  setDefaultAppBar: () => void;
  setShowMenu: Dispatch<SetStateAction<boolean>>;
}

/**
 * state at initial render
 */
const initialState: MobileAppBarContextState = {
  barVisible: false,
  fabAction: () => {},
  fabContent: <></>,
};

const defaultContextValue: MobileAppBarContextValue = {
  setAppBarState: () => {},
  setShowMenu: () => {},
  setDefaultAppBar: () => {},
};

export const MobileAppBarContext =
  createContext<MobileAppBarContextValue>(defaultContextValue);

export const useDefaultAppBar = () => {
  const { setDefaultAppBar } = useContext(MobileAppBarContext);
  useEffect(() => {
    setDefaultAppBar();
  }, [setDefaultAppBar]);
};

const MobileAppBarContextProvider = ({ children }: { children: ReactNode }) => {
  const t = useTranslations();

  const [showMenu, setShowMenu] = useState(false);
  const [state, setState] = useState(initialState);
  const { barVisible } = state;
  const pathInfo = getPathInfo(useRealPathname());

  const setDefaultAppBar = useCallback(() => {
    setState({
      barVisible: true,
      fabAction: () => {
        setShowMenu((current) => !current);
      },
      fabContent: showMenu ? (
        <Close height="24px" width="24px" />
      ) : (
        <>{t('Staking')}</>
      ),
    });
  }, [showMenu, t]);

  const dashboardActive = isCategoryInSameView(pathInfo.category, 'dashboard');
  const vnoActive = isCategoryInSameView(pathInfo.category, 'vno');

  return (
    <MobileAppBarContext.Provider
      value={{ setAppBarState: setState, setShowMenu, setDefaultAppBar }}
    >
      <Box
        minH="100vh"
        pb={{
          base: `${SAFE_BOTTOM_SPACE}px`,
          desktop: '0px',
        }}
      >
        {children}
      </Box>
      <InMobile>
        <NavigationModal isOpen={showMenu} onClose={() => setShowMenu(false)} />
        <Flex
          position="fixed"
          zIndex="overlay"
          bottom={0}
          justify="space-between"
          width="full"
          backgroundColor={
            barVisible && !showMenu ? 'bg-mobileAppBar' : 'transparent'
          }
          backdropFilter="auto"
          backdropBlur="2px"
          height={`${BAR_HEIGHT_PX}px`}
          color="gray.600"
          textTransform="uppercase"
        >
          <Link href={PATH_DASHBOARD}>
            <Flex
              flex={1}
              height="100%"
              justify="center"
              align="center"
              flexDirection="column"
              hidden={!barVisible || showMenu}
              fontWeight={dashboardActive ? 'bold' : 'normal'}
              fontSize={12}
              color={dashboardActive ? 'text-active' : 'text-inactive'}
              onClick={() => setShowMenu(false)}
            >
              {dashboardActive ? (
                <DashboardFilled height={24} width={24} />
              ) : (
                <Dashboard height={24} width={24} />
              )}
              {t('Dashboard')}
            </Flex>
          </Link>
          <Flex justify="center" position="relative" flex={1}>
            <Flex
              marginTop={`${FAB_OFFSET_Y}px`}
              width="76px"
              height="76px"
              justify="center"
              align="center"
              color="white"
              fontSize={14}
              fontWeight="bold"
              rounded="full"
              shadow="md"
              backgroundColor="pink.500"
              onClick={state.fabAction}
            >
              {state.fabContent}
            </Flex>
          </Flex>
          <Link href={PATH_VNO}>
            <Flex
              justify="center"
              height="100%"
              hidden={!barVisible || showMenu}
              flexDirection="column"
              align="center"
              fontWeight={vnoActive ? 'bold' : 'normal'}
              fontSize={12}
              color={vnoActive ? 'text-active' : 'text-inactive'}
              flex={1}
              onClick={() => setShowMenu(false)}
            >
              {vnoActive ? (
                <VnoFilled height={24} width={24} />
              ) : (
                <Vno height={24} width={24} />
              )}
              VNO
            </Flex>
          </Link>
        </Flex>
      </InMobile>
    </MobileAppBarContext.Provider>
  );
};

export default MobileAppBarContextProvider;
