import { PudgyCruiser__factory } from '@contracts';
import { VenoSDKModule } from './base';
import { wrapSdkTransaction } from './util';
export default class StakebleNftModule extends VenoSDKModule {
    constructor(sdk, address) {
        super(sdk);
        this.contractFactory = PudgyCruiser__factory;
        this.contractAddress = this.sdk.addresses.cronosCruisers;
        this.contractAddress = address;
    }
    async getOwnerTokenIds(address) {
        const ids = await this.getContract().ownerTokenIds(address);
        return ids.map((id) => id.toNumber());
    }
    async approveStorm() {
        return wrapSdkTransaction(this.getContract().setApprovalForAll(this.addresses.venoStorm, true));
    }
    async isStormApproved(owner) {
        return this.getContract().isApprovedForAll(owner, this.addresses.venoStorm);
    }
    async mint() {
        await this.getContract().mint(4);
    }
}
