import type { BigNumber } from '@ethersproject/bignumber';
import type { TransactionStatus } from '@ui/components/TransactionDrawer';
import {
  TransactionError,
  TransactionPending,
} from '@ui/components/TransactionDrawer';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import useGardenApy from '@ui/hooks/useGardenApy';
import type { EthersError } from '@veno-app/sdk';
import React from 'react';

import SupplyTransactionCompleted from './SupplyTransactionCompleted';
import type { SupplyLcroModalType } from './types';

export type DoSupplyContentProps = {
  coinMode: CoinMode;
  transactionStatus: TransactionStatus;
  error: EthersError | null;
  receivedAmount: BigNumber;
  type: SupplyLcroModalType;
  nSteps: number;
  currentStep: number;
};

function DoSupplyContent(props: DoSupplyContentProps) {
  const { view, error, gardenApy, receivedAmount, type, nSteps, currentStep } =
    useDoSupplyContent(props);
  return (
    <>
      {view === 'PENDING' && (
        <TransactionPending.Content nSteps={nSteps} currentStep={currentStep} />
      )}
      {view === 'ERROR' && <TransactionError.Content error={error} />}
      {view === 'COMPLETED' && (
        <SupplyTransactionCompleted.Content
          type={type}
          gardenApy={gardenApy}
          receivedAmount={receivedAmount}
        />
      )}
    </>
  );
}

type DoSuppyContentFooterProps = {
  onClose: () => void;
  transactionStatus: TransactionStatus;
  type: SupplyLcroModalType;
};

export function DoSupplyContentFooter({
  onClose,
  transactionStatus,
  type,
}: DoSuppyContentFooterProps) {
  return (
    <>
      {transactionStatus === 'PENDING' && <TransactionPending.Footer />}
      {transactionStatus === 'COMPLETED' && (
        <SupplyTransactionCompleted.Footer
          type={type}
          onCloseDrawer={onClose}
        />
      )}
    </>
  );
}

type DoSupplyContentView = 'PENDING' | 'ERROR' | 'COMPLETED' | 'CONFIRM';

const useDoSupplyContent = ({
  coinMode,
  transactionStatus,
  error,
  type,
  receivedAmount,
  nSteps,
  currentStep,
}: DoSupplyContentProps) => {
  let view: DoSupplyContentView = 'CONFIRM';
  if (transactionStatus === 'PENDING') view = 'PENDING';
  if (transactionStatus === 'COMPLETED') view = 'COMPLETED';
  if (error) view = 'ERROR';

  const {
    ferroGardenApy,
    tectonicGardenApy,
    vvsGardenApy,
    vvsCroGardenApy,
    vvsTiaGardenApy,
  } = useGardenApy(type, false, coinMode);

  let gardenApy = ferroGardenApy ?? 0;
  if (type === 'TECTONIC') gardenApy = tectonicGardenApy ?? 0;
  if (type === 'VVS') gardenApy = vvsGardenApy ?? 0;
  if (type === 'VVS_CRO') gardenApy = vvsCroGardenApy ?? 0;
  if (type === 'VVS_TIA') gardenApy = vvsTiaGardenApy ?? 0;

  return {
    view,
    error,
    gardenApy,
    receivedAmount,
    type,
    nSteps,
    currentStep,
  };
};

export default DoSupplyContent;
