const TIMESTAMP_PER_YEAR = 31536000;

const estimateReward = (
  deposit: number | undefined,
  lockPeriod?: number | null,
  apr?: number | null,
): number | null => {
  if (apr === null || apr === undefined || !deposit || !lockPeriod) return null;
  return deposit * apr * (lockPeriod / TIMESTAMP_PER_YEAR);
};

export default estimateReward;
