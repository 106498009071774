/**
 * Locale files under /src/locales/[lang]/
 * @see https://github.com/cronos-labs/veno-app/tree/main/ui/src/locales/en/common.json
 */

import { features } from '@ui/components/Features';
import { isFeatureEnabled } from '@ui/hooks/useIsFeatureEnabled';
import type { Locale as DateFnsLocale } from 'date-fns';
import dateFnsIt from 'date-fns/locale/it';
import dateFnsKo from 'date-fns/locale/ko';

export type Language = {
  lang: string;
  name: string;
  dateFnsLocale?: DateFnsLocale;
};

export const defaultLanguage: Language = {
  lang: 'en',
  name: 'English',
};

const koLanguage: Language = {
  lang: 'ko',
  name: '한국어',
  dateFnsLocale: dateFnsKo,
};

export const languages: Language[] = isFeatureEnabled(features.italianLanguage)
  ? [
      defaultLanguage,
      {
        lang: 'it',
        name: 'Italiano',
        dateFnsLocale: dateFnsIt,
      },
      koLanguage,
    ]
  : [defaultLanguage, koLanguage];

export const defaultNamespace = 'common';

export const namespaces = [defaultNamespace];
