import { useWalletModal } from '@ui/components/Wallet';
import { resetWhenRouterChange } from '@ui/utils/zustandMiddleware';
import { currentWallet } from '@veno-app/wallet';
import { useEffect } from 'react';
import type { Step } from 'react-joyride';
import { usePreviousDistinct } from 'react-use';
import { create } from 'zustand';

import { VenoTour } from './VenoTour';

export const WalletTour = () => {
  const stepIndex = useWalletTour((s) => s.stepIndex);
  const isConnected = currentWallet.useIsConnectedToSupportedChain();
  const isWalletOpen = useWalletModal((s) => s.isOpen);
  const isWalletOpenPrevious = usePreviousDistinct(isWalletOpen);

  useEffect(() => {
    if (isWalletOpen) {
      useWalletTour.setState((s) => ({
        stepIndex: s.stepIndex + 1,
      }));
    }
  }, [isWalletOpen]);

  useEffect(() => {
    if (isWalletOpenPrevious && !isWalletOpen) {
      useWalletTour.setState((s) => ({
        stepIndex: s.stepIndex + 1,
      }));
    }
  }, [isWalletOpen, isWalletOpenPrevious]);

  useEffect(() => {
    if (isConnected) {
      useWalletTour.setState((s) => ({
        stepIndex: s.stepIndex + 1,
      }));
    }
  }, [isConnected]);

  const steps: Step[] = [
    {
      target: '.veno-tour__wallet',
      content: 'Connect you wallet to start earning staking rewards',
    },
    {
      target: '.veno-tour__wallet-list',
      content: 'Connect you wallet to start earning staking rewards',
    },
    {
      target: 'body',
      content: 'connecting',
      placement: 'center',
    },
  ];

  return (
    <VenoTour
      tourId="WalletTour"
      steps={steps}
      stepIndex={stepIndex}
      onStepIndexChange={(stepIndex) => {
        useWalletTour.setState({
          stepIndex: stepIndex,
        });
      }}
      withWalletTour={false}
    />
  );
};

type UseWalletTourStore = {
  stepIndex: number;
};

export const useWalletTour = create(
  resetWhenRouterChange<UseWalletTourStore>(() => ({
    stepIndex: 0,
  })),
);
