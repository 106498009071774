import '@public/fonts/style.css';
import '@ui/i18n';

import { ChakraProvider } from '@chakra-ui/react';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import EntryPointProvider from '@ui/components/EntryPointProvider/EntryPointProvider';
import ErrorBoundary from '@ui/components/ErrorBoundary';
import { GlobalComponents } from '@ui/components/GlobalComponents';
import MobileAppBarProvider from '@ui/components/MobileAppBarProvider';
import { RenderInDesktop } from '@ui/components/MobileOrDesktop';
import {
  ShowIfNavigation,
  ShowIfNoNavigation,
} from '@ui/components/Navigation/ShowIfNavigation';
import TopNavProvider from '@ui/components/TopNavProvider';
import { Footer } from '@ui/Footer';
import { FooterArticlesProvider } from '@ui/Footer/FooterArticlesProvider';
import { useInitializeApp } from '@ui/hooks/useInitializeApp';
import type { I18nPageProps } from '@ui/i18n/provider';
import { I18nProvider } from '@ui/i18n/provider';
import EnsProvider from '@ui/providers/EnsProvider';
import GraphQLProvider from '@ui/providers/GraphQLProvider';
import VenoSDKProvider from '@ui/providers/VenoSDKProvider';
import { theme } from '@ui/theme';
import { GA_ID } from '@ui/utils/telemetry';
import { ColorThemeProvider } from '@veno-app/chakra';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import React from 'react';

type NextAppProps = AppProps<
  I18nPageProps & { articles?: { slug: string; title: string }[] }
>;

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, q) => {
      console.error('error at query', q.queryKey);
      console.error(error);
    },
  }),
  defaultOptions: {
    queries: {
      retry: (failCount) => failCount < 2,
      refetchOnWindowFocus: false,
    },
  },
});

function MyApp({ Component, pageProps }: NextAppProps) {
  const { lang, langResource, usingDefaultLanguage, articles } = pageProps;
  useInitializeApp();

  return (
    <>
      {!!GA_ID && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`}
            strategy="afterInteractive"
          />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${GA_ID}');
            `}
          </Script>
        </>
      )}

      <QueryClientProvider client={queryClient}>
        <ChakraProvider resetCSS theme={theme}>
          <EnsProvider>
            <GraphQLProvider>
              <VenoSDKProvider>
                <ColorThemeProvider>
                  <I18nProvider
                    lang={lang}
                    langResource={langResource}
                    usingDefaultLanguage={usingDefaultLanguage}
                  >
                    <Head>
                      <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
                      />
                    </Head>
                    <RenderInDesktop>
                      <Script
                        src="https://cdn.jsdelivr.net/npm/@widgetbot/crate@3"
                        async
                        defer
                        onLoad={() => {
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          new (window as any).Crate({
                            server: '1042850384550572113',
                            channel: '1042850385670447119',
                          });
                        }}
                      />
                    </RenderInDesktop>
                    <ErrorBoundary>
                      <GlobalComponents />
                      <ShowIfNavigation>
                        <FooterArticlesProvider articles={articles}>
                          <MobileAppBarProvider>
                            <TopNavProvider>
                              <EntryPointProvider>
                                <Component {...pageProps} />
                              </EntryPointProvider>
                            </TopNavProvider>
                            <Footer />
                          </MobileAppBarProvider>
                        </FooterArticlesProvider>
                      </ShowIfNavigation>
                      <ShowIfNoNavigation>
                        <Component {...pageProps} />
                      </ShowIfNoNavigation>
                    </ErrorBoundary>
                  </I18nProvider>
                </ColorThemeProvider>
              </VenoSDKProvider>
            </GraphQLProvider>
          </EnsProvider>
        </ChakraProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
