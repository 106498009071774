import { Flex, Link, Text } from '@chakra-ui/react';
import Notification from '@ui/components/Notification';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { pathDict } from '@ui/config/paths';
import { useCoinMode } from '@ui/hooks/useCoinMode';
import { useTranslations } from '@ui/i18n';
import { useRouter } from '@ui/overrides/next/router';
import { MAX_W_FAQ_CARD, PRELAUNCH } from '@ui/utils/constants';

import { useFaqModalStore } from './useFaqModalStore';

type FaqInfoProps = {
  showTitle?: boolean;
  closeFaq?: () => void;
};

const FaqInfo = ({ showTitle, closeFaq }: FaqInfoProps) => {
  const t = useTranslations();
  const globalCoinMode = useCoinMode();
  const coinModePathDict = pathDict[globalCoinMode];

  const router = useRouter();
  const toggleFaqModal = useFaqModalStore((s) => s.toggleFaqModal);

  const changeRoute = (route: string) => {
    closeFaq && closeFaq();
    toggleFaqModal(false);
    router.push(route);
  };

  return (
    <>
      <Flex
        flexDir="column"
        flexGrow={1}
        gap={4}
        w="full"
        mx="auto"
        maxW={showTitle ? MAX_W_FAQ_CARD : undefined}
      >
        {showTitle && (
          <Text textAlign="center" pt="48px" textStyle="h1" fontWeight="normal">
            FAQs
          </Text>
        )}
        <Notification
          marginTop={!showTitle ? '40px' : 4}
          title={t('How does liquid staking work on Veno?')}
          text={
            t('how-does-liquid-staking-work-desc', {
              Link: (chunks) => chunks,
            }).slice(0, 84) + '...'
          }
          longText={
            <>
              {t('how-does-liquid-staking-work-desc', {
                Link: (chunks) => (
                  <Link
                    onClick={() =>
                      changeRoute && changeRoute(coinModePathDict.stake)
                    }
                    color="pink.500"
                  >
                    {chunks}
                  </Link>
                ),
              })}
              <br />
              <br />{' '}
              {t('Find out more here {Link}', {
                Link: (chunks) => (
                  <Link
                    href={EXTERNAL_LINK.whitepaper}
                    color="pink.500"
                    isExternal
                  >
                    {chunks}
                  </Link>
                ),
              })}
              .
            </>
          }
        />
        <Notification
          title={t('What can I do with my LCRO?')}
          text={t('what-can-I-do-with-lcro-desc').slice(0, 81) + '...'}
          longText={t('what-can-I-do-with-lcro-desc')}
        />
        <Notification
          title={t('How does unstaking work on Veno?')}
          text={
            t('How-does-unstaking-work-desc-{Link}', {
              Link: (chunks) => chunks,
            }).slice(0, 80) + '...'
          }
          longText={
            <>
              {t('How-does-unstaking-work-desc-{Link}', {
                Link: (chunks) => (
                  <Link
                    onClick={() =>
                      changeRoute && changeRoute(coinModePathDict.unstake)
                    }
                    color="pink.500"
                  >
                    {chunks}
                  </Link>
                ),
              })}
              <br />
              <br />
              {t('Find out more here {Link}', {
                Link: (chunks) => (
                  <Link
                    href={EXTERNAL_LINK.gitbook}
                    color="pink.500"
                    isExternal
                  >
                    {chunks}
                  </Link>
                ),
              })}
              .
            </>
          }
        />
        {!PRELAUNCH && (
          <>
            <Notification
              title={t('What is the Veno Claims NFT?')}
              text={
                t('What-is-the-Veno-Claims-NFT-desc-1').slice(0, 80) + '...'
              }
              longText={
                <>
                  {t('What-is-the-Veno-Claims-NFT-desc-1')}
                  <br />
                  <br />
                  {t(
                    'What-is-the-Veno-Claims-NFT-desc-2-{br}-{MintedLink}-{ClaimLink}',
                    {
                      br: () => <br />,
                      MintedLink: (chunks) => (
                        <Link
                          href={EXTERNAL_LINK.minted}
                          color="pink.500"
                          isExternal
                        >
                          {chunks}
                        </Link>
                      ),
                      ClaimLink: (chunks) => (
                        <Link
                          onClick={() =>
                            changeRoute && changeRoute(coinModePathDict.unstake)
                          }
                          color="pink.500"
                        >
                          {chunks}
                        </Link>
                      ),
                    },
                  )}
                </>
              }
            />
            <Notification
              title={t(
                'What is the difference between locking VNO in the Fountain or Reservoir?',
              )}
              text={
                t(
                  'Fountain-and-Reservoir-diff-{br}-{FountainLink}-{ReservoirLink}',
                  {
                    br: () => '',
                    FountainLink: (chunks) => chunks,
                    ReservoirLink: (chunks) => chunks,
                  },
                ).slice(0, 72) + '...'
              }
              longText={
                <>
                  {t(
                    'Fountain-and-Reservoir-diff-{br}-{FountainLink}-{ReservoirLink}',
                    {
                      br: () => <br />,
                      FountainLink: () => (
                        <Link
                          onClick={() =>
                            changeRoute && changeRoute(coinModePathDict.vno)
                          }
                          color="pink.500"
                        >
                          Fountain
                        </Link>
                      ),
                      ReservoirLink: () => (
                        <Link
                          onClick={() =>
                            changeRoute && changeRoute(coinModePathDict.vno)
                          }
                          color="pink.500"
                        >
                          Reservoir
                        </Link>
                      ),
                    },
                  )}
                </>
              }
            />
          </>
        )}
        {PRELAUNCH && <Notification title="What is VNO?" text="Coming soon" />}
        <Text textStyle="body">
          {t('For more information, check out our docs {Link}', {
            Link: (chunks) => (
              <Link href={EXTERNAL_LINK.gitbook} color="pink.500" isExternal>
                {chunks}
              </Link>
            ),
          })}
        </Text>
      </Flex>
    </>
  );
};

export default FaqInfo;
