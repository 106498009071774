import { colors } from './colors';
import { fontFamily } from './fontFamily';
import { inAtomSelector } from './utils/colorThemes';
export const styles = {
    global: {
        body: {
            backgroundColor: 'bg-main',
            color: 'text-normal',
            fontSize: '16px',
            fontFamily: fontFamily.default,
            // disable selection on mobile
            touchCallout: {
                base: 'none',
                desktop: 'auto',
            },
            userSelect: {
                base: 'none',
                desktop: 'auto',
            },
        },
        'd1,d2,h1,h2,h3': {
            fontFamily: `${fontFamily.heading}`,
        },
        '*': {
            WebkitTapHighlightColor: 'rgba(0,0,0,0)',
        },
        // light scrollbar
        '.light-scrollbar::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
            background: 'transparent',
        },
        '.light-scrollbar::-webkit-scrollbar-thumb': {
            backgroundColor: '#c1c1c1',
            borderRadius: '25px',
        },
        // no scrollbar
        '.no-scrollbar::-webkit-scrollbar': {
            display: 'none',
        },
        '.no-scrollbar': {
            scrollbarWidth: 'none',
        },
        // text-link color in atom
        [`${inAtomSelector} *`]: {
            '--chakra-colors-text-link': colors.brand.pinkInDark,
        },
    },
};
