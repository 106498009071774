import type { BigNumber } from '@ethersproject/bignumber';
import { MaxUint256 } from '@ethersproject/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEntryPoint } from '@ui/components/EntryPointProvider/EntryPointProvider';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { getVnoUsdPrice } from '@ui/queries/prices';
import { QueryKey } from '@ui/queries/queryKey';
import bigNumberToNumber from '@ui/utils/bigNumberToNumber';
import { trackEvent } from '@ui/utils/tracking';
import type { VenoSDK } from '@veno-app/sdk';
import { currentWallet } from '@veno-app/wallet';
import { useState } from 'react';

const { useAccount } = currentWallet;

const useVenoDeposit = (
  venoContractInstance:
    | ReturnType<typeof useVenoSDK>['VenoReservoir']
    | ReturnType<typeof useVenoSDK>['VenoFountain'],
  onSuccess: (
    data: void,
    variables: {
      pid: number;
      amount: BigNumber;
    },
  ) => void,
) => {
  const account = useAccount();
  const sdk = useVenoSDK();
  const [nSteps, setNSteps] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);

  const lockVno = async ({
    pid,
    amount,
  }: {
    pid: number;
    amount: BigNumber;
  }) => {
    if (!account) {
      return;
    }

    const allowance = await sdk.VenoToken.allowance(
      account,
      venoContractInstance.contractAddress,
    );
    if (allowance.lt(amount)) {
      // not yet approved.
      // 2 steps:
      // 1. approve, 2. deposit
      setNSteps(2);
      setCurrentStep(1);
      const approveRet = await sdk.VenoToken.approve(
        venoContractInstance.contractAddress,
        MaxUint256,
      );
      await approveRet.txReceiptPromise;
      // approval done, now got to step 2, deposit
      setCurrentStep(2);
    } else {
      // it was already approved, only one step, which is "deposit"
      setNSteps(1);
      setCurrentStep(1);
    }

    const depositRet = await venoContractInstance.deposit(pid, amount);
    await depositRet.txReceiptPromise;
  };

  return {
    nSteps,
    currentStep,
    ...useMutation(lockVno, {
      onSuccess,
    }),
  };
};

export const useVenoReservoirDeposit = () => {
  const sdk = useVenoSDK();
  const queryClient = useQueryClient();
  const entrypoint = useEntryPoint(true);
  const account = useAccount();
  return useVenoDeposit(sdk.VenoReservoir, async (_, variables) => {
    queryClient.invalidateQueries([QueryKey.GET_VENO_RESERVOIR_USER_INFO]);
    queryClient.invalidateQueries([QueryKey.BOOST_MULTIPLIER_POOL]);
    queryClient.invalidateQueries([QueryKey.BOOST_MULT_LOCKED_VNO]);
    queryClient.invalidateQueries([QueryKey.BOOST_TOKENS]);
    queryClient.invalidateQueries([QueryKey.VNO_BALANCE]);
    await trackDeposit('RESERVOIR', variables, account ?? '', sdk, entrypoint);
  });
};

export const useVenoFountainDeposit = () => {
  const sdk = useVenoSDK();
  const queryClient = useQueryClient();
  const entrypoint = useEntryPoint(true);
  const account = useAccount();
  return useVenoDeposit(sdk.VenoFountain, async (_, variables) => {
    queryClient.invalidateQueries([QueryKey.GET_VENO_FOUNTAIN_USER_INFO]);
    queryClient.invalidateQueries([QueryKey.BOOST_MULTIPLIER_POOL]);
    queryClient.invalidateQueries([QueryKey.BOOST_MULT_LOCKED_VNO]);
    queryClient.invalidateQueries([QueryKey.BOOST_TOKENS]);
    queryClient.invalidateQueries([QueryKey.VNO_BALANCE]);
    await trackDeposit('FOUNTAIN', variables, account ?? '', sdk, entrypoint);
  });
};

const trackDeposit = async (
  vaultType: 'RESERVOIR' | 'FOUNTAIN',
  variables: {
    pid: number;
    amount: BigNumber;
  },
  account: string,
  sdk: VenoSDK,
  entrypoint: string,
) => {
  const amount = bigNumberToNumber(variables.amount);
  const isTransaction = true;
  const vault = vaultType.toLowerCase();
  let period = '3-months';
  if (variables.pid === 1) period = '12-months';
  if (variables.pid === 2) period = '48-months';
  if (variables.pid === 3) period = '96-months';
  const [remainingBalance, usdAmount] = await Promise.all([
    bigNumberToNumber(await sdk.VenoToken.balanceOf(account)),
    await (async () => {
      const vnoUsdPrice = await getVnoUsdPrice().queryFn();
      return vnoUsdPrice * amount;
    })(),
  ]);
  trackEvent('Lock VNO Confirmed', {
    Amount: amount,
    'USD Amount': usdAmount,
    isTransaction,
    Vault: vault,
    Period: period,
    'Remaining Balance': remainingBalance,
    entrypoint,
  });
};
