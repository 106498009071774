/**
 * Alert theme
 * @see https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src/components/alert.ts
 */
import { alertAnatomy as parts } from '@chakra-ui/anatomy';
export const Alert = {
    parts: parts.keys,
    baseStyle() {
        return {
            container: {
                rounded: '5px',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '10px 16px',
                gap: '10px',
            },
        };
    },
    sizes: {},
    defaultProps: {
        variant: 'solid',
    },
    variants: {},
};
