import { getNetworkConfig } from '@veno-app/wallet';

import { useAtomBalance } from './useAtomBalance';
import { useBalance } from './useBalance';
import type { CoinMode } from './useCoinMode';
import { useCoinMode } from './useCoinMode';

const useCoinBalance = (coinMode?: CoinMode) => {
  const { chainId: chainIdCronos } = getNetworkConfig();
  const { chainId: chainIdZksync } = getNetworkConfig(true);

  const globalCoinMode = useCoinMode();
  const mode = coinMode ?? globalCoinMode;
  const balanceCro = useBalance(chainIdCronos);
  const balanceEth = useBalance(chainIdZksync);
  const balanceAtom = useAtomBalance({ enabled: coinMode === 'atom' });
  const balanceTia = useAtomBalance({ enabled: coinMode === 'tia' });

  if (mode === 'atom') {
    return balanceAtom;
  }
  if (mode === 'cro') {
    return balanceCro;
  }
  if (mode === 'tia') {
    return balanceTia;
  }

  return balanceEth;
};

export default useCoinBalance;
