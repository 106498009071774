import type { FlexProps } from '@chakra-ui/react';
import { Box, Button, Flex } from '@chakra-ui/react';
import type { ReactNode } from 'react';
import { useState } from 'react';

export type RetryProps = FlexProps & {
  hint: ReactNode;
  onRetry: () => Promise<void | boolean>;
  retryTimes?: number;
};
export const Retry = ({
  hint,
  onRetry,
  retryTimes = 3,
  ...props
}: RetryProps) => {
  const [isRetrying, setIsRetrying] = useState(false);

  const doRetry = async () => {
    setIsRetrying(true);
    try {
      for (let i = 0; i < retryTimes; i++) {
        const isContinue = await onRetry();
        if (isContinue === false) {
          break;
        }
      }
    } finally {
      setIsRetrying(false);
    }
  };

  return (
    <Flex direction="column" gap="10px" alignItems="center" {...props}>
      <Box
        textStyle={{
          base: 'body',
          desktop: 'h4',
        }}
        color="warning"
      >
        {hint}
      </Box>
      <Button
        variant="secondary"
        isLoading={isRetrying}
        disabled={isRetrying}
        onClick={doRetry}
        mt="10px"
      >
        Retry
      </Button>
    </Flex>
  );
};
