import type { Whitelist } from '@ui/components/Features';
import { features } from '@ui/components/Features';
import whitelists from '@ui/config/whitelist.json';
import { useCallback } from 'react';

import { useHashedAddress } from './useHashedAddress';

export enum AppEnvironment {
  Development = 'development',
  Dev = 'dev',
  UAT = 'uat',
  Production = 'production',
  Staging1 = 'sta1',
  Staging2 = 'sta2',
}

interface IsFeatureEnabledParams {
  disabledEnvironments: AppEnvironment[];
  whitelist?: Whitelist;
  hashedAddress: string | null;
}

export const useIsWhitelistFeatureEnabled = (
  feature: keyof typeof features,
) => {
  const hashedAddress = useHashedAddress();
  const whitelist: Whitelist | undefined =
    whitelists[feature as keyof typeof whitelists];
  if (!whitelist) return isFeatureEnabled(features[feature]);
  const allowedAddresses = getAllowedAddresses(whitelist);

  return (
    isFeatureEnabled(features[feature]) &&
    allowedAddresses.includes(hashedAddress ?? '')
  );
};

export function isFeatureEnabled(
  disabledEnvironments: IsFeatureEnabledParams['disabledEnvironments'],
) {
  const buildEnvironment: AppEnvironment = (process.env
    .NEXT_PUBLIC_RELEASE_STAGE || AppEnvironment.Development) as AppEnvironment;
  return disabledEnvironments.indexOf(buildEnvironment) === -1;
}
function useIsFeatureEnabled(): (params: IsFeatureEnabledParams) => boolean {
  const buildEnvironment: AppEnvironment = (process.env
    .NEXT_PUBLIC_RELEASE_STAGE || AppEnvironment.Development) as AppEnvironment;

  return useCallback(
    ({
      disabledEnvironments,
      whitelist,
      hashedAddress,
    }: IsFeatureEnabledParams): boolean =>
      disabledEnvironments.indexOf(buildEnvironment) === -1 &&
      inWhitelist(hashedAddress, whitelist),
    [buildEnvironment],
  );
}

const inWhitelist = (
  hashedAddress: string | null,
  whitelist: Whitelist | undefined,
): boolean => {
  if (!whitelist) return true;
  if (!hashedAddress) return false;
  const allowedAddresses = getAllowedAddresses(whitelist);
  return allowedAddresses.includes(hashedAddress);
};

export default useIsFeatureEnabled;
function getAllowedAddresses(whitelist: Whitelist) {
  const buildEnvironment: AppEnvironment = (process.env
    .NEXT_PUBLIC_RELEASE_STAGE || AppEnvironment.Development) as AppEnvironment;
  const allowedAddresses =
    buildEnvironment === 'production' || buildEnvironment === 'uat'
      ? whitelist['main']
      : whitelist['test'];
  return allowedAddresses;
}
