import { useIsDesktop } from '@ui/components/MobileOrDesktop';
import { NoSSR } from '@ui/components/NoSSR';
import { withReset } from '@ui/utils/zustandMiddleware';
import { zIndices } from '@veno-app/chakra';
import { currentWallet } from '@veno-app/wallet';
import type { FC } from 'react';
import { useRef } from 'react';
import type { Step } from 'react-joyride';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { usePreviousDistinct, useUnmount } from 'react-use';
import { create } from 'zustand';

import {
  initSteps,
  useDeepCompareMemo,
  useIsFirstStepReady,
  useIsWalletReady,
  useStepIndexWhenStepReady,
  useTourHasShown,
} from '.';
import { ToursTooltip } from './ToursTooltip';
import { WalletTour } from './Wallet';

export type VenoTourProps = {
  tourId: string;
  steps: Step[];
  stepIndex: number;
  onStepIndexChange: (stepIndex: number, isFinished: boolean) => void;
  withWalletTour?: boolean;
};
export const VenoTour: FC<VenoTourProps> = ({
  tourId,
  stepIndex: nextStepIndex,
  onStepIndexChange,
  steps: nextSteps,
  withWalletTour = true,
}) => {
  const isConnected = currentWallet.useIsConnectedToSupportedChain();
  const isConnectedPrevious = usePreviousDistinct(isConnected);
  const stepIndex = useStepIndexWhenStepReady(nextStepIndex, nextSteps);
  const showingTourId = useVenoTour((s) => s.showingTourId);
  const isTourRun = stepIndex >= 0;
  const isStartedRef = useRef(false);
  const isFirstStepReady = useIsFirstStepReady(nextStepIndex, nextSteps);
  const [haveShown, setHaveShown] = useTourHasShown(tourId);
  const isDesktop = useIsDesktop();

  const steps = useDeepCompareMemo(() => {
    return initSteps(nextSteps, {
      /**
       * when stepIndex is not ready, return initial steps for Joyride
       * otherwise Joyride will throw errors if target element is removed from window.document
       */
      skipElementInitialize: stepIndex !== nextStepIndex,
    });
  }, [stepIndex, nextStepIndex, nextSteps]);

  /**
   * if including the wallet tour,
   * wait for the wallet's initialization
   */
  const isWalletReady = useIsWalletReady(withWalletTour);

  useUnmount(() => {
    if (useVenoTour.getState().showingTourId === tourId) {
      useVenoTour.setState({
        showingTourId: null,
      });
    }
  });

  if (haveShown) {
    return null;
  }

  if (!isWalletReady) {
    return null;
  }

  if (withWalletTour && !isConnected && !isConnectedPrevious) {
    return <WalletTour />;
  }

  if (!showingTourId && !isStartedRef.current && isFirstStepReady) {
    isStartedRef.current = true;
    useVenoTour.setState({
      showingTourId: tourId,
    });
  }

  if (!isStartedRef.current) {
    return null;
  }

  return (
    <NoSSR>
      <Joyride
        callback={(data) => {
          setHaveShown();

          if (data.type === EVENTS.STEP_AFTER) {
            onStepIndexChange(
              data.index + (data.action === ACTIONS.PREV ? -1 : 1),
              false,
            );
            return;
          }

          if (
            data.status === STATUS.FINISHED ||
            data.status === STATUS.SKIPPED
          ) {
            onStepIndexChange(0, true);
            useVenoTour.setState({
              showingTourId: null,
            });
            return;
          }
        }}
        run={isTourRun}
        steps={steps}
        disableOverlayClose
        stepIndex={stepIndex}
        continuous
        scrollToFirstStep
        showProgress
        disableCloseOnEsc
        tooltipComponent={ToursTooltip}
        /**
         * avoid overlap with navbar
         */
        scrollOffset={isDesktop ? 100 : 10}
        spotlightClicks={true}
        floaterProps={{
          hideArrow: true,
        }}
        styles={{
          options: {
            zIndex: zIndices.tour,
          },
        }}
      />
    </NoSSR>
  );
};

type UseVenoTourStore = {
  showingTourId: string | null;
};

const useVenoTour = create(
  withReset<UseVenoTourStore>(() => ({
    showingTourId: null,
  })),
);
