import { type FlexProps, Icon } from '@chakra-ui/react';
import { useLanguage, useTranslations } from '@ui/i18n';
import { Vno } from '@veno-app/icons';
import { formatDistanceStrict } from 'date-fns';
import {
  AnnouncementConfigProvider,
  AnnouncementList,
  MarkAllAsReadButton,
} from 'telegram-announcement-components';

import { useAnnouncementQuery } from './utils';

export type AnnouncementContentProps = FlexProps;
export const AnnouncementContent = () => {
  const announcementRet = useAnnouncementQuery();
  const t = useTranslations();
  const language = useLanguage();

  return (
    <AnnouncementConfigProvider
      t={t}
      subtitle="Veno Finance announcements"
      defaultAvatar={<Icon as={Vno} boxSize="24px" />}
      announcementDateFormatter={(date) => {
        return formatDistanceStrict(date * 1000, Date.now(), {
          addSuffix: true,
          locale: language.dateFnsLocale,
        });
      }}
    >
      <AnnouncementList announcements={announcementRet.data} />
    </AnnouncementConfigProvider>
  );
};

export const MarkAllButton = () => {
  const announcementRet = useAnnouncementQuery();
  const t = useTranslations();

  return (
    <AnnouncementConfigProvider t={t}>
      <MarkAllAsReadButton announcements={announcementRet.data} />
    </AnnouncementConfigProvider>
  );
};
