import { Button } from '@chakra-ui/react';
import { InDesktop, InMobile } from '@ui/components/MobileOrDesktop';
import type { TransactionStatus } from '@ui/components/TransactionDrawer';
import {
  DepositUpgradeCompleted,
  TransactionPending,
} from '@ui/components/TransactionDrawer';
import { useTranslations } from '@ui/i18n';

import { UpgradeStep } from './types';

interface DepositUpgradeFooterProps {
  step: UpgradeStep;
  onNextStep: () => void;
  transactionStatus: TransactionStatus;
  onCloseDrawer: () => void;
  onUpgrade: () => void;
  disabled?: boolean;
}
const DepositUpgradeFooter: React.FC<DepositUpgradeFooterProps> = ({
  step,
  onNextStep,
  transactionStatus,
  onCloseDrawer,
  onUpgrade,
  disabled,
}) => {
  const t = useTranslations();

  switch (transactionStatus) {
    case 'COMPLETED':
      return <DepositUpgradeCompleted.Footer onCloseDrawer={onCloseDrawer} />;
    case 'PENDING':
      return <TransactionPending.Footer />;
    case 'ERROR':
      return null;
    default:
      switch (step) {
        case UpgradeStep.SelectStake:
          return (
            <Button disabled={disabled} onClick={onNextStep}>
              {t('Confirm')}
            </Button>
          );
        case UpgradeStep.Upgrade:
          return (
            <>
              <InDesktop>
                <Button disabled={disabled} onClick={onNextStep}>
                  {t('Confirm')}
                </Button>
              </InDesktop>
              <InMobile>
                <Button disabled={disabled} onClick={onUpgrade}>
                  {t('Upgrade now')}
                </Button>
              </InMobile>
            </>
          );
        default:
          return (
            <Button disabled={disabled} onClick={onUpgrade}>
              {t('Upgrade now')}
            </Button>
          );
      }
  }
};

export default DepositUpgradeFooter;
