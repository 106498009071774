import type { InputProps } from '@chakra-ui/react';
import { Box, Flex, Input, Text, useControllableState } from '@chakra-ui/react';
import numbro from 'numbro';
import { useRef, useState } from 'react';

import type { CardProps } from '../Card';
import { Card } from '../Card';
import { InputCardStepper } from './InputCardStepper';

export type InputCardProps = Omit<CardProps, 'onChange'> & {
  label: string;
  isActive?: boolean;
  onChange?: (value: string) => void;
  max?: number | string;
  value?: string;
  errorMessage?: string;
  warningMessage?: string;
  type?: InputProps['type'];
  inputMode?: InputProps['inputMode'];
  placeholder?: string;
  maxDecimals?: number;
  usdValue?: number;
};

export const InputCard = (props: InputCardProps) => {
  const {
    colorTheme,
    isActive,
    label,
    onChange,
    max,
    value,
    errorMessage,
    type,
    inputMode,
    placeholder,
    maxDecimals,
    warningMessage,
    usdValue,
    ...boxProps
  } = props;
  const inputProps = {
    colorTheme,
    isActive,
    label,
    onChange,
    max,
    value,
    errorMessage,
    type,
    inputMode,
    placeholder,
    maxDecimals,
    warningMessage,
    usdValue,
  };
  return (
    <Box {...boxProps}>
      {max ? (
        <InputCardStepper
          max={max}
          maxDecimals={maxDecimals}
          value={value}
          onChange={onChange}
          mb="16px"
        />
      ) : null}
      <InputBox {...inputProps} />
    </Box>
  );
};

export const InputBox = ({
  isActive,
  label,
  onChange,
  max,
  value,
  errorMessage,
  type = 'number',
  inputMode = 'decimal',
  placeholder,
  maxDecimals,
  warningMessage,
  usdValue,
  ...props
}: InputCardProps) => {
  const [isFocus, setIsFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [internalValue, setInternalValue] = useControllableState({
    value,
    onChange,
  });

  const isInputActive =
    typeof isActive === 'boolean'
      ? isActive
      : isFocus || Boolean(internalValue);

  return (
    <Card
      as={Flex}
      isActive={isInputActive}
      minH="68px"
      mx="0"
      paddingInline="16px"
      paddingTop="12px"
      paddingBottom="9px"
      alignItems="flex-end"
      gap="4px"
      onClick={() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }}
      {...props}
    >
      <Flex flexDirection="column" gap="2px" flexGrow="1">
        <Text textStyle="bodySmall" height="24px">
          {isFocus || internalValue ? label : null}
        </Text>
        <Flex align="center" justify="space-between">
          <Input
            ref={inputRef}
            type={type}
            value={internalValue}
            placeholder={placeholder}
            onWheel={(e) => (e.target as HTMLElement).blur()}
            height={internalValue || isFocus ? '24px' : '0px'}
            width={internalValue || isFocus ? 'auto' : '0px'}
            flexGrow={internalValue || isFocus ? 1 : 0}
            variant="unstyled"
            max={max}
            inputMode={inputMode}
            onFocus={() => {
              setIsFocus(true);
            }}
            onBlur={() => {
              setIsFocus(false);
            }}
            onChange={(e) => {
              if (
                maxDecimals &&
                new RegExp(`\\.[0-9]{${maxDecimals + 1},}$`).test(
                  e.target.value,
                )
              ) {
                return;
              }
              setInternalValue(e.target.value);
            }}
          />
          {!!internalValue && !!usdValue && (
            <Text color="text-light" maxW="50px" ml="8px" noOfLines={1}>
              $
              {numbro(usdValue).format({
                average: true,
                mantissa: 0,
                totalLength: 2,
              })}
            </Text>
          )}
          {!isFocus && !internalValue && (
            <Text h="24px" textStyle="bodySmall" flexGrow={1}>
              {label}
            </Text>
          )}
        </Flex>
        <Text
          transition="all 0.3s"
          textStyle="caption"
          fontWeight="bold"
          lineHeight="16px"
          color={errorMessage ? 'error' : 'warning'}
        >
          {errorMessage ? errorMessage : warningMessage}
        </Text>
      </Flex>
    </Card>
  );
};
