import { formatUnits, parseUnits } from '@ethersproject/units';
import { useQuery } from '@tanstack/react-query';
import { useVenoSDK } from '@ui/providers/VenoSDKProvider';
import { convertScientificToDecimal } from '@ui/utils/bigNumberToNumber';
import type { GardenType } from '@ui/utils/garden';
import { getLpUsdValue } from '@ui/utils/garden';

import { useCoinMode } from './useCoinMode';

const useLpUsdValue = (type: GardenType, amount: string) => {
  const coinMode = useCoinMode();
  const sdk = useVenoSDK();

  const fetchLpUsdValue = async () => {
    const val = await getLpUsdValue(
      coinMode,
      type,
      sdk,
      parseUnits(convertScientificToDecimal(amount, 18)),
    );
    return parseFloat(formatUnits(val));
  };

  const { data: lpUsdValue } = useQuery(
    ['useLpUsdValue', type, amount, coinMode],
    fetchLpUsdValue,
    {
      enabled: !!amount,
    },
  );

  return lpUsdValue;
};

export default useLpUsdValue;
