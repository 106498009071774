import type { FlexProps } from '@chakra-ui/react';
import { Box, Flex } from '@chakra-ui/react';
import type { CoinMode } from '@ui/hooks/useCoinMode';
import { useTranslations } from '@ui/i18n';
import type { GardenToken, GardenType } from '@ui/utils/garden';
import { gardenTokenDict, getGardenName } from '@ui/utils/garden';
import React from 'react';

export type AssetExplainerProps = {
  coinMode: CoinMode;
  type: GardenType;
  explainText: 'TOKEN' | 'GARDEN';
} & FlexProps;

function AssetExplainer({
  coinMode,
  type,
  explainText,
  ...flexProps
}: AssetExplainerProps) {
  const t = useTranslations();
  const gardenToken = gardenTokenDict[coinMode][type] as Extract<
    GardenToken,
    { enabled: true }
  >;

  return (
    <Flex
      flexDir="column"
      px={{ base: 0, desktop: 8 }}
      py={{ base: 0, desktop: 4 }}
      minW={{ base: 'full' }}
      {...flexProps}
    >
      {explainText === 'TOKEN' && (
        <Box>
          {t(
            '{coin} represents your share of the {poolName} pool on {protocolName}',
            {
              coin: gardenToken.name,
              poolName: gardenToken.poolName,
              protocolName: gardenToken.protocolName,
            },
          )}{' '}
          {type === 'TECTONIC' && (
            <>
              {t(
                'The value of {coin} will increase as interest is earned over time',
                {
                  coin: gardenToken.name,
                },
              )}
            </>
          )}
        </Box>
      )}
      {explainText === 'GARDEN' && (
        <Box>
          {t(
            'Deposit your received {coin} into the {gardenName} to receive VNO rewards',
            {
              coin: gardenToken.name,
              gardenName: getGardenName(coinMode, type),
            },
          )}
        </Box>
      )}
    </Flex>
  );
}

export default AssetExplainer;
