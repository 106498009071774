import { Flex } from '@chakra-ui/react';
import { TokenIcon } from '@ui/components/TokenIcon';
import { pathDict } from '@ui/config/paths';
import { COIN_MODE } from '@ui/hooks/useCoinMode';
import { useCoinUsdValue } from '@ui/hooks/useCoinUsdValue';
import useNftTokens from '@ui/hooks/useNftTokens';
import { useTranslations } from '@ui/i18n';
import { formatTokenAmount } from '@ui/utils/format';
import { useRouter } from 'next/router';

import AccountModalInfo from './AccountModalInfo';
import { useAccountModalStore } from './useAccountModalStore';

const NftPanelCronos = () => {
  const t = useTranslations();
  const croNftTokens = useNftTokens({
    coinMode: 'cro',
  });
  const atomNftTokens = useNftTokens({
    coinMode: 'atom',
  });
  const toggleAccountModal = useAccountModalStore((s) => s.toggleAccountModal);
  const router = useRouter();

  const atomAmountUsd = useCoinUsdValue(
    atomNftTokens.redeemableTokenAmount,
    'atom',
  ).data;
  const croAmountUsd = useCoinUsdValue(
    croNftTokens.redeemableTokenAmount,
    'cro',
  ).data;

  return (
    <>
      <AccountModalInfo
        title={
          <Flex>
            <TokenIcon token="cro" />{' '}
            {t('Claimable {coin}', {
              coin: 'CRO',
            })}
          </Flex>
        }
        value={
          formatTokenAmount(croNftTokens.redeemableTokenAmount) ?? undefined
        }
        convertedValue={
          croAmountUsd ? formatTokenAmount(croAmountUsd) + ' USD' : undefined
        }
        convertedValueLoading={!croAmountUsd}
        actionType="claim"
        action={() => {
          router.push(pathDict.cro.claim);
          toggleAccountModal(false);
        }}
      />
      <AccountModalInfo
        title={
          <Flex>
            <TokenIcon token="cro" />
            {t('Claimable {coin}', {
              coin: 'ATOM',
            })}
          </Flex>
        }
        value={
          formatTokenAmount(
            atomNftTokens.redeemableTokenAmount,
            undefined,
            COIN_MODE.atom.decimals,
          ) ?? undefined
        }
        convertedValue={
          atomAmountUsd ? formatTokenAmount(atomAmountUsd) + ' USD' : undefined
        }
        convertedValueLoading={!atomAmountUsd}
        actionType="claim"
        action={() => {
          router.push(pathDict.atom.claim);
          toggleAccountModal(false);
        }}
      />
    </>
  );
};

export default NftPanelCronos;
