import { formatEther } from '@ethersproject/units';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { defaultRpcMap } from '@ui/utils/rpcProviders';
import { currentWallet } from '@veno-app/wallet';

/**
 * keep the useBalance() in line with the previous version as much as possible.
 * @see  https://github.com/web3-wallet/web3-wallet/blob/%40web3-wallet/walletconnect%401.1.2/packages/plugins/balance/src/plugin.ts
 */
export const useBalance = (
  chainId?: number,
  options: UseQueryOptions<number | undefined> = {},
) => {
  const _provider = currentWallet.useProvider();
  const account = currentWallet.useAccount();
  const defaultChainId = currentWallet.useChainId();

  return useQuery<number | undefined>(
    // the provider will be changed if account or chainId is changed
    ['useBalance', account, chainId, defaultChainId],
    async () => {
      const providerChainId = chainId ?? defaultChainId;
      const staticProvider = providerChainId
        ? defaultRpcMap[providerChainId]
        : undefined;

      if (!staticProvider || !account) {
        return;
      }

      const balance = await staticProvider.getBalance(account);

      return Number(formatEther(balance));
    },
    {
      ...options,
      enabled: account ? options.enabled : false,
    },
  );
};
